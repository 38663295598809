/* eslint-disable max-lines */
import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  IAddCollectionRequest,
  ICollection,
  ICollectionEmailConfig,
  ICollectionEmailConfigCreateRequest,
  ICollectionSendEmailResponse,
  ICollectionTestEmailRequest,
  IDuplicateVariantOutsideResponse,
  IUpdateCollectionEmailConfigVariables,
  ParsedVariant,
  SharingCollectionPermissions,
} from '@features/collections/types'
import { invalidateCollectionVariants } from '@features/collections/utils'
import { Destination, IDriveData } from '@features/destinations/types'
import { FilterType, IFilter } from '@features/filters/types'
import { FilePickerType } from '@features/users/providers/file-picker-provider'

import { duplicateDestinationPartialSuccessToast, sharingFailedInDriveToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import {
  UseMutationWithErrorHandlerOptions,
  UseQueryWithErrorHandlerOptions,
  createNotFoundOrForbiddenErrorHandler,
  onError,
  queryClient,
  useMutationWithErrorHandler,
  useQueryWithErrorHandler,
} from '@shared/http'
import { api } from '@shared/http/axios'
import { CollaborationApiResponse, DrivePermissionApiResponse } from '@shared/types'

import { handleCollectionOutOfDateError } from './error-handler'

interface GetFiltersArgs {
  types?: FilterType[]
  credentialsIds?: string[]
  dashboardIds?: string[]
}

export const useGetFilters = (
  { types, credentialsIds, dashboardIds }: GetFiltersArgs,
  options: { enabled: boolean } = { enabled: true }
) => {
  return useQuery<IFilter[], AxiosError>(
    ['filters', { types, dashboardIds }],
    async () => {
      const response = await api.get(`filters`, {
        params: {
          types,
          credentialsIds,
          dashboardIds,
        },
      })

      if (!response) {
        return []
      }

      return response.data
    },
    {
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('GET_FILTERS_ERROR'),
          message: error.message,
        })
      },
      enabled: options.enabled,
      useErrorBoundary: false,
    }
  )
}

export const useGetCollections = () => {
  return useQueryWithErrorHandler<ICollection[], AxiosError>(
    ['collections'],
    async () => {
      const response = await api.get('collections')

      if (!response) return null

      return response.data
    },
    {
      errorTitle: getMessage('COLLECTIONS_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useGetSharedCollections = () => {
  return useQuery<ICollection[], Error>(
    ['shared-collections'],
    async () => {
      const response = await api.get('collections/shared')

      if (!response) return null

      return response.data
    },
    {
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('SHARED_COLLECTIONS_GET_ERROR'),
          message: error.message,
        })
      },
      refetchOnMount: 'always',
      useErrorBoundary: false,
    }
  )
}

interface IGetCollectionById {
  collectionId?: string
}
export const useGetCollectionById = ({ collectionId }: IGetCollectionById) => {
  const navigate = useNavigate()

  return useQueryWithErrorHandler<ICollection, AxiosError>(
    ['collection', { collectionId }],
    async () => {
      if (!collectionId) {
        return null
      }

      const response = await api.get(`collections/${collectionId}`)

      return response.data
    },
    {
      errorHandlers: [createNotFoundOrForbiddenErrorHandler(navigate)],
      errorTitle: getMessage('COLLECTION_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export interface IGetCollectionDriveData {
  collectionId: string
  enabled?: boolean
  itemId?: string
  itemName?: string
  type?: FilePickerType
  onSuccess?: (data: IDriveData) => void
}
export const useGetCollectionDriveData = ({
  collectionId,
  enabled,
  itemId,
  itemName,
  type,
  onSuccess,
}: IGetCollectionDriveData) => {
  const navigate = useNavigate()

  return useQueryWithErrorHandler<IDriveData, AxiosError>(
    ['collection-drive-data', { collectionId }],
    async () => {
      const response = await api.get(`collections/${collectionId}/drive-data`)

      if (!response) {
        return null
      }

      return response.data
    },
    {
      enabled,
      onSuccess: data => onSuccess && onSuccess(data),
      oauthOptions: {
        onCancel: () => {
          navigate(-1)
        },
      },
      filePickerOptions: {
        itemId,
        itemName,
        type,
        onCancel: () => {
          navigate(-1)
        },
      },
      errorTitle: getMessage('COLLECTION_DRIVE_DATA_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export type ICollectionUpdateRequest = Partial<Destination> & { id: string; updatedAt: string }

export const useUpdateCollection = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const queryFn = async ({ id, ...collectionSettings }: ICollectionUpdateRequest) => {
    const result = await api.patch(`/collections/${id}`, JSON.stringify(collectionSettings))
    return result.data
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess: (data: ICollection) => {
      onSuccess && onSuccess()
      queryClient.invalidateQueries(['collection', { collectionId: data.id }])
      queryClient.invalidateQueries(['collection-drive-data', { collectionId: data.id }])
      queryClient.invalidateQueries(['collections'])
      const templateDestinationId = data.collectionVariantTemplate?.destinationId
      queryClient.invalidateQueries(['presentation', { destinationId: templateDestinationId }])
      queryClient.invalidateQueries(['presentations'])
    },
    errorTitle: getMessage('COLLECTION_UPDATE_ERROR'),
    errorHandlers: [handleCollectionOutOfDateError],
    useErrorBoundary: false,
  })
}

type ICollectionAddVariantsRequest = {
  variantsToAdd: ParsedVariant[]
  updatedAt: string
}
export const useAddCollectionVariants = (collection: ICollection) => {
  const queryFn = async ({ variantsToAdd, updatedAt }: ICollectionAddVariantsRequest) => {
    const result = await api.post(
      `/collections/${collection.id}/variants`,
      JSON.stringify({ variantsToAdd, updatedAt })
    )
    return result.data
  }

  return useMutationWithErrorHandler(queryFn, {
    insufficientPermissionOptions: {
      message: getMessage('COLLECTION_INSUFFICIENT_PERMISSIONS_ERROR'),
    },
    errorTitle: getMessage('COLLECTION_VARIANTS_UPDATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useRecreateCollectionVariants = (
  collectionId: string,
  options: UseMutationOptions<DrivePermissionApiResponse, AxiosError, any> = {}
) => {
  const queryFn = async ({ withUpdate }: { withUpdate: boolean }) => {
    const result = await api.post(`/collections/${collectionId}/variants/regenerate`, {
      withUpdate,
    })
    return result.data
  }

  const onSuccess = (data: DrivePermissionApiResponse, variables: any, context: any) => {
    options.onSuccess && options.onSuccess(data, variables, context)
    queryClient.invalidateQueries(['collection', { collectionId: collectionId }])
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess,
    errorTitle: getMessage('COLLECTION_VARIANTS_UPDATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useRecreateCollectionVariant = () => {
  const queryFn = async ({
    collectionId,
    variantId,
    withUpdate,
  }: {
    collectionId: string
    variantId: string
    withUpdate: boolean
  }) => {
    const result = await api.post(`/collections/${collectionId}/variants/${variantId}/regenerate`, {
      withUpdate,
    })
    return result.data
  }

  const onSuccess = (data: DrivePermissionApiResponse, params: { collectionId: string; variantId: string }) => {
    queryClient.invalidateQueries(['collection', { collectionId: params.collectionId }])
  }
  return useMutationWithErrorHandler(queryFn, {
    onSuccess,
    errorTitle: getMessage('COLLECTION_VARIANT_UPDATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useCollectionSync = () => {
  const queryFn = async ({
    collection,
    selectedTemplateSyncIds,
    selectedVariantIds,
  }: {
    collection: ICollection
    selectedTemplateSyncIds?: string[]
    selectedVariantIds?: string[]
  }) => {
    const result = await api.post(
      `/collections/${collection.id}/update`,
      JSON.stringify({ selectedTemplateSyncIds, selectedVariantIds })
    )
    return result.data
  }

  const onSuccess = (data: DrivePermissionApiResponse, { collection }: { collection: ICollection }) => {
    invalidateCollectionVariants(collection)
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess,
    errorTitle: getMessage('COLLECTION_SYNC_ERROR'),
    useErrorBoundary: false,
  })
}

export const useCollectionArchiveSync = () => {
  const queryFn = async ({ collection }: { collection: ICollection }) => {
    const result = await api.post(`/collections/${collection.id}/archive-update`)
    return result.data
  }

  const onSuccess = (data: DrivePermissionApiResponse, { collection }: { collection: ICollection }) => {
    queryClient.invalidateQueries(['collections'])
    queryClient.invalidateQueries(['collection', { collectionId: collection.id }])
    invalidateCollectionVariants(collection)
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess,
    errorTitle: getMessage('COLLECTION_ARCHIVE_SYNC_ERROR'),
    useErrorBoundary: false,
  })
}

export const useCollectionCancelUpdate = () => {
  return useMutation((collection: ICollection) => api.post(`/collections/${collection.id}/cancel-update`), {
    onSuccess: async (_, collection) => {
      await queryClient.invalidateQueries(['collections'])
      await queryClient.invalidateQueries(['collection', { collectionId: collection.id }])
      await invalidateCollectionVariants(collection)
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('COLLECTION_CANCEL_UPDATE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useAddCollection = () => {
  return useMutationWithErrorHandler<DrivePermissionApiResponse<ICollection>, AxiosError, IAddCollectionRequest, any>(
    async collection => {
      const result = await api.post('/collections', JSON.stringify(collection))
      return result.data
    },
    {
      onSuccess: (data: DrivePermissionApiResponse<ICollection>) => {
        queryClient.invalidateQueries(['collections'])
        queryClient.invalidateQueries(['presentations'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('COLLECTION_ADD_ERROR'),
          message: error.message,
        })
      },
      insufficientPermissionOptions: {
        message: getMessage('COLLECTION_INSUFFICIENT_PERMISSIONS_ERROR'),
      },
      useErrorBoundary: false,
    }
  )
}

export const useDeleteCollection = () => {
  return useMutation((id: string) => api.delete(`/collections/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['collections'])
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('COLLECTION_DELETE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useDuplicateOutside = (
  options: UseMutationWithErrorHandlerOptions<IDuplicateVariantOutsideResponse, AxiosError, any, any> = {}
) => {
  const queryFn = async ({ id, name, collectionId }: { id: string; name: string; collectionId: string }) => {
    const result = await api.post(`/collections/${collectionId}/variants/${id}/duplicate-outside`, { name })
    return result.data
  }

  const onSuccess = (data: IDuplicateVariantOutsideResponse) => {
    queryClient.invalidateQueries(['presentations'])
    const failedVisNames = data.variants
      .filter(visResult => visResult.status === 'FAILED')
      .map(visResult => visResult.name)
    if (failedVisNames.length) {
      duplicateDestinationPartialSuccessToast(failedVisNames)
    }
  }

  return useMutationWithErrorHandler<IDuplicateVariantOutsideResponse, AxiosError>(queryFn, {
    filePickerOptions: options.filePickerOptions,
    onSuccess: (data, variables, context) => {
      options.onSuccess && options.onSuccess(data, variables, context)
      onSuccess(data)
    },
    errorTitle: getMessage('COLLECTION_DUPLICATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useDeleteVariant = () => {
  return useMutation(
    ({ collectionId, variantId }: { collectionId: string; variantId: string }) =>
      api.delete(`/collections/${collectionId}/variants/${variantId}`),
    {
      onSuccess: async (_, { collectionId }) => {
        await queryClient.invalidateQueries(['collection', { collectionId }])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('COLLECTION_VARIANTS_DELETE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export interface ShareCollectionParams {
  data: {
    email: string
    permission: SharingCollectionPermissions
  }[]
}

export const useShareCollection = (collectionId: string) => {
  const { data: collection } = useGetCollectionById({ collectionId })

  return useMutationWithErrorHandler<CollaborationApiResponse<{}>, AxiosError, ShareCollectionParams, any>(
    async ({ data }: ShareCollectionParams) => {
      const response = await api.post(`/collections/${collectionId}/share`, JSON.stringify(data))
      return response.data
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['collection', { collectionId }])
        if (collection) {
          await invalidateCollectionVariants(collection)
        }
        if (!data.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast(
            `We couldn't share the collection in your Drive. Please make sure that the following users have access to the collection's folder. Emails: ${variables.data.map(({ email }) => email).join(',')}`
          )
        }
        return data
      },
      insufficientPermissionOptions: {
        message: getMessage('COLLECTION_INSUFFICIENT_PERMISSIONS_ERROR'),
      },
      useErrorBoundary: false,
    }
  )
}

export interface UpdateShareCollectionParams {
  data: {
    userId: number
    permission: SharingCollectionPermissions
  }
}
export const useUpdateSharePermission = (id: string) => {
  return useMutationWithErrorHandler<CollaborationApiResponse<{}>, AxiosError, UpdateShareCollectionParams, any>(
    async ({ data }: UpdateShareCollectionParams) => {
      const response = await api.patch(`/collections/${id}/share/${data.userId}`, JSON.stringify(data))
      return response.data
    },
    {
      onSuccess: result => {
        queryClient.invalidateQueries(['collection', { collectionId: id }])
        queryClient.invalidateQueries(['shared-collections'])
        if (!result.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast()
        }
      },
      insufficientPermissionOptions: {
        message: getMessage('COLLECTION_INSUFFICIENT_PERMISSIONS_ERROR'),
      },
      useErrorBoundary: false,
    }
  )
}

export interface RevokeShareCollectionParams {
  userId: number
}
export const useRevokeSharePermission = (id: string) => {
  return useMutationWithErrorHandler<CollaborationApiResponse<{}>, AxiosError, RevokeShareCollectionParams, any>(
    async ({ userId }: RevokeShareCollectionParams) => {
      const response = await api.delete(`/collections/${id}/share/${userId}`)
      return response.data
    },
    {
      onSuccess: result => {
        queryClient.invalidateQueries(['collection', { collectionId: id }])
        queryClient.invalidateQueries(['shared-collections'])
        if (!result.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast()
        }
      },
      insufficientPermissionOptions: {
        message: getMessage('COLLECTION_INSUFFICIENT_PERMISSIONS_ERROR'),
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetCollectionEmailConfig = (
  collectionId?: string,
  options: UseQueryWithErrorHandlerOptions<ICollectionEmailConfig, AxiosError> = {}
) => {
  return useQueryWithErrorHandler<ICollectionEmailConfig, AxiosError>(
    ['collection-email-config', { collectionId }],
    async () => {
      if (!collectionId) {
        return null
      }

      const response = await api.get(`collections/${collectionId}/email-configs`)

      return response.data
    },
    {
      errorTitle: getMessage('COLLECTION_EMAIL_CONFIG_GET_ERROR'),
      useErrorBoundary: false,
      enabled: options.enabled,
    }
  )
}

export const useAddCollectionEmailConfig = (
  collectionId?: string,
  options: UseMutationWithErrorHandlerOptions<
    ICollectionEmailConfig,
    AxiosError,
    ICollectionEmailConfigCreateRequest,
    any
  > = {}
) => {
  return useMutationWithErrorHandler<ICollectionEmailConfig, AxiosError, ICollectionEmailConfigCreateRequest, any>(
    async data => {
      if (!collectionId) {
        return null
      }
      const result = await api.post(`/collections/${collectionId}/email-configs`, JSON.stringify(data))
      return result.data
    },
    {
      onSuccess: async (...args) => {
        if (options.onSuccess) {
          await options.onSuccess(...args)
        }
        await queryClient.invalidateQueries(['collection-email-config', { collectionId }])
      },
      errorTitle: getMessage('COLLECTION_EMAIL_CONFIG_ADD_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useUpdateCollectionEmailConfig = (
  collectionId?: string,
  options: UseMutationWithErrorHandlerOptions<
    ICollectionEmailConfig,
    AxiosError,
    IUpdateCollectionEmailConfigVariables,
    any
  > = {}
) => {
  return useMutationWithErrorHandler<ICollectionEmailConfig, AxiosError, IUpdateCollectionEmailConfigVariables, any>(
    async ({ emailConfigId, data }) => {
      if (!collectionId) {
        return null
      }
      const result = await api.patch(
        `/collections/${collectionId}/email-configs/${emailConfigId}`,
        JSON.stringify(data)
      )
      return result.data
    },
    {
      onSuccess: async (...args) => {
        if (options.onSuccess) {
          await options.onSuccess(...args)
        }
        await queryClient.invalidateQueries(['collection-email-config', { collectionId }])
      },
      errorTitle: getMessage('COLLECTION_EMAIL_CONFIG_UPDATE_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useSendTestEmail = (
  collectionId: string,
  options: UseMutationWithErrorHandlerOptions<any, AxiosError, ICollectionTestEmailRequest, any> = {}
) => {
  return useMutationWithErrorHandler(
    data => api.post(`/collections/${collectionId}/send-test-email`, JSON.stringify(data)),
    {
      onSuccess: options.onSuccess,
      errorTitle: getMessage('COLLECTION_SEND_TEST_EMAIL_FAILED'),
      useErrorBoundary: false,
    }
  )
}

export const useSendCollectionEmail = (
  collectionId: string,
  options: UseMutationWithErrorHandlerOptions<ICollectionSendEmailResponse, AxiosError, any, any> = {}
) => {
  return useMutationWithErrorHandler<ICollectionSendEmailResponse, AxiosError, any, any>(
    async () => {
      const result = await api.post(`/collections/${collectionId}/send-email`)
      return result.data
    },
    {
      onSuccess: (data, variables, context) => {
        if (!data.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast()
        }
        options.onSuccess && options.onSuccess(data, variables, context)
      },
      errorTitle: getMessage('COLLECTION_SEND_EMAIL_FAILED'),
      useErrorBoundary: false,
    }
  )
}
