import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 20

export const SwitchHorizontal: FC<IIcon> = ({ color = 'currentColor', size = defaultSize }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M20 17H4M4 17L8 13M4 17L8 21M4 7H20M20 7L16 3M20 7L16 11"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
