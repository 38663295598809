import { FC, useEffect, useState } from 'react'

import { UsersPlusIcon } from '@assets/icons/users'

import { RevokeShareCollectionParams, ShareCollectionParams, UpdateShareCollectionParams } from '@features/collections'
import { ICollection, SharingCollectionPermissions, UserSharedCollection } from '@features/collections/types'
import { isOAuthMissingError } from '@features/drive/error-handler'
import { useGetOrganizationUsers, useOrganizationsStore } from '@features/organizations'
import { OrganizationUser } from '@features/organizations/types'

import { ActionModal, Avatar, Button, SelectInput } from '@shared/components'
import { CollaborationApiResponse } from '@shared/types'

import styles from './share-modal.module.css'

const sharingOptions = [
  { value: SharingCollectionPermissions.admin, label: 'Admin' },
  { value: SharingCollectionPermissions.contributor, label: 'Contributor' },
]

type CollectionModalProps = {
  collection: ICollection | undefined
  open: boolean
  isInviteLoading?: boolean
  isUpdatePermissionsLoading?: boolean
  isRevokePermissionLoading?: boolean
  toggle: () => void
  shareItem: (params: ShareCollectionParams) => Promise<CollaborationApiResponse<{}>>
  updatePermissions: (params: UpdateShareCollectionParams) => Promise<CollaborationApiResponse<{}>>
  revokePermissions: (params: RevokeShareCollectionParams) => Promise<CollaborationApiResponse<{}>>
}

type PermissionChangeAction = (typeof sharingOptions)[number]['value']

export const CollectionShareModal: FC<CollectionModalProps> = ({
  collection,
  open,
  isInviteLoading,
  isUpdatePermissionsLoading,
  isRevokePermissionLoading,
  toggle,
  shareItem,
  updatePermissions,
  revokePermissions,
}) => {
  const { activeOrganization } = useOrganizationsStore()
  const { data: organizationMembers } = useGetOrganizationUsers(activeOrganization?.organizationId)
  const [emailList, setEmailList] = useState<OrganizationUser[]>([])

  const [selectedUsers, setSelectedUsers] = useState<OrganizationUser[]>([])
  const [error, setError] = useState('')
  const [updatedUserId, setUpdatedUserId] = useState<number | null>(null)
  const [userPermissions, setUserPermissions] = useState<UserSharedCollection[]>([])
  const [selectedSharingOption, setSelectedSharingOption] = useState<(typeof sharingOptions)[number]>(sharingOptions[0])

  const handleInviteClick = async () => {
    setError('')
    const shareItemPayload = selectedUsers.map(user => ({
      email: user.email,
      permission: selectedSharingOption.value,
    }))

    try {
      await shareItem({
        data: shareItemPayload,
      })
    } catch (e: any) {
      if (isOAuthMissingError(e)) {
        return
      }
      setError(e?.response?.data?.message || e?.message)
    } finally {
      reset()
    }
  }

  const changeUserPermissions = async (userPermissionIdx: number, permissionChangeAction?: PermissionChangeAction) => {
    if (!permissionChangeAction) return

    const userId = userPermissions[userPermissionIdx].userId
    setUpdatedUserId(userId)

    setError('')

    try {
      const updatePermissionPayload = {
        userId,
        permission: permissionChangeAction,
      }
      await updatePermissions({
        data: updatePermissionPayload,
      })

      setUserPermissions(prev => {
        const newPermissions = [...prev]
        newPermissions[userPermissionIdx].permission = permissionChangeAction
        return newPermissions
      })
    } catch (e: any) {
      if (isOAuthMissingError(e)) {
        return
      }
      setError(e?.response?.data?.message || e?.message)
    } finally {
      setUpdatedUserId(null)
    }
  }

  const handleRemove = async (userId: any) => {
    setUpdatedUserId(userId)
    try {
      await revokePermissions({ userId })
    } catch (e: any) {
      if (isOAuthMissingError(e)) {
        return
      }
      setError(e?.response?.data?.message || e?.message)
    } finally {
      setUpdatedUserId(null)
    }
  }

  const reset = () => {
    setSelectedUsers([])
    setSelectedSharingOption(sharingOptions[0])
  }

  useEffect(() => {
    if (collection) {
      setUserPermissions(collection.sharedWith || [])
    }
  }, [collection])

  useEffect(() => {
    if (!organizationMembers || !activeOrganization || !collection) {
      return
    }

    const { userId: activeUserId } = activeOrganization
    const sharedWithUserIds = new Set((collection.sharedWith || []).map(member => member.userId))

    const isEligibleMember = (member: OrganizationUser) => {
      return (
        member.userId !== collection.userId &&
        member.userId !== activeUserId &&
        member.userId &&
        !sharedWithUserIds.has(member.userId)
      )
    }

    const updatedEmailList = organizationMembers.filter(isEligibleMember)
    setEmailList(updatedEmailList)
  }, [organizationMembers, activeOrganization, collection])

  useEffect(() => {
    reset()
    setError('')
  }, [])

  if (!collection) return null

  return (
    <ActionModal
      open={open}
      onBackgroundClick={toggle}
      className={'max-w-[700px]'}
      Icon={UsersPlusIcon}
      title={`Share ${collection.name}`}
      subtitle="The following users have access:"
    >
      {error && <p className={styles.error}>{error}</p>}

      <div className="flex flex-col gap-3">
        <div className="text-sm font-medium flex flex-row items-center w-full justify-between">
          <Avatar name={collection.user.name}>
            <div className="flex flex-col">
              <span className="font-semibold text-sm">{collection.user.name}</span>
              <span className="font-normal text-xs">{collection.user.email}</span>
            </div>
          </Avatar>
          <p className={styles.owner}>Owner</p>
        </div>
        {collection.sharedWith
          ?.filter(({ user }) => user)
          ?.sort((a, b) => a.userId - b.userId)
          ?.map(({ user }, idx) => (
            <div key={user.id} className="text-sm font-medium flex flex-row items-center w-full justify-between">
              <div>
                <Avatar name={user.name}>
                  <div className="flex flex-col">
                    <span className="font-semibold text-sm">{user.name}</span>
                    <span className="font-normal text-xs">{user.email}</span>
                  </div>
                </Avatar>
              </div>
              <div className={styles.roleAndAction}>
                <SelectInput
                  className={styles.roleSelect}
                  value={sharingOptions.find(sharingOption => sharingOption.value === userPermissions[idx]?.permission)}
                  options={sharingOptions}
                  onChange={option => changeUserPermissions(idx, option?.value)}
                  isDisabled={user.id === updatedUserId || isUpdatePermissionsLoading}
                />
                <Button
                  text="Remove"
                  onClick={() => handleRemove(user.id)}
                  loading={user.id === updatedUserId || isRevokePermissionLoading}
                  tertiaryError
                />
              </div>
            </div>
          ))}
      </div>
      <p className={styles.addUserTitle}>Add another user</p>
      <div className={styles.addAnotherUser}>
        <SelectInput
          isMulti
          isSearchable
          className={styles.userSelect}
          value={selectedUsers}
          options={emailList}
          onChange={option => setSelectedUsers(option)}
          getOptionLabel={option => option.email}
          getOptionValue={option => option.email}
          placeholder="Select user"
        />
        <div className={styles.roleAndAction}>
          <SelectInput
            className={styles.roleSelect}
            value={selectedSharingOption}
            options={sharingOptions}
            onChange={option => setSelectedSharingOption(option || sharingOptions[0])}
            isDisabled={!selectedUsers.length}
          />
          <Button
            text="Add user"
            onClick={handleInviteClick}
            disabled={!selectedUsers.length}
            loading={isInviteLoading}
            tertiaryColor
          />
        </div>
      </div>
    </ActionModal>
  )
}
