import { FC } from 'react'

import { IIcon } from '@assets/types'

export const RefreshSingleArrowIcon: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50098 8.49935C2.50098 8.49935 4.17179 6.22286 5.52917 4.86455C6.88655 3.50624 8.76231 2.66602 10.8343 2.66602C14.9764 2.66602 18.3343 6.02388 18.3343 10.166C18.3343 14.3082 14.9764 17.666 10.8343 17.666C7.41507 17.666 4.53024 15.3779 3.62745 12.2493M2.50098 8.49935V3.49935M2.50098 8.49935H7.50098"
        stroke={color}
        strokeWidth="1.36095"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
