import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { useGetDestinations, useGetSharedDestinations } from '@features/destinations'
import { useAuthStore } from '@features/users'

import { useFilter } from '@shared/hooks/use-filter'
import { useSort } from '@shared/hooks/use-sort'

export const useDestinations = () => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const { user } = useAuthStore()

  const { sortingOption, isAscendingOrder, handleSortTypeChange, handleSortOrderChange } = useSort(
    'destinationsSortingType',
    'isDestinationsAscendingOrder'
  )

  const { filteringOption, handleFilterTypeChange } = useFilter('destinationsFilteringType')

  const { data: personalDestinations, isLoading: personalDestinationsLoading } = useGetDestinations()
  const { data: sharedDestinations, isLoading: sharedDestinationsLoading } = useGetSharedDestinations()

  const destinations =
    personalDestinations && sharedDestinations ? [...personalDestinations, ...sharedDestinations] : undefined
  const isLoading = personalDestinationsLoading || sharedDestinationsLoading

  let sortedAndFiltered = destinations
    ? destinations
        .filter(destination => destination.name.toLowerCase()?.includes(searchQuery.toLowerCase()))
        .filter(filteringOption.appliedFilter(user!))
        .sort(sortingOption.sorter)
    : []
  if (isAscendingOrder!) sortedAndFiltered = sortedAndFiltered.reverse()

  const handleAddDestination = () => navigate(Paths.ADD_DESTINATION)

  return {
    isLoading,
    destinations: sortedAndFiltered,
    sortingOption,
    isAscendingOrder,
    handleAddDestination,
    setSearchQuery,
    handleSortTypeChange,
    handleSortOrderChange,
    filteringOption,
    handleFilterTypeChange,
  }
}
