const nestingDepthTwo = 2
type NestingDepthTwo = typeof nestingDepthTwo

export type NestedKeyOf<T, K = keyof T> = K extends keyof T & (string | number)
  ? `${K}` | (T[K] extends object | null | undefined ? `${K}.${NestedKeyOf<T[K]>}` : never)
  : never

export type NestedNullableKeyOf<T, K = keyof T, Depth extends number = NestingDepthTwo> = K extends keyof T &
  (string | number)
  ? Depth extends 1 | NestingDepthTwo
    ?
        | `${K}`
        | (T[K] extends object | null | undefined
            ? Depth extends NestingDepthTwo
              ? `${K}.${NestedNullableKeyOf<NonNullable<T[K]>, keyof NonNullable<T[K]>, 1>}`
              : never
            : never)
    : never
  : never

export const resolveNestedKey = <T extends { [key: string]: any }>(
  path: NestedKeyOf<T>,
  obj: T,
  separator = '.'
): string | number | object => {
  const properties = path.split(separator)
  return properties.reduce((prev, curr) => prev[curr], obj)
}

export const resolveNestedNullableKey = <T extends { [key: string]: any }>(
  path: NestedNullableKeyOf<T>,
  obj: T,
  separator = '.'
): string | number | object => {
  const properties = path.split(separator)
  return properties.reduce((prev, curr) => {
    if (!prev) return ''
    if (typeof prev !== 'object') return ''
    if (Array.isArray(prev)) return ''
    if (!prev.hasOwnProperty(curr)) return ''
    return prev[curr]
  }, obj)
}
