import { useContext } from 'react'

import { EmailTemplatePreview } from '@features/emails/components/templates/editors/email-template-preview'
import { EmailTemplateRichTextEditor } from '@features/emails/components/templates/editors/email-template-rich-text-editor'
import { CustomEmailTemplateVariable, EmailTemplate } from '@features/emails/types'

import { Input, Label } from '@shared/components'
import { useBoolean } from '@shared/hooks'
import { cn } from '@shared/utils'

import { EmailTemplateHTMLEditor } from './editors/email-template-html-editor'
import { EmailTemplateEditorContext } from './providers/email-template-editor-provider'
import { EditTemplateSettings } from './settings/edit-template-settings'

export interface EmailTemplateEditorProps {
  recipients?: string[]
  variables?: CustomEmailTemplateVariable[]
  showNameField?: boolean
}

export const EmailTemplateEditor = ({ recipients, variables = [], showNameField = true }: EmailTemplateEditorProps) => {
  const { template, setTemplate, destinationType } = useContext(EmailTemplateEditorContext)
  const [htmlEditorMode, toggleHTMLEditorMode] = useBoolean(false)

  const onEditHandler = (newTemplate: EmailTemplate) => {
    setTemplate?.(newTemplate)
  }

  return (
    <div className="min-h-fit h-fit flex gap-5">
      <div className="min-h-fit h-fit w-1/2 pt-0 flex flex-col gap-5">
        <div className={cn('flex flex-col gap-5', !showNameField && variables?.length === 0 && 'hidden')}>
          {showNameField && (
            <div className="flex flex-col gap-1">
              <Label sm medium>
                Name
              </Label>
              <Input
                placeholder="Name"
                value={template.name}
                onChange={e => {
                  onEditHandler({ ...template, name: e.target.value })
                }}
              />
            </div>
          )}

          {variables?.length > 0 && (
            <div>
              <Label sm regular>
                These fields can be used in the email subject and template:
              </Label>

              {variables.map(variable => {
                return (
                  <Label key={variable.id} sm regular className={cn(variable.disabled && 'hidden')}>
                    • <Label sm semibold className="inline text-primary">{`{{${variable.id}}}`}</Label>:{' '}
                    {variable.description}
                  </Label>
                )
              })}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            <Label sm medium>
              Subject
            </Label>
            <Input
              placeholder="Subject"
              value={template.subject}
              onChange={e => onEditHandler({ ...template, subject: e.target.value })}
            />
          </div>
          <EditTemplateSettings template={template} type={destinationType} setTemplate={setTemplate} />

          <div className="h-fit flex flex-col gap-1">
            <Label sm medium>
              Body
            </Label>
            {htmlEditorMode ? (
              <EmailTemplateHTMLEditor
                content={template.content}
                setContent={(content: string) => onEditHandler({ ...template, content })}
                toggleHTMLEditorMode={toggleHTMLEditorMode}
              />
            ) : (
              <EmailTemplateRichTextEditor
                key={template.id}
                templateId={template.id}
                content={template.content}
                setContent={(content: string) => onEditHandler({ ...template, content })}
                toggleHTMLEditorMode={toggleHTMLEditorMode}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-1/2">
        <EmailTemplatePreview recipients={recipients} template={template} variables={variables} />
      </div>
    </div>
  )
}
