import classNames from 'classnames'
import { FC } from 'react'

import { FileIcon } from '@assets/icons/file'

import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'
import { IntegrationDashboard } from '@features/syncs/types'
import { getIntegrationDashboardName, isIntegrationDashboardDisabled } from '@features/syncs/utils'

import { FileComponentProps } from '@shared/components'
import { SelectableOption } from '@shared/components/selectable-options/selectable-options'

import styles from './dashboard-file.module.css'

export const DashboardFile: FC<FileComponentProps<IntegrationDashboard>> = ({ file: dashboard, folderName }) => {
  const { dashboardSelected, updateVanityData, updateSyncData, clearVisSelectData } = useSyncSettingsContext()

  if (!dashboard) return null

  const handleClick = () => {
    if (dashboard.id !== dashboardSelected?.id) {
      updateSyncData({ dashboardSelected: dashboard })
      clearVisSelectData()
    }

    updateVanityData({ showDashboardModal: false })
  }

  const dashboardName = getIntegrationDashboardName(dashboard)
  const isDashDisabled = isIntegrationDashboardDisabled(dashboard)

  const name = isDashDisabled ? `[Embed disabled] ${dashboardName}` : dashboardName
  const containerClass = classNames(styles.container, isDashDisabled && styles.disabled)

  return (
    <SelectableOption
      className={containerClass}
      disabled={isDashDisabled}
      selected={dashboard.id === dashboardSelected?.id}
      onClick={handleClick}
    >
      <FileIcon />
      {name}
      {folderName && <div className="text-xs-medium">({folderName})</div>}
    </SelectableOption>
  )
}
