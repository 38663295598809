import { FC } from 'react'

import { IIcon } from '@assets/types'

export const PresentationPlaceholderIcon: FC<IIcon> = ({ size = '210' }) => {
  return (
    <svg width={size} viewBox="0 0 210 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M210 0.539062V10.1394C172.631 10.1394 164.77 10.1394 106.78 10.1394C48.789 10.1394 60.512 10.1394 0 10.1394V0.539062C44.495 0.539062 48.789 0.539062 106.78 0.539062C164.77 0.539062 165.508 0.539062 210 0.539062Z"
          fill="#7DB0DE"
        />
        <rect y="9.97266" width="210" height="110.565" fill="#DFEAF4" />
        <path
          d="M137.969 67.7402C139.361 67.7402 140.489 68.8685 140.489 70.2603V92.821C140.489 94.2128 139.361 95.3411 137.969 95.3411C136.972 95.3411 135.87 95.3411 133.707 95.3411C131.023 95.3411 130.612 95.3411 129.213 95.3411C127.821 95.3411 126.691 94.2128 126.691 92.821V70.2603C126.691 68.8685 127.82 67.7402 129.212 67.7402C130.335 67.7402 131.34 67.7402 133.707 67.7402C136.018 67.7402 136.945 67.7402 137.969 67.7402Z"
          fill="#7DB0DE"
        />
        <path
          d="M83.3034 62.5664C83.9993 62.5664 84.5639 63.1305 84.5639 63.8264V66.6998C84.5639 67.3957 83.9962 67.9598 83.3003 67.9598C72.5725 67.9598 69.6243 67.9598 52.0044 67.9598C34.1299 67.9598 37.2525 67.9598 19.595 67.9598C18.8991 67.9598 18.3223 67.3957 18.3223 66.6998V63.8264C18.3223 63.1305 18.8869 62.5664 19.5828 62.5664C32.457 62.5664 34.275 62.5664 52.0044 62.5664C69.7324 62.5664 70.4981 62.5664 83.3034 62.5664Z"
          fill="#7DB0DE"
        />
        <path
          d="M83.3034 49.0801C83.9993 49.0801 84.5639 49.6442 84.5639 50.3401V53.2135C84.5639 53.9094 83.9962 54.4735 83.3003 54.4735C72.5725 54.4735 69.6243 54.4735 52.0044 54.4735C34.1299 54.4735 37.2525 54.4735 19.595 54.4735C18.8991 54.4735 18.3223 53.9094 18.3223 53.2135V50.3401C18.3223 49.6442 18.8869 49.0801 19.5828 49.0801C32.457 49.0801 34.275 49.0801 52.0044 49.0801C69.7324 49.0801 70.4981 49.0801 83.3034 49.0801Z"
          fill="#7DB0DE"
        />
        <path
          d="M139.662 22.1152C140.358 22.1152 140.94 22.6794 140.94 23.3753V26.2486C140.94 26.9445 140.364 27.5087 139.668 27.5087C118.928 27.5087 113.863 27.5087 80.6701 27.5087C47.2276 27.5087 53.492 27.5087 19.611 27.5087C18.9151 27.5087 18.3223 26.9445 18.3223 26.2486V23.3753C18.3223 22.6794 18.9029 22.1152 19.5988 22.1152C44.3946 22.1152 47.3727 22.1152 80.6701 22.1152C113.966 22.1152 114.938 22.1152 139.662 22.1152Z"
          fill="#7DB0DE"
        />
        <path
          d="M83.3034 76.0508C83.9993 76.0508 84.5639 76.6149 84.5639 77.3108V80.1842C84.5639 80.8801 83.9962 81.4442 83.3003 81.4442C72.5725 81.4442 69.6243 81.4442 52.0044 81.4442C34.1299 81.4442 37.2525 81.4442 19.595 81.4442C18.8991 81.4442 18.3223 80.8801 18.3223 80.1842V77.3108C18.3223 76.6149 18.8869 76.0508 19.5828 76.0508C32.457 76.0508 34.275 76.0508 52.0044 76.0508C69.7324 76.0508 70.4981 76.0508 83.3034 76.0508Z"
          fill="#7DB0DE"
        />
        <path
          d="M83.3034 89.5332C83.9993 89.5332 84.5639 90.0973 84.5639 90.7932V93.6666C84.5639 94.3625 83.9962 94.9266 83.3003 94.9266C72.5725 94.9266 69.6243 94.9266 52.0044 94.9266C34.1299 94.9266 37.2525 94.9266 19.595 94.9266C18.8991 94.9266 18.3223 94.3625 18.3223 93.6666V90.7932C18.3223 90.0973 18.8869 89.5332 19.5828 89.5332C32.457 89.5332 34.275 89.5332 52.0044 89.5332C69.7324 89.5332 70.4981 89.5332 83.3034 89.5332Z"
          fill="#7DB0DE"
        />
        <path
          d="M164.309 54.541C165.701 54.541 166.83 55.6693 166.83 57.0611V92.8222C166.83 94.214 165.701 95.3422 164.31 95.3422C163.156 95.3422 161.957 95.3422 159.431 95.3422C156.361 95.3422 156.017 95.3422 154.299 95.3422C152.907 95.3422 151.777 94.214 151.777 92.8222V57.0611C151.777 55.6693 152.906 54.541 154.298 54.541C155.622 54.541 156.689 54.541 159.431 54.541C162.123 54.541 163.093 54.541 164.309 54.541Z"
          fill="#7DB0DE"
        />
        <path
          d="M189.16 40.9902C190.551 40.9902 191.68 42.1185 191.68 43.5103V92.821C191.68 94.2128 190.552 95.3411 189.16 95.3411C188.127 95.3411 187.001 95.3411 184.752 95.3411C181.975 95.3411 181.58 95.3411 180.107 95.3411C178.715 95.3411 177.586 94.2128 177.586 92.821V43.5103C177.586 42.1185 178.714 40.9902 180.106 40.9902C181.276 40.9902 182.296 40.9902 184.752 40.9902C187.153 40.9902 188.092 40.9902 189.16 40.9902Z"
          fill="#7DB0DE"
        />
      </g>
    </svg>
  )
}
