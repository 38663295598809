import { ColumnDef } from '@tanstack/react-table'

import { TrashIcon } from '@assets'

import { EditUserRole, OrganizationInviteBadge } from '@features/organizations/components'
import { OrganizationUser, UserOrganizationRole, UserStatus } from '@features/organizations/types'

import { Avatar, Button } from '@shared/components'

interface RowData {
  id: string
  name: string
  email: string
  status: UserStatus
  role: UserOrganizationRole
  member: OrganizationUser
}

export const organizationMembersTableData = (members: OrganizationUser[]): RowData[] =>
  members.map(member => ({
    id: member.userId?.toString()!,
    name: member.name!,
    email: member.email,
    status: member.status,
    role: member.role,
    member: member,
  }))

interface organizationMembersTableColumnsProps {
  userId: number
  canDeleteUsers: boolean | undefined
  organizationId: string | undefined
  startRemoveMemberProcess: (email: string) => void
}

export const organizationMembersTableColumns = ({
  userId,
  canDeleteUsers,
  organizationId,
  startRemoveMemberProcess,
}: organizationMembersTableColumnsProps): ColumnDef<RowData>[] => [
  {
    accessorKey: 'name',
    header: 'Name',
    size: 200,
    cell: ({ row }) => <Avatar name={row.original.name}>{row.original.name}</Avatar>,
    enableSorting: true,
  },
  {
    accessorKey: 'email',
    header: 'Email',
    size: 200,
    cell: info => info.getValue(),
    enableSorting: true,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 100,
    cell: ({ row }) => <OrganizationInviteBadge status={row.original.status as UserStatus} />,
    enableSorting: true,
  },
  {
    accessorKey: 'role',
    header: 'Role',
    size: 100,
    cell: ({ row }) => organizationId && <EditUserRole organizationId={organizationId} member={row.original.member} />,
    enableSorting: true,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    size: 100,
    cell: ({ row }) =>
      canDeleteUsers &&
      row.original.id !== userId.toString() && (
        <Button
          tertiaryError
          iconLeading={<TrashIcon />}
          onClick={() => startRemoveMemberProcess(row.original.email)}
        />
      ),
  },
]
