import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'

import { ExpiredIcon, SyncImagePlaceholder } from '@assets'

import { Tooltip } from '@shared/components'

import styles from './image.module.css'

type ImageDimensions = {
  width?: number | string
  height?: number | string
}

type ImageWithFallbackProps = {
  src?: string | null | undefined
  alt?: string
  className?: string
  disablePosthogCapture?: boolean
  imageSize?: ImageDimensions
  placeholderSize?: ImageDimensions
  fallback?: React.ReactNode
  dataTestId?: string
  showTooltip?: boolean
  onError?: (hasError: boolean) => void
  style?: React.CSSProperties
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src = '',
  alt = 'Destination Preview',
  className,
  imageSize,
  placeholderSize,
  fallback,
  dataTestId,
  showTooltip,
  style,
  onError,
  disablePosthogCapture = false,
}) => {
  const [imageHasError, setImageHasError] = useState(false)
  const placeholderClass = classNames(styles.placeholder, className)
  const iconSize = useMemo(() => placeholderSize ?? imageSize, [placeholderSize, imageSize])

  const onImageLoadError = (e: unknown) => {
    console.error('Image load error:', e)
    onError?.(true)
    setImageHasError(true)
  }

  useEffect(() => {
    setImageHasError(false)
  }, [src])

  if (imageHasError) {
    return (
      <div className={placeholderClass} style={style}>
        <Tooltip
          title="Image preview is no longer available per our security policy. Please run another update to access the image."
          show={showTooltip}
        >
          {fallback ? (
            fallback
          ) : (
            <ExpiredIcon className={placeholderClass} height={iconSize?.height} width={iconSize?.width} />
          )}
        </Tooltip>
      </div>
    )
  }

  if (src) {
    return (
      <div className={styles.placeholder}>
        <img
          alt={alt}
          className={classNames(className,disablePosthogCapture ? 'ph-no-capture' : '')}
          data-testid={dataTestId}
          height={imageSize?.height}
          key={src}
          onError={onImageLoadError}
          src={src}
          width={imageSize?.width}
          style={style}
        />
      </div>
    )
  }

  return <div className={styles.placeholder}>{fallback ? fallback : <SyncImagePlaceholder />}</div>
}
