import React, { useState } from 'react'

const dragTimeout = 50
const dragMinDistance = 10

// Only works with horizontal scroll but easily extendable to vertical
export const useDragScroll = (querySelector: string) => {
  const [isDragging, setIsDragging] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })

  const onMouseDown = (e: React.MouseEvent) => {
    setIsMouseDown(true)
    setStartPosition({ x: e.clientX, y: e.clientY })
    setPosition({ x: e.clientX, y: e.clientY })
  }

  const onMouseUp = () => {
    setTimeout(() => {
      setIsDragging(false)
      setIsMouseDown(false)
    }, dragTimeout)
  }

  const onMouseMove = (e: React.MouseEvent) => {
    if (
      isMouseDown &&
      (Math.abs(startPosition.x - e.clientX) > dragMinDistance ||
        Math.abs(startPosition.y - e.clientY) > dragMinDistance)
    ) {
      setIsDragging(true)
    }

    if (isDragging) {
      const el = document.querySelector(querySelector)

      if (!el) return

      el.scrollLeft += position.x - e.clientX
      setPosition({ x: e.clientX, y: e.clientY })
    }
  }

  const preventClickWhenDrag = (e: React.MouseEvent) => {
    if (isDragging) {
      e.stopPropagation()
    }
  }

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    preventClickWhenDrag,
  }
}
