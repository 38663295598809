import classNames from 'classnames'
import { FC, ReactNode } from 'react'

interface ILogoProps {
  className?: string
  logo: ReactNode
}
export const CompanyLogo: FC<ILogoProps> = ({ logo, className }) => {
  const logoClass = classNames('flex w-[120px] h-[40px] justify-center items-center', className)

  return <div className={logoClass}>{logo}</div>
}
