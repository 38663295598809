import { useState } from 'react'

import { useBoolean } from './use-boolean'

export const useModalWithData = <T>() => {
  const [data, setData] = useState<T>()
  const [modal, toggleModal] = useBoolean()

  const openModalWithData = (data: T) => {
    setData(data)
    toggleModal(true)
  }

  return [modal, data, toggleModal, openModalWithData] as const
}
