import { useContext } from 'react'

import { EmailTemplateEditorContext } from '@features/emails/components/templates/providers/email-template-editor-provider'

import { Button, Tooltip } from '@shared/components'
import { useBoolean } from '@shared/hooks'

export const SaveTemplateButton = ({
  upsertTemplate,
  onSaveTemplate,
  fullWidth = false,
}: {
  upsertTemplate: () => Promise<string>
  onSaveTemplate?: (templateId: string) => void
  fullWidth?: boolean
}) => {
  const { template, isTemplateValid, setTemplate } = useContext(EmailTemplateEditorContext)
  const [isLoading, setIsLoading] = useBoolean(false)
  return (
    <Tooltip
      fullWidth={fullWidth}
      show={!template.content || !template.subject}
      title={
        !template.content ? 'Add a body a to the template before saving' : 'Add a subject to the body before saving'
      }
    >
      <Button
        disabled={!isTemplateValid || isLoading}
        text="Save template"
        fullWidth={fullWidth}
        onClick={async () => {
          setIsLoading(true)
          try {
            const templateId = await upsertTemplate()
            await onSaveTemplate?.(templateId)
            setTemplate({ ...template, dirty: false })
          } finally {
            setIsLoading(false)
          }
        }}
        loading={isLoading}
      />
    </Tooltip>
  )
}
