import { FC } from 'react'

export const Logo: FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M9.88435 7V11.3188H22.8406C24.0336 11.3188 25 10.3524 25 9.15938C25 7.96637 24.0336 7 22.8406 7H9.88435Z"
        fill="url(#paint0_linear_10277_11261)"
      />
      <path
        d="M15.9926 14.9284V16.3463H11.3348V14.9284H15.9926ZM17.4431 13.478H9.88435V17.7967H17.4431V13.478Z"
        fill="url(#paint1_linear_10277_11261)"
      />
      <path
        d="M23.5494 21.4064V22.8242H18.8916V21.4064H23.5494ZM24.9999 19.9559H17.4411V24.2747H24.9999V19.9559Z"
        fill="url(#paint2_linear_10277_11261)"
      />
      <path d="M8.45047 7H7V24.2768H8.45047V7Z" fill="url(#paint3_linear_10277_11261)" />
      <defs>
        <linearGradient
          id="paint0_linear_10277_11261"
          x1="16"
          y1="7"
          x2="16"
          y2="24.2768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D9" />
          <stop offset="1" stopColor="#109FDC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10277_11261"
          x1="16"
          y1="7"
          x2="16"
          y2="24.2768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D9" />
          <stop offset="1" stopColor="#109FDC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10277_11261"
          x1="16"
          y1="7"
          x2="16"
          y2="24.2768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D9" />
          <stop offset="1" stopColor="#109FDC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10277_11261"
          x1="16"
          y1="7"
          x2="16"
          y2="24.2768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D9" />
          <stop offset="1" stopColor="#109FDC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
