import { collectionModalsAssetsUrlsBase } from '@assets'

import { useShowTutorialModals } from '@features/users'

import { TutorialModal } from '@shared/components'

interface CollectionExplainerModalProps {
  visible: boolean
  setModalOpen: (param: boolean) => void
}

export const CollectionExplainerModal = ({ visible, setModalOpen }: CollectionExplainerModalProps) => {
  const { mutateAsync: updateShowTutorialModals, isLoading } = useShowTutorialModals()

  const handleDismiss = async () => {
    if (isLoading) {
      return
    }
    await updateShowTutorialModals({
      data: {
        showCollectionExplainerModal: false,
      },
    })
    setModalOpen(false)
  }

  return (
    <TutorialModal
      visible={visible}
      onDismiss={handleDismiss}
      contents={[
        {
          imageUrl: `${collectionModalsAssetsUrlsBase}/collection-explainer-1.png`,
          title: 'Welcome to collections',
          description:
            'Collections are powerful tools that allow you to generate multiple versions of a presentation or document by varying the variables of your choice.',
        },
        {
          imageUrl: `${collectionModalsAssetsUrlsBase}/collection-explainer-2.png`,
          description: `You can use any presentation or document you have created with Rollstack to create your Collection, which willthen serve as your template.`,
        },
        {
          imageUrl: `${collectionModalsAssetsUrlsBase}/collection-explainer-3.png`,
          description:
            'Configure the variants you wish to generate, and Rollstack will automatically create these presentations or documents, updating the content with the appropriate data.',
        },
      ]}
    />
  )
}
