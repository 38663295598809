import { FC } from 'react'

import { IIcon } from '@assets/types'

export const TheOrchard: FC<IIcon> = () => {
  return (
    <svg width="60" height="22" viewBox="0 0 60 22" fill="none">
      <g clipPath="url(#clip0_16270_176210)">
        <path d="M26.538 8.63272V12.1457H25.6548V8.63272H24.4102V7.76953H27.7826V8.63272H26.538Z" fill="#093C59" />
        <path
          d="M30.3926 12.1461V10.3796C30.3926 9.79747 30.1919 9.6168 29.8305 9.6168C29.4692 9.6168 29.2685 9.79747 29.2685 10.3595V12.1461H28.4053V8.03093L29.2685 7.60938V9.09487C29.409 8.93427 29.69 8.81383 30.0313 8.81383C30.8744 8.81383 31.2357 9.39598 31.2357 10.3194V12.1662L30.3926 12.1461Z"
          fill="#093C59"
        />
        <path
          d="M34.7088 10.7808H32.7416C32.7817 11.2224 33.1029 11.4031 33.4241 11.4031C33.665 11.4031 33.8657 11.3228 33.9862 11.1822L34.6085 11.7042C34.3475 12.0454 33.8657 12.2261 33.4442 12.2261C32.5007 12.2261 31.8984 11.5636 31.8984 10.5198C31.8984 9.51607 32.5207 8.81348 33.3639 8.81348C34.2471 8.81348 34.749 9.59637 34.749 10.5198C34.7088 10.6402 34.7088 10.7205 34.7088 10.7808ZM33.3036 9.55622C33.0025 9.55622 32.8018 9.81719 32.7616 10.1384H33.8858C33.8657 9.83726 33.685 9.55622 33.3036 9.55622Z"
          fill="#093C59"
        />
        <path
          d="M21.0579 19.4526C18.9702 19.4526 17.7256 17.8266 17.7256 15.498C17.7256 13.1694 18.9702 11.5635 21.0579 11.5635C23.1456 11.5635 24.3902 13.1895 24.3902 15.498C24.3902 17.8266 23.1456 19.4526 21.0579 19.4526ZM21.0579 13.0891C19.8133 13.0891 19.3114 14.1531 19.3114 15.5181C19.3114 16.8831 19.8334 17.9471 21.0579 17.9471C22.3025 17.9471 22.8044 16.8831 22.8044 15.5181C22.8044 14.133 22.3025 13.0891 21.0579 13.0891Z"
          fill="#093C59"
        />
        <path
          d="M29.0477 15.3577C28.8068 15.1369 28.5057 14.9763 28.1644 14.9763C27.5823 14.9763 27.2009 15.2172 27.2009 16.2209V19.3324H25.6953V13.5911H27.2009V13.9726C27.4618 13.6915 27.8433 13.4707 28.3853 13.4707C28.867 13.4707 29.2484 13.6714 29.4492 13.8722L29.0477 15.3577Z"
          fill="#093C59"
        />
        <path
          d="M32.6001 19.4528C31.0945 19.4528 29.9102 18.2283 29.9102 16.4618C29.9102 14.6952 31.0745 13.4707 32.6603 13.4707C33.7443 13.4707 34.4469 14.0127 34.9287 14.7354L33.925 15.7391C33.5837 15.2573 33.2224 14.916 32.6202 14.916C31.8975 14.916 31.4358 15.5183 31.4358 16.4618C31.4358 17.3852 31.8975 17.9874 32.6202 17.9874C33.2023 17.9874 33.5837 17.7264 33.9852 17.2647L34.9488 18.1681C34.3867 18.9108 33.7443 19.4528 32.6001 19.4528Z"
          fill="#093C59"
        />
        <path
          d="M39.5465 19.3327V16.2212C39.5465 15.1975 39.2053 14.8763 38.583 14.8763C37.9607 14.8763 37.5993 15.1774 37.5993 16.1811V19.3127H36.0938V12.1261L37.5993 11.3633V13.9729C37.8603 13.6919 38.3421 13.4711 38.9443 13.4711C40.4097 13.4711 41.0722 14.4748 41.0722 16.1008V19.3327H39.5465Z"
          fill="#093C59"
        />
        <path
          d="M45.6085 19.3328V18.9514C45.3274 19.2324 44.8457 19.4533 44.3237 19.4533C43.2598 19.4533 42.1758 18.7908 42.1758 17.4057C42.1758 16.141 43.1996 15.4986 44.5044 15.4986C45.0263 15.4986 45.3676 15.6191 45.6085 15.7395V15.4585C45.6085 15.057 45.3274 14.7358 44.7854 14.7358C44.1029 14.7358 43.7416 14.8362 43.2196 15.1172L42.6375 14.0734C43.2999 13.692 43.862 13.4912 44.7854 13.4912C46.1706 13.4912 47.114 14.2139 47.114 15.4986V19.3529L45.6085 19.3328ZM45.6085 16.9239C45.3475 16.7633 45.0865 16.683 44.5646 16.683C44.0025 16.683 43.6813 16.944 43.6813 17.4057C43.6813 17.8072 43.9022 18.1685 44.5646 18.1685C45.0464 18.1685 45.4479 17.9276 45.6085 17.6466V16.9239Z"
          fill="#093C59"
        />
        <path
          d="M51.8915 15.3577C51.6506 15.1369 51.3495 14.9763 51.0283 14.9763C50.4461 14.9763 50.0446 15.2172 50.0446 16.2209V19.3324H48.5391V13.5911H50.0446V13.9726C50.3056 13.6915 50.687 13.4707 51.229 13.4707C51.7309 13.4707 52.0922 13.6714 52.2929 13.8722L51.8915 15.3577Z"
          fill="#093C59"
        />
        <path
          d="M56.2081 19.3323V18.971C55.927 19.2319 55.5456 19.4528 55.0036 19.4528C53.7791 19.4528 52.7754 18.5293 52.7754 16.3814C52.7754 14.6751 53.5784 13.4706 55.0036 13.4706C55.5055 13.4706 55.9471 13.6714 56.2081 13.9123V12.1056L57.7137 11.3428V19.3122H56.2081V19.3323ZM56.2081 15.3978C55.9873 15.0766 55.6259 14.8959 55.1843 14.8959C54.6021 14.8959 54.281 15.3978 54.281 16.3613C54.281 17.5457 54.6824 18.0275 55.2646 18.0275C55.7263 18.0275 55.9672 17.8267 56.188 17.5457V15.3978H56.2081Z"
          fill="#093C59"
        />
        <path
          d="M15.4572 13.8116C15.4572 18.0874 12.0044 21.5402 7.72858 21.5402C3.47284 21.5402 0 18.0874 0 13.8116C0 9.53578 3.47284 6.08301 7.72858 6.08301C12.0044 6.08301 15.4572 9.53578 15.4572 13.8116Z"
          fill="#FF893E"
        />
        <path
          d="M8.5913 1.04484C7.78834 0.924391 7.14596 0.924391 6.58388 0.844094C5.90136 0.783871 4.57646 0.603203 4.21512 0.000976562C4.21512 0.000976562 4.21512 0.0611992 4.19505 0.16157C4.17497 0.382387 4.17497 0.844094 4.31549 1.42625C4.43594 1.9281 4.61661 2.42996 4.87757 2.91174C4.95787 3.07233 5.05824 3.21285 5.15861 3.37345C5.80099 4.31693 6.82477 4.97938 8.37049 5.32065C10.398 5.78235 12.305 6.10354 13.7905 7.99052V7.97044C14.0515 6.92658 13.0277 1.74743 8.5913 1.04484Z"
          fill="#78A22F"
        />
        <path
          d="M58.9171 18.3887C59.2383 18.3887 59.4993 18.6496 59.4993 18.9508C59.4993 19.2719 59.2383 19.5128 58.9171 19.5128C58.5959 19.5128 58.335 19.2519 58.335 18.9508C58.335 18.6496 58.5959 18.3887 58.9171 18.3887ZM58.9171 18.5292C58.6963 18.5292 58.5156 18.7099 58.5156 18.9508C58.5156 19.1916 58.6963 19.3723 58.9171 19.3723C59.158 19.3723 59.3186 19.1716 59.3186 18.9508C59.3387 18.7099 59.158 18.5292 58.9171 18.5292ZM58.8569 19.2318H58.6963V18.7099C58.7364 18.7099 58.8167 18.6898 58.9171 18.6898C59.0376 18.6898 59.0978 18.7099 59.1179 18.7299C59.158 18.75 59.1781 18.7902 59.1781 18.8504C59.1781 18.9106 59.1379 18.9508 59.0576 18.9708C59.1179 18.9909 59.158 19.031 59.158 19.1113C59.1781 19.1916 59.1781 19.2117 59.1982 19.2318H59.0175C58.9974 19.2117 58.9974 19.1716 58.9773 19.1113C58.9773 19.0511 58.9372 19.031 58.897 19.031H58.8368L58.8569 19.2318ZM58.8569 18.9307H58.897C58.9573 18.9307 58.9974 18.9106 58.9974 18.8705C58.9974 18.8303 58.9573 18.8102 58.897 18.8102C58.877 18.8102 58.8569 18.8102 58.8368 18.8102L58.8569 18.9307Z"
          fill="#093C59"
        />
        <path
          d="M13.4698 20.0557C13.791 20.0557 14.052 20.3166 14.052 20.6177C14.052 20.9389 13.791 21.1798 13.4698 21.1798C13.1487 21.1798 12.8877 20.9189 12.8877 20.6177C12.8877 20.2966 13.1487 20.0557 13.4698 20.0557ZM13.4698 20.1962C13.249 20.1962 13.0684 20.3769 13.0684 20.6177C13.0684 20.8586 13.249 21.0393 13.4698 21.0393C13.7107 21.0393 13.8713 20.8386 13.8713 20.6177C13.8713 20.3969 13.7107 20.1962 13.4698 20.1962ZM13.3896 20.8787H13.229V20.3568C13.2691 20.3568 13.3494 20.3367 13.4498 20.3367C13.5702 20.3367 13.6304 20.3568 13.6505 20.3769C13.6907 20.3969 13.7107 20.4371 13.7107 20.4973C13.7107 20.5575 13.6706 20.5977 13.5903 20.6177C13.6505 20.6378 13.6907 20.678 13.6907 20.7583C13.7107 20.8386 13.7107 20.8586 13.7308 20.8787H13.5501C13.5301 20.8586 13.5301 20.8185 13.51 20.7583C13.51 20.698 13.4698 20.678 13.4297 20.678H13.3695V20.8787H13.3896ZM13.4096 20.5776H13.4498C13.51 20.5776 13.5501 20.5575 13.5501 20.5174C13.5501 20.4772 13.51 20.4571 13.4498 20.4571C13.4297 20.4571 13.4096 20.4571 13.3896 20.4571V20.5776H13.4096Z"
          fill="#093C59"
        />
      </g>
      <defs>
        <clipPath id="clip0_16270_176210">
          <rect width="59.5" height="21.5396" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
