import { defaultPreviewHeight, defaultPreviewWidth } from '.'
import { MailIcon } from 'lucide-react'

import { EmailDestinationIcon } from '@assets/icons/email-destination'

import { ViewIdOptions } from '@features/drive/google-drive/types'

import { AbstractDestinationHandler } from './destination-handler'

export class EmailHandler extends AbstractDestinationHandler {
  override getTitle() {
    return 'presentation'
  }

  override getTitlePlural() {
    return 'presentations'
  }

  override getCloudAppTitle(): string {
    return 'Email'
  }

  override getCloudTitle(): string {
    return 'Email'
  }

  override getShortNamePlural() {
    return 'email'
  }

  override getDestinationTitle(_isFileDeleted = false) {
    return 'Open Destination'
  }

  override getDestinationUrl() {
    return null
  }

  override getGroupData(slideIndex: number) {
    return {
      group: `Email ${slideIndex.toString()}`,
      groupIndex: slideIndex,
    }
  }

  override getGPickerCustomViews() {
    return [google.picker.ViewId.PRESENTATIONS]
  }

  override getGPickerViewId(): ViewIdOptions {
    return 'PRESENTATIONS'
  }

  override getSmallIcon() {
    return <MailIcon height={20} />
  }

  override getPreview(
    _itemsInPageCount?: number,
    width = defaultPreviewWidth,
    height = defaultPreviewHeight,
    _className?: string
  ) {
    return (
      <div style={{ height, width }} className="flex items-center justify-center">
        <EmailDestinationIcon />
      </div>
    )
  }

  override getAddVizStep1() {
    return null
  }

  override getDateDelimiter() {
    return '/'
  }
}
