import { useGoogleLogin } from '@react-oauth/google'

import { GoogleIcon, MicrosoftIcon } from '@assets'

import { GoogleOAuthScopes } from '@features/drive/google-drive/types'
import { MicrosoftOAuthScopes } from '@features/drive/one-drive/types'
import { OAuthType, useGetGoogleOAuthTokensByCode, useSetMicrosoftOAuthTokens } from '@features/users'
import { useMicrosoftLogin } from '@features/users/hooks/use-microsoft-login'

import { ActionModal, Button, authorizeDriveToast, oAuthConnectedToast } from '@shared/components'
import { config } from '@shared/config'

interface IAuthModal {
  authModalVisible: boolean
  scopes: GoogleOAuthScopes[] | MicrosoftOAuthScopes[]
  email?: string
  setAuthModalVisible: (visible: boolean) => void
  errorCallback?: () => void
  successCallback?: () => void
  authTitle?: string
  authContent?: string
  handleClose?: () => void
  type: OAuthType | null
  skipAuthorizeDriveToast?: { onError?: boolean; onSuccess?: boolean }
}

export const AuthModal = ({
  authTitle,
  authContent,
  authModalVisible,
  email,
  errorCallback,
  scopes,
  setAuthModalVisible,
  handleClose = () => setAuthModalVisible(false),
  successCallback,
  type,
  skipAuthorizeDriveToast,
}: IAuthModal) => {
  const getTokensByCodeQuery = useGetGoogleOAuthTokensByCode()
  const setMicrosoftOauthTokensQuery = useSetMicrosoftOAuthTokens()

  const scope = scopes.join(' ')
  const isGoogle = type === OAuthType.GOOGLE
  const isLoading = getTokensByCodeQuery.isLoading || setMicrosoftOauthTokensQuery.isLoading

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    hint: email,
    onError: errorResponse => {
      console.log(errorResponse)
      if (skipAuthorizeDriveToast?.onError === false) {
        authorizeDriveToast({ type, success: false })
      }
      errorCallback && errorCallback()
    },
    onSuccess: async codeResponse => {
      await getTokensByCodeQuery.mutateAsync({
        code: codeResponse.code,
        redirectUri: config.google.redirectUri,
      })
      if (skipAuthorizeDriveToast?.onSuccess === false) {
        authorizeDriveToast({ type, success: true })
      }
      successCallback && successCallback()
      oAuthConnectedToast({ type: OAuthType.GOOGLE })
      setAuthModalVisible(false)
    },
    onNonOAuthError: errorResponse => {
      console.log(errorResponse)
      handleClose()
    },
    redirect_uri: config.google.redirectUri,
    scope,
  })

  const microsoftLogin = useMicrosoftLogin({
    onError: errorResponse => {
      console.error(errorResponse)
      if (skipAuthorizeDriveToast?.onError === false) {
        authorizeDriveToast({ type, success: false })
      }
      errorCallback && errorCallback()
    },
    onSuccess: async codeResponse => {
      await setMicrosoftOauthTokensQuery.mutateAsync({
        code: codeResponse,
        redirectUri: config.microsoft.redirectUri,
      })
      if (skipAuthorizeDriveToast?.onSuccess === false) {
        authorizeDriveToast({ type, success: true })
      }
      successCallback && successCallback()
      oAuthConnectedToast({ type: OAuthType.MICROSOFT })
      setAuthModalVisible(false)
    },
  })

  const title = authTitle ?? `Authorize ${isGoogle ? 'Google' : 'Microsoft'}`

  const subtitle =
    authContent ??
    `We need additional authorization to access the files in your ${isGoogle ? 'Google drive' : 'OneDrive'}.`

  return (
    <ActionModal
      title={title}
      subtitle={subtitle}
      open={authModalVisible}
      onBackgroundClick={handleClose}
      btns={
        <>
          <Button text="Cancel" secondaryGray fullWidth onClick={handleClose} disabled={isLoading} />
          <Button
            iconLeading={isGoogle ? <GoogleIcon /> : <MicrosoftIcon />}
            text="Authorize"
            fullWidth
            secondaryColor
            onClick={isGoogle ? googleLogin : microsoftLogin}
            loading={isLoading}
          />
        </>
      }
    />
  )
}
