import { RenameIcon } from '@assets'

import { Tooltip } from '@shared/components'
import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'
import { getMessage } from '@shared/constants'

interface RenameItemProps extends MenuItemProps {
  showMicrosoftTooltip?: boolean
}

export const RenameItem = ({ showMicrosoftTooltip = false, ...menuItemProps }: RenameItemProps) => {
  if (showMicrosoftTooltip) {
    return (
      <MenuItem {...menuItemProps}>
        <RenameIcon />
        <Tooltip title={getMessage('RENAME_MS_SHARED_ITEM')}>{'Rename'}</Tooltip>
      </MenuItem>
    )
  }

  return (
    <MenuItem {...menuItemProps}>
      <RenameIcon />
      {'Rename'}
    </MenuItem>
  )
}
