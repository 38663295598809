import { useContext } from 'react'

import { EDestinationType } from '@features/destinations/types'
import { useAddEmailTemplate, useUpdateEmailTemplate } from '@features/emails/api/api'
import { EmailTemplateEditorContext } from '@features/emails/components/templates/providers/email-template-editor-provider'
import { EEmailTemplateType } from '@features/emails/types'
import { ISync } from '@features/syncs/types'

import { errorToast, successToast } from '@shared/components'

interface EditorContainerSettings {
  upsertTemplate: () => Promise<string>
  isUpsertTemplateLoading: boolean
}

interface Props {
  templateType: EEmailTemplateType
  destinationType: EDestinationType
  onSuccess?: () => void
  targetId: string
  syncs?: ISync[]
}

export const useEditorContainerSettings = ({ templateType, onSuccess, targetId }: Props): EditorContainerSettings => {
  const { template } = useContext(EmailTemplateEditorContext)
  const { mutateAsync: addTemplate, isLoading: isAddTemplateLoading } = useAddEmailTemplate({
    type: templateType,
    targetId,
  })
  const { mutateAsync: updateTemplate, isLoading: isUpdateTemplateLoading } = useUpdateEmailTemplate(templateType)

  const upsertTemplate = async (): Promise<string> => {
    if (!template.subject || template.subject.trim() === '') {
      const error = 'Subject cannot be empty'
      errorToast(error)
      throw error
    } else if (!template.content || template.content.trim() === '') {
      const error = 'Body cannot be empty'
      errorToast(error)
      throw error
    }

    const onSuccessHandler = () => {
      successToast('Template saved.')
      onSuccess?.()
    }

    if (template.id) {
      await updateTemplate({ templateId: template.id, data: template }, { onSuccess: onSuccessHandler })
      return template.id
    } else {
      const newTemplate = await addTemplate(template, { onSuccess: onSuccessHandler })
      return newTemplate.id
    }
  }

  return {
    upsertTemplate,
    isUpsertTemplateLoading: isAddTemplateLoading || isUpdateTemplateLoading,
  }
}
