import { DuplicateIcon } from '@assets'

import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'

interface DuplicateItemProps extends MenuItemProps {
  resourceType: 'destination' | 'collection'
}

export const DuplicateItem = ({ resourceType, ...props }: DuplicateItemProps) => {
  const isCollection = resourceType === 'collection'
  return (
    <MenuItem {...props}>
      <DuplicateIcon />
      {isCollection ? 'Duplicate as standalone' : 'Duplicate'}
    </MenuItem>
  )
}
