import { PostHogFeature, PostHogFeatureProps } from 'posthog-js/react'
import { FC } from 'react'

import { FeatureFlagName } from '@shared/product-tooling/posthog/flags'

interface FeatureFlagProps extends PostHogFeatureProps {
  flag: FeatureFlagName
}

export const FeatureFlag: FC<FeatureFlagProps> = ({ children, flag, ...props }) => {
  return (
    <PostHogFeature {...props} flag={flag} match={true}>
      {children}
    </PostHogFeature>
  )
}
