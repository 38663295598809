import { EDataSourceType } from '@features/data-sources/types/types'
import { EDestinationType } from '@features/destinations/types'

export const integrationTestsEmail = 'testrollstack@gmail.com'

export enum ESignInType {
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft',
}

export const addDestinationTests = [
  {
    dataSourceType: EDataSourceType.Tableau,
    destinationType: EDestinationType.GoogleSlides,
    signInType: ESignInType.GOOGLE,
  },
  {
    dataSourceType: EDataSourceType.Looker,
    destinationType: EDestinationType.MicrosoftPowerPoint,
    signInType: ESignInType.MICROSOFT,
  },
]

export const testVisualizations = {
  Tableau: ['CustomerScatter', 'CustomerRank'],
  Looker: ['Orders by Day and Category', 'Total Sales, Year over Year'],
  Metabase: ['Websites, Count, Grouped by Rating', 'Users'],
}

export const testDashboard = {
  Tableau: 'Customers',
  Looker: 'E2E tests',
  Metabase: 'E2E tests',
  GoogleSheet: '',
}

export const destinationsFilters = {
  Tableau: {
    label: 'Category',
    value: 'Furniture',
  },
  Looker: {
    label: 'State',
    value: 'California',
  },
  Metabase: {
    label: 'Text contains',
    value: 'gmail',
  },
  GoogleSheet: {},
}

export const powerpointIframeLocator = 'iframe[name="WacFrame_PowerPoint_0"]'

export const collectionVariants = {
  Tableau: {
    filterLabel: 'Segment',
    filterValues: ['Consumer'],
  },
  Looker: {
    filterLabel: 'state',
    filterValues: ['California'],
  },
  Metabase: {
    filterLabel: 'User rating',
    filterValues: ['2'],
  },
  GoogleSheet: {
    filterLabel: '',
    filterValues: [''],
  },
}

export enum EDataTestId {
  THREE_DOTS_BUTTON = 'menu-',
  THREE_DOTS_ACTION_OPEN = 'external-link',
}

export const testUserAgent =
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.1.5790.171 Safari/537.36'
