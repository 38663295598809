import { copyTextFailedToast, copyTextToast } from '@shared/components'

export const useCopyToClipboard = () => {
  const copy = async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      copyTextFailedToast('Clipboard not supported')
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      copyTextToast()
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      copyTextFailedToast('Copy failed')
      return false
    }
  }

  return copy
}
