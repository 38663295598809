import { FC } from 'react'

interface ISyncImagePlaceholderProps {
  width?: string | number
  height?: string | number
}

const DEFAULT_SIZE = 40

export const SyncImagePlaceholder: FC<ISyncImagePlaceholderProps> = ({
  height = DEFAULT_SIZE,
  width = DEFAULT_SIZE,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none">
      <path
        d="M25 25V16.25M15 25V12.5M5 25L5 20M16.7583 6.28438L23.2189 8.70709M13.4985 6.75115L6.50029 11.9998M26.3258 8.04917C27.0581 8.78141 27.0581 9.96859 26.3258 10.7008C25.5936 11.4331 24.4064 11.4331 23.6742 10.7008C22.9419 9.96859 22.9419 8.78141 23.6742 8.04917C24.4064 7.31694 25.5936 7.31694 26.3258 8.04917ZM6.32583 11.7992C7.05806 12.5314 7.05806 13.7186 6.32583 14.4508C5.59359 15.1831 4.40641 15.1831 3.67417 14.4508C2.94194 13.7186 2.94194 12.5314 3.67417 11.7992C4.40641 11.0669 5.59359 11.0669 6.32583 11.7992ZM16.3258 4.29917C17.0581 5.03141 17.0581 6.21859 16.3258 6.95083C15.5936 7.68306 14.4064 7.68306 13.6742 6.95083C12.9419 6.21859 12.9419 5.03141 13.6742 4.29917C14.4064 3.56694 15.5936 3.56694 16.3258 4.29917Z"
        stroke="currentColor"
        strokeWidth="2.48698"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
