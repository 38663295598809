import { FC, useState } from 'react'

import { DuplicateIcon } from '@assets'

import { destinationHandlers } from '@features/destinations/destination-handlers'
import { EDestinationType } from '@features/destinations/types'

import { Spinner } from '@shared/components'
import { Button } from '@shared/components/button/button'
import { Input } from '@shared/components/input/input'
import { ActionModal } from '@shared/components/modal/action/action-modal'

interface IDuplicateDestinationStandalone {
  type: EDestinationType
  visible: boolean
  originalName: string
  processing: boolean
  isCheckingDrivePermission: boolean
  onDuplicate: (name: string) => void
  toggleVisibility: () => void
}

export const DuplicateDestinationStandalone: FC<IDuplicateDestinationStandalone> = ({
  type,
  visible,
  originalName,
  processing,
  isCheckingDrivePermission,
  onDuplicate,
  toggleVisibility,
}) => {
  const [name, setName] = useState(`Copy of ${originalName}`)

  const destinationLabel = destinationHandlers(type).getTitle()

  return (
    <ActionModal
      open={visible}
      Icon={DuplicateIcon}
      title={`Duplicate ${destinationLabel} as standalone`}
      subtitle={`A copy of the ${destinationLabel} will be created of the collection. The new ${destinationLabel} will have the same configuration.`}
      processing={processing}
      onBackgroundClick={toggleVisibility}
      btns={
        <>
          <Button fullWidth secondaryGray text="Cancel" onClick={toggleVisibility} disabled={processing} />
          <Button
            fullWidth
            disabled={!name.trim() || isCheckingDrivePermission}
            text="Duplicate"
            onClick={() => onDuplicate(name)}
            loading={processing}
          />
        </>
      }
    >
      <div className="h-20 relative">
        {isCheckingDrivePermission ? (
          <Spinner localCenter />
        ) : (
          <Input
            autoFocus
            value={name}
            label={`New ${destinationLabel} name`}
            onChange={e => setName(e.target.value)}
          />
        )}
      </div>
    </ActionModal>
  )
}
