import { UsersPlusIcon } from '@assets/icons/users'

import { MenuItem } from '@shared/components/menu-item/menu-item'

export const ShareItem = ({ visible, onClick }: { visible: boolean; onClick?: () => void }) => {
  return (
    <MenuItem onClick={onClick} visible={visible}>
      <UsersPlusIcon />
      {'Share'}
    </MenuItem>
  )
}
