import classNames from 'classnames'
import clsx from 'clsx'

import rootStyles from '@pages/root/root.module.css'

import { RollstackLogoFull } from '@assets/icons/logos'

import { LogoutButton } from '@shared/components/auth/logout-button/logout-button'

import styles from './organization-not-found.module.css'

export const OrganizationNotFoundPage = () => {
  return (
    <div className={classNames(rootStyles.container)}>
      <div className={rootStyles.pageContent}>
        <div className={clsx('w-full h-full', 'flex flex-col justify-center items-center gap-10')}>
          <RollstackLogoFull />
          <p className={styles.title}>You have been removed from your organization.</p>
          <LogoutButton />
          <p className={styles.title}>Please reach out to support if you want more details.</p>
        </div>
      </div>
    </div>
  )
}
