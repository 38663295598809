import classNames from 'classnames'
import { FC } from 'react'

import overlayStyles from './overlay.module.css'

interface ISyncWorksheetOverlay {
  size: { width: number; height: number }
  onClick: () => void
}

export const SyncWorksheetFullOverlay: FC<ISyncWorksheetOverlay> = ({ size, onClick }) => {
  const overlayAreaClassName = classNames(overlayStyles.overlayArea, overlayStyles.overlayAreaSelected)
  const overlayBadgeClassName = classNames(overlayStyles.overlayBadge, overlayStyles.overlayBadgeSelected)

  return (
    <div
      onClick={onClick}
      className={overlayAreaClassName}
      style={{
        top: 0,
        left: 0,
        width: size.width,
        height: size.height,
      }}
    >
      <div className={overlayBadgeClassName}>
        <div className={overlayStyles.badgeContent}>Full dashboard</div>
      </div>
    </div>
  )
}
