import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 19

export const MsWordLogo: FC<IIcon> = ({ size = defaultSize }) => {
  return (
    <svg width={size} version="1.1" x="0px" y="0px" viewBox="0 0 1881.25 1750" enableBackground="new 0 0 1881.25 1750">
      <path
        fill="#41A5EE"
        d="M1801.056,0H517.694C473.404,0,437.5,35.904,437.5,80.194c0,0,0,0,0,0V437.5l743.75,218.75l700-218.75  V80.194C1881.25,35.904,1845.346,0,1801.056,0L1801.056,0z"
      />
      <path fill="#2B7CD3" d="M1881.25,437.5H437.5V875l743.75,131.25l700-131.25V437.5z" />
      <path fill="#185ABD" d="M437.5,875v437.5l700,87.5l743.75-87.5V875H437.5z" />
      <path
        fill="#103F91"
        d="M517.694,1750h1283.363c44.29,0,80.194-35.904,80.194-80.194l0,0V1312.5H437.5v357.306  C437.5,1714.096,473.404,1750,517.694,1750L517.694,1750z"
      />
      <path
        opacity="0.1"
        enableBackground="new    "
        d="M969.806,350H437.5v1093.75h532.306c44.23-0.144,80.05-35.964,80.194-80.194  V430.194C1049.856,385.964,1014.036,350.144,969.806,350z"
      />
      <path
        opacity="0.2"
        enableBackground="new    "
        d="M926.056,393.75H437.5V1487.5h488.556c44.23-0.144,80.05-35.964,80.194-80.194  V473.944C1006.106,429.714,970.286,393.894,926.056,393.75z"
      />
      <path
        opacity="0.2"
        enableBackground="new    "
        d="M926.056,393.75H437.5V1400h488.556c44.23-0.144,80.05-35.964,80.194-80.194  V473.944C1006.106,429.714,970.286,393.894,926.056,393.75z"
      />
      <path
        opacity="0.2"
        enableBackground="new    "
        d="M882.306,393.75H437.5V1400h444.806c44.23-0.144,80.05-35.964,80.194-80.194  V473.944C962.356,429.714,926.536,393.894,882.306,393.75z"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="167.2057"
        y1="1420.9117"
        x2="795.2943"
        y2="333.0883"
        gradientTransform="matrix(1 0 0 -1 0 1752)"
      >
        <stop offset="0" style={{ stopColor: '#2368C4' }} />
        <stop offset="0.5" style={{ stopColor: '#1A5DBE' }} />
        <stop offset="1" style={{ stopColor: '#1146AC' }} />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M80.194,393.75h802.112c44.29,0,80.194,35.904,80.194,80.194v802.113  c0,44.29-35.904,80.194-80.194,80.194H80.194c-44.29,0-80.194-35.904-80.194-80.194V473.944C0,429.654,35.904,393.75,80.194,393.75z  "
      />
      <path
        fill="#FFFFFF"
        d="M329.088,1008.788c1.575,12.381,2.625,23.144,3.106,32.375h1.837c0.7-8.75,2.158-19.294,4.375-31.631  c2.217-12.338,4.215-22.765,5.994-31.281l84.35-363.913h109.069l87.5,358.444c5.084,22.288,8.723,44.881,10.894,67.637h1.444  c1.631-22.047,4.671-43.966,9.1-65.625l69.781-360.631h99.269l-122.588,521.5H577.238L494.113,790.3  c-2.406-9.931-5.162-22.925-8.181-38.894c-3.019-15.969-4.9-27.65-5.644-35h-1.444c-0.962,8.487-2.844,21.088-5.644,37.8  c-2.8,16.713-5.046,29.079-6.738,37.1l-78.138,344.269h-117.95L147.131,614.337h101.062l75.994,364.656  C325.894,986.475,327.513,996.45,329.088,1008.788z"
      />
    </svg>
  )
}
