import { FC } from 'react'

import { useGetTableauProjects, useGetTableauWorkbooks } from '@features/data-sources'
import { TableauCoreDataSource } from '@features/data-sources/types'
import { DashboardFile } from '@features/syncs/components/sync-dashboard-select/dashboard-file/dashboard-file'
import { IntegrationDashboardSelect } from '@features/syncs/components/sync-dashboard-select/dashboard-select-modal'

import { FilePicker, Spinner } from '@shared/components'

import { getFileFolderId, getFileName, getFolderId, getFolderName, getParentFolderId } from './getters'

export const DashboardSelectTableau: FC<IntegrationDashboardSelect<TableauCoreDataSource>> = ({
  dataSource,
  searchQuery,
}) => {
  const { data: getTableauProjects, isLoading: projectsLoading } = useGetTableauProjects(dataSource.id)
  const { data: getTableauWorkbooks, isLoading: workbooksLoading } = useGetTableauWorkbooks(dataSource.id)

  const isLoading = projectsLoading || workbooksLoading
  const folders = getTableauProjects?.projects?.project
  const dashboards = getTableauWorkbooks?.workbooks?.workbook

  return isLoading ? (
    <Spinner />
  ) : (
    <FilePicker
      folders={folders}
      files={dashboards}
      FileComponent={DashboardFile}
      searchQuery={searchQuery}
      getFolderId={getFolderId}
      getFolderName={getFolderName}
      getParentFolderId={getParentFolderId}
      getFileName={getFileName}
      getFileFolderId={getFileFolderId}
    />
  )
}
