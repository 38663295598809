import { useState } from 'react'

import { ISlide } from '@features/destinations'
import { EDestinationType } from '@features/destinations/types'

export interface SlideData {
  slideId: string
  slideIndex: number
}

export interface SyncSlideSelectProps {
  slides: ISlide[]
  docId?: string
  slideId: string
  slideIndex: number
  type: EDestinationType
  cb?: (slideData: SlideData) => void
}

export const useSyncSlideSelect = ({ slides, slideId, slideIndex, cb }: SyncSlideSelectProps) => {
  const [slideData, setSlideData] = useState<SlideData>({
    slideId: slideId,
    slideIndex: slideIndex,
  })

  const slideIds = slides ? slides.map(slide => slide.id) : []
  const nextSlideDisabled = slides && slideData.slideIndex >= slides?.length
  const formattedSlideIds = slideIds.map((slideId, index) => ({ slideIndex: index + 1, slideId }))

  const updateSlide = (data: SlideData) => {
    setSlideData(data)
    cb && cb(data)
  }

  const prevSlide = () => {
    if (!slideIds) return

    if (slideData.slideIndex > 1) {
      const updatedIndex = slideData.slideIndex - 1
      updateSlide({ slideId: slideIds[updatedIndex - 1], slideIndex: updatedIndex })
    }
  }

  const nextSlide = () => {
    if (!slideIds) return

    if (slideData.slideIndex >= slideIds.length) return

    const updatedIndex = slideData.slideIndex + 1
    updateSlide({ slideId: slideIds[updatedIndex - 1], slideIndex: updatedIndex })
  }

  const selectSlide = (data: SlideData | null) => {
    if (!data) {
      return
    }
    updateSlide({ slideId: data.slideId, slideIndex: data.slideIndex })
  }

  return {
    slideIds,
    slideIndex: slideData.slideIndex,
    formattedSlideIds,
    nextSlideDisabled,
    prevSlide,
    nextSlide,
    selectSlide,
  }
}
