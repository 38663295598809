import { FC } from 'react'

import { useGetMetabaseCollection, useGetMetabaseDashboards } from '@features/data-sources'
import { MetabaseCoreDataSource } from '@features/data-sources/types'
import { DashboardFile, IntegrationDashboardSelect } from '@features/syncs/components'

import { FilePicker, Spinner } from '@shared/components'

import { getFileFolderId, getFileName, getFolderId, getFolderName, getParentFolderId } from './getters'

export const DashboardSelectMetabase: FC<IntegrationDashboardSelect<MetabaseCoreDataSource>> = ({
  dataSource,
  searchQuery,
}) => {
  const { data: metabaseCollections, isLoading: isLoadingFolders } = useGetMetabaseCollection(dataSource.id)
  const { data: metabaseDashboards, isLoading: isLoadingDashboards } = useGetMetabaseDashboards(dataSource.id)

  const folders = metabaseCollections?.filter(collection => collection.id !== 'root')
  const files = metabaseDashboards?.map(file => ({ ...file, id: file.id.toString() }))
  const isLoading = isLoadingDashboards || isLoadingFolders

  return isLoading ? (
    <Spinner />
  ) : (
    <FilePicker
      files={files}
      folders={folders}
      FileComponent={DashboardFile}
      searchQuery={searchQuery}
      getFolderId={getFolderId}
      getFolderName={getFolderName}
      getParentFolderId={getParentFolderId}
      getFileName={getFileName}
      getFileFolderId={getFileFolderId}
    />
  )
}
