import { AxiosError } from 'axios'

import { warnToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import { formatApiErrorFromAxios, onError } from '@shared/http'

const LOOKER_API_ERROR_CODES = {
  INSUFFICIENT_PERMISSIONS: '403',
  RESOURCE_NOT_FOUND: '404',
  INVALID_CREDENTIALS: 'looker-invalid-credentials',
} as const

export type LookerApiErrorCodes = (typeof LOOKER_API_ERROR_CODES)[keyof typeof LOOKER_API_ERROR_CODES]

const LOOKER_API_ERROR_MESSAGES: Record<LookerApiErrorCodes, string> = {
  [LOOKER_API_ERROR_CODES.INSUFFICIENT_PERMISSIONS]:
    "You don't have permissions to access this Looker resource. Please check your credentials.",
  [LOOKER_API_ERROR_CODES.RESOURCE_NOT_FOUND]: 'Looker resource not found. Please check your credentials.',
  [LOOKER_API_ERROR_CODES.INVALID_CREDENTIALS]:
    'Your Looker credentials are either incomplete or invalid. Please verify your Looker credentials.',
}

const FALLBACK_LOOKER_API_ERROR =
  'Something went wrong when accessing Looker. Please verify your Looker credentials, to make sure you have access to this view.'

export const handleLookerApiErrors = (error: AxiosError, errorTitle = getMessage('LOOKER_API_ERROR_GENERIC')) => {
  const apiError = formatApiErrorFromAxios(error)
  const lookerCode = apiError.metadata.code as keyof typeof LOOKER_API_ERROR_MESSAGES

  if (lookerCode) {
    if (lookerCode === LOOKER_API_ERROR_CODES.INVALID_CREDENTIALS) {
      return handleInvalidLookerCredentials()
    }
    const specificErrorMessage =
      LOOKER_API_ERROR_MESSAGES[lookerCode] || apiError.metadata.message || FALLBACK_LOOKER_API_ERROR
    onError(error, {
      title: errorTitle,
      message: specificErrorMessage,
      hideSubtext: true,
    })
    return
  }
  onError(error, {
    title: errorTitle,
    message: apiError.message,
  })
}

export const handleInvalidLookerCredentials = () => {
  const lookerCode = LOOKER_API_ERROR_CODES.INVALID_CREDENTIALS
  const subtitle = LOOKER_API_ERROR_MESSAGES[lookerCode] || FALLBACK_LOOKER_API_ERROR
  warnToast({title: getMessage('INVALID_CREDENTIALS'), subtitle, toastId: lookerCode})
}
