import classNames from 'classnames'
import { FC, PropsWithChildren, ReactNode } from 'react'

import { ChevronDownUp } from '@assets/icons/arrows'

import { FieldStatus } from '@features/collections/types'

import styles from './collection-field-step.module.css'

interface CollectionFieldStepProps extends PropsWithChildren {
  icon: ReactNode
  title: string
  status: FieldStatus
  onHeaderClick: () => void
}

export const CollectionFieldStep: FC<CollectionFieldStepProps> = ({ icon, title, status, children, onHeaderClick }) => {
  const headerClasses: Record<FieldStatus, string> = {
    OPEN: styles.headerOpen,
    SELECTED: styles.headerSelected,
    DISABLED: styles.headerDisabled,
  }

  return (
    <div className={classNames(status === 'OPEN' && styles.containerBorder)}>
      <div
        className={classNames(styles.header, headerClasses[status])}
        onClick={() => {
          if (status === 'DISABLED') {
            return
          }
          onHeaderClick()
        }}
      >
        <div className={styles.titleAndIcon}>
          {icon} <p>{title}</p>
        </div>
        <ChevronDownUp isUp={status === 'OPEN'} />
      </div>
      {status === 'OPEN' && <div className={styles.content}>{children}</div>}
    </div>
  )
}
