import { FC } from 'react'

import { Button } from '@shared/components'

const BASE_URL = 'https://www.rollstack.com'

export const Footer: FC = () => {
  return (
    <footer className="p-5 text-sm w-full absolute bottom-0 left-1/2 transform -translate-x-1/2">
      <div className="flex flex-col align-center gap-4">
        <div className="flex justify-center text-sm-regular">Copyright © 2024 Rollstack Inc.</div>
        <div className="flex justify-center gap-10">
          <a href={`${BASE_URL}/terms`}>
            <Button linkColor text="Terms & Conditions" />
          </a>

          <a href={`${BASE_URL}/privacy-policy`}>
            <Button linkColor text="Privacy Policy" />
          </a>
        </div>
      </div>
    </footer>
  )
}
