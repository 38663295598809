import { Crisp } from 'crisp-sdk-web'
import { FC, useEffect, useState } from 'react'

import { Router } from '@pages/routes/router'

import { DestinationsStatuses } from '@features/notch/components/destinations-statuses/destinations-statuses'
import { OrganizationInviteModal } from '@features/organizations/components/organization-invite-modal/organization-invite-modal'
import { useAuthStore } from '@features/users'

import { config, isDev } from '@shared/config'

const INITIAL_MESSAGE_QUERY_PARAM = 'initial-message'
const CHAT_OPEN_QUERY_PARAM = 'chat-open'

export const App: FC = () => {
  const { user } = useAuthStore()
  const [sentMessage, setSentMessage] = useState(false)

  useEffect(() => {
    if (sentMessage) {
      return
    }
    const searchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(searchParams)
    const initialMessage = params[INITIAL_MESSAGE_QUERY_PARAM]
    const chatOpen = params[CHAT_OPEN_QUERY_PARAM]
    if (chatOpen === 'true') {
      Crisp.chat.open()
      if (initialMessage) {
        Crisp.message.sendText(initialMessage)
        setSentMessage(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user || !user.tokens) {
    return null
  }

  if (!isDev) {
    Crisp.configure(config.crispWebsiteId)
    Crisp.user.setEmail(user.email)
    Crisp.user.setNickname(user.name)
  }

  return (
    <>
      <DestinationsStatuses />
      <OrganizationInviteModal />
      <Router />
    </>
  )
}
