import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_COLOR = '#FAC107'

export const WarningInfoIcon: FC<Pick<IIcon, 'color'>> = ({ color = DEFAULT_COLOR }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_7125_7915)">
        <path
          d="M10.0003 6.66602V9.99935M10.0003 13.3327H10.0087M18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327C5.39795 18.3327 1.66699 14.6017 1.66699 9.99935C1.66699 5.39698 5.39795 1.66602 10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7125_7915">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
