import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildAddSourcePath, Paths } from '@pages/routes/paths'

import { ArrowRight, GSheetsLogo, InfoIcon, LookerIcon, MetabaseIcon, TableauIcon } from '@assets'

import { useAddDataSourcesFromDrive } from '@features/data-sources'
import { EDataSourceType } from '@features/data-sources/types/types'
import { InviteMembersModal, useOrganizationsStore } from '@features/organizations'

import { Button, PageContainer, SelectableOption, Tooltip } from '@shared/components'
import { Colors } from '@shared/constants'
import { useBoolean, useNullBoolean } from '@shared/hooks'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import styles from './data-sources-options.module.css'

export const DataSourceOptions: FC = () => {
  const { isMember } = useOrganizationsStore()
  const navigate = useNavigate()

  const [isAdmin, toggleIsAdmin] = useNullBoolean()
  const [inviteModal, toggleInviteModal] = useBoolean()
  const [dataSourceSelection, setDataSourceSelection] = useState<EDataSourceType>()

  const handleOptionClick = (option: EDataSourceType) => () => setDataSourceSelection(option)
  const navigateToSettings = () => navigate(buildAddSourcePath(dataSourceSelection!))

  const { addSheetsDataSources, isLoading } = useAddDataSourcesFromDrive(() => navigate(Paths.DATA_SOURCES))

  return (
    <PageContainer title="Add data source" className={styles.pageContainer}>
      <div className={styles.view}>
        <div className={styles.firstSection}>
          <Tooltip title="Only admins can add data sources" show={isMember}>
            <SelectableOption
              selected={dataSourceSelection === EDataSourceType.Tableau}
              disabled={isMember}
              className={styles.option}
              dataTestId={EDataSourceType.Tableau}
              onClick={handleOptionClick(EDataSourceType.Tableau)}
            >
              <TableauIcon className={styles.icon} size={150} />
            </SelectableOption>
          </Tooltip>
          <Tooltip title="Only admins can add data sources" show={isMember}>
            <SelectableOption
              selected={dataSourceSelection === EDataSourceType.Looker}
              disabled={isMember}
              className={styles.option}
              dataTestId={EDataSourceType.Looker}
              onClick={handleOptionClick(EDataSourceType.Looker)}
            >
              <LookerIcon className={styles.icon} size={110} />
            </SelectableOption>
          </Tooltip>
          <Tooltip title="Only admins can add data sources" show={isMember}>
            <SelectableOption
              selected={dataSourceSelection === EDataSourceType.Metabase}
              disabled={isMember}
              className={styles.option}
              dataTestId={EDataSourceType.Metabase}
              onClick={handleOptionClick(EDataSourceType.Metabase)}
            >
              <MetabaseIcon className={styles.icon} size={150} />
            </SelectableOption>
          </Tooltip>
          {isFeatureEnabled('sheets-data-source') && (
            <SelectableOption
              selected={dataSourceSelection === EDataSourceType.GoogleSheets}
              loading={isLoading}
              className={styles.option}
              dataTestId={EDataSourceType.GoogleSheets}
              onClick={addSheetsDataSources(EDataSourceType.GoogleSheets)}
            >
              <GSheetsLogo className={styles.icon} size={150} />
            </SelectableOption>
          )}
        </div>

        {dataSourceSelection && (
          <div className={styles.secondSection}>
            <span>Are you a {dataSourceSelection} admin ?</span>
            <div className={styles.options}>
              <SelectableOption className={styles.selectable} selected={Boolean(isAdmin)} onClick={navigateToSettings}>
                <div className="text-sm-semibold">Yes</div>
              </SelectableOption>

              <SelectableOption
                className={styles.selectable}
                selected={isAdmin === false}
                onClick={() => toggleIsAdmin(false)}
              >
                <div className="text-sm-semibold">No</div>
              </SelectableOption>
            </div>

            {isAdmin === false && (
              <div className={styles.notAdminMessage}>
                <div>Ask your {dataSourceSelection} admin to add the data source on Rollstack.</div>
                <div className={styles.info}>
                  <InfoIcon color={Colors.primary700} />
                  If your {dataSourceSelection} admin is not on Rollstack you can invite them
                </div>
                <Button
                  text={`Invite ${dataSourceSelection} admin`}
                  onClick={toggleInviteModal}
                  iconTrailing={<ArrowRight />}
                />
              </div>
            )}
          </div>
        )}
        {inviteModal && <InviteMembersModal adminInvite toggleModal={toggleInviteModal} />}
      </div>
    </PageContainer>
  )
}
