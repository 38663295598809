import classNames from 'classnames'
import { FC } from 'react'

import { Switch as ShadcnSwitch } from '@shared/components/ui/switch'

import styles from './switch.module.css'

interface ISwitch {
  value: boolean
  text?: React.ReactNode
  className?: string
  disabled?: boolean
  dataTestId?: string
  onChange: (isOn: boolean) => void
}

export const Switch: FC<ISwitch> = ({ value, text, disabled = false, className, onChange, dataTestId }) => {
  const containerClasses = classNames(styles.container, className)
  return (
    <div className={containerClasses} onClick={() => onChange(!value)}>
      <ShadcnSwitch disabled={disabled} data-testid={dataTestId} checked={value} />
      {text}
    </div>
  )
}
