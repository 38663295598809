import { FC } from 'react'

import styles from './empty-page-template.module.css'

interface IEmptyPageTemplate {
  label: string
  icon?: JSX.Element
  button?: JSX.Element
  description: string | JSX.Element
}

export const EmptyPageTemplate: FC<IEmptyPageTemplate> = ({ description, icon, label, button }) => {
  return (
    <div className={styles.container}>
      <div className={styles.centered}>
        {icon}
        <div className={styles.label}>{label}</div>
        <div className={styles.description}>{description}</div>
        {button}
      </div>
    </div>
  )
}
