import { Dayjs } from 'dayjs'
import { FC } from 'react'

import { ITooltip, Tooltip } from '@shared/components/tooltip/tooltip'
import { DEFAULT_DATE_FORMAT } from '@shared/constants/dates'

interface RelativeDateTimeProps extends ITooltip {
  date: Dayjs | null
}

export const RelativeDateTime: FC<RelativeDateTimeProps> = ({ date, ...tooltipProps }) => {
  return (
    <Tooltip show={Boolean(date)} title={date?.format(DEFAULT_DATE_FORMAT)} {...tooltipProps}>
      {date?.fromNow()}
    </Tooltip>
  )
}
