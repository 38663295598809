import { destinationModalsAssetsUrlsBase } from '@assets'

import { destinationHandlers } from '@features/destinations/destination-handlers'
import { EDestinationType } from '@features/destinations/types'
import { useShowTutorialModals } from '@features/users'

import { TutorialModal } from '@shared/components/tutorial-modal/tutorial-modal'

interface VisualizationExplainerModalProps {
  visible: boolean
  type: EDestinationType
  setModalOpen: (param: boolean) => void
  onDismiss: () => void
}

export const VisualizationExplainerModal = ({
  visible,
  type,
  setModalOpen,
  onDismiss,
}: VisualizationExplainerModalProps) => {
  const collectionHandler = destinationHandlers(type)
  const cloudApp = collectionHandler.getCloudAppTitle()

  const { mutateAsync: updateShowTutorialModals, isLoading } = useShowTutorialModals()

  const handleDismiss = async () => {
    if (isLoading) {
      return
    }
    await updateShowTutorialModals({
      data: {
        showVisualizationExplainerModal: false,
      },
    })
    setModalOpen(false)
    onDismiss()
  }

  return (
    <TutorialModal
      visible={visible}
      onDismiss={handleDismiss}
      contents={[
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-explainer-1.png`,
          title: 'Updating visualizations',
          description:
            'You can automatically update all the visualizations inside your presentation or document by simply clicking the Update Now button',
        },
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-explainer-2.png`,
          title: 'Granular update',
          description: 'You also have the option to update specific visualizations or slides.',
        },
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-explainer-3.png`,
          title: 'Formatting',
          description: `Resizing, moving visualizations or applying borders to them in ${cloudApp}, will be maintained by future Rollstack updates.`,
        },
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-explainer-4.png`,
          title: 'Cropping',
          description:
            'In case you need to crop a visualization, we strongly advise to do it in Rollstack. After the first visualization update, the option will be available in the visualization settings. You can choose either automatic or manual cropping.',
        },
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-explainer-5.png`,
          title: 'Duplicating',
          description: 'You can duplicate visualizations or even entire slides on Rollstack.',
        },
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-explainer-6.png`,
          title: 'Deleting',
          description: `Deleting a visualization on Rollstack also removes it from the deck. However, deleting a visualization directly from the deck does not remove the visualization setup in Rollstack. E.g. you might want to remove a visualization in this week's report but not for all future ones.`,
        },
      ]}
    />
  )
}
