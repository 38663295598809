import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { LogOutIcon } from '@assets'
import { SettingsIcon } from '@assets/icons/settings'
import { TickIcon } from '@assets/icons/ticks'

import { useOrganizationsStore } from '@features/organizations'
import { UserInOrganization } from '@features/organizations/types'
import { AccountInfo, useAuthStore, useUnifiedAuth } from '@features/users'

import { Tooltip } from '@shared/components/tooltip/tooltip'
import { Colors } from '@shared/constants'
import { useBoolean } from '@shared/hooks/use-boolean'
import { useClickOutside } from '@shared/hooks/use-click-outside'

import styles from './account.module.css'

interface IAccount {
  collapsedSidebar: boolean
}

export const Account: FC<IAccount> = ({ collapsedSidebar }) => {
  const { user } = useAuthStore()
  const { unifiedSignOut } = useUnifiedAuth()

  const navigate = useNavigate()
  const [accountModal, toggleAccountModal] = useBoolean()
  const containerRef = useClickOutside(toggleAccountModal)
  const { organizations, activeOrganization, setActiveOrganization } = useOrganizationsStore()

  const goToOrgSettings = () => navigate(Paths.SETTINGS_ORGANIZATION)
  const goToHome = () => navigate(Paths.BASE)

  const handleOrganizationChange = (org: UserInOrganization) => {
    setActiveOrganization(org)
    goToHome()
  }

  if (!organizations || !user || !activeOrganization) return null

  const orgsWithoutActive = organizations.filter(org => org.organizationId !== activeOrganization.organizationId)

  return (
    <div className={styles.container} onClick={e => e.stopPropagation()}>
      <Tooltip show={collapsedSidebar} title={user.name} position="right" secondary>
        <AccountInfo
          name={user.name}
          organizationName={activeOrganization.organization.name}
          showChevron={accountModal}
          onClick={toggleAccountModal}
          fullVersion={!collapsedSidebar}
        />
      </Tooltip>
      {accountModal && (
        <div className={styles.modal} ref={containerRef}>
          <div className={styles.details}>
            <span className="text-sm-semibold">{user.name}</span>
            <span className={styles.email}>{user.email}</span>
          </div>
          <hr className={styles.hr} />

          {activeOrganization && (
            <>
              <div className={styles.block}>
                <span className="text-xs-regular">Current organization</span>
                <div className={styles.currentOrg} onClick={goToOrgSettings}>
                  <div className={styles.name}>
                    <TickIcon color={Colors.base3} />
                    {activeOrganization.organization.name}
                  </div>
                  <SettingsIcon />
                </div>
              </div>
              <hr className={styles.hr} />
            </>
          )}

          {orgsWithoutActive.length > 0 && (
            <div className={styles.block}>
              <span className="text-xs-regular">Other organizations</span>
              {orgsWithoutActive.map(org => (
                <p key={org.organizationId} className={styles.org} onClick={() => handleOrganizationChange(org)}>
                  {org.organization.name}
                </p>
              ))}
            </div>
          )}

          <hr className={styles.hr} />
          <button className={styles.logOut} onClick={unifiedSignOut}>
            <LogOutIcon />
            Log out
          </button>
        </div>
      )}
    </div>
  )
}
