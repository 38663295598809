import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { PathSegments } from '@pages/routes/paths'

import { TemplateModificationModal } from '@features/collections'
import { EDataSourceType } from '@features/data-sources/types/types'
import { useEditVisualization } from '@features/destinations/hooks/use-edit-visualization'
import { ApplyFiltersToMany } from '@features/syncs'
import { SyncEmbed } from '@features/syncs/components/sync-embed/sync-embed/sync-embed'
import { FiltersSelect } from '@features/syncs/components/sync-settings/filters-select/filters-select'
import { VisualizationSelect } from '@features/syncs/components/sync-settings/visualization-select/visualization-select'

import { Button, PageContainer, Spinner } from '@shared/components'

import styles from './edit-visualization.module.css'

export const EditVisualization: FC = () => {
  const {
    visualization,
    destination,
    slides,
    goToDestinationsPage,
    handleClickSave,
    isLoading,
    isSavingSync,
    canSaveSync,
    isLoadingValidSyncsForSelectedFilters,
    showFilterModificationModal,
    templateModificationModal,
    setTemplateModificationModal,
    handleCancelOnFilterModal,
    handleSaveFiltersToOne,
    handleSaveFiltersAndApplyToMany,
    handleSaveSync,
  } = useEditVisualization({})

  const navigate = useNavigate()

  if (isLoading) {
    return (
      <PageContainer loading={isLoading}>
        <Spinner localCenter />
      </PageContainer>
    )
  }

  // If destination or slides not found, can be retried after OAuth permissions
  if (!destination || !slides) {
    return
  }

  // If visualization not found, redirect user to not-found page
  if (!visualization) {
    navigate('/' + PathSegments.NOT_FOUND)
    return
  }

  if (visualization.type === EDataSourceType.GoogleSheets) {
    goToDestinationsPage(visualization.slideId)
    return
  }

  return (
    <PageContainer title={visualization.name}>
      <div className={styles.container}>
        <div className={styles.secondSection}>
          <VisualizationSelect />
          <FiltersSelect />
        </div>

        <div className={styles.btnsContainer}>
          <Button className={styles.boxButton} secondaryGray text="Back" onClick={() => goToDestinationsPage()} />
          <Button
            className={styles.boxButton}
            text="Save"
            onClick={handleClickSave}
            loading={isSavingSync}
            disabled={!canSaveSync || isLoadingValidSyncsForSelectedFilters}
          />
        </div>

        <SyncEmbed />

        <ApplyFiltersToMany
          visible={showFilterModificationModal}
          handleBack={handleCancelOnFilterModal}
          handleSaveToOne={handleSaveFiltersToOne}
          saveAndApplyToMany={handleSaveFiltersAndApplyToMany}
        />

        <TemplateModificationModal
          visible={templateModificationModal}
          setModalOpen={setTemplateModificationModal}
          onConfirm={handleSaveSync}
        />
      </div>
    </PageContainer>
  )
}
