import { FC, useMemo } from 'react'

import { SendRightIcon } from '@assets'

import { useSendDestinationEmail } from '@features/destinations/api/api'
import { Destination, DestinationEmailConfig, DestinationEmailConfigSharingFormat } from '@features/destinations/types'
import { isEmailDestination } from '@features/destinations/utils'

import { ActionModal, Button, successToast } from '@shared/components'
import { mapPermissionTypeToLabel } from '@shared/utils/sending'

import styles from './destination-sending-confirm-modal.module.css'

export interface DestinationSendingConfirmModalProps {
  destination: Destination
  destinationEmailConfig: DestinationEmailConfig
  isOpen: boolean
  onClose: () => void
}

export const DestinationSendingConfirmModal: FC<DestinationSendingConfirmModalProps> = ({
  destination,
  destinationEmailConfig,
  isOpen,
  onClose,
}) => {
  const destinationId = destination.id
  const { mutate: sendEmail, isLoading } = useSendDestinationEmail(destinationId, {
    onSuccess: () => {
      successToast('Destination sent')
      onClose()
    },
  })

  const sharingFormat = useMemo(() => {
    switch (destinationEmailConfig.sharingFormat) {
      case DestinationEmailConfigSharingFormat.link:
        return 'Link'
      case DestinationEmailConfigSharingFormat.pdf:
        return 'PDF'
      default:
        return 'None'
    }
  }, [destinationEmailConfig])

  const permissionType = useMemo(() => {
    return mapPermissionTypeToLabel(destinationEmailConfig.sharingPermission)
  }, [destinationEmailConfig])

  const onSend = () => {
    sendEmail({})
  }

  return (
    <ActionModal
      open={isOpen}
      title="Send destination"
      Icon={SendRightIcon}
      btns={[
        <Button key="cancel" secondaryGray fullWidth text="Cancel" onClick={onClose} />,
        <Button
          key="send"
          fullWidth
          text="Send now"
          iconLeading={<SendRightIcon />}
          onClick={onSend}
          disabled={isLoading || !destinationEmailConfig}
          loading={isLoading}
        >
          Send
        </Button>,
      ]}
      onBackgroundClick={onClose}
    >
      <section className={styles.container}>
        <div className={styles.row}>
          <label className={styles.label}>Recipients</label>
          <div className={styles.content}>
            {destinationEmailConfig.recipientConfig.map((recipientConfig, idx) => (
              <p key={idx}>{recipientConfig.recipient_email}</p>
            ))}
          </div>
        </div>
        {!isEmailDestination(destination) && (
          <>
            <div className={styles.row}>
              <label className={styles.label}>Send as</label>
              <div className={styles.content}>
                <p>{sharingFormat}</p>
              </div>
            </div>
            {destinationEmailConfig.sharingFormat === DestinationEmailConfigSharingFormat.link && (
              <div className={styles.row}>
                <label className={styles.label}>Permission type</label>
                <div className={styles.content}>
                  <p>{permissionType}</p>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </ActionModal>
  )
}
