import noop from 'lodash/noop'
import { FC, PropsWithChildren, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildUserManagementRoute } from '@pages/routes/paths'

import { ArrowRight, InfoIcon, PlusIcon } from '@assets'
import { lookerAssetsUrlsBase } from '@assets/public-urls'

import { useAddParentLookerDataSource, useUpdateLookerDataSource } from '@features/data-sources/api'
import { DataSourceTestStatus } from '@features/data-sources/components/connection-test/data-source-test-status'
import { useLookerConnectionTest } from '@features/data-sources/hooks'
import { ILookerDataSource } from '@features/data-sources/types'
import { EDataSourceType } from '@features/data-sources/types/types'

import { Button, Carousel, CarouselContent } from '@shared/components'
import { Input } from '@shared/components/input/input'
import { ActionModal, ActionModalProps } from '@shared/components/modal/action/action-modal'
import { Tooltip } from '@shared/components/tooltip/tooltip'
import { Colors } from '@shared/constants'
import { useBoolean } from '@shared/hooks/use-boolean'
import { useCarouselSteps } from '@shared/hooks/use-carousel-steps'

import styles from './looker-api-keys-modal.module.css'

interface LookerAPIKeysModalProps extends PropsWithChildren, ActionModalProps {
  dataSource: Pick<ILookerDataSource, 'name' | 'baseUrl' | 'embedSecret' | 'userAttributes' | 'autoProvision'> &
    Partial<Pick<ILookerDataSource, 'id' | 'clientId' | 'clientSecret'>>
  testDashboardEmbed: boolean
  isAdminView: boolean
  toggle: () => void
}

interface UserAttr {
  name: string
  value: string
}

const carouselContent: CarouselContent[] = [
  {
    content:
      'In order to properly test your data source connection, you need to provide your admin credentials. In the Users page, enter the user you want to add to this data source',
    imageUrl: `${lookerAssetsUrlsBase}looker-credentials1.png`,
  },
  {
    content: 'Click on Edit keys to create a key for this user',
    imageUrl: `${lookerAssetsUrlsBase}looker-credentials2.png`,
  },
  {
    content: `Generate an API key for this user if they don't already have one`,
    imageUrl: `${lookerAssetsUrlsBase}looker-credentials3.png`,
  },
  {
    content: `If the connection is successful, you should see the following URLs added to your embed domain allowlist`,
    imageUrl: `${lookerAssetsUrlsBase}looker-credentials4.png`,
  },
]

export const LookerAPIKeysModal: FC<LookerAPIKeysModalProps> = ({
  dataSource,
  children,
  testDashboardEmbed,
  isAdminView,
  toggle,
  ...modalProps
}) => {
  const { id, name, baseUrl, embedSecret, userAttributes: initialUserAttributes, autoProvision } = dataSource

  const navigate = useNavigate()
  const [isTesting, toggleTesting] = useBoolean()
  const [clientId, setClientId] = useState(dataSource.clientId || '')
  const [clientSecret, setClientSecret] = useState(dataSource.clientSecret || '')
  const { step, maxStep, goToStep, goToPrevious, goToNext } = useCarouselSteps(carouselContent.length)
  const [userAttrList, setUserAttrList] = useState<UserAttr[]>(
    initialUserAttributes ? Object.entries(initialUserAttributes).map(([name, value]) => ({ name, value })) : []
  )

  const { mutateAsync: updateDataSource } = useUpdateLookerDataSource()
  const { mutateAsync: addDataSource } = useAddParentLookerDataSource()
  const { iframeDashboardRef, currentTest, stage, connectionTestsMessages, testConnection } =
    useLookerConnectionTest(testDashboardEmbed)

  const userAttributes = useMemo(() => {
    return userAttrList.reduce(
      (acc, { name, value }) => {
        if (name && value) {
          acc[name] = value
        }
        return acc
      },
      {} as Record<string, string>
    )
  }, [userAttrList])

  const addUserAttr = () => {
    setUserAttrList([...userAttrList, { name: '', value: '' }])
  }

  const removeUserAttr = (idx: number) => {
    setUserAttrList(userAttrList.filter((_, i) => i !== idx))
  }

  const handleUserAttrNameChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const newUserAttrList = [...userAttrList]
    newUserAttrList[idx].name = e.target.value
    setUserAttrList(newUserAttrList)
  }

  const handleUserAttrValueChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const newUserAttrList = [...userAttrList]
    newUserAttrList[idx].value = e.target.value
    setUserAttrList(newUserAttrList)
  }

  const saveDataSource = async () => {
    if (!id) {
      const { data } = await addDataSource({
        name,
        baseUrl,
        clientId,
        clientSecret,
        embedSecret,
        type: EDataSourceType.Looker,
        userAttributes,
        autoProvision,
      })
      navigate(buildUserManagementRoute(EDataSourceType.Looker, data.id))
    } else {
      await updateDataSource({
        id,
        name,
        baseUrl,
        embedSecret,
        clientId,
        clientSecret,
        userAttributes,
        autoProvision,
      })
    }
    toggle()
  }

  const testConnectionAndSave = async () => {
    toggleTesting(true)
    const isConnectionSuccessful = await testConnection({
      baseUrl,
      embedSecret,
      clientId,
      clientSecret,
      isAdmin: isAdminView,
      userAttributes,
    })
    toggleTesting(false)

    if (isConnectionSuccessful) {
      await saveDataSource()
    }
  }

  const isValidInput = useMemo(() => Boolean(clientId) && Boolean(clientSecret), [clientId, clientSecret])

  return (
    <ActionModal
      {...modalProps}
      onBackgroundClick={isTesting ? noop : toggle}
      className={styles.modal}
      btns={
        <>
          <Button text="Cancel" secondaryGray fullWidth onClick={toggle} />
          <Button
            fullWidth
            text="Test data source"
            iconTrailing={<ArrowRight />}
            disabled={!isValidInput}
            loading={isTesting}
            onClick={testConnectionAndSave}
          />
        </>
      }
    >
      <Carousel
        contents={carouselContent}
        width={500}
        height={300}
        step={step}
        maxStep={maxStep}
        onClickStep={goToStep}
        onClickPrevious={goToPrevious}
        onClickNext={goToNext}
      />
      <div className={styles.container}>
        <div className={styles.row}>
          <Input
            autoFocus
            label="Client ID"
            placeholder="Client ID"
            name="clientId"
            value={clientId}
            onChange={e => setClientId(e.target.value)}
          />
          <Input
            label="Client Secret"
            placeholder="Client Secret"
            name="clientSecret"
            value={clientSecret}
            onChange={e => setClientSecret(e.target.value)}
            secret
          />
        </div>

        {userAttrList.map(({ name, value }, idx) => (
          <div className={styles.row} key={idx}>
            <div className={styles.userAttr}>
              <Input label="User attribute name" value={name} onChange={e => handleUserAttrNameChange(e, idx)} />
              <Input
                label="User attribute value"
                value={value}
                onChange={e => handleUserAttrValueChange(e, idx)}
                secret
                viewable={false}
                onCrossClick={() => removeUserAttr(idx)}
              />
            </div>
          </div>
        ))}
        <div className={styles.info}>
          <Button secondaryColor text="Add user attributes" iconLeading={<PlusIcon />} onClick={addUserAttr} />
          <Tooltip title="Hidden user attribute values needs to be provided separately here on Rollstack">
            <InfoIcon color={Colors.primary400} />
          </Tooltip>
        </div>
      </div>

      {stage && currentTest && (
        <DataSourceTestStatus
          stage={stage}
          currentTest={currentTest}
          connectionTestMessages={connectionTestsMessages}
        />
      )}
      <div id="dashboard" hidden ref={iframeDashboardRef} />
    </ActionModal>
  )
}
