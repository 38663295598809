import { ColumnDef } from '@tanstack/react-table'

import { Destination } from '@features/destinations/types'
import { SyncsTooltip } from '@features/syncs/components/syncs-tooltip/syncs-tooltip'
import { ISync } from '@features/syncs/types'

import { Checkbox, ImageWithFallback } from '@shared/components'
import { Button } from '@shared/components/button'

import styles from './filters-table.module.css'

const MAX_PREVIEWS_PER_ROW = 4

interface RowData {
  id: string
  filterName: string
  filterFormattedValue: string
  syncIds: string[]
}

export const filtersTableData = (destination: Destination): RowData[] =>
  destination.syncsGroupedByFilterValue?.map((group, index) => ({
    id: `${index}`,
    filterName: group.filterName,
    filterFormattedValue: group.filterFormattedValue,
    syncIds: group.syncIds,
  })) || []

interface FiltersTableColumnsProps {
  syncs: Map<string, ISync>
  hideCheckbox: boolean
  hideModify: boolean
  handleModify: (syncIds: string[]) => void
}

export const filtersTableColumns = ({
  syncs,
  hideCheckbox,
  hideModify,
  handleModify,
}: FiltersTableColumnsProps): ColumnDef<RowData>[] => {
  return [
    {
      id: 'select',
      header: ({ table }) =>
        hideCheckbox ? null : (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
            onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
      cell: ({ row }) =>
        hideCheckbox ? null : (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={value => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
      size: 40,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'filterName',
      header: 'Filter Name',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'filterFormattedValue',
      header: 'Filter Value',
      cell: info => info.getValue(),
    },
    {
      id: 'visualizations',
      header: 'Visualizations',
      cell: ({ row }) => {
        const syncIds = row.original.syncIds
        return (
          <div className={styles.previews}>
            {syncIds.slice(0, MAX_PREVIEWS_PER_ROW).map(syncId => {
              const sync = syncs.get(syncId)
              if (!sync) return null

              return (
                <div key={syncId}>
                  <SyncsTooltip title={sync.name} slideIndex={sync.slideIndex} imageUrl={sync.imageUrl}>
                    <ImageWithFallback
                      src={sync.imageUrl}
                      className={styles.preview}
                      imageSize={{ width: 40, height: 30 }}
                      alt="Visualization"
                      dataTestId={sync.name}
                    />
                  </SyncsTooltip>
                </div>
              )
            })}
            {syncIds.length > MAX_PREVIEWS_PER_ROW && <div>and {syncIds.length - MAX_PREVIEWS_PER_ROW} others</div>}
          </div>
        )
      },
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) =>
        hideModify ? null : <Button secondaryGray text="Modify" onClick={() => handleModify(row.original.syncIds)} />,
    },
  ]
}
