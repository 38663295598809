import { FC, MouseEventHandler, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { EDataTestId } from '@tests/constants'

import { buildCollectionSetupPath } from '@pages/routes/paths'

import { MenuHorizontalIcon } from '@assets/icons/menu'

import { destinationHandlers, isEmailDestination, isMicrosoftDestination } from '@features/destinations'
import { Destination, EDestinationType } from '@features/destinations/types'
import { FreeTrialContext } from '@features/free-trial'
import { OAuthContext, OAuthType, useAuthStore } from '@features/users'
import { FilePickerContext } from '@features/users/providers/file-picker-provider'

import { Button as ShadcnButton } from '@shared/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@shared/components/ui/dropdown-menu'

import {
  AuthorizeItem,
  DeleteItem,
  DuplicateItem,
  OpenExternalItem,
  RecreateItem,
  RenameItem,
  ShareItem,
  StartCollectionItem,
} from './menu-items'

interface IDestinationMenu {
  viewer: boolean
  editor: boolean
  owner: boolean
  destination: Destination
  shared: boolean
  renameClick: () => void
  deleteClick: () => void
  duplicateClick: () => void
  duplicateOutsideClick: () => void
  shareDestinationClick: () => void
  recreateVariantClick: () => void
}

export const DestinationMenu: FC<IDestinationMenu> = ({
  viewer,
  editor,
  owner,
  destination,
  shared,
  renameClick,
  deleteClick,
  duplicateClick,
  duplicateOutsideClick,
  shareDestinationClick,
  recreateVariantClick,
}) => {
  const { variantInCollection: inCollection, isFileDeleted } = destination

  const { user } = useAuthStore()

  const { showAuthModal } = useContext(OAuthContext)
  const { showFilePickerModal } = useContext(FilePickerContext)

  const openPicker = () => {
    const destinationType = destination.type
    switch (destinationType) {
      case EDestinationType.GoogleSlides:
      case EDestinationType.GoogleDocs:
        if (!user?.googleOAuth) {
          showAuthModal({
            type: OAuthType.GOOGLE,
            successCallback: () => {
              showFilePickerModal({
                type: destinationType,
                itemId: destination.docId,
                itemName: destination.name,
                modalTitle: "Select the destination's file from Google Drive",
                modalMessage: 'Please authorize Rollstack to access the file from Google Drive',
              })
            },
          })
          return
        }
        showFilePickerModal({
          type: destinationType,
          itemId: destination.docId,
          itemName: destination.name,
          modalTitle: "Select the destination's file from Google Drive",
          modalMessage: 'Please authorize Rollstack to access the file from Google Drive',
        })
        break
    }
  }

  const handle = destinationHandlers(destination)
  const navigate = useNavigate()
  const { freeTrial } = useContext(FreeTrialContext)

  const hasVisualizations = destination.syncs && destination.syncs.length > 0
  const hasRunUpdate = useMemo(
    () => destination.syncs?.some(sync => Boolean(sync.originalImageUrl)),
    [destination.syncs]
  )

  const convertToCollection: MouseEventHandler<HTMLDivElement> = e => {
    if (!hasVisualizations) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    freeTrial(() => navigate(buildCollectionSetupPath(destination.id)))
  }

  const duplicate = () => freeTrial(duplicateClick)
  const duplicateOutside = () => freeTrial(duplicateOutsideClick)
  const handleRecreateVariant = () => freeTrial(recreateVariantClick)

  const isEmail = isEmailDestination({ type: destination.type })
  const isMicrosoft = isMicrosoftDestination({ type: destination.type })
  const isMicrosoftShared = isMicrosoft && shared
  const showCollectionItem = !viewer && !isFileDeleted
  const showDeleteItem = !viewer && !editor
  const showDuplicateItem = !isFileDeleted
  const showShareItem = !viewer && !isFileDeleted
  const showRenameItem = !viewer && !isFileDeleted
  const showRecreateItem = !viewer && !isFileDeleted

  const variantUrl = handle.getDestinationUrl()

  const variantMenu = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadcnButton size="sm" variant="outline">
          {<MenuHorizontalIcon />}
        </ShadcnButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" onClick={e => e.stopPropagation()}>
        <DuplicateItem onClick={duplicateOutside} visible={showDuplicateItem} resourceType="collection" />
        <ShareItem onClick={shareDestinationClick} visible={showShareItem} />
        <RenameItem
          disabled={isMicrosoftShared}
          showMicrosoftTooltip={isMicrosoftShared}
          onClick={renameClick}
          visible={showRenameItem}
        />
        {!isEmailDestination(destination) && (
          <RecreateItem
            visible={showRecreateItem}
            cloudTitle={handle.getCloudTitle()}
            onClick={handleRecreateVariant}
          />
        )}
        {variantUrl && (
          <OpenExternalItem
            dataTestId={EDataTestId.THREE_DOTS_ACTION_OPEN + destination.name}
            link={variantUrl}
            visible
          />
        )}
        <DeleteItem onClick={deleteClick} visible={showDeleteItem} />
      </DropdownMenuContent>
    </DropdownMenu>
  )

  const url = handle.getDestinationUrl()

  const destinationMenu = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadcnButton size="sm" variant="outline">
          {<MenuHorizontalIcon />}
        </ShadcnButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" onClick={e => e.stopPropagation()}>
        <DuplicateItem onClick={duplicate} visible={!isFileDeleted} resourceType="destination" />
        <StartCollectionItem
          onClick={convertToCollection}
          hasRunUpdate={hasRunUpdate}
          hasVisualizations={hasVisualizations}
          shared={shared}
          visible={showCollectionItem}
        />
        <ShareItem onClick={shareDestinationClick} visible={showShareItem} />
        <RenameItem
          disabled={isMicrosoftShared}
          showMicrosoftTooltip={isMicrosoftShared}
          onClick={renameClick}
          visible={showRenameItem}
        />
        {!isMicrosoft && !isEmail && <AuthorizeItem onClick={openPicker} />}
        {url && (
          <OpenExternalItem dataTestId={EDataTestId.THREE_DOTS_ACTION_OPEN + destination.name} link={url} visible />
        )}
        <DeleteItem onClick={deleteClick} visible={owner && !isFileDeleted} />
      </DropdownMenuContent>
    </DropdownMenu>
  )

  if (inCollection) return variantMenu
  return destinationMenu
}
