import {
  ColumnDef,
  ColumnFiltersState,
  Table as ITable,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import { LegacyRef, ReactNode, useEffect, useState } from 'react'

import { ChevronDownUp } from '@assets/icons/arrows'

import { Button } from '@shared/components/button'
import { Button as ShadcnButton } from '@shared/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@shared/components/ui/dropdown-menu'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shared/components/ui/table'

interface IBaseCellProps {
  id: string
  onClick?: () => void
  sectionHeader?: string
}

interface PaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

interface DataGridProps<T extends IBaseCellProps> {
  columns: ColumnDef<T>[]
  data: T[]
  enablePagination?: boolean
  customPagination?: PaginationProps
  enableRowSelection?: boolean
  rowSelection?: RowSelectionState
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>
  EmptyState?: string | React.ReactNode
  clickableRows?: boolean
  enableChangeColumnVisibility?: boolean
  columnVisibility?: VisibilityState
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>
  isGrid?: boolean
  numberOfElementsRef?: (isGridLayout: boolean) => LegacyRef<HTMLTableSectionElement> | undefined
  elementsPerPage?: number
  preHeader?: ReactNode
}

export const DataGrid = <T extends IBaseCellProps>(props: DataGridProps<T>): JSX.Element => {
  const {
    columns,
    data,
    enablePagination = false,
    customPagination,
    enableRowSelection = false,
    rowSelection,
    setRowSelection,
    clickableRows = false,
    EmptyState,
    enableChangeColumnVisibility = false,
    columnVisibility,
    onColumnVisibilityChange,
    isGrid = false,
    numberOfElementsRef = null,
    elementsPerPage,
    preHeader,
  } = props

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...(enablePagination && { getPaginationRowModel: getPaginationRowModel() }),
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange,
    enableRowSelection: enableRowSelection,
    getRowId: row => row.id,
    state: {
      sorting,
      columnFilters,
      ...(enableRowSelection && { rowSelection }),
      ...(enableChangeColumnVisibility && { columnVisibility }),
    },
  })

  useEffect(() => {
    if (enablePagination && elementsPerPage) {
      table.setPageSize(elementsPerPage)
    }
  }, [enablePagination, elementsPerPage, table])

  const emptyData = table.getRowModel().rows.length === 0

  const ColumnsSelection = () => {
    if (!enableChangeColumnVisibility) return
    return (
      <div className="flex items-center justify-between py-4 px-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ShadcnButton className="ml-auto gap-2" variant="outline">
              Columns <ChevronDownUp />
            </ShadcnButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            {table
              .getAllColumns()
              .filter(column => column.getCanHide())
              .map(column => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.columnDef.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={value => column.toggleVisibility(!!value)}
                  >
                    {column.columnDef.header as string}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
  }

  interface DataGridContainerProps {
    children: ReactNode
  }
  const DataGridContainer: React.FC<DataGridContainerProps> = ({ children }) => {
    if (isGrid) return <div className="w-full">{children}</div>
    return <Table className="w-full">{children}</Table>
  }

  const Header = () => {
    if (isGrid) return
    return (
      <TableHeader className="bg-gray-50 select-none">
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const canSort = header.column.columnDef.enableSorting
              return (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px`, cursor: canSort ? 'pointer' : 'default' }}
                  onClick={canSort ? header.column.getToggleSortingHandler() : undefined}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {canSort &&
                    ({
                      asc: ' ↑',
                      desc: ' ↓',
                    }[header.column.getIsSorted() as string] ??
                      null)}
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
    )
  }

  const EmptyStateContent = () => {
    if (!emptyData) return
    return (
      <div
        className={classNames('w-full flex justify-center p-24', typeof EmptyState === 'string' && 'text-sm-medium')}
      >
        {EmptyState}
      </div>
    )
  }

  const ListView = () => (
    <TableBody ref={numberOfElementsRef && numberOfElementsRef(isGrid)}>
      {table.getRowModel().rows.map(row => {
        if (row.original.sectionHeader) {
          return (
            <TableRow key={row.id} className="bg-gray-50 text-sm-semibold">
              <TableCell colSpan={columns.length}>{row.original.sectionHeader}</TableCell>
            </TableRow>
          )
        }

        return (
          <TableRow
            key={row.id}
            onClick={clickableRows ? row.original.onClick : undefined}
            data-state={row.getIsSelected() ? 'selected' : undefined}
            className={clickableRows ? 'hover:bg-gray-50' : undefined}
          >
            {row.getVisibleCells().map(cell => (
              <TableCell className={clickableRows ? 'cursor-pointer' : 'bg-white transition-colors'} key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        )
      })}
    </TableBody>
  )

  const GridView = () => (
    <div
      ref={numberOfElementsRef && numberOfElementsRef(isGrid)}
      className="w-full grid gap-x-8 gap-y-12 grid-cols-[repeat(auto-fit,_210px)]"
    >
      {table.getRowModel().rows.map(row => (
        <div key={row.id} className="flex flex-col">
          {row.getVisibleCells().map(cell => (
            <div key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
          ))}
        </div>
      ))}
    </div>
  )

  const Body = () => (isGrid ? <GridView /> : <ListView />)

  type PaginationFooterProps = {
    table: ITable<T>
    customPagination?: PaginationProps
    isGrid: boolean
  }
  const PaginationFooter = ({ table, customPagination, isGrid }: PaginationFooterProps) => {
    if (!enablePagination) return
    const listViewCurrentPage = customPagination
      ? customPagination.currentPage
      : table.getState().pagination.pageIndex + 1
    const listViewTotalPages = customPagination ? customPagination.totalPages : table.getPageCount()
    const onPageChange = customPagination
      ? customPagination.onPageChange
      : (pageIndex: number) => {
          if (pageIndex < listViewCurrentPage) table.previousPage()
          else table.nextPage()
        }
    const canPreviousPage = customPagination ? customPagination.currentPage > 1 : table.getCanPreviousPage()
    const canNextPage = customPagination
      ? customPagination.currentPage < customPagination.totalPages
      : table.getCanNextPage()
    return (
      <div className="text-sm-medium flex items-center justify-between p-10">
        <div className={classNames('invisible', isGrid || emptyData ? '' : 'visible')}>
          Page {listViewCurrentPage} of {listViewTotalPages}
        </div>
        <div className="flex flex-row gap-5">
          <Button
            secondaryGray
            onClick={() => onPageChange(listViewCurrentPage - 1)}
            disabled={!canPreviousPage}
            text="Previous"
          />
          <Button
            secondaryGray
            onClick={() => onPageChange(listViewCurrentPage + 1)}
            disabled={!canNextPage}
            text="Next"
          />
        </div>
      </div>
    )
  }

  return (
    <div className={isGrid ? 'w-full' : 'w-full outline outline-1 outline-gray-200 rounded-[12px]'}>
      {preHeader}
      <ColumnsSelection />
      <DataGridContainer>
        <Header />
        <Body />
      </DataGridContainer>
      <EmptyStateContent />
      <PaginationFooter table={table} isGrid={isGrid} customPagination={customPagination} />
    </div>
  )
}
