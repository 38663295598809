import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { FC, PropsWithChildren, useRef } from 'react'

interface Props extends PropsWithChildren {
  color: string
  onColorChange: (color: string) => void
}

const OFFSET_TIME = 100

export const ColorPicker: FC<Props> = ({ color, onColorChange, children }: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <div
      onClick={() => ref.current?.click()}
      className={clsx(!children && 'border border-solid border-gray-300 rounded-sm w-4 h-4 cursor-pointer')}
      style={{
        backgroundColor: children ? undefined : color,
      }}
    >
      {children}
      <input
        ref={ref}
        type="color"
        className="invisible h-0 w-0"
        defaultValue={color}
        onChange={debounce(e => onColorChange(e.target.value), OFFSET_TIME)}
      />
    </div>
  )
}
