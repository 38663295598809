import { useContext, useEffect } from 'react'
import { Socket } from 'socket.io-client'

import { ServerToClientEvents, SocketContext } from '@shared/http'

interface EventsMap {
  [event: string]: any
}

type EventNames<Map extends EventsMap> = keyof Map & (string | symbol)

interface SocketReservedEvents {
  connect: () => void
  connect_error: (err: Error) => void
  disconnect: (reason: Socket.DisconnectReason, description?: any) => void
}

export interface SocketEvent {
  name: EventNames<SocketReservedEvents> | EventNames<ServerToClientEvents>
  handler(...args: any[]): any
}

export const useAuthenticatedSocket = (events: SocketEvent[] = [], enabled = true) => {
  const { connected, socket } = useContext(SocketContext)

  useEffect(() => {
    if (!enabled) return

    for (const event of events) {
      socket.on(event.name, event.handler)
    }

    return function () {
      for (const event of events) {
        socket.off(event.name, event.handler)
      }
    }
  }, [socket, events, enabled])

  return { connected, socket }
}
