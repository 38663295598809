import { User } from '@sentry/react'
import { AxiosError } from 'axios'

import { OAuthType } from '@features/users'
import { ShowAuthModalParams } from '@features/users'
import { FilePickerFolderType, ShowFilePickerParams } from '@features/users/providers/file-picker-provider'

import {
  googleItemNotFoundNonOwnerToast,
  insufficientPermissionNonOwnerToast,
  insufficientPermissionToast,
  microsoftFileNotFoundNonOwnerToast,
  microsoftFileNotFoundToast,
  oAuthMissingNonOwnerToast,
  oAuthMissingToast,
} from '@shared/components'
import { ApiError } from '@shared/http'
import { ApiErrorHandlerFn, ApiErrorHandlerParams } from '@shared/http/types'

export enum RollstackResource {
  Destination = 'Destination',
  Collection = 'Collection',
}

export interface OAuthErrorHandlerParams extends ApiErrorHandlerParams {
  successCallback?: () => void
  showAuthModal?: (params: ShowAuthModalParams) => void
  handleClose?: () => void
  ignoreError?: boolean
  user?: User
}
export const handleOAuthError: ApiErrorHandlerFn = (
  error: ApiError,
  { showAuthModal, successCallback, handleClose, ignoreError, user }: OAuthErrorHandlerParams = {}
) => {
  if (error.code === 'google-oauth-token-missing' || error.code === 'google-oauth-token-invalid') {
    if (ignoreError) {
      return true
    }

    const userId = error?.metadata?.userId
    const resource = error?.metadata?.resourceType

    if (userId !== user?.id) {
      oAuthMissingNonOwnerToast({ type: OAuthType.GOOGLE, resource })
      return true
    }

    if (showAuthModal) {
      showAuthModal({ type: OAuthType.GOOGLE, successCallback, handleClose })
      return true
    }

    oAuthMissingToast({ type: OAuthType.GOOGLE, toastId: error.metadata.code })
    return true
  }
  if (error.code === 'microsoft-oauth-token-missing' || error.code === 'microsoft-oauth-token-invalid') {
    if (ignoreError) {
      return true
    }

    const userId = error?.metadata?.userId
    const resource = error?.metadata?.resourceType

    if (userId !== user?.id) {
      oAuthMissingNonOwnerToast({ type: OAuthType.MICROSOFT, resource })
      return true
    }

    if (showAuthModal) {
      showAuthModal({ type: OAuthType.MICROSOFT, successCallback, handleClose })
      return true
    }

    oAuthMissingToast({ type: OAuthType.MICROSOFT, toastId: error.metadata.code })
    return true
  }
  return false
}

export const oauthErrorHandlerFactory = (params: OAuthErrorHandlerParams) => (error: ApiError) =>
  handleOAuthError(error, params)

export const isOAuthMissingError = (error: unknown): boolean => {
  if (!(error instanceof AxiosError)) {
    return false
  }

  const errorCode = error.response?.data.metadata?.code
  if (errorCode === 'google-oauth-token-missing' || errorCode === 'microsoft-oauth-token-missing') {
    return true
  }

  return false
}

export interface InsufficientPermissionsErrorHandlerParams extends ApiErrorHandlerParams {
  ignoreError?: boolean
  message?: string
  user?: User
}
export const handleInsufficientPermissionsError: ApiErrorHandlerFn = (
  error: ApiError,
  { user, ignoreError, message }: InsufficientPermissionsErrorHandlerParams = {}
) => {
  if (error.code === 'google-drive-item-insufficient-permission' || error.code === 'google-drive-item-forbidden') {
    if (ignoreError) {
      return true
    }

    const userId = error?.metadata?.userId
    const resource = error?.metadata?.resourceType

    if (userId !== user?.id) {
      insufficientPermissionNonOwnerToast({ type: OAuthType.GOOGLE, resource })
      return true
    }

    insufficientPermissionToast({ type: OAuthType.GOOGLE, message })
    return true
  }

  if (error.code === 'microsoft-drive-item-insufficient-permission') {
    if (ignoreError) {
      return true
    }

    const userId = error?.metadata?.userId
    const resource = error?.metadata?.resourceType

    if (userId !== user?.id) {
      insufficientPermissionNonOwnerToast({ type: OAuthType.MICROSOFT, resource })
      return true
    }

    insufficientPermissionToast({ type: OAuthType.MICROSOFT, message })
    return true
  }

  return false
}
export const insufficientPermissionsErrorHandlerFactory =
  (params: InsufficientPermissionsErrorHandlerParams) => (error: ApiError) =>
    handleInsufficientPermissionsError(error, params)

export interface GoogleDrivePermissionErrorHandlerParams extends Partial<ShowFilePickerParams>, ApiErrorHandlerParams {
  showFilePickerModal?: (params: ShowFilePickerParams) => void
  ignoreError?: boolean
  user?: User
}
export const handleGoogleDrivePermissionError: ApiErrorHandlerFn = (
  error: ApiError,
  { showFilePickerModal, ignoreError, user, ...filePickerOptions }: GoogleDrivePermissionErrorHandlerParams = {}
) => {
  if (error.code === 'google-drive-item-not-found' || error.code === 'google-drive-app-not-authorized-to-file') {
    if (ignoreError) {
      return true
    }

    const userId = error?.metadata?.userId
    const resource = error?.metadata?.resourceType

    if (userId !== user?.id) {
      googleItemNotFoundNonOwnerToast({ type: OAuthType.GOOGLE, resource })
      return true
    }

    if (showFilePickerModal) {
      showFilePickerModal({
        type: filePickerOptions.type || FilePickerFolderType.GoogleUnknown,
        itemName: filePickerOptions.itemName,
        itemId: filePickerOptions.itemId,
        skipSelectedItemIdValidation: filePickerOptions.skipSelectedItemIdValidation,
        successCallback: filePickerOptions.successCallback,
        handleClose: filePickerOptions.handleClose,
      })
      return true
    }
  }
  return false
}

export const googleDrivePermissionErrorHandlerFactory =
  (params: GoogleDrivePermissionErrorHandlerParams) => (error: ApiError) =>
    handleGoogleDrivePermissionError(error, params)

export interface MicrosoftDrivePermissionErrorHandlerParams extends ApiErrorHandlerParams {
  ignoreError?: boolean
  user?: User
}
export const handleMicrosoftDriveNotFoundError: ApiErrorHandlerFn = (
  error: ApiError,
  { ignoreError, user }: MicrosoftDrivePermissionErrorHandlerParams = {}
) => {
  if (error.code === 'microsoft-drive-item-not-found') {
    if (ignoreError) {
      return true
    }

    const userId = error?.metadata?.userId
    const resource = error?.metadata?.resourceType

    if (userId !== user?.id) {
      microsoftFileNotFoundNonOwnerToast({ type: OAuthType.MICROSOFT, resource })
      return true
    }

    microsoftFileNotFoundToast({ type: OAuthType.MICROSOFT })
    return true
  }
  return false
}

export const microsoftDrivePermissionErrorHandlerFactory =
  (params: MicrosoftDrivePermissionErrorHandlerParams) => (error: ApiError) =>
    handleMicrosoftDriveNotFoundError(error, params)
