import classNames from 'classnames'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC } from 'react'

import { useCollectionSettings } from '@features/collections'
import { VariantNamingOptions } from '@features/collections/components/variant-naming-options/variant-naming-options'
import { ICollection } from '@features/collections/types'
import { SuffixGroups, SuffixOptions, isGoogleDestination, mapSuffixOptionsToExample } from '@features/destinations'
import { DestinationSchedule } from '@features/destinations/components/destination-update-schedule/destination-schedule'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { LinkOption, UpdateMethod } from '@features/destinations/types'
import { SelectFolder } from '@features/drive'
import { CropOption } from '@features/syncs/types'
import { OAuthType } from '@features/users'

import { ActionModal, Button, Collapsable, SelectableOption, Spinner } from '@shared/components'

import styles from './collection-settings.module.css'

dayjs.extend(utc)
dayjs.extend(timezone)

interface CollectionDataSettingsProps {
  collection: ICollection
}

export const CollectionSettings: FC<CollectionDataSettingsProps> = ({ collection }) => {
  const {
    formattedSchedules,
    isLoading,
    suffixGroup,
    suffix,
    updateMethod,
    freqOptions,
    timeOptions,
    variantNaming,
    selectedParentFolder,
    linkOption,
    cropOption,
    isSubmitting,
    owner,
    confirmModal,
    expandedScheduleId,
    setExpandedScheduleId,
    toggleConfirmModal,
    freeTrial,
    setUpdateMethod,
    setVariantNaming,
    setSelectedParentFolder,
    setCropOption,
    setLinkOption,
    addSchedule,
    removeSchedule,
    handleFrequencyChange,
    handleTimeChange,
    handlePeriodChange,
    handleStartDateChange,
    handleEndDateChange,
    toggleEndDate,
    updateConfiguration,
    handleArchiveUpdateSelection,
    handleSelectSuffixGroup,
    handleSelectSuffix,
  } = useCollectionSettings({ itemUpdatedAt: collection.updatedAt, collection })

  const templateDestination = collection.collectionVariantTemplate.destination
  const destinationHandler = destinationHandlers(templateDestination)

  const destinationTitle = destinationHandler.getTitle()
  const dateDelimiter = destinationHandler.getDateDelimiter()
  const dateFormats = mapSuffixOptionsToExample(dateDelimiter)

  const handleSave = () => {
    freeTrial(async () => {
      await updateConfiguration()
      toggleConfirmModal(false)
    })
  }

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Spinner localCenter />
      </div>
    )
  }

  return (
    <div className={styles.grid}>
      {confirmModal && (
        <ActionModal
          open={confirmModal}
          onBackgroundClick={toggleConfirmModal}
          title="Apply to all variants"
          subtitle="Are you sure you want to apply these settings to all variants in the collection?"
          btns={[
            <Button text="Cancel" fullWidth secondaryGray onClick={toggleConfirmModal} />,
            <Button text="Confirm" fullWidth onClick={handleSave} loading={isSubmitting} />,
          ]}
        />
      )}

      <div>
        <h3 className={styles.label}>Collection variants naming</h3>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <VariantNamingOptions selected={variantNaming} onClick={option => setVariantNaming(option)} />
      </section>

      <div>
        <h3 className={styles.label}>Update method</h3>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <SelectableOption
          className={styles.option}
          selected={updateMethod === UpdateMethod.Update}
          onClick={() => setUpdateMethod(UpdateMethod.Update)}
        >
          <div className={styles.optionTitle}>Update same {destinationTitle}</div>
          <div className={styles.optionSubtitle}>The same {destinationTitle} is updated with the latest data.</div>
        </SelectableOption>
        <SelectableOption
          className={styles.option}
          selected={updateMethod === UpdateMethod.Archive}
          onClick={handleArchiveUpdateSelection}
        >
          <div className={styles.optionTitle}>Archive & Update</div>
          <div className={styles.optionSubtitle}>The old content is archived and kept in your drive.</div>
        </SelectableOption>
      </section>

      <div>
        <h3 className={styles.label}>Scheduling</h3>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <DestinationSchedule
          formattedSchedules={formattedSchedules}
          freqOptions={freqOptions}
          timeOptions={timeOptions}
          expandedScheduleId={expandedScheduleId}
          handleFrequencyChange={handleFrequencyChange}
          handleTimeChange={handleTimeChange}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          toggleEndDate={toggleEndDate}
          addSchedule={addSchedule}
          removeSchedule={removeSchedule}
          handlePeriodChange={handlePeriodChange}
          setExpandedScheduleId={setExpandedScheduleId}
        />
      </section>

      <div className={styles.labelColumn}>
        <h3 className={styles.label}>Version name suffix format</h3>
        <p>
          The date will be used as a suffix for filenames. The date can also be used inside the collections template
          using {`{{rollstack.date}}`}
        </p>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <Collapsable open>
          <div className={styles.row}>
            <div className={styles.versionNameFields}>
              <div className={styles.fields}>
                <div className={styles.suffixGroupOptions}>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffixGroup === SuffixGroups.DATE}
                    onClick={() => handleSelectSuffixGroup(SuffixGroups.DATE)}
                  >
                    Date
                  </SelectableOption>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffixGroup === SuffixGroups.MONTH}
                    onClick={() => handleSelectSuffixGroup(SuffixGroups.MONTH)}
                  >
                    Month
                  </SelectableOption>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffixGroup === SuffixGroups.QUARTER}
                    onClick={() => handleSelectSuffixGroup(SuffixGroups.QUARTER)}
                  >
                    Quarter
                  </SelectableOption>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffixGroup === SuffixGroups.YEAR}
                    onClick={() => handleSelectSuffixGroup(SuffixGroups.YEAR)}
                  >
                    Year
                  </SelectableOption>
                </div>
              </div>

              <div className={styles.fields}>
                <div className={styles.suffixOptions}>
                  {suffixGroup === SuffixGroups.DATE && (
                    <>
                      <SelectableOption
                        className={styles.suffixOption}
                        selected={suffix === SuffixOptions.MM_DD_YYYY}
                        onClick={() => handleSelectSuffix(SuffixOptions.MM_DD_YYYY)}
                      >
                        {dateFormats[SuffixOptions.MM_DD_YYYY]}
                      </SelectableOption>
                      <SelectableOption
                        className={styles.suffixOption}
                        selected={suffix === SuffixOptions.DD_MM_YYYY}
                        onClick={() => handleSelectSuffix(SuffixOptions.DD_MM_YYYY)}
                      >
                        {dateFormats[SuffixOptions.DD_MM_YYYY]}
                      </SelectableOption>
                    </>
                  )}
                  {suffixGroup === SuffixGroups.MONTH && (
                    <>
                      <SelectableOption
                        className={styles.suffixOption}
                        selected={suffix === SuffixOptions.MMMM_YYYY}
                        onClick={() => handleSelectSuffix(SuffixOptions.MMMM_YYYY)}
                      >
                        {dateFormats[SuffixOptions.MMMM_YYYY]}
                      </SelectableOption>
                      <SelectableOption
                        className={styles.suffixOption}
                        selected={suffix === SuffixOptions.MM_YYYY}
                        onClick={() => handleSelectSuffix(SuffixOptions.MM_YYYY)}
                      >
                        {dateFormats[SuffixOptions.MM_YYYY]}
                      </SelectableOption>
                    </>
                  )}
                  {suffixGroup === SuffixGroups.QUARTER && (
                    <>
                      <SelectableOption
                        className={styles.suffixOption}
                        selected={suffix === SuffixOptions.Q}
                        onClick={() => handleSelectSuffix(SuffixOptions.Q)}
                      >
                        {dateFormats[SuffixOptions.Q]}
                      </SelectableOption>
                      <SelectableOption
                        className={styles.suffixOption}
                        selected={suffix === SuffixOptions.Q_YYYY}
                        onClick={() => handleSelectSuffix(SuffixOptions.Q_YYYY)}
                      >
                        {dateFormats[SuffixOptions.Q_YYYY]}
                      </SelectableOption>
                    </>
                  )}
                  {suffixGroup === SuffixGroups.YEAR && (
                    <SelectableOption
                      className={styles.suffixOption}
                      selected={suffix === SuffixOptions.YYYY}
                      onClick={() => handleSelectSuffix(SuffixOptions.YYYY)}
                    >
                      {dateFormats[SuffixOptions.YYYY]}
                    </SelectableOption>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Collapsable>
      </section>

      {owner && (
        <>
          <div>
            <h3 className={styles.label}>Folder location</h3>
          </div>
          <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
            <div className={styles.folderInput}>
              <SelectFolder
                oauthType={isGoogleDestination({ type: collection.type }) ? OAuthType.GOOGLE : OAuthType.MICROSOFT}
                selectedParentFolder={selectedParentFolder}
                setSelectedParentFolder={setSelectedParentFolder}
              />
            </div>
          </section>
        </>
      )}

      {isGoogleDestination({ type: collection.type }) && (
        <>
          <div>
            <h3 className={styles.label}>Link on images</h3>
          </div>
          <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
            <SelectableOption
              className={styles.option}
              selected={linkOption === LinkOption.rollstack}
              onClick={() => setLinkOption(LinkOption.rollstack)}
            >
              <div className={styles.optionWithoutDescription}>Link to Rollstack visualization settings</div>
            </SelectableOption>

            <SelectableOption
              className={styles.option}
              selected={linkOption === LinkOption.external}
              onClick={() => setLinkOption(LinkOption.external)}
            >
              <div className={styles.optionWithoutDescription}>Link to visualization's dashboard</div>
            </SelectableOption>

            <SelectableOption
              className={styles.option}
              selected={linkOption === LinkOption.nolink}
              onClick={() => setLinkOption(LinkOption.nolink)}
            >
              <div className={styles.optionWithoutDescription}>No link</div>
            </SelectableOption>
          </section>
        </>
      )}

      <div className={styles.labelColumn}>
        <h3 className={styles.label}>Default Crop Option</h3>
        <p>By default, all variants & new syncs will use the selected crop option.</p>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <SelectableOption
          className={styles.option}
          selected={cropOption === CropOption.None}
          onClick={() => setCropOption(CropOption.None)}
        >
          <div className={styles.optionTitle}>No Crop</div>
        </SelectableOption>

        <SelectableOption
          className={styles.option}
          selected={cropOption === CropOption.Auto}
          onClick={() => setCropOption(CropOption.Auto)}
        >
          <div className={styles.optionTitle}>Automatic Cropping</div>
        </SelectableOption>
      </section>

      <hr />

      <section className={classNames(styles.rowContent, styles.saveButtonRow)}>
        <Button text="Save" onClick={toggleConfirmModal} loading={isSubmitting} />
      </section>
    </div>
  )
}
