import classNames from 'classnames'

import { FOOTER_HEIGHT } from '@features/embedding'

import { BORDER_COUNT, BORDER_WIDTH, FILTER_EMPTY_PADDING } from './metabase-embed'

import overlayStyles from './dashboard-element-overlay.module.css'

interface DashboardFullOverlayProps {
  width: number
  height: number
  onClick?: () => void
  hadFilters?: boolean
}

export const DashboardFullOverlay = ({ width, height, onClick, hadFilters }: DashboardFullOverlayProps) => {
  const overlayAreaClassName = classNames(overlayStyles.overlayArea, { [overlayStyles.overlayAreaSelected]: true })
  const overlayBadgeClassName = classNames(overlayStyles.overlayBadge, { [overlayStyles.overlayBadgeSelected]: true })

  const footerExtra = hadFilters ? FILTER_EMPTY_PADDING : 0

  return (
    <div
      onClick={onClick}
      className={overlayAreaClassName}
      style={{
        position: 'absolute',
        top: footerExtra,
        left: 0,
        width: width - BORDER_WIDTH * BORDER_COUNT,
        height: height - FOOTER_HEIGHT - footerExtra,
      }}
    >
      <div className={overlayBadgeClassName}>
        <div className={overlayStyles.badgeContent}>Full dashboard</div>
      </div>
    </div>
  )
}
