import { getOneDriveFolderName } from '@features/drive/one-drive/api'
import { useAuthStore, useRefreshMicrosoftAccessToken } from '@features/users'

import { oneDrivePickerErrorToast } from '@shared/components'
import { config } from '@shared/config'

import { MicrosoftPickerSuccessResponse, OneDrivePickerType } from './types'
import { useOneDrivePicker } from './use-onedrive-picker'

interface IOneDriveObject {
  id: string
  driveId: string
  name: string
  serviceId: 'pres' | 'folder' | 'doc'
  type: string
}

export const useOpenOneDrivePicker = (cb: (doc: IOneDriveObject[]) => void, cancelCb?: () => void) => {
  const { createPicker } = useOneDrivePicker()
  const refreshAccessTokenQuery = useRefreshMicrosoftAccessToken()

  const { clientId } = config.microsoft

  const { getState } = useAuthStore

  const openOneDrivePicker = async (fileType: OneDrivePickerType) => {
    const { user } = getState()
    if (!user || !user.microsoftOAuth) {
      throw new Error('User is not authenticated')
    }

    let { microsoftOAuth } = user
    let accessToken = microsoftOAuth.accessToken
    if (Date.parse(microsoftOAuth.expiredAt) < Date.now()) {
      console.log('Access token expired, refreshing')
      const response = await refreshAccessTokenQuery.mutateAsync()
      accessToken = response.data.accessToken
    }

    const isFolderPicker = fileType === OneDrivePickerType.Folder
    const isPowerPointPicker = fileType === OneDrivePickerType.PowerPoint

    createPicker({
      action: 'query',
      advanced: {
        // provide "accessToken" to skip second auth
        accessToken,
        // Required if "accessToken" is provided
        endpointHint: microsoftOAuth.driveUrl,
        // filter files by extension
        filter: isFolderPicker ? 'folder' : isPowerPointPicker ? '.pptx' : '.docx',
      },
      viewType: isFolderPicker ? 'folders' : 'files',
      clientId: clientId,
      multiSelect: false,
      success: async (data: MicrosoftPickerSuccessResponse, ...rest) => {
        console.log('OneDrive picker: user selected a file', data, rest)

        if (data.errorCode) {
          oneDrivePickerErrorToast(`Error: ${data.errorCode} ${data.message}`)
        } else {
          const fileId = data.value[0].id
          const driveId = data.value[0].parentReference.driveId
          let name = data.value[0].name

          if (isFolderPicker && !name) {
            name = await getOneDriveFolderName(fileId, driveId, accessToken)
          }

          cb([
            {
              id: fileId,
              driveId,
              name: name,
              serviceId:
                fileType === OneDrivePickerType.Folder
                  ? 'folder'
                  : fileType === OneDrivePickerType.PowerPoint
                    ? 'pres'
                    : 'doc',
              type: fileType,
            },
          ])
        }
      },
      cancel: () => {
        console.log('OneDrive picker: cancelled')
        cancelCb && cancelCb()
      },
      error: err => {
        console.error('OneDrive picker: error', err)
      },
    })
  }

  return { openOneDrivePicker }
}
