import { FC, PropsWithChildren } from 'react'

import { presentationSlidesThumbnailsToast } from '@shared/components'
import { SocketEvent, useAuthenticatedSocket } from '@shared/hooks'
import { queryClient } from '@shared/http'

export interface SlidesThumbnailsEventArgs {
  destinationId: string
  status: 'success' | 'error'
  error?: any
}

export const SlidesThumbnailsListener: FC<PropsWithChildren> = ({ children }) => {
  const slidesThumbnailsEvents: SocketEvent[] = [
    {
      name: 'destinationSlidesThumbnailsUpdate',
      handler: async ({ destinationId, status, error }: SlidesThumbnailsEventArgs) => {
        if (status === 'success') {
          queryClient.invalidateQueries(['slides-thumbnails', destinationId])
        } else {
          const errorMessage =
            error?.metadata?.code === 'export-size-limit-exceeded'
              ? 'Presentation size limit exceeded'
              : 'Failed to generate slide previews'

          presentationSlidesThumbnailsToast(errorMessage)
        }
      },
    },
  ]

  useAuthenticatedSocket(slidesThumbnailsEvents)

  return children
}
