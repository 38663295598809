import { FC, useCallback, useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { RollstackTabs } from '@shared/components/tabs/tabs'

export interface Tab {
  tabComponent: JSX.Element
  path: string
  tabIndex: number
  tabPanel: (key: number | string) => JSX.Element
}

export interface RoutedTabsProps {
  tabs: Tab[]
  defaultTabIndex: number
  defaultRoute: string
  tabPanelClassName?: string
  selectedTabPanelClassName?: string
}

export const RoutedTabs: FC<RoutedTabsProps> = ({
  tabs,
  defaultTabIndex,
  defaultRoute,
  tabPanelClassName,
  selectedTabPanelClassName,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const getTabFromRoute = useCallback((): Tab => {
    const tabFromRoute = tabs.find(tab => {
      const match = matchPath({ path: tab.path, end: false }, location.pathname)
      return !!match
    })

    if (tabFromRoute) {
      return tabFromRoute
    }

    navigate(defaultRoute)
    const defaultTab = tabs.find(tab => tab.tabIndex === defaultTabIndex)
    if (defaultTab) {
      return defaultTab
    }
    console.warn('Default tab index out of range', defaultTabIndex)
    return tabs[0]!
  }, [tabs, navigate, defaultRoute, defaultTabIndex, location.pathname])

  useEffect(() => {
    setSelectedTab(getTabFromRoute())
  }, [location.pathname, getTabFromRoute])

  const [selectedTab, setSelectedTab] = useState<Tab>(getTabFromRoute())

  const handleTabSelect = (idx: number) => {
    const selectedTab = tabs.find(tab => tab.tabIndex === idx)
    if (!selectedTab) {
      console.warn('Settings tab index out of range', idx)
      return
    }
    navigateToTab(selectedTab)
  }

  const navigateToTab = (tab: Tab) => {
    setSelectedTab(tab)
    navigate(tab.path)
  }

  if (!tabs.length) {
    console.warn('Routed tabs should have at least 1 tab provided')
    return null
  }

  return (
    <RollstackTabs
      tabs={tabs.map(tab => tab.tabComponent)}
      defaultIndex={selectedTab.tabIndex}
      selectedIndex={selectedTab.tabIndex}
      onSelect={handleTabSelect}
      tabPanelClassName={tabPanelClassName}
      selectedTabPanelClassName={selectedTabPanelClassName}
    >
      {tabs.map(tab => tab.tabPanel(tab.tabIndex))}
    </RollstackTabs>
  )
}
