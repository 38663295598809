import { useEffect, useState } from 'react'

type ObjectWithId = { id: number }
type ItemWithUserId = { userId: number }

const noFilter = () => (item: any) => item

const onlyMeFilter =
  ({ id }: ObjectWithId) =>
  ({ userId }: ItemWithUserId) =>
    userId === id

const sharedWithMeFilter =
  ({ id }: ObjectWithId) =>
  ({ userId }: ItemWithUserId) =>
    userId !== id

export const filteringOptions = [
  { value: 'all', label: 'All', appliedFilter: noFilter },
  { value: 'me', label: 'Me', appliedFilter: onlyMeFilter },
  { value: 'sharedWithMe', label: 'Shared with me', appliedFilter: sharedWithMeFilter },
]

export const useFilter = (sortTypeLocalKey: string) => {
  const [filteringOption, setFilteringOption] = useState(filteringOptions[0])

  useEffect(() => {
    const filteringType = localStorage.getItem(sortTypeLocalKey)
    if (filteringType) {
      setFilteringOption(filteringOptions.find(option => option.value === filteringType)!)
    } else localStorage.setItem(sortTypeLocalKey, filteringOptions[0].value.toString())
  }, [sortTypeLocalKey])

  const handleFilterTypeChange = (option: any) => {
    localStorage.setItem(sortTypeLocalKey, option.value)
    setFilteringOption(option)
  }

  return {
    filteringOption,
    handleFilterTypeChange,
  }
}
