import { DirectorySyncProviders, DirectoryType } from '@boxyhq/saml-jackson/dist/typings'
import React, { useState } from 'react'

import { Button, ImageWithFallback, Modal } from '@shared/components'

import { AzureTutorialSections } from './tutorial-data/azure-tutorial-data'
import { OktaTutorialSections } from './tutorial-data/okta-tutorial-data'
import { TemplateTutorialSections } from './tutorial-data/template-tutorial-data'
import { ETutorialSections, ITutorialDataSections } from './types'

import styles from './directory-sync-tutorial.module.css'

type StepProps = {
  text: string
  imgUrl?: string
}

const Step: React.FC<StepProps> = ({ text, imgUrl }) => {
  return (
    <div className={styles.tutorialStep}>
      <p className={styles.stepText}>{text}</p>
      <ImageWithFallback src={imgUrl} alt={text} className={styles.stepImage} />
    </div>
  )
}

const AllTutorialSections: Record<keyof typeof DirectorySyncProviders, ITutorialDataSections> = {
  'okta-scim-v2': OktaTutorialSections,
  'azure-scim-v2': AzureTutorialSections,
  // TODO: to be completed later
  'onelogin-scim-v2': TemplateTutorialSections,
  'jumpcloud-scim-v2': TemplateTutorialSections,
  'generic-scim-v2': TemplateTutorialSections,
  google: TemplateTutorialSections,
}

interface IDirectorySyncTutorialProps {
  type: DirectoryType
  open: boolean
  toggle: () => void
}

export const DirectorySyncTutorial: React.FC<IDirectorySyncTutorialProps> = ({ type, open, toggle }) => {
  const [section, setSection] = useState<ETutorialSections>(ETutorialSections.SETUP)

  const tutorialSections = AllTutorialSections[type]
  const sectionKeys = Object.keys(tutorialSections) as ETutorialSections[]

  const setNextSection = () => {
    const currentIndex = sectionKeys.indexOf(section)
    if (currentIndex < sectionKeys.length - 1) {
      setSection(sectionKeys[currentIndex + 1])
    }
  }

  const setPrevSection = () => {
    const currentIndex = sectionKeys.indexOf(section)
    if (currentIndex > 0) {
      setSection(sectionKeys[currentIndex - 1])
    }
  }

  const renderSection = () => {
    const currentSection = tutorialSections[section]
    if (!currentSection) return null

    return (
      <>
        <h1 className={styles.tutorialTitle}>{currentSection.title}</h1>
        <p className={styles.tutorialDescription}>{currentSection.description}</p>
        {currentSection.steps.map((step, index) => (
          <Step key={index} text={step.text} imgUrl={step.imgUrl} />
        ))}
      </>
    )
  }

  return (
    <Modal open={open} onBackgroundClick={toggle} className={styles.modal}>
      <div className={styles.tutorialContainer}>
        {renderSection()}
        <div className={styles.navigationButtons}>
          <Button text="Back" onClick={setPrevSection} secondaryGray disabled={section === ETutorialSections.SETUP} />
          <Button
            text="Next"
            onClick={setNextSection}
            secondaryGray
            disabled={section === ETutorialSections.PROVISIONING}
          />
        </div>
      </div>
    </Modal>
  )
}
