import classNames from 'classnames'
import React, { useMemo, useRef, useState } from 'react'

import { UploadIcon } from '@assets'

import styles from './file-input.module.css'

// TODO: extend list if needed
type FileType = '.csv' | '.pdf' | '.doc' | '.ppt'

interface FileInputProps extends React.HTMLAttributes<HTMLInputElement> {
  fileType: FileType
  disabled?: boolean
  onFileSelected?: (file: File) => void
  label?: string
  containerClassName?: string
}

export const FileInput: React.FC<FileInputProps> = ({ onFileSelected, fileType, disabled, label, ...props }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [isDragOver, setIsDragOver] = useState(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file && file.name.endsWith(fileType)) {
      onFileSelected && onFileSelected(file)
    } else {
      alert(`Please select a valid ${fileType} file.`)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) {
      return
    }
    event.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = () => {
    if (disabled) {
      return
    }
    setIsDragOver(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) {
      return
    }
    event.preventDefault()
    setIsDragOver(false)

    const file = event.dataTransfer.files && event.dataTransfer.files[0]
    if (file && file.name.endsWith(fileType)) {
      onFileSelected && onFileSelected(file)
    } else {
      alert(`Please drop a valid ${fileType} file.`)
    }
  }

  const fileTypeLabel = useMemo(() => {
    switch (fileType) {
      case '.csv':
        return 'CSV'
      case '.pdf':
        return 'PDF'
      case '.doc':
        return 'DOC'
      case '.ppt':
        return 'PPT'
      default:
        return ''
    }
  }, [fileType])

  return (
    <div className={props.containerClassName}>
      {label && (
        <label htmlFor={props.id || 'file-input'} className={styles.title}>
          {label}
        </label>
      )}
      <input
        id={props.id || 'file-input'}
        type="file"
        ref={fileInputRef}
        accept={fileType}
        onChange={handleFileChange}
        className={styles.fileInput}
        disabled={disabled}
      />
      <div
        onClick={() => fileInputRef.current?.click()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={classNames(styles.fileDropZone, isDragOver && styles.fileDropZoneDragOver)}
      >
        <div className={classNames(styles.fileDropOverlay, disabled && styles.fileDropDisabled)}></div>
        <div className={styles.fileDropContent}>
          <UploadIcon className={styles.fileDropIcon} />
          <div className={styles.label}>
            {isDragOver ? (
              'Release to drop'
            ) : (
              <div>
                <span className={styles.clickToUpload}>Click to upload </span>
                {`or drag and drop a ${fileTypeLabel} file`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
