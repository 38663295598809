import { useNavigate } from 'react-router-dom'

import { useOrganizationsStore } from '@features/organizations'
import { UserOrganizationRole } from '@features/organizations/types'
import { useAuthStore } from '@features/users'

export const ProtectedRoute = ({
  requiredRole = UserOrganizationRole.member,
  redirectTo = -1,
  children,
}: {
  requiredRole?: UserOrganizationRole
  redirectTo?: string | number
  children: JSX.Element
}) => {
  const { user } = useAuthStore()
  const { activeOrganization } = useOrganizationsStore()
  const navigate = useNavigate()

  const redirect = (redirectTo: string | number) => {
    if (typeof redirectTo === 'string') {
      navigate(redirectTo, { replace: true })
    } else {
      navigate(redirectTo)
    }
    return <></>
  }

  if (!user || !activeOrganization) {
    return redirect(redirectTo)
  }

  if (requiredRole === UserOrganizationRole.member) {
    return children
  }

  if (
    requiredRole === UserOrganizationRole.admin &&
    activeOrganization.role !== UserOrganizationRole.admin &&
    activeOrganization.role !== UserOrganizationRole.owner
  ) {
    return redirect(redirectTo)
  }

  if (requiredRole === UserOrganizationRole.owner && activeOrganization.role !== UserOrganizationRole.owner) {
    return redirect(redirectTo)
  }

  return children
}
