import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 24

export const AlertIcon: FC<Pick<IIcon, 'color' | 'size'>> = ({ color = '#344054', size = defaultSize }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9998 9.50023V13.5002M11.9998 17.5002H12.0098M10.6151 4.39195L2.39019 18.5986C1.93398 19.3866 1.70588 19.7806 1.73959 20.1039C1.769 20.386 1.91677 20.6423 2.14613 20.809C2.40908 21.0002 2.86435 21.0002 3.77487 21.0002H20.2246C21.1352 21.0002 21.5904 21.0002 21.8534 20.809C22.0827 20.6423 22.2305 20.386 22.2599 20.1039C22.2936 19.7806 22.0655 19.3866 21.6093 18.5986L13.3844 4.39195C12.9299 3.60679 12.7026 3.21421 12.4061 3.08235C12.1474 2.96734 11.8521 2.96734 11.5935 3.08235C11.2969 3.21421 11.0696 3.60679 10.6151 4.39195Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
