import { defaultPreviewHeight, defaultPreviewWidth } from '.'

import { MsPowerPointIcon } from '@assets'

import { ViewIdOptions } from '@features/drive/google-drive/types'
import { MSPowerpoint } from '@features/embedding'

import { AbstractDestinationHandler } from './destination-handler'

const defaultIconSize = 14

export class MicrosoftPowerpointHandler extends AbstractDestinationHandler {
  override getTitle() {
    return 'presentation'
  }

  override getTitlePlural() {
    return 'presentations'
  }

  override getCloudAppTitle(): string {
    return 'Microsoft'
  }

  override getCloudTitle(): string {
    return 'OneDrive'
  }

  override getShortNamePlural() {
    return 'slides'
  }

  override getDestinationTitle(isFileDeleted = false) {
    return isFileDeleted ? 'File Not Found In OneDrive' : 'Open destination'
  }

  override getDestinationUrl() {
    return this.presentation?.docUrl ?? ''
  }

  override getGroupData(slideIndex: number) {
    return {
      group: `Slide ${slideIndex.toString()}`,
      groupIndex: slideIndex,
    }
  }

  override getGPickerCustomViews() {
    return [google.picker.ViewId.PRESENTATIONS]
  }

  override getGPickerViewId(): ViewIdOptions {
    return 'PRESENTATIONS'
  }

  override getSmallIcon(iconSize = defaultIconSize, className?: string) {
    return <MsPowerPointIcon size={iconSize} className={className} />
  }

  override getPreview(
    itemsInPageCount?: number,
    width = defaultPreviewWidth,
    height = defaultPreviewHeight,
    className?: string
  ) {
    return this.presentation ? (
      <MSPowerpoint
        className={className}
        destinationId={this.presentation.id}
        height={height}
        title={this.presentation.name}
        width={width}
      />
    ) : null
  }

  override getAddVizStep1() {
    return { title: 'Destination slide', subtitle: 'Select the slide where the visualization should be inserted.' }
  }

  override getDateDelimiter() {
    return '-'
  }
}
