import { FC } from 'react'

export const DestinationsPlaceholder: FC = () => {
  return (
    <svg width="176" height="101" viewBox="0 0 176 101" fill="none">
      <g opacity="0.6">
        <g clipPath="url(#clip0_15681_110451)">
          <path
            d="M175.5 0.5V8.50024C144.359 8.50024 137.809 8.50024 89.4831 8.50024C41.1575 8.50024 50.9266 8.50024 0.5 8.50024V0.5C37.5792 0.5 41.1575 0.5 89.4831 0.5C137.809 0.5 138.424 0.5 175.5 0.5Z"
            fill="#7DB0DE"
          />
          <rect x="0.5" y="8.36133" width="175" height="92.1376" fill="#DFEAF4" />
          <path
            d="M114.816 56.502C116.339 56.502 117.573 57.7364 117.573 59.2591V76.7455C117.573 78.2682 116.339 79.5027 114.816 79.5027C114.124 79.5027 113.27 79.5027 111.921 79.5027C110.158 79.5027 109.572 79.5027 108.832 79.5027C107.309 79.5027 106.074 78.2682 106.074 76.7455V59.2591C106.074 57.7364 107.308 56.502 108.831 56.502C109.565 56.502 110.41 56.502 111.921 56.502C113.356 56.502 114.151 56.502 114.816 56.502Z"
            fill="#7DB0DE"
          />
          <path
            d="M68.7193 52.1914C69.9604 52.1914 70.9709 53.1975 70.9709 54.4387V54.4387C70.9709 55.6798 69.9604 56.6859 68.7193 56.6859C60.7252 56.6859 57.8433 56.6859 43.838 56.6859C29.3605 56.6859 31.4124 56.6859 18.0288 56.6859C16.7877 56.6859 15.7695 55.6798 15.7695 54.4387V54.4387C15.7695 53.1975 16.7794 52.1914 18.0206 52.1914C27.6849 52.1914 29.6328 52.1914 43.838 52.1914C58.0366 52.1914 59.1783 52.1914 68.7193 52.1914Z"
            fill="#7DB0DE"
          />
          <path
            d="M68.7193 40.9531C69.9604 40.9531 70.9709 41.9593 70.9709 43.2004V43.2004C70.9709 44.4415 69.9604 45.4476 68.7193 45.4476C60.7252 45.4476 57.8433 45.4476 43.838 45.4476C29.3605 45.4476 31.4124 45.4476 18.0288 45.4476C16.7877 45.4476 15.7695 44.4415 15.7695 43.2004V43.2004C15.7695 41.9593 16.7794 40.9531 18.0206 40.9531C27.6849 40.9531 29.6328 40.9531 43.838 40.9531C58.0366 40.9531 59.1783 40.9531 68.7193 40.9531Z"
            fill="#7DB0DE"
          />
          <path
            d="M115.696 18.4805C116.937 18.4805 117.951 19.4866 117.951 20.7277V20.7277C117.951 21.9689 116.936 22.975 115.695 22.975C99.3962 22.975 94.737 22.975 67.7261 22.975C40.2543 22.975 44.9357 22.975 18.0298 22.975C16.7886 22.975 15.7695 21.9689 15.7695 20.7277V20.7277C15.7695 19.4866 16.7821 18.4805 18.0232 18.4805C37.603 18.4805 40.5201 18.4805 67.7261 18.4805C94.9287 18.4805 96.2404 18.4805 115.696 18.4805Z"
            fill="#7DB0DE"
          />
          <path
            d="M68.7193 63.4277C69.9604 63.4277 70.9709 64.4339 70.9709 65.675V65.675C70.9709 66.9161 69.9604 67.9223 68.7193 67.9223C60.7252 67.9223 57.8433 67.9223 43.838 67.9223C29.3605 67.9223 31.4124 67.9223 18.0288 67.9223C16.7877 67.9223 15.7695 66.9161 15.7695 65.675V65.675C15.7695 64.4339 16.7794 63.4277 18.0206 63.4277C27.6849 63.4277 29.6328 63.4277 43.838 63.4277C58.0366 63.4277 59.1783 63.4277 68.7193 63.4277Z"
            fill="#7DB0DE"
          />
          <path
            d="M68.7193 74.6621C69.9604 74.6621 70.9709 75.6682 70.9709 76.9094V76.9094C70.9709 78.1505 69.9604 79.1566 68.7193 79.1566C60.7252 79.1566 57.8433 79.1566 43.838 79.1566C29.3605 79.1566 31.4124 79.1566 18.0288 79.1566C16.7877 79.1566 15.7695 78.1505 15.7695 76.9094V76.9094C15.7695 75.6682 16.7794 74.6621 18.0206 74.6621C27.6849 74.6621 29.6328 74.6621 43.838 74.6621C58.0366 74.6621 59.1783 74.6621 68.7193 74.6621Z"
            fill="#7DB0DE"
          />
          <path
            d="M136.769 45.5C138.292 45.5 139.526 46.7344 139.526 48.2571V76.7439C139.526 78.2666 138.292 79.501 136.769 79.501C135.967 79.501 134.999 79.501 133.361 79.501C131.244 79.501 130.682 79.501 129.74 79.501C128.218 79.501 126.982 78.2666 126.982 76.7439V48.2571C126.982 46.7344 128.217 45.5 129.739 45.5C130.603 45.5 131.538 45.5 133.361 45.5C135.11 45.5 135.987 45.5 136.769 45.5Z"
            fill="#7DB0DE"
          />
          <path
            d="M157.476 34.209C158.999 34.209 160.233 35.4434 160.233 36.9661V76.7442C160.233 78.267 158.999 79.5014 157.476 79.5014C156.758 79.5014 155.877 79.5014 154.46 79.5014C152.613 79.5014 152.03 79.5014 151.246 79.5014C149.723 79.5014 148.488 78.267 148.488 76.7442V36.9661C148.488 35.4434 149.722 34.209 151.245 34.209C152.008 34.209 152.876 34.209 154.46 34.209C155.969 34.209 156.785 34.209 157.476 34.209Z"
            fill="#7DB0DE"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_15681_110451">
          <rect x="0.5" y="0.5" width="175" height="100" rx="3.67619" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
