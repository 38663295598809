import { useNavigate } from 'react-router-dom'

import { buildAddVisualizationPath } from '@pages/routes/paths'

import { ConfirmModal } from '@shared/components/confirm-modal/confirm-modal'

export const SaveChangesModal = ({
  open,
  targetId,
  closeModal,
  upsertTemplate,
}: {
  open: boolean
  targetId: string
  closeModal: () => void
  upsertTemplate: () => Promise<unknown>
}) => {
  const navigate = useNavigate()
  const navigateToVisualizations = () => {
    navigate(buildAddVisualizationPath(targetId), { replace: true })
  }

  return (
    <ConfirmModal
      title="Do you want to save the template?"
      subtitle="If not, your changes will be lost."
      open={open}
      cancelText="Continue without saving"
      onCancel={() => {
        navigateToVisualizations()
      }}
      onConfirm={async () => {
        await upsertTemplate()
        navigateToVisualizations()
      }}
      onClose={() => closeModal()}
      confirmText="Save"
    />
  )
}
