import { FC } from 'react'

import { IIcon } from '@assets/types'

export const CBInsights: FC<IIcon> = () => {
  return (
    <svg width="102" height="13" viewBox="0 0 102 13" fill="none">
      <path
        d="M30.0334 9.09877C29.1903 11.1028 27.0757 12.3605 24.9749 12.3605C21.8098 12.3605 19.2529 9.69308 19.2529 6.59716C19.2806 3.47359 21.8236 0.944336 24.9472 0.944336C26.7716 0.944336 29.0521 1.89799 30.1163 4.27522H27.7944C27.0204 3.36303 26.0667 2.83783 24.8366 2.83783C23.0399 2.83783 21.257 4.45489 21.257 6.63862C21.257 8.71178 22.9846 10.4671 24.9058 10.4671C26.3846 10.4671 27.1862 9.62397 27.6838 9.09877H30.0334Z"
        fill="#003366"
      />
      <path
        d="M31.5117 1.15137H33.6954C34.9255 1.15137 36.1141 1.15137 37.0678 1.99445C37.6483 2.51965 37.9938 3.25217 38.0214 4.03997C38.0214 5.40826 37.1369 5.93346 36.7914 6.14078C37.5239 6.4172 38.6849 7.02532 38.6849 8.87735C38.7125 9.92775 38.2011 10.909 37.3166 11.4895C36.4735 12.0562 35.2158 12.1529 34.4694 12.1529H31.5117V1.15137ZM33.5158 5.4359H34.1792C34.6767 5.4359 35.9345 5.4359 35.9345 4.24729C35.9345 3.58388 35.5198 3.05868 34.3589 3.05868H33.5158V5.4359ZM33.5158 10.2456H34.5385C34.9255 10.2456 36.5702 10.2456 36.5702 8.80824C36.5702 8.35215 36.3629 7.32939 34.5662 7.32939H33.5158V10.2456Z"
        fill="#003366"
      />
      <path d="M40.3301 1.15137H41.4219V12.1529H40.3301V1.15137Z" fill="#006699" />
      <path
        d="M43.8125 1.15137H45.2914L51.4003 10.2595H51.4417V1.15137H52.5336V12.1529H51.4832L44.9873 2.49201H44.9458V12.1529H43.854V1.15137H43.8125Z"
        fill="#006699"
      />
      <path
        d="M55.2707 9.05686C55.3398 10.4251 56.2244 11.3373 57.5512 11.3373C58.7536 11.365 59.7349 10.3975 59.7626 9.20889C59.7626 9.19507 59.7626 9.18125 59.7626 9.16743C59.7626 7.48126 58.0349 7.0528 57.3024 6.81784C56.3211 6.54142 54.4553 6.11297 54.4553 3.94306C54.4553 2.21543 55.7959 0.985352 57.5512 0.985352C59.3479 0.985352 60.6057 2.35364 60.6057 3.9707H59.5138C59.5138 2.85119 58.6016 1.96664 57.5788 1.96664C56.3211 1.96664 55.5748 2.9203 55.5748 3.86013C55.5748 5.20078 56.7358 5.51866 58.1732 5.9333C60.8406 6.63817 60.8406 8.73897 60.8406 9.09832C60.8268 10.8951 59.3479 12.3463 57.5512 12.3325C57.5512 12.3325 57.5512 12.3325 57.5374 12.3325C56.1 12.3325 54.1927 11.4894 54.165 9.07068L55.2707 9.05686Z"
        fill="#006699"
      />
      <path d="M62.5127 1.15137H63.6046V12.1529H62.5127V1.15137Z" fill="#006699" />
      <path
        d="M77.1635 7.09471C76.9147 10.3565 74.0676 12.3329 71.3586 12.3329C68.0969 12.3329 65.457 9.66544 65.457 6.63862C65.457 3.79148 67.8066 0.944336 71.331 0.944336C74.2472 0.944336 76.1131 2.94839 76.5277 4.0679H75.2562C74.6204 2.97603 73.183 1.96709 71.3586 1.96709C68.7326 1.95327 66.6042 4.05408 66.5765 6.68008C66.5765 9.14023 68.5806 11.3516 71.4277 11.3516C73.7497 11.3516 75.4635 9.62397 75.8505 8.11747H69.7416V7.09471H77.1635Z"
        fill="#006699"
      />
      <path
        d="M79.0986 1.15137H80.1905V6.00257H85.8848V1.15137H86.9766V12.1529H85.8848V6.98386H80.1905V12.1529H79.0986V1.15137Z"
        fill="#006699"
      />
      <path d="M90.8593 2.17413H88.3301V1.15137H94.439V2.17413H91.9512V12.1529H90.8593V2.17413Z" fill="#006699" />
      <path
        d="M96.1397 9.05701C96.2088 10.4253 97.0934 11.3375 98.4202 11.3375C99.5812 11.3375 100.632 10.4529 100.632 9.15375C100.632 7.46758 98.9039 7.03913 98.1714 6.80417C97.1901 6.52775 95.3243 6.0993 95.3243 3.92939C95.3243 2.20175 96.6649 0.97168 98.4202 0.97168C100.217 0.97168 101.475 2.33997 101.475 3.95703H100.383C100.383 2.83753 99.4706 1.95298 98.4478 1.95298C97.1763 1.95298 96.4438 2.90663 96.4438 3.84646C96.4438 5.18711 97.6047 5.50499 99.0421 5.91962C101.723 6.61068 101.723 8.7253 101.723 9.07083C101.71 10.8676 100.231 12.3188 98.434 12.305C98.434 12.305 98.434 12.305 98.4202 12.305C96.9828 12.305 95.0755 11.4619 95.0479 9.04319L96.1397 9.05701Z"
        fill="#006699"
      />
      <path
        d="M16.0883 5.8923H11.168V1.12402H14.0842C15.2037 1.12402 16.1159 2.03621 16.1159 3.15572V5.8923H16.0883Z"
        fill="#006699"
      />
      <path
        d="M14.0569 12.1799H11.1406V7.09375H16.0609V10.1482C16.0886 11.2401 15.2178 12.1523 14.1122 12.1799C14.0845 12.1799 14.0707 12.1799 14.0569 12.1799Z"
        fill="#FF6633"
      />
      <path
        d="M5.40496 7.09473V5.86465H10.2285V1.12402H2.2814C1.17571 1.12402 0.277344 2.03621 0.277344 3.1419V3.15572V10.1906C0.277344 11.3102 1.18954 12.2223 2.32286 12.2223H10.27V7.09473H5.40496Z"
        fill="#003366"
      />
    </svg>
  )
}
