import { ChangeEvent, FC, useState } from 'react'

import { EmailIcon, SendRightIcon } from '@assets'

import { useSendDestinationTestEmail } from '@features/destinations/api/api'
import { Destination } from '@features/destinations/types'
import { isEmailDestination } from '@features/destinations/utils'
import { SelectEmailTemplate } from '@features/emails/components/select-email-template'
import { EEmailTemplateType, ESelectEmailTemplateEditorMode } from '@features/emails/types'

import { ActionModal, Button, Input, successToast } from '@shared/components'
import { isValidEmail } from '@shared/utils'

import styles from './destination-email-modal.module.css'

export interface IDestinationTestEmailModal {
  destination: Destination
  initialTemplateId?: string | null
  isOpen: boolean
  onClose: () => void
}

export const DestinationTestEmailModal: FC<IDestinationTestEmailModal> = ({
  destination,
  isOpen,
  onClose,
  initialTemplateId,
}) => {
  const { mutateAsync: sendEmail, isLoading } = useSendDestinationTestEmail(destination, {
    onSuccess: () => {
      successToast('Test email sent')
      onClose()
    },
  })
  const [recipient, setRecipient] = useState('')
  const [recipientError, setRecipientError] = useState('')
  const [name, setName] = useState('')
  const [emailTemplateId, setEmailTemplateId] = useState<string | undefined | null>(initialTemplateId)

  const handleRecipientChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientError('')
    setRecipient(e.target.value)
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientError('')
    setName(e.target.value)
  }

  const onSend = async () => {
    if (!recipient) {
      setRecipientError('Email is required')
      return
    }
    if (!isValidEmail(recipient)) {
      setRecipientError('Invalid email')
      return
    }

    await sendEmail({ recipient, emailTemplateId, name })
  }

  return (
    <ActionModal
      open={isOpen}
      title="Test email"
      subtitle="Fill these information to receive a test email"
      Icon={EmailIcon}
      btns={[
        <Button key="cancel" text="Cancel" fullWidth secondaryGray onClick={onClose} />,
        <Button
          key="send"
          text="Send email"
          iconLeading={<SendRightIcon />}
          fullWidth
          onClick={onSend}
          loading={isLoading}
        />,
      ]}
      onBackgroundClick={onClose}
    >
      <section className={styles.container}>
        <div className={styles.row}>
          <label htmlFor="test-recipient" className={styles.label}>
            Recipient email
          </label>
          <Input id="test-recipient" className={styles.input} value={recipient} onChange={handleRecipientChange} />
          {recipientError && <label className={styles.errorLabel}>{recipientError}</label>}
        </div>
        <div className={styles.row}>
          <label htmlFor="test-recipient-name" className={styles.label}>
            Recipient name
          </label>
          <Input id="test-recipient-name" className={styles.input} value={name} onChange={handleNameChange} />
        </div>
        {!isEmailDestination(destination) && (
          <div className={styles.row}>
            <label htmlFor="test-template" className={styles.label}>
              Template
            </label>
            <SelectEmailTemplate
              targetId={destination.id}
              selectClassName="w-full"
              containerClassName="w-full"
              destinationType={destination.type}
              templateType={EEmailTemplateType.destinationUpdate}
              onSelectTemplate={templateId => {
                setEmailTemplateId(templateId)
              }}
              initialTemplateId={emailTemplateId}
              editorMode={ESelectEmailTemplateEditorMode.SELECT_ONLY}
            />
          </div>
        )}
      </section>
    </ActionModal>
  )
}
