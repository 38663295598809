import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 20

export const SendRightIcon: FC<IIcon> = ({ size = defaultSize, color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24987 10.5003H4.66653M4.59599 10.7432L2.65022 16.5555C2.49736 17.0121 2.42093 17.2404 2.47578 17.381C2.52341 17.5031 2.62571 17.5957 2.75195 17.6309C2.89731 17.6715 3.11687 17.5727 3.55598 17.3751L17.4822 11.1083C17.9108 10.9154 18.1251 10.819 18.1913 10.685C18.2489 10.5686 18.2489 10.432 18.1913 10.3156C18.1251 10.1817 17.9108 10.0852 17.4822 9.89237L3.55112 3.62342C3.11334 3.42641 2.89444 3.32791 2.74922 3.36832C2.62311 3.40341 2.52082 3.49573 2.47302 3.6176C2.41798 3.75793 2.4936 3.98574 2.64482 4.44137L4.59653 10.3216C4.6225 10.3998 4.63549 10.439 4.64062 10.479C4.64517 10.5145 4.64512 10.5504 4.64048 10.5859C4.63525 10.6259 4.62216 10.665 4.59599 10.7432Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
