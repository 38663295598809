import { AxiosResponse } from 'axios'
import { FC } from 'react'
import { UseMutationResult, UseQueryResult } from 'react-query'

import { Paths } from '@pages/routes/paths'

import { KeyIcon, PlusIcon } from '@assets'
import { SettingsIcon } from '@assets/icons/settings'

import { ManageDefaultDataSourceRequest } from '@features/data-sources'
import { IDataSource } from '@features/data-sources/types/types'

import { ActionModal, Button, PageContainer, Spinner } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { sortByCreatedAt } from '@shared/utils'

import { useUserManagement } from './use-user-management'
import { dataSourceUsersTableColumns, dataSourceUsersTableData } from './user-management-table'

import styles from './user-management.module.css'

interface UserManagementTableProps {
  sourceId: string
  useGetDataSource: (sourceId: string, includeChildCredentials?: boolean) => UseQueryResult<any, Error>
  useDeleteDataSource: () => UseMutationResult<AxiosResponse<any, any>, Error, string, unknown>
  useManageDefaultDataSource: () => UseMutationResult<ManageDefaultDataSourceRequest, any, any, unknown>
  TestModalComponent: FC<any>
  AddChildDataSourceModalComponent: FC<any>
}

export const UserManagement: FC<UserManagementTableProps> = ({
  sourceId,
  useGetDataSource,
  useDeleteDataSource,
  useManageDefaultDataSource,
  TestModalComponent,
  AddChildDataSourceModalComponent,
}) => {
  const {
    user,
    dataSource,
    isLoading,
    deleteModal,
    dataSourceToDelete,
    toggleDeleteModal,
    openDeleteModal,
    addChildDataSourceModal,
    toggleAddChildDataSourceModal,
    testModal,
    dataSourceToUpdate,
    toggleTestModal,
    openTestModal,
    defaultDSModal,
    toggleDefaultDSModal,
    handleSharedDSChange,
    setDefaultDS,
    handleDelete,
    navigate,
  } = useUserManagement({
    useGetDataSource,
    useDeleteDataSource,
    useManageDefaultDataSource,
    sourceId,
  })

  if (isLoading) return <Spinner />
  if (!dataSource) return null

  const childDataSources = dataSource.childCredentials?.sort(sortByCreatedAt) || []
  const defaultDataSourceId = childDataSources.find((ds: IDataSource) => ds.defaultAcrossOrganization)?.id

  return (
    <PageContainer back={() => navigate(Paths.DATA_SOURCES)}>
      <section className={styles.section}>
        <span className="text-md-semibold">Data source user management</span>
        <div className={styles.addButton}>
          <Button text="Add user" iconLeading={<PlusIcon />} onClick={toggleAddChildDataSourceModal} />
        </div>

        <DataGrid
          data={dataSourceUsersTableData(childDataSources, defaultDataSourceId)}
          columns={dataSourceUsersTableColumns({
            userId: user?.id,
            openTestModal,
            openDeleteModal,
            handleSharedDSChange,
          })}
          EmptyState="No activity yet"
        />
      </section>

      <ActionModal
        Icon={SettingsIcon}
        open={defaultDSModal}
        onBackgroundClick={toggleDefaultDSModal}
        title="Use as service account across the organization"
        subtitle={`This will set this account as a service account for the whole organization. This means that all your organization's users will access ${dataSource.type} data in Rollstack using this account.`}
        btns={
          <>
            <Button text="Cancel" secondaryGray fullWidth onClick={toggleDefaultDSModal} />
            <Button text="Confirm" fullWidth onClick={setDefaultDS} />
          </>
        }
      />

      <ActionModal
        open={deleteModal}
        onBackgroundClick={toggleDeleteModal}
        title={`Remove access to data source`}
        subtitle={`Are you sure you want to remove ${dataSourceToDelete?.user?.name}'s access to this data source?`}
        btns={
          <>
            <Button text="Cancel" secondaryGray fullWidth onClick={toggleDeleteModal} />
            <Button text="Delete" primaryError fullWidth onClick={handleDelete} />
          </>
        }
      />

      {dataSourceToUpdate && testModal && (
        <TestModalComponent
          title={`${dataSourceToUpdate.user?.name}'s credentials`}
          Icon={KeyIcon}
          open={testModal}
          toggle={toggleTestModal}
          dataSource={dataSourceToUpdate}
        />
      )}

      {addChildDataSourceModal && (
        <AddChildDataSourceModalComponent toggle={toggleAddChildDataSourceModal} dataSource={dataSource} />
      )}
    </PageContainer>
  )
}
