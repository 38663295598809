import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 20

export const ClockIcon: FC<IIcon> = ({ size = defaultSize, color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_10058_17045)">
        <path
          d="M10 4.99935V9.99935L13.3333 11.666M18.3333 9.99935C18.3333 14.6017 14.6024 18.3327 10 18.3327C5.39763 18.3327 1.66667 14.6017 1.66667 9.99935C1.66667 5.39698 5.39763 1.66602 10 1.66602C14.6024 1.66602 18.3333 5.39698 18.3333 9.99935Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10058_17045">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
