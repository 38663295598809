import { EditorContent, useEditor } from '@tiptap/react'
import { useEffect } from 'react'

import { EmailTemplateMenuBar } from '@features/emails/components/templates/editors/email-template-menu-bar'
import {
  getBaseEditorOptions,
  getPlaceholderSettings,
} from '@features/emails/components/templates/editors/tiptap-settings'
import { decodeHTML } from '@features/emails/components/templates/utils'

import { cn } from '@shared/utils'

import styles from './email-template-rich-text-editor.module.css'

export const EmailTemplateRichTextEditor = ({
  templateId,
  content,
  setContent,
  toggleHTMLEditorMode,
}: {
  templateId?: string
  content: string | null
  setContent: (content: string) => void
  toggleHTMLEditorMode: () => void
}) => {
  const editorOptions = getBaseEditorOptions(decodeHTML(content || ''))
  const baseClasses = editorOptions.editorProps?.attributes?.class || ''

  const editor = useEditor(
    {
      ...editorOptions,
      extensions: [...editorOptions.extensions, getPlaceholderSettings('Body')],
      editorProps: {
        attributes: {
          class: cn('h-full min-h-full', baseClasses),
        },
      },
    },
    [templateId]
  )

  editor?.on('update', ({ editor }) => {
    const rawContent = editor.getText() ? editor.getHTML() : ''
    setContent(rawContent)
  })

  useEffect(() => {
    if (content === null) {
      editor?.commands.clearContent()
    }
  }, [content, editor])

  if (!editor) return null
  return (
    <div className="h-full flex flex-col">
      <EmailTemplateMenuBar editor={editor} toggleHTMLEditorMode={toggleHTMLEditorMode} />
      <EditorContent
        className={cn(styles.editor, 'min-h-[140px] border border-solid border-color-[#e3e3e5] rounded p-4')}
        editor={editor}
      />
    </div>
  )
}
