import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC } from 'react'

import { UpdateIcon } from '@assets'

import { ICollection } from '@features/collections/types'
import { DestinationMenuButton } from '@features/destinations/components/destination-element/destination-menu-button'
import { useDestination } from '@features/destinations/components/destination-element/use-destination'
import { DeletedDestinationTooltip, ViewerDestinationTooltip } from '@features/destinations/components/tooltips'
import {
  WithDestinationOperationsProps,
  withDestinationOperations,
} from '@features/destinations/hocs/with-destination-operations'
import { Destination } from '@features/destinations/types'

import { Button } from '@shared/components'

dayjs.extend(localizedFormat)

type IDestinationActionButtons = {
  collection?: ICollection
  destination: Destination
  isGrid: boolean
} & WithDestinationOperationsProps

const RawDestinationActionButtons: FC<IDestinationActionButtons> = ({
  collection,
  destination,
  updateDestination,
  createVersion,
  isGrid,
}) => {
  const { isViewer, isUpdateRunning, onUpdateClick } = useDestination({
    destination,
    collection,
    updateDestination,
    createVersion,
  })

  const { isFileDeleted } = destination

  return (
    <div className="flex flex-row gap-2">
      <DeletedDestinationTooltip show={isFileDeleted}>
        <ViewerDestinationTooltip show={!isFileDeleted && isViewer}>
          <Button
            secondaryColor={isGrid}
            secondaryGray={!isGrid}
            fullWidth={isGrid}
            text="Update"
            disabled={isViewer || isFileDeleted}
            onClick={onUpdateClick}
            iconLeading={<UpdateIcon />}
            loading={isUpdateRunning}
            stopPropagation
          />
        </ViewerDestinationTooltip>
      </DeletedDestinationTooltip>
      {!isFileDeleted && <DestinationMenuButton destination={destination} />}
    </div>
  )
}

export const DestinationActionButtons = withDestinationOperations(RawDestinationActionButtons)
