import { FC } from 'react'

import { DuplicateIcon } from '@assets'

import { destinationHandlers } from '@features/destinations/destination-handlers'
import { EDestinationType } from '@features/destinations/types'

import { ActionModal, Button, Input, Spinner } from '@shared/components'

interface IDuplicateDestination {
  type: EDestinationType
  visible: boolean
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  processing: boolean
  isCheckingDrivePermission: boolean
  onDuplicate: () => void
  toggleVisibility: () => void
}

export const DuplicateDestination: FC<IDuplicateDestination> = ({
  type,
  visible,
  name,
  setName,
  processing,
  isCheckingDrivePermission,
  onDuplicate,
  toggleVisibility,
}) => {
  const destinationLabel = destinationHandlers(type).getTitle()

  return (
    <ActionModal
      open={visible}
      Icon={DuplicateIcon}
      title={`Duplicate ${destinationLabel}`}
      subtitle={`A copy of the ${destinationLabel} will be created. The new ${destinationLabel} will have the same configuration.`}
      processing={processing}
      onBackgroundClick={toggleVisibility}
      btns={
        <>
          <Button fullWidth secondaryGray text="Cancel" onClick={toggleVisibility} disabled={processing} />
          <Button
            fullWidth
            disabled={!name.trim() || isCheckingDrivePermission}
            text="Duplicate"
            onClick={() => onDuplicate()}
            loading={processing}
          />
        </>
      }
    >
      <div className="h-20 relative">
        {isCheckingDrivePermission ? (
          <Spinner localCenter />
        ) : (
          <Input
            autoFocus
            value={name}
            label={`New ${destinationLabel} name`}
            onChange={e => setName(e.target.value)}
          />
        )}
      </div>
    </ActionModal>
  )
}
