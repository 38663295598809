import { FC } from 'react'

import { IIcon } from '@assets/types'

export const ChevronLeft: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" transform="scale(-1)">
      <path d="M9 18L15 12L9 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
