import { FC } from 'react'

import { IIcon } from '@assets/types'

export const VisualizationIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M15 17.5365V9.20312M10 17.5365V4.20312M5 17.5365V12.5365"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
