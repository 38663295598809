import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import {
  useAddParentMetabaseDataSource,
  useGetMetabaseDataSource,
  useUpdateMetabaseDataSource,
} from '@features/data-sources'
import { EDataSourceType, IDataSourceValidationErrors } from '@features/data-sources/types/types'
import { validateURLAndGetError } from '@features/embedding/shared/utils'

import { updateDataSourceToast } from '@shared/components'
import { useBoolean, useStateReducer } from '@shared/hooks'
import { parseDuration } from '@shared/utils/time'

import { hasNoValidationErrors } from './utils'

const initialState = {
  id: '',
  name: '',
  baseUrl: '',
  username: '',
  password: '',
  embedSecret: '',
  type: EDataSourceType.Metabase as const,
  connection: {
    loading: false,
    connected: false,
    failed: false,
  },
  autoProvision: true,
}

const FIELDS_TO_INVALIDATE_DATA_SOURCE = ['baseUrl', 'username', 'password', 'embedSecret']

export const useMetabaseDataSourcesSettings = () => {
  const [state, updateState] = useStateReducer(initialState)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [modal, toggleModal] = useBoolean(false)
  const [validationErrors, setValidationErrors] = useState<IDataSourceValidationErrors>({})

  const params = useParams()
  const navigate = useNavigate()

  const { data: dataSource } = useGetMetabaseDataSource(params.sourceId)
  const addMetabaseDataSourceMutation = useAddParentMetabaseDataSource()
  const updateMetabaseDataSourceMutation = useUpdateMetabaseDataSource()

  const toggleAutoProvision = () => updateState({ autoProvision: !state.autoProvision })

  const isLoading = addMetabaseDataSourceMutation.isLoading || updateMetabaseDataSourceMutation.isLoading
  const isFormValid = state.name && state.baseUrl && state.embedSecret && hasNoValidationErrors(validationErrors)
  const isUpdate = Boolean(params.sourceId)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    updateState({ [name]: value })
    if (name === 'baseUrl') {
      const validationError = validateURLAndGetError(value)
      setValidationErrors(err => ({
        ...err,
        baseUrlError: validationError,
      }))
      if (timer) {
        clearTimeout(timer)
      }
      setTimer(
        setTimeout(() => {
          const newUrl = value.replace(/\/+$/, '')
          updateState({ [name]: newUrl })
        }, parseDuration('1s'))
      )
    }
    if (FIELDS_TO_INVALIDATE_DATA_SOURCE.includes(name)) {
      updateState({ connection: { connected: false, failed: false, loading: false } })
    }
  }

  const saveDataSource = async () => {
    if (isLoading) return

    if (isUpdate && state.id) {
      await updateMetabaseDataSourceMutation.mutateAsync(state)
      updateDataSourceToast(isUpdate)
    } else {
      await addMetabaseDataSourceMutation.mutateAsync(state)
      updateDataSourceToast(isUpdate)
    }
    navigate(Paths.DATA_SOURCES)
  }

  const testAndSave = () => {
    if (!state.connection.connected) {
      toggleModal()
    } else {
      saveDataSource()
    }
  }

  useEffect(() => {
    if (dataSource) {
      updateState({ ...dataSource, connection: { connected: true, failed: false, loading: false } })
    }
  }, [dataSource, updateState])

  return {
    state,
    isFormValid,
    isLoading,
    isUpdate,
    modal,
    validationErrors,
    testAndSave,
    toggleModal,
    handleInputChange,
    toggleAutoProvision,
  }
}
