import AsyncCreatableSelect from 'react-select/async-creatable'

import { useGetFilterValues } from '@features/collections/hooks/use-get-filter-values'
import { FilterOption, VariantOption } from '@features/collections/types'

import { primaryStyle } from '@shared/components'

export const FilterValuesSelect = ({
  credentialsId,
  filter,
  value,
  onChange,
}: {
  credentialsId?: string
  filter: FilterOption
  value: VariantOption[]
  onChange: (variants: VariantOption[]) => void
}) => {
  const { handleLoadOptions } = useGetFilterValues(filter.type, {
    credentialsId,
    dashboardId: filter.dashboardId,
    filterId: filter.dashboardFilterId,
  })

  return (
    <AsyncCreatableSelect
      isMulti
      cacheOptions
      defaultOptions
      value={value}
      loadOptions={handleLoadOptions}
      onChange={options => onChange(options as VariantOption[])}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      isSearchable={true}
      styles={primaryStyle()}
      closeMenuOnSelect={false}
    />
  )
}
