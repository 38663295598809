import { RefreshSingleArrowIcon } from '@assets'

import { Tooltip } from '@shared/components'
import { MenuItem } from '@shared/components/menu-item/menu-item'

export const RecreateAllVariantsItem = ({
  cloudTitle,
  onClick,
  visible,
}: {
  cloudTitle: string
  onClick?: () => void
  visible: boolean
}) => {
  if (!visible) {
    return null
  }

  return (
    <Tooltip
      title={`If you wish to see the changes made in ${cloudTitle} reflected in the variants, you can recreate them. Be aware that specific changes made to variants will be lost.`}
      width={300}
      position="left"
      fullWidth
    >
      <MenuItem onClick={onClick}>
        <RefreshSingleArrowIcon />
        {'Recreate all variants'}
      </MenuItem>
    </Tooltip>
  )
}
