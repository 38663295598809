import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { ISpreadsheetInfo } from '@features/data-sources/types'
import { ISpreadsheet } from '@features/data-sources/types/types'

import { getMessage } from '@shared/constants'
import { useMutationWithErrorHandler, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'
import { onError, queryClient } from '@shared/http/query-client'

type ISpreadsheetsAddFilesRequest = {
  spreadsheets: ISpreadsheet[]
}

export const useAddSpreadsheetsFiles = () => {
  const queryFn = async ({ spreadsheets }: ISpreadsheetsAddFilesRequest) => {
    const result = await api.post(`/datasources/spreadsheets`, JSON.stringify({ spreadsheets }))
    return result.data
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(['datasources'])
    },
    errorTitle: getMessage('SPREADSHEETS_DATA_SOURCE_ADD_ERROR'),
    useErrorBoundary: false,
  })
}

export const useDeleteSpreadsheetsFile = () => {
  return useMutation((id: string) => api.delete(`/datasources/spreadsheets/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['datasources'])
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('SPREADSHEETS_DATA_SOURCE_DELETE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useGetSpreadsheetData = (spreadsheetId?: string) => {
  return useQueryWithErrorHandler<ISpreadsheetInfo, AxiosError>(
    ['spreadsheet-data', { spreadsheetId }],
    async () => {
      if (!spreadsheetId) {
        return {
          sheets: [],
          namedRanges: [],
        } as ISpreadsheetInfo
      }

      const response = await api.get<ISpreadsheetInfo>(`datasources/spreadsheets/${spreadsheetId}`)

      return response.data
    },
    {
      errorTitle: getMessage('SPREADSHEET_DATA_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}
