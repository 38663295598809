import { FC } from 'react'

import { PlusIcon } from '@assets'

import { InviteMembersModal } from '@features/organizations'
import { OrganizationBadge } from '@features/organizations/components'
import { useOrganizationSettings } from '@features/settings/hooks'
import { useAuthStore } from '@features/users'

import { ActionModal, Badge, Button, EBadgeColor, Input } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { StripePlan } from '@shared/types'

import { organizationMembersTableColumns, organizationMembersTableData } from './organization-members-table'

import styles from './organization-settings.module.css'

export const OrganizationSettings: FC = () => {
  const {
    users,
    isOwner,
    isAdmin,
    newOrgName,
    newOrgSlug,
    inviteModal,
    saveIsDisabled,
    saveOrgSlugIsDisabled,
    changeOrgSlugIsDisabled,
    removeMemberModal,
    changeNameIsDisabled,
    organizationId,
    plan,
    addMember,
    configureSSO,
    configureDirectorySync,
    toggleInviteModal,
    saveOrgName,
    saveOrgSlug,
    removeMember,
    setNewOrgName,
    setNewOrgSlug,
    startRemoveMemberProcess,
    toggleRemoveMemberModal,
  } = useOrganizationSettings()
  const { user } = useAuthStore()

  if (!user) return null

  return (
    <div>
      <div className={styles.currentPlan}>
        <div className="text-sm-regular">Current plan</div>
        <OrganizationBadge />
      </div>

      <div className={styles.currentPlan}>
        <div className="text-sm-regular">Organization name</div>
        <section className={styles.orgName}>
          <Input disabled={changeNameIsDisabled} value={newOrgName} onChange={e => setNewOrgName(e.target.value)} />
          <Button text="Save" onClick={saveOrgName} disabled={saveIsDisabled} />
        </section>
      </div>

      {plan === StripePlan.Enterprise && (
        <>
          <div className={styles.currentPlan}>
            <div className="text-sm-regular">Organization slug</div>
            <section className={styles.orgName}>
              <Input
                disabled={changeOrgSlugIsDisabled}
                value={newOrgSlug}
                onChange={e => setNewOrgSlug(e.target.value)}
              />
              <Button text="Save" onClick={saveOrgSlug} disabled={saveOrgSlugIsDisabled} />
            </section>
          </div>
          <div className="text-xs-regular">
            Your organization slug is a unique ID used to identify your team.
            <br />
            <i>Note: You cannot update your slug if you already attached an SSO connection.</i>
          </div>
        </>
      )}
      {users && (
        <section className={styles.members}>
          <div className={styles.tableInfo}>
            <div className={styles.membersCount}>
              <span className={styles.membersTitle}>Members</span>
              <Badge color={EBadgeColor.SECONDARY} text={`${users.length} users`} />
            </div>
            <div className={styles.subtitle}>Manage your organization members</div>
            <Button text="Add member" iconLeading={<PlusIcon />} className={styles.addMemberBtn} onClick={addMember} />
          </div>

          <div className={styles.divider} />
          <DataGrid
            columns={organizationMembersTableColumns({
              userId: user.id,
              canDeleteUsers: isAdmin || isOwner,
              organizationId,
              startRemoveMemberProcess,
            })}
            data={organizationMembersTableData(users.sort((a, b) => a.userId! - b.userId!))}
          />
        </section>
      )}

      {(isOwner || isAdmin) && plan === StripePlan.Enterprise && (
        <>
          <div className={styles.enterprise}>
            <div className={styles.tableInfo}>
              <span className={styles.enterpriseTitle}>Single Sign On</span>
              <div className={styles.subtitle}>Allow users to authentication using an Identity Provider.</div>
              <Button text="Configure" className={styles.addMemberBtn} onClick={configureSSO} />
            </div>
          </div>

          <div className={styles.enterprise}>
            <div className={styles.tableInfo}>
              <span className={styles.enterpriseTitle}>Directory Sync</span>
              <div className={styles.subtitle}>Use directory sync to provision users from your identity provider.</div>
              <Button text="Configure" className={styles.addMemberBtn} onClick={configureDirectorySync} />
            </div>
          </div>
        </>
      )}

      {inviteModal && <InviteMembersModal toggleModal={toggleInviteModal} />}

      <ActionModal
        open={removeMemberModal}
        title="Remove member"
        subtitle="Are you sure you want to remove this member?"
        onBackgroundClick={toggleRemoveMemberModal}
        btns={
          <>
            <Button secondaryGray text="Cancel" onClick={toggleRemoveMemberModal} fullWidth />
            <Button primaryError text="Delete" onClick={removeMember} fullWidth />
          </>
        }
      />
    </div>
  )
}
