import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { SelectVisualizationsModal } from '@features/syncs/components/select-syncs-modal/select-syncs-modal'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'
import { ISync } from '@features/syncs/types'

import { Button, Modal } from '@shared/components'
import { useSelectable } from '@shared/hooks/use-selectable'

import styles from './apply-filters-to-many.module.css'

interface ApplyFiltersToManyProps {
  visible: boolean
  handleBack: () => void
  handleSaveToOne: () => void
  saveAndApplyToMany: (selectedVises: ISync[]) => void
}

export const ApplyFiltersToMany: FC<ApplyFiltersToManyProps> = ({
  visible,
  handleBack,
  handleSaveToOne,
  saveAndApplyToMany,
}) => {
  const [step, setStep] = useState(0)
  const { isSavingSync, originalSync, syncs, validSyncsForSelectedFilters, destination } = useSyncSettingsContext()

  const { allSelected, selected, handleAllSelectClick, handleSelectClick } = useSelectable(
    validSyncsForSelectedFilters ?? []
  )

  const disabled = originalSync ? { [originalSync.id]: true } : {}
  const selectedWithOriginalSync = originalSync ? { ...selected, [originalSync.id]: true } : {}

  const validSyncSorted = useMemo(() => {
    const validSyncs = syncs.filter(sync => validSyncsForSelectedFilters?.includes(sync.id))
    return validSyncs.sort((a, b) => {
      if (a.id === originalSync?.id) return -1
      if (b.id === originalSync?.id) return 1
      return a.groupIndex - b.groupIndex
    })
  }, [syncs, originalSync, validSyncsForSelectedFilters])

  const save = useCallback(() => {
    if (!originalSync) {
      return
    }

    const applyToSyncs = syncs.filter((sync: ISync) => selected[sync.id])

    if (!selected[originalSync.id]) {
      applyToSyncs.push(originalSync)
    }

    saveAndApplyToMany(applyToSyncs)
    handleBack()
  }, [originalSync, syncs, selected, saveAndApplyToMany, handleBack])

  useEffect(() => {
    return () => setStep(0)
  }, [])

  return (
    <>
      <Modal open={visible && step === 0} onBackgroundClick={handleBack} className={styles.firstModal}>
        <span className="text-lg-semibold">Filters modification</span>
        <div className="text-sm-regular">Do you want to also apply them on other visualizations?</div>
        <div className={styles.row}>
          <Button text="Apply to other visualizations" secondaryGray onClick={() => setStep(1)} fullWidth />
          <Button text="Only this one" loading={isSavingSync} onClick={handleSaveToOne} fullWidth autoFocus />
        </div>
      </Modal>
      <SelectVisualizationsModal
        visible={visible && step === 1}
        syncs={validSyncSorted}
        selected={selectedWithOriginalSync}
        disabled={disabled}
        destination={destination}
        isAllSelected={allSelected}
        isSaving={isSavingSync}
        handleAllSelect={handleAllSelectClick}
        handleSelectOption={handleSelectClick}
        handleBack={handleBack}
        handleSave={save}
      />
    </>
  )

}
