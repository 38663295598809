import {
  DashboardEvent,
  ElementOptionItems,
  LookerDashboardOptions,
  LookerEmbedCookielessSessionData,
  LookerEmbedDashboard,
  LookerEmbedFilterParams,
  LookerEmbedSDK,
  PagePropertiesChangedEvent,
  SessionStatus,
} from '@looker/embed-sdk'
import { IDashboardElement } from '@looker/sdk'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  acquireEmbedSession,
  generateEmbedTokens,
  resetSession,
  useGetLookerDashboardEmbedData,
  useGetLookerDashboardFilters,
  useGetLookerVersion,
} from '@features/data-sources'
import { handleInvalidLookerCredentials } from '@features/data-sources/api/looker/error-handler'
import { LookerCoreDataSource } from '@features/data-sources/types'
import { IntegrationEmbedCommonProps } from '@features/embedding/types'
import { useAuthStore } from '@features/users'

import { lookerErrorToast, selectVisualisationToast } from '@shared/components'
import { config } from '@shared/config'
import { getMessage } from '@shared/constants'
import { onError, sleep } from '@shared/http'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'
import { parseDuration, removeHttp } from '@shared/utils'

import { LookerElementOverlay } from './looker-embed/dashboard-element-overlay-wrapper'

export interface LookerDashboardElement {
  id: string
  index?: number
  title?: string | null
}

interface IUseLooker extends IntegrationEmbedCommonProps<LookerDashboardElement> {
  dataSource: LookerCoreDataSource | null
  dashboardId?: string
  initialFilters?: LookerEmbedFilterParams
}

const LOOKER_MIN_VERSION = 23

const clearDashboardUrl = (url: string) => url.replace('/embed', '')

export enum LookerEmbedType {
  SIGNED_EMBEDDING = 'SIGNED_EMBEDDING',
  PRIVATE_EMBEDDING = 'PRIVATE_EMBEDDING',
  COOKIELESS_EMBEDDING = 'COOKIELESS_EMBEDDING',
}

export const useLooker = ({
  dataSource,
  dashboardId,
  initialFilters = {},
  initialElements = [],
  singleSelect = false,
  showOverlays = false,
  visSelectCallback,
}: IUseLooker) => {
  const user = useAuthStore(state => state.user)
  const userAccessToken = user?.tokens.access.token
  const [isSDKinitialized, setIsSDKinitialized] = useState(false)

  const [dashboardWithoutFilters, setDashboardWithoutFilters] = useState<LookerEmbedDashboard>()

  const [dashboardOptions, setDashboardOptions] = useState<LookerDashboardOptions>()
  const [dashboardUrl, setDashboardUrl] = useState<string | null>(null)
  const [elementsOverlays, setElementsOverlays] = useState<LookerElementOverlay[]>([])
  const [iframeRect, setIframeRect] = useState({ height: 0, width: 0 })
  const [elementsSelected, setElementsSelected] = useState<Array<LookerDashboardElement>>([])
  const [filtersSelected, setFiltersSelected] = useState<LookerEmbedFilterParams>()

  const [loadingDash, setLoadingDash] = useState(false)
  const [loadingDashWithoutFilters, setLoadingDashWithoutFilters] = useState(false)

  const [canLoadLookerDashboardEmbedData, setCanLoadLookerDashboardEmbedData] = useState(false)

  const getLookerVersionQuery = useGetLookerVersion()
  const isPrivateEmbedding = isFeatureEnabled('looker-private-embedding')
  const isCookielessEmbed = isFeatureEnabled('cookieless-embed')
  const embedType: LookerEmbedType = useMemo(() => {
    if (isPrivateEmbedding) {
      return LookerEmbedType.PRIVATE_EMBEDDING
    }
    if (isCookielessEmbed) {
      return LookerEmbedType.COOKIELESS_EMBEDDING
    }
    return LookerEmbedType.SIGNED_EMBEDDING
  }, [isPrivateEmbedding, isCookielessEmbed])

  const [hasFilters, setHasFilters] = useState(true)
  const { data: dashboardEmbedData, isLoading: loadingEmbedData } = useGetLookerDashboardEmbedData(
    dataSource?.id,
    dashboardId,
    { enabled: canLoadLookerDashboardEmbedData }
  )
  const { data: filters, isLoading: loadingFilters } = useGetLookerDashboardFilters(dataSource?.id, dashboardId)
  const filtersTitles = filters?.map(filter => filter.title)

  const privateIframeRef = useRef<HTMLIFrameElement>(null)

  const handleDashboardRunStart = () => {
    setLoadingDash(true)
  }

  const handleDashboardRunComplete = () => {
    setLoadingDash(false)
    setCanLoadLookerDashboardEmbedData(true)
  }

  const handleSessionStatus = useCallback(
    (status: DashboardEvent | SessionStatus) => {
      if (dataSource?.id && (status.interrupted || status.expired)) {
        console.error(status)
        resetSession({ dataSourceId: dataSource.id })
        lookerErrorToast()
      }
    },
    [dataSource?.id]
  )

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (
        privateIframeRef.current &&
        event.source === privateIframeRef.current.contentWindow &&
        event.origin === dataSource?.baseUrl
      ) {
        const eventParsed: DashboardEvent = JSON.parse(event.data)
        if (eventParsed.type === 'dashboard:loaded') handleDashboardLoaded(eventParsed)
        if (eventParsed.type === 'dashboard:run:start') handleDashboardRunStart()
        if (eventParsed.type === 'page:properties:changed') handlePagePropertiesChange(eventParsed)
        if (eventParsed.type === 'dashboard:filters:changed') handleFiltersChange(eventParsed)
        if (eventParsed.type === 'dashboard:run:complete') handleDashboardRunComplete()
        if (eventParsed.type === 'session:status') handleSessionStatus(eventParsed)
      }
    }
    window.addEventListener('message', messageHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [dataSource?.baseUrl, handleSessionStatus])

  const privateIframeSrc = useMemo(
    () => {
      const privateIframeSrcParams = new URLSearchParams([
        ['embed_domain', config.google.redirectUri],
        ...Object.entries(filtersSelected || initialFilters), // filters
        ...(!showOverlays ? [] : filtersTitles ?? []).map(filter => ['hide_filter', filter]), // hide filters when overlays are displayed
      ])
      return `${dataSource?.baseUrl}/embed/dashboards/${dashboardId}?${privateIframeSrcParams}`
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource, dashboardId, showOverlays]
  )

  const clearElementsSelected = () => {
    setElementsSelected([])
    setFiltersSelected(undefined)
  }

  const allFiltersRequiredSelected = useMemo(() => {
    if (!filters) return false
    const filtersRequired = filters?.filter(filter => filter.required).map(filter => filter.title)
    const filtersWithValues: string[] = []
    for (const key in filtersSelected) {
      if (filtersSelected.hasOwnProperty(key) && filtersSelected[key]) {
        filtersWithValues.push(key)
      }
    }
    return filtersRequired.every(filter => filtersWithValues.includes(filter))
  }, [filters, filtersSelected])

  const cookielessAcquireEmbedSessionCallback = useCallback(async (): Promise<LookerEmbedCookielessSessionData> => {
    if (!dataSource?.id || !dashboardId) {
      throw new Error('Datasource or dashboard not defined')
    }
    const res = await acquireEmbedSession({ dataSourceId: dataSource.id, dashboardId })
    return res.data
  }, [dataSource?.id, dashboardId])

  const cookielessGenerateEmbedTokensCallback = useCallback(async (): Promise<LookerEmbedCookielessSessionData> => {
    if (!dataSource?.id) {
      throw new Error('Datasource not defined')
    }
    const res = await generateEmbedTokens({ dataSourceId: dataSource?.id })
    return res.data
  }, [dataSource?.id])

  useEffect(() => {
    if (!userAccessToken || !dataSource?.baseUrl || !dataSource.id) return

    switch (embedType) {
      case LookerEmbedType.SIGNED_EMBEDDING:
        LookerEmbedSDK.init(dataSource.baseUrl, {
          url: `${config.apiHost}/datasources/looker/${dataSource.id}/embed-url`,
          headers: [{ name: 'Authorization', value: `Bearer ${userAccessToken}` }],
        })
        break
      case LookerEmbedType.COOKIELESS_EMBEDDING:
        LookerEmbedSDK.initCookieless(
          removeHttp(dataSource.baseUrl),
          cookielessAcquireEmbedSessionCallback,
          cookielessGenerateEmbedTokensCallback
        )
        break
      case LookerEmbedType.PRIVATE_EMBEDDING:
        console.log('Not need to initialize private embedding')
        break
    }

    setIsSDKinitialized(true)
  }, [
    userAccessToken,
    dataSource?.baseUrl,
    dataSource?.id,
    embedType,
    cookielessAcquireEmbedSessionCallback,
    cookielessGenerateEmbedTokensCallback,
  ])

  useEffect(() => {
    if (!dashboardWithoutFilters || !filtersSelected) return
    dashboardWithoutFilters.updateFilters(filtersSelected)
    dashboardWithoutFilters.run()
  }, [filtersSelected, dashboardWithoutFilters])

  useEffect(() => {
    if (!dashboardEmbedData) return

    const dashboardLayout = dashboardEmbedData.dashboard_layouts?.[0]?.dashboard_layout_components

    if (!dashboardLayout) return

    setHasFilters(dashboardEmbedData.dashboard_filters?.length !== 0)
    setDashboardUrl(clearDashboardUrl(dashboardEmbedData.dashboardAbsoluteUrl))

    const overlays = dashboardLayout.map(({ dashboard_element_id, row, column, width, height }) => ({
      id: dashboard_element_id,
      element: dashboardEmbedData.dashboard_elements?.find(element => element.id === dashboard_element_id),
      row,
      column,
      width,
      height,
    }))
    setElementsOverlays(overlays as LookerElementOverlay[])
  }, [dashboardEmbedData])

  const getNumRows = (elementsOverlays: LookerElementOverlay[]): number => {
    if (elementsOverlays.length) {
      let indexOfLargestRow = 0
      for (let i = 1; i < elementsOverlays.length; i++) {
        if (elementsOverlays[i].row > elementsOverlays[indexOfLargestRow].row) {
          indexOfLargestRow = i
        }
      }
      return elementsOverlays[indexOfLargestRow].row + elementsOverlays[indexOfLargestRow].height
    }
    return 0
  }
  const numRows = useMemo(() => getNumRows(elementsOverlays), [elementsOverlays])

  const handlePagePropertiesChange = ({ height }: PagePropertiesChangedEvent) => {
    if (!height) return
    const element = document.querySelector<HTMLElement>(`#dashboard iframe`)
    if (!element) return
    element.style.height = `${height}px`
    setIframeRect({
      height,
      width: element.clientWidth,
    })
  }

  const handlePagePropertiesChangeDashWithoutFilters = ({ height }: PagePropertiesChangedEvent) => {
    if (!height) return
    const element = document.querySelector<HTMLElement>(`#dashboard-without-filters iframe`)
    if (!element) return
    element.style.height = `${height}px`
    setIframeRect({
      height,
      width: element.clientWidth,
    })
  }

  const handleDashboardLoaded = (event: DashboardEvent) => {
    setDashboardOptions(event.dashboard.options)
    setFiltersSelected(event.dashboard.dashboard_filters)
  }

  const handleFiltersChange = (event: DashboardEvent) => {
    setDashboardUrl(clearDashboardUrl(event.dashboard.absoluteUrl))
    setFiltersSelected(event.dashboard.dashboard_filters)
  }

  const getElementTitle = (element: IDashboardElement) => {
    let title = element.title || element?.look?.title
    if (
      element?.result_maker?.vis_config?.type === 'single_value' &&
      element?.result_maker?.vis_config?.show_single_value_title &&
      element?.result_maker?.vis_config?.single_value_title
    ) {
      title = element?.result_maker?.vis_config?.single_value_title
    }
    return title
  }

  const handleSelectElement = ({ id, index, element }: { id: string; index: number; element: ElementOptionItems }) => {
    const title = getElementTitle(element)
    selectVisualisationToast(title ?? 'Unamed visualisation')
    const newElement = {
      id,
      index,
      title,
    }
    if (singleSelect) {
      setElementsSelected([newElement])
      visSelectCallback && visSelectCallback(newElement)
      return
    }
    setElementsSelected([...elementsSelected, newElement])
  }

  const handleUnselectElement = (id: string) => {
    const newElements = [...elementsSelected]
    const elementIndex = newElements.findIndex(element => element.id === id)
    if (elementIndex !== -1) {
      newElements.splice(elementIndex, 1)
      setElementsSelected(newElements)
    }
  }

  const handleDashboardWithoutFiltersConnect = (dashboard: LookerEmbedDashboard) => {
    setDashboardWithoutFilters(dashboard)
  }

  const handleDashboardConnectError = (error: unknown) => {
    if (error === 'Unauthorized') {
      return handleInvalidLookerCredentials()
    }
    if (error === 'Not Found') {
      onError(new Error('Dashboard not found'), {
        title: getMessage('LOOKER_CONNECT_TO_DASHBOARD_NOT_FOUND_ERROR'),
        hideSubtext: true,
        message: 'The Looker dashboard you are trying to embed does not exist. Please check your credentials.',
      })
      return
    }
    const defaultErrorTitle = getMessage('LOOKER_CONNECT_TO_DASHBOARD_ERROR')
    const defaultErrorMessage = 'Something went wrong when connecting to Looker. Please check your credentials.'
    if (error instanceof Error) {
      onError(error, {
        title: defaultErrorTitle,
        message: defaultErrorMessage,
      })
      return
    }
    if (typeof error === 'string') {
      onError(new Error(error), {
        title: error,
        message: defaultErrorMessage,
      })
      return
    }
    onError(new Error(defaultErrorTitle), {
      title: defaultErrorTitle,
      message: defaultErrorMessage,
    })
  }

  const makeDashboardForCookielessEmbed = useCallback(
    async (el: HTMLDivElement) => {
      if (!el || !dashboardId || !isSDKinitialized) {
        setLoadingDash(false)
        return
      }

      setLoadingDash(true)

      el.innerHTML = ''

      const filters = filtersSelected || initialFilters

      const hideFilters = showOverlays
      const showFiltersBar = await getShowFiltersBar()

      const embedBuilder = LookerEmbedSDK.createDashboardWithId(dashboardId).withParams({
        // https://cloud.google.com/looker/docs/filters-user-defined-dashboards#hiding_dashboard_filters
        ...(hideFilters && filtersTitles?.length ? { hide_filter: filtersTitles[0] } : {}),
        // https://cloud.google.com/looker/docs/themes-for-embedded-dashboards-and-explores#using_the_theme_url_argument_to_apply_individual_dashboard_theme_elements
        ...(hideFilters ? { _theme: `{"show_filters_bar":${showFiltersBar}}` } : {}),
      })

      embedBuilder
        .appendTo(el)
        .withFilters(filters)
        .on('dashboard:loaded', handleDashboardLoaded)
        .on('page:properties:changed', handlePagePropertiesChange)
        .on('dashboard:filters:changed', handleFiltersChange)
        .on('dashboard:run:complete', handleDashboardRunComplete)
        .on('session:status', handleSessionStatus)
        .build()
        .connect()
        .catch(error => {
          handleDashboardConnectError(error)
        })
        .finally(() => {
          setLoadingDash(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardId, showOverlays, isSDKinitialized]
  )

  const makeDashboardForSignedEmbed = useCallback(
    async (el: HTMLDivElement) => {
      if (!el || !dashboardId || !isSDKinitialized) {
        setLoadingDash(false)
        return
      }

      setLoadingDash(true)

      el.innerHTML = ''

      const filters = filtersSelected || initialFilters

      const embedBuilder = LookerEmbedSDK.createDashboardWithId(`${dashboardId}?&hideFilters=false`)

      embedBuilder
        .appendTo(el)
        .withFilters(filters)
        .on('dashboard:loaded', handleDashboardLoaded)
        .on('page:properties:changed', handlePagePropertiesChange)
        .on('dashboard:filters:changed', handleFiltersChange)
        .on('dashboard:run:complete', handleDashboardRunComplete)
        .on('session:status', handleSessionStatus)
        .build()
        .connect()
        .catch(error => {
          handleDashboardConnectError(error)
        })
        .finally(() => {
          setLoadingDash(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardId, showOverlays, isSDKinitialized]
  )

  const getShowFiltersBar = async () => {
    if (!dataSource?.baseUrl) {
      return true
    }
    try {
      const response = await getLookerVersionQuery.mutateAsync(dataSource?.baseUrl)
      const version = response.data
      return version.major ? parseInt(version.major) >= LOOKER_MIN_VERSION : false
    } catch (error) {
      console.error('Error getting Looker version', error)
      return true
    }
  }

  const makeDashboardWithoutFiltersForSignedEmbed = useCallback(
    async (el: HTMLDivElement) => {
      if (!el || !dashboardId || !isSDKinitialized) {
        setLoadingDashWithoutFilters(false)
        return
      }

      setLoadingDashWithoutFilters(true)

      el.innerHTML = ''

      const filters = filtersSelected || initialFilters
      const showFiltersBar = await getShowFiltersBar()

      await sleep(parseDuration('2s'))

      LookerEmbedSDK.createDashboardWithId(
        `${dashboardId}?_theme={"show_filters_bar":${showFiltersBar}}&hideFilters=true`
      )
        .appendTo(el)
        .withFilters(filters)
        .on('dashboard:run:start', () => {
          setLoadingDashWithoutFilters(true)
        })
        .on('page:properties:changed', handlePagePropertiesChangeDashWithoutFilters)
        .on('dashboard:run:complete', () => {
          setLoadingDashWithoutFilters(false)
          setCanLoadLookerDashboardEmbedData(true)
        })
        .build()
        .connect()
        .then(handleDashboardWithoutFiltersConnect)
        .catch(error => {
          console.error('Unable to connect to dashboard without filters', error)
          handleDashboardConnectError(error)
        })
        .finally(() => {
          setLoadingDashWithoutFilters(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardId, isSDKinitialized]
  )

  useEffect(() => {
    clearElementsSelected()
  }, [dashboardId])

  useEffect(() => {
    setElementsSelected(initialElements)
  }, [initialElements])

  return {
    makeDashboardForSignedEmbed,
    makeDashboardWithoutFiltersForSignedEmbed,
    makeDashboardForCookielessEmbed,
    handleSelectElement,
    handleUnselectElement,
    loadingDash,
    loadingDashWithoutFilters,
    loadingEmbedData,
    dashboardOptions,
    dashboardUrl,
    numRows,
    iframeRect,
    hasFilters,
    filtersSelected,
    elementsOverlays,
    elementsSelected,
    allFiltersRequiredSelected,
    loadingFilters,
    privateIframeSrc,
    privateIframeRef,
    embedType,
  }
}
