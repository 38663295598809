import classNames from 'classnames'
import { FC } from 'react'

import { Dot } from '@shared/components/dot/dot'

import styles from './stepper.module.css'

interface IStepper {
  activeStep: number
  steps: string[]
}
export const Stepper: FC<IStepper> = ({ activeStep, steps }) => (
  <div className={styles.container}>
    {steps.map((step, i) => (
      <div key={step} className={classNames(styles.step, i === activeStep && styles.active)}>
        {i < steps.length - 1 && (
          <div className={classNames(styles.connector, i < activeStep && styles.activeConnector)} />
        )}
        <Dot active={i === activeStep} done={i < activeStep} className={styles.dot} />
        {step}
      </div>
    ))}
  </div>
)
