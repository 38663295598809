import { FC } from 'react'

import { IIcon } from '@assets/types'

export const EmailIcon: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M1.66602 5.83203L8.47012 10.5949C9.02109 10.9806 9.29658 11.1734 9.59624 11.2481C9.86093 11.3141 10.1378 11.3141 10.4025 11.2481C10.7021 11.1734 10.9776 10.9806 11.5286 10.5949L18.3327 5.83203M5.66602 16.6654H14.3327C15.7328 16.6654 16.4329 16.6654 16.9677 16.3929C17.4381 16.1532 17.8205 15.7707 18.0602 15.3003C18.3327 14.7656 18.3327 14.0655 18.3327 12.6654V7.33203C18.3327 5.9319 18.3327 5.23183 18.0602 4.69705C17.8205 4.22665 17.4381 3.8442 16.9677 3.60451C16.4329 3.33203 15.7328 3.33203 14.3327 3.33203H5.66602C4.26588 3.33203 3.56582 3.33203 3.03104 3.60451C2.56063 3.8442 2.17818 4.22665 1.9385 4.69705C1.66602 5.23183 1.66602 5.9319 1.66602 7.33203V12.6654C1.66602 14.0655 1.66602 14.7656 1.9385 15.3003C2.17818 15.7707 2.56063 16.1532 3.03104 16.3929C3.56582 16.6654 4.26588 16.6654 5.66602 16.6654Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
