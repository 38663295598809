import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { FC, useEffect, useState } from 'react'
import ReactCrop from 'react-image-crop'

import { CropIcon } from '@assets'

import { useSyncCrop, useUpdateSync } from '@features/syncs'
import { MAX_GOOGLE_PIXELS, getCropOptionDescription, getCropOptionLabel } from '@features/syncs/hooks/utils'
import { ISync, cropOptions } from '@features/syncs/types'

import {
  ActionModal,
  Badge,
  Button,
  Checkbox,
  EBadgeColor,
  ImageWithFallback,
  Switch,
  Tooltip,
} from '@shared/components'
import { Callout } from '@shared/components/callout/callout'
import { useBoolean } from '@shared/hooks'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import styles from './sync-crop.module.css'

interface CropModalProps {
  open: boolean
  sync: ISync
  isGoogle: boolean
  toggle: () => void
}

export const SyncCrop: FC<CropModalProps> = ({ open, sync, isGoogle, toggle }) => {
  const [originalImgSize, toggleOriginalImgSize] = useBoolean()
  const [imageHasError, setImageHasError] = useState(false)
  const [isMaxResolution, setIsMaxResolution] = useState(false)

  const { cropDimensions, autoCrop, manualCrop, manualPxCrop, localCropOption, setManualCrop, setCropOption } =
    useSyncCrop({
      imgUrl: sync?.originalImageUrl,
      initialCropOption: sync.cropOption,
      autoCropValuesPx: sync?.autoCropValues,
      initialManualCropValuesPx: sync.manualCropValues ?? null,
      open,
    })

  const { mutateAsync: updateSync, isLoading } = useUpdateSync()
  const [removedBackground, toggleRemovedBackground] = useBoolean(sync.removedBackground)
  const [enhancedResolution, toggleEnhancedResolution] = useBoolean(sync.enhancedResolution)

  const isRemoveBackground = isFeatureEnabled('remove-background')
  const isEnhanceResolution = isFeatureEnabled('enhance-resolution')

  useEffect(() => {
    if (!isGoogle || !enhancedResolution) return
    const { height, width } = cropDimensions
    if (!height || !width) return
    setIsMaxResolution(height > MAX_GOOGLE_PIXELS || width > MAX_GOOGLE_PIXELS)
  }, [isGoogle, cropDimensions, enhancedResolution])

  const handleSave = async () => {
    if (
      sync.cropOption === localCropOption &&
      isEqual(sync.manualCropValues, manualPxCrop) &&
      isEqual(sync.removedBackground, removedBackground) &&
      isEqual(sync.enhancedResolution, enhancedResolution)
    ) {
      toggle()
      return
    }

    await updateSync({
      id: sync?.id,
      updatedAt: sync?.updatedAt,
      cropOption: localCropOption,
      manualCropValues: localCropOption === 'manual' ? JSON.stringify(manualPxCrop) : undefined,
      removedBackground,
      enhancedResolution,
    })
    toggle()
  }

  const imageClass = classNames(
    originalImgSize ? styles.originalImg : styles.containedImg,
    localCropOption === 'none' && styles.noCrop
  )
  const imageView = <ImageWithFallback className={imageClass} src={sync?.originalImageUrl} onError={setImageHasError} />

  const renderImage = () => {
    if (!sync?.originalImageUrl) {
      return <div className={styles.noImageText}>No preview available</div>
    }

    if (imageHasError) {
      return (
        <div className={styles.noImageText}>
          The preview is no longer available per our security policy.
          <br />
          <br />
          Please run another update to access the preview
        </div>
      )
    }

    return (
      <div className={styles.cropContainer}>
        {localCropOption === 'none' ? (
          imageView
        ) : (
          <ReactCrop
            crop={localCropOption === 'auto' ? autoCrop : manualCrop}
            onChange={(_, c) => setManualCrop(c)}
            keepSelection
            disabled={localCropOption === 'auto'}
          >
            {imageView}
          </ReactCrop>
        )}
      </div>
    )
  }

  return (
    <ActionModal
      open={open}
      className={styles.container}
      onBackgroundClick={toggle}
      Icon={CropIcon}
      title="Crop settings"
      btns={
        <>
          <Button secondaryGray text="Cancel" onClick={toggle} fullWidth />
          <Button text="Save" loading={isLoading} onClick={handleSave} fullWidth />
        </>
      }
    >
      <div className={styles.options}>
        {cropOptions.map(option => {
          const isActive = localCropOption === option
          const isDisabled = option === 'manual' && !sync?.originalImageUrl
          const optionClass = classNames(styles.option, isActive && styles.active)
          const tooltip = isDisabled
            ? 'Manual cropping is available after the first image is taken'
            : getCropOptionDescription(option)

          return (
            <Tooltip key={option} title={tooltip}>
              <Button
                key={option}
                disabled={isDisabled}
                text={getCropOptionLabel(option)}
                onClick={() => setCropOption(option)}
                className={optionClass}
                secondaryColor={isActive}
                tertiaryColor={!isActive}
              />
            </Tooltip>
          )
        })}
      </div>
      <Switch value={originalImgSize} text="Original image size" onChange={toggleOriginalImgSize} />
      <br />

      {renderImage()}

      <br />
      {isRemoveBackground && (
        <div className={styles.checkboxTooltip}>
          <Tooltip
            title={
              'Enable this option to remove the image background. The results may vary depending on the image quality and complexity.'
            }
          >
            <div className={styles.checkboxTooltip} onClick={toggleRemovedBackground}>
              <Checkbox checked={removedBackground} />
              Remove background
            </div>
          </Tooltip>
          <Tooltip title={"This feature is currently in Beta phase, meaning it's under active development and testing"}>
            <Badge text="Beta" color={EBadgeColor.SECONDARY} />
          </Tooltip>
        </div>
      )}
      {isEnhanceResolution && (
        <div className={styles.checkboxTooltip}>
          <Tooltip title={'Enable this option to enhance resolution'}>
            <div className={styles.checkboxTooltip} onClick={toggleEnhancedResolution}>
              <Checkbox checked={enhancedResolution} />
              Enhance resolution
            </div>
          </Tooltip>
          <Tooltip title={"This feature is currently in Beta phase, meaning it's under active development and testing"}>
            <Badge text="Beta" color={EBadgeColor.SECONDARY} />
          </Tooltip>
        </div>
      )}

      <Callout
        open={isMaxResolution && enhancedResolution}
        type="warning"
        title="Maximum Resolution Reached"
        subtitle="This image is already at the highest resolution supported for Google destinations. Further enhancement may not yield noticeable improvements."
      />
    </ActionModal>
  )
}
