import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { DestinationTabs, buildDestinationPath } from '@pages/routes/paths'

import { ArrowRight } from '@assets'

import { destinationHandlers, isEmailDestination } from '@features/destinations'
import { Destination } from '@features/destinations/types'
import { useAuthStore } from '@features/users'

import { Badge, Button, EBadgeColor } from '@shared/components'

import styles from './detailed-preview.module.css'

interface DetailedPreviewProps {
  badge?: string
  destination: Destination
  isInsideCollection: boolean
  itemsInPageCount: number
}

export const DetailedPreview: FC<DetailedPreviewProps> = ({
  badge,
  destination,
  isInsideCollection,
  itemsInPageCount,
}) => {
  const navigate = useNavigate()
  const user = useAuthStore(state => state.user)

  const { isFileDeleted } = destination
  const destinationConstruct = destinationHandlers(destination)

  const isEmail = useMemo(() => isEmailDestination(destination), [destination])
  const containerClass = classNames(styles.container, isEmail && styles.email)
  const onOpenDestinationClick = () =>
    navigate(
      buildDestinationPath(destination.id, isEmail ? DestinationTabs.VISUALIZATIONS : undefined)
    )

  return (
    <div className={containerClass} onClick={onOpenDestinationClick}>
      {destinationConstruct.getPreview(itemsInPageCount)}
      {destination.userId !== user?.id && !isInsideCollection && (
        <Badge className={styles.badge} color={EBadgeColor.SECONDARY} text="Shared with me" />
      )}
      {badge && <Badge className={styles.badge} color={EBadgeColor.LIGHTGRAY} text={badge} />}

      <div className={classNames(styles.hoverBlur, isFileDeleted && styles.disabled)}>
        <Button
          disabled={isFileDeleted}
          text={destinationConstruct.getDestinationTitle(isFileDeleted)}
          iconTrailing={<ArrowRight />}
        />
      </div>
    </div>
  )
}
