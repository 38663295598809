import { ElementOptionItems } from '@looker/embed-sdk'
import { useEffect, useState } from 'react'

import { LookerDashboardElement, LookerEmbedType } from '@features/embedding/looker/use-looker'
import { IntegrationEmbedCommonProps } from '@features/embedding/types'

import { Spinner } from '@shared/components'

import { DashboardElementOverlay } from './dashboard-element-overlay'
import { DashboardElementOverlayWrapper, LookerElementOverlay } from './dashboard-element-overlay-wrapper'
import { DashboardFullOverlay } from './dashboard-full-overlay'

import styles from './looker-embed.module.css'

interface ILookerEmber extends IntegrationEmbedCommonProps<LookerDashboardElement> {
  dashboardId: string
  loading?: boolean
  numRows: number
  iframeWidth: number
  iframeHeight: number
  fullDashboard?: boolean
  elementsOverlays: LookerElementOverlay[]
  elementsSelected: LookerDashboardElement[]
  hasFilters?: boolean
  modifyFiltersOnly: boolean
  visSelectOnly: boolean
  privateIframeRef?: React.RefObject<HTMLIFrameElement>
  privateIframeSrc?: string
  embedType: LookerEmbedType
  fullDashboardClick?: () => void
  handleSelectElement: ({ id, index, element }: { id: string; index: number; element: ElementOptionItems }) => void
  handleUnselectElement: (id: string) => void
  makeDashboardForSignedEmbed: (el: HTMLDivElement) => void
  makeDashboardWithoutFiltersForSignedEmbed?: (el: HTMLDivElement) => void
}

const WIDTH_TIMEOUT_MS = 50

export const FILTER_HEIGHT_WITH_FILTERS = 20
export const FILTER_HEIGHT_WITHOUT_FILTERS = 8
export const TITLE_HEIGHT = 64
export const BASE_FOOTER_HEIGHT = 54
export const RANDOM_FOUR = 4

export const LookerEmbed = ({
  numRows,
  loading = false,
  hasFilters,
  showOverlays = false,
  dashboardId,
  iframeWidth,
  iframeHeight,
  fullDashboard = false,
  elementsOverlays,
  elementsSelected,
  privateIframeRef,
  privateIframeSrc,
  modifyFiltersOnly,
  visSelectOnly,
  embedType,
  fullDashboardClick,
  handleSelectElement,
  handleUnselectElement,
  makeDashboardForSignedEmbed,
  makeDashboardWithoutFiltersForSignedEmbed,
}: ILookerEmber) => {
  const [width, setWidth] = useState('100%')

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    if (showOverlays) {
      timeout = setTimeout(() => {
        setWidth('100%')
      }, WIDTH_TIMEOUT_MS)
    } else {
      setWidth('0')
    }
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [showOverlays])

  return (
    <div key={dashboardId} className={styles.root}>
      <div className={styles.dashboardWrapper}>
        {loading && (
          <div className={styles.spinnerContainer}>
            <Spinner center={false} />
          </div>
        )}

        {embedType === LookerEmbedType.SIGNED_EMBEDDING && (
          <>
            {!visSelectOnly && (
              <div
                className={styles.dashboard}
                style={{ display: !showOverlays ? 'block' : 'none' }}
                id="dashboard"
                ref={makeDashboardForSignedEmbed}
              />
            )}
            {!modifyFiltersOnly && makeDashboardWithoutFiltersForSignedEmbed && (
              <div
                className={styles.dashboard}
                style={{ display: showOverlays ? 'block' : 'none', width }}
                id="dashboard-without-filters"
                ref={makeDashboardWithoutFiltersForSignedEmbed}
              />
            )}
          </>
        )}

        {embedType === LookerEmbedType.COOKIELESS_EMBEDDING && (
          <div className={styles.dashboard} id="dashboard" ref={makeDashboardForSignedEmbed} />
        )}

        {embedType === LookerEmbedType.PRIVATE_EMBEDDING && (
          <div id="dashboard" className={styles.dashboard}>
            <iframe ref={privateIframeRef} title="Looker private embedding" src={privateIframeSrc} frameBorder={0} />
          </div>
        )}

        {!loading && showOverlays && (
          <div>
            {fullDashboard ? (
              <DashboardFullOverlay
                width={iframeWidth}
                height={iframeHeight}
                hasFilters={hasFilters}
                onClick={fullDashboardClick}
              />
            ) : (
              <DashboardElementOverlayWrapper
                numRows={numRows}
                iframeWidth={iframeWidth}
                iframeHeight={iframeHeight}
                elements={elementsOverlays}
                hasFilters={hasFilters}
                render={element => (
                  <DashboardElementOverlay
                    key={element.id}
                    id={element.id}
                    index={element.index}
                    element={element.element}
                    columnStart={element.columnStart}
                    columnEnd={element.columnEnd}
                    rowStart={element.rowStart}
                    rowEnd={element.rowEnd}
                    isSelected={!!elementsSelected.find(e => e.id === element.id)}
                    handleSelectElement={handleSelectElement}
                    handleUnselectElement={handleUnselectElement}
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
