import { ColumnDef } from '@tanstack/react-table'

import { parseSyncProperties } from '@features/syncs'
import { ISync } from '@features/syncs/types'

import { ImageWithFallback } from '@shared/components'

export interface RowData {
  id: string
  sectionHeader?: string
  name?: string
  dashboardName?: string | undefined
  imageUrl?: string | undefined
}

export const reviewCollectionTableData = (syncs: ISync[]) => {
  const sortedSyncs = syncs.sort((a, b) => a.slideIndex - b.slideIndex)

  const formattedData: RowData[] = []
  let previousSlideIndex: number | null = null
  const uniqueSlideIndices = new Set<number>(sortedSyncs.map(s => s.slideIndex))

  const multipleSections = uniqueSlideIndices.size > 1

  sortedSyncs.forEach(sync => {
    if (sync.slideIndex !== previousSlideIndex && multipleSections) {
      formattedData.push({
        id: `header-${sync.id}-${sync.slideIndex}`,
        sectionHeader: `Slide ${sync.slideIndex}`,
      })
    }
    previousSlideIndex = sync.slideIndex

    formattedData.push({
      id: sync.id,
      name: sync.name,
      dashboardName: parseSyncProperties(sync).dashboard,
      imageUrl: sync.imageUrl,
    })
  })
  return formattedData
}

export const reviewCollectionTableColumns = () => {
  const reviewCollectionTableColumns: ColumnDef<RowData>[] = [
    {
      id: 'imageUrl',
      accessorKey: 'imageUrl',
      header: 'Preview',
      cell: ({ row }) => (
        <ImageWithFallback
          key={row.id}
          src={row.getValue('imageUrl')}
          imageSize={{ width: 80, height: 40 }}
          placeholderSize={{ width: 80, height: 40 }}
          alt="Visualization"
          dataTestId={row.getValue('name')}
        />
      ),
      size: 100,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Name',
      cell: info => info.getValue(),
      size: 100,
    },
    {
      id: 'dashboardName',
      accessorKey: 'dashboardName',
      header: 'Dashboard',
      cell: info => info.getValue(),
      size: 100,
    },
  ]
  return reviewCollectionTableColumns
}
