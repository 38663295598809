import { useEffect, useState } from 'react'

import { sortingOptions } from '@features/destinations'

export const useSort = (sortTypeLocalKey: string, sortOrderLocalKey: string) => {
  const [sortingOption, setSortingOption] = useState(sortingOptions[0])
  const [isAscendingOrder, setIsAscendingOrder] = useState(true)

  useEffect(() => {
    const sortingType = localStorage.getItem(sortTypeLocalKey)
    if (sortingType) {
      setSortingOption(sortingOptions.find(option => option.value === sortingType)!)
    } else localStorage.setItem(sortTypeLocalKey, sortingOptions[0].value)

    const sortingOrder = localStorage.getItem(sortOrderLocalKey)
    if (sortingOrder) {
      setIsAscendingOrder(sortingOrder === 'true')
    } else localStorage.setItem(sortTypeLocalKey, sortingOptions[0].value)
  }, [sortTypeLocalKey, sortOrderLocalKey])

  const handleSortTypeChange = (option: any) => {
    localStorage.setItem(sortTypeLocalKey, option.value)
    setSortingOption(option)
  }

  const handleSortOrderChange = () => {
    localStorage.setItem(sortOrderLocalKey, (!isAscendingOrder).toString())
    setIsAscendingOrder(!isAscendingOrder)
  }

  return {
    sortingOption,
    isAscendingOrder,
    handleSortTypeChange,
    handleSortOrderChange,
  }
}
