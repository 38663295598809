import classNames from 'classnames'
import React, { FC, PropsWithChildren, ReactNode, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs, TabsProps } from 'react-tabs'

import { useDragScroll } from '@shared/hooks/use-drag-scroll'

import styles from './tabs.module.css'

interface ITab extends TabsProps, PropsWithChildren {
  tabs: ReactNode[]
  tabListClassName?: string
  tabPanelClassName?: string
  onSelect?: (index: number) => void
}

const two = 2
const tabListQuerySelector = '[itemid=tablist]'

export const RollstackTabs: FC<ITab> = ({
  tabs,
  children,
  className,
  defaultIndex,
  tabListClassName,
  tabPanelClassName,
  selectedTabPanelClassName,
  onSelect,
  ...props
}) => {
  const { onMouseDown, onMouseUp, onMouseMove, preventClickWhenDrag } = useDragScroll(tabListQuerySelector)

  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedIndex ?? defaultIndex)

  const handleSelect = (index: number) => {
    onSelect && onSelect(index)
    setSelectedTabIndex(index)

    const selectedTab = document.querySelector(`[itemid='tab-${index}']`) as HTMLElement

    if (selectedTab) {
      const scrollContainer = document.querySelector(tabListQuerySelector) as HTMLElement

      const scrollLeft = selectedTab.offsetLeft - scrollContainer.offsetWidth / two + selectedTab.offsetWidth / two

      scrollContainer.scrollTo({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth',
      })
    }
  }

  const containerClass = classNames(styles.container, className)
  const tabListClass = classNames(styles.tabList, tabListClassName)

  return (
    <Tabs
      onSelect={handleSelect}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      className={containerClass}
      {...props}
    >
      <TabList className={tabListClass} itemID="tablist">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            itemID={`tab-${index}`}
            className={styles.tab}
            selectedClassName={styles.selectedTab}
            onClick={preventClickWhenDrag}
          >
            {tab}
          </Tab>
        ))}
      </TabList>

      {tabs &&
        children &&
        React.Children.map(children, (child, index) => {
          return (
            <TabPanel
              className={classNames(
                styles.tabPanel,
                tabPanelClassName,
                index === selectedTabIndex ? selectedTabPanelClassName : undefined
              )}
            >
              {child}
            </TabPanel>
          )
        })}
    </Tabs>
  )
}
