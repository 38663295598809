import React, { useContext, useEffect, useState } from 'react'

import { useDuplicateDestination, useGetDrivePermissionCheckForCopy } from '@features/destinations/api/api'
import { DuplicateDestination } from '@features/destinations/components'
import { Destination } from '@features/destinations/types'
import {
  googleDrivePermissionErrorHandlerFactory,
  isOAuthMissingError,
  oauthErrorHandlerFactory,
} from '@features/drive/error-handler'
import { useDestinationsStatuses } from '@features/notch/destinations-status-provider'
import { OAuthContext } from '@features/users'

import {
  duplicateDestinationAfterAuthorizeFileToast,
  duplicateDestinationPartialSuccessToast,
  duplicateDestinationToast,
} from '@shared/components'
import { getMessage } from '@shared/constants'
import { handleWebSocketError, onError, queryClient } from '@shared/http'

export interface WithDestinationDuplicateProps {
  duplicateDestination: (destination: Destination) => void
}

enum ProcessSteps {
  Inactive,
  AssignName,
  DuplicateOperation,
  Completed,
}

export const withDestinationDuplicate = <T,>(
  WrappedComponent: React.ComponentType<T & WithDestinationDuplicateProps>
) => {
  return (props: T) => {
    const duplicateDestinationQuery = useDuplicateDestination()
    const { showAuthModal, hideAuthModal } = useContext(OAuthContext)

    const [name, setName] = useState('')

    const { duplicateDestinationStatus, setDuplicateDestinationStatus } = useDestinationsStatuses()
    const [step, setStep] = useState(ProcessSteps.Inactive)
    const [destination, setDestination] = useState<Destination | null>(null)
    const [processing, setProcessing] = useState(false)

    const { isLoading: isCheckingDrivePermission } = useGetDrivePermissionCheckForCopy({
      destination,
      onSuccess: () => {
        duplicateDestinationAfterAuthorizeFileToast()
      },
    })

    const closeProcess = () => {
      setDestination(null)
      setStep(ProcessSteps.Inactive)
    }

    const startDuplicationProcess = async (destination: Destination) => {
      setDestination(destination)
      setName(`Copy of ${destination.name}`)
      setStep(ProcessSteps.AssignName)
    }

    const duplicateDestination = async () => {
      if (!destination || !name) return

      setProcessing(true)
      try {
        await duplicateDestinationQuery.mutateAsync({ id: destination.id, name })
      } catch (error) {
        setProcessing(false)
        // TODO: handle this error in the query after react-query updated to v5
        if (isOAuthMissingError(error)) {
          return
        }
        closeProcess()
      }
    }

    useEffect(() => {
      if (!duplicateDestinationStatus || !destination || duplicateDestinationStatus.originalId !== destination.id) {
        return
      }

      switch (duplicateDestinationStatus.status) {
        case 'RUNNING':
          setProcessing(true)
          break
        case 'COMPLETED':
        case 'PARTIALLY_COMPLETED':
          const { presentation } = duplicateDestinationStatus
          if (presentation) {
            setStep(ProcessSteps.Completed)
            if (duplicateDestinationStatus.status === 'COMPLETED') {
              duplicateDestinationToast(presentation.type)
            } else {
              const failedVisNames = duplicateDestinationStatus.duplicationVisResults
                .filter(visResult => visResult.status === 'FAILED')
                .map(visResult => visResult.name)
              duplicateDestinationPartialSuccessToast(failedVisNames)
            }
          }
          setProcessing(false)
          closeProcess()
          setDestination(null)
          setName('')
          setDuplicateDestinationStatus && setDuplicateDestinationStatus()
          queryClient.invalidateQueries(['presentations'])
          break
        case 'FAILED':
          setProcessing(false)
          handleWebSocketError(duplicateDestinationStatus, [
            oauthErrorHandlerFactory({
              showAuthModal,
              handleClose: () => {
                hideAuthModal()
              },
              successCallback: () => {
                duplicateDestination()
              },
            }),
            googleDrivePermissionErrorHandlerFactory({
              itemId: destination.docId,
              itemName: destination.name,
              type: destination.type,
              successCallback: () => {
                duplicateDestination()
              },
            }),
            error => {
              closeProcess()
              setDestination(null)
              setName('')
              onError(error, {
                title: getMessage('PRESENTATION_DUPLICATE_ERROR'),
                message: error.metadata?.message || error.message,
              })
              return true
            },
          ])
          break
        default:
          break
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicateDestinationStatus])

    return (
      <>
        <WrappedComponent {...props} duplicateDestination={startDuplicationProcess} />

        {destination && (
          <DuplicateDestination
            type={destination.type}
            visible={step === ProcessSteps.AssignName}
            toggleVisibility={closeProcess}
            onDuplicate={duplicateDestination}
            processing={processing}
            isCheckingDrivePermission={isCheckingDrivePermission}
            name={name}
            setName={setName}
          />
        )}
      </>
    )
  }
}
