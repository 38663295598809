import classNames from 'classnames'
import Papa from 'papaparse'
import { FC, useEffect, useMemo, useState } from 'react'

import { SendRightIcon } from '@assets'

import { CollectionTestEmailModal } from '@features/collections/components/collection-test-email-modal'
import { useCollectionEmailConfigHandler } from '@features/collections/hooks/use-collection-email-config-handler'
import {
  ICollection,
  ICollectionEmailConfig,
  ICollectionEmailRecipientConfig,
  ICollectionVariant,
} from '@features/collections/types'
import { getEmptyCollectionEmailConfig, getVariantName } from '@features/collections/utils'
import { isEmailDestination } from '@features/destinations'
import { DestinationEmailConfigSharingPermission } from '@features/destinations/types'
import { SelectEmailTemplate } from '@features/emails/components/select-email-template'
import { EEmailTemplateType, ESelectEmailTemplateEditorMode } from '@features/emails/types'

import { Button, FileInput, SelectableOption, Spinner, Switch } from '@shared/components'
import { EmailConfigTrigger } from '@shared/components/email-config/types'
import { MAX_CSV_ROWS, MAX_SAMPLE_ROWS } from '@shared/components/file-input/consts'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'
import { isValidEmail } from '@shared/utils'
import { createSharingPermissionOptions } from '@shared/utils/sending'

import { getUpdateCollectionTemplateVariables } from './utils'

import styles from './collection-sending.module.css'

export interface ICollectionSendingParams {
  collection: ICollection
  isLoading?: boolean
  initialCollectionEmailConfig?: ICollectionEmailConfig
}

export const CollectionSending: FC<ICollectionSendingParams> = ({ collection, isLoading }) => {
  const [fileProcessing, setFileProcessing] = useState<boolean>(false)
  const [fileError, setFileError] = useState<string | null>(null)
  const [isTestEmailModalOpen, setIsTestEmailModalOpen] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const { emailConfig, setEmailConfig, upsertEmailConfig, isEmailConfigLoading } = useCollectionEmailConfigHandler(
    collection.id
  )

  const sharingPermissionOptions: { label: string; value: DestinationEmailConfigSharingPermission }[] = useMemo(
    () => createSharingPermissionOptions({ type: collection.type }),
    [collection.type]
  )

  useEffect(() => {
    setIsFormValid(!emailConfig?.recipientConfig || emailConfig?.recipientConfig.length === 0)
  }, [emailConfig?.recipientConfig])

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Spinner localCenter />
      </div>
    )
  }

  const generateCsvExample = (collectionName: string, variants: ICollectionVariant[]) => {
    // First line of the CSV
    let csvString = 'variant_name,recipient_email\n'

    // Adding a few sample rows
    if (variants && variants.length > 0) {
      for (let i = 0; i < Math.min(MAX_SAMPLE_ROWS, variants.length); i++) {
        csvString += `${getVariantName(collectionName, variants[i].destination.name)},example${i}@example.com\n`
      }
    }

    return csvString
  }

  const onFileParseBefore = (chunk: string) => {
    setFileProcessing(true)
    setEmailConfig({
      recipientConfig: [],
    })
    setFileError(null)
    return chunk
  }

  const onComplete = (results: Papa.ParseResult<ICollectionEmailRecipientConfig>) => {
    setFileProcessing(false)
    if (results.meta.truncated) {
      setFileError(`File is too big. The maximum lines allowed is ${MAX_CSV_ROWS}`)
      return
    }
    if (!results.meta.fields?.includes('recipient_email')) {
      setFileError('File is missing "recipient_email" column')
      return
    }
    if (!results.meta.fields?.includes('variant_name')) {
      setFileError('File is missing "variant_name" column')
      return
    }

    const firstInvalidRow = results.data.find(row => !isValidEmail(row.recipient_email))
    if (firstInvalidRow) {
      setFileError(`Invalid email address: ${firstInvalidRow.recipient_email}`)
      return
    }

    setEmailConfig({
      recipientConfig: results.data,
    })
  }

  const onError = (error: Error) => {
    setFileProcessing(false)
    setFileError(error.message)
    console.error('error processing file', error)
  }

  const handleFileSelected = (file: File) => {
    Papa.parse<ICollectionEmailRecipientConfig>(file, {
      beforeFirstChunk: onFileParseBefore,
      complete: onComplete,
      delimiter: ',',
      error: onError,
      header: true,
      preview: MAX_CSV_ROWS,
      skipEmptyLines: true,
    })
  }

  const handleSharingPermissionClick = (value: DestinationEmailConfigSharingPermission) => () => {
    if (emailConfig?.sharingPermission === value) {
      setEmailConfig({
        sharingPermission: null,
      })
    } else {
      setEmailConfig({
        sharingPermission: value,
      })
    }
  }

  const handleAutomaticSendChange = (value: boolean) => {
    setEmailConfig({
      trigger: value ? EmailConfigTrigger.all : EmailConfigTrigger.none,
    })
  }

  const handleSaveClicked = () => {
    if (!emailConfig?.recipientConfig || emailConfig.recipientConfig.length === 0) {
      setFileError('Please upload a CSV file')
      return
    }

    upsertEmailConfig({ ...getEmptyCollectionEmailConfig(collection.id), ...emailConfig })
  }

  const getCSVPreview = () => {
    const columns: string[] = Object.keys(emailConfig?.recipientConfig?.[0] || {})
    const maxColumnLength = columns.reduce(
      (acc, column) => {
        const values = emailConfig?.recipientConfig?.map(config => config[column]) || []
        acc[column] = Math.max(...values.map(value => value?.length || 0))
        return acc
      },
      {} as Record<string, number>
    )
    const columnWithoutEmailAndName = columns.filter(
      column => column !== 'recipient_email' && column !== 'recipient_name'
    )
    const preview = emailConfig?.recipientConfig
      ?.map(config => {
        return [
          config.recipient_email.padEnd(maxColumnLength['recipient_email'] || 0),
          config.recipient_name?.padEnd(maxColumnLength['recipient_name'] || 0),
          ...columnWithoutEmailAndName.map(column => {
            return config[column]?.padEnd(maxColumnLength[column])
          }),
        ].join(',')
      })
      .join('\n')
    return preview
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.label}>Recipients</h3>
      <section className={styles.rowContainer}>
        <div className={styles.rowContainerItem}>
          <label className={styles.inputLabel}>Recipients</label>
          {fileError && (
            <div>
              <label className={styles.inputError} htmlFor="file-input">
                {fileError}
              </label>
            </div>
          )}
          <FileInput
            id="file-input"
            containerClassName={styles.input}
            fileType=".csv"
            disabled={fileProcessing}
            onFileSelected={handleFileSelected}
          />
          <div className={styles.hint}>
            * The file should contain all mandatory columns: recipient_email, variant_name.
          </div>
        </div>
        <div className={styles.rowContainerItem}>
          <div className={styles.csvExample}>
            <p className={styles.csvExampleTitle}>CSV Example</p>
            <div>
              <pre className="text-sm-regular">
                {generateCsvExample(collection.name, collection.collectionVariants)}
              </pre>
            </div>
          </div>
        </div>
        {emailConfig?.recipientConfig && emailConfig.recipientConfig.length > 0 && (
          <div className={styles.rowContainerItem}>
            <div className={styles.csvExample}>
              <p className={styles.csvExampleTitle}>CSV Preview</p>
              <div>
                <pre className="text-sm-regular">{getCSVPreview()}</pre>
              </div>
            </div>
          </div>
        )}
      </section>
      {isFeatureEnabled('email-templates') && !isEmailDestination(collection.collectionVariantTemplate.destination) && (
        <>
          <h3 className={styles.label}>Email template</h3>
          <div className="flex gap-2">
            <SelectEmailTemplate
              targetId={collection.id}
              editorMode={ESelectEmailTemplateEditorMode.MODAL}
              variables={getUpdateCollectionTemplateVariables(
                collection.type,
                collection.user,
                collection.collectionVariants[0]?.destination.name
              )}
              destinationType={collection.type}
              templateType={EEmailTemplateType.collectionUpdate}
              initialTemplateId={emailConfig?.emailTemplateId}
              onSelectTemplate={templateId => {
                setEmailConfig({
                  ...emailConfig,
                  emailTemplateId: templateId,
                })
              }}
            />
          </div>
        </>
      )}
      <h3 className={styles.label}>Sharing Permission</h3>
      <section className={styles.rowContainer}>
        {sharingPermissionOptions.map(option => (
          <SelectableOption
            className={styles.sharingOptionItem}
            key={option.value}
            selected={emailConfig?.sharingPermission === option.value}
            onClick={handleSharingPermissionClick(option.value)}
          >
            {option.label}
          </SelectableOption>
        ))}
      </section>
      <h3 className={styles.label}>Automatic send after each update</h3>
      <section className={classNames(styles.rowContainer, styles.justifyStart)}>
        <Switch value={emailConfig?.trigger === EmailConfigTrigger.all} onChange={handleAutomaticSendChange} />
      </section>

      <hr />

      <section className={classNames(styles.rowContainer, styles.saveButtonRow)}>
        <Button
          text="Send test"
          iconLeading={<SendRightIcon />}
          secondaryGray
          onClick={() => setIsTestEmailModalOpen(true)}
        />
        <Button text="Save" onClick={handleSaveClicked} loading={isEmailConfigLoading} disabled={isFormValid} />
      </section>

      <CollectionTestEmailModal
        collection={collection}
        variants={collection.collectionVariants}
        isOpen={isTestEmailModalOpen}
        onClose={() => setIsTestEmailModalOpen(false)}
        initialTemplateId={emailConfig?.emailTemplateId}
        key={emailConfig?.emailTemplateId}
      />
    </div>
  )
}
