import { Login } from '@pages/login/login'
import { TrustedBy } from '@pages/login/trusted-by/trusted-by'

import { RollstackWallPaper } from '@assets/wallpaper/rollstack-wallpaper'

import styles from './login-page.module.css'

export const LoginPage = () => {
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <Login />
      </div>
      <div className={styles.verticalLine}></div>
      <div className={styles.wallpaperContainer}>
        <RollstackWallPaper />
        <TrustedBy />
      </div>
    </div>
  )
}
