// Stole from
// https://github.com/ueberdosis/tiptap/issues/333#issuecomment-1056434177
import Image from '@tiptap/extension-image'
import { type NodeViewProps, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import { type CSSProperties, FC, RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react'

import { Colors } from '@shared/constants'
import { cn } from '@shared/utils'

import './resizable-image-extension.css'

const useEvent = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef<T | null>(null)

  useLayoutEffect(() => {
    handlerRef.current = handler
  }, [handler])

  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    if (handlerRef.current === null) {
      throw new Error('Handler is not assigned')
    }
    return handlerRef.current(...args)
  }, []) as T
}

const MIN_WIDTH = 60

enum DIRECTIONS {
  NW = 'nw',
  NE = 'ne',
  SW = 'sw',
  SE = 'se',
}

const DragCornerButton: FC<{
  direction: DIRECTIONS
  imgRef: RefObject<HTMLImageElement>
  onResizeStart: (width: number) => void
  onResizeEnd: (width: number) => void
}> = ({ direction, imgRef, onResizeEnd, onResizeStart }) => {
  const handleMouseDown = useEvent((event: React.MouseEvent<HTMLDivElement>) => {
    if (!imgRef.current) return
    event.preventDefault()
    const direction = event.currentTarget.dataset.direction || '--'
    const initialXPosition = event.clientX
    const currentWidth = imgRef.current.width
    let newWidth = currentWidth
    const transform = direction[1] === 'w' ? -1 : 1

    const removeListeners = () => {
      window.removeEventListener('mousemove', mouseMoveHandler)
      window.removeEventListener('mouseup', removeListeners)
      onResizeEnd(newWidth)
    }

    const mouseMoveHandler = (event: MouseEvent) => {
      // If mouse is up, remove event listeners
      newWidth = Math.max(currentWidth + transform * (event.clientX - initialXPosition), MIN_WIDTH)
      onResizeStart(newWidth)
      if (!event.buttons) removeListeners()
    }

    window.addEventListener('mousemove', mouseMoveHandler)
    window.addEventListener('mouseup', removeListeners)
  })

  return (
    <div
      role="button"
      tabIndex={0}
      onMouseDown={handleMouseDown}
      data-direction={direction}
      className={cn(
        'absolute h-2.5 w-2.5 bg-primary',
        direction[0] === 'n' && 'top-0',
        direction[0] === 's' && 'bottom-0',
        direction[1] === 'e' && 'right-0',
        direction[1] === 'w' && 'left-0'
      )}
      style={{
        cursor: `${direction}-resize`,
      }}
    />
  )
}

const ResizableImageTemplate = ({ node, updateAttributes, selected }: NodeViewProps) => {
  const imgRef = useRef<HTMLImageElement>(null)

  const [resizingStyle, setResizingStyle] = useState<Pick<CSSProperties, 'width'> | undefined>()

  const { textAlign, ...attrs } = node.attrs
  return (
    <NodeViewWrapper>
      <div
        className={cn('image-container overflow-hidden relative inline-block', selected && 'outline')}
        style={{
          // Weird! Basically tiptap/prose wraps this in a span and the line height causes an annoying buffer.
          // No Tailwind class is available to remove this.
          lineHeight: '0px',
          outlineColor: Colors.primary600,
        }}
      >
        <img
          {...attrs}
          className={cn('inline-block')}
          alt={node.attrs.src}
          ref={imgRef}
          // Weird! Basically tiptap/prose wraps this in a span and the line height causes an annoying buffer.
          // No Tailwind class is available to remove this.
          style={{
            lineHeight: '0px',
            width: resizingStyle?.width || attrs.width,
          }}
        />
        <div className="after-overlay" />
        {selected &&
          [DIRECTIONS.NW, DIRECTIONS.NE, DIRECTIONS.SW, DIRECTIONS.SE].map(direction => (
            <DragCornerButton
              key={direction}
              direction={direction}
              imgRef={imgRef}
              onResizeStart={width => {
                setResizingStyle({ width })
              }}
              onResizeEnd={width => {
                updateAttributes({ width })
                setResizingStyle(undefined)
              }}
            />
          ))}
      </div>
    </NodeViewWrapper>
  )
}

export const TipTapResizableImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: { renderHTML: ({ width }) => ({ width }) },
      height: { renderHTML: ({ height }) => ({ height }) },
      'data-sync-id': {
        default: null,
        parseHTML: (element: HTMLElement) => {
          return element.hasAttribute('data-sync-id') ? element.getAttribute('data-sync-id') : null
        },
      },
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(ResizableImageTemplate)
  },
}).configure({
  inline: true,
  HTMLAttributes: {
    style: 'max-width: 1024px;',
  },
})
