import classNames from 'classnames'
import { FC, Fragment, useContext } from 'react'

import { XClose } from '@assets'
import { ChevronDownUp } from '@assets/icons/arrows'

import {
  formatVizName,
  useCancelAddVariants,
  useCancelAllDestinationUpdates,
  useCancelDestinationSync,
} from '@features/destinations'
import { Destination } from '@features/destinations/types'
import { FreeTrialContext } from '@features/free-trial'
import { useDestinationsStatuses } from '@features/notch/destinations-status-provider'
import { retrieveDestinationErrorString, retrieveErrorString } from '@features/notch/utils'
import { useUpdateSyncsById } from '@features/syncs'

import { Button, Collapsable } from '@shared/components'

import { ProgressBar } from './progress-bar/progress-bar'
import {
  addVariantStatusToIcon,
  calculateAllPercentageProgress,
  calculatePercentageProgress,
  combinedStatus,
  destinationStatusToIcon,
  regenerateVariantStatusToIcon,
  visStatusToIcon,
} from './utils'

import styles from './destinations-statuses.module.css'

export const DestinationsStatuses: FC = () => {
  const {
    destinationsStatuses,
    addVariantsStatuses,
    regenerateVariantsStatuses,
    notchVisible,
    notchOpen,
    allFinished,
    toggle,
    hideNotchAndClear,
    cancelDestinationUpdate,
    cancelAllDestinationUpdates,
    cancelAddVariants,
  } = useDestinationsStatuses()

  const updateSelectedSyncs = useUpdateSyncsById()
  const presentationCancelQuery = useCancelDestinationSync()
  const cancelAllUpdatesQuery = useCancelAllDestinationUpdates()
  const cancelAddVariantsMutation = useCancelAddVariants()

  const { freeTrial } = useContext(FreeTrialContext)

  const handleVisRetry = async (destination: Pick<Destination, 'id' | 'type'>, visId: string) => {
    freeTrial(
      () =>
        updateSelectedSyncs.mutateAsync({
          destinationId: destination.id,
          syncIds: [visId],
          type: destination.type,
        }),
      { allowedExtraFreeUpdate: true }
    )
  }

  const cancelUpdate = async (destinationId: string) => {
    await presentationCancelQuery.mutateAsync(destinationId)
    cancelDestinationUpdate(destinationId)
  }

  const cancelAllUpdates = async () => {
    await cancelAllUpdatesQuery.mutateAsync()
    cancelAllDestinationUpdates()
  }

  const handleCancelAddVariants = async () => {
    await cancelAddVariantsMutation.mutateAsync()
    cancelAddVariants()
  }

  const retryAllFailed = async (destination: Pick<Destination, 'id' | 'type'>, syncIds: string[]) => {
    freeTrial(
      () => {
        updateSelectedSyncs.mutateAsync({ destinationId: destination.id, syncIds, type: destination.type })
      },
      { allowedExtraFreeUpdate: true }
    )
  }

  const showCross = allFinished
  const containerClass = classNames(styles.container, !notchVisible && styles.hidden)
  const addVariantsCreating = addVariantsStatuses.filter(s => s.status === 'CREATING')

  return (
    <div className={containerClass}>
      <div className={styles.header} onClick={() => toggle()}>
        {combinedStatus(destinationsStatuses, addVariantsStatuses, regenerateVariantsStatuses)}
        {showCross ? <XClose onClick={hideNotchAndClear} /> : <ChevronDownUp isUp={notchOpen} />}
      </div>
      {notchOpen ? (
        <div className={styles.noCombinedProgress} />
      ) : (
        <ProgressBar
          red={destinationsStatuses.some(destination => destination.status === 'FAILED')}
          progress={calculateAllPercentageProgress(
            destinationsStatuses,
            addVariantsStatuses,
            regenerateVariantsStatuses
          )}
        />
      )}
      <Collapsable open={notchOpen} className={styles.notchList}>
        <div className="flex flex-row-reverse pt-2 pr-2 gap-2">
          {addVariantsCreating.filter(s => s.status === 'CREATING').length > 0 && (
            <Button
              text={cancelAddVariantsMutation.isLoading ? 'Canceling...' : 'Cancel All Variants'}
              loading={cancelAddVariantsMutation.isLoading}
              secondaryGray
              className={styles.cancelButton}
              onClick={() => handleCancelAddVariants()}
            />
          )}
          {destinationsStatuses
            .filter(s => !addVariantsCreating.find(a => a.collectionId === s.collectionId))
            .filter(s => s.status === 'RUNNING').length > 0 && (
            <Button
              text={cancelAllUpdatesQuery.isLoading ? 'Canceling...' : 'Cancel All Updates'}
              secondaryGray
              loading={cancelAllUpdatesQuery.isLoading}
              className={styles.cancelButton}
              onClick={cancelAllUpdates}
            />
          )}
        </div>

        {destinationsStatuses.map(destination => {
          const showCancelButton = destination.status === 'PENDING' || destination.status === 'RUNNING'
          const showRetryAllFailedButton = destination.status === 'PARTIALLY_COMPLETED'
          const failedSyncIds = destination.vises.filter(vis => vis.status === 'FAILED').map(vis => vis.id)
          return (
            <Fragment key={destination.id}>
              <div key={destination.id} className={styles.destinationUpdate}>
                <div className={styles.status}>
                  {destinationStatusToIcon(destination.status)}
                  <div className={styles.withCancelButton}>
                    <div className={classNames(styles.title, showCancelButton && styles.hasCancelButton)}>
                      {destination.name}
                      {destination.error && (
                        <div className={styles.error}>{retrieveDestinationErrorString(destination.error)}</div>
                      )}
                    </div>
                    {showCancelButton && (
                      <Button
                        className={styles.cancelButton}
                        text={presentationCancelQuery.isLoading ? 'Canceling...' : 'Cancel'}
                        secondaryGray
                        onClick={() => cancelUpdate(destination.id)}
                        disabled={presentationCancelQuery.isLoading}
                      />
                    )}
                    {showRetryAllFailedButton && (
                      <div className={styles.visBtnContainer}>
                        <Button
                          text="Retry All Failed"
                          secondaryGray
                          className={styles.visBtn}
                          onClick={() => retryAllFailed(destination, failedSyncIds)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-2">
                  {destination.vises &&
                    destination.vises.map(vis => (
                      <div className={styles.visContainer} key={vis.id}>
                        <div className={styles.visStatus} title={formatVizName(vis.name)}>
                          {visStatusToIcon(vis.status)}
                          <div className={styles.visName}>{formatVizName(vis.name)}</div>
                          {vis.error && (
                            <div className={styles.visBtnContainer}>
                              <Button
                                text="Retry"
                                secondaryGray
                                className={styles.visBtn}
                                onClick={() => handleVisRetry(destination, vis.id)}
                              />
                            </div>
                          )}
                        </div>
                        {vis.error && <div className={styles.error}>{retrieveErrorString(vis.error)}</div>}
                      </div>
                    ))}
                </div>
              </div>
              <ProgressBar red={destination.status === 'FAILED'} progress={calculatePercentageProgress(destination)} />
            </Fragment>
          )
        })}

        {addVariantsStatuses.map(status =>
          status.variants
            ?.filter(variantStatus => !destinationsStatuses.find(destination => destination.id === variantStatus.id))
            ?.map(variant => (
              <div key={variant.name} className={styles.destinationUpdate}>
                <div className={styles.status}>
                  {addVariantStatusToIcon(variant.status)}
                  <div className={styles.title}>{variant.name}</div>
                </div>
              </div>
            ))
        )}

        {regenerateVariantsStatuses.map(status =>
          status.variants
            ?.filter(
              variantStatus => !destinationsStatuses.find(destination => destination.id === variantStatus.destinationId)
            )
            ?.map(variant => (
              <div key={variant.name} className={styles.destinationUpdate}>
                <div className={styles.status}>
                  {regenerateVariantStatusToIcon(variant.status)}
                  <div className={styles.title}>{variant.name}</div>
                </div>
              </div>
            ))
        )}
      </Collapsable>
    </div>
  )
}
