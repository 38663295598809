import { FC, useMemo } from 'react'

import { CollectionTabs, buildCollectionPath } from '@pages/routes/paths'

import { EmailIcon, ExternalLinkIcon, SendRightIcon, SmallSpinner, UpdateIcon } from '@assets'
import { CollectionBigIcon, CollectionIcon } from '@assets/icons/collection'
import { SettingsIcon } from '@assets/icons/settings'

import {
  CollectionMenu,
  CollectionSettings,
  WithCollectionRenameProps,
  WithCollectionShareProps,
  WithCollectionUpdateProps,
  useCollection,
  withCollectionRename,
  withCollectionShare,
  withCollectionUpdate,
} from '@features/collections'
import { RecreateVariantsModal } from '@features/collections/components/collection-details/recreate-variants-modal/recreate-variants-modal'
import { CollectionSendingConfirmModal } from '@features/collections/components/collection-sending-confirm-modal/collection-sending-confirm-modal'
import { CollectionSending } from '@features/collections/components/collection-sending/collection-sending'
import { CollectionVariantsList } from '@features/collections/components/collection-variants/collection-variants'
import { isSharedItem } from '@features/destinations'
import { UpdateMethod } from '@features/destinations/types'
import { useAuthStore } from '@features/users'

import { Avatar, Button, NewTabLink, OverlayingComponents, PageContainer, Tooltip } from '@shared/components'
import { RoutedTabs, Tab } from '@shared/components/routed-tabs/routed-tabs'

import styles from './collection.module.css'

type CollectionTab = Omit<Tab, 'tabIndex'>

export const CollectionRaw: FC<WithCollectionUpdateProps & WithCollectionShareProps & WithCollectionRenameProps> = ({
  updateAll,
  shareCollection,
  renameCollection,
}) => {
  const {
    cancelIsLoading,
    canSendCollectionEmail,
    collection,
    collectionEmailSettings,
    collectionEmailSettingsLoading,
    isCollectionSendingOpen,
    setIsCollectionSendingOpen,
    templateDestination,
    admin,
    recreateVariantsModal,
    goBack,
    isLoading,
    isUpdateRunning,
    onCancelCollectionUpdate,
    onUpdate,
    toggleRecreateVariantsModal,
    contributor,
    owner,
  } = useCollection({ updateAll })

  const { user } = useAuthStore()

  const shared = useMemo(() => !!collection && isSharedItem(collection, user), [collection, user])

  if (!collection || !templateDestination) {
    return <PageContainer loading />
  }

  const header = (
    <div className={styles.collectionTitle}>
      <div className={styles.title}>
        <CollectionBigIcon />
      </div>
      <div className="max-w-[500px] whitespace-nowrap overflow-hidden text-ellipsis">{collection.name}</div>
      {(owner || admin) && (
        <div className="text-primary-700">
          <NewTabLink link={collection.folderUrl}>
            <ExternalLinkIcon />
          </NewTabLink>
        </div>
      )}
    </div>
  )

  const variantsTab: CollectionTab = {
    tabComponent: (
      <div className={styles.tab}>
        <CollectionIcon />
        Variants
      </div>
    ),
    tabPanel: (key: React.Key) => (
      <CollectionVariantsList
        key={key}
        collection={collection}
        shared={shared}
        templateDestination={templateDestination}
      />
    ),
    path: buildCollectionPath(collection.id, CollectionTabs.VARIANTS),
  }

  const sendingTab: CollectionTab = {
    tabComponent: (
      <div className={styles.tab}>
        <EmailIcon />
        Sending
      </div>
    ),
    tabPanel: (key: React.Key) => (
      <CollectionSending
        key={key}
        collection={collection}
        initialCollectionEmailConfig={collectionEmailSettings}
        isLoading={collectionEmailSettingsLoading}
      />
    ),
    path: buildCollectionPath(collection.id, CollectionTabs.SENDING),
  }

  const settingsTab: CollectionTab = {
    tabComponent: (
      <div className={styles.tab}>
        <SettingsIcon />
        Settings
      </div>
    ),
    tabPanel: (key: React.Key) => <CollectionSettings key={key} collection={collection} />,
    path: buildCollectionPath(collection.id, CollectionTabs.SETTINGS),
  }

  const tabs = owner || admin ? [variantsTab, sendingTab, settingsTab] : [variantsTab]

  const sharedWithUsers = [
    { userId: collection.userId, user: collection.user }, // owner
    ...(collection.sharedWith ?? []),
  ].filter(u => u.userId !== user?.id)

  const onUpdateButtonClick = () => {
    if (isUpdateRunning) {
      onCancelCollectionUpdate(collection)
    } else {
      onUpdate(collection)
    }
  }

  const updateButtonText =
    templateDestination.updateMethod === UpdateMethod.Update
      ? 'Update all'
      : templateDestination.updateMethod === UpdateMethod.Archive
        ? 'Archive & Update all'
        : 'Update all visualizations'

  return (
    <PageContainer
      title={header}
      rightActionButtons={
        <div className={styles.actionButtons}>
          <OverlayingComponents
            renderMoreComponents={hiddenCount => (
              <Avatar name={`+ ${hiddenCount.toString()}`} size={30} className="text-xs-medium" />
            )}
          >
            <Avatar key={user?.id} name={user?.name} size={30} className="text-xs-medium" />
            {sharedWithUsers.map(u => (
              <Avatar key={u.userId} name={u.user.name} size={30} className="text-xs-medium" />
            ))}
          </OverlayingComponents>
          <CollectionMenu
            collection={collection}
            owner={owner}
            admin={admin}
            contributor={contributor}
            shared={shared}
            shareCollection={() => shareCollection(collection.id)}
            renameClick={() => renameCollection(collection, true)}
            toggleRegenerateVariantsModal={toggleRecreateVariantsModal}
          />
          {(owner || admin) && (
            <Tooltip
              position="top"
              title="Collection can only be sent after the email sending is configured for it"
              show={!canSendCollectionEmail}
            >
              <Button
                secondaryColor
                iconLeading={<SendRightIcon size={20} />}
                text="Send all"
                disabled={!canSendCollectionEmail}
                onClick={() => setIsCollectionSendingOpen(true)}
              />
            </Tooltip>
          )}

          {isUpdateRunning ? (
            <Button
              text="Cancel"
              iconLeading={<SmallSpinner />}
              loading={cancelIsLoading}
              onClick={onUpdateButtonClick}
              disabled={contributor}
            />
          ) : (
            <Button text={updateButtonText} iconLeading={<UpdateIcon />} onClick={onUpdateButtonClick} />
          )}
        </div>
      }
      loading={isLoading}
      back={goBack}
    >
      <RoutedTabs
        tabs={tabs.map((tab, idx) => ({ ...tab, tabIndex: idx }))}
        defaultRoute={tabs[0].path}
        defaultTabIndex={0}
        selectedTabPanelClassName={styles.tabPanel}
      />

      <RecreateVariantsModal
        visible={recreateVariantsModal}
        toggle={toggleRecreateVariantsModal}
        collection={collection}
      />

      {collectionEmailSettings && (
        <CollectionSendingConfirmModal
          collectionId={collection.id}
          collectionEmailConfig={collectionEmailSettings}
          isOpen={isCollectionSendingOpen}
          onClose={() => setIsCollectionSendingOpen(false)}
          templateName={collection.collectionVariantTemplate.destination.name}
        />
      )}
    </PageContainer>
  )
}

export const Collection = withCollectionRename(withCollectionShare(withCollectionUpdate(CollectionRaw)))
