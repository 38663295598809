import { FC } from 'react'

import { IIcon } from '@assets/types'

export const ArrowLeft: FC<IIcon> = ({ className, onClick }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={className} onClick={onClick}>
      <path
        d="M15.8327 9.99935H4.16602M4.16602 9.99935L9.99935 15.8327M4.16602 9.99935L9.99935 4.16602"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
