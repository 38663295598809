import { FC, ReactElement } from 'react'

import { GreenCheckCircleIcon } from '@assets'

import { Button, Modal } from '@shared/components'

import styles from './update-presentation-finished.module.css'

export enum ModalStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface IUpdatePresentationFinished {
  status: ModalStatus | null
  visible: boolean
  title?: string
  subtitle?: string
  linkToDestination?: ReactElement
  onNext?: () => void
}

export const UpdatePresentationFinished: FC<IUpdatePresentationFinished> = ({
  status,
  visible,
  onNext,
  title = 'Presentation updated!',
  subtitle = '',
  linkToDestination,
}) => {
  const handleNextStep = () => {
    if (onNext) {
      onNext()
    }
  }

  const successView = (
    <div className={styles.flex}>
      <GreenCheckCircleIcon />
      <div className={styles.modalTitle}>{title}</div>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.buttonsContainer}>
        <Button text="Ok" secondaryGray onClick={handleNextStep} fullWidth={!Boolean(linkToDestination)} />
        {linkToDestination}
      </div>
    </div>
  )

  const errorView = (
    <div className={styles.flex}>
      <span className={styles.modalTitle}>Error</span>
      <p className={styles.subtitle}>Something went wrong. Please try again later.</p>
      <div className={styles.buttonsContainer}>
        <Button text="Ok" secondaryGray onClick={handleNextStep} fullWidth={!Boolean(linkToDestination)} />
      </div>
    </div>
  )

  if (!status) return null

  return (
    <Modal open={visible} className={styles.container} onBackgroundClick={handleNextStep}>
      {status === ModalStatus.SUCCESS ? successView : errorView}
    </Modal>
  )
}
