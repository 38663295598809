type TutorialStep = {
  text: string
  imgUrl?: string
}

export type TutorialSection = {
  title: string
  description: string
  steps: TutorialStep[]
}

export enum ETutorialSections {
  SETUP = 'setup',
  PROVISIONING = 'provisioning',
}

export type ITutorialDataSections = Record<ETutorialSections, TutorialSection>
