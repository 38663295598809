import { FC, PropsWithChildren } from 'react'

import { ImageWithFallback, Tooltip } from '@shared/components'

import styles from './syncs-tooltip.module.css'

interface IVisualizationTooltip extends PropsWithChildren {
  title: string
  slideIndex: number
  imageUrl?: string
}

export const SyncsTooltip: FC<IVisualizationTooltip> = ({ title, slideIndex, imageUrl, children }) => (
  <Tooltip
    position="right"
    title={
      <div className={styles.container}>
        <ImageWithFallback src={imageUrl} alt="Visualization" className={styles.image} />
        <h3 className={styles.title}>{title}</h3>
        <p>Slide {slideIndex}</p>
      </div>
    }
  >
    {children}
  </Tooltip>
)
