import { TableauWorkbook } from '@features/data-sources'
import {
  AddLookerData,
  AddMetabaseData,
  AddSpreadsheetsData,
  AddTableauData,
  LookerData,
  MetabaseData,
  SpreadsheetsData,
  TableauData,
} from '@features/data-sources/types'
import { EDataSourceType } from '@features/data-sources/types/types'
import { ILookerDashboard } from '@features/embedding/looker/types'
import { IMetabaseDashboard } from '@features/embedding/metabase/types'
import { IAppliedFilter, IFilter } from '@features/filters/types'
import { User } from '@features/users'

export type IntegrationDashboard = null | IMetabaseDashboard | ILookerDashboard | TableauWorkbook

export type IBatchApplyFilterResponse = {
  failedSyncs: ISync[]
  updatedSyncs: ISync[]
}

export enum SyncStatus {
  Active = 'active',
  Draft = 'draft',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Inactive = 'inactive',
}

export enum CropOption {
  Auto = 'auto',
  Manual = 'manual',
  None = 'none',
}
export const cropOptions = [CropOption.Auto, CropOption.Manual, CropOption.None]

export enum SyncUpdateType {
  image = 'image',
  text = 'text',
}

export interface CropValues {
  left: number
  top: number
  width: number
  height: number
}

export interface SyncCoreDataSource {
  id: string
  name: string
  type: EDataSourceType
  baseUrl: string

  siteName?: string
}

type CanEditVizCode = 'invalid-credentials' | 'missing-credentials' | 'view-only' | 'unsupported-source-type' | null
type OauthCapabilityCode = 'missing-oauth' | 'view-only' | 'invalid-oauth' | null
type TransferOwnershipCode = 'view-only' | 'already-owner' | null

export type SyncCapabilities = {
  editViz: {
    value: boolean
    code: CanEditVizCode
  }
  crop: {
    value: boolean
    code: OauthCapabilityCode
  }
  moveToSlide: {
    value: boolean
    code: OauthCapabilityCode
  }
  update: {
    value: boolean
    code: OauthCapabilityCode
  }
  duplicate: {
    value: boolean
    code: OauthCapabilityCode
  }
  transferOwnership: {
    value: boolean
    code: TransferOwnershipCode
  }
  delete: {
    value: boolean
    code: OauthCapabilityCode
  }
}
export enum SyncStatusMessage {
  // credentials
  invalid_credentials = 'Invalid credentials',
  missing_credentials = 'Missing credentials',

  // dashboards
  tableau_view_not_found = 'Tableau view was not found', // deprecated
  dashboard_not_found = 'Dashboard was not found',
  dashboard_not_ready = 'Dashboard took too long to load',
  dashboard_has_errors = 'Dashboard has errors',

  // sheets
  file_not_found = 'File not found',
  sheet_not_found = 'Sheet not found',
  range_not_found = 'Range not found',

  // elements
  metabase_element_not_found = 'Metabase element was not found', // deprecated
  looker_element_not_found = 'Looker element was not found', // deprecated
  element_not_found = 'Visualization no longer exists',

  // visualizations
  visualization_not_found = 'Visualisation was not found within the dashboard',
  visualization_not_ready = 'Visualisation took too long to load',
  screenshot_failed = 'Failed to capture screenshot',
  no_iframe = 'Dashboard failed to load correctly',

  // documents
  google_slide_not_found = 'Slide was not found',
  slide_not_found = 'Slide was not found',
}

export enum DestinationStatusMessage {
  file_unavailable = 'File is not found or not authorized',
  file_corrupted = 'File is corrupted. Please try again',
  file_locked = 'File is locked. Please try again',
  insufficient_permissions = 'Insufficient permissions to edit the file',
}

export interface ISync {
  id: string
  name: string
  userId: number
  user: Pick<User, 'id' | 'name' | 'email'>
  type: EDataSourceType
  presentationId: string
  slideId: string
  slideIndex: number
  group: string
  groupIndex: number
  duplicatedSyncId: string
  updatedAt: string
  gObjectId?: string
  status?: SyncStatus
  statusMessage?: SyncStatusMessage
  imageUrl?: string
  imageUpdatedAt?: string
  originalImageUrl?: string
  originalImageUpdatedAt?: string
  cropOption: CropOption
  manualCropValues?: CropValues
  autoCropValues?: CropValues
  removedBackground: boolean
  enhancedResolution: boolean
  parentSyncId: string | null
  text: string | null
  updateType: SyncUpdateType

  lookerData?: LookerData
  tableauData?: TableauData
  metabaseData?: MetabaseData
  spreadsheetsData?: SpreadsheetsData

  appliedFilters?: IAppliedFilter[]
  applicableFilters?: IFilter[]

  capabilities?: SyncCapabilities
  isDetachedDeleted?: boolean

  createdAt: string
}

export type BatchAddSyncRequest = {
  syncs: {
    name: string
    tableauData?: Pick<AddTableauData, 'sheetName' | 'objectId' | 'objectType'> | { fullDashboard: boolean }
    lookerData?: Pick<AddLookerData, 'elementId' | 'elementIndex' | 'elementName'> | { fullDashboard: boolean }
    metabaseData?: Pick<AddMetabaseData, 'elementId' | 'elementIndex' | 'elementName'> | { fullDashboard: boolean }
    spreadsheetsData?: Pick<AddSpreadsheetsData, 'cellRange' | 'gridlines' | 'freezeRows' | 'collapsedRows'>
  }[]
  type: EDataSourceType
  presentationId: string
  slideId: string
  slideIndex: number
  group: string
  groupIndex: number
  status?: SyncStatus
  gObjectId?: string
  manualCropValues?: string | null

  tableauData?: Omit<AddTableauData, 'sheetName' | 'objectId' | 'objectType'>
  lookerData?: Omit<AddLookerData, 'elementId' | 'elementIndex' | 'elementName'>
  metabaseData?: Omit<AddMetabaseData, 'elementId' | 'elementIndex' | 'elementName'>
  spreadsheetsData?: Omit<AddSpreadsheetsData, 'cellRange' | 'gridlines' | 'freezeRows' | 'collapsedRows'>
}

export type ISyncUpdateRequest = {
  id: string
  updatedAt: string

  name?: string
  type?: EDataSourceType
  presentationId?: string
  slideId?: string
  slideIndex?: number
  group?: string
  groupIndex?: number
  status?: SyncStatus
  gObjectId?: string
  cropOption?: CropOption
  manualCropValues?: string | null
  removedBackground?: boolean
  enhancedResolution?: boolean

  tableauData?: Partial<AddTableauData> & { updatedAt?: Date | string }
  lookerData?: Partial<AddLookerData> & { updatedAt?: Date | string }
  metabaseData?: Partial<AddMetabaseData> & { updatedAt?: Date | string }
  spreadsheetsData?: Partial<AddSpreadsheetsData> & { updatedAt?: Date | string }
}

export type IBatchApplyFilterRequest = {
  fromSyncId: string
  syncIds: string[]
  jsonFilters?: string
  jsonParams?: string
}
