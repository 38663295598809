import { ColumnDef } from '@tanstack/react-table'
import { Dayjs } from 'dayjs'

import { ArrowRight } from '@assets'
import { CollectionIcon, CollectionPreviewIcon } from '@assets/icons/collection'

import { CollectionActionButtons } from '@features/collections/components/collection-element/collection-action-buttons'
import { ICollection } from '@features/collections/types'
import { destinationHandlers, getLastUpdate } from '@features/destinations'
import { User } from '@features/users'

import { Badge, Button, EBadgeColor } from '@shared/components'
import { RelativeDateTime } from '@shared/components/relative-date-time'
import { ThumbnailAndTitle } from '@shared/components/thumbnail-and-title/thumbnail-and-title'
import { numbers } from '@shared/constants'
import { DEFAULT_DATE_FORMAT } from '@shared/constants/dates'

import styles from './collections-table.module.css'

interface RowData {
  id: string
  collection: ICollection
  name: string
  lastUpdateAt: Dayjs | null
  onClick: () => void
}

export const CollectionsDataGridData = (
  collections: ICollection[],
  onClickHandler: (collectionId: string) => void
): RowData[] =>
  collections.map(collection => {
    return {
      id: collection.id,
      collection: collection,
      name: collection.name,
      lastUpdateAt: getLastUpdate(collection),
      onClick: () => onClickHandler(collection.id),
    }
  })

export const CollectionsDataGridColumns = (): ColumnDef<RowData>[] => {
  return [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <div className={styles.nameCell}>
          <CollectionIcon />
          {row.original.name}
        </div>
      ),
    },
    {
      accessorKey: 'lastUpdateAt',
      header: 'Last update',
      cell: ({ row }) => row.original.lastUpdateAt?.format(DEFAULT_DATE_FORMAT),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <CollectionActionButtons key={row.original.id} collection={row.original.collection} isGrid={false} />
      ),
    },
  ]
}

export const CollectionsDataGridElements = (
  user: User,
  onOpenCollectionClick: (collectionId: string) => void
): ColumnDef<RowData>[] => {
  return [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => {
        const thumbnail = (
          <div className={styles.thumbnail} onClick={() => onOpenCollectionClick(row.original.id)}>
            <div className={styles.previewIcon}>
              <CollectionPreviewIcon />
            </div>
            {row.original.collection.userId !== user?.id && (
              <div className={styles.badge}>
                <Badge color={EBadgeColor.SECONDARY} text="Shared with me" />
              </div>
            )}
            <div className={styles.hoverBlur}>
              <Button text="Open collection" iconTrailing={<ArrowRight />} stopPropagation={false} />
            </div>
          </div>
        )
        return (
          <ThumbnailAndTitle
            Thumbnail={thumbnail}
            TypeIcon={destinationHandlers(row.original.collection.type).getSmallIcon(
              numbers.defaultIconSize,
              styles.destinationIcon
            )}
            title={row.original.name}
          />
        )
      },
    },
    {
      accessorKey: 'lastUpdateAt',
      header: 'Last update',
      cell: ({ row }) => (
        <div className={styles.updatesContainer}>
          <span className={styles.updateTitle}>Last full update:</span>
          {row.original.lastUpdateAt ? <RelativeDateTime date={row.original.lastUpdateAt} /> : '--'}
        </div>
      ),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <CollectionActionButtons key={row.original.id} collection={row.original.collection} isGrid={true} />
      ),
    },
  ]
}
