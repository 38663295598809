import { FC } from 'react'

import { ISlide, isGoogleDocsDestination } from '@features/destinations'
import { Destination, EDestinationType } from '@features/destinations/types'
import { GDocs } from '@features/embedding'
import { SyncSlideSelect } from '@features/syncs/components/sync-slide-select/sync-slide-select'
import { SlideData } from '@features/syncs/hooks'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

interface SlidePreviewProps {
  destination: Destination
  slides: ISlide[]
  slideIndex: number
  slideId: string
  setSlideIndex: (slideIndex: number) => void
}

export const SlidePreview: FC<SlidePreviewProps> = ({ destination, slides, slideIndex, slideId, setSlideIndex }) => {
  const { docId, type } = destination
  const { updateSyncData } = useSyncSettingsContext()

  const selectSlide = (data: SlideData) => {
    updateSyncData({ slideData: data })
    setSlideIndex(data.slideIndex)
  }

  if (isGoogleDocsDestination(destination)) {
    return <GDocs docId={destination.docId} showFullDocument />
  }

  if (type === EDestinationType.GoogleSlides) {
    return (
      <SyncSlideSelect
        key={slideId}
        slides={slides}
        docId={docId}
        slideId={slideId}
        slideIndex={slideIndex}
        cb={selectSlide}
        type={EDestinationType.GoogleSlides}
      />
    )
  }

  if (type === EDestinationType.MicrosoftPowerPoint) {
    return (
      <SyncSlideSelect
        key={slideId}
        slides={slides}
        docId={docId}
        slideId={slideId}
        slideIndex={slideIndex}
        cb={selectSlide}
        type={EDestinationType.MicrosoftPowerPoint}
      />
    )
  }

  return null
}
