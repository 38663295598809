import { OnChangeFn, RowSelectionState, VisibilityState } from '@tanstack/react-table'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PathSegments, buildSlideIdSearchParam } from '@pages/routes/paths'

import { useCollectionSync } from '@features/collections'
import { CollectionVariantType, ICollection } from '@features/collections/types'
import { useAddDataSourcesFromDrive } from '@features/data-sources'
import { EDataSourceType } from '@features/data-sources/types/types'
import {
  ISlide,
  isDocumentDestination,
  isGoogleDestination,
  isPresentationDestination,
  useDestinationRights,
  useDuplicateSlides,
  useGetSlidesThumbnails,
  useRefreshDestinations,
} from '@features/destinations'
import { EDestinationVizColumn } from '@features/destinations/components/destination-visualizations/visualizations-table/visualizations-table'
import { Destination } from '@features/destinations/types'
import { useDestinationsStatuses } from '@features/notch/destinations-status-provider'
import { DeleteSyncsMutation, useSyncDuplicate } from '@features/syncs'
import { useSortAndFilterSyncs } from '@features/syncs/hooks/use-sync-sort-and-filter'
import { useSyncUpdate } from '@features/syncs/hooks/use-sync-update'
import { ISync } from '@features/syncs/types'
import { useAuthStore } from '@features/users'

import { useBoolean } from '@shared/hooks/use-boolean'
import { useSelectable } from '@shared/hooks/use-selectable'
import { queryClient } from '@shared/http'

type VisualizationModalFrom = 'ADD_VIS' | 'LEARN_MORE'
type TemplateModificationModalFrom = 'DUPLICATE_VIS' | 'DELETE_VIS'
const COLUMN_VISIBILITY_LOCAL_STORAGE_KEY = 'destinationVisTableColumnVisibilitySettings'

export const useDestinationVisTable = (
  destination: Destination,
  deleteSyncsWithConfirmation: (props: DeleteSyncsMutation) => void,
  slideId?: string | null,
  slides?: ISlide[]
) => {
  const destinationId = destination.id
  const collectionId = destination.variantInCollection?.collectionId

  const user = useAuthStore(state => state.user)
  const navigate = useNavigate()

  const { isDestinationUpdateRunning } = useDestinationsStatuses()
  const { editor, owner, viewer } = useDestinationRights(destination)

  const [openUpdateVariantsModal, setOpenUpdateVariantsModal] = useState(false)

  // Tutorial modals
  const [visualizationExplainerModal, setVisualizationExplainerModal] = useState<boolean>(false)
  const [visualizationModalFrom, setVisualizationModalFrom] = useState<VisualizationModalFrom>('ADD_VIS')
  const [refreshSlidesExplainerModal, toggleRefreshSlidesExplainerModal] = useBoolean()
  const [templateModificationModal, setTemplateModificationModal] = useState<boolean>(false)
  const [templateModificationModalFrom, setTemplateModificationModalFrom] =
    useState<TemplateModificationModalFrom>('DUPLICATE_VIS')
  const refreshDestinationMutation = useRefreshDestinations()
  const { data: slideThumbnails } = useGetSlidesThumbnails(destination.id)

  const [currentSync, setCurrentSync] = useState<ISync>()
  const [showSyncCrop, toggleShowSyncCrop] = useBoolean()
  const [showVisualizationSelectSheets, toggleShowVisualizationSelectSheets] = useBoolean()
  const [showSyncLocation, toggleShowSyncLocation] = useBoolean()
  const [columnVisibility, setColumnVisibility] = useState<{ [key in EDestinationVizColumn]?: boolean }>({
    [EDestinationVizColumn.IMAGE_UPDATED_AT]: false,
    [EDestinationVizColumn.STATUS]: false,
    [EDestinationVizColumn.OWNER]: false,
    [EDestinationVizColumn.TYPE]: false,
  })
  const [collection, setCollection] = useState<ICollection>()
  const [transferSyncModalOpen, setTransferSyncModalOpen] = useState(false)
  const closeTransferSyncModal = () => setTransferSyncModalOpen(false)

  const { addSheetsDataSources } = useAddDataSourcesFromDrive(() => {
    queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
  })
  const addGoogleSheetsDataSource = (syncId: string) => {
    const sync = syncs.find(sync => sync.id === syncId)
    if (!sync) return
    const query = sync.spreadsheetsData?.spreadsheet.name
    addSheetsDataSources(EDataSourceType.GoogleSheets, query)()
  }

  const buildRelativeEditSyncPath = (syncId: string) => {
    return `./${syncId}`
  }

  const isPresentation = useMemo(() => isPresentationDestination(destination), [destination])
  const isGoogle = useMemo(() => isGoogleDestination(destination), [destination])

  const allSlides = useMemo(() => {
    if (!slides) {
      return []
    }
    const defaultSlideLabel = isPresentation ? 'Slide not found' : ''

    const allSlides = [
      ...slides.map((slide, index) => ({ ...slide, label: `Slide ${index + 1}`, notFound: false, index: index + 1 })),
    ]

    const slidesNotFound = (destination.syncs ?? [])
      .filter(sync => sync.slideId && !(slides ?? []).find(s => s.id === sync.slideId))
      .map(sync => ({ id: sync.slideId, index: sync.slideIndex, label: defaultSlideLabel, notFound: isPresentation }))

    const uniqueSlidesNotFound = uniqBy(slidesNotFound, 'id')

    for (const slide of uniqueSlidesNotFound) {
      allSlides.splice(slide.index - 1, 0, slide)
    }

    return allSlides
  }, [slides, destination.syncs, isPresentation])

  const slidesNotFoundIndexes = useMemo(() => {
    return uniq(allSlides.filter(s => s.notFound).map(s => s.index))
  }, [allSlides])

  const slidesNotFoundIds = useMemo(() => {
    return allSlides.filter(s => s.notFound).map(s => s.id)
  }, [allSlides])

  const { syncs: filteredSyncs, searchQuery, setSearchQuery } = useSortAndFilterSyncs(destination.syncs)
  const slideSelect = useSelectable((slides || []).map(s => s.id))

  const syncs: ISync[] = useMemo(() => {
    return isDocumentDestination({ type: destination.type })
      ? destination.syncs ?? []
      : filteredSyncs.filter(
          s =>
            !s.slideId ||
            (slideSelect.selectedCount ? slideSelect.selectedList.includes(s.slideId) : s.slideId === slideId)
        )
  }, [destination.syncs, filteredSyncs, slideSelect.selectedCount, slideSelect.selectedList, slideId, destination.type])

  const syncSelect = useSelectable(syncs!.map(s => s.id))

  const duplicateSlideMutation = useDuplicateSlides(destinationId, {
    onSuccess: () => {
      slideSelect.deselect()
    },
  })

  const { updateSyncs, isUpdating } = useSyncUpdate(destination)
  const { mutateAsync: updateCollectionSyncs } = useCollectionSync()
  const { duplicateSyncs, isDuplicating } = useSyncDuplicate({ destinationId, collectionId })

  const editable = !viewer
  const destinationUpdateRunning = isDestinationUpdateRunning(destination.id)
  const syncsOwnedByUser = (destination.syncs || []).filter(sync => sync.userId === user?.id)
  const syncsGroupedBySlideId = (destination.syncs || []).reduce(
    (acc, sync) => {
      const groupSlideId = sync.slideId
      if (!acc[groupSlideId]) {
        acc[groupSlideId] = []
      }
      acc[groupSlideId].push(sync)
      return acc
    },
    {} as { [key: string]: typeof syncs }
  )

  const handleAddSync = (slideId?: string) => {
    navigate({ pathname: PathSegments.ADD, search: buildSlideIdSearchParam(slideId) }, { relative: 'path' })
  }

  const handleModifySync = (syncId: string, type?: string, slideId?: string) => {
    if (type === EDataSourceType.GoogleSheets) {
      setCurrentSync(syncs.find(sync => sync.id === syncId))
      toggleShowVisualizationSelectSheets()
    } else {
      navigate(
        { pathname: buildRelativeEditSyncPath(syncId), search: buildSlideIdSearchParam(slideId) },
        { relative: 'path' }
      )
    }
  }

  const openSyncCrop = (syncId: string) => {
    setCurrentSync(syncs.find(sync => sync.id === syncId))
    toggleShowSyncCrop()
  }

  const openSyncLocation = (syncId: string) => {
    setCurrentSync(syncs.find(sync => sync.id === syncId))
    toggleShowSyncLocation()
  }

  const handleTransferSync = (syncId: string) => {
    setCurrentSync(syncs.find(sync => sync.id === syncId))
    setTransferSyncModalOpen(true)
  }

  const duplicateSelectedSyncs = async () => {
    await duplicateSyncs(syncSelect.selectedList)
    handleSyncDeselect()
  }

  const handleSlideClick = (newSlideId: string, notFound: boolean) => {
    if (slideId === newSlideId) {
      return
    }

    if (slideSelect.anySelected) {
      if (notFound) return
      slideSelect.handleSelectClick(newSlideId)
    }

    handleSyncDeselect()

    navigate({ search: buildSlideIdSearchParam(newSlideId) }, { relative: 'path', replace: true })
  }

  const handleGroupSelect = (slideIds: string | string[]) => {
    slideSelect.handleSelectClick(slideIds)
    if (!Array.isArray(slideIds))
      navigate({ search: buildSlideIdSearchParam(slideId) }, { relative: 'path', replace: true })
  }

  const duplicateSelectedSlides = () => {
    duplicateSlideMutation.mutate({ slideIds: slideSelect.selectedList })
  }

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = updaterOrValue => {
    let value = updaterOrValue
    if (updaterOrValue instanceof Function) {
      value = updaterOrValue(columnVisibility)
    }
    setColumnVisibility(value)
    localStorage.setItem(COLUMN_VISIBILITY_LOCAL_STORAGE_KEY, JSON.stringify(value))
  }

  useEffect(() => {
    async function getInitialColumnVisibility() {
      const item = localStorage.getItem(COLUMN_VISIBILITY_LOCAL_STORAGE_KEY)
      if (item) {
        const settings = JSON.parse(item)
        setColumnVisibility(settings)
      }
    }
    getInitialColumnVisibility()
  }, [])

  useEffect(() => {
    if (slideId) {
      navigate({ search: buildSlideIdSearchParam(slideId) }, { relative: 'path', replace: true })
      return
    }

    if (slides && slides.length) {
      navigate({ search: buildSlideIdSearchParam(slides[0].id) }, { relative: 'path', replace: true })
      return
    }
  }, [slideId, slides, navigate])

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const handleUpdateSync = (syncId: string) => updateSyncs([syncId])
  const handleDuplicateSync = (syncId: string) => duplicateSyncs(syncId)
  const handleDeleteSync = (syncId: string) =>
    deleteSyncsWithConfirmation({ syncIds: [syncId], destination, collectionId })

  const deleteMultipleSyncs = () => {
    deleteSyncsWithConfirmation({
      syncIds: syncSelect.selectedList,
      destination,
      collectionId,
      callback: handleSyncDeselect,
    })
  }

  useEffect(() => {
    syncSelect.setSelected(rowSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection])

  const handleSyncDeselect = () => {
    syncSelect.deselect()
    setRowSelection({})
  }

  const handleDuplicateVisualizations = () => {
    if (
      destination.variantInCollection?.type === CollectionVariantType.template &&
      user?.showTemplateModificationModal
    ) {
      setTemplateModificationModalFrom('DUPLICATE_VIS')
      setTemplateModificationModal(true)
    } else {
      duplicateSelectedSyncs()
    }
  }

  const handleDeleteVisualizations = () => {
    if (
      destination.variantInCollection?.type === CollectionVariantType.template &&
      user?.showTemplateModificationModal
    ) {
      setTemplateModificationModalFrom('DELETE_VIS')
      setTemplateModificationModal(true)
    } else {
      deleteMultipleSyncs()
    }
  }

  const combinedSelectedSyncs = useMemo(() => {
    if (syncSelect.selectedList.length) {
      return syncSelect.selectedList
    } else if (slideSelect.selectedList.length) {
      return syncs.map(s => s.id)
    }
    return []
  }, [syncSelect.selectedList, slideSelect.selectedList, syncs])

  const triggerSyncsUpdate = async (variantsToUpdate: string[] = []) => {
    if (collection) {
      await updateCollectionSyncs({
        collection,
        selectedTemplateSyncIds: combinedSelectedSyncs,
        selectedVariantIds: variantsToUpdate,
      })
    } else {
      await updateSyncs(combinedSelectedSyncs)
    }
    slideSelect.deselect()
    handleSyncDeselect()
  }

  return {
    syncs,
    allSlides,
    slideThumbnails,
    slidesNotFoundIds,
    slidesNotFoundIndexes,
    editor,
    owner,
    viewer,
    syncsGroupedBySlideId,
    isGoogle,
    collectionId,
    searchQuery,
    editable,
    isUpdating,
    destinationUpdateRunning,
    syncsOwnedByUser,
    slideSelect,
    syncSelect,
    isDuplicating,
    isDuplicatingSlides: duplicateSlideMutation.isLoading,
    visualizationExplainerModal,
    visualizationModalFrom,
    refreshSlidesExplainerModal,
    templateModificationModal,
    templateModificationModalFrom,
    refreshDestinationMutation,
    currentSync,
    showSyncCrop,
    showSyncLocation,
    showVisualizationSelectSheets,
    rowSelection,
    columnVisibility,
    openUpdateVariantsModal,
    transferSyncModalOpen,
    closeTransferSyncModal,
    setOpenUpdateVariantsModal,
    toggleShowSyncLocation,
    toggleShowSyncCrop,
    toggleShowVisualizationSelectSheets,
    duplicateSelectedSlides,
    duplicateSelectedSyncs,
    handleUpdateSync,
    setSearchQuery,
    handleModifySync,
    openSyncCrop,
    openSyncLocation,
    handleSlideClick,
    handleGroupSelect,
    handleAddSync,
    setVisualizationExplainerModal,
    toggleRefreshSlidesExplainerModal,
    setTemplateModificationModal,
    setVisualizationModalFrom,
    handleDuplicateVisualizations,
    handleDeleteVisualizations,
    setRowSelection,
    handleSyncDeselect,
    handleDuplicateSync,
    handleDeleteSync,
    handleTransferSync,
    deleteMultipleSyncs,
    onColumnVisibilityChange,
    triggerSyncsUpdate,
    setCollection,
    addGoogleSheetsDataSource,
  }
}
