import { EDestinationType } from '@features/destinations/types'

import { ColorType } from './types'

export const isValidHexColor = (hexColor: string) => /^#([0-9A-F]{6}|[0-9A-F]{3})$/i.test(hexColor)

export const mapColorTypeToReadable = (type: ColorType, presentationType: EDestinationType): string => {
  if (presentationType === EDestinationType.GoogleSlides) {
    switch (type) {
      case 'dark1':
        return 'Text and background 1'
      case 'light1':
        return 'Text and background 2'
      case 'dark2':
        return 'Text and background 3'
      case 'light2':
        return 'Text and background 4'
      case 'accent1':
        return 'Accent 1'
      case 'accent2':
        return 'Accent 2'
      case 'accent3':
        return 'Accent 3'
      case 'accent4':
        return 'Accent 4'
      case 'accent5':
        return 'Accent 5'
      case 'accent6':
        return 'Accent 6'
      case 'link':
        return 'Link'
    }
  }

  if (presentationType === EDestinationType.MicrosoftPowerPoint) {
    switch (type) {
      case 'dark1':
        return 'Text 1'
      case 'light1':
        return 'Background 1'
      case 'dark2':
        return 'Text 2'
      case 'light2':
        return 'Background 2'
      case 'accent1':
        return 'Accent 1'
      case 'accent2':
        return 'Accent 2'
      case 'accent3':
        return 'Accent 3'
      case 'accent4':
        return 'Accent 4'
      case 'accent5':
        return 'Accent 5'
      case 'accent6':
        return 'Accent 6'
      case 'link':
        return 'Link'
    }
  }

  return ''
}

export const getColorTypeOrder = (destinationType: EDestinationType) => {
  if (destinationType === EDestinationType.GoogleSlides) {
    return [
      'dark1',
      'light1',
      'dark2',
      'light2',
      'accent1',
      'accent2',
      'accent3',
      'accent4',
      'accent5',
      'accent6',
      'link',
    ] satisfies ColorType[]
  }

  if (destinationType === EDestinationType.MicrosoftPowerPoint) {
    return [
      'light1',
      'dark1',
      'light2',
      'dark2',
      'accent1',
      'accent2',
      'accent3',
      'accent4',
      'accent5',
      'accent6',
      'link',
    ] satisfies ColorType[]
  }

  return []
}
