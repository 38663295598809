import { User } from '@sentry/react'

import { isPresentationDestination } from '@features/destinations'
import { EDestinationType } from '@features/destinations/types'
import { CustomEmailTemplateVariable } from '@features/emails/types'

export const getUpdateCollectionTemplateVariables = (
  type: EDestinationType,
  user?: User,
  variantName?: string
): CustomEmailTemplateVariable[] => [
  {
    id: 'open_button',
    description: `(Only for body) A button redirecting to your ${isPresentationDestination({ type }) ? 'presentation' : 'document'}`,
  },
  {
    id: 'variant_name',
    description: 'Name of the variant',
    defaultValue: variantName,
  },
]
