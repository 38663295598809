import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 20

export const ActivityShareIcon: FC<Pick<IIcon, 'size'>> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 18" fill="none">
      <path
        d="M15.8333 16.5V11.5M13.3333 14H18.3333M9.99996 11.5H6.66663C5.11349 11.5 4.33692 11.5 3.72435 11.7537C2.90759 12.092 2.25867 12.741 1.92036 13.5577C1.66663 14.1703 1.66663 14.9469 1.66663 16.5M12.9166 1.7423C14.1382 2.23679 15 3.43443 15 4.83333C15 6.23224 14.1382 7.42988 12.9166 7.92437M11.25 4.83333C11.25 6.67428 9.75758 8.16667 7.91663 8.16667C6.07568 8.16667 4.58329 6.67428 4.58329 4.83333C4.58329 2.99238 6.07568 1.5 7.91663 1.5C9.75758 1.5 11.25 2.99238 11.25 4.83333Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
