import { FC, useEffect, useState } from 'react'

import { EDestinationType } from '@features/destinations/types'
import { ColorOrb } from '@features/theme/components/color-orb'
import { ColorType, DestinationThemeColors } from '@features/theme/types'
import { getColorTypeOrder, isValidHexColor, mapColorTypeToReadable } from '@features/theme/utils'

import { Input, Tooltip } from '@shared/components'

interface ThemePickerProps {
  themeColors: DestinationThemeColors
  destinationType: EDestinationType
  onThemeColorChange: (theme: DestinationThemeColors) => void
}

export const ThemePicker: FC<ThemePickerProps> = ({ destinationType, themeColors, onThemeColorChange }) => {
  const [selectedColorType, setSelectedColorType] = useState<ColorType>('dark1')
  const [currentHexColor, setCurrentHexColor] = useState(themeColors[selectedColorType])

  const handleColorChange = (type: string, newValue: string) => {
    setCurrentHexColor(newValue)

    if (!isValidHexColor(newValue)) return

    const newTheme = { ...themeColors, [type]: newValue }
    onThemeColorChange(newTheme)
  }

  const handleChangeColorType = (type: ColorType) => {
    setSelectedColorType(type)
    setCurrentHexColor(themeColors[type])
  }

  useEffect(() => {
    if (themeColors) {
      setCurrentHexColor(themeColors[selectedColorType])
    }
  }, [themeColors, selectedColorType])

  return (
    <div className="flex flex-col gap-10 justify-center items-center">
      <div className="flex gap-2 w-auto">
        {getColorTypeOrder(destinationType).map(colorType => {
          return (
            <Tooltip key={colorType} title={mapColorTypeToReadable(colorType, destinationType)}>
              <ColorOrb color={themeColors[colorType]} onClick={() => handleChangeColorType(colorType)} />
            </Tooltip>
          )
        })}
      </div>

      <div className="flex flex-row justify-between gap-4 items-center w-1/1">
        <Tooltip title={themeColors[selectedColorType]}>
          <ColorOrb color={themeColors[selectedColorType]} />
        </Tooltip>
        <span className="text-sm-medium">{mapColorTypeToReadable(selectedColorType, destinationType)}</span>
        <Input
          key={selectedColorType}
          placeholder="#000000"
          value={currentHexColor}
          onChange={e => handleColorChange(selectedColorType, e.target.value)}
          errorMessage={!isValidHexColor(currentHexColor) && 'Invalid color code'}
        />
      </div>
    </div>
  )
}
