import classNames from 'classnames'
import { FC } from 'react'

import { ITableauObject } from '@features/embedding/tableau/types'

import { useHover } from '@shared/hooks/use-hover'

import overlayStyles from './overlay.module.css'

interface ISyncWorksheetOverlay {
  object: ITableauObject
  selectedSheets: ITableauObject[]
  handleSelectSheet: (sheetName: ITableauObject) => void
  handleUnselectSheet: (sheetName: ITableauObject) => void
  isPublicDashboard: boolean
}

export const SyncWorksheetOverlay: FC<ISyncWorksheetOverlay> = ({
  object,
  selectedSheets,
  handleSelectSheet,
  handleUnselectSheet,
}) => {
  const { hoverRef: outerDivHover, isHover: isOuterDivHover } = useHover()
  const isSelectedSheet = selectedSheets.includes(object)
  const { id, size, position } = object

  const overlayAreaClassName = classNames(
    overlayStyles.overlayArea,
    { [overlayStyles.overlayAreaHovered]: isOuterDivHover },
    { [overlayStyles.overlayAreaSelected]: isSelectedSheet }
  )
  const overlayBadgeClassName = classNames(
    overlayStyles.overlayBadge,
    { [overlayStyles.overlayBadgeHovered]: isOuterDivHover },
    { [overlayStyles.overlayBadgeSelected]: isSelectedSheet }
  )

  const handleClick = () => {
    isSelectedSheet ? handleUnselectSheet(object) : handleSelectSheet(object)
  }

  return (
    <div
      key={id}
      className={overlayAreaClassName}
      ref={outerDivHover}
      style={{
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
      }}
      onClick={handleClick}
      data-testid={object.name}
    >
      <div className={overlayBadgeClassName}>
        <div className={overlayStyles.badgeContent}>Selected</div>
      </div>
    </div>
  )
}
