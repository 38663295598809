import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 30
export const OnePassword: FC<IIcon> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width="96" height="19" viewBox="0 0 96 19" fill="none">
      <g clipPath="url(#clip0_16270_176253)">
        <mask
          id="mask0_16270_176253"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="96"
          height="19"
        >
          <path d="M95.4772 0.554688H0.523438V18.7507H95.4772V0.554688Z" fill="white" />
        </mask>
        <g mask="url(#mask0_16270_176253)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7773 9.63136C18.7773 4.61851 14.711 0.554688 9.69527 0.554688C4.67949 0.554688 0.613281 4.61851 0.613281 9.63136C0.613281 14.6444 4.67949 18.708 9.69527 18.708C14.711 18.708 18.7773 14.6444 18.7773 9.63136ZM8.04223 4.58876C7.97083 4.72881 7.97083 4.91215 7.97083 5.27882V7.35698C7.97083 7.44857 7.97083 7.49437 7.98241 7.53671C7.99266 7.57422 8.00951 7.60961 8.03218 7.64121C8.05776 7.67688 8.09333 7.70575 8.16446 7.76351L8.69045 8.19054C8.77619 8.26015 8.81906 8.29495 8.83462 8.33685C8.84826 8.3736 8.84826 8.41403 8.83462 8.45077C8.81906 8.49267 8.77619 8.52747 8.69045 8.59707L8.16447 9.02412C8.09334 9.08186 8.05776 9.11073 8.03218 9.1464C8.00951 9.17801 7.99266 9.21339 7.98241 9.25092C7.97083 9.29326 7.97083 9.33905 7.97083 9.43065V13.9839C7.97083 14.3505 7.97083 14.5338 8.04223 14.6738C8.10504 14.7971 8.20526 14.8972 8.32852 14.96C8.46866 15.0314 8.6521 15.0314 9.01898 15.0314H10.3715C10.7384 15.0314 10.9219 15.0314 11.062 14.96C11.1853 14.8972 11.2854 14.7971 11.3483 14.6738C11.4197 14.5338 11.4197 14.3505 11.4197 13.9839V11.9056C11.4197 11.814 11.4197 11.7682 11.4081 11.726C11.3979 11.6884 11.381 11.653 11.3583 11.6214C11.3327 11.5857 11.2972 11.5569 11.2261 11.4992L10.7001 11.0721C10.6143 11.0025 10.5715 10.9677 10.5559 10.9258C10.5423 10.889 10.5423 10.8486 10.5559 10.8119C10.5715 10.77 10.6143 10.7352 10.7001 10.6656L11.2261 10.2385C11.2972 10.1808 11.3327 10.1519 11.3583 10.1162C11.381 10.0846 11.3979 10.0492 11.4081 10.0117C11.4197 9.96939 11.4197 9.92359 11.4197 9.832V5.27882C11.4197 4.91215 11.4197 4.72881 11.3483 4.58876C11.2854 4.46557 11.1853 4.36541 11.062 4.30264C10.9219 4.23128 10.7384 4.23128 10.3715 4.23128H9.01898C8.6521 4.23128 8.46866 4.23128 8.32852 4.30264C8.20526 4.36541 8.10504 4.46557 8.04223 4.58876Z"
            fill="#1A285F"
          />
          <path
            d="M26.0674 15.0618C26.1575 15.0618 26.2303 14.9889 26.2303 14.899V4.23704C26.2303 4.14712 26.1575 4.07422 26.0674 4.07422H24.4952C24.437 4.07422 24.3837 4.10573 24.3517 4.15438C23.9912 4.70219 23.3209 4.97811 22.4023 5.00684C22.3124 5.00964 22.2393 5.08224 22.2393 5.17217V6.76685C22.2393 6.85677 22.3121 6.92967 22.4022 6.92967H23.6728C23.7627 6.92967 23.8357 7.00257 23.8357 7.0925V14.899C23.8357 14.9889 23.9086 15.0618 23.9986 15.0618H26.0674Z"
            fill="#1A285F"
          />
          <path
            d="M32.3441 6.27765C33.1922 6.27765 33.874 6.72851 33.874 7.86401C33.874 8.96612 33.1922 9.45037 32.3441 9.45037H30.4981V6.27765H32.3441ZM32.3441 11.5043C34.8385 11.5043 36.2853 9.98473 36.2853 7.79722C36.2853 5.7266 34.8385 4.20703 32.3441 4.20703H28.283C28.193 4.20703 28.1201 4.27993 28.1201 4.36985V14.8982C28.1201 14.9882 28.193 15.0611 28.283 15.0611H30.3352C30.4252 15.0611 30.4981 14.9882 30.4981 14.8982V11.6671C30.4981 11.5771 30.5711 11.5043 30.6611 11.5043H32.3441Z"
            fill="#1A285F"
          />
          <path
            d="M39.9746 13.5247C39.2761 13.5247 38.8438 13.2074 38.8438 12.6396C38.8438 12.1386 39.1431 11.8047 39.8582 11.8047H41.8039V11.9716C41.8039 12.9402 40.9724 13.5247 39.9746 13.5247ZM41.9868 14.898C41.9868 14.988 42.0598 15.0609 42.1497 15.0609H43.9359C44.0259 15.0609 44.0988 14.988 44.0988 14.898V10.1849C44.0988 7.91391 42.8183 6.59473 40.5067 6.59473C38.7905 6.59473 37.4765 7.51126 36.9377 8.98473C36.9054 9.07322 36.959 9.16864 37.0507 9.19108L38.8502 9.63216C38.9315 9.65209 39.0141 9.60698 39.0458 9.52953C39.3191 8.86108 39.7741 8.46497 40.5067 8.46497C41.2717 8.46497 41.8039 8.96593 41.8039 9.86764V9.98453C41.8039 10.235 41.7208 10.3352 41.4214 10.3352H39.3426C37.6132 10.3352 36.5654 11.2536 36.5654 12.7064C36.5654 14.2594 37.7628 15.2613 39.4258 15.2613C40.2626 15.2613 41.0665 14.9863 41.6331 14.3739C41.7445 14.2535 41.9868 14.327 41.9868 14.4909V14.898Z"
            fill="#1A285F"
          />
          <path
            d="M48.5225 13.5914C47.7145 13.5914 47.1241 13.256 46.8331 12.6396C46.7979 12.5649 46.7159 12.5214 46.6356 12.5417L44.9535 12.9675C44.867 12.9893 44.8138 13.077 44.8395 13.1624C45.2653 14.5767 46.6897 15.2613 48.4394 15.2613C50.6845 15.2613 51.9649 14.1091 51.9649 12.5395C51.9649 9.50028 47.1589 10.5356 47.1589 9.03272C47.1589 8.53176 47.5747 8.21448 48.323 8.21448C49.0678 8.21448 49.5665 8.62153 49.8055 9.24571C49.8351 9.32314 49.9146 9.37172 49.9961 9.35629L51.7129 9.0313C51.8046 9.01392 51.8634 8.92286 51.8369 8.83332C51.4431 7.50371 50.3364 6.59473 48.3396 6.59473C46.1445 6.59473 44.9804 7.76363 44.9804 9.2331C44.9804 12.3725 49.803 11.4373 49.803 12.7398C49.803 13.2575 49.3707 13.5914 48.5225 13.5914Z"
            fill="#1A285F"
          />
          <path
            d="M55.9932 13.5914C55.1851 13.5914 54.5949 13.256 54.3038 12.6396C54.2685 12.5649 54.1865 12.5214 54.1063 12.5417L52.4242 12.9675C52.3377 12.9893 52.2845 13.077 52.3102 13.1624C52.736 14.5767 54.1603 15.2613 55.9101 15.2613C58.1551 15.2613 59.4356 14.1091 59.4356 12.5395C59.4356 9.50028 54.6296 10.5356 54.6296 9.03272C54.6296 8.53176 55.0454 8.21448 55.7937 8.21448C56.5385 8.21448 57.0371 8.62153 57.2761 9.24571C57.3058 9.32314 57.3852 9.37172 57.4668 9.35629L59.1835 9.0313C59.2753 9.01392 59.3341 8.92286 59.3075 8.83332C58.9138 7.50371 57.807 6.59473 55.8103 6.59473C53.6152 6.59473 52.4511 7.76363 52.4511 9.2331C52.4511 12.3725 57.2737 11.4373 57.2737 12.7398C57.2737 13.2575 56.8413 13.5914 55.9932 13.5914Z"
            fill="#1A285F"
          />
          <path
            d="M61.5392 14.9363C61.557 15.0093 61.6224 15.0607 61.6976 15.0607H64.2841C64.3597 15.0607 64.4254 15.0087 64.4427 14.9352L65.7602 9.3331L67.0777 14.9352C67.095 15.0087 67.1606 15.0607 67.2364 15.0607H69.9063C69.9813 15.0607 70.0467 15.0095 70.0646 14.9366L72.0134 6.99654C72.0386 6.89394 71.9609 6.79492 71.8552 6.79492H70.0171C69.9398 6.79492 69.8732 6.84915 69.8575 6.92477L68.554 13.2238L66.939 6.91738C66.9205 6.84532 66.8556 6.79492 66.7812 6.79492H64.7249C64.6494 6.79492 64.5838 6.84671 64.5663 6.92007L63.0661 13.2238L61.779 6.92517C61.7635 6.84936 61.6968 6.79492 61.6194 6.79492H59.7646C59.659 6.79492 59.5813 6.89372 59.6063 6.99624L61.5392 14.9363Z"
            fill="#1A285F"
          />
          <path
            d="M76.4066 15.2613C78.901 15.2613 80.6139 13.3243 80.6139 10.9364C80.6139 8.53176 78.901 6.59473 76.4066 6.59473C73.9287 6.59473 72.1992 8.53176 72.1992 10.9364C72.1992 13.3243 73.9287 15.2613 76.4066 15.2613ZM76.4066 13.2742C75.3755 13.2742 74.5772 12.4058 74.5772 10.9364C74.5772 9.46688 75.3755 8.58185 76.4066 8.58185C77.4376 8.58185 78.2524 9.46688 78.2524 10.9364C78.2524 12.4058 77.4376 13.2742 76.4066 13.2742Z"
            fill="#1A285F"
          />
          <path
            d="M83.8946 10.6859C83.8946 9.26649 84.6096 8.93252 85.7071 8.93252C85.9175 8.93252 86.1488 8.95341 86.3678 8.99517C86.4607 9.01287 86.5527 8.95361 86.5692 8.86058L86.9173 6.89409C86.9295 6.82502 86.8965 6.75499 86.8319 6.72749C86.6319 6.64233 86.3845 6.59473 86.0897 6.59473C84.9754 6.59473 84.1107 7.24598 83.695 8.56515V6.95794C83.695 6.86801 83.622 6.79511 83.5321 6.79511H81.7292C81.6393 6.79511 81.5664 6.86801 81.5664 6.95794V14.898C81.5664 14.988 81.6393 15.0609 81.7292 15.0609H83.7316C83.8216 15.0609 83.8946 14.988 83.8946 14.898V10.6859Z"
            fill="#1A285F"
          />
          <path
            d="M91.22 13.3246C90.0226 13.3246 89.3907 12.3393 89.3907 10.9367C89.3907 9.534 90.0226 8.53209 91.22 8.53209C92.4174 8.53209 93.0493 9.534 93.0493 10.9367C93.0493 12.3393 92.4174 13.3246 91.22 13.3246ZM93.1824 14.8984C93.1824 14.9883 93.2553 15.0612 93.3453 15.0612H95.2146C95.3045 15.0612 95.3775 14.9883 95.3775 14.8984V4.39427C95.3775 4.30434 95.3045 4.23145 95.2146 4.23145H93.2788C93.1887 4.23145 93.1159 4.30434 93.1159 4.39427V7.94763C92.6003 6.97912 91.7356 6.59505 90.638 6.59505C88.4927 6.59505 87.0293 8.4653 87.0293 10.9367C87.0293 13.4081 88.4927 15.2617 90.638 15.2617C91.8021 15.2617 92.7001 14.794 93.1824 13.5917V14.8984Z"
            fill="#1A285F"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_16270_176253">
          <rect width="94.9538" height="18.196" fill="white" transform="translate(0.523438 0.554688)" />
        </clipPath>
      </defs>
    </svg>
  )
}
