import { FC, PropsWithChildren } from 'react'

import { Tooltip } from '@shared/components/tooltip/tooltip'

interface IViewerTooltip extends PropsWithChildren {
  show: boolean
  position?: 'top' | 'bottom' | 'left' | 'right'
}

export const ViewerDestinationTooltip: FC<IViewerTooltip> = ({ show, children, position = 'top' }) => (
  <Tooltip show={show} position={position} title="You have viewer permissions." fullWidth>
    {children}
  </Tooltip>
)
