import { DirectorySyncProviders } from '@boxyhq/saml-jackson/dist/directory-sync/types'
import classNames from 'classnames'
import { Crisp } from 'crisp-sdk-web'
import { useEffect, useMemo, useState } from 'react'

import { useOrganizationsStore } from '@features/organizations'
import { useGetDirectorySync } from '@features/users'

import { Button, CopyButton, Input, Spinner } from '@shared/components'
import { config } from '@shared/config'

import { DirectorySyncTutorial } from './tutorials/directory-sync-tutorial'

import styles from './directory-sync.module.css'

const COPY_TRANSITION_TIME = 1000

export const DirectorySync = () => {
  const { activeOrganization } = useOrganizationsStore()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const toggleDocumentation = () => setIsModalOpen(prev => !prev)

  const organizationId = activeOrganization?.organizationId

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false)
    }, COPY_TRANSITION_TIME)

    return () => clearTimeout(timeout)
  }, [copied])
  const { data: directorySync, isLoading } = useGetDirectorySync(organizationId)

  const scimEndpoint = useMemo(() => {
    return `${config.jackson.apiUrl}${directorySync?.scim.path}`
  }, [directorySync])

  const scimSecret = useMemo(() => {
    return directorySync?.scim.secret
  }, [directorySync])

  const titleClass = classNames('text-sm-regular', styles.title)

  if (isLoading) return <Spinner />
  return (
    <div className={styles.container}>
      {directorySync ? (
        <>
          <div className={styles.directoryData}>
            <div className={titleClass}>Directory Provider</div>
            <section className={styles.directoryValue}>
              <Input className={styles.input} disabled value={DirectorySyncProviders[directorySync?.type]} />
            </section>
          </div>
          <div className={styles.directoryData}>
            <div className={titleClass}>SCIM Endpoint</div>
            <section className={styles.directoryValue}>
              <Input className={styles.input} disabled value={scimEndpoint} />
              <CopyButton value={scimEndpoint} />
            </section>
          </div>
          <div className={styles.directoryData}>
            <div className={titleClass}>SCIM Token</div>
            <section className={styles.directoryValue}>
              <Input secret className={styles.input} disabled value={scimSecret} />
              <CopyButton value={scimSecret} />
            </section>
          </div>
          <Button text="Start configuration" secondaryGray onClick={toggleDocumentation} />
          <DirectorySyncTutorial type={directorySync?.type} open={isModalOpen} toggle={toggleDocumentation} />
        </>
      ) : (
        <div className={styles.body}>
          <span>{'Directory Sync is not enabled yet. Please reach out to us to help you set up your connection'}</span>
          <div className={styles.linkContainer}>
            <span className={styles.link} onClick={() => Crisp.chat.open()}>
              {'contact us here'}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
