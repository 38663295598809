import { useContext } from 'react'

import { isEmailDestination } from '@features/destinations'
import { EDestinationType } from '@features/destinations/types'
import { SaveTemplateButton } from '@features/emails/components/save-template.button'
import { EmailTemplateEditor } from '@features/emails/components/templates/email-template-editor'
import { EmailTemplateModal } from '@features/emails/components/templates/email-template-modal'
import { useSelectEmailTemplateSettings } from '@features/emails/components/templates/hooks/use-select-email-template-settings'
import {
  CustomEmailTemplateVariable,
  EEmailTemplateType,
  ESelectEmailTemplateEditorMode,
  SelectTemplateOption,
} from '@features/emails/types'
import { ISync } from '@features/syncs/types'

import { Button, Label, SelectInput, Spinner } from '@shared/components'
import { cn } from '@shared/utils'

import { SaveChangesModal } from './save-changes-modal'
import {
  EmailTemplateEditorContext,
  EmailTemplateEditorProvider,
} from './templates/providers/email-template-editor-provider'
import {
  EmailTemplateSelectContext,
  EmailTemplateSelectProvider,
} from './templates/providers/email-template-select-provider'

type Props = {
  targetId: string
  onlyTemplatesForTarget?: boolean
  onSelectTemplate: (templateId?: string | null) => void
  onSaveTemplate?: (templateId: string | null) => void
  initialTemplateId?: string | null
  destinationType: EDestinationType
  templateType: EEmailTemplateType
  variables?: CustomEmailTemplateVariable[]
  selectClassName?: string
  containerClassName?: string
  editorMode: ESelectEmailTemplateEditorMode
  syncs?: ISync[]
}

export const SelectEmailTemplate = (props: Props) => {
  return (
    <EmailTemplateSelectProvider
      destinationType={props.destinationType}
      targetId={props.targetId}
      templateType={props.templateType}
      initialTemplateId={props.initialTemplateId}
      onlyTemplatesForTarget={props.onlyTemplatesForTarget}
    >
      <EmailTemplateEditorProvider
        templateType={props.templateType}
        targetId={props.targetId}
        destinationType={props.destinationType}
        syncs={props.syncs}
        initialTemplateId={props.initialTemplateId}
      >
        <SelectEmailTemplateInner {...props} />
      </EmailTemplateEditorProvider>
    </EmailTemplateSelectProvider>
  )
}

const SelectEmailTemplateInner = ({
  targetId,
  onSelectTemplate,
  templateType,
  destinationType,
  variables = [],
  selectClassName,
  containerClassName,
  editorMode,
  syncs,
  onSaveTemplate,
}: Props) => {
  const { setTemplate: setTemplateInEditor } = useContext(EmailTemplateEditorContext)
  const {
    template: templateInSelect,
    emailTemplates,
    areTemplatesLoading,
    openSaveChangesModal,
    toggleOpenSaveChangesModal,
  } = useContext(EmailTemplateSelectContext)

  const {
    upsertTemplate,
    selectValue,
    onSelectChange,
    openEmailTemplateModal,
    toggleOpenEmailTemplateModal,
    emailTemplateOptions,
    currentTemplateOption,
    isEditorModalMode,
    isEditorInlineMode,
    hideActionButtons,
    createEmptyTemplate,
  } = useSelectEmailTemplateSettings({
    onSelectTemplate,
    destinationType,
    templateType,
    editorMode,
    targetId,
    syncs,
    emailTemplates,
    templateInSelect,
    setTemplateInEditor,
  })

  return (
    <div className="flex flex-col gap-7">
      <SaveChangesModal
        open={openSaveChangesModal}
        closeModal={toggleOpenSaveChangesModal}
        targetId={targetId}
        upsertTemplate={upsertTemplate}
      />
      <EmailTemplateModal
        open={openEmailTemplateModal}
        closeModal={() => toggleOpenEmailTemplateModal(false)}
        variables={variables}
      />
      {areTemplatesLoading ? (
        <div className="flex items-center justify-center">
          <Spinner center={false} />
        </div>
      ) : (
        <div>
          {isEditorInlineMode ? (
            <InlineEditorSection
              upsertTemplate={upsertTemplate}
              variables={variables}
              onSaveTemplate={onSaveTemplate}
            />
          ) : (
            <div className="flex gap-2">
              <SelectInput<SelectTemplateOption>
                className={cn('min-w-52', selectClassName)}
                containerClassName={containerClassName}
                defaultValue={currentTemplateOption}
                value={selectValue}
                options={emailTemplateOptions}
                placeholder="Select a template"
                onChange={option => onSelectChange(option)}
              />
              {hideActionButtons ? null : (
                <ButtonsSection
                  toggleOpenEmailTemplateModal={toggleOpenEmailTemplateModal}
                  createEmptyTemplate={createEmptyTemplate}
                  isEditorModalMode={isEditorModalMode}
                  isEditorInlineMode={isEditorInlineMode}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const InlineEditorSection = ({
  variables,
  onSaveTemplate,
  upsertTemplate,
}: {
  variables?: CustomEmailTemplateVariable[]
  onSaveTemplate?: (templateId: string | null) => void
  upsertTemplate: () => Promise<string>
}) => {
  const { template, destinationType } = useContext(EmailTemplateEditorContext)
  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <Label sm medium>
          Template configuration
        </Label>
        <div className="flex gap-4 items-center">
          {template.dirty && (
            <Label sm bold className="text-primary-700">
              You have unsaved changes
            </Label>
          )}
          <SaveTemplateButton upsertTemplate={upsertTemplate} onSaveTemplate={onSaveTemplate} />
        </div>
      </div>
      <EmailTemplateEditor variables={variables} showNameField={!isEmailDestination({ type: destinationType })} />
    </div>
  )
}

const ButtonsSection = ({
  toggleOpenEmailTemplateModal,
  createEmptyTemplate,
  isEditorModalMode,
  isEditorInlineMode,
}: {
  toggleOpenEmailTemplateModal: (value: boolean) => void
  createEmptyTemplate: () => void
  isEditorModalMode: boolean
  isEditorInlineMode: boolean
}) => {
  const { template: templateInEditor, setTemplate: setTemplateInEditor } = useContext(EmailTemplateEditorContext)
  const { template: templateInSelect } = useContext(EmailTemplateSelectContext)

  return (
    <>
      {(isEditorModalMode || (templateInSelect && templateInSelect?.id !== templateInEditor.id)) && (
        <Button
          secondaryColor={isEditorModalMode}
          tertiaryColor={isEditorInlineMode}
          text="Modify Template"
          onClick={() => {
            setTemplateInEditor(templateInSelect)
            if (isEditorModalMode) {
              toggleOpenEmailTemplateModal(true)
            }
          }}
          disabled={!templateInSelect}
        />
      )}

      <Button
        text="Add new"
        tertiaryColor={isEditorInlineMode}
        onClick={() => {
          setTemplateInEditor(undefined)
          if (isEditorModalMode) {
            toggleOpenEmailTemplateModal(true)
          } else if (isEditorInlineMode) {
            createEmptyTemplate()
          }
        }}
      />
    </>
  )
}
