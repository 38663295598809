import { FC, useEffect, useState } from 'react'
import Select from 'react-select'

import { EDateRangeType, ETableauPeriodType, IRange, ITableauFilterObject } from '@features/embedding/tableau/types'

import { Switch } from '@shared/components/switch/switch'

import styles from './popup.module.css'

interface ISyncFilterPopup {
  showPopup?: boolean
  filterObject: ITableauFilterObject & IRange
  updateDateRangeFilter: (filter: ITableauFilterObject) => void
}

interface IPeriodTypeOption {
  label: string
  value: ETableauPeriodType
}

interface IDateRangeTypeOption {
  label: string
  value: EDateRangeType
}

const periodTypeOptions: IPeriodTypeOption[] = Object.values(ETableauPeriodType).map(freq => ({
  label: freq,
  value: freq,
}))

const dateRangeTypeOptions: IDateRangeTypeOption[] = Object.values(EDateRangeType).map(freq => ({
  label: freq,
  value: freq,
}))

export const SyncFilterPopup: FC<ISyncFilterPopup> = ({ showPopup = false, filterObject, updateDateRangeFilter }) => {
  const [datePeriodType, setDatePeriodType] = useState<ETableauPeriodType | null>(filterObject.datePeriodType || null)
  const [dateRangeType, setDateRangeType] = useState<EDateRangeType | null>(filterObject.dateRangeType || null)
  const [dateRangeN, setDateRangeN] = useState<number | null>(filterObject.dateRangeN || null)
  const [dynamic, setDynamic] = useState<boolean>(false)

  const getDateRangeOptions = (periodType: ETableauPeriodType) => {
    let dateRangeLimits = { min: 1, max: 4 }
    switch (periodType) {
      case ETableauPeriodType.days:
        dateRangeLimits = { min: 1, max: 30 }
        break
      case ETableauPeriodType.months:
        dateRangeLimits = { min: 1, max: 12 }
        break
    }
    const dateRangeList = Array.from(
      { length: dateRangeLimits.max - dateRangeLimits.min + 1 },
      (_, i) => i + dateRangeLimits.min
    )
    return dateRangeList.map(n => {
      return { label: n, value: n }
    })
  }

  const dateRangeOptions = getDateRangeOptions(datePeriodType!)

  const disableDynamicDates = () => {
    setDynamic(!dynamic)
    if (dynamic) {
      setDatePeriodType(null)
      setDateRangeType(null)
      setDateRangeN(null)
    }
  }

  useEffect(() => {
    let updatedFilterObject = { ...filterObject }
    if (datePeriodType) {
      updatedFilterObject.datePeriodType = datePeriodType
    } else {
      delete updatedFilterObject.datePeriodType
    }

    if (dateRangeType) {
      updatedFilterObject.dateRangeType = dateRangeType
    } else {
      delete updatedFilterObject.dateRangeType
    }
    if (dateRangeN) {
      updatedFilterObject.dateRangeN = dateRangeN
    } else {
      delete updatedFilterObject.dateRangeN
    }

    updateDateRangeFilter(updatedFilterObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamic, datePeriodType, dateRangeType, dateRangeN])

  return (
    <div className={styles.popup} itemID="popup" style={{ display: showPopup ? 'block' : 'none' }}>
      <Switch value={dynamic} text="dynamic dates" onChange={disableDynamicDates} />
      {dynamic && (
        <>
          <span className={styles.label}>Date Range type</span>
          <Select
            options={dateRangeTypeOptions}
            value={dateRangeTypeOptions.find(item => item.value === dateRangeType)}
            onChange={option => setDateRangeType(option!.value)}
          />

          {dateRangeType === EDateRangeType.last && (
            <>
              {' '}
              <span className={styles.label}>Number of {datePeriodType}</span>
              <Select
                options={dateRangeOptions}
                value={dateRangeOptions.find(item => dateRangeN && item.value === dateRangeN)}
                onChange={option => setDateRangeN(option!.value)}
              />
              <span className={styles.label}>Period</span>
              <Select
                options={periodTypeOptions}
                value={periodTypeOptions.find(item => item.value === datePeriodType)}
                onChange={option => setDatePeriodType(option!.value)}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}
