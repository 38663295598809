import { Crisp } from 'crisp-sdk-web'
import merge from 'lodash/merge'
import { useMemo } from 'react'

import { SlashCircle, SmallSpinner } from '@assets'
import { TickCircleOutline } from '@assets/icons/ticks'

import { Colors } from '@shared/constants'

import { ConnectionTestKey, ConnectionTestMessages, ConnectionTestStage, defaultConnectionTests } from './utils'

import styles from './data-source-test-status.module.css'

interface IDataSourceTestStatusProps {
  stage: ConnectionTestStage
  currentTest: ConnectionTestKey
  connectionTestMessages?: Partial<ConnectionTestMessages>
}

export const DataSourceTestStatus = ({ connectionTestMessages, currentTest, stage }: IDataSourceTestStatusProps) => {
  const combinedConnectionTests = useMemo(
    () => merge(defaultConnectionTests, connectionTestMessages),
    [connectionTestMessages]
  )

  const icon = useMemo(() => {
    switch (stage) {
      case 'running':
        return <SmallSpinner />
      case 'success':
        return <TickCircleOutline color={Colors.green} />
      case 'failed':
        return <SlashCircle />
      default:
        return null
    }
  }, [stage])

  const text = combinedConnectionTests[currentTest][stage]

  return (
    <div className={styles.container}>
      <div className={styles.stage}>
        {icon}
        {text && text}
      </div>
      {stage === 'failed' && (
        <span className={styles.contactLink} onClick={() => Crisp.chat.open()}>
          Contact us
        </span>
      )}
    </div>
  )
}
