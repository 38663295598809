import { Directory, OAuthTokenRes, SetupLink } from '@boxyhq/saml-jackson'
import * as Sentry from '@sentry/react'
import { Scope } from '@sentry/types'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import { SSOConnection, SamlProfile } from '@features/users/types'

import { api } from '@shared/http/axios'

export const useVerifyTenantByEmail = () => {
  return useMutation(
    (email: string): Promise<AxiosResponse<SSOConnection[]>> => {
      return api.post('/saml/email', { email })
    },
    {
      onError: (error: AxiosError) => {
        console.log(error)
      },
    }
  )
}

export const useVerifyTenant = () => {
  return useMutation(
    (tenant: string): Promise<AxiosResponse<SSOConnection[]>> => {
      return api.post('/saml/tenant', { tenant })
    },
    {
      onError: (error: AxiosError) => {
        console.log(error)
      },
    }
  )
}

export const useAuthenticateSamlUser = () => {
  return useMutation(
    (accessToken: string): Promise<AxiosResponse<SamlProfile>> => api.post(`/saml/auth`, { accessToken }),
    {
      onSuccess: () => {},
      onError: (error: AxiosError) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setContext('useAuthenticateSamlUser', {})
          return scope
        })
      },
    }
  )
}

export const useExchangeSamlCode = () => {
  return useMutation((code: string): Promise<AxiosResponse<OAuthTokenRes>> => api.post(`/saml/token`, { code }), {
    onError: (error: AxiosError) => {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setContext('useExchangeSamlCode', {})
        return scope
      })
    },
  })
}

export const useGetSetupLink = (organizationId?: string) => {
  return useQuery<SetupLink, Error>(['setupLink', { organizationId }], async () => {
    if (!organizationId) return
    const response = await api.get(`/saml/setup-link/${organizationId}`)

    if (!response) {
      return []
    }

    return response.data
  })
}

export const useGetDirectorySync = (organizationId?: string) => {
  return useQuery<Directory, Error>(['directorySync', { organizationId }], async () => {
    if (!organizationId) return
    const response = await api.get(`/saml/directory/${organizationId}`)

    if (!response) {
      return []
    }

    return response.data
  })
}
