import classNames from 'classnames'
import { FC, PropsWithChildren, useRef, useState } from 'react'

import { XClose } from '@assets'

import { Button } from '@shared/components/button'
import { useOutsideModalClick } from '@shared/hooks/use-outside-modal-click'

import styles from './modal.module.css'

export interface IModal extends PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
  open: boolean
  visible?: boolean
  cross?: boolean
  processing?: boolean
  onBackgroundClick?: () => void
}

export const Modal: FC<IModal> = ({
  open,
  cross = true,
  visible = true,
  children,
  className,
  processing = false,
  onBackgroundClick,
  ...props
}) => {
  const wrapperRef = useRef(null)
  const [mouseDownOutsideModal, setMouseDownOutsideModal] = useState(false)

  const preventPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()

  const handleClose = () => {
    onBackgroundClick && onBackgroundClick()
    setMouseDownOutsideModal(false)
  }

  useOutsideModalClick(wrapperRef, mouseDownOutsideModal, setMouseDownOutsideModal, processing, handleClose)

  const modalClass = classNames(styles.container, className)
  const backgroundClass = classNames(styles.background, open && !visible && styles.invisible)

  if (!open) return null

  return (
    <div ref={wrapperRef} className={backgroundClass}>
      <div className={modalClass} onClick={preventPropagation} {...props}>
        {cross && !processing && (
          <Button className="absolute top-4 right-4" linkGray onClick={handleClose} iconLeading={<XClose />} />
        )}
        {children}
      </div>
    </div>
  )
}
