import { AxiosResponse } from 'axios'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ManageDefaultDataSourceRequest } from '@features/data-sources'
import { IDataSource } from '@features/data-sources/types/types'
import { useAuthStore } from '@features/users'

import { successToast } from '@shared/components'
import { useBoolean } from '@shared/hooks/use-boolean'
import { useModalWithData } from '@shared/hooks/use-modal-with-data'

interface UserUserManagementProps {
  sourceId: string
  useGetDataSource: (sourceId: string, includeChildCredentials?: boolean) => UseQueryResult<any, Error>
  useDeleteDataSource: () => UseMutationResult<AxiosResponse<any, any>, Error, string, unknown>
  useManageDefaultDataSource: () => UseMutationResult<ManageDefaultDataSourceRequest, any, any, unknown>
}

export const useUserManagement = ({
  sourceId,
  useGetDataSource,
  useDeleteDataSource,
  useManageDefaultDataSource,
}: UserUserManagementProps) => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const { data: dataSource, isLoading } = useGetDataSource(sourceId, true)
  const { mutateAsync: deleteDataSource } = useDeleteDataSource()
  const { mutateAsync: manageDefaultDataSource } = useManageDefaultDataSource()
  const [deleteModal, dataSourceToDelete, toggleDeleteModal, openDeleteModal] = useModalWithData<IDataSource>()
  const [testModal, dataSourceToUpdate, toggleTestModal, openTestModal] = useModalWithData<IDataSource>()
  const [addChildDataSourceModal, toggleAddChildDataSourceModal] = useBoolean()
  const [defaultDSModal, defaultDataSourceId, toggleDefaultDSModal, openDefaultDSModal] = useModalWithData()

  const handleSharedDSChange = async (isDefault: boolean, dataSourceId: string) => {
    if (isDefault) {
      openDefaultDSModal(dataSourceId)
    } else {
      await manageDefaultDataSource({ dataSourceId, isDefault })
      successToast('Service account disabled')
    }
  }

  const setDefaultDS = async () => {
    await manageDefaultDataSource({ dataSourceId: defaultDataSourceId, isDefault: true })
    successToast('Service account enabled')
    toggleDefaultDSModal()
  }

  const handleDelete = () => {
    if (!dataSourceToDelete) return
    deleteDataSource(dataSourceToDelete.id)
    toggleDeleteModal()
  }

  return {
    user,
    dataSource,
    isLoading,
    deleteModal,
    dataSourceToDelete,
    toggleDeleteModal,
    openDeleteModal,
    addChildDataSourceModal,
    toggleAddChildDataSourceModal,
    testModal,
    dataSourceToUpdate,
    toggleTestModal,
    openTestModal,
    defaultDSModal,
    toggleDefaultDSModal,
    handleSharedDSChange,
    setDefaultDS,
    handleDelete,
    navigate,
  }
}
