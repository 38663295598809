import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { UseMutationResult, UseQueryResult } from 'react-query'

import { successToast } from '@shared/components'
import { IUpdateEmailConfigVariables } from '@shared/components/email-config/types'
import { UseQueryWithErrorHandlerOptions } from '@shared/http'
import { WithId } from '@shared/utils/types'

export const useEmailConfigHandler = <
  EmailConfig extends WithId<unknown>,
  CreateRequest extends Omit<EmailConfig, 'id' | 'createdAt' | 'updatedAt'> = Omit<
    EmailConfig,
    'id' | 'createdAt' | 'updatedAt'
  >,
>(
  {
    targetId,
    useGetEmailConfig,
    useAddEmailConfig,
    useUpdateEmailConfig,
    getEmptyConfig,
  }: {
    targetId?: string
    useGetEmailConfig: (
      id?: string,
      options?: UseQueryWithErrorHandlerOptions<EmailConfig, AxiosError>
    ) => UseQueryResult<CreateRequest, AxiosError>
    useAddEmailConfig: (id?: string) => UseMutationResult<EmailConfig, AxiosError, CreateRequest>
    useUpdateEmailConfig: (
      id?: string
    ) => UseMutationResult<EmailConfig, AxiosError, IUpdateEmailConfigVariables<Partial<CreateRequest>>>
    getEmptyConfig: (targetId?: string) => CreateRequest
  },
  options: UseQueryWithErrorHandlerOptions<EmailConfig, AxiosError> = {}
) => {
  const [emailConfig, setEmailConfig] = useState<CreateRequest | undefined>()

  const { data, isLoading: isGetEmailConfigLoading } = useGetEmailConfig(targetId, options)

  const { mutateAsync: addEmailConfig, isLoading: isAddEmailConfigLoading } = useAddEmailConfig(targetId)
  const { mutateAsync: updateEmailConfig, isLoading: isUpdateEmailConfigLoading } = useUpdateEmailConfig(targetId)

  useEffect(() => {
    setEmailConfig(data)
  }, [data])

  const upsertEmailConfig = async (newConfig: CreateRequest | EmailConfig, { showToast = true } = {}) => {
    if ('id' in newConfig) {
      const { id, ...config } = newConfig
      await updateEmailConfig(
        { emailConfigId: id, data: config as unknown as Partial<CreateRequest> },
        {
          onSuccess: () => {
            if (showToast) {
              successToast('Email configuration updated')
            }
          },
        }
      )
      return
    }

    await addEmailConfig(newConfig as CreateRequest, {
      onSuccess: () => {
        successToast('Email configuration saved')
      },
    })
  }

  return {
    emailConfig,
    setEmailConfig: (partialConfig: Partial<CreateRequest>) =>
      setEmailConfig(config => ({ ...getEmptyConfig(targetId), ...config, ...partialConfig })),
    upsertEmailConfig,
    isGetEmailConfigLoading,
    isUpdateEmailConfigLoading,
    isAddEmailConfigLoading,
    isEmailConfigLoading: isGetEmailConfigLoading || isAddEmailConfigLoading || isUpdateEmailConfigLoading,
  }
}
