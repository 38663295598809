import { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { AddDestination } from '@pages/destinations/add-destination/add-destination'
import { Destination } from '@pages/destinations/destination/destination'
import { Destinations } from '@pages/destinations/destinations'
import { AddVisualization } from '@pages/visualizations/add-visualization/add-visualization'
import { EditVisualization } from '@pages/visualizations/edit-visualization/edit-visualization'

import { useDestinationsStore } from '@features/destinations'

import {
  DestinationCrumb,
  DestinationsCrumb,
  DestinationsNewCrumb,
  VisualizationAddCrumb,
  VisualizationDetailsCrumb,
} from '@shared/components/breadcrumbs/breadcrumbs'

import { DestinationTabs, PathSegments } from './paths'

const RedirectToDestination = () => {
  const { destinationId, visualizationId } = useParams()
  const navigate = useNavigate()
  const { destinations } = useDestinationsStore()

  const destination = destinations?.find(destination => destination.id === destinationId)
  const sync = destination?.syncs?.find(sync => sync.id === visualizationId)

  useEffect(() => {
    if (sync?.slideId) {
      navigate(`/destinations/${destinationId}/visualizations?slideId=${sync.slideId}`, { replace: true })
    } else {
      navigate(`/destinations/${destinationId}/visualizations`, { replace: true })
    }
  }, [destinationId, sync?.slideId, navigate])

  return null
}

export const destinationRoutes = {
  path: PathSegments.DESTINATIONS,
  handle: { crumb: DestinationsCrumb },
  children: [
    {
      index: true,
      element: <Destinations />,
    },
    {
      path: PathSegments.ADD,
      element: <AddDestination />,
      handle: { crumb: DestinationsNewCrumb },
    },
    {
      path: PathSegments.DESTINATION_ID,
      handle: { crumb: DestinationCrumb },
      children: [
        {
          index: true,
          element: <Navigate to={DestinationTabs.VISUALIZATIONS} replace relative="path" />,
        },
        {
          path: DestinationTabs.VISUALIZATIONS,
          children: [
            {
              index: true,
              element: <Destination />,
            },
            // Redirect old add visualization path to new add visualization path
            {
              path: 'new',
              element: (
                <Navigate
                  to={{ pathname: `../${PathSegments.ADD}`, search: window.location.search }}
                  replace
                  relative="path"
                />
              ),
            },
            {
              path: PathSegments.ADD,
              element: <AddVisualization />,
              handle: { crumb: VisualizationAddCrumb },
            },
            {
              path: PathSegments.VISUALIZATION_ID,
              element: <EditVisualization />,
              handle: { crumb: VisualizationDetailsCrumb },
            },
          ],
        },
        {
          path: DestinationTabs.FILTERS,
          element: <Destination />,
        },
        {
          path: DestinationTabs.SENDING,
          element: <Destination />,
        },
        {
          path: DestinationTabs.HISTORICAL_RUNS,
          element: <Destination />,
        },
        {
          path: DestinationTabs.ACTIVITY_LOG,
          element: <Destination />,
        },
        {
          path: DestinationTabs.THEME,
          element: <Destination />,
        },
        {
          path: DestinationTabs.TEMPLATE,
          element: <Destination />,
        },
        {
          path: DestinationTabs.SETTINGS,
          element: <Destination />,
        },
        {
          path: DestinationTabs.UPDATE_SETTINGS,
          element: <Navigate to={`../${DestinationTabs.SETTINGS}`} replace relative="path" />,
        },
        {
          path: `${PathSegments.OLD_VISUALIZATIONS}/${PathSegments.SYNC}/:syncId`, // Matches the old path
          element: <RedirectToDestination />,
        },
        {
          path: PathSegments.VISUALIZATION_ID, // Correctly redirect with slideId
          element: <RedirectToDestination />,
        },
      ],
    },
  ],
}
