import { useCallback, useContext, useState } from 'react'

import { useAddSpreadsheetsFiles } from '@features/data-sources/api'
import { EDataSourceType } from '@features/data-sources/types/types'
import { getGooglePickerViewId, useGDrivePicker } from '@features/drive'
import { GOOGLE_DRIVE_SCOPES } from '@features/drive'
import { OAuthContext, checkUserScopes, getOAuthDetailsDataSource, useAuthStore } from '@features/users'

import { updateDataSourceToast } from '@shared/components'

export interface ISheetsDataSourceDoc {
  id: string
  driveId?: string | null
  name: string
  url: string
  serviceId: string
  type: string
  mimeType?: string
}

export const useAddDataSourcesFromDrive = (cb?: () => void) => {
  const { mutateAsync: addSpreadsheetsFiles } = useAddSpreadsheetsFiles()

  const [isLoading, setIsLoading] = useState(false)

  const user = useAuthStore(state => state.user)
  const { showAuthModal, hideAuthModal } = useContext(OAuthContext)

  const openPicker = async (option: EDataSourceType, query?: string) => {
    switch (option) {
      case EDataSourceType.GoogleSheets:
        const viewId = option ? getGooglePickerViewId(option) : undefined
        await openGDrivePicker({
          customScopes: GOOGLE_DRIVE_SCOPES,
          viewId,
          customViews: [],
          multiselect: true,
          query,
        })
        break
    }
  }

  const addSheetsDataSources = (option: EDataSourceType, query?: string) => () => {
    setIsLoading(true)
    const { oAuthType } = getOAuthDetailsDataSource(option)
    if (!oAuthType) {
      console.error('No OAuth type found for data source type', option, oAuthType)
      return
    }
    const userHasScopes = checkUserScopes(oAuthType, user)
    if (!userHasScopes) {
      showAuthModal({
        type: oAuthType,
        successCallback: () => {
          openPicker(option, query)
        },
        handleClose: () => {
          setIsLoading(false)
          hideAuthModal()
        },
      })
      return
    }
    openPicker(option, query)
  }

  const addSpreadsheets = () => async (docs: ISheetsDataSourceDoc[]) => {
    setIsLoading(true)

    const spreadsheets = docs.map(doc => ({
      fileId: doc.id,
      name: doc.name,
      spreadsheetUrl: doc.url,
      baseUrl: '',
      id: '',
      type: EDataSourceType.GoogleSheets,
    }))

    try {
      await addSpreadsheetsFiles({
        spreadsheets: spreadsheets,
      })
      updateDataSourceToast(true)
      cb && cb()
    } finally {
      setIsLoading(false)
    }
  }

  const onCancelfn = useCallback(() => {
    setIsLoading(false)
  }, [])

  const { openGDrivePicker } = useGDrivePicker(addSpreadsheets(), onCancelfn)

  return {
    addSheetsDataSources,
    isLoading,
  }
}
