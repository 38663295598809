import classNames from 'classnames'
import { FC } from 'react'

import { TickCircleFull } from '@assets/icons/ticks'

import { Button } from '@shared/components'

import styles from './onboarding-step.module.css'

interface OnboardingStepProps {
  title: string
  text: string
  buttonText: string
  completed: boolean
  completedButtonText: string
  disabled?: boolean
  onBtnClick: () => void
}

export const OnboardingStep: FC<OnboardingStepProps> = ({
  title,
  text,
  buttonText,
  completed,
  completedButtonText,
  disabled,
  onBtnClick,
}) => {
  const containerClass = classNames(styles.container, completed && styles.completed, disabled && styles.disabled)

  return (
    <div className={containerClass}>
      {completed && (
        <span className={styles.completedTick}>
          <TickCircleFull />
        </span>
      )}
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>{text}</p>
      <div className={styles.btnContainer}>
        <Button
          className={styles.btn}
          disabled={disabled}
          text={completed ? completedButtonText : buttonText}
          onClick={onBtnClick}
        />
      </div>
    </div>
  )
}
