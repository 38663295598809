import React from 'react'

import { DestinationShareModal } from '@features/collaboration/components/share-modal/share-modal'
import {
  useGetDestinationById,
  useRevokeSharePermission,
  useShareDestination,
  useUpdateSharePermission,
} from '@features/destinations/api/api'

import { useModalWithData } from '@shared/hooks/use-modal-with-data'

export interface WithDestinationShareProps {
  shareDestination: (destinationId: string) => void
}

export const withDestinationShare = <T,>(WrappedComponent: React.ComponentType<T & WithDestinationShareProps>) => {
  return (props: T) => {
    const [modal, destinationId, toggleModal, openModalWithData] = useModalWithData<string>()

    const { data: destination } = useGetDestinationById({ destinationId })
    const { mutateAsync: shareDestination, isLoading: shareIsLoading } = useShareDestination(
      destinationId!,
      destination
    )
    const { mutateAsync: updatePermissions, isLoading: updateShareIsLoading } = useUpdateSharePermission(
      destinationId!,
      destination
    )
    const { mutateAsync: revokePermissions, isLoading: revokeShareIsLoading } = useRevokeSharePermission(
      destinationId!,
      destination
    )

    return (
      <>
        <WrappedComponent {...props} shareDestination={openModalWithData} />
        {destinationId && destination && (
          <DestinationShareModal
            open={modal}
            toggle={toggleModal}
            destination={destination}
            shareItem={shareDestination}
            updatePermissions={updatePermissions}
            revokePermissions={revokePermissions}
            isInviteLoading={shareIsLoading}
            isUpdatePermissionsLoading={updateShareIsLoading}
            isRevokePermissionLoading={revokeShareIsLoading}
          />
        )}
      </>
    )
  }
}
