import { FC } from 'react'

import { AutoProvisioning } from '@pages/data-sources/common/auto-provisioning'

import { ArrowRight, DatabaseIcon, metabaseAssetsUrlsBase } from '@assets'

import { MetabasePasswordModal, useMetabaseDataSourcesSettings } from '@features/data-sources'
import { ConfirmBox } from '@features/data-sources/components/settings/confirm-box/confirm-box'
import { InputBox } from '@features/data-sources/components/settings/input-box/input-box'

import { Button } from '@shared/components'
import { Input } from '@shared/components/input/input'
import { PageContainer } from '@shared/components/page-container/page-container'

import styles from './metabase-data-sources-settings.module.css'

const metabase1Url = `${metabaseAssetsUrlsBase}metabase1.png`
const metabase2Url = `${metabaseAssetsUrlsBase}metabase2.png`
const metabase3Url = `${metabaseAssetsUrlsBase}metabase3.png`

export const MetabaseDataSourceSettings: FC = () => {
  const {
    state,
    isFormValid,
    isLoading,
    isUpdate,
    modal,
    validationErrors,
    toggleModal,
    testAndSave,
    handleInputChange,
    toggleAutoProvision,
  } = useMetabaseDataSourcesSettings()

  const buttonText = isUpdate ? 'Update data source' : 'Continue'

  return (
    <PageContainer hideBreadcrumb={false}>
      <div className={styles.container}>
        <span>Choose a data source name and fill in your company’s Metabase base url:</span>
        <InputBox>
          <Input
            label="Data source name"
            placeholder="Data source name"
            name="name"
            value={state.name}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <Input
            label="Metabase Base url"
            placeholder="Metabase Base url"
            name="baseUrl"
            value={state.baseUrl}
            errorMessage={validationErrors.baseUrlError}
            onChange={handleInputChange}
            description={
              <>
                This is the URL you see when you go to your Metabase instance. <br />
                <b>
                  <u>Example:</u>
                </b>
                {' https://metabase.<yourdomain>.com'}
                <br />
                <br />
                If you're using Metabase Cloud, you can find your URL in the browser address bar when you're logged in
                to Metabase. <br />
                <b>
                  <u>Example:</u>
                </b>
                {' https://<yourdomain>.metabaseapp.com'}
              </>
            }
          />
        </InputBox>
        <span>In your Metabase admin page, go to Embedding and enable embedding.</span>
        <img src={metabase1Url} alt="metabase1Url" />
        <span>Go to “More details”</span>
        <img src={metabase2Url} alt="metabase2Url" />
        <span>Generate an embedding secret key and copy the value</span>
        <img src={metabase3Url} alt="metabase3Url" />
        <span>Paste the embedding secret key here:</span>
        <InputBox>
          <Input
            label="Embed Secret"
            placeholder="Embed Secret"
            name="embedSecret"
            value={state.embedSecret}
            onChange={handleInputChange}
            secret
            description={
              <p>
                To get your Embed Secret: Open Metabase {'>'} Admin Settings (gear icon menu) {'>'} Embedding {'>'}{' '}
                Enable {'>'} Standalone Embeds {'>'} Copy the Embed Secret
              </p>
            }
          />
        </InputBox>
        <AutoProvisioning toggleAutoProvision={toggleAutoProvision} state={state} />

        <ConfirmBox text="Save your Embedding secret key">
          <Button
            disabled={isLoading || !isFormValid}
            loading={isLoading}
            text={buttonText}
            onClick={testAndSave}
            iconTrailing={<ArrowRight />}
          />
        </ConfirmBox>
      </div>
      {modal && state && (
        <MetabasePasswordModal
          open={modal}
          Icon={DatabaseIcon}
          title="Test and save data source"
          subtitle="In order to properly test your data source connection, you need to provide your admin username"
          dataSource={state}
          toggle={toggleModal}
        />
      )}
    </PageContainer>
  )
}
