import { SharingCollectionPermissions } from '@features/collections/types'
import { Destination, DestinationSharingPermissions } from '@features/destinations/types'
import { useAuthStore } from '@features/users'

export const useDestinationRights = (destination?: Destination | null) => {
  const user = useAuthStore(state => state.user)

  const sharedWith = destination?.sharedWith || []

  const owner = user ? destination?.userId === user.id : false

  const viewer = user
    ? sharedWith.some(
        ({ userId: sharedUserId, permission }) =>
          sharedUserId === user.id && permission === DestinationSharingPermissions.view
      )
    : false

  const editor = user
    ? sharedWith.some(
        ({ userId: sharedUserId, permission }) =>
          sharedUserId === user.id && permission === DestinationSharingPermissions.edit
      )
    : false

  const collectionAdmin = Boolean(
    destination?.variantInCollection?.collection?.sharedWith?.find(
      sharedWith => sharedWith.permission === SharingCollectionPermissions.admin && sharedWith.userId === user?.id
    )
  )

  const collectionOwner = Boolean(destination?.variantInCollection?.collection?.userId === user?.id)

  const canEdit = collectionOwner || collectionAdmin || owner || editor

  return {
    owner,
    viewer,
    editor,
    canEdit,
  }
}
