import { FC } from 'react'

import { Arrow, DotsGrid, FilterIcon, List, SearchIconMd } from '@assets'

import { sortingOptions } from '@features/destinations'

import { Input } from '@shared/components'
import { Button as ShadcnButton } from '@shared/components/ui/button'
import { ToggleGroup, ToggleGroupItem } from '@shared/components/ui/toggle-group'
import { filteringOptions } from '@shared/hooks'

import { BaseFilterOption, FilterOrStorterDropdown } from './filter-or-sorter-dropdown/filter-or-sorter-dropdown'

type ISortAndFilterHeader = {
  sortingOption: any
  isAscendingOrder: boolean
  filteringOption?: any
  headerRightActions?: JSX.Element
  isGridLayout: boolean
  filteringVariantOwnerOption?: BaseFilterOption
  filteringVariantOwnerOptions?: BaseFilterOption[]
  setIsGridLayout: (value: boolean) => void
  handleSortTypeChange: (option: any) => void
  handleSortOrderChange: (option: any) => void
  handleFilterTypeChange?: (option: any) => void
  handleFilterVariantOwnerChange?: (option: BaseFilterOption) => void
  setSearchQuery: (query: string) => void
}

export const SortAndFilterHeader: FC<ISortAndFilterHeader> = ({
  sortingOption,
  isAscendingOrder,
  filteringOption,
  headerRightActions,
  isGridLayout,
  filteringVariantOwnerOption,
  filteringVariantOwnerOptions,
  setIsGridLayout,
  handleSortTypeChange,
  handleSortOrderChange,
  handleFilterTypeChange,
  handleFilterVariantOwnerChange,
  setSearchQuery,
}) => {
  return (
    <div className="w-full h-9 flex items-center justify-between gap-2.5">
      <div className="flex justify-end items-center gap-5">
        <div className="flex gap-1">
          <ShadcnButton variant={'outline'} size="icon" onClick={handleSortOrderChange}>
            <Arrow orientation={isAscendingOrder ? 'top' : 'bottom'} />
          </ShadcnButton>
          <FilterOrStorterDropdown
            label="Sort by"
            options={sortingOptions}
            selectedOption={sortingOption}
            onSelect={option => handleSortTypeChange(option)}
            highlightNonDefaultValue={false}
          />
        </div>
        {filteringOption && handleFilterTypeChange && (
          <FilterOrStorterDropdown
            icon={<FilterIcon />}
            label="Owner"
            options={filteringOptions}
            selectedOption={filteringOption}
            onSelect={option => handleFilterTypeChange(option)}
          />
        )}
        {filteringVariantOwnerOption && filteringVariantOwnerOptions && handleFilterVariantOwnerChange && (
          <FilterOrStorterDropdown
            icon={<FilterIcon />}
            label="Owner"
            options={filteringVariantOwnerOptions}
            selectedOption={filteringVariantOwnerOption}
            onSelect={option => handleFilterVariantOwnerChange(option)}
            highlightNonDefaultValue={false}
          />
        )}
        <ToggleGroup type="single" value={isGridLayout ? 'grid' : 'list'}>
          <ToggleGroupItem value="grid" onClick={() => setIsGridLayout(true)}>
            <DotsGrid />
          </ToggleGroupItem>
          <ToggleGroupItem value="list" onClick={() => setIsGridLayout(false)}>
            <List />
          </ToggleGroupItem>
        </ToggleGroup>
        <Input
          icon={<SearchIconMd />}
          className="w-50 h-9"
          onChange={e => {
            setSearchQuery(e.target.value)
          }}
          placeholder="Search"
        />
      </div>
      {headerRightActions}
    </div>
  )
}
