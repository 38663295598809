import { FC } from 'react'

import { SmallSpinner } from '@assets'
import { PresentationPlaceholderIcon } from '@assets/icons/presentation-placeholder'

import { useGetDestinationThumbnails } from '@features/destinations'

import { ImageWithFallback } from '@shared/components'

import styles from './ms-word.module.css'

export interface IMSWord {
  destinationId: string
  height?: number
  loading?: boolean
  showFullDocument?: boolean
  title?: string
  width?: number
}

const defaultWidth = 380
const defaultHeight = 470

const three = 3
const four = 4
const mBottomOffset = 50
const bRadius = 12
const bRadiusSmall = 4

export const MSWord: FC<IMSWord> = ({
  destinationId,
  height = defaultHeight,
  loading = false,
  showFullDocument = false,
  title = '',
  width = defaultWidth,
}) => {
  const { data, isLoading } = useGetDestinationThumbnails(destinationId)

  const position = showFullDocument ? 'static' : 'relative'
  const marginTop = showFullDocument ? `-${height / three}px` : 0
  const marginBottom = showFullDocument ? `-${height / four + mBottomOffset}px` : 0
  const borderRadius = showFullDocument ? bRadius : bRadiusSmall
  const borderColor = showFullDocument ? '#98a2b3' : '#d0d5dd'

  // eliminate caching
  const url = data?.url ? `${data.url}&timestamp=${new Date().getTime()}` : ''

  const showLoader = isLoading || loading

  return (
    <div
      style={{
        height,
        marginBottom,
        marginTop,
        position,
        width,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {showLoader && (
        <div className={styles.spinnerContainer} hidden={!isLoading && !loading} style={{ height: `${height}px` }}>
          <SmallSpinner />
        </div>
      )}
      {!showLoader && (
        <ImageWithFallback
          alt={title}
          className={styles.image}
          imageSize={{ width, height }}
          src={url}
          style={{ height, width }}
          fallback={<PresentationPlaceholderIcon />}
          disablePosthogCapture
        />
      )}
      <div className={styles.insideBorder} style={{ width, height }} />
      <div className={styles.border} style={{ width, height, borderRadius, borderColor }} />
    </div>
  )
}
