import { FC } from 'react'

import { MenuHorizontalIcon } from '@assets/icons/menu'

import { ICollection } from '@features/collections/types'
import { destinationHandlers, isGoogleDestination, isMicrosoftDestination } from '@features/destinations'

import { Button as ShadcnButton } from '@shared/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@shared/components/ui/dropdown-menu'

import { AuthorizeItem } from './menu-items/authorize-item'
import { DeleteItem } from './menu-items/delete-item'
import { OpenExternalItem } from './menu-items/open-external-item'
import { RecreateAllVariantsItem } from './menu-items/recreate-all-variants-item'
import { RenameItem } from './menu-items/rename-item'
import { ShareItem } from './menu-items/share-item'

interface ICollectionMenu {
  collection: ICollection
  admin: boolean
  shared: boolean
  contributor: boolean
  owner: boolean
  handleDeleteCollection?: () => void
  renameClick?: () => void
  shareCollection?: () => void
  toggleRegenerateVariantsModal?: () => void
  authorizeCollection?: () => void
}

export const CollectionMenu: FC<ICollectionMenu> = ({
  collection,
  shared,
  admin,
  contributor,
  handleDeleteCollection,
  renameClick,
  shareCollection,
  toggleRegenerateVariantsModal,
  authorizeCollection,
}) => {
  const handle = destinationHandlers(collection.type)
  const isGoogle = isGoogleDestination({ type: collection.type })
  const isMicrosoft = isMicrosoftDestination({ type: collection.type })
  const isMicrosoftShared = isMicrosoft && shared
  const showRenameItem = Boolean(!contributor)
  const showShareItem = Boolean(shareCollection && !contributor)
  const showRecreateItem = Boolean(toggleRegenerateVariantsModal)
  const showDeleteItem = Boolean(handleDeleteCollection && !contributor && !admin)
  const showAuthorizeItem = Boolean(authorizeCollection && isGoogle)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadcnButton size="sm" variant="outline">
          {<MenuHorizontalIcon />}
        </ShadcnButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" onClick={e => e.stopPropagation()}>
        <RenameItem
          disabled={isMicrosoftShared}
          showMicrosoftTooltip={isMicrosoftShared}
          onClick={renameClick}
          visible={showRenameItem}
        />
        <ShareItem onClick={shareCollection} visible={showShareItem} />
        <RecreateAllVariantsItem
          cloudTitle={handle.getCloudTitle()}
          onClick={toggleRegenerateVariantsModal}
          visible={showRecreateItem}
        />
        <OpenExternalItem link={collection.folderUrl} />
        <DeleteItem onClick={handleDeleteCollection} visible={showDeleteItem} />
        <AuthorizeItem onClick={authorizeCollection} visible={showAuthorizeItem} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
