import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 20

export const ActivityAddVisIcon: FC<Pick<IIcon, 'size'>> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 19" fill="none">
      <path
        d="M9.99996 2.50033H13.5C14.9001 2.50033 15.6002 2.50033 16.1349 2.77281C16.6053 3.01249 16.9878 3.39494 17.2275 3.86535C17.5 4.40013 17.5 5.10019 17.5 6.50033V13.5003C17.5 14.9005 17.5 15.6005 17.2275 16.1353C16.9878 16.6057 16.6053 16.9882 16.1349 17.2278C15.6002 17.5003 14.9001 17.5003 13.5 17.5003H6.49996C5.09983 17.5003 4.39976 17.5003 3.86498 17.2278C3.39458 16.9882 3.01213 16.6057 2.77244 16.1353C2.49996 15.6005 2.49996 14.9005 2.49996 13.5003V10.0003M6.66663 10.8337V14.167M13.3333 9.16699V14.167M9.99996 5.83366V14.167M4.16663 6.66699V1.66699M1.66663 4.16699H6.66663"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
