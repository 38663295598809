import { useMemo } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { VariantTableSelectProps } from '@features/collections/components/collection-setup/variant-select-table/variant-select-table'
import { useGetFilterValues } from '@features/collections/hooks/use-get-filter-values'
import { useGetTableauFilterValues } from '@features/collections/hooks/use-get-tableau-filter-values'
import { VariantOption, VariantOptionRow } from '@features/collections/types'
import { toOption } from '@features/collections/utils'
import { useGetLookerFilterValues, useGetMetabaseFilterValues } from '@features/data-sources'
import { FilterType } from '@features/filters/types'

import { Switch, primaryStyle } from '@shared/components'

export const SecondaryFilterValuesSelect = ({
  credentialsId,
  primaryFilter,
  secondaryFilter,
  index,
  row,
  onChangeSecondaryValue,
  onSelectAll,
}: Pick<VariantTableSelectProps, 'onSelectAll' | 'onChangeSecondaryValue' | 'primaryFilter' | 'secondaryFilter'> & {
  credentialsId?: string
  index: number
  row: VariantOptionRow
}) => {
  const args = {
    credentialsId,
    dashboardId: secondaryFilter.dashboardId,
    filterId: secondaryFilter.dashboardFilterId,
    filteredByFilterId: primaryFilter.dashboardFilterId,
    filteredByFilterValue: row.primaryValue?.value,
  }

  const { handleLoadOptions } = useGetFilterValues(secondaryFilter.type, args)

  const {
    data: allValuesFilteredByPrimaryFilterForLooker,
    isLoading: isLoadingAllValuesFilteredByPrimaryFilterForLooker,
  } = useGetLookerFilterValues(args, {
    enabled: secondaryFilter.type === FilterType.LookerFilter,
  })

  const {
    data: allValuesFilteredByPrimaryFilterForMetabase,
    isLoading: isLoadingAllValuesFilteredByPrimaryFilterForMetabase,
  } = useGetMetabaseFilterValues(args, {
    enabled: secondaryFilter.type === FilterType.MetabaseFilter,
  })

  const {
    data: allValuesFilteredByPrimaryFilterForTableau,
    isLoading: isLoadingAllValuesFilteredByPrimaryFilterForTableau,
  } = useGetTableauFilterValues(args, {
    enabled: secondaryFilter.type === FilterType.TableauFilter || secondaryFilter.type === FilterType.TableauParams,
  })

  const { allOptionsFilteredByPrimaryFilter, isLoading } = useMemo(() => {
    switch (secondaryFilter.type) {
      case FilterType.TableauFilter:
      case FilterType.TableauParams:
        return {
          allOptionsFilteredByPrimaryFilter: (allValuesFilteredByPrimaryFilterForTableau ?? []).map(toOption),
          isLoading: isLoadingAllValuesFilteredByPrimaryFilterForTableau,
        }
      case FilterType.LookerFilter:
        return {
          allOptionsFilteredByPrimaryFilter: (allValuesFilteredByPrimaryFilterForLooker ?? []).map(toOption),
          isLoading: isLoadingAllValuesFilteredByPrimaryFilterForLooker,
        }
      case FilterType.MetabaseFilter:
        return {
          allOptionsFilteredByPrimaryFilter: (allValuesFilteredByPrimaryFilterForMetabase ?? []).map(toOption),
          isLoading: isLoadingAllValuesFilteredByPrimaryFilterForMetabase,
        }
      default:
        return {
          allOptionsFilteredByPrimaryFilter: [],
          isLoading: true,
        }
    }
  }, [
    secondaryFilter.type,
    allValuesFilteredByPrimaryFilterForTableau,
    isLoadingAllValuesFilteredByPrimaryFilterForTableau,
    allValuesFilteredByPrimaryFilterForLooker,
    isLoadingAllValuesFilteredByPrimaryFilterForLooker,
    allValuesFilteredByPrimaryFilterForMetabase,
    isLoadingAllValuesFilteredByPrimaryFilterForMetabase,
  ])

  const primaryValueEmpty = !row.primaryValue?.value
  const disabled = isLoading || primaryValueEmpty

  return (
    <div className="flex gap-4">
      <Switch
        disabled={disabled}
        className={row.secondaryAllSelected ? 'w-full' : 'w-[70px]'}
        value={row.secondaryAllSelected}
        onChange={() => onSelectAll(allOptionsFilteredByPrimaryFilter, index)}
        text={row.secondaryAllSelected ? 'All values selected' : 'All'}
      />
      {!row.secondaryAllSelected && (
        <AsyncCreatableSelect
          key={row.primaryValue?.value}
          value={row.secondaryValues}
          className="w-full"
          isMulti
          cacheOptions
          defaultOptions
          loadOptions={handleLoadOptions}
          placeholder={primaryValueEmpty ? `Select ${primaryFilter.name} first` : undefined}
          isDisabled={disabled}
          onChange={options => onChangeSecondaryValue(options as VariantOption[], index)}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          isSearchable={true}
          styles={primaryStyle(disabled)}
          closeMenuOnSelect={false}
        />
      )}
    </div>
  )
}
