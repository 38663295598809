import { useEffect, useState } from 'react'

export const useLayout = () => {
  const layoutLocalKey = 'isGridLayout'

  const initialIsGridLayout = () => {
    const localStorageIsGridLayout = localStorage.getItem(layoutLocalKey)
    return localStorageIsGridLayout ? localStorageIsGridLayout === 'true' : true
  }

  const [isGridLayout, setIsGridLayout] = useState(initialIsGridLayout)

  useEffect(() => {
    localStorage.setItem(layoutLocalKey, isGridLayout.toString())
  }, [isGridLayout])

  return {
    isGridLayout,
    setIsGridLayout,
  }
}
