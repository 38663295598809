import { ExternalLinkIcon } from '@assets'

import { MenuItem, NewTabLink } from '@shared/components'

export const OpenExternalItem = ({ link, visible = true }: { link?: string; visible?: boolean }) => {
  if (!visible) {
    return null
  }

  return (
    <NewTabLink link={link}>
      <MenuItem visible={visible}>
        <ExternalLinkIcon />
        {'Open'}
      </MenuItem>
    </NewTabLink>
  )
}
