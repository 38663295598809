import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetHistoricalRunsByDestinationId } from '@features/historical-run/api'

import { Spinner } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'

import { historicalRunsTableColumns, historicalRunsTableData } from './historical-runs-table'

interface DestinationHistoricalRunsProps {
  destinationId: string
}

export const DestinationHistoricalRuns: FC<DestinationHistoricalRunsProps> = ({ destinationId }) => {
  const [page, setPage] = useState<number>(1)
  const navigate = useNavigate()

  const { isLoading, data: historicalRunsResponse } = useGetHistoricalRunsByDestinationId(destinationId, {
    page,
  })

  const data = historicalRunsTableData(historicalRunsResponse?.items || [], navigate)
  const columns = historicalRunsTableColumns(false)

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  return (
    <section>
      {isLoading ? (
        <Spinner />
      ) : (
        <DataGrid
          data={data}
          columns={columns}
          enablePagination
          customPagination={{
            currentPage: page,
            totalPages: historicalRunsResponse?.totalPages || 1,
            onPageChange: handlePageChange,
          }}
          clickableRows
          EmptyState="No runs"
        />
      )}
    </section>
  )
}
