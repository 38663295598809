import { useState } from 'react'

import { NestedNullableKeyOf, resolveNestedNullableKey } from '@shared/utils'

export const useSearching = <T extends object>(searchedProperties: NestedNullableKeyOf<T>[]) => {
  const [searchQuery, setSearchQuery] = useState('')

  const filter = (objects: T[], searchQuery: string): T[] => {
    if (!searchQuery) {
      return objects
    }

    const query = searchQuery.toLowerCase()

    const filtered = objects.filter(obj => {
      let isMatch = false

      for (const property of searchedProperties) {
        let value = resolveNestedNullableKey(property, obj)

        if (typeof value === 'number') {
          value = value.toString()
        }

        if (!value || typeof value !== 'string') continue

        if (value.toLowerCase().includes(query)) {
          isMatch = true
          break
        }
      }

      return isMatch
    })

    return filtered
  }

  return { searchQuery, setSearchQuery, filter }
}
