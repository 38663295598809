import React from 'react'

import { WithDestinationDeleteProps, withDestinationDelete } from './with-destination-delete'
import { WithDestinationDuplicateProps, withDestinationDuplicate } from './with-destination-duplicate'
import {
  WithDestinationDuplicateOutsideProps,
  withDestinationDuplicateOutside,
} from './with-destination-duplicate-outside'
import { WithDestinationRecreateProps, withDestinationRecreate } from './with-destination-recreate'
import { WithDestinationRenameProps, withDestinationRename } from './with-destination-rename'
import { WithDestinationShareProps, withDestinationShare } from './with-destination-share'
import { WithDestinationUpdateProps, withDestinationUpdate } from './with-destination-update'
import { WithDestinationVersionProps, withDestinationVersion } from './with-destination-version'

export type WithDestinationOperationsProps = WithDestinationUpdateProps &
  WithDestinationVersionProps &
  WithDestinationDuplicateProps &
  WithDestinationRenameProps &
  WithDestinationDeleteProps &
  WithDestinationDuplicateOutsideProps &
  WithDestinationShareProps &
  WithDestinationRecreateProps

export const withDestinationOperations = <T,>(
  WrappedComponent: React.ComponentType<T & WithDestinationOperationsProps>
) =>
  withDestinationRecreate(
    withDestinationDuplicateOutside(
      withDestinationShare(
        withDestinationDelete(
          withDestinationRename(
            withDestinationDuplicate(withDestinationVersion(withDestinationUpdate(WrappedComponent)))
          )
        )
      )
    )
  )
