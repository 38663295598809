import { FC } from 'react'

import { IIcon } from '@assets/types'

export const PlusIcon: FC<IIcon> = ({ color = 'currentColor', onClick }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" onClick={onClick}>
      <path
        d="M10.0003 4.16797V15.8346M4.16699 10.0013H15.8337"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
