import { FC } from 'react'

import { IIcon } from '@assets/types'

export const MsPowerPointIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10.625" cy="10" r="8.75" fill="url(#paint0_linear_11956_266869)" />
      <mask id="mask0_11956_266869" maskUnits="userSpaceOnUse" x="1" y="1" width="19" height="18">
        <circle cx="10.625" cy="10" r="8.75" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_11956_266869)">
        <rect x="11.25" width="10.625" height="10.625" fill="url(#paint1_linear_11956_266869)" />
        <path
          d="M3.75 7.5C3.75 6.46447 4.58947 5.625 5.625 5.625H10.625C11.6605 5.625 12.5 6.46447 12.5 7.5V15C12.5 16.0355 11.6605 16.875 10.625 16.875H3.75V7.5Z"
          fill="black"
          fillOpacity="0.3"
        />
        <rect x="0.625" width="10.625" height="10.625" fill="#EB6C4D" />
      </g>
      <rect y="4.375" width="11.25" height="11.25" rx="1.25" fill="url(#paint2_linear_11956_266869)" />
      <path
        d="M8.125 9.03571C8.125 7.6875 7.23745 6.875 5.82992 6.875H3.75V13.125H5.04098V11.2232H5.77613C7.09401 11.2232 8.125 10.4732 8.125 9.03571ZM6.81609 9.0625C6.81609 9.66964 6.43058 10.0625 5.80302 10.0625H5.04098V8.04464H5.79406C6.42162 8.04464 6.81609 8.375 6.81609 9.0625Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11956_266869"
          x1="1.875"
          y1="11.2069"
          x2="19.375"
          y2="11.2069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A73A24" />
          <stop offset="1" stopColor="#F75936" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11956_266869"
          x1="19.6875"
          y1="6.25"
          x2="11.25"
          y2="6.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB8A3" />
          <stop offset="1" stopColor="#F1876D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11956_266869"
          x1="4.61453e-08"
          y1="10.7759"
          x2="11.25"
          y2="10.7759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A73A24" />
          <stop offset="1" stopColor="#F75936" />
        </linearGradient>
      </defs>
    </svg>
  )
}
