import { User } from '@features/users'

import { StripePlan } from '@shared/types'

export enum UserOrganizationRole {
  owner = 'owner',
  admin = 'admin',
  member = 'member',
}

export enum UserStatus {
  joined = 'Joined',
  pending = 'Pending',
  declined = 'Declined',
}

export enum InviteStatus {
  accepted = 'accepted',
  declined = 'declined',
  request = 'request',
}

export interface Organization {
  id: string
  domain: string
  name: string
  slug?: string
  invites: Invite[]

  stripePlan?: StripePlan
  stripeSubscriptionId?: String

  freeTrialEndDate?: Date
}

export interface OrganizationSetupRequest {
  organizationName: string
}

export interface UserInOrganization {
  organizationId: string
  organization: Organization
  userId: number
  role: UserOrganizationRole
}

export interface Invite {
  id: number
  email: string
  user: User
  userId: number
  organization: Organization
  organizationId: string
  status: InviteStatus
  role: UserOrganizationRole
  createdAt: string
  updatedAt: string
}

export interface OrganizationUser {
  email: string
  status: UserStatus
  role: UserOrganizationRole
  name?: string
  userId?: number
  inviteId?: number
}

export interface ExistingOrganizationUser extends OrganizationUser {
  name: string
  userId: number
}

export interface ActiveOrganizationUser extends OrganizationUser {
  userId: number
  name: string
}
