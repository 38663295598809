import { EDestinationType } from '@features/destinations/types'
import { User } from '@features/users'
import { FilePickerFolderType, FilePickerType } from '@features/users/providers/file-picker-provider'

import { GoogleOAuthScopes, ViewIdOptions } from './types'
import { EDataSourceType } from '@features/data-sources/types/types'

const googleDriveScopes = [GoogleOAuthScopes.DRIVE, GoogleOAuthScopes.DRIVE_FILE]

export const userHasGDriveScope = (user?: User) => {
  return (
    user &&
    user.googleOAuth &&
    user.googleOAuth.scopes?.split(' ').some(scope => googleDriveScopes.includes(scope as GoogleOAuthScopes))
  )
}

export const getGooglePickerViewId = (type?: FilePickerType): ViewIdOptions => {
  if (type === EDestinationType.GoogleDocs) {
    return 'DOCUMENTS'
  }
  if (type === EDestinationType.GoogleSlides) {
    return 'PRESENTATIONS'
  }
  if (type === FilePickerFolderType.GoogleFolder) {
    return 'FOLDERS'
  }
  if (type === EDataSourceType.GoogleSheets) {
    return 'SPREADSHEETS'
  }
  return 'DOCS'
}

export const GOOGLE_DRIVE_SCOPES = [GoogleOAuthScopes.DRIVE_FILE]
