import * as Sentry from '@sentry/react'
import { QueryClient } from 'react-query'

import { ApiErrorToastPayload, apiErrorToast } from '@shared/components/toasts/toasts'
import { parseDuration } from '@shared/utils/time'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: attemptIndex => Math.min(parseDuration('2s') ** attemptIndex, parseDuration('30s')),
      retry: false,
      useErrorBoundary: false,
      cacheTime: parseDuration('1h'),
      staleTime: parseDuration('1h'),
    },
    mutations: {
      retryDelay: attemptIndex => Math.min(parseDuration('2s') ** attemptIndex, parseDuration('30s')),
      retry: false,
      useErrorBoundary: false,
    },
  },
})

export const onError = (error: Error, toastData?: ApiErrorToastPayload) => {
  typeof toastData !== 'undefined' && !toastData.skipToast && apiErrorToast(toastData)
  console.error(error)
  Sentry.captureException(error)
}
