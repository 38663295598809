import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { TableauWorkbook } from '@features/data-sources'
import { TableauCoreDataSource } from '@features/data-sources/types'
import { exactDashboardMargin, isPublicTableau, isRangeFilterObject, useTableau } from '@features/embedding'
import { TableauEmbed } from '@features/embedding/tableau/tableau-embed/tableau-embed'
import { ETableauSizingBehavior } from '@features/embedding/tableau/types'
import { buildJsonFilters } from '@features/syncs/hooks'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { parseDuration } from '@shared/utils/time'

import { SyncEmbedProps, SyncEmbedRef } from './types'

export const SyncEmbedTableau = forwardRef<
  SyncEmbedRef,
  SyncEmbedProps<TableauCoreDataSource, TableauWorkbook>
>(({ dataSource, dashboard, toggleFiltersChanged, setVizSelected }, ref) => {
  const {
    isNewSync,
    tableauData,
    originalSync,
    fullDashboard,
    filtersSelectModal,
    vizSelectModal,
    filtersSelected,
    paramsSelected,
    updateVanityData,
    tableauViewSelectCallback,
    updateSyncData,
    visSelectCallback,
    toggleFullDashboard,
  } = useSyncSettingsContext()

  const [selectedFilterObject, setSelectedFilterObject] = useState<string | null>(null)

  const localTableauData = useTableau({
    dataSource,
    singleSelect: !isNewSync,
    viewSelectCallback: tableauViewSelectCallback,
    visSelectCallback: visSelectCallback,
  })

  const {
    state,
    selectedView,
    viewOptions,
    viewFilters,
    tableauToken,
    filtersLoaded,
    dashboardSize,
    workbookOptions,
    tableauFilterLoading,
    updateState,
    handleSelectView,
    handleSelectWorkbook,
    setCurrentFilters,
    setCurrentMarks,
    setCurrentParams,
  } = localTableauData

  const localFiltersChanged = !isEqual(state.filters, filtersSelected) || !isEqual(state.params, paramsSelected)

  useEffect(() => {
    toggleFiltersChanged?.(localFiltersChanged)
  }, [toggleFiltersChanged, localFiltersChanged])

  useEffect(() => {
    setVizSelected?.(state.selectedSheets.length > 0)
  }, [state.selectedSheets, setVizSelected])

  useImperativeHandle(ref, () => ({
    saveEmbedDataToProvider: () => {
      updateSyncData({
        selectedVis: state.selectedSheets,
        tableauData: {
          tableauCredentialsId: dataSource.id,
          workbookUrl: state.workbookUrl,
          workbookName: state.workbookName,
          workbookContentUrl: state.workbookContentUrl,
          workbookId: state.workbookId,
          viewName: state.viewName,
          viewId: state.viewId,
          viewUrlName: state.viewUrlName,
          viewContentUrl: state.viewContentUrl,
          sheetName: state.workbookName,
          filters: JSON.stringify(state.filters),
          params: JSON.stringify(state.params),
          marks: JSON.stringify(state.marks),
          objectId: state.objectId,
          fullDashboard,
          objectType: state.objectType,
        },
      })
    },
    saveFiltersToContext: () => {
      if (!tableauData) {
        console.log('Error: No tableau data when saving filters to context')
        return
      }

      updateSyncData({
        tableauData: {
          ...tableauData,
          filters: JSON.stringify(state.filters),
          params: JSON.stringify(state.params),
          marks: JSON.stringify(state.marks),
        },
      })
    },
  }))

  const tableauVizToken: { token?: string } =
    isPublicTableau(state.workbookUrl) || !tableauToken ? { token: undefined } : { token: tableauToken! }
  const viewRangeFilters = viewFilters.filter(isRangeFilterObject)

  const fullOverlaySize =
    dashboardSize.behavior === ETableauSizingBehavior.exactly
      ? {
          width: dashboardSize.size.width - exactDashboardMargin,
          height: dashboardSize.size.height - exactDashboardMargin,
        }
      : dashboardSize.size

  const tabIndex = state.viewName ? viewOptions.findIndex(v => v.name === state.viewName) : 0

  const handleSelectViewDebounced = debounce(handleSelectView, parseDuration('1s'))

  const handleShowDateSettingsPopup = (fieldName: string) => {
    if (selectedFilterObject === fieldName) {
      setSelectedFilterObject(null)
    } else {
      setSelectedFilterObject(fieldName)
    }
  }

  useEffect(() => {
    const selectedWorkbook = workbookOptions.find(w => w.id === dashboard.id)

    if (selectedWorkbook) {
      handleSelectWorkbook(selectedWorkbook)
    }
  }, [dashboard.id, workbookOptions, handleSelectWorkbook])

  useEffect(() => {
    if (!filtersSelectModal) {
      return
    }

    const filtersLoading = () => {
      if (tableauFilterLoading) {
        return true
      }
      if (!filtersLoaded) {
        return true
      }
      return false
    }

    updateVanityData({ filtersLoading: filtersLoading() })
  }, [filtersSelectModal, filtersLoaded, tableauFilterLoading, updateVanityData])

  // set useTableau initial state, this should be done in useTableau but I don't want to touch it for now
  useEffect(() => {
    const td = originalSync?.tableauData
    if (td) {
      const { viewId, jsonMarks, viewName, workbookUrl, workbookName, workbookContentUrl, workbookId } = td
      const { jsonFilters, jsonParams } = buildJsonFilters(originalSync)

      updateState({
        viewId,
        params: jsonParams,
        filters: jsonFilters,
        marks: jsonMarks,
        viewName,
        workbookUrl,
        originalWorkbookUrl: workbookUrl,
        workbookName,
        workbookContentUrl,
        workbookId,
      })

      setCurrentParams(jsonParams)
      setCurrentFilters(jsonFilters)
      setCurrentMarks(jsonMarks)
      const currentView = viewOptions.find(v => v.id === viewId)
      if (currentView) {
        localTableauData.handleSelectView(currentView)
      }
    } else {
      if (!tableauData) return

      const { viewId, viewName, workbookUrl, workbookName, workbookContentUrl, workbookId } = tableauData
      const jsonFilters = JSON.parse(tableauData?.filters ?? '{}')
      const jsonParams = JSON.parse(tableauData?.params ?? '{}')

      updateState({
        viewId,
        params: jsonParams,
        filters: jsonFilters,
        viewName,
        workbookUrl,
        workbookId,
        originalWorkbookUrl: workbookUrl,
        workbookName,
        workbookContentUrl,
      })

      setCurrentParams(jsonParams)
      setCurrentFilters(jsonFilters)
      const currentView = viewOptions.find(v => v.id === viewId)
      if (currentView) {
        localTableauData.handleSelectView(currentView)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalSync?.tableauData, viewOptions, tableauData])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectedFilterObject && !event.target.closest(`[itemid=popup]`)) {
        setSelectedFilterObject(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedFilterObject])

  return (
    <TableauEmbed
      disableViewChange={!isNewSync}
      tabIndex={tabIndex}
      tableauData={localTableauData}
      loading={!filtersLoaded}
      showOverlays={vizSelectModal}
      fullDashboard={fullDashboard}
      tableauVizToken={tableauVizToken}
      fullOverlaySize={fullOverlaySize}
      viewRangeFilters={viewRangeFilters}
      selectedFilterObject={selectedFilterObject}
      selectedView={selectedView}
      handleSelectViewDebounced={handleSelectViewDebounced}
      toggleFullDashboard={toggleFullDashboard}
      handleShowDateSettingsPopup={handleShowDateSettingsPopup}
    />
  )
})
