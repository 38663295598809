import classNames from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import { Tooltip } from '@shared/components/tooltip/tooltip'

import styles from './input-button.module.css'

interface InputButtonProps extends PropsWithChildren {
  label: string
  value?: string | null
  inactive?: boolean
  nonEditable?: boolean
  tooltip?: boolean
  placeholder?: string
  onClick?: (e: React.MouseEvent) => void
}

export const InputButton: FC<InputButtonProps> = ({
  value,
  label,
  placeholder,
  inactive = false,
  nonEditable = false,
  tooltip = false,
  children,
  onClick,
}) => {
  const title = value || placeholder
  const inputClass = classNames(styles.inputButton, inactive && styles.inactive, nonEditable && styles.nonEditable)
  const textClass = classNames(styles.title, placeholder && !value && styles.placeholder)

  const handleClick = (e: React.MouseEvent) => {
    if (inactive || nonEditable) return

    onClick?.(e)
  }

  return (
    <div className={styles.container}>
      <span className="text-xs-medium">{label}</span>
      <div onClick={handleClick} className={inputClass}>
        <Tooltip title={title} show={tooltip}>
          <span className={textClass}>{title}</span>
        </Tooltip>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  )
}
