import compact from 'lodash/compact'
import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { GDocsLogo, GSlidesLogo, MsPowerPointLogo, MsWordLogo } from '@assets'
import { EmailDestinationIcon } from '@assets/icons/email-destination'
import { IIcon } from '@assets/types'

import { useAddDestinationFromDrive } from '@features/destinations'
import { AddEmailDestinationModal } from '@features/destinations/components/add-email-destinations-modal/add-email-destinations-modal'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { EDestinationType } from '@features/destinations/types'
import { GOOGLE_DRIVE_SCOPES } from '@features/drive'
import { OneDrivePickerType } from '@features/drive/one-drive/types'
import { OAuthContext, checkUserScopes, getOAuthDetails, useAuthStore } from '@features/users'

import { PageContainer, SelectableOption } from '@shared/components'
import { useBoolean } from '@shared/hooks'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import styles from './add-destination.module.css'

const iconSize = 150
const microsoftIconSize = 50
const emailIconSize = 70

const destinationConfig: Record<
  EDestinationType,
  {
    scope: string[]
    icon: FC<IIcon>
    iconSize: number
  }
> = {
  [EDestinationType.GoogleSlides]: {
    scope: GOOGLE_DRIVE_SCOPES,
    icon: GSlidesLogo,
    iconSize,
  },
  [EDestinationType.GoogleDocs]: {
    scope: GOOGLE_DRIVE_SCOPES,
    icon: GDocsLogo,
    iconSize,
  },
  [EDestinationType.MicrosoftPowerPoint]: {
    scope: [],
    icon: MsPowerPointLogo,
    iconSize: microsoftIconSize,
  },
  [EDestinationType.MicrosoftWord]: {
    scope: [],
    icon: MsWordLogo,
    iconSize: microsoftIconSize,
  },
  [EDestinationType.Email]: {
    scope: [],
    icon: EmailDestinationIcon,
    iconSize: emailIconSize,
  },
}

export const AddDestination: FC = () => {
  const navigate = useNavigate()
  const user = useAuthStore(state => state.user)
  const { showAuthModal, hideAuthModal } = useContext(OAuthContext)

  const { openGDrivePicker, openOneDrivePicker, setOption, option, setIsLoading, isLoading } =
    useAddDestinationFromDrive()

  const [openEmailModal, toggleOpenEmailModal] = useBoolean()

  const handleBack = () => navigate(Paths.BASE)

  const openPicker = async (selectedOption: EDestinationType) => {
    switch (selectedOption) {
      case EDestinationType.GoogleSlides:
      case EDestinationType.GoogleDocs:
        const customViews = selectedOption ? destinationHandlers(selectedOption).getGPickerCustomViews() : []
        await openGDrivePicker({ customScopes: GOOGLE_DRIVE_SCOPES, customViews })
        break
      case EDestinationType.MicrosoftPowerPoint:
        openOneDrivePicker(OneDrivePickerType.PowerPoint)
        break
      case EDestinationType.MicrosoftWord:
        openOneDrivePicker(OneDrivePickerType.Word)
        break
      case EDestinationType.Email:
        setIsLoading(false)
        toggleOpenEmailModal()
    }
  }

  const handleNext = (option: EDestinationType) => {
    setIsLoading(true)
    const { oAuthType } = getOAuthDetails(option)
    const userHasScopes = checkUserScopes(oAuthType, user)
    if (oAuthType && !userHasScopes) {
      showAuthModal({
        type: oAuthType,
        successCallback: () => {
          openPicker(option)
        },
        handleClose: () => {
          setIsLoading(false)
          setOption(null)
          hideAuthModal()
        },
      })
    } else {
      openPicker(option)
    }
  }

  const OptionButton = ({ type }: { type: EDestinationType }) => {
    const config = destinationConfig[type]
    return (
      <SelectableOption
        className={styles.option}
        selected={option === type}
        loading={isLoading && option === type}
        disabled={isLoading}
        onClick={() => {
          setOption(type)
          handleNext(type)
        }}
        dataTestId={type}
      >
        <config.icon size={config.iconSize} />
      </SelectableOption>
    )
  }

  const activeDestinationTypes = compact([
    EDestinationType.GoogleSlides,
    EDestinationType.GoogleDocs,
    EDestinationType.MicrosoftPowerPoint,
    EDestinationType.MicrosoftWord,
    isFeatureEnabled('emails-as-a-destination') ? EDestinationType.Email : null,
  ])

  return (
    <PageContainer back={handleBack} title="Add destination" className={styles.pageContainer}>
      <AddEmailDestinationModal
        open={openEmailModal}
        onClose={() => {
          toggleOpenEmailModal()
          setOption(null)
        }}
      />
      <div className={styles.view}>
        <div className={styles.options}>
          {activeDestinationTypes.map(type => (
            <OptionButton key={type} type={type} />
          ))}
        </div>
      </div>
    </PageContainer>
  )
}
