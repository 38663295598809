import { FC, useState } from 'react'

import { PlusIcon } from '@assets'
import { UsersPlusIcon } from '@assets/icons/users'

import { useInviteOrganizationUser } from '@features/organizations/api'
import { useOrganizationsStore } from '@features/organizations/organizations-store'
import { UserOrganizationRole } from '@features/organizations/types'
import { getAvailableRoleOptions } from '@features/users'

import { ActionModal, Button, Input, SelectInput, addMemberToOrgToast } from '@shared/components'
import { Colors } from '@shared/constants'
import { queryClient } from '@shared/http/query-client'
import { isValidEmail } from '@shared/utils/utils'

import styles from './invite-members-modal.module.css'

interface IInviteMembersModalProps {
  adminInvite?: boolean
  toggleModal: () => void
}

interface EmailRolePair {
  email: string
  role: UserOrganizationRole
}

export const InviteMembersModal: FC<IInviteMembersModalProps> = ({ adminInvite = false, toggleModal }) => {
  const defaultRole = adminInvite ? UserOrganizationRole.admin : UserOrganizationRole.member
  const { activeOrganization, isAdmin, isOwner } = useOrganizationsStore()
  const [emailList, setEmailList] = useState<EmailRolePair[]>([{ email: '', role: defaultRole }])
  const { mutateAsync: inviteUser, isLoading } = useInviteOrganizationUser()

  const roleOptions = getAvailableRoleOptions(isAdmin || isOwner)

  const title = `${adminInvite ? 'Invite an admin to' : 'Invite others to'} ${
    activeOrganization?.organization.name
  } organization`

  const subtitle = adminInvite
    ? 'Invite your data source admin to Rollstack.'
    : 'Invite others to collaborate together in Rollstack.'

  const sendInviteEnabled = emailList.some(item => isValidEmail(item.email))

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const newEmailList = [...emailList]
    newEmailList[idx].email = e.target.value
    setEmailList(newEmailList)
  }

  const handleRoleChange = (role: UserOrganizationRole, idx: number) => {
    const newEmailList = [...emailList]
    newEmailList[idx].role = role
    setEmailList(newEmailList)
  }

  const handleInputClose = (idx: number) => {
    setEmailList(emailList.filter((_, i) => i !== idx))
  }

  const addEmail = () => {
    setEmailList([...emailList, { email: '', role: defaultRole }])
  }

  const sendInvites = async () => {
    if (!activeOrganization) return

    const organizationId = activeOrganization.organization.id
    await Promise.all(
      emailList.map(
        item => isValidEmail(item.email) && inviteUser({ email: item.email, organizationId, role: item.role })
      )
    )

    queryClient.invalidateQueries(['organizationUsers'])
    toggleModal()
    addMemberToOrgToast()
  }

  return (
    <ActionModal
      open={true}
      Icon={UsersPlusIcon}
      title={title}
      subtitle={subtitle}
      onBackgroundClick={toggleModal}
      btns={
        <>
          <Button text="Cancel" secondaryGray fullWidth onClick={toggleModal} />
          <Button
            fullWidth
            autoFocus
            text="Send invite"
            disabled={!sendInviteEnabled}
            loading={isLoading}
            onClick={sendInvites}
          />
        </>
      }
    >
      <div className={styles.inputsContainer}>
        {emailList.map((item, idx) => (
          <div key={idx} className={styles.input}>
            <Input
              value={item.email}
              autoFocus={idx === 0}
              label={idx === 0 ? 'Email address' : undefined}
              onChange={e => handleEmailChange(e, idx)}
              onCrossClick={emailList.length > 1 ? () => handleInputClose(idx) : undefined}
            />
            <SelectInput
              isDisabled={adminInvite}
              value={roleOptions.find(option => option.value === item.role)}
              label={idx === 0 ? 'Role' : undefined}
              className={styles.role}
              onChange={option => handleRoleChange(option.value, idx)}
              options={roleOptions}
            />
          </div>
        ))}
      </div>
      {!adminInvite && (
        <Button
          secondaryColor
          fullWidth
          text="Add another email address"
          className={styles.addEmailBtn}
          iconLeading={<PlusIcon color={Colors.primary700} />}
          onClick={addEmail}
        />
      )}
    </ActionModal>
  )
}
