import { FC } from 'react'

import { IIcon } from '@assets/types'

export const XCircle: FC<IIcon> = ({ color = '#E11A0C' }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
      <g id="x-circle">
        <path
          id="Icon"
          d="M12.5003 8.17513L7.50033 13.1751M7.50033 8.17513L12.5003 13.1751M18.3337 10.6751C18.3337 15.2775 14.6027 19.0085 10.0003 19.0085C5.39795 19.0085 1.66699 15.2775 1.66699 10.6751C1.66699 6.07276 5.39795 2.3418 10.0003 2.3418C14.6027 2.3418 18.3337 6.07276 18.3337 10.6751Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
