import * as Sentry from '@sentry/react'
import { usePostHog } from 'posthog-js/react'
import { FC, PropsWithChildren, useEffect, useMemo } from 'react'

import { useOrganizationsStore } from '@features/organizations'
import { OrganizationJoined } from '@features/organizations/components/organization-joined/organization-joined'
import { OrganizationSetup } from '@features/organizations/components/organization-setup/organization-setup'
import { AuthStatus, useSaml } from '@features/users'
import { useAuth0 } from '@features/users'
import { EOnboardingStep } from '@features/users'
import { useAuthStore } from '@features/users'

import { Spinner } from '@shared/components/spinner/spinner'
import { initializeBeamer } from '@shared/product-tooling/beamer/beamer'
import { useHubSpotTracking } from '@shared/product-tooling/hubspot/hubspot'
import { initializePendo } from '@shared/product-tooling/pendo/pendo'
import { constructPersonProperties } from '@shared/product-tooling/posthog/posthog'

import { LoginPage } from './login/login-page/login-page'

const waiters = [
  '@matik.io',
  '@leverslabs.com',
  'waseem.akram@getcakewalktest.com',
  '@google.com',
  '@miri.ai',
  '@miri.health',
]

export const AppGate: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading: isLoadingAuth0 } = useAuth0()
  const { authStatus, isLoading: isLoadingSaml } = useSaml()
  const { user, isLoggingOut } = useAuthStore()
  const { activeOrganization } = useOrganizationsStore()
  const { identifyUser: identifyHubspotUser } = useHubSpotTracking()

  const posthog = usePostHog()

  const isLoading = useMemo(() => isLoadingAuth0 || isLoadingSaml, [isLoadingAuth0, isLoadingSaml])

  useEffect(() => {
    if (!user) {
      Sentry.setUser(null)
      return
    }

    if (posthog) {
      const userDataForPosthog = constructPersonProperties(user)
      posthog.identify(user.email, userDataForPosthog)
      posthog.people.set(userDataForPosthog)
      posthog.setPersonPropertiesForFlags(userDataForPosthog)
      posthog.capture('open application')
    }

    identifyHubspotUser(user)
    initializeBeamer(user)
    initializePendo(user, activeOrganization)

    Sentry.setUser({
      id: user.id.toString(),
      email: user.email,
      username: user.name,
    })
  }, [user, activeOrganization, posthog, identifyHubspotUser])

  if (isLoggingOut || isLoading || authStatus === AuthStatus.Fetching) {
    return <Spinner />
  }

  if (!user || !user.tokens) {
    return <LoginPage />
  }

  if (user && waiters.some(waiter => user.email.includes(waiter))) {
    return <Spinner />
  }

  if (
    (!user.organizations || !user.organizations.length) &&
    (!user.onboardingStepsCompleted ||
      (!user.onboardingStepsCompleted.includes(EOnboardingStep.organizationSetup) &&
        !user.onboardingStepsCompleted.includes(EOnboardingStep.acceptOrganizationInvite)))
  ) {
    return <OrganizationSetup email={user.email} />
  }

  if (activeOrganization && activeOrganization.organization && user.firstLogin) {
    return <OrganizationJoined organizationName={activeOrganization.organization.name} />
  }

  return <>{children}</>
}
