import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Paths, buildDataSourceSettingsRoute, buildUserManagementRoute } from '@pages/routes/paths'

import { FilterIcon, KeyIcon, PlusIcon, SearchIconMd } from '@assets'

import {
  DataSourcesTutorial,
  EmptyDataSources,
  MetabasePasswordModal,
  useDeleteLookerDataSource,
  useDeleteMetabaseDataSource,
  useDeleteSpreadsheetsFile,
  useDeleteTableauDataSource,
  useGetDataSources,
  useRequestDataSource,
} from '@features/data-sources'
import { CoreDataSource, EDataSourceType } from '@features/data-sources/types/types'
import { useOrganizationsStore } from '@features/organizations'
import { useAuthStore } from '@features/users'

import { ActionModal, Button, Input, PageContainer, deleteDataSourceToast } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { FilterOrStorterDropdown } from '@shared/components/filter-or-sorter-dropdown/filter-or-sorter-dropdown'
import { useBoolean } from '@shared/hooks/use-boolean'
import { useModalWithData } from '@shared/hooks/use-modal-with-data'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import { dataSourcesTableColumns, isAdminCredentials } from './data-sources-table'

type DeletionData = {
  id: string
  type: EDataSourceType
}

interface TypeOption {
  label: string
  value: EDataSourceType | undefined
}

const typeOptions: TypeOption[] = [
  { label: 'All', value: undefined },
  { label: 'Looker', value: EDataSourceType.Looker },
  { label: 'Metabase', value: EDataSourceType.Metabase },
  { label: 'Tableau', value: EDataSourceType.Tableau },
  { label: 'Google Sheets', value: EDataSourceType.GoogleSheets },
]

const SUCCESS_HTTP_STATUS_CODE_LIMIT = 400

export const DataSources: FC = () => {
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const { isMember } = useOrganizationsStore()
  const [modal, deletionData, toggleModal, openModalWithData] = useModalWithData<DeletionData>()
  const [dataSourcesModal, toggleDataSourcesModal] = useBoolean()
  const [dsRequestsExpiries, setDSRequestsExpiries] = useState<Record<string, string>>({})

  const [selectedType, setSelectedType] = useState<TypeOption>(typeOptions[0])
  const [passwordModal, dataSourceToUpdate, togglePasswordModal, openPasswordModalWithData] =
    useModalWithData<CoreDataSource | null>()

  const { data: dataSources, isLoading } = useGetDataSources()
  const [searchQuery, setSearchQuery] = useState('')

  const deleteTableauDataSourceQuery = useDeleteTableauDataSource()
  const deleteLookerDataSourceQuery = useDeleteLookerDataSource()
  const deleteMetabaseDataSourceQuery = useDeleteMetabaseDataSource()
  const deleteSpreadsheetsFileQuery = useDeleteSpreadsheetsFile()
  const { mutateAsync: requestDataSourceAccess, isLoading: requestAccessLoading } = useRequestDataSource()

  const isDeleteLoading = useMemo(
    () =>
      deleteTableauDataSourceQuery.isLoading ||
      deleteLookerDataSourceQuery.isLoading ||
      deleteMetabaseDataSourceQuery.isLoading ||
      deleteSpreadsheetsFileQuery.isLoading,
    [
      deleteTableauDataSourceQuery.isLoading,
      deleteLookerDataSourceQuery.isLoading,
      deleteMetabaseDataSourceQuery.isLoading,
      deleteSpreadsheetsFileQuery.isLoading,
    ]
  )

  const filteredDataSources = useMemo(() => {
    if (!dataSources) return
    return dataSources
      .filter(dataSource => dataSource.name.toLowerCase()?.includes(searchQuery.toLowerCase()))
      .filter(dataSource => selectedType.label === 'All' || dataSource.type === selectedType.value)
  }, [dataSources, searchQuery, selectedType])

  useEffect(() => {
    if (!dataSources) return

    dataSources.forEach(ds => {
      const expiryDate = localStorage.getItem(`dataSourcesAccessRequestExpiry/${ds.id}`)

      if (expiryDate) {
        setDSRequestsExpiries(prev => ({ ...prev, [ds.id]: expiryDate }))
      }
    })
  }, [dataSources])

  const handleQueryChange = (query: string) => {
    setSearchQuery(query)
  }

  const handleTypeChange = (option: TypeOption) => {
    setSelectedType(option)
  }

  useEffect(() => {
    if (user?.showDataSourcesModal) {
      toggleDataSourcesModal(true)
    }
  }, [user, toggleDataSourcesModal])

  const requestDSAccess = async (dataSourceId: string) => {
    const res = await requestDataSourceAccess(dataSourceId)
    setDSRequestsExpiries(prev => ({ ...prev, [dataSourceId]: res.data.expiresAt }))
  }

  const deleteDataSource = async () => {
    if (!deletionData) {
      return
    }

    const { type, id } = deletionData

    let status: number

    switch (type) {
      case EDataSourceType.Tableau:
        const tableauResult = await deleteTableauDataSourceQuery.mutateAsync(id)
        status = tableauResult.status
        break
      case EDataSourceType.Looker:
        const lookerResult = await deleteLookerDataSourceQuery.mutateAsync(id)
        status = lookerResult.status
        break
      case EDataSourceType.Metabase:
        const metabaseResult = await deleteMetabaseDataSourceQuery.mutateAsync(id)
        status = metabaseResult.status
        break
      case EDataSourceType.GoogleSheets:
        const spreadsheetsResult = await deleteSpreadsheetsFileQuery.mutateAsync(id)
        status = spreadsheetsResult.status
        break
    }
    if (status < SUCCESS_HTTP_STATUS_CODE_LIMIT) {
      toggleModal()
      deleteDataSourceToast()
    }
  }

  const openUserManagement = (ds: CoreDataSource) => () => navigate(buildUserManagementRoute(ds.type, ds.id))
  const openSettings = (ds: CoreDataSource) => () => navigate(buildDataSourceSettingsRoute(ds.type, ds.id))
  const hasAdminCredentials = useMemo(() => {
    return (dataSources ?? []).filter(ds => isAdminCredentials(ds)).length > 0
  }, [dataSources])

  if (!dataSources) return <PageContainer loading />

  const mainView =
    !filteredDataSources?.length || !user ? (
      <EmptyDataSources />
    ) : (
      <DataGrid
        columns={dataSourcesTableColumns({
          memberView: !hasAdminCredentials,
          dsRequestsExpiries,
          requestAccessLoading,
          requestDSAccess,
          openUserManagement,
          openSettings,
          openModalWithData,
          openPasswordModalWithData,
        })}
        data={filteredDataSources}
        enablePagination
      />
    )

  return (
    <PageContainer
      hideBackButton
      title="Data sources"
      rightActionButtons={
        (!isMember || isFeatureEnabled('sheets-data-source')) && (
          <Link to={Paths.ADD_DATA_SOURCE}>
            <Button text="Add data source" iconLeading={<PlusIcon color="white" />} />
          </Link>
        )
      }
      subtitle="Manage and update your data sources"
      loading={isLoading}
    >
      <section className="flex justify-start flex-wrap items-center gap-4">
        <FilterOrStorterDropdown
          icon={<FilterIcon />}
          label="Type"
          options={typeOptions}
          selectedOption={selectedType}
          onSelect={handleTypeChange}
        />
        <Input
          icon={<SearchIconMd />}
          onChange={e => {
            handleQueryChange(e.target.value)
          }}
          placeholder="Search"
        />
      </section>
      <ActionModal
        open={modal}
        title="Delete data source"
        subtitle="Your data source will be deleted"
        onBackgroundClick={toggleModal}
        btns={
          <>
            <Button secondaryGray text="Back" onClick={toggleModal} fullWidth />
            <Button primaryError text="Delete" onClick={deleteDataSource} loading={isDeleteLoading} fullWidth />
          </>
        }
      />
      {passwordModal && dataSourceToUpdate && (
        <MetabasePasswordModal
          open={passwordModal}
          Icon={KeyIcon}
          title={`${dataSourceToUpdate.user?.name || 'User'}'s credentials`}
          dataSource={dataSourceToUpdate}
          toggle={togglePasswordModal}
        />
      )}
      <DataSourcesTutorial visible={dataSourcesModal} setModalOpen={toggleDataSourcesModal} />
      {mainView}
    </PageContainer>
  )
}
