import { Button } from '@shared/components/button/button'

import styles from './data-out-of-date-toast.module.css'

export const DataOutOfDateToast = ({
  title = 'Your data is out of sync',
  message = 'Please click the data below to refresh it, or refresh the page',
  handleDataRefresh,
}: {
  title?: string
  message?: string
  handleDataRefresh: Function
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>
        <span>{message}</span>
        <Button onClick={() => handleDataRefresh()} text="Refresh" secondaryGray />
      </div>
    </div>
  )
}
