import clsx from 'clsx'
import { useState } from 'react'

import { CollectionVariantType, ICollectionVariant } from '@features/collections/types'

import { ActionModal, Button, SelectableOption, Switch } from '@shared/components'

interface Props {
  open: boolean
  closeModal: () => void
  collectionVariants: ICollectionVariant[]
  onUpdateSelected: (variantIds: string[]) => void
}

export const UpdateVariantsModal = ({ open, closeModal, collectionVariants = [], onUpdateSelected }: Props) => {
  const template = collectionVariants.find(v => v.type === CollectionVariantType.template) as ICollectionVariant

  const [selectedVariants, setSelectedVariants] = useState<Set<string>>(new Set([template.id]))

  const addVariant = (variantId: string) => {
    selectedVariants.add(variantId)
  }

  const toggleSelection = (variantId: string) => {
    if (selectedVariants.has(variantId)) {
      selectedVariants.delete(variantId)
    } else {
      addVariant(variantId)
    }
    setSelectedVariants(new Set(selectedVariants))
  }

  const onSelectAll = (newValue: boolean) => {
    if (newValue) {
      for (const variant of collectionVariants) {
        selectedVariants.add(variant.id)
      }
      setSelectedVariants(new Set(selectedVariants))
    } else {
      setSelectedVariants(new Set())
    }
  }

  const renderVariantCard = (variant: ICollectionVariant) => {
    return (
      <SelectableOption
        key={variant.id}
        className="max-w-[180px]"
        selected={selectedVariants.has(variant.id)}
        onClick={() => toggleSelection(variant.id)}
      >
        {variant.code}
      </SelectableOption>
    )
  }

  return (
    <ActionModal open={open} title="Update variants" onBackgroundClick={() => closeModal()} className="min-w-[500px]">
      <div className={clsx('flex flex-col gap-4', 'divide-y')}>
        <div className="flex flex-col gap-4">
          <p className="text-gray-600">Select the variants you want to update</p>
          <span className="w-full flex justify-between">
            <div></div>
            <span className={clsx('flex gap-2', 'text-gray-700')}>
              <Switch value={selectedVariants.size === collectionVariants.length} onChange={onSelectAll} />
              Select/Unselect all
            </span>
          </span>
          <div
            className={clsx(
              'h-full max-h-[460px] overflow-y-auto',
              'py-2 px-1',
              'grid grid-cols-2 gap-3 justify-items-center'
            )}
          >
            {collectionVariants.length > 0 && renderVariantCard(template)}
            {collectionVariants.filter(v => v.type === CollectionVariantType.variant).map(renderVariantCard)}
          </div>
        </div>
        <span className="pt-4 flex justify-between">
          <Button secondaryGray text="Cancel" onClick={() => closeModal()} />
          <Button
            text="Update selected"
            disabled={selectedVariants.size === 0}
            onClick={() => {
              onUpdateSelected(Array.from(selectedVariants))
              closeModal()
            }}
          />
        </span>
      </div>
    </ActionModal>
  )
}
