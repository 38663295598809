import { UsersPlusIcon } from '@assets/icons/users'

import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'

export const ShareItem = (props: MenuItemProps) => {
  return (
    <MenuItem {...props}>
      <UsersPlusIcon />
      {'Share'}
    </MenuItem>
  )
}
