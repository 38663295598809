import { useDuplicateSync } from '@features/syncs/api'

interface IUseSyncDuplicate {
  destinationId?: string
  collectionId?: string
}

export const useSyncDuplicate = ({ destinationId, collectionId }: IUseSyncDuplicate) => {
  const duplicateSyncQuery = useDuplicateSync({ destinationId, collectionId })

  const duplicateSyncs = async (syncIds: string[] | string) => {
    const syncIdsArray = Array.isArray(syncIds) ? syncIds : [syncIds]
    const responses = await Promise.all(syncIdsArray.map(syncId => duplicateSyncQuery.mutateAsync(syncId)))

    const newSyncIds = responses
      .map(response => {
        if (Array.isArray(response)) {
          return response.map(sync => sync.id)
        }
        return response.id
      })
      .flat()

    return newSyncIds
  }

  return { duplicateSyncs, isDuplicating: duplicateSyncQuery.isLoading }
}
