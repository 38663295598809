import { FC } from 'react'

import { IIcon } from '@assets/types'

export const GSheetsIcon: FC<IIcon> = () => {
  return (
    <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.43204 0L14.3725 4.94424L11.9023 5.39371L9.43204 4.94424L8.98291 2.47212L9.43204 0Z" fill="#188038" />
      <path
        d="M9.43174 4.94424V0H1.34739C0.602958 0 0 0.603422 0 1.34843V18.4285C0 19.1735 0.602958 19.777 1.34739 19.777H13.0248C13.7692 19.777 14.3722 19.1735 14.3722 18.4285V4.94424H9.43174Z"
        fill="#34A853"
      />
      <path
        d="M2.69482 7.64062V14.158H11.6774V7.64062H2.69482ZM6.62472 13.0343H3.81765V11.4612H6.62472V13.0343ZM6.62472 10.3375H3.81765V8.76432H6.62472V10.3375ZM10.5546 13.0343H7.74754V11.4612H10.5546V13.0343ZM10.5546 10.3375H7.74754V8.76432H10.5546V10.3375Z"
        fill="white"
      />
    </svg>
  )
}
