import { FC, PropsWithChildren } from 'react'

import { cn } from '@shared/utils'

interface Props extends PropsWithChildren {
  className?: string
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  regular?: boolean
  medium?: boolean
  semibold?: boolean
  bold?: boolean
}
export const Label: FC<Props> = ({ children, className, xs, sm, md, lg, regular, medium, semibold, bold }) => {
  const fontSizeAndWeight = cn(
    xs && 'text-xs',
    sm && 'text-sm',
    md && 'text-md',
    lg && 'text-lg',
    regular && 'font-regular',
    medium && 'font-medium',
    semibold && 'font-semibold',
    bold && 'font-bold'
  )
  return <span className={cn(fontSizeAndWeight, 'text-gray-700', 'block', className)}>{children}</span>
}
