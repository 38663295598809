import { WarningInfoIcon } from '@assets'

import { EDestinationType } from '@features/destinations/types'
import { isMicrosoftDestination } from '@features/destinations/utils'

import { ActionModal, Button } from '@shared/components'
import { Colors } from '@shared/constants'

export const MicrosoftLockWarning = ({
  open,
  onClose,
  onOk,
  processing,
  type,
}: {
  open: boolean
  onClose: () => void
  onOk: () => void
  processing?: boolean
  type?: EDestinationType
}) => {
  if (!type) return null

  const isMicrosoft = isMicrosoftDestination({ type })
  if (!isMicrosoft) return null

  const isPowerPoint = type === EDestinationType.MicrosoftPowerPoint

  return (
    <ActionModal
      open={open}
      title={`${isPowerPoint ? 'PowerPoint presentation' : 'Word document'} update`}
      subtitle={`Your ${isPowerPoint ? 'presentation' : 'document'} needs to be closed for Rollstack to perform updates including scheduled updates. Please make sure your ${isPowerPoint ? 'presentation' : 'document'} is closed on other tabs.`}
      onBackgroundClick={onClose}
      Icon={() => <WarningInfoIcon color={Colors.gray700} />}
      processing={processing}
      btns={
        <>
          <Button text="Ok" onClick={onOk} fullWidth />
        </>
      }
    />
  )
}
