import { FC } from 'react'

import { IIcon } from '@assets/types'

export const FancyTick: FC<IIcon> = ({ color = 'white' }) => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40568 0.734432L3.33401 5.62902L1.98818 4.1911C1.74026 3.95735 1.35068 3.94318 1.06734 4.14152C0.791094 4.34693 0.713177 4.70818 0.883177 4.9986L2.47693 7.5911C2.63276 7.83193 2.90193 7.98068 3.20651 7.98068C3.49693 7.98068 3.77318 7.83193 3.92901 7.5911C4.18401 7.25818 9.05026 1.45693 9.05026 1.45693C9.68776 0.805265 8.91568 0.231515 8.40568 0.727349V0.734432Z"
        fill={color}
      />
    </svg>
  )
}
