import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 20

export const ActivitySyncScheduledIcon: FC<Pick<IIcon, 'size'>> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M15.0444 8.74459C14.8129 10.9195 13.5806 12.9576 11.5414 14.1349C8.15352 16.0909 3.82142 14.9301 1.86542 11.5422L1.65708 11.1814M0.955179 7.25651C1.18668 5.0816 2.41901 3.04349 4.45816 1.86618C7.84607 -0.0898234 12.1782 1.07096 14.1342 4.45887L14.3425 4.81971M0.911011 13.0555L1.52105 10.7788L3.79776 11.3888M12.2019 4.61222L14.4786 5.22226L15.0886 2.94555M7.99983 4.25053V8.00053L10.0832 9.25053"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
