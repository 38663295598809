import styles from '@features/destinations/components/destination-sending/destination-sending.module.css'
import { CropOption } from '@features/syncs/types'

import { SelectableOption } from '@shared/components'

interface Props {
  cropOption: CropOption
  setCropOption: (cropOption: CropOption) => void
}

export const DestinationCropOptionSelect = ({ cropOption, setCropOption }: Props) => {
  return (
    <>
      <SelectableOption
        className={styles.option}
        selected={cropOption === CropOption.None}
        onClick={() => setCropOption(CropOption.None)}
      >
        <div className={styles.optionWithoutDescription}>No Crop</div>
      </SelectableOption>

      <SelectableOption
        className={styles.option}
        selected={cropOption === CropOption.Auto}
        onClick={() => setCropOption(CropOption.Auto)}
      >
        <div className={styles.optionWithoutDescription}>Automatic Cropping</div>
      </SelectableOption>
    </>
  )
}
