import { FC } from 'react'

import { IIcon } from '@assets/types'

export const StarsIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.75002 18.3333V14.1666M3.75002 5.83329V1.66663M1.66669 3.74996H5.83335M1.66669 16.25H5.83335M10.8334 2.49996L9.38821 6.25734C9.1532 6.86837 9.03569 7.17388 8.85296 7.43086C8.69101 7.65862 8.49202 7.85762 8.26426 8.01957C8.00728 8.2023 7.70176 8.3198 7.09074 8.55481L3.33335 9.99996L7.09074 11.4451C7.70176 11.6801 8.00728 11.7976 8.26426 11.9804C8.49202 12.1423 8.69101 12.3413 8.85296 12.5691C9.03569 12.826 9.1532 13.1315 9.38821 13.7426L10.8334 17.5L12.2785 13.7426C12.5135 13.1315 12.631 12.826 12.8137 12.5691C12.9757 12.3413 13.1747 12.1423 13.4024 11.9804C13.6594 11.7976 13.9649 11.6801 14.576 11.4451L18.3334 9.99996L14.576 8.55481C13.9649 8.3198 13.6594 8.2023 13.4024 8.01957C13.1747 7.85762 12.9757 7.65862 12.8137 7.43086C12.631 7.17388 12.5135 6.86837 12.2785 6.25734L10.8334 2.49996Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
