import { FC } from 'react'
import { useParams } from 'react-router-dom'

import {
  AddChildDataSourceModal,
  TestTableauConnectionModal,
  useDeleteTableauDataSource,
  useGetTableauDataSource,
  useManageDefaultDataSource,
} from '@features/data-sources'

import { UserManagement } from './user-management'

export const TableauUserManagement: FC = () => {
  const { sourceId } = useParams()

  return (
    <UserManagement
      sourceId={sourceId!}
      useGetDataSource={useGetTableauDataSource}
      useDeleteDataSource={useDeleteTableauDataSource}
      useManageDefaultDataSource={useManageDefaultDataSource}
      TestModalComponent={TestTableauConnectionModal}
      AddChildDataSourceModalComponent={AddChildDataSourceModal}
    />
  )
}
