import { FC, useEffect, useState } from 'react'

import { CopyIcon } from '@assets'
import { TickIcon } from '@assets/icons/ticks'

import { Button, IButton, Tooltip } from '@shared/components'

import styles from './copy-button.module.css'

interface IIconWrapperProps {
  copied: boolean
}

interface ICopyButtonProps extends IButton {
  value?: string
}

const COPY_TRANSITION_TIME = 3000

export const CopyButton: FC<ICopyButtonProps> = ({ value, ...props }) => {
  const [localCopied, setLocalCopied] = useState(false)

  const copy = (value: string) => {
    navigator.clipboard.writeText(value)
  }

  const handleClick = () => {
    value && copy(value)
    setLocalCopied(true)
  }

  useEffect(() => {
    if (localCopied) {
      const timeout = setTimeout(() => setLocalCopied(false), COPY_TRANSITION_TIME)
      return () => clearTimeout(timeout)
    }
  }, [localCopied])

  const IconWrapper: FC<IIconWrapperProps> = ({ copied }) => {
    const tooltipProps = copied
      ? { title: 'Copied!', children: <TickIcon size={15} /> }
      : { title: 'Copy to clipboard', children: <CopyIcon /> }

    return (
      <div className={styles.iconContainer}>
        <Tooltip title={tooltipProps.title} position="top">
          <div className={styles.iconTransition}>{tooltipProps.children}</div>
        </Tooltip>
      </div>
    )
  }

  return <Button onClick={handleClick} iconLeading={<IconWrapper copied={localCopied} />} tertiaryColor {...props} />
}
