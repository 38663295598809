import dayjs from 'dayjs'

const ONE_SECOND_IN_MS = 1000
const SIXTY = 60

export function parseDuration(durationStr: string): number {
  const multiplier: { [unit: string]: number } = {
    s: ONE_SECOND_IN_MS,
    m: SIXTY * ONE_SECOND_IN_MS,
    h: SIXTY * SIXTY * ONE_SECOND_IN_MS,
  }

  if (!durationStr) {
    return 0
  }

  const unit = durationStr.slice(-1).toLowerCase()
  if (multiplier.hasOwnProperty(unit)) {
    return parseInt(durationStr.slice(0, -1), 10) * multiplier[unit]
  }

  // If the format is incorrect or unsupported, return 0 or throw an exception
  return 0
}

export function formatDate(date: Date, format = 'll LT'): string {
  return dayjs(date).format(format)
}

interface ObjectWithCreatedAt {
  createdAt: string
}

export const sortByCreatedAt = (a: ObjectWithCreatedAt, b: ObjectWithCreatedAt) =>
  dayjs(a.createdAt).isBefore(b.createdAt) ? -1 : 1
