import { FC, useMemo } from 'react'

import { SendRightIcon } from '@assets'

import { useSendCollectionEmail } from '@features/collections/api/api'
import { ICollectionEmailConfig } from '@features/collections/types'

import { ActionModal, Button, Tooltip, successToast } from '@shared/components'
import { mapPermissionTypeToLabel } from '@shared/utils/sending'

import styles from './collection-sending-confirm-modal.module.css'

export interface CollectionSendingConfirmModalProps {
  collectionId: string
  templateName: string
  collectionEmailConfig: ICollectionEmailConfig
  isOpen: boolean
  onClose: () => void
}

export const CollectionSendingConfirmModal: FC<CollectionSendingConfirmModalProps> = ({
  collectionId,
  templateName,
  collectionEmailConfig,
  isOpen,
  onClose,
}) => {
  const { mutate: sendEmail, isLoading } = useSendCollectionEmail(collectionId, {
    onSuccess: () => {
      successToast('Collection sent')
      onClose()
    },
  })

  const groupedRecipients = useMemo(() => {
    return collectionEmailConfig.recipientConfig.reduce<Record<string, string[]>>((acc, recipientConfig) => {
      const { recipient_email: recipient, variant_name: variantName } = recipientConfig
      if (!acc[variantName]) {
        acc[variantName] = []
      }
      acc[variantName].push(recipient)
      return acc
    }, {})
  }, [collectionEmailConfig])

  const permissionType = useMemo(() => {
    return mapPermissionTypeToLabel(collectionEmailConfig.sharingPermission)
  }, [collectionEmailConfig])

  const onSend = () => {
    sendEmail({})
  }

  return (
    <ActionModal
      open={isOpen}
      title="Send collection"
      subtitle="Are you sure you want to send these documents to these recipients?"
      Icon={SendRightIcon}
      btns={[
        <Button key="cancel" secondaryGray fullWidth text="Cancel" onClick={onClose} />,
        <Button
          key="send"
          fullWidth
          text="Send now"
          iconLeading={<SendRightIcon />}
          onClick={onSend}
          disabled={isLoading || !collectionEmailConfig}
          loading={isLoading}
        >
          Send
        </Button>,
      ]}
      onBackgroundClick={onClose}
    >
      <section className={styles.container}>
        <div className={styles.row}>
          <label className={styles.label}>Recipients</label>
          <div className={styles.content}>
            <div className={styles.variantsContainer}>
              {Object.keys(groupedRecipients).map((variant, idx) => (
                <div key={idx} className={styles.variantsRow}>
                  <Tooltip show={false} showOnOverflow title={variant}>
                    <span className={styles.variantLabel}>{variant}</span>
                  </Tooltip>
                  <Tooltip show={false} showOnOverflow title={groupedRecipients[variant].join(', ')}>
                    <span className={styles.variantRecipients}>{groupedRecipients[variant].join(', ')}</span>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <label className={styles.label}>Template</label>
          <div className={styles.content}>
            <p>{templateName}</p>
          </div>
        </div>
        <div className={styles.row}>
          <label className={styles.label}>Permission type</label>
          <div className={styles.content}>
            <p>{permissionType}</p>
          </div>
        </div>
      </section>
    </ActionModal>
  )
}
