import httpStatus from 'http-status'
import { TypeOptions } from 'react-toastify'

import { dataOutOfDateToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import { ApiError, onError, queryClient } from '@shared/http'
import { ApiErrorHandlerFn, ApiErrorHandlerParams } from '@shared/http/types'

export const handlePresentationUpdateError: ApiErrorHandlerFn = (apiError: ApiError) => {
  if (apiError.statusCode === httpStatus.CONFLICT) {
    dataOutOfDateToast({
      handleDataRefresh: () => {
        if (apiError.metadata.id) {
          queryClient.invalidateQueries(['presentation', { destinationId: apiError.metadata.id }])
          queryClient.invalidateQueries(['presentations'])
        }
      },
    })
    return true
  }
  return false
}

// TODO: clean up after OAuth error handling is moved to the new error handling system
const DESTINATION_ERROR_CODES: Record<string, { message: string; type: TypeOptions }> = {
  GOOGLE_API_404: {
    message:
      'Google resource not found. If you cannot access this destination, it may have been deleted. If not, You can re-authorize it',
    type: 'warning',
  },
}

const FALLBACK_DESTINATION_ERROR = 'Something went wrong when accessing the destination. Please try again later.'

export const handleDestinationGetError: ApiErrorHandlerFn = (
  apiError: ApiError,
  { errorTitle }: ApiErrorHandlerParams = {}
) => {
  const isInvalidGrant = apiError.metadata.error === 'invalid_grant'

  const title = errorTitle || getMessage('PRESENTATION_GENERIC_ERROR')

  /**
   * TODO: add error handling in oauth error handling system
   *
   * Hide error message, as this error is handled from a component
   * by asking the user to re-authorize using the file picker
   */
  if (apiError.code === 'google-drive-item-not-found') {
    return true
  }

  if (isInvalidGrant) {
    onError(apiError, {
      title,
      message: getMessage('GOOGLE_OAUTH_INVALID_TOKEN'),
      type: 'error',
    })
    return true
  }
  if (apiError.metadata.code) {
    const errorDetails = DESTINATION_ERROR_CODES[apiError.metadata.code as keyof typeof DESTINATION_ERROR_CODES]
    if (errorDetails) {
      const specificErrorMessage = errorDetails.message || apiError.metadata.message || FALLBACK_DESTINATION_ERROR
      onError(apiError, {
        title,
        message: specificErrorMessage,
        type: errorDetails?.type || 'error',
        hideSubtext: true,
      })
      return true
    }
  }

  return false
}
