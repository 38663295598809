import { FC } from 'react'

import { IIcon } from '@assets/types'

export const GSlidesIcon: FC<IIcon> = () => {
  return (
    <svg width="20" viewBox="0 0 20 20">
      <path
        d="M15.7892 20H3.97106C3.22106 20 2.60742 19.3864 2.60742 18.6364V1.36364C2.60742 0.613636 3.22106 0 3.97106 0H12.1529L17.1529 5V18.6364C17.1529 19.3864 16.5392 20 15.7892 20Z"
        fill="#F8BF08"
      />
      <path d="M12.1529 0L17.1529 5H12.153L12.1529 0Z" fill="#F59307" />
      <path
        d="M5.33449 7.84103V14.2047H14.4254V7.84103H5.33449ZM13.289 13.0683H6.47086V8.97739H13.289V13.0683Z"
        fill="#FDFFFF"
      />
    </svg>
  )
}
