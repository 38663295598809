import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildDestinationPath } from '@pages/routes/paths'

import { useAddDestination } from '@features/destinations/api/api'
import { CreateDestinationRequest, EDestinationType } from '@features/destinations/types'
import { getPresentationTypeByServiceIdAndOauthType } from '@features/destinations/utils'
import { GOOGLE_PERMISSION_WAIT_TIME, useGDrivePicker, useOpenOneDrivePicker } from '@features/drive'
import { GDriveMimeTypes } from '@features/drive/google-drive/types'
import { OAuthType } from '@features/users'

import { drivePickerWrongFileTypeToast } from '@shared/components'
import { sleep } from '@shared/http'

import { IDestinationDoc } from './use-destination-settings'

export const useAddDestinationFromDrive = () => {
  const navigate = useNavigate()
  const { isLoading: addDestinationLoading, mutateAsync } = useAddDestination()

  const [option, setOption] = useState<null | EDestinationType>(null)
  const [isLoading, setIsLoading] = useState(false)

  const addDestination = (oauthType: OAuthType) => async (docs: IDestinationDoc[]) => {
    const { id, name, serviceId, type, driveId, ...props } = docs[0]
    setIsLoading(true)
    if (type === 'file') {
      let filetype = ''

      if (name.includes('.')) {
        filetype = name.split('.').pop()!
      } else if (props.mimeType === GDriveMimeTypes.pptx) {
        filetype = 'pptx'
      } else if (props.mimeType === GDriveMimeTypes.docx) {
        filetype = 'docx'
      }

      drivePickerWrongFileTypeToast(filetype)
      setIsLoading(false)
      return
    }

    const destinationType = getPresentationTypeByServiceIdAndOauthType(serviceId, oauthType)
    if (!destinationType) {
      throw new Error('Invalid presentation type')
    }
    const newPresentation: CreateDestinationRequest = {
      docId: id,
      driveId: driveId ?? null,
      name: oauthType === OAuthType.MICROSOFT ? id : name,
      type: destinationType,
    }

    try {
      const res = await mutateAsync(newPresentation)

      // Need to wait for the permission changes to take effect in the Drive
      // as it can take a couple of seconds after the user can access the slides data after authorizing the file
      await sleep(GOOGLE_PERMISSION_WAIT_TIME)

      setIsLoading(false)
      navigate(buildDestinationPath(res.data.id))
    } finally {
      setIsLoading(false)
    }
  }

  const onCancelfn = useCallback(() => {
    setIsLoading(false)
    setOption(null)
  }, [])

  const { openGDrivePicker } = useGDrivePicker(addDestination(OAuthType.GOOGLE), onCancelfn)
  const { openOneDrivePicker } = useOpenOneDrivePicker(addDestination(OAuthType.MICROSOFT), onCancelfn)

  return {
    openGDrivePicker,
    openOneDrivePicker,
    setOption,
    option,
    setIsLoading,
    isLoading: addDestinationLoading || isLoading,
  }
}
