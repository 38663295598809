import { forwardRef } from 'react'

interface ITableauEmbed {
  src: string
  token?: string
  width?: string
  height?: string
  debug?: boolean
  props?: any
}

export const TableauIframe = forwardRef<HTMLElement | null, ITableauEmbed>(
  ({ props, src, token, debug, width = '1000', height = '1000' }, ref) => {
    return (
      <tableau-viz
        {...props}
        src={src}
        ref={ref}
        debug={debug}
        token={token}
        width={width}
        height={height}
        id="tableauViz"
        device="Desktop"
        toolbar="hidden"
        hide-tabs={true}
        disable-url-actions={true}
        suppress-default-edit-behavior={true}
        iframe-auth
      >
        <custom-parameter name=":refresh" value="yes"></custom-parameter>
      </tableau-viz>
    )
  }
)
