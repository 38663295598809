import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { FreeTrialContext } from '@features/free-trial/free-trial-provider'
import { useOrganizationsStore } from '@features/organizations'

export const useFreeTrial = (onFreeTrialExpired?: () => void) => {
  const navigate = useNavigate()

  const { show, freeTrialExpired } = useContext(FreeTrialContext)
  const { activeOrganization } = useOrganizationsStore()

  useEffect(() => {
    if (freeTrialExpired) {
      show()
      if (onFreeTrialExpired) {
        onFreeTrialExpired()
      } else {
        navigate(Paths.BASE)
      }
    }
  }, [activeOrganization, freeTrialExpired, navigate, onFreeTrialExpired, show])
}
