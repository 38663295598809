import { FC } from 'react'

import { EVariantNaming } from '@features/collections/types'

import { SelectableOption } from '@shared/components/selectable-options/selectable-options'

import styles from './variant-naming-options.module.css'

export const VariantNamingOptions: FC<{
  selected: EVariantNaming
  onClick: (variantNaming: EVariantNaming) => void
  placeholderVariant?: string
}> = ({ selected, onClick, placeholderVariant }) => {
  placeholderVariant = placeholderVariant ?? 'LATAM'
  return (
    <div className={styles.variantNamingOptions}>
      <SelectableOption
        className={styles.variantNamingOption}
        selected={selected === EVariantNaming.sameName}
        onClick={() => onClick(EVariantNaming.sameName)}
      >
        <div>
          Use the <span className={styles.variantNamingOptionHighlight}>name</span> as it appears in the filter
        </div>
        <div className={styles.variantNamingExample}>e.g. {placeholderVariant}</div>
      </SelectableOption>
      <SelectableOption
        className={styles.variantNamingOption}
        selected={selected === EVariantNaming.prefix}
        onClick={() => onClick(EVariantNaming.prefix)}
      >
        <div>
          Add a <span className={styles.variantNamingOptionHighlight}>prefix</span> to the original destination name
        </div>
        <div className={styles.variantNamingExample}>e.g. {placeholderVariant} - Weekly Business Review</div>
      </SelectableOption>
      <SelectableOption
        className={styles.variantNamingOption}
        selected={selected === EVariantNaming.suffix}
        onClick={() => onClick(EVariantNaming.suffix)}
      >
        <div>
          Add a <span className={styles.variantNamingOptionHighlight}>suffix</span> to the original destination name
        </div>
        <div className={styles.variantNamingExample}>e.g. Weekly Business Review - {placeholderVariant}</div>
      </SelectableOption>
    </div>
  )
}
