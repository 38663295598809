import * as Sentry from '@sentry/react'
import { useRouteError } from 'react-router-dom'

import { ErrorFallback } from '@shared/components/error-boundary/error-fallback'

export const ErrorPage = () => {
  const error = useRouteError() as Error
  console.error(error)
  Sentry.captureException(error)

  return <ErrorFallback error={error} />
}
