import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Destination } from '@features/destinations/types'

interface DestinationsState {
  destinations?: Destination[]
}

interface DestinationActions {
  setDestinations: (destinations: Destination[]) => void
}

export const useDestinationsStore = create<DestinationsState & DestinationActions>()(
  persist(
    immer(set => ({
      setDestinations: destinations => {
        set({ destinations })
      },
    })),
    {
      name: 'destinations',
    }
  )
)
