import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'

import { DataSourceIcon, ExternalLinkIcon, TrashIcon } from '@assets'
import { SettingsIcon } from '@assets/icons/settings'
import { UsersIcon } from '@assets/icons/users'

import { CoreDataSource, EDataSourceType } from '@features/data-sources/types/types'

import { Avatar, Badge, Button, EBadgeColor, OverlayingComponents, Tooltip } from '@shared/components'

import styles from './data-sources-table.module.css'

export const isAdminCredentials = (dataSource: CoreDataSource) => !!dataSource.childCredentials
export const isSpreadsheetsDataSource = ({ type }: { type: EDataSourceType }) => {
  return type === EDataSourceType.GoogleSheets
}

interface RowData {
  id: string
  type: string
  name: string
  childCredentials?: CoreDataSource[] | undefined
}

interface FiltersTableColumnsProps {
  memberView: boolean
  dsRequestsExpiries: Record<string, string>
  requestAccessLoading: boolean
  requestDSAccess: (dataSourceId: string) => Promise<void>
  openUserManagement: (ds: CoreDataSource) => () => void
  openSettings: (ds: CoreDataSource) => () => void
  openModalWithData: (deletionData: { id: string; type: EDataSourceType }) => void
  openPasswordModalWithData: (data: CoreDataSource) => void
}

export const dataSourcesTableColumns = ({
  memberView,
  dsRequestsExpiries,
  requestAccessLoading,
  requestDSAccess,
  openUserManagement,
  openSettings,
  openModalWithData,
  openPasswordModalWithData,
}: FiltersTableColumnsProps): ColumnDef<RowData>[] => {
  const allColumns: ColumnDef<RowData>[] = [
    {
      accessorKey: 'type',
      header: 'Type',
      size: 200,
      cell: info => <DataSourceIcon type={info.getValue() as EDataSourceType} />,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      size: 200,
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'role',
      header: 'Role',
      size: 200,
      cell: ({ row }) => {
        const dataSource = row.original as CoreDataSource
        const isSpreadsheet = isSpreadsheetsDataSource(dataSource)
        const isAdmin = isAdminCredentials(dataSource)
        if (isAdmin || isSpreadsheet) {
          return <Badge color={EBadgeColor.SECONDARY} text="Admin" />
        } else {
          return <Badge color={EBadgeColor.LIGHTGRAY} text="User" />
        }
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 200,
      cell: ({ row }) => {
        const dataSource = row.original as CoreDataSource
        const isSpreadsheet = isSpreadsheetsDataSource(dataSource)
        const isAdmin = isAdminCredentials(dataSource)
        const isActive = dataSource.isActive
        if (isActive || isAdmin || isSpreadsheet) {
          return <Badge color={EBadgeColor.GREEN} text="Active" />
        }
        const canRequestAccess = dsRequestsExpiries[row.original.id]
          ? dayjs(dsRequestsExpiries[row.original.id]).isBefore(dayjs())
          : true

        return (
          <Tooltip show={!canRequestAccess} title="A request was sent to your admin">
            <Button
              loading={requestAccessLoading}
              disabled={!canRequestAccess}
              text={dsRequestsExpiries[row.original.id] ? 'Pending admin action' : 'Request access'}
              onClick={() => requestDSAccess(row.original.id)}
            />
          </Tooltip>
        )
      },
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      size: 200,
      cell: ({ row }) => {
        const dataSource = row.original as CoreDataSource
        const isAdmin = isAdminCredentials(dataSource)
        const isSpreadsheets = isSpreadsheetsDataSource(dataSource)
        return (
          <div className="flex flex-row gap-2">
            {isSpreadsheets && (
              <>
                <Tooltip title={'Delete'} position="top">
                  <Button
                    secondaryGray
                    iconLeading={<TrashIcon />}
                    onClick={() =>
                      openModalWithData({ id: row.original.id, type: row.original.type as EDataSourceType })
                    }
                  />
                </Tooltip>
                <Tooltip title={'Open'} position="top">
                  <Button
                    secondaryGray
                    iconLeading={<ExternalLinkIcon />}
                    onClick={() => window.open(dataSource.baseUrl, '_blank', 'noopener,noreferrer')}
                  />
                </Tooltip>
              </>
            )}
            {isAdmin && !isSpreadsheets && (
              <>
                <Tooltip title={'Settings'} position="top">
                  <Button
                    secondaryGray
                    iconLeading={<SettingsIcon />}
                    onClick={openSettings(row.original as CoreDataSource)}
                  />
                </Tooltip>
                <Tooltip title={'User management'} position="top">
                  <Button
                    secondaryGray
                    iconLeading={<UsersIcon />}
                    onClick={openUserManagement(row.original as CoreDataSource)}
                  />
                </Tooltip>
                <Tooltip title={'Delete'} position="top">
                  <Button
                    secondaryGray
                    iconLeading={<TrashIcon />}
                    onClick={() =>
                      openModalWithData({ id: row.original.id, type: row.original.type as EDataSourceType })
                    }
                  />
                </Tooltip>
              </>
            )}
            {!isAdmin && !isSpreadsheets && (row.original as CoreDataSource).hasMetabasePassword && (
              <Button
                text="Enter password"
                secondaryColor
                onClick={() => openPasswordModalWithData(row.original as CoreDataSource)}
              />
            )}
          </div>
        )
      },
    },
  ]

  if (!memberView) {
    allColumns.splice(allColumns.length - 1, 0, {
      accessorKey: 'allowedUsers',
      header: 'Allowed Users',
      size: 200,
      cell: ({ row }) => {
        const dataSource = row.original as CoreDataSource

        const isAdmin = isAdminCredentials(dataSource)
        const isSpreadsheets = dataSource.type === EDataSourceType.GoogleSheets
        if (isAdmin && !isSpreadsheets) {
          const childCredentials = (dataSource.childCredentials || []) as CoreDataSource[]
          const activeChildCredentials = childCredentials
            .filter(ds => ds.isActive)
            .sort((a, b) => {
              if (!a.user?.name || !b.user?.name) return 0
              return a.user.name.localeCompare(b.user.name)
            })

          const isDefaultDataSource = Boolean(childCredentials.find(cred => cred.defaultAcrossOrganization))

          return (
            <div className={styles.allowedUsersView} onClick={() => openUserManagement(dataSource)}>
              {isDefaultDataSource ? (
                `All users`
              ) : (
                <>
                  <span>
                    {activeChildCredentials.length} {activeChildCredentials.length === 1 ? 'user' : 'users'}
                  </span>
                  <OverlayingComponents
                    renderMoreComponents={hiddenCount => (
                      <Avatar name={`+ ${hiddenCount}`} size={30} className={styles.avatar} />
                    )}
                  >
                    {activeChildCredentials.map(childCred => (
                      <Avatar key={childCred.id} name={childCred.user?.name} size={30} className={styles.avatar} />
                    ))}
                  </OverlayingComponents>
                </>
              )}
            </div>
          )
        }
        if (isSpreadsheets) {
          const countUsers = [dataSource.user].filter(Boolean).length
          return (
            <div className={styles.allowedUsersView} onClick={() => openUserManagement(dataSource)}>
              {
                <>
                  <span>{countUsers} user</span>
                  <OverlayingComponents
                    renderMoreComponents={hiddenCount => (
                      <Avatar name={`+ ${hiddenCount}`} size={30} className={styles.avatar} />
                    )}
                  >
                    <Avatar key={row.id} name={dataSource.user?.name} size={30} className={styles.avatar} />
                  </OverlayingComponents>
                </>
              }
            </div>
          )
        }

        return null
      },
    })
  }
  return allColumns
}
