import { FC } from 'react'

import { SettingsIcon } from '@assets/icons/settings'

import { IRange, ITableauFilterObject } from '@features/embedding/tableau/types'

import { Colors } from '@shared/constants'
import { useHover } from '@shared/hooks/use-hover'

import { SyncFilterPopup } from './sync-filter-popup'

import overlayStyles from './overlay.module.css'

interface ISyncFilterOverlay {
  filterObject: ITableauFilterObject & IRange
  showDateSettingsPopup: boolean
  handleShowDateSettingsPopup: () => void
  updateDateRangeFilter: (filter: ITableauFilterObject) => void
}

const two = 2
const fifteen = 15

export const SyncFilterOverlay: FC<ISyncFilterOverlay> = ({
  filterObject,
  showDateSettingsPopup,
  handleShowDateSettingsPopup,
  updateDateRangeFilter,
}) => {
  const { hoverRef: outerDivHover } = useHover()
  if (!filterObject.object) return null
  const { size, position, name } = filterObject.object

  const handleClickDisplay = () => {
    handleShowDateSettingsPopup()
  }

  return (
    <div>
      <div
        key={name}
        className={overlayStyles.filterOverlayArea}
        ref={outerDivHover}
        style={{
          left: position.x,
          top: position.y,
          width: size.width,
          height: size.height,
        }}
      />

      <div
        style={{
          position: 'absolute',
          top: position.y + size.height / two - fifteen,
          left: position.x + size.width,
        }}
      >
        <div className={overlayStyles.badge} onClick={handleClickDisplay}>
          <SettingsIcon color={Colors.white} />
        </div>
        <SyncFilterPopup
          showPopup={showDateSettingsPopup}
          filterObject={filterObject}
          updateDateRangeFilter={updateDateRangeFilter}
        />
      </div>
    </div>
  )
}
