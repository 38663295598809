import { EDataSourceType } from '@features/data-sources/types/types'
import { Destination, EDestinationType } from '@features/destinations/types'

import { EmailHandler } from './email-handler'
import { GoogleDocsHandler } from './google-docs-handler'
import { GoogleSheetsHandler } from './google-sheets-handler'
import { GoogleSlidesHandler } from './google-slides-handler'
import { MicrosoftPowerpointHandler } from './microsoft-powerpoint-slides-handler'
import { MicrosoftWordHandler } from './microsoft-word-handler'

export const defaultPreviewWidth = 210
export const defaultPreviewHeight = 118

export const destinationHandlers = (data: Destination | string) => {
  const pres = typeof data === 'string' ? null : data
  const type = typeof data === 'string' ? data : data.type

  switch (type) {
    case EDestinationType.GoogleSlides:
      return new GoogleSlidesHandler(pres)
    case EDestinationType.GoogleDocs:
      return new GoogleDocsHandler(pres)
    case EDataSourceType.GoogleSheets:
      return new GoogleSheetsHandler(pres)
    case EDestinationType.MicrosoftPowerPoint:
      return new MicrosoftPowerpointHandler(pres)
    case EDestinationType.MicrosoftWord:
      return new MicrosoftWordHandler(pres)
    case EDestinationType.Email:
      return new EmailHandler(pres)
    default:
      throw new Error('Unknown destination type')
  }
}
