import httpStatus from 'http-status'

import { dataOutOfDateToast } from '@shared/components/toasts/toasts'
import { ApiError, queryClient } from '@shared/http'
import { ApiErrorHandlerFn } from '@shared/http/types'

export const handleCollectionOutOfDateError: ApiErrorHandlerFn = (apiError: ApiError) => {
  if (apiError.statusCode === httpStatus.CONFLICT) {
    dataOutOfDateToast({
      handleDataRefresh: () => {
        if (apiError.metadata.id) {
          queryClient.invalidateQueries(['collection', { collectionId: apiError.metadata.id }])
          queryClient.invalidateQueries(['presentations'])
        }
      },
    })
    return true
  }
  return false
}
