import { ETutorialSections, TutorialSection } from '@features/settings/components/directory-sync/tutorials/types'

export const TemplateTutorialSections: Record<ETutorialSections, TutorialSection> = {
  [ETutorialSections.SETUP]: {
    title: 'Template SCIM v2.0',
    description:
      'The following guide will walk you through the process of configuring SAML Jackson to use Okta as a directory sync provider.',
    steps: [],
  },
  [ETutorialSections.PROVISIONING]: {
    title: 'Enable Template SCIM Provisioning',
    description: 'In your application, click the Provisioning tab and click Configure API Integration.',
    steps: [],
  },
}
