import classNames from 'classnames'

import styles from './period-switch.module.css'

export type Period = 'am' | 'pm'

interface IPeriodSwitch {
  value: Period
  onChange: (period: Period) => void
}

export const PeriodSwitch = ({ value, onChange }: IPeriodSwitch) => {
  const amClass = classNames(styles.option, styles.leftOption, value === 'am' && styles.selectedOption)
  const pmClass = classNames(styles.option, styles.rightOption, value === 'pm' && styles.selectedOption)
  return (
    <div className={styles.container}>
      <div onClick={() => onChange('am')} className={amClass}>
        AM
      </div>
      <div onClick={() => onChange('pm')} className={pmClass}>
        PM
      </div>
    </div>
  )
}
