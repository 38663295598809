import { FC } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { ErrorPage } from '@pages/error-page/error-page'
import { NotFound } from '@pages/not-found/not-found'
import { Onboarding } from '@pages/onboarding/onboarding'
import { OrganizationNotFoundPage } from '@pages/organization/not-found/organization-not-found'
import { Root } from '@pages/root/root'

import { SyncSettingsProvider } from '@features/syncs'

import { HomeCrumb } from '@shared/components/breadcrumbs/breadcrumbs'

import { authCallbackRoutes } from './auth-callback-routes'
import { collectionRoutes } from './collection-routes'
import { dataSourceRoutes } from './data-source-routes'
import { destinationRoutes } from './destination-routes'
import { historicalRunRoutes } from './historical-run-routes'
import { PathSegments, Paths } from './paths'
import { settingsRoutes } from './setting-routes'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

export const router = sentryCreateBrowserRouter([
  {
    path: Paths.ORGANIZATION_NOT_FOUND,
    element: <OrganizationNotFoundPage />,
  },
  {
    path: PathSegments.BASE,
    element: (
      <SyncSettingsProvider>
        <Root />
      </SyncSettingsProvider>
    ),
    errorElement: <ErrorPage />,
    handle: { crumb: HomeCrumb },
    children: [
      {
        index: true,
        element: <Onboarding />,
      },
      {
        path: PathSegments.NOT_FOUND,
        element: <NotFound />,
      },
      destinationRoutes,
      collectionRoutes,
      dataSourceRoutes,
      historicalRunRoutes,
      settingsRoutes,
      authCallbackRoutes,
    ],
  },
])

export const Router: FC = () => <RouterProvider router={router} />
