import classNames from 'classnames'
import Papa from 'papaparse'
import { FC, useMemo, useState } from 'react'

import { SendRightIcon } from '@assets'

import { DestinationCropOptionSelect } from '@features/destinations/components/destination-crop-option-select/destination-crop-option-select'
import { DestinationLinkOnImages } from '@features/destinations/components/destination-link-on-images-select/destination-link-on-images-select'
import { DestinationTestEmailModal } from '@features/destinations/components/destination-test-email-modal/destination-test-email-modal'
import { DestinationSchedule } from '@features/destinations/components/destination-update-schedule/destination-schedule'
import { useDestinationConfiguration } from '@features/destinations/hooks'
import { useDestinationEmailConfigHandler } from '@features/destinations/hooks/use-destination-email-config-handler'
import {
  Destination,
  DestinationEmailConfigSharingFormat,
  DestinationEmailConfigSharingPermission,
} from '@features/destinations/types'
import { getEmptyDestinationEmailConfig, isEmailDestination } from '@features/destinations/utils'
import { SelectEmailTemplate } from '@features/emails/components/select-email-template'
import { getUpdateDestinationTemplateVariables } from '@features/emails/components/templates/utils'
import { EEmailTemplateType, ESelectEmailTemplateEditorMode } from '@features/emails/types'

import { Button, FileInput, Label, SelectableOption, Spinner, Switch, Tooltip } from '@shared/components'
import { EmailConfigTrigger } from '@shared/components/email-config/types'
import { MAX_CSV_ROWS } from '@shared/components/file-input/consts'
import { InputList } from '@shared/components/input-list/Input-list'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'
import { isValidEmail } from '@shared/utils'
import { DEFAULT_SHARING_PERMISSION, createSharingPermissionOptions } from '@shared/utils/sending'

import styles from './destination-sending.module.css'

export interface IDestinationSending {
  destination: Destination
  isLoading?: boolean
}

export interface IRecipientCsvRow {
  recipient_email: string
  recipient_name?: string
}

const csvExample = `recipient_email,recipient_name
jane.doe@email.com,Ms. Doe
john.doe@email.com,John
`

export const DestinationSending: FC<IDestinationSending> = ({ destination, isLoading }) => {
  const [emailInputError, setEmailInputError] = useState<string | null>(null)
  const [fileProcessing, setFileProcessing] = useState<boolean>(false)
  const [fileError, setFileError] = useState<string | null>(null)
  const [showCsvPreview, setShowCsvPreview] = useState<boolean>(true)
  const [isTestEmailModalOpen, setIsTestEmailModalOpen] = useState<boolean>(false)

  const { emailConfig, setEmailConfig, upsertEmailConfig, isEmailConfigLoading } = useDestinationEmailConfigHandler(
    destination.id
  )

  const isInCollection = useMemo(() => !!destination.variantInCollection, [destination])

  const {
    formattedSchedules,
    expandedScheduleId,
    timeOptions,
    freqOptions,
    linkOption,
    cropOption,
    isSubmitting,
    setCropOption,
    setLinkOption,
    removeSchedule,
    addSchedule,
    setExpandedScheduleId,
    handleEndDateChange,
    handleFrequencyChange,
    handlePeriodChange,
    handleStartDateChange,
    handleTimeChange,
    toggleEndDate,
    updateConfiguration,
  } = useDestinationConfiguration({ destination, itemUpdatedAt: destination.updatedAt })

  const sharingFormatOptions: { label: string; value: DestinationEmailConfigSharingFormat }[] = [
    {
      label: 'Link',
      value: DestinationEmailConfigSharingFormat.link,
    },
    {
      label: 'PDF',
      value: DestinationEmailConfigSharingFormat.pdf,
    },
  ]

  const sharingPermissionOptions: { label: string; value: DestinationEmailConfigSharingPermission }[] = useMemo(
    () => createSharingPermissionOptions({ type: destination.type }),
    [destination.type]
  )

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Spinner localCenter />
      </div>
    )
  }

  const isRecipientConfigEmpty = (emailConfig?.recipientConfig?.length || 0) === 0

  const handleRecipientChange = (recipientEmails: string[]) => {
    setEmailInputError(null)
    setFileError(null)
    setEmailConfig({
      recipientConfig: recipientEmails.map(email => ({
        recipient_email: email,
      })),
    })
  }

  const handleEmailInputError = (error: string) => {
    setEmailInputError(`The inserted email address is not valid: ${error}`)
  }

  const onFileParseBefore = (chunk: string) => {
    setFileProcessing(true)
    setEmailConfig({
      recipientConfig: [],
    })
    setEmailInputError(null)
    setFileError(null)
    setShowCsvPreview(false)
    return chunk
  }

  const onComplete = (results: Papa.ParseResult<IRecipientCsvRow>) => {
    setFileProcessing(false)
    if (results.meta.truncated) {
      setFileError(`File is too big. The maximum lines allowed is ${MAX_CSV_ROWS}`)
      return
    }
    if (!results.meta.fields?.includes('recipient_email')) {
      setFileError('File is missing "recipient_email" column')
      return
    }

    const firstInvalidRow = results.data.find(row => !isValidEmail(row.recipient_email))
    if (firstInvalidRow) {
      setFileError(`Invalid email address: ${firstInvalidRow.recipient_email}`)
      return
    }

    setEmailConfig({
      recipientConfig: results.data.map(data => ({
        ...data,
        recipient_email: data.recipient_email,
        recipient_name: data.recipient_name || '',
      })),
    })
    setShowCsvPreview(true)
  }

  const onError = (error: Error) => {
    setFileProcessing(false)
    setShowCsvPreview(false)
    setFileError(error.message)
    console.error('error processing file', error)
  }

  const handleFileSelected = (file: File) => {
    Papa.parse<IRecipientCsvRow>(file, {
      beforeFirstChunk: onFileParseBefore,
      complete: onComplete,
      delimiter: ',',
      error: onError,
      header: true,
      preview: MAX_CSV_ROWS,
      skipEmptyLines: true,
    })
  }

  const handleSharingFormatClick = (value: DestinationEmailConfigSharingFormat) => () => {
    setEmailConfig({
      sharingFormat: value,
      sharingPermission: value === DestinationEmailConfigSharingFormat.link ? sharingPermission : null,
    })
  }

  const handleSharingPermissionClick = (value: DestinationEmailConfigSharingPermission) => () => {
    setEmailConfig({
      sharingPermission: sharingPermission === value ? null : value,
    })
  }

  const handleAutomaticSendChange = (value: boolean) => {
    setEmailConfig({
      trigger: value ? EmailConfigTrigger.all : EmailConfigTrigger.none,
    })
  }

  const handleSaveClicked = async () => {
    if (isEmailDestination(destination)) {
      await updateConfiguration()
    }

    if (!emailConfig?.recipientConfig || emailConfig?.recipientConfig?.length === 0) {
      setEmailInputError('Please add at least one recipient')
      return
    }

    await upsertEmailConfig({ ...getEmptyDestinationEmailConfig(destination.id), ...emailConfig })
  }

  if (isInCollection) {
    return (
      <>
        <div className={styles.container}>
          <section className={classNames(styles.rowContainer, styles.sendTestButtonRow)}>
            <Button
              text="Send to a test recipient"
              iconLeading={<SendRightIcon />}
              secondaryGray
              onClick={() => setIsTestEmailModalOpen(true)}
            />
          </section>
        </div>
        <DestinationTestEmailModal
          destination={destination}
          isOpen={isTestEmailModalOpen}
          onClose={() => setIsTestEmailModalOpen(false)}
          initialTemplateId={emailConfig?.emailTemplateId}
          key={emailConfig?.emailTemplateId}
        />
      </>
    )
  }

  const getCSVPreview = () => {
    const columns: string[] = Object.keys(emailConfig?.recipientConfig?.[0] || {})
    const maxColumnLength = columns.reduce(
      (acc, column) => {
        const values = emailConfig?.recipientConfig?.map(config => config[column]) || []
        acc[column] = Math.max(...values.map(value => value?.length || 0))
        return acc
      },
      {} as Record<string, number>
    )
    const columnWithoutEmailAndName = columns.filter(
      column => column !== 'recipient_email' && column !== 'recipient_name'
    )
    const preview = emailConfig?.recipientConfig
      ?.map(config => {
        return [
          config.recipient_email.padEnd(maxColumnLength['recipient_email'] || 0),
          config.recipient_name?.padEnd(maxColumnLength['recipient_name'] || 0),
          ...columnWithoutEmailAndName.map(column => {
            return config[column]?.padEnd(maxColumnLength[column])
          }),
        ].join(',')
      })
      .join('\n')
    return preview
  }

  const sharingFormat = emailConfig?.sharingFormat || DestinationEmailConfigSharingFormat.link
  const sharingPermission = emailConfig?.sharingPermission || DEFAULT_SHARING_PERMISSION
  return (
    <div className={styles.container}>
      <h3 className={styles.label}>Recipients</h3>
      <section className={styles.rowContainer}>
        <div className={styles.rowContainerItem}>
          <label className={styles.inputLabel}>Recipients (manual entry)</label>
          {emailInputError && (
            <div>
              <label className={styles.inputError}>{emailInputError}</label>
            </div>
          )}
          <InputList
            onChange={handleRecipientChange}
            value={emailConfig?.recipientConfig?.map(rc => rc.recipient_email) || []}
            isClearable={false}
            isMulti
            placeholder="Recipient emails"
            separator={[',', 'Enter']}
            validateNewOption={isValidEmail}
            onError={handleEmailInputError}
          />
          <div className={styles.hint}>Emails should be comma separated</div>
        </div>
        <div className={styles.rowContainerItem}>
          <p className={styles.inputOptionSeparator}>OR</p>
        </div>
        <div className={styles.rowContainerItem}>
          <label className={styles.inputLabel}>Recipients (CSV)</label>
          {fileError && (
            <div>
              <label className={styles.inputError} htmlFor="file-input">
                {fileError}
              </label>
            </div>
          )}
          <FileInput
            id="file-input"
            containerClassName={styles.input}
            fileType=".csv"
            disabled={fileProcessing}
            onFileSelected={handleFileSelected}
          />
          <div className={styles.hint}>* The file should contain the mandatory column: recipient_email.</div>
        </div>
        <div className={styles.rowContainerItem}>
          <div className={styles.csvExample}>
            <p className={styles.csvExampleTitle}>CSV Example</p>
            <div>
              <pre className="text-sm-regular">{csvExample}</pre>
            </div>
          </div>
        </div>
        {showCsvPreview && emailConfig?.recipientConfig && emailConfig?.recipientConfig.length > 0 && (
          <div className={styles.rowContainerItem}>
            <div className={styles.csvExample}>
              <p className={styles.csvExampleTitle}>CSV Preview</p>
              <div>
                <pre className="text-sm-regular">{getCSVPreview()}</pre>
              </div>
            </div>
          </div>
        )}
      </section>
      {!isEmailDestination(destination) && isFeatureEnabled('email-templates') && (
        <>
          <h3 className={styles.label}>Email template</h3>
          <section className={styles.rowContainer}>
            <SelectEmailTemplate
              targetId={destination.id}
              variables={getUpdateDestinationTemplateVariables(destination, emailConfig)}
              editorMode={ESelectEmailTemplateEditorMode.MODAL}
              destinationType={destination.type}
              templateType={
                isEmailDestination(destination)
                  ? EEmailTemplateType.emailDestination
                  : EEmailTemplateType.destinationUpdate
              }
              initialTemplateId={emailConfig?.emailTemplateId}
              onSelectTemplate={(templateId?: string | null) => {
                setEmailConfig({
                  emailTemplateId: templateId,
                })
              }}
            />
          </section>
        </>
      )}

      {!isEmailDestination(destination) && isFeatureEnabled('send-emails-as-pdf') ? (
        <>
          <h3 className={styles.label}>Sharing Format</h3>
          <section className={styles.rowContainer}>
            {sharingFormatOptions.map(option => (
              <SelectableOption
                className={styles.sharingOptionItem}
                key={option.value}
                selected={sharingFormat === option.value}
                onClick={handleSharingFormatClick(option.value)}
              >
                {option.label}
              </SelectableOption>
            ))}
          </section>
        </>
      ) : null}
      {!isEmailDestination(destination) && sharingFormat === DestinationEmailConfigSharingFormat.link && (
        <>
          <h3 className={styles.label}>Sharing Permission</h3>
          <section className={styles.rowContainer}>
            {sharingPermissionOptions.map(option => (
              <SelectableOption
                className={styles.sharingOptionItem}
                key={option.value}
                selected={sharingPermission === option.value}
                onClick={handleSharingPermissionClick(option.value)}
              >
                {option.label}
              </SelectableOption>
            ))}
          </section>
        </>
      )}

      {isEmailDestination(destination) && (
        <>
          <h3 className={styles.label}>Scheduling</h3>
          <section className={styles.rowContainer}>
            <DestinationSchedule
              formattedSchedules={formattedSchedules}
              freqOptions={freqOptions}
              timeOptions={timeOptions}
              expandedScheduleId={expandedScheduleId}
              handleFrequencyChange={handleFrequencyChange}
              handleTimeChange={handleTimeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              toggleEndDate={toggleEndDate}
              addSchedule={addSchedule}
              removeSchedule={removeSchedule}
              handlePeriodChange={handlePeriodChange}
              setExpandedScheduleId={setExpandedScheduleId}
            />
          </section>
        </>
      )}

      <h3 className={styles.label}>Automatic send after each update</h3>
      <section className={classNames(styles.rowContainer, styles.justifyStart)}>
        <Switch value={emailConfig?.trigger === EmailConfigTrigger.all} onChange={handleAutomaticSendChange} />
      </section>

      {isEmailDestination(destination) && (
        <>
          <h3 className={styles.label}>Link on images</h3>
          <section className="flex gap-4 justify-center">
            <DestinationLinkOnImages linkOption={linkOption} setLinkOption={setLinkOption} />
          </section>
          <div>
            <h3 className={styles.label}>Default Crop Option</h3>
            <Label xs regular>
              By default, all new syncs will use the selected crop option.
            </Label>
          </div>
          <section className="flex gap-4 justify-center">
            <DestinationCropOptionSelect cropOption={cropOption} setCropOption={setCropOption} />
          </section>
        </>
      )}
      <hr />

      <section className={classNames(styles.rowContainer, styles.saveButtonRow)}>
        <Button
          text="Send test"
          iconLeading={<SendRightIcon />}
          secondaryGray
          onClick={() => setIsTestEmailModalOpen(true)}
        />
        <Tooltip show={isRecipientConfigEmpty} title="Add recipients before saving">
          <Button
            text="Save"
            onClick={handleSaveClicked}
            loading={isSubmitting || isEmailConfigLoading}
            disabled={isSubmitting || isEmailConfigLoading || isRecipientConfigEmpty}
          />
        </Tooltip>
      </section>

      <DestinationTestEmailModal
        destination={destination}
        isOpen={isTestEmailModalOpen}
        onClose={() => setIsTestEmailModalOpen(false)}
        initialTemplateId={emailConfig?.emailTemplateId}
        key={emailConfig?.emailTemplateId}
      />
    </div>
  )
}
