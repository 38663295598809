import { IDashboard } from '@looker/sdk'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import {
  ILookerApiCredentials,
  ILookerDataSource,
  ILookerDataSourceRequest,
  ILookerDataSourceUpdateRequest,
} from '@features/data-sources/types'
import { ILookerDashboard, ILookerFolder } from '@features/embedding/looker/types'

import { successToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import { onError, queryClient, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'

import { handleLookerApiErrors } from './error-handler'

export const useGetLookerDataSource = (sourceId?: string, includeChildCredentials = false) => {
  return useQueryWithErrorHandler<ILookerDataSource, AxiosError>(
    ['looker', sourceId, includeChildCredentials],
    async () => {
      if (!sourceId) {
        return {} as ILookerDataSource
      }
      const response = await api.get(`datasources/looker/${sourceId}`, { params: { includeChildCredentials } })
      if (!response) {
        return []
      }
      return response.data
    },
    {
      useErrorBoundary: false,
    }
  )
}

export const useAddParentLookerDataSource = () => {
  return useMutation<AxiosResponse<ILookerDataSource>, any, any>(
    (datasource: ILookerDataSourceRequest) => api.post('/datasources/looker', JSON.stringify(datasource)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasources'])
        successToast('Data source added')
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('LOOKER_DATA_SOURCE_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useAddChildLookerDataSource = (parentDataSource?: string) => {
  return useMutation<AxiosResponse<ILookerDataSource> | undefined, any, any>(
    async (userId: number) => {
      if (!parentDataSource) return

      return api.post(`/datasources/looker/${parentDataSource}/children`, JSON.stringify({ userId }))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['looker'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('LOOKER_DATA_SOURCE_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useUpdateLookerDataSource = () => {
  return useMutation(
    (datasource: ILookerDataSourceUpdateRequest) =>
      api.patch(`/datasources/looker/${datasource.id}`, JSON.stringify(datasource)),
    {
      onSuccess: () => {
        successToast('Data source updated')
        queryClient.invalidateQueries(['looker'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('LOOKER_DATA_SOURCE_UPDATE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useDeleteLookerDataSource = () => {
  return useMutation((id: string) => api.delete(`/datasources/looker/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['looker'])
      queryClient.invalidateQueries(['datasources'])
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('LOOKER_DATA_SOURCE_DELETE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useDeleteLookerEmbedUser = () => {
  return useMutation(
    (credentials: ILookerApiCredentials) =>
      api.delete(`datasources/looker-test/embed-user`, {
        data: credentials,
      }),
    {
      useErrorBoundary: false,
    }
  )
}

export const useGetLookerFolders = ({ credentialId, disabled }: { credentialId?: string; disabled?: boolean }) => {
  return useQuery<ILookerFolder[], AxiosError>(
    ['looker/folders', credentialId],
    async ({ signal }) => {
      if (!credentialId) return

      const response = await api.get(`datasources/looker/${credentialId}/folders`, { signal })
      if (!response) {
        return []
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleLookerApiErrors(error, getMessage('LOOKER_FOLDERS_GET_ERROR'))
      },
      useErrorBoundary: false,
      enabled: !disabled,
    }
  )
}

export const useSearchLookerDashboards = ({
  credentialId,
  disabled,
  search,
}: {
  credentialId?: string
  disabled?: boolean
  search?: string
}) => {
  return useQuery<ILookerDashboard[], AxiosError>(
    ['looker/search-dashboards', credentialId, search],
    async ({ signal }) => {
      if (!credentialId || !search) return
      await queryClient.cancelQueries('looker/folders')
      await queryClient.cancelQueries('looker/dashboards')
      const response = await api.get(`datasources/looker/${credentialId}/search-dashboards`, {
        params: { search },
        signal,
      })
      if (!response) {
        return []
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleLookerApiErrors(error, getMessage('LOOKER_DASHBOARDS_SEARCH_ERROR'))
      },
      useErrorBoundary: false,
      enabled: !disabled,
    }
  )
}

export const useGetLookerFolderDashboards = ({
  credentialId,
  folderId,
  disabled,
}: {
  credentialId?: string
  folderId?: string
  disabled?: boolean
}) => {
  return useQuery<ILookerDashboard[], AxiosError>(
    ['looker/folder-dashboards', credentialId, folderId],
    async ({ signal }) => {
      if (!credentialId || !folderId || folderId === 'root') return
      const response = await api.get(`datasources/looker/${credentialId}/folders/${folderId}/dashboards`, { signal })
      if (!response) {
        return []
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleLookerApiErrors(error, getMessage('LOOKER_FOLDER_DASHBOARDS_GET_ERROR'))
      },
      useErrorBoundary: false,
      enabled: !disabled,
    }
  )
}

export interface ILookerFilter {
  id: string
  title: string
  type: string
  model: string
  dimension: string
  explore: string
  field?: {
    suggestable?: boolean
    suggest_explore?: string
    suggest_dimension?: string
    enumerations?: Array<{
      label: string
      value: string
    }>
  }
  required: boolean
}

export const useGetLookerDashboardFilters = (
  credentialId?: string,
  dashboardId?: string,
  options: { enabled: boolean } = { enabled: true }
) => {
  return useQuery<ILookerFilter[], AxiosError>(
    ['looker/dashboard-filters', { credentialId, dashboardId }],
    async () => {
      if (!credentialId || !dashboardId) return
      const response = await api.get(`datasources/looker/${credentialId}/dashboards/${dashboardId}/filters`)
      if (!response) {
        return []
      }
      return response.data?.dashboard_filters
    },
    {
      onError: (error: AxiosError) => {
        handleLookerApiErrors(error, getMessage('LOOKER_FILTERS_GET_ERROR'))
      },
      useErrorBoundary: false,
      enabled: options.enabled,
    }
  )
}

export const useGetLookerDashboardEmbedData = (
  credentialId?: string,
  dashboardId?: string,
  { enabled } = { enabled: true }
) => {
  return useQuery<IDashboard & { dashboardAbsoluteUrl: string }, AxiosError>(
    ['looker/dashboard-embed-data', { credentialId, dashboardId }],
    async () => {
      if (!credentialId || !dashboardId) return
      const response = await api.get(`datasources/looker/${credentialId}/dashboards/${dashboardId}/embed-data`)
      if (!response) {
        return []
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleLookerApiErrors(error, getMessage('LOOKER_DASHBOARD_EMBED_DATA_GET_ERROR'))
      },
      useErrorBoundary: false,
      enabled,
    }
  )
}

type GetFilterValuesParams = {
  filterId?: string
  dashboardId?: string
  credentialsId?: string
  filteredByFilterId?: string
  filteredByFilterValue?: string
  query?: string
}

export const getLookerFilterValuesQueryKey = (params: GetFilterValuesParams) => ['looker/filters-values', params]

export const getLookerFilterValues = async ({
  filterId,
  dashboardId,
  credentialsId,
  filteredByFilterId,
  filteredByFilterValue,
  query,
}: GetFilterValuesParams): Promise<string[]> => {
  if (!credentialsId || !dashboardId || !filterId) {
    return []
  }

  const response = await api.get(
    `datasources/looker/${credentialsId}/dashboards/${dashboardId}/filters/${filterId}/values`,
    {
      params: {
        filteredByFilterId,
        filteredByFilterValue,
        query,
      },
    }
  )

  return response.data
}

export const useGetLookerFilterValues = (params: GetFilterValuesParams, queryOptions?: { enabled: boolean }) => {
  return useQuery<string[], AxiosError>(getLookerFilterValuesQueryKey(params), () => getLookerFilterValues(params), {
    onError: (error: AxiosError) => {
      handleLookerApiErrors(error, getMessage('LOOKER_FILTERS_VALUES_GET_ERROR'))
    },
    enabled: queryOptions?.enabled,
    useErrorBoundary: false,
  })
}

export const useGetLookerFilterValuesCombinations = ({
  dashboardId,
  credentialsId,
  primaryFilterId,
  secondaryFilterId,
  queryOptions = { enabled: true },
}: {
  dashboardId?: string
  credentialsId?: string
  primaryFilterId?: string
  secondaryFilterId?: string
  queryOptions?: { enabled: boolean }
}) => {
  return useQuery<{ primaryValue: string; secondaryValue: string }[], AxiosError>(
    ['looker/filters-combinations', { credentialsId, dashboardId, primaryFilterId, secondaryFilterId }],
    async () => {
      if (!credentialsId || !dashboardId || !primaryFilterId || !secondaryFilterId) return []

      const response = await api.get(
        `datasources/looker/${credentialsId}/dashboards/${dashboardId}/filters-combinations?primaryFilterId=${primaryFilterId}&secondaryFilterId=${secondaryFilterId}`
      )

      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleLookerApiErrors(error, getMessage('LOOKER_FILTERS_VALUES_GET_ERROR'))
      },
      enabled: queryOptions.enabled,
      useErrorBoundary: false,
    }
  )
}

export const useUpdateLookerEmbedConfig = () => {
  return useMutation(
    (credentials: ILookerApiCredentials) =>
      api.post('/datasources/looker-test/embed-config', JSON.stringify(credentials)),
    {
      useErrorBoundary: false,
    }
  )
}

export const acquireEmbedSession = ({ dataSourceId, dashboardId }: { dataSourceId: string; dashboardId: string }) =>
  api.post(`/datasources/looker/${dataSourceId}/acquire-embed-session`, { dashboardId })

export const generateEmbedTokens = ({ dataSourceId }: { dataSourceId: string }) =>
  api.post(`/datasources/looker/${dataSourceId}/generate-embed-tokens`)

export const resetSession = ({ dataSourceId }: { dataSourceId: string }) =>
  api.post(`/datasources/looker/${dataSourceId}/reset-session`)

export const acquireEmbedSessionForConnectionTest = ({
  baseUrl,
  clientId,
  clientSecret,
  dashboardId,
}: {
  baseUrl: string
  clientId: string
  clientSecret: string
  dashboardId: string
}) =>
  api.post(`/datasources/looker-test/acquire-embed-session`, {
    baseUrl,
    clientId,
    clientSecret,
    dashboardId,
  })

export const generateEmbedTokensForConnectionTest = ({
  baseUrl,
  clientId,
  clientSecret,
}: {
  baseUrl: string
  clientId: string
  clientSecret: string
}) =>
  api.post(`/datasources/looker-test/generate-embed-tokens`, {
    baseUrl,
    clientId,
    clientSecret,
  })

interface ILookerApiVersionResponse {
  major?: string
  minor?: string
  patch?: string
  version?: string
}
export const useGetLookerVersion = () => {
  return useMutation<AxiosResponse<ILookerApiVersionResponse>, any, any>(
    (baseUrl: string) => api.post('/datasources/looker/version', JSON.stringify({ baseUrl })),
    {
      useErrorBoundary: false,
    }
  )
}
