export type EmailTemplateSettings = {
  updateDestination: {
    button: {
      text: string
      bgColor: string
      textColor: string
    }
  }
  updateCollection: {
    button: {
      text: string
      bgColor: string
      textColor: string
    }
  }
}

export enum EEmailTemplateType {
  destinationUpdate = 'destinationUpdate',
  collectionUpdate = 'collectionUpdate',
  emailDestination = 'emailDestination',
}

export interface EmailTemplate<T extends keyof EmailTemplateSettings = any> {
  id?: string

  type: EEmailTemplateType

  name: string
  content: string
  subject: string

  createdAt?: string
  updatedAt?: string

  settings?: EmailTemplateSettings[T]

  dirty?: boolean
}

export type EmailTemplateCreateRequest = Omit<EmailTemplate, 'id' | 'createdAt' | 'updatedAt'> & {
  destinationId?: string
  collectionId?: string
}

export type EmailTemplateUpdateRequest = Partial<EmailTemplate>

export interface CustomEmailTemplateVariable {
  id: string
  description: string
  disabled?: boolean
  defaultValue?: string
}

export enum ESelectEmailTemplateEditorMode {
  MODAL = 'modal',
  INLINE = 'inline',
  SELECT_ONLY = 'selectOnly',
}

export type SelectTemplateOption = {
  label: string
  value?: string | null
}
