import { UpdateMethod } from '@features/destinations/types'

import { HistoricalRunTrigger } from './types'

export const mapUpdateMethodToLabel = (updateMethod: UpdateMethod): string => {
  switch (updateMethod) {
    case UpdateMethod.Archive:
      return 'Archive & Update'
    case UpdateMethod.Update:
      return 'Update same file'
    case UpdateMethod.UpdateVisualizations:
      return 'Update visualizations'
  }
}

export const mapTriggerToLabel = (trigger: HistoricalRunTrigger): string => {
  switch (trigger) {
    case 'manual':
      return 'Manual'
    case 'scheduled':
      return 'Scheduled'
  }
}
