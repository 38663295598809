import { FC, useMemo } from 'react'

interface IArrow {
  orientation?: 'top' | 'right' | 'bottom' | 'left'
  size?: number
}

const defaultSize = 16

export const ArrowNarrow: FC<IArrow> = ({ orientation = 'top', size = defaultSize }) => {
  const rotation = useMemo(() => {
    switch (orientation) {
      case 'top':
        return '2700deg'
      case 'right':
        return '0deg'
      case 'bottom':
        return '90deg'
      case 'left':
        return '180deg'
    }
  }, [orientation])
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      style={{ transform: `rotate(${rotation})` }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12H20M20 12L14 6M20 12L14 18"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
