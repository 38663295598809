import { Editor } from '@tiptap/react'
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  Code2Icon,
  DropletIcon,
  EraserIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  Heading6Icon,
  ImageIcon,
  ItalicIcon,
  ListIcon,
  ListOrderedIcon,
  MinusIcon,
  RedoIcon,
  StrikethroughIcon,
  UnderlineIcon,
  UndoIcon,
} from 'lucide-react'
import { FC } from 'react'

import { IIcon } from '@assets/types'

import { useBoolean } from '@shared/hooks'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

type Option = { label: string; value: string }

const FONT_FAMILY_OPTIONS = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Verdana', label: 'Verdana' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Courier New', label: 'Courier New' },
]

const LINE_HEIGHT_OPTIONS = [
  { value: '1', label: 'Single' },
  { value: '1.15', label: '1.15' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: 'Double' },
]

type IEditorMenuBarSettingItem =
  | {
      type?: 'button'
      icon?: FC<IIcon>
      onClick?: () => void
      disabled?: boolean
      isActive?: boolean
      tooltip?: string
    }
  | {
      type: 'color'
      icon?: FC<IIcon>
      color: string
      onColorChange: (color: string) => void
      tooltip?: string
    }
  | {
      type: 'select'
      options: Option[]
      placeholder: string
      onChange: (option: Option) => void
      value?: Option
    }

interface IEditorMenuBarSettingGroup {
  hide?: boolean
  items: IEditorMenuBarSettingItem[]
}

export const useEditorMenuBarSettings = ({
  editor,
  isHTMLEditorMode,
  toggleHTMLEditorMode,
}: {
  editor: Editor
  isHTMLEditorMode: boolean
  toggleHTMLEditorMode: () => void
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useBoolean(false)
  const [isAddImageLinkModalOpen, setIsAddImageLinkModal] = useBoolean(false)
  const settingGroups: IEditorMenuBarSettingGroup[] = [
    {
      hide: isHTMLEditorMode,
      items: [
        {
          type: 'select',
          placeholder: 'Font Family',
          options: FONT_FAMILY_OPTIONS,
          onChange: (option: Option) => editor.chain().focus().setFontFamily(option.value).run(),
          value: FONT_FAMILY_OPTIONS.find(option => editor.isActive('textStyle', { fontFamily: option.value })),
        },
        {
          type: 'select',
          placeholder: 'Line Height',
          options: LINE_HEIGHT_OPTIONS,
          onChange: (option: Option) => editor.chain().focus().setLineHeight(option.value).run(),
          value: LINE_HEIGHT_OPTIONS.find(option => editor.isActive('textStyle', { lineHeight: option.value })),
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: BoldIcon,
          onClick: () => editor.chain().focus().toggleBold().run(),
          disabled: !editor.can().chain().focus().toggleBold().run(),
          isActive: editor.isActive('bold'),
          tooltip: 'Bold',
        },
        {
          icon: ItalicIcon,
          onClick: () => editor.chain().focus().toggleItalic().run(),
          disabled: !editor.can().chain().focus().toggleItalic().run(),
          isActive: editor.isActive('italic'),
          tooltip: 'Italic',
        },
        {
          icon: UnderlineIcon,
          onClick: () => editor.chain().focus().toggleUnderline().run(),
          disabled: !editor.can().chain().focus().toggleUnderline().run(),
          isActive: editor.isActive('underline'),
          tooltip: 'Underline',
        },
        {
          icon: StrikethroughIcon,
          onClick: () => editor.chain().focus().toggleStrike().run(),
          disabled: !editor.can().chain().focus().toggleStrike().run(),
          tooltip: 'Strikethrough',
        },
        {
          type: 'color',
          icon: DropletIcon,
          color: editor.isActive('textStyle') ? editor.getAttributes('textStyle')?.color : '#000',
          onColorChange: (color: string) => editor.chain().setColor(color).run(),
          tooltip: 'Text Color',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: EraserIcon,
          onClick: () => editor.chain().focus().unsetAllMarks().run(),
          tooltip: 'Clear Formatting',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: MinusIcon,
          onClick: () => editor.chain().focus().setHorizontalRule().run(),
          tooltip: 'Horizontal Line',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: ImageIcon,
          onClick: () => setIsAddImageLinkModal(true),
          tooltip: 'Insert Image',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: ListIcon,
          onClick: () => editor.chain().focus().toggleBulletList().run(),
          isActive: editor.isActive('bulletList'),
          tooltip: 'Bullet List',
        },
        {
          icon: ListOrderedIcon,
          onClick: () => editor.chain().focus().toggleOrderedList().run(),
          isActive: editor.isActive('orderedList'),
          tooltip: 'Numbered List',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: AlignLeftIcon,
          onClick: () => editor.chain().focus().setTextAlign('left').run(),
          isActive: editor.isActive({ textAlign: 'left' }),
          tooltip: 'Align Left',
        },
        {
          icon: AlignCenterIcon,
          onClick: () => editor.chain().focus().setTextAlign('center').run(),
          isActive: editor.isActive({ textAlign: 'center' }),
          tooltip: 'Align Center',
        },
        {
          icon: AlignRightIcon,
          onClick: () => editor.chain().focus().setTextAlign('right').run(),
          isActive: editor.isActive({ textAlign: 'right' }),
          tooltip: 'Align Right',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: Heading1Icon,
          onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: editor.isActive('heading', { level: 1 }),
          tooltip: 'Heading 1',
        },
        {
          icon: Heading2Icon,
          onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: editor.isActive('heading', { level: 2 }),
          tooltip: 'Heading 2',
        },
        {
          icon: Heading3Icon,
          onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: editor.isActive('heading', { level: 3 }),
          tooltip: 'Heading 3',
        },
        {
          icon: Heading4Icon,
          onClick: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: editor.isActive('heading', { level: 4 }),
          tooltip: 'Heading 4',
        },
        {
          icon: Heading5Icon,
          onClick: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: editor.isActive('heading', { level: 5 }),
          tooltip: 'Heading 5',
        },
        {
          icon: Heading6Icon,
          onClick: () => editor.chain().focus().toggleHeading({ level: 6 }).run(),
          isActive: editor.isActive('heading', { level: 6 }),
          tooltip: 'Heading 6',
        },
      ],
    },
    {
      hide: isHTMLEditorMode,
      items: [
        {
          icon: UndoIcon,
          onClick: () => editor.chain().focus().undo().run(),
          disabled: !editor.can().chain().focus().undo().run(),
          tooltip: 'Undo',
        },
        {
          icon: RedoIcon,
          onClick: () => editor.chain().focus().redo().run(),
          disabled: !editor.can().chain().focus().redo().run(),
          tooltip: 'Redo',
        },
      ],
    },
    {
      hide: !isFeatureEnabled('email-templates-html-editor'),
      items: [
        {
          icon: Code2Icon,
          onClick: () => toggleHTMLEditorMode(),
          tooltip: isHTMLEditorMode ? 'Switch to text editor' : 'Switch to HTML editor',
        },
      ],
    },
  ]
  return {
    isAddImageLinkModalOpen,
    closeAddImageLinkModal: () => setIsAddImageLinkModal(false),
    fontFamilyOptions: FONT_FAMILY_OPTIONS,
    lineHeightOptions: LINE_HEIGHT_OPTIONS,
    settingGroups,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
  }
}
