import { Navigate } from 'react-router-dom'

import { CollectionSetup } from '@pages/collections/collection-setup/collection-setup'
import { Collection } from '@pages/collections/collection/collection'
import { Collections } from '@pages/collections/collections'

import { CollectionCrumb, CollectionsCrumb } from '@shared/components/breadcrumbs/breadcrumbs'

import { CollectionTabs, PathSegments } from './paths'

export const collectionRoutes = {
  path: PathSegments.COLLECTIONS,
  handle: { crumb: CollectionsCrumb },
  children: [
    {
      index: true,
      element: <Collections />,
    },
    {
      path: PathSegments.COLLECTION_SETUP,
      element: <CollectionSetup />,
    },
    {
      path: PathSegments.COLLECTION_ID,
      handle: { crumb: CollectionCrumb },
      children: [
        {
          index: true,
          element: <Navigate to={CollectionTabs.VARIANTS} replace relative="path" />,
        },
        {
          path: CollectionTabs.VARIANTS,
          element: <Collection />,
        },
        {
          path: CollectionTabs.SENDING,
          element: <Collection />,
        },
        {
          path: CollectionTabs.SETTINGS,
          element: <Collection />,
        },
        {
          path: CollectionTabs.UPDATE_SETTINGS,
          element: <Navigate to={`../${CollectionTabs.SETTINGS}`} replace relative="path" />,
        },
      ],
    },
  ],
}
