import React, { useState } from 'react'

import {
  MicrosoftLockWarning,
  ModalStatus,
  UpdatePresentationFinished,
  isGoogleDestination,
  isMicrosoftDestination,
  useArchiveAndUpdate,
} from '@features/destinations'
import { Destination } from '@features/destinations/types'

import { useModalWithData } from '@shared/hooks/use-modal-with-data'

import { buildModalProps } from './utils'

export interface WithDestinationVersionProps {
  createVersion: (presentation: Destination) => Promise<void>
  versionLoading: boolean
}

export interface IDestinationRef {
  back: () => void
}

export const withDestinationVersion = <T,>(WrappedComponent: React.ComponentType<T & WithDestinationVersionProps>) => {
  return (props: T) => {
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [docId, setDocId] = useState<string | undefined>('')
    const [presentation, setPresentation] = useState<Destination>()
    const [updateStatus, setUpdateStatus] = useState<ModalStatus | null>(null)
    const [modal, destination, toggleModal, openModalWithData] = useModalWithData<Destination>()
    const createVersionQuery = useArchiveAndUpdate({ destination })

    const createVersion = async () => {
      if (!destination) return

      setProcessing(true)
      setDocId(destination.docId)
      setPresentation(destination)

      await createVersionQuery.mutateAsync({ id: destination.id })
      setProcessing(false)
      toggleModal()
    }

    let { modalTitle, modalSubtitle, linkToDestination } = buildModalProps({
      action: 'Update',
      destinationType: presentation?.type,
      docId,
    })

    const createVersionHandler = async (destination: Destination) => {
      if (isGoogleDestination(destination)) {
        await createVersionQuery.mutateAsync({ id: destination.id })
      } else if (isMicrosoftDestination(destination)) {
        setLoading(true)
        openModalWithData(destination)
      }
    }

    const closeModal = () => {
      setLoading(false)
      toggleModal()
    }

    return (
      <>
        <WrappedComponent {...props} createVersion={createVersionHandler} versionLoading={loading} />
        <UpdatePresentationFinished
          title={modalTitle + ' New version created!'}
          subtitle={modalSubtitle}
          linkToDestination={linkToDestination}
          status={updateStatus}
          visible={Boolean(updateStatus)}
          onNext={() => setUpdateStatus(null)}
        />
        <MicrosoftLockWarning
          open={modal}
          onClose={closeModal}
          onOk={createVersion}
          processing={processing}
          type={destination?.type}
        />
      </>
    )
  }
}
