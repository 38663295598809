import { DebouncedFunc } from 'lodash'
import { FC } from 'react'

import { IView } from '@features/data-sources'
import { isPublicTableau, useTableau } from '@features/embedding'
import { ITableauObject, ITableauSize } from '@features/embedding/tableau/types'
import { IntegrationEmbedCommonProps } from '@features/embedding/types'

import { RollstackTabs, Spinner } from '@shared/components'

import { SyncFilterOverlay } from './sync-filter-overlay'
import { SyncWorksheetFullOverlay } from './sync-worksheet-full-overlay'
import { SyncWorksheetOverlay } from './sync-worksheet-overlay'
import { TableauIframe } from './tableau-iframe'

import styles from './tableau-embed.module.css'

const magicNumberForNoScroll = 7

interface TableauEmbedProps extends IntegrationEmbedCommonProps<ITableauObject> {
  tabIndex: number
  tableauData: ReturnType<typeof useTableau>
  disableViewChange?: boolean
  loading?: boolean
  fullDashboard: boolean
  tableauVizToken: { token?: string }
  fullOverlaySize: ITableauSize
  viewRangeFilters: any[]
  selectedFilterObject: string | null
  selectedView: IView | null
  toggleFullDashboard(): void
  handleShowDateSettingsPopup: (fieldName: string) => void
  handleSelectViewDebounced: DebouncedFunc<(view: IView | null) => void>
}

export const TableauEmbed: FC<TableauEmbedProps> = ({
  tableauData,
  tabIndex,
  loading = false,
  disableViewChange = false,
  showOverlays,
  fullDashboard,
  tableauVizToken,
  fullOverlaySize,
  viewRangeFilters,
  selectedFilterObject,
  selectedView,
  handleSelectViewDebounced,
  handleShowDateSettingsPopup,
  toggleFullDashboard,
}) => {
  const {
    tableauVizKey,
    state,
    tableauRef,
    tableauToken,
    viewOptions,
    sheetOptions,
    sheetObjects,
    dashboardSize: {
      size: { height, width },
    },
    setTableauViz,
    handleSelectSheet,
    handleUnselectSheet,
    updateDateRangeFilter,
  } = tableauData

  const tabs = viewOptions
    .map(option => option.name)
    .filter((_, index) => (disableViewChange ? index === tabIndex : true))

  const selectedViewIndex = viewOptions.findIndex(v => v.id === selectedView?.id)

  return (
    <>
      {viewOptions && tabIndex !== -1 && (
        <RollstackTabs
          tabs={tabs}
          tabListClassName={styles.tabList}
          onSelect={viewIdx => handleSelectViewDebounced(viewOptions[viewIdx])}
          selectedIndex={selectedViewIndex}
        >
          {tabs.map((_, idx) => (
            <div key={idx} />
          ))}
        </RollstackTabs>
      )}

      <div className={styles.tableauEmbed}>
        {loading && (
          <div className={styles.spinnerContainer}>
            <Spinner center={false} />
          </div>
        )}

        {tableauToken && state.embedUrl && (
          <div className={styles.vizOuterDiv}>
            {showOverlays && fullDashboard && (
              <SyncWorksheetFullOverlay onClick={toggleFullDashboard} size={fullOverlaySize} />
            )}

            {showOverlays &&
              !fullDashboard &&
              sheetOptions.length > 0 &&
              sheetObjects &&
              sheetObjects.map((object, objIndex) => (
                <SyncWorksheetOverlay
                  key={objIndex}
                  object={object}
                  selectedSheets={state.selectedSheets}
                  handleSelectSheet={handleSelectSheet}
                  handleUnselectSheet={handleUnselectSheet}
                  isPublicDashboard={isPublicTableau(state.embedUrl)}
                />
              ))}

            {showOverlays &&
              viewRangeFilters.length > 0 &&
              viewRangeFilters.map((filterObject, filterIndex) => (
                <SyncFilterOverlay
                  key={filterIndex}
                  filterObject={filterObject}
                  showDateSettingsPopup={selectedFilterObject === filterObject._fieldName}
                  updateDateRangeFilter={updateDateRangeFilter}
                  handleShowDateSettingsPopup={() => handleShowDateSettingsPopup(filterObject._fieldName)}
                />
              ))}

            <TableauIframe
              {...tableauVizToken}
              key={`${tableauVizKey}${tableauVizToken.token}`}
              width={width.toString()}
              height={(height + magicNumberForNoScroll).toString()}
              src={state.embedUrl}
              ref={el => {
                tableauRef.current = el
                setTableauViz(el)
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
