import dayjs from 'dayjs'
import { SignJWT } from 'jose'
import { uniq, uniqWith } from 'lodash'
import isObject from 'lodash/isObject'
import { v4 as uuid } from 'uuid'

import { TWO_FILTERS } from '@features/collections/consts'
import { FilterOption, ParsedVariant } from '@features/collections/types'
import { TableauCredentials } from '@features/data-sources/types'
import { FilterType } from '@features/filters/types'

import {
  ETableauScriptUrl,
  IFilterValues,
  IRangeValues,
  IRelativeDate,
  ITableauDataValue,
  ITableauFilterObject,
  ITableauParameterObject,
  ITableauRangeFilter,
} from './types'

const TYPE = 'JWT'
const ALGORITHM = 'HS256'
const MAX_TOKEN_AGE = '600s'
const TABLEAU_AUDIENCE = 'tableau'
const TABLEAU_SCOPES = ['tableau:views:embed', 'tableau:metrics:embed', 'tableau:content:read']

export const getJWT = async ({ clientId, secretId, secretValue, username }: TableauCredentials) => {
  if (!clientId || !secretId || !secretValue || !username) {
    return ''
  }
  const secret = new TextEncoder().encode(secretValue)
  const uniqueId = uuid()

  const jwt = await new SignJWT({ scp: TABLEAU_SCOPES })
    .setProtectedHeader({ alg: ALGORITHM, typ: TYPE, kid: secretId, iss: clientId })
    .setIssuedAt()
    .setExpirationTime(MAX_TOKEN_AGE)
    .setAudience(TABLEAU_AUDIENCE)
    .setIssuer(clientId)
    .setSubject(username)
    .setJti(uniqueId)
    .sign(secret)

  return jwt
}

export const getWorkbookUrl = (baseUrl: string, siteName: string, workbookContentUrl: string, viewUrlName: string) => {
  if (isPublicTableau(baseUrl)) {
    return baseUrl
  }

  if (siteName) {
    return `${baseUrl}/#/site/${siteName}/views/${workbookContentUrl}/${viewUrlName}`
  }
  return `${baseUrl}/#/views/${workbookContentUrl}/${viewUrlName}`
}

// ================================ Parse Filters Tableau ================================
export const getReadableFilters = (data: any) => {
  if (!data) return []
  return Object.keys(data)
    .map(key => {
      let filterValue = data[key]
      let displayValue

      if (Array.isArray(filterValue)) {
        displayValue = filterValue.join(', ')
      } else if (filterValue.isAllSelected) {
        displayValue = 'All'
      } else if (filterValue.values) {
        displayValue = filterValue.values.join(', ')
      } else if (filterValue.minValue && filterValue.maxValue) {
        let minValue
        let maxValue
        if (filterValue.typeValue === 'any') {
          minValue = filterValue.minValue
          maxValue = filterValue.maxValue
        } else {
          minValue = dayjs(new Date(filterValue.minValue)).format('YYYY-MM-DD')
          maxValue = dayjs(new Date(filterValue.maxValue)).format('YYYY-MM-DD')
        }
        displayValue = `${minValue} - ${maxValue}`
      } else if (filterValue.rangeN && filterValue.rangeType && filterValue.periodType) {
        // Handle periodical filters
        displayValue = `${filterValue.rangeType.charAt(0).toUpperCase() + filterValue.rangeType.slice(1)} ${
          filterValue.rangeN
        } ${filterValue.periodType}`
      } else if (isObject(filterValue)) {
        displayValue = JSON.stringify(filterValue)
      } else {
        displayValue = filterValue
      }

      return { key, displayValue }
    })
    .filter(filter => filter.displayValue) // remove empty filters (like isAllSelected)
}

/**
 * Check if the url is a public tableau url
 * @param {string} url
 * @returns
 */
export const isPublicTableau = (url: string) => !!url && url.startsWith(ETableauScriptUrl.Public)

export const isOnlineTableau = (url: string) =>
  !!url && url.includes('tableau.com') && !url.startsWith(ETableauScriptUrl.Public)

/**
 * get tableau URL with disabled toolbar
 * @param {string} url
 * @returns {boolean}
 */
export const isValidUrl = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}

// if the filter value is a number with commas, remove the commas
// tableau doesn't accept commas in filter values even though it gives them as _formattedValue
export const fixTableauFilterValues = (input: string): string => {
  const numberWithCommasPattern = /^[\d,]+$/
  if (numberWithCommasPattern.test(input)) {
    return input.replace(/,/g, '')
  }

  return input
}

export const removeActionPrefix = (input: string): string => {
  const actionRegex = /^Action \((.+?)\)$/
  const match = input.match(actionRegex)
  return match ? match[1] : input
}

const findWorksheetWithFiltersOrThrow = async (
  filters: FilterOption[],
  worksheets: any[]
): Promise<{ worksheet: any; filtersColumns: any[] }> => {
  for (const worksheet of worksheets) {
    let dataTableReader
    try {
      const underlyingTablesData = await worksheet.getUnderlyingTablesAsync()
      dataTableReader = await worksheet.getUnderlyingTableDataReaderAsync(underlyingTablesData[0].id, 1, {
        ignoreSelection: true,
        includeAllColumns: true,
        maxRows: 1,
      })
      const firstPage = await dataTableReader.getPageAsync(0)

      const filtersColumns: any[] = []
      for (const filter of filters) {
        const foundFilterColumn = firstPage.columns.find(
          (column: any) => column.fieldName === removeActionPrefix(filter.name)
        )
        if (foundFilterColumn) {
          filtersColumns.push(foundFilterColumn)
        }
      }

      if (filtersColumns.length === filters.length) {
        return {
          worksheet,
          filtersColumns,
        }
      }

      console.log('Filters not found in this worksheet', { worksheet: worksheet.name })
    } catch (error) {
      console.error('Failed to get underlying data for this worksheet', { error, worksheet: worksheet.name })
    } finally {
      if (dataTableReader) {
        try {
          await dataTableReader.releaseAsync()
        } catch (err) {
          console.error('Unable to release dataTableReader')
        }
      }
    }
  }

  throw new Error(`Failed to find a worksheet with columns containing filter's data `)
}

const getUnderlyingDataFromWorksheet = async (worksheet: any, filtersColumns: any[]) => {
  const underlyingTablesData = await worksheet.getUnderlyingTablesAsync()
  const dataTableReader = await worksheet.getUnderlyingTableDataReaderAsync(underlyingTablesData[0].id, undefined, {
    columnsToIncludeById: filtersColumns.map(filter => filter.fieldId),
    ignoreSelection: true,
  })

  let rows: any[] = []
  let columns: any[] = []

  for (let i = 0; i < dataTableReader.pageCount; i++) {
    const page = await dataTableReader.getPageAsync(i)
    rows = rows.concat(page.data)
    columns = page.columns
  }

  return {
    rows,
    columns,
  }
}

const findFilterOrParamObject = (
  filter: FilterOption,
  viewFilters: ITableauFilterObject[],
  viewParameters: ITableauParameterObject[]
) => {
  if (filter?.type === FilterType.TableauFilter) {
    return viewFilters.find(f => f._fieldId === filter.dashboardFilterId)
  }

  if (filter?.type === FilterType.TableauParams) {
    return viewParameters.find(p => p.id === filter.dashboardFilterId)
  }
}

const getFilterValues = (
  filter: FilterOption,
  viewFilters: ITableauFilterObject[],
  viewParameters: ITableauParameterObject[]
) => {
  if (filter?.type === FilterType.TableauFilter) {
    const foundFilter = viewFilters.find(f => f._fieldId === filter.dashboardFilterId)
    return foundFilter?.domain?.values.map(v => v.value.toString()) ?? []
  }

  if (filter?.type === FilterType.TableauParams) {
    const foundParam = viewParameters.find(p => p.id === filter.dashboardFilterId)
    const isListParam = foundParam?.allowableValues.type === 'list'
    const allowableValues = foundParam?.allowableValues?.allowableValues
    return allowableValues?.map(v => (isListParam ? v.formattedValue : v.value.toString())) ?? []
  }
}

export const getValidVariantsFromWorksheet = async (
  filters: FilterOption[],
  worksheets: any[],
  viewFilters: ITableauFilterObject[],
  viewParams: ITableauParameterObject[]
): Promise<{
  primaryFilterValues?: string[]
  secondaryFilterValues?: string[]
  filterValuesCombinations?: ParsedVariant[]
}> => {
  if (filters.length && filters.length > TWO_FILTERS) {
    throw new Error(`Invalid number of filters: ${filters.length}`)
  }

  if (filters.length === 1) {
    console.log('1 Filter - Extracting values from filter domain')

    const primaryFilterValues =
      getFilterValues(filters[0], viewFilters, viewParams)?.sort((a, b) => a.localeCompare(b)) ?? []
    return {
      primaryFilterValues,
    }
  }

  if (filters.length === TWO_FILTERS) {
    try {
      console.log('2 Filters - Extracting values from worksheet')

      const { worksheet, filtersColumns } = await findWorksheetWithFiltersOrThrow(filters, worksheets)

      await Promise.allSettled(viewFilters.map(filter => worksheet.clearFilterAsync(filter._fieldName)))

      const { rows, columns } = await getUnderlyingDataFromWorksheet(worksheet, filtersColumns)

      const primaryFilterObj = findFilterOrParamObject(filters[0], viewFilters, viewParams)
      const secondaryFilterObj = findFilterOrParamObject(filters[1], viewFilters, viewParams)

      const primaryFilterValueProp =
        (primaryFilterObj as ITableauParameterObject)?.allowableValues?.type === 'list' ? 'formattedValue' : 'value'
      const secondaryFilterValueProp =
        (secondaryFilterObj as ITableauParameterObject)?.allowableValues?.type === 'list' ? 'formattedValue' : 'value'

      const columnIdxPrimaryFilter = columns.findIndex(column => column.fieldId === filtersColumns[0].fieldId)
      const columnIdxSecondaryFilter = columns.findIndex(column => column.fieldId === filtersColumns[1].fieldId)

      const uniqueFilterCombinations = uniqWith(
        rows,
        (row1: ITableauDataValue[], row2: ITableauDataValue[]) =>
          row1[0][primaryFilterValueProp] === row2[0][primaryFilterValueProp] &&
          row1[1][secondaryFilterValueProp] === row2[1][secondaryFilterValueProp]
      )

      const primaryFilterValues = uniq(
        uniqueFilterCombinations.map(row => row[columnIdxPrimaryFilter][primaryFilterValueProp].toString())
      ).sort((a: string, b: string) => a.localeCompare(b))

      const secondaryFilterValues = uniq(
        uniqueFilterCombinations.map(row => row[columnIdxSecondaryFilter][secondaryFilterValueProp].toString())
      ).sort((a: string, b: string) => a.localeCompare(b))

      const filterValuesCombinations = uniqueFilterCombinations
        .map(row => ({
          primaryValue: row[columnIdxPrimaryFilter][primaryFilterValueProp].toString(),
          secondaryValue: row[columnIdxSecondaryFilter][secondaryFilterValueProp].toString(),
        }))
        .sort((a, b) => {
          const primaryComparation = a.primaryValue.localeCompare(b.primaryValue)
          if (primaryComparation === 0) {
            return a.secondaryValue.localeCompare(b.secondaryValue)
          }
          return primaryComparation
        })

      return {
        primaryFilterValues,
        secondaryFilterValues,
        filterValuesCombinations,
      }
    } catch (err) {
      console.log('2 Filters - Extracting values from domain, generating combinations')

      const primaryFilterValues = getFilterValues(filters[0], viewFilters, viewParams) ?? []
      const secondaryFilterValues = getFilterValues(filters[1], viewFilters, viewParams) ?? []

      const filterValuesCombinations = primaryFilterValues
        .map(primaryValue =>
          secondaryFilterValues.map(secondaryValue => ({
            primaryValue,
            secondaryValue,
          }))
        )
        .flat()

      return {
        primaryFilterValues,
        secondaryFilterValues,
        filterValuesCombinations,
      }
    }
  }

  return {}
}

export const isRangeValues = (
  values: IRelativeDate | ITableauRangeFilter | IRangeValues | IFilterValues
): values is IRangeValues => {
  return values.hasOwnProperty('maxValue')
}
