declare global {
  interface Window {
    OneDrive?: any
  }
}

export type MicrosoftPickerSuccessResponse = {
  value: {
    endpoint: string
    id: string
    name: string
    parentReference: {
      driveId: string
      id: string
    }
    sharePoint: {
      listId: string
      uniqueId: string
    }
  }[]
  webUrl: string | null
  errorCode?: string
  message?: string
  apiEndpoint?: string
}

export enum MicrosoftOAuthScopes {
  DEFAULT = '.default',
}

export enum OneDrivePickerType {
  PowerPoint = 'PowerPoint',
  Word = 'Word',
  Folder = 'Folder',
}
