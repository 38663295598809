import { useCallback, useEffect, useRef, useState } from 'react'

import { PickerConfiguration, defaultConfiguration } from './types'
import { useInjectScript } from './use-inject-script'

type TokenResponse = google.accounts.oauth2.TokenResponse

interface IUseDrivePicker {
  authResult: TokenResponse | undefined
  openPicker: (config: PickerConfiguration) => boolean | undefined
}

export const useDrivePicker = (): IUseDrivePicker => {
  const defaultScopes = ['https://www.googleapis.com/auth/drive.readonly']
  const [loaded, error] = useInjectScript('https://apis.google.com/js/api.js')
  const [loadedGsi, errorGsi] = useInjectScript('https://accounts.google.com/gsi/client')
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false)
  const [openAfterAuth, setOpenAfterAuth] = useState(false)
  const [config, setConfig] = useState<PickerConfiguration>(defaultConfiguration)
  const [authResult, setAuthResult] = useState<TokenResponse>()

  let picker = useRef<google.picker.PickerBuilder>()

  const onPickerApiLoad = useCallback(() => {
    setPickerApiLoaded(true)
  }, [setPickerApiLoaded])

  // load the Drive picker api
  const loadApis = useCallback(() => {
    window.gapi.load('auth', { callback: () => console.log('auth loaded') })
    window.gapi.load('picker', { callback: onPickerApiLoad })
  }, [onPickerApiLoad])

  // get the apis from googleapis
  useEffect(() => {
    if (loaded && !error && loadedGsi && !errorGsi && !pickerApiLoaded) {
      loadApis()
    }
  }, [loaded, error, loadedGsi, errorGsi, pickerApiLoaded, loadApis])

  const createPicker = useCallback(
    ({
      token,
      appId = config.clientId,
      supportDrives = false,
      developerKey,
      viewId = 'DOCS',
      disabled,
      multiselect,
      showUploadView = false,
      showUploadFolders,
      setParentFolder = '',
      viewMimeTypes,
      customViews,
      locale = 'en',
      query = '',
      setIncludeFolders,
      setSelectFolderEnabled,
      disableDefaultView = false,
      title,
      callbackFunction,
    }: PickerConfiguration) => {
      if (disabled) return false

      const view = new google.picker.DocsView(google.picker.ViewId[viewId]).setMode(google.picker.DocsViewMode.LIST)

      if (viewMimeTypes) {
        view.setMimeTypes(viewMimeTypes)
      }

      if (setIncludeFolders) {
        view.setIncludeFolders(true)
      }

      if (setSelectFolderEnabled) {
        view.setSelectFolderEnabled(true)
      }

      if (query) {
        ;(view as any).setQuery(query)
      }

      const uploadView = new google.picker.DocsUploadView()

      if (showUploadFolders) {
        uploadView.setIncludeFolders(true)
      }

      if (setParentFolder) {
        uploadView.setParent(setParentFolder)
        view.setParent(setParentFolder)
      }

      picker.current = new google.picker.PickerBuilder()
        .setAppId(appId)
        .setOAuthToken(token!)
        .setDeveloperKey(developerKey)
        .setLocale(locale)
        .setCallback(callbackFunction)

      if (title) {
        picker.current.setTitle(title)
      }

      if (!disableDefaultView) {
        picker.current.addView(view)
      }

      if (customViews) {
        customViews.map(view => picker.current?.addView(view))
      }

      if (multiselect) {
        picker.current.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      }

      if (showUploadView) {
        picker.current.addView(uploadView)
      }

      if (supportDrives) {
        picker.current.enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      }

      picker.current.build().setVisible(true)
      return true
    },
    [config]
  )

  // use effect to open picker after auth
  useEffect(() => {
    if (openAfterAuth && config.token && loaded && !error && loadedGsi && !errorGsi && pickerApiLoaded) {
      createPicker(config)
      setOpenAfterAuth(false)
    }
  }, [openAfterAuth, config.token, loaded, error, loadedGsi, errorGsi, pickerApiLoaded, config, createPicker])

  // open the picker
  const openPicker = (config: PickerConfiguration) => {
    // global scope given conf
    setConfig(config)

    // if we didn't get token generate token.
    if (!config.token) {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: config.clientId,
        scope: (config.customScopes ? [...defaultScopes, ...config.customScopes] : defaultScopes).join(' '),
        callback: tokenResponse => {
          setAuthResult(tokenResponse)
          createPicker({ ...config, token: tokenResponse.access_token })
        },
      })

      client.requestAccessToken()
    }

    // if we have token and everything is loaded open the picker
    if (config.token && loaded && !error && pickerApiLoaded) {
      return createPicker(config)
    }
  }

  return { authResult, openPicker }
}
