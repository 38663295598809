import { FC, useMemo } from 'react'

import { Paths } from '@pages/routes/paths'

import { OrganizationIcon, UserIcon, VisualizationIcon } from '@assets'

import { useRoutes } from '@features/root/hooks/use-routes'
import { DirectorySync, OrganizationSettings, ProfileSettings, SingleSignOn, Usage } from '@features/settings'

import { PageContainer } from '@shared/components'
import { RoutedTabs, Tab } from '@shared/components/routed-tabs/routed-tabs'

import styles from './settings.module.css'

export const Settings: FC = () => {
  const { isOrganizationSSO, isOrganizationDirectorySync } = useRoutes()

  const usageTab = useMemo(() => {
    return {
      tabComponent: (
        <div className={styles.tab}>
          <VisualizationIcon />
          Usage
        </div>
      ),
      tabPanel: (key: React.Key) => <Usage key={key} />,
      path: Paths.SETTINGS_USAGE,
      tabIndex: 0,
    }
  }, [])

  const accountTab: Tab = useMemo(() => {
    return {
      tabComponent: (
        <div className={styles.tab}>
          <UserIcon />
          Account
        </div>
      ),
      tabPanel: (key: React.Key) => <ProfileSettings key={key} />,
      path: Paths.SETTINGS_ACCOUNT,
      tabIndex: 1,
    }
  }, [])

  const organizationTab: Tab = useMemo(() => {
    return {
      tabComponent: (
        <div className={styles.tab}>
          <OrganizationIcon />
          Organization
        </div>
      ),
      tabPanel: (key: React.Key) => {
        if (isOrganizationDirectorySync) {
          return <DirectorySync key={key} />
        }
        if (isOrganizationSSO) {
          return <SingleSignOn key={key} />
        }
        return <OrganizationSettings key={key} />
      },
      path: Paths.SETTINGS_ORGANIZATION,
      tabIndex: 2,
    }
  }, [isOrganizationDirectorySync, isOrganizationSSO])

  const tabs = [usageTab, accountTab, organizationTab]

  return (
    <PageContainer hideBackButton title="Settings" subtitle="Manage your account's settings">
      <RoutedTabs tabs={tabs} defaultTabIndex={0} defaultRoute={Paths.SETTINGS_USAGE} />
    </PageContainer>
  )
}
