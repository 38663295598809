import { useCallback, useRef } from 'react'

import { useLoadExternalScript } from '@shared/hooks/use-load-external-script'

import { MicrosoftPickerSuccessResponse } from './types'

type OneDriveOptions = {
  clientId: string
  action: string
  multiSelect: boolean
  viewType: 'folders' | 'files' | 'all'
  advanced: Partial<{
    queryParameters: string
    createLinkParameters: string
    filter: string
    redirectUri: string
    endpointHint: string
    accessToken: string
    loginHint: string
    isConsumerAccount: boolean
    scopes: string
    navigation: {
      disable: boolean
      sourceTypes: string[]
      drives: string[]
    }
  }>
  success?: (files: MicrosoftPickerSuccessResponse) => void
  cancel?: () => void
  error?: (err: any) => void
}

export const useOneDrivePicker = () => {
  const scriptStatus = useLoadExternalScript('https://js.live.net/v7.2/OneDrive.js')

  const scriptLoaded = scriptStatus === 'ready' || scriptStatus === 'loading'

  const pickerRef = useRef<any>()

  const createPicker = useCallback(
    (options: OneDriveOptions) => {
      if (!scriptLoaded) {
        console.warn('OneDrive script not loaded')
        return
      }
      const win = window.OneDrive.open(options)
      pickerRef.current = win
    },
    [scriptLoaded]
  )

  return { createPicker }
}
