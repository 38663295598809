import posthog from 'posthog-js'

import { useRoutes } from '@features/root/hooks/use-routes'

import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

export const useRoot = () => {
  const showAppVersion = isFeatureEnabled('show-app-version')
  const { isBase, isDestination, isDataSource, isSettings, isCollections, isHistoricalRuns } = useRoutes()

  const onboardingProps = {
    label: 'Get started',
    active: isBase,
  }

  const presentationProps = {
    label: 'Destinations',
    active: isDestination,
  }

  const dataSourcesProps = {
    label: 'Data sources',
    active: isDataSource,
  }

  const inviteProps = {
    label: 'Invite a teammate',
    active: false,
  }

  const settingsProps = {
    label: 'Settings',
    active: isSettings,
  }

  const collectionsProps = {
    label: 'Collections',
    active: isCollections,
  }

  const historicalRunsProps = {
    label: 'Update runs',
    active: isHistoricalRuns,
  }

  const handlePresentationsClick = () => posthog.capture('open presentations')
  const handleDataSourcesClick = () => posthog.capture('open data sources')
  const handleCollectionsClick = () => posthog.capture('open collections')
  const handleHistoricalRunsClick = () => posthog.capture('open historical runs')
  const handleInviteClick = () => posthog.capture('open invite teammate')
  const handleSettingsClick = () => posthog.capture('open settings')

  return {
    onboardingProps,
    inviteProps,
    settingsProps,
    showAppVersion,
    dataSourcesProps,
    collectionsProps,
    historicalRunsProps,
    presentationProps,
    handleInviteClick,
    handleSettingsClick,
    handleDataSourcesClick,
    handleCollectionsClick,
    handleHistoricalRunsClick,
    handlePresentationsClick,
  }
}
