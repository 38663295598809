import React, { useMemo, useState } from 'react'

import { useDeleteVariant } from '@features/collections'
import { useDeleteDestinations } from '@features/destinations'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { Destination } from '@features/destinations/types'

import { deleteDestinationToast } from '@shared/components'
import { Button } from '@shared/components/button/button'
import { ActionModal } from '@shared/components/modal/action/action-modal'
import { useModalWithData } from '@shared/hooks/use-modal-with-data'

export interface WithDestinationDeleteProps {
  deleteDestination: (destination: Destination) => void
}

export const withDestinationDelete = <T,>(WrappedComponent: React.ComponentType<T & WithDestinationDeleteProps>) => {
  return (props: T) => {
    const [processing, setProcessing] = useState(false)
    const [modal, destinationToDelete, toggleModal, openModalWithData] = useModalWithData<Destination>()

    const deleteVariantMutation = useDeleteVariant()
    const deleteDestinationQuery = useDeleteDestinations()

    const deleteDestination = async () => {
      if (!destinationToDelete) return

      setProcessing(true)
      if (destinationToDelete.variantInCollection?.collectionId) {
        await deleteVariantMutation.mutateAsync({
          collectionId: destinationToDelete.variantInCollection.collectionId,
          variantId: destinationToDelete.variantInCollection.id,
        })
      } else {
        await deleteDestinationQuery.mutateAsync(destinationToDelete.id)
      }
      setProcessing(false)
      deleteDestinationToast(destinationToDelete.type)
    }

    const isDeleting = useMemo(() => {
      return deleteDestinationQuery.isLoading || deleteVariantMutation.isLoading
    }, [deleteDestinationQuery.isLoading, deleteVariantMutation.isLoading])

    const destinationLabel = useMemo(() => {
      if (!destinationToDelete) return ''

      return destinationHandlers(destinationToDelete).getTitle()
    }, [destinationToDelete])

    return (
      <>
        <WrappedComponent {...props} deleteDestination={openModalWithData} />
        <ActionModal
          open={modal && !deleteDestinationQuery.isSuccess}
          title={`Delete ${destinationLabel}`}
          subtitle={`Are you sure you want to delete this ${destinationLabel}?`}
          onBackgroundClick={toggleModal}
          processing={processing}
          btns={
            <>
              <Button secondaryGray text="Cancel" onClick={toggleModal} fullWidth />
              <Button primaryError text="Delete" onClick={deleteDestination} fullWidth loading={isDeleting} />
            </>
          }
        />
      </>
    )
  }
}
