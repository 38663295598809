import classNames from 'classnames'
import { FC } from 'react'

import { ArrowLeft, ArrowRight } from '@assets'

import { Button, Dot } from '@shared/components'

import styles from './new-sync-embed.module.css'

interface IVisualizationProgressSteps {
  step: 'filters' | 'viz'
  nextDisabled?: boolean
  onNextClick: () => void
  onPreviousClick: () => void
}

export const ProgressSteps: FC<IVisualizationProgressSteps> = ({
  step,
  nextDisabled = false,
  onNextClick,
  onPreviousClick,
}) => {
  const isVisStep = step === 'viz'
  const isFiltersStep = step === 'filters'

  const lineClass = classNames(styles.line, isVisStep && styles.lineDone)
  const dotContainer1Class = classNames(styles.dotContainer, isFiltersStep && styles.active)
  const dotContainer2Class = classNames(styles.dotContainer, isVisStep && styles.active)
  const nextText = isFiltersStep ? 'Next' : 'Confirm'

  return (
    <div className={styles.stepsContainer}>
      <Button
        secondaryGray
        text="Previous"
        iconLeading={<ArrowLeft />}
        className={styles.footerBtn}
        onClick={onPreviousClick}
      />
      <div className={styles.steps}>
        <div className={lineClass} />
        <div className={dotContainer1Class}>
          <Dot active={isFiltersStep} done={isVisStep} />
          Select filters
        </div>
        <div className={dotContainer2Class}>
          <Dot active={isVisStep} />
          Select a visualization
        </div>
      </div>
      <div className={styles.nextBtn}>
        <Button text={nextText} disabled={nextDisabled} iconTrailing={<ArrowRight />} onClick={onNextClick} />
      </div>
    </div>
  )
}
