import noop from 'lodash/noop'

import { IView } from '@features/data-sources'
import {
  AddLookerData,
  AddMetabaseData,
  AddSpreadsheetsData,
  AddTableauData,
  ISheetInfo,
} from '@features/data-sources/types'
import { JsonObject } from '@features/data-sources/types/types'
import { Destination } from '@features/destinations/types'
import { LookerDashboardElement } from '@features/embedding'
import { MetabaseDashboardElement } from '@features/embedding/metabase/types'
import { ITableauObject, JsonTableauParameter } from '@features/embedding/tableau/types'
import { SlideData } from '@features/syncs/hooks'
import {
  CropOption,
  CropValues,
  ISync,
  IntegrationDashboard,
  SyncCoreDataSource,
  SyncUpdateType,
} from '@features/syncs/types'

import { UseSyncSettingsVanityData, VanityData, initialVanityData } from './use-vanity-data'

export type IntegrationDashboardElement = LookerDashboardElement | MetabaseDashboardElement | ITableauObject

export interface SyncData {
  slideData: SlideData
  dataSource: SyncCoreDataSource | null
  dashboardSelected: IntegrationDashboard
  sheetSelected: ISheetInfo | null
  cellRanges: string[] | null
  fullDashboard: boolean
  selectedVis: IntegrationDashboardElement[]
  manualCropValues: CropValues | null
  cropOption: CropOption
  updateType: SyncUpdateType
  tableauData?: AddTableauData
  lookerData?: AddLookerData
  metabaseData?: AddMetabaseData
  spreadsheetsData?: AddSpreadsheetsData
}

export type IntegrationDataKey = 'tableauData' | 'lookerData' | 'metabaseData'

interface ComputedSyncData {
  paramsSelected: JsonTableauParameter
  filtersSelected: JsonObject
  syncs: ISync[]
  newFiltersSelected: JsonObject
  newParamsSelected: JsonTableauParameter
}

export interface ISyncSettingsContext extends SyncData, ComputedSyncData, UseSyncSettingsVanityData {
  destination: Destination | null
  originalSync: ISync | null
  validSyncsForSelectedFilters?: string[]
  isLoadingValidSyncsForSelectedFilters: boolean
  saveSync(): Promise<void>
  setDestination: (destination: Destination) => void
  setOriginalSync: (originalSync: ISync | null) => void
  clearSyncSettings: () => void
  updateSyncSettings: (originalSync: ISync, vanityData?: VanityData) => void
  updateSyncSettingsIfChanged: (originalSync: ISync | null) => void
  updateSyncData: (data: Partial<SyncData>) => void
  toggleFullDashboard: () => void
  toggleSyncUpdateType: () => void
  clearVisSelectData: () => void
  visSelectCallback: (element: IntegrationDashboardElement | null) => void
  tableauViewSelectCallback: (view: IView) => void
  batchApplyFilters: (selectedSyncs: ISync[], ignoreOriginalSyncUpdate?: boolean) => Promise<void>
  removeFilters: () => void
  handleRemoveFilter: (key: string) => void
}

export const initialSyncData: SyncData = {
  dataSource: null,
  dashboardSelected: null,
  sheetSelected: null,
  cellRanges: null,
  fullDashboard: false,
  selectedVis: [],
  slideData: {
    slideId: '',
    slideIndex: 1,
  },
  manualCropValues: null,
  cropOption: CropOption.Auto,
  lookerData: undefined,
  metabaseData: undefined,
  tableauData: undefined,
  spreadsheetsData: undefined,
  updateType: SyncUpdateType.image,
}

const initialComputedSyncData: ComputedSyncData = {
  paramsSelected: {},
  filtersSelected: {},
  syncs: [],
  newFiltersSelected: {},
  newParamsSelected: {},
}

export const initialState: ISyncSettingsContext = {
  ...initialSyncData,
  ...initialVanityData,
  ...initialComputedSyncData,
  validSyncsForSelectedFilters: [],
  isLoadingValidSyncsForSelectedFilters: false,
  destination: null,
  originalSync: null,
  setDestination: noop,
  setOriginalSync: noop,
  clearSyncSettings: noop,
  updateSyncSettings: noop,
  updateSyncSettingsIfChanged: noop,
  updateVanityData: noop,
  updateSyncData: noop,
  clearVisSelectData: noop,
  toggleFullDashboard: noop,
  toggleSyncUpdateType: noop,
  visSelectCallback: noop,
  tableauViewSelectCallback: noop,
  saveSync: async () => {},
  batchApplyFilters: async () => {},
  removeFilters: () => {},
  handleRemoveFilter: () => {},
}
