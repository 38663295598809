import { FC } from 'react'

import { Tooltip } from '@shared/components/tooltip/tooltip'

import styles from './thumbnail-and-title.module.css'

interface ThumbnailAndTitleProps {
  Thumbnail: JSX.Element
  TypeIcon: JSX.Element
  title: string
}

export const ThumbnailAndTitle: FC<ThumbnailAndTitleProps> = ({ Thumbnail, TypeIcon, title }) => {
  return (
    <div>
      {Thumbnail}
      <div className="flex items-center justify-start w-full gap-1">
        {TypeIcon}
        <Tooltip title={title} position="top">
          <p className={styles.name}>{title}</p>
        </Tooltip>
      </div>
    </div>
  )
}
