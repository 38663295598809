import { useCallback, useEffect } from 'react'

import { IView } from '@features/data-sources'
import { isPublicTableau } from '@features/embedding'

import { SyncData } from './types'
import { parsePublicTableauUrl } from './utils'

interface UseTableauLogicProps {
  syncData: SyncData
  updateSyncData: React.Dispatch<Partial<SyncData>>
}

export const useTableauLogic = ({ syncData, updateSyncData }: UseTableauLogicProps) => {
  const { dataSource, tableauData } = syncData

  const tableauViewSelectCallback = useCallback(
    async (view: IView) => {
      if (!tableauData) return

      const viewId = view.id
      const viewUrlName = view.viewUrlName

      if (!viewId || !viewUrlName || view.id === tableauData.viewId) {
        return
      }

      updateSyncData({
        tableauData: {
          ...tableauData,
          viewId,
          viewUrlName,
          viewContentUrl: view.contentUrl,
          viewName: view.name,
          filters: '{}',
        },
      })
    },
    [tableauData, updateSyncData]
  )

  useEffect(() => {
    if (dataSource && isPublicTableau(dataSource.baseUrl)) {
      const { workbook } = parsePublicTableauUrl(dataSource.baseUrl)
      updateSyncData({ dashboardSelected: workbook })
    }
  }, [dataSource, updateSyncData])

  return { tableauViewSelectCallback }
}
