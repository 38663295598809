import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router'

import { ArrowLeft } from '@assets'

import { Breadcrumbs } from '@shared/components/breadcrumbs/breadcrumbs-builder'
import { Button } from '@shared/components/button/button'
import { ErrorBoundary } from '@shared/components/error-boundary/error-boundary'
import { Spinner } from '@shared/components/spinner/spinner'

import styles from './page-container.module.css'

interface IPageContainerProps extends PropsWithChildren {
  isProcessPage?: boolean
  title?: React.ReactNode
  rightActionButtons?: React.ReactNode
  subtitle?: string | React.ReactNode
  loading?: boolean
  className?: string
  hideBackButton?: boolean
  hideBreadcrumb?: boolean
  back?: () => void
}

export const PageContainer: FC<IPageContainerProps> = ({
  isProcessPage = false,
  title,
  rightActionButtons,
  subtitle,
  hideBreadcrumb = false,
  hideBackButton = false,
  children,
  loading,
  className,
  back,
}) => {
  const navigate = useNavigate()
  const handleBack = back ? back : () => navigate(-1)

  const containerClass = classNames(styles.container, className)

  return (
    <div className={styles.pageContainer}>
      {loading ? (
        <Spinner localCenter />
      ) : (
        <ErrorBoundary>
          {isProcessPage ? (
            children
          ) : (
            <>
              {!hideBreadcrumb && <Breadcrumbs />}
              <div className={containerClass}>
                <div className={styles.header}>
                  <div className={styles.titleAndActions}>
                    <div className={styles.backAndTitle}>
                      {!hideBackButton && (
                        <div className={styles.backBtnContainer}>
                          <Button onClick={handleBack} tertiaryColor iconLeading={<ArrowLeft />} />
                        </div>
                      )}
                      {title && <h1 className={styles.title}>{title}</h1>}
                    </div>
                    {rightActionButtons}
                  </div>
                  {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                </div>
                {children}
              </div>
            </>
          )}
        </ErrorBoundary>
      )}
    </div>
  )
}
