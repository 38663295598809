import { FC } from 'react'

import { useOrganizationsStore, useUpdateUserInOrganization } from '@features/organizations'
import { OrganizationUser, UserOrganizationRole } from '@features/organizations/types'
import { IRoleSelectPicker, getAvailableRoleOptions, getUserRoleOption } from '@features/users'

import { SelectInput } from '@shared/components/select-input/select-input'

import styles from './edit-user-role.module.css'

interface ICollectionTestEmailParams {
  member: OrganizationUser
  organizationId: string
}

export const EditUserRole: FC<ICollectionTestEmailParams> = ({ member, organizationId }) => {
  const { activeOrganization, isMember, isAdmin, isOwner } = useOrganizationsStore()
  const { mutateAsync: updateUserInOrganization } = useUpdateUserInOrganization(organizationId)

  const onRoleSelected = async (selectedRole: IRoleSelectPicker) => {
    if (member.userId && selectedRole && selectedRole.value !== member.role) {
      await updateUserInOrganization({ role: selectedRole.value, userId: member.userId })
    }
  }
  const isUpdateRoleDisabled =
    !activeOrganization || !member.userId || isMember || member.role === UserOrganizationRole.owner

  return (
    <div className={styles.input}>
      <SelectInput
        value={getUserRoleOption(member.role)}
        options={getAvailableRoleOptions(isAdmin || isOwner)}
        onChange={onRoleSelected}
        isDisabled={isUpdateRoleDisabled}
        placeholder="Select Role"
      />
    </div>
  )
}
