import { FC } from 'react'

import { SyncStatus } from '@features/syncs/types'

import { Badge, EBadgeColor } from '@shared/components'

export type DestinationStatus = 'succeeded' | 'failed' | 'partially-succeeded' | 'running' | 'cancelled' | 'inactive'

type Status = DestinationStatus | SyncStatus

const color: Record<Status, EBadgeColor> = {
  succeeded: EBadgeColor.GREEN,
  failed: EBadgeColor.RED,
  'partially-succeeded': EBadgeColor.YELLOW,
  running: EBadgeColor.SECONDARY,
  cancelled: EBadgeColor.LIGHTGRAY,
  inactive: EBadgeColor.LIGHTGRAY,
  draft: EBadgeColor.LIGHTGRAY,
  active: EBadgeColor.LIGHTGRAY,
}

interface StatusLabelProps {
  status: Status
}

export const StatusLabel: FC<StatusLabelProps> = ({ status }) => {
  return <Badge text={status} color={color[status]} />
}
