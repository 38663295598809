import { ActionModal, Button } from '@shared/components'

interface Props {
  title: string
  subtitle?: string
  open: boolean
  cancelText?: string
  onCancel: () => void
  confirmText?: string
  onConfirm: () => void
  onClose?: () => void
}

export const ConfirmModal = ({
  title,
  subtitle,
  open,
  onCancel,
  onConfirm,
  onClose,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: Props) => {
  return (
    <ActionModal
      className="z-50"
      title={title}
      subtitle={subtitle}
      open={open}
      onBackgroundClick={onClose}
      btns={
        <>
          <Button text={cancelText} secondaryGray fullWidth onClick={onCancel} />
          <Button text={confirmText} fullWidth secondaryColor onClick={onConfirm} />
        </>
      }
    />
  )
}
