import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { MicrosoftLoginResponse } from '@features/users/hooks/use-microsoft-login'

import { Button } from '@shared/components/button/button'
import { LinkButton } from '@shared/components/button/link-button/link-button'

import styles from './microsoft-auth-callback.module.css'

export const MicrosoftAuthCallback: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const hasOpener = window.opener !== null

  const [error, setError] = useState<string | null>(null)
  const [errorDescription, setErrorDescription] = useState<string | null>(null)

  const [code, setCode] = useState<string | null>(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get('code')
    const state = searchParams.get('state')
    const error = searchParams.get('error')
    const errorDescription = searchParams.get('error_description')

    let loginResponse: MicrosoftLoginResponse = {}

    if (error) {
      setError(error)
      setErrorDescription(errorDescription)
      loginResponse.error = error
      loginResponse.errorDescription = errorDescription
    }

    if (code) {
      setCode(code)
      loginResponse.code = code
      loginResponse.state = state
    }

    if (!error && !code) {
      return
    }

    if (hasOpener) {
      window.opener.postMessage(loginResponse, window.location.origin)
      window.close()
    }

    if (!hasOpener) {
      navigate(Paths.BASE)
    }
  }, [location.search, navigate, hasOpener])

  if (error) {
    return (
      <main className={styles.container}>
        <section className={styles.section}>
          <h1 className={styles.title}>Authentication failed</h1>
          <p className={styles.subtitle}>Please try again</p>
          <p className={styles.text}>{errorDescription}</p>
          {hasOpener && (
            <Button className={styles.button} text="Close window" secondaryColor onClick={() => window.close()} />
          )}
          {!hasOpener && (
            <>
              <p className={styles.text}>
                If you are not redirected please click the button below to return to the home page
              </p>
              <LinkButton className={styles.button} to={Paths.BASE} text="Home" secondaryColor />
            </>
          )}
        </section>
      </main>
    )
  }

  if (code) {
    return (
      <main className={styles.container}>
        <section className={styles.section}>
          <h1 className={styles.title}>Successful authentication with Microsoft</h1>
          <p className={styles.subtitle}>You should be redirected back to the app shortly</p>
          {hasOpener && (
            <>
              <p className={styles.text}>
                If you are not redirected, please click the button below to close this window, and return to the app
              </p>
              <Button className={styles.button} text="Close window" secondaryColor onClick={() => window.close()} />
            </>
          )}
          {!hasOpener && <LinkButton className={styles.button} to={Paths.BASE} text="Home" secondaryColor />}
        </section>
      </main>
    )
  }

  return (
    <main className={styles.container}>
      <section className={styles.section}>
        <h1 className={styles.title}>Authentication in progress</h1>
        <p className={styles.subtitle}>Please wait</p>
      </section>
    </main>
  )
}
