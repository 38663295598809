import { RowSelectionState } from '@tanstack/react-table'
import parseInt from 'lodash/parseInt'
import uniqBy from 'lodash/uniqBy'
import { useState } from 'react'

import { useBatchApplyAppliedFilters, useBatchRemoveAppliedFilters } from '@features/destinations/api/api'
import { Destination } from '@features/destinations/types'

import { useBoolean, useSelectable } from '@shared/hooks'
import { filterNotNullOrUndefined } from '@shared/utils'

export const useDestinationSelectFilters = (destination: Destination) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [applyToModalVisible, toggleApplyToModalVisible] = useBoolean(false)
  const { mutateAsync: batchRemoveAppliedFilters, isLoading: isRemoving } = useBatchRemoveAppliedFilters()
  const { mutateAsync: batchApplyAppliedFilters, isLoading: isApplying } = useBatchApplyAppliedFilters()

  const indexes = Object.keys(rowSelection)
  const selectedFilters = indexes
    .map((i: string) => destination.syncsGroupedByFilterValue?.[parseInt(i)])
    .filter(filterNotNullOrUndefined)
  const uniqueFilters = uniqBy(selectedFilters, filter => `${filter?.filterType}-${filter?.filterName}`)
  const haveSelectSameFilter = selectedFilters.length !== uniqueFilters.length

  const disableRemove = selectedFilters.length === 0
  const disableDeselect = selectedFilters.length === 0

  const applicableForSyncs =
    destination.syncs?.filter(
      sync =>
        uniqueFilters.length &&
        uniqueFilters.every(filter =>
          sync.applicableFilters?.find(
            applicableFilter =>
              applicableFilter.type === filter?.filterType && applicableFilter.name === filter.filterName
          )
        )
    ) ?? []

  const applyToSelectable = useSelectable(applicableForSyncs.map(sync => sync.id))

  const disableApplyTo = applicableForSyncs.length === 0 || haveSelectSameFilter
  const errorMessage = haveSelectSameFilter ? `You can't select more than one value for the same filter` : ''
  const showHowManyVisMessage = !disableApplyTo && !!applicableForSyncs?.length

  const handleApplyTo = () => {
    toggleApplyToModalVisible(true)
  }

  const handleApplyToSave = async () => {
    await batchApplyAppliedFilters({
      destinationId: destination.id,
      params: {
        filters: selectedFilters,
        syncIds: applyToSelectable.selectedList,
      },
    })
    closeApplyToModal()
    applyToSelectable.deselect()
    setRowSelection({})
  }

  const handleRemove = async () => {
    if (!selectedFilters) {
      return
    }

    await batchRemoveAppliedFilters({
      destinationId: destination.id,
      filters: selectedFilters,
    })

    setRowSelection({})
  }

  const handleDeselect = () => {
    setRowSelection({})
  }

  const closeApplyToModal = () => {
    toggleApplyToModalVisible(false)
  }

  return {
    rowSelection,
    setRowSelection,
    disableApplyTo,
    disableRemove,
    disableDeselect,
    showHowManyVisMessage,
    errorMessage,
    applicableForSyncs,
    applyToModalVisible,
    closeApplyToModal,
    applyToSelectable,
    handleApplyTo,
    handleApplyToSave,
    isApplying,
    handleRemove,
    isRemoving,
    handleDeselect,
  }
}
