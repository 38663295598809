import {
  ActivityAddVisIcon,
  ActivityDeleteVisIcon,
  ActivityShareIcon,
  ActivitySyncScheduledIcon,
  UpdateIcon,
} from '@assets'

import {
  EActivityAction,
  EActivityResource,
  IActivityDataSync,
  IActivityLog,
  ILogShareDestinationData,
} from '@features/activity-log/types'

import { FeaturedIcon } from './featured-icon'

import styles from './destination-activity.module.css'

const buildShareMessage = (activityData: ILogShareDestinationData) => {
  if (!activityData.shareWith.length) return
  return (
    <p>
      <span className={styles.highlight}>Shared</span> the presentation with {activityData.shareWith[0].username}{' '}
      {activityData.shareWith.length > 1 ? 'and others' : ''}
    </p>
  )
}

export const buildActivityItem = (
  activity: IActivityLog
): { icon: React.ReactNode; message: React.ReactNode } | undefined => {
  switch (activity.resource) {
    case EActivityResource.presentation:
      switch (activity.action) {
        case EActivityAction.manualSync:
          return {
            icon: <FeaturedIcon icon={<UpdateIcon />} />,
            message: (
              <p>
                <span className={styles.highlight}>Updated</span> the presentation (User-triggered)
              </p>
            ),
          }
        case EActivityAction.scheduledSync:
          return {
            icon: <FeaturedIcon icon={<ActivitySyncScheduledIcon />} />,
            message: (
              <p>
                <span className={styles.highlight}>Updated</span>
                the presentation (Scheduled)
              </p>
            ),
          }
        case EActivityAction.share:
          return {
            icon: <FeaturedIcon icon={<ActivityShareIcon />} />,
            message: buildShareMessage(activity.data as ILogShareDestinationData),
          }
        default:
          console.log(activity.action)
          return
      }

    case EActivityResource.sync:
      switch (activity.action) {
        case EActivityAction.add:
          return {
            icon: <FeaturedIcon icon={<ActivityAddVisIcon />} />,
            message: (
              <p>
                <span className={styles.highlight}>Added</span> the visualization{' '}
                <span className={styles.highlight}>{activity!.data!.name}</span> in Slide{' '}
                <span className={styles.highlight}>{(activity.data as IActivityDataSync).slideIndex}</span>
              </p>
            ),
          }
        case EActivityAction.delete:
          return {
            icon: <FeaturedIcon icon={<ActivityDeleteVisIcon />} />,
            message: (
              <p>
                <span className={styles.highlight}>Deleted</span> the visualization{' '}
                <span className={styles.highlight}>{activity!.data!.name}</span> in Slide{' '}
                <span className={styles.highlight}>{(activity.data as IActivityDataSync).slideIndex}</span>
              </p>
            ),
          }
        case EActivityAction.duplicate:
          return {
            icon: <FeaturedIcon icon={<ActivityAddVisIcon />} />,
            message: (
              <p>
                <span className={styles.highlight}>Duplicated</span> the visualization{' '}
                <span className={styles.highlight}>{activity!.data!.name}</span> in Slide{' '}
                <span className={styles.highlight}>{(activity.data as IActivityDataSync).slideIndex}</span>
              </p>
            ),
          }
        case EActivityAction.update:
          return {
            icon: <FeaturedIcon icon={<UpdateIcon />} />,
            message: (
              <p>
                <span className={styles.highlight}>Updated</span> the visualization{' '}
                <span className={styles.highlight}>{activity!.data!.name}</span> in Slide{' '}
                <span className={styles.highlight}>{(activity.data as IActivityDataSync).slideIndex}</span>
              </p>
            ),
          }
        default:
          return
      }
  }
}
