import { FC } from 'react'

import { isPresentationDestination } from '@features/destinations'
import { Destination } from '@features/destinations/types'
import { getFiltersList } from '@features/filters/utils'
import { parseSyncProperties } from '@features/syncs/hooks'
import { ISync } from '@features/syncs/types'

import { ActionModal, Button, ImageWithFallback, SelectableOption, Spinner, Switch, Tooltip } from '@shared/components'

import styles from './select-syncs-modal.module.css'

interface SelectVisualizationsModalProps {
  visible: boolean

  syncs: ISync[]

  selected: Record<string, boolean>
  disabled?: Record<string, boolean>
  destination: Destination | null

  isAllSelected: boolean
  isSaving: boolean
  isLoadingSyncs?: boolean

  handleAllSelect: () => void
  handleSelectOption: (id: string) => void
  handleBack: () => void
  handleSave: () => void
}

export const SelectVisualizationsModal: FC<SelectVisualizationsModalProps> = ({
  visible,
  syncs,
  selected,
  destination,
  disabled = {},
  isLoadingSyncs = false,
  isAllSelected,
  isSaving,
  handleAllSelect,
  handleSelectOption,
  handleBack,
  handleSave,
}) => {
  const filtersList = (sync: ISync) => getFiltersList(parseSyncProperties(sync).filters)

  const isPresentation = destination && isPresentationDestination(destination)

  return (
    <ActionModal
      open={visible}
      onBackgroundClick={handleBack}
      className={styles.secondModal}
      title="Apply filter to visualizations"
      subtitle="Select the visualizations where this new filter value should be applied"
      btns={
        <>
          <Button text="Cancel" secondaryGray onClick={handleBack} fullWidth />
          <Button text="Save" onClick={handleSave} loading={isSaving} fullWidth />
        </>
      }
    >
      <Switch value={isAllSelected} className={styles.switch} onChange={handleAllSelect} text="Select/Unselect all" />

      {isLoadingSyncs && <Spinner />}

      <div className={styles.grid}>
        {syncs.map(sync => {
          const filters = filtersList(sync)
          return (
            <Tooltip key={sync.id} title={filters} show={Boolean(filters.length)}>
              <SelectableOption
                className={styles.option}
                key={sync.id}
                disabled={disabled[sync.id]}
                selected={selected[sync.id]}
                onClick={() => handleSelectOption(sync.id)}
              >
                <div className={styles.preview}>
                  <ImageWithFallback
                    src={sync.imageUrl}
                    className={styles.preview}
                    imageSize={{ height: '100%', width: '100%' }}
                    placeholderSize={{ height: 60, width: 60 }}
                    alt="Visualization"
                  />
                </div>
                <div className={styles.name}>{sync.name}</div>
                {isPresentation && <span className={styles.slideIndex}>(Slide {sync.slideIndex})</span>}
              </SelectableOption>
            </Tooltip>
          )
        })}
      </div>
    </ActionModal>
  )
}
