export enum FilterType {
  LookerFilter = 'LookerFilter',
  MetabaseFilter = 'MetabaseFilter',
  TableauFilter = 'TableauFilter',
  TableauParams = 'TableauParams',
}

export interface IFilter {
  id: string

  type: FilterType
  name: string

  credentialsId: string
  dashboardId: string
  dashboardFilterId: string

  data: any
}

export interface IAppliedFilter {
  id: string
  filterId: string
  syncId: string
  sourceDataId: string
  value: string
  createdAt: Date
  updatedAt: Date
  filter: Pick<IFilter, 'type' | 'name' | 'dashboardId' | 'dashboardFilterId'>
}
