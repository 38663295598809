import { RenameIcon } from '@assets'

import { Tooltip } from '@shared/components'
import { MenuItem } from '@shared/components/menu-item/menu-item'
import { getMessage } from '@shared/constants'

export const RenameItem = ({
  disabled = false,
  onClick,
  showMicrosoftTooltip = false,
  visible,
}: {
  disabled?: boolean
  onClick?: () => void
  showMicrosoftTooltip?: boolean
  visible: boolean
}) => {
  if (showMicrosoftTooltip) {
    return (
      <MenuItem disabled visible={visible}>
        <RenameIcon />
        <Tooltip title={getMessage('RENAME_MS_SHARED_ITEM')}>{'Rename'}</Tooltip>
      </MenuItem>
    )
  }

  return (
    <MenuItem disabled={disabled} onClick={onClick} visible={visible}>
      <RenameIcon />
      {'Rename'}
    </MenuItem>
  )
}
