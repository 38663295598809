import { FC } from 'react'

import { IIcon } from '@assets/types'

export const CollectionPreviewIcon: FC<IIcon> = () => {
  return (
    <svg width="210" height="120" viewBox="0 0 210 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <g clipPath="url(#clip0_16263_69359)">
          <path
            d="M97.9998 11.293V14.6297C85.0116 14.6297 82.2797 14.6297 62.1243 14.6297C41.9689 14.6297 46.0434 14.6297 25.0117 14.6297V11.293C40.4765 11.293 41.9689 11.293 62.1243 11.293C82.2797 11.293 82.5362 11.293 97.9998 11.293Z"
            fill="#7DB0DE"
          />
          <rect x="25.0117" y="14.5723" width="72.9881" height="38.4283" fill="#DFEAF4" />
          <path
            d="M53.4649 32.8496C53.9825 32.8496 54.4039 33.2692 54.4039 33.7869V33.7869C54.4039 34.3045 53.9825 34.7242 53.4649 34.7242C50.1307 34.7242 48.9288 34.7242 43.0875 34.7242C37.0493 34.7242 37.9051 34.7242 32.3231 34.7242C31.8055 34.7242 31.3809 34.3045 31.3809 33.7869V33.7869C31.3809 33.2692 31.8021 32.8496 32.3197 32.8496C36.3505 32.8496 37.1629 32.8496 43.0875 32.8496C49.0094 32.8496 49.4856 32.8496 53.4649 32.8496Z"
            fill="#7DB0DE"
          />
          <path
            d="M53.4649 28.1621C53.9825 28.1621 54.4039 28.5817 54.4039 29.0994V29.0994C54.4039 29.617 53.9825 30.0367 53.4649 30.0367C50.1307 30.0367 48.9288 30.0367 43.0875 30.0367C37.0493 30.0367 37.9051 30.0367 32.3231 30.0367C31.8055 30.0367 31.3809 29.617 31.3809 29.0994V29.0994C31.3809 28.5817 31.8021 28.1621 32.3197 28.1621C36.3505 28.1621 37.1629 28.1621 43.0875 28.1621C49.0094 28.1621 49.4856 28.1621 53.4649 28.1621Z"
            fill="#7DB0DE"
          />
          <path
            d="M73.0578 18.791C73.5754 18.791 73.9981 19.2106 73.9981 19.7283V19.7283C73.9981 20.2459 73.5749 20.6656 73.0572 20.6656C66.2594 20.6656 64.3162 20.6656 53.0506 20.6656C41.5928 20.6656 43.5453 20.6656 32.3235 20.6656C31.8059 20.6656 31.3809 20.2459 31.3809 19.7283V19.7283C31.3809 19.2106 31.8032 18.791 32.3208 18.791C40.4871 18.791 41.7037 18.791 53.0506 18.791C64.3961 18.791 64.9432 18.791 73.0578 18.791Z"
            fill="#7DB0DE"
          />
          <path
            d="M53.4649 37.5371C53.9825 37.5371 54.4039 37.9567 54.4039 38.4744V38.4744C54.4039 38.992 53.9825 39.4117 53.4649 39.4117C50.1307 39.4117 48.9288 39.4117 43.0875 39.4117C37.0493 39.4117 37.9051 39.4117 32.3231 39.4117C31.8055 39.4117 31.3809 38.992 31.3809 38.4744V38.4744C31.3809 37.9567 31.8021 37.5371 32.3197 37.5371C36.3505 37.5371 37.1629 37.5371 43.0875 37.5371C49.0094 37.5371 49.4856 37.5371 53.4649 37.5371Z"
            fill="#7DB0DE"
          />
          <path
            d="M53.4649 42.2227C53.9825 42.2227 54.4039 42.6423 54.4039 43.1599V43.1599C54.4039 43.6776 53.9825 44.0972 53.4649 44.0972C50.1307 44.0972 48.9288 44.0972 43.0875 44.0972C37.0493 44.0972 37.9051 44.0972 32.3231 44.0972C31.8055 44.0972 31.3809 43.6776 31.3809 43.1599V43.1599C31.3809 42.6423 31.8021 42.2227 32.3197 42.2227C36.3505 42.2227 37.1629 42.2227 43.0875 42.2227C49.0094 42.2227 49.4856 42.2227 53.4649 42.2227Z"
            fill="#7DB0DE"
          />
          <path
            d="M89.3903 44.2006C94.145 39.4458 94.0883 31.6782 89.2637 26.8535L80.6535 35.4637L89.3903 44.2006Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.267247"
            strokeMiterlimit="10"
          />
          <path
            d="M89.2645 26.8544C84.5098 22.0996 76.7563 22.1421 71.9491 26.9493C67.1419 31.7565 67.0994 39.51 71.8541 44.2647L89.2645 26.8544Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.267247"
            strokeMiterlimit="10"
          />
          <path
            d="M80.6543 35.4629L71.8541 44.263C76.6788 49.0877 84.5315 49.0594 89.3911 44.1997L80.6543 35.4629Z"
            fill="#19A0EC"
            stroke="#261E47"
            strokeWidth="0.267247"
            strokeMiterlimit="10"
          />
        </g>
        <g clipPath="url(#clip1_16263_69359)">
          <path
            d="M184.988 11.293V14.6297C172 14.6297 169.268 14.6297 149.113 14.6297C128.957 14.6297 133.032 14.6297 112 14.6297V11.293C127.465 11.293 128.957 11.293 149.113 11.293C169.268 11.293 169.525 11.293 184.988 11.293Z"
            fill="#7DB0DE"
          />
          <rect x="112" y="14.5723" width="72.9881" height="38.4283" fill="#DFEAF4" />
          <path
            d="M140.453 32.8496C140.971 32.8496 141.392 33.2692 141.392 33.7869V33.7869C141.392 34.3045 140.971 34.7242 140.453 34.7242C137.119 34.7242 135.917 34.7242 130.076 34.7242C124.038 34.7242 124.893 34.7242 119.311 34.7242C118.794 34.7242 118.369 34.3045 118.369 33.7869V33.7869C118.369 33.2692 118.79 32.8496 119.308 32.8496C123.339 32.8496 124.151 32.8496 130.076 32.8496C135.998 32.8496 136.474 32.8496 140.453 32.8496Z"
            fill="#7DB0DE"
          />
          <path
            d="M140.453 28.1621C140.971 28.1621 141.392 28.5817 141.392 29.0994V29.0994C141.392 29.617 140.971 30.0367 140.453 30.0367C137.119 30.0367 135.917 30.0367 130.076 30.0367C124.038 30.0367 124.893 30.0367 119.311 30.0367C118.794 30.0367 118.369 29.617 118.369 29.0994V29.0994C118.369 28.5817 118.79 28.1621 119.308 28.1621C123.339 28.1621 124.151 28.1621 130.076 28.1621C135.998 28.1621 136.474 28.1621 140.453 28.1621Z"
            fill="#7DB0DE"
          />
          <path
            d="M160.046 18.791C160.564 18.791 160.986 19.2106 160.986 19.7283V19.7283C160.986 20.2459 160.563 20.6656 160.045 20.6656C153.248 20.6656 151.304 20.6656 140.039 20.6656C128.581 20.6656 130.534 20.6656 119.312 20.6656C118.794 20.6656 118.369 20.2459 118.369 19.7283V19.7283C118.369 19.2106 118.791 18.791 119.309 18.791C127.475 18.791 128.692 18.791 140.039 18.791C151.384 18.791 151.931 18.791 160.046 18.791Z"
            fill="#7DB0DE"
          />
          <path
            d="M140.453 37.5371C140.971 37.5371 141.392 37.9567 141.392 38.4744V38.4744C141.392 38.992 140.971 39.4117 140.453 39.4117C137.119 39.4117 135.917 39.4117 130.076 39.4117C124.038 39.4117 124.893 39.4117 119.311 39.4117C118.794 39.4117 118.369 38.992 118.369 38.4744V38.4744C118.369 37.9567 118.79 37.5371 119.308 37.5371C123.339 37.5371 124.151 37.5371 130.076 37.5371C135.998 37.5371 136.474 37.5371 140.453 37.5371Z"
            fill="#7DB0DE"
          />
          <path
            d="M140.453 42.2227C140.971 42.2227 141.392 42.6423 141.392 43.1599V43.1599C141.392 43.6776 140.971 44.0972 140.453 44.0972C137.119 44.0972 135.917 44.0972 130.076 44.0972C124.038 44.0972 124.893 44.0972 119.311 44.0972C118.794 44.0972 118.369 43.6776 118.369 43.1599V43.1599C118.369 42.6423 118.79 42.2227 119.308 42.2227C123.339 42.2227 124.151 42.2227 130.076 42.2227C135.998 42.2227 136.474 42.2227 140.453 42.2227Z"
            fill="#7DB0DE"
          />
          <path
            d="M176.379 44.2002C181.133 39.4455 181.077 31.6781 176.252 26.8535L167.642 35.4635L176.379 44.2002Z"
            fill="#19A0EC"
            stroke="#261E47"
            strokeWidth="0.0963786"
            strokeMiterlimit="10"
          />
          <path
            d="M176.251 26.8542C171.496 22.0995 163.743 22.142 158.936 26.9491C154.129 31.7562 154.086 39.5095 158.841 44.2642L176.251 26.8542Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.0963786"
            strokeMiterlimit="10"
          />
          <path
            d="M167.641 35.4629L158.841 44.2628C163.665 49.0874 171.518 49.0591 176.377 44.1995L167.641 35.4629Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.0963786"
            strokeMiterlimit="10"
          />
        </g>
        <g clipPath="url(#clip2_16263_69359)">
          <path
            d="M97.9998 67V70.3367C85.0116 70.3367 82.2797 70.3367 62.1243 70.3367C41.9689 70.3367 46.0434 70.3367 25.0117 70.3367V67C40.4765 67 41.9689 67 62.1243 67C82.2797 67 82.5362 67 97.9998 67Z"
            fill="#7DB0DE"
          />
          <rect x="25.0117" y="70.2793" width="72.9881" height="38.4283" fill="#DFEAF4" />
          <path
            d="M53.4649 88.5566C53.9825 88.5566 54.4039 88.9763 54.4039 89.4939V89.4939C54.4039 90.0116 53.9825 90.4312 53.4649 90.4312C50.1307 90.4312 48.9288 90.4312 43.0875 90.4312C37.0493 90.4312 37.9051 90.4312 32.3231 90.4312C31.8055 90.4312 31.3809 90.0116 31.3809 89.4939V89.4939C31.3809 88.9763 31.8021 88.5566 32.3197 88.5566C36.3505 88.5566 37.1629 88.5566 43.0875 88.5566C49.0094 88.5566 49.4856 88.5566 53.4649 88.5566Z"
            fill="#7DB0DE"
          />
          <path
            d="M53.4649 83.8691C53.9825 83.8691 54.4039 84.2888 54.4039 84.8064V84.8064C54.4039 85.3241 53.9825 85.7437 53.4649 85.7437C50.1307 85.7437 48.9288 85.7437 43.0875 85.7437C37.0493 85.7437 37.9051 85.7437 32.3231 85.7437C31.8055 85.7437 31.3809 85.3241 31.3809 84.8064V84.8064C31.3809 84.2888 31.8021 83.8691 32.3197 83.8691C36.3505 83.8691 37.1629 83.8691 43.0875 83.8691C49.0094 83.8691 49.4856 83.8691 53.4649 83.8691Z"
            fill="#7DB0DE"
          />
          <path
            d="M73.0578 74.498C73.5754 74.498 73.9981 74.9177 73.9981 75.4353V75.4353C73.9981 75.953 73.5749 76.3726 73.0572 76.3726C66.2594 76.3726 64.3162 76.3726 53.0506 76.3726C41.5928 76.3726 43.5453 76.3726 32.3235 76.3726C31.8059 76.3726 31.3809 75.953 31.3809 75.4353V75.4353C31.3809 74.9177 31.8032 74.498 32.3208 74.498C40.4871 74.498 41.7037 74.498 53.0506 74.498C64.3961 74.498 64.9432 74.498 73.0578 74.498Z"
            fill="#7DB0DE"
          />
          <path
            d="M53.4649 93.2441C53.9825 93.2441 54.4039 93.6638 54.4039 94.1814V94.1814C54.4039 94.6991 53.9825 95.1187 53.4649 95.1187C50.1307 95.1187 48.9288 95.1187 43.0875 95.1187C37.0493 95.1187 37.9051 95.1187 32.3231 95.1187C31.8055 95.1187 31.3809 94.6991 31.3809 94.1814V94.1814C31.3809 93.6638 31.8021 93.2441 32.3197 93.2441C36.3505 93.2441 37.1629 93.2441 43.0875 93.2441C49.0094 93.2441 49.4856 93.2441 53.4649 93.2441Z"
            fill="#7DB0DE"
          />
          <path
            d="M53.4649 97.9297C53.9825 97.9297 54.4039 98.3493 54.4039 98.867V98.867C54.4039 99.3846 53.9825 99.8042 53.4649 99.8042C50.1307 99.8042 48.9288 99.8042 43.0875 99.8042C37.0493 99.8042 37.9051 99.8042 32.3231 99.8042C31.8055 99.8042 31.3809 99.3846 31.3809 98.867V98.867C31.3809 98.3493 31.8021 97.9297 32.3197 97.9297C36.3505 97.9297 37.1629 97.9297 43.0875 97.9297C49.0094 97.9297 49.4856 97.9297 53.4649 97.9297Z"
            fill="#7DB0DE"
          />
          <path
            d="M89.3883 99.9062C94.1427 95.1518 94.086 87.3848 89.2617 82.5605L80.6522 91.1701L89.3883 99.9062Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.0963815"
            strokeMiterlimit="10"
          />
          <path
            d="M89.2619 82.5607C84.5075 77.8063 76.7547 77.8488 71.9479 82.6556C67.1411 87.4625 67.0985 95.2153 71.8529 99.9696L89.2619 82.5607Z"
            fill="#19A0EC"
            stroke="#261E47"
            strokeWidth="0.0963815"
            strokeMiterlimit="10"
          />
          <path
            d="M80.6523 91.1719L71.8529 99.9713C76.6772 104.796 84.5292 104.767 89.3885 99.908L80.6523 91.1719Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.0963815"
            strokeMiterlimit="10"
          />
        </g>
        <g clipPath="url(#clip3_16263_69359)">
          <path
            d="M184.988 67V70.3367C172 70.3367 169.268 70.3367 149.113 70.3367C128.957 70.3367 133.032 70.3367 112 70.3367V67C127.465 67 128.957 67 149.113 67C169.268 67 169.525 67 184.988 67Z"
            fill="#7DB0DE"
          />
          <rect x="112" y="70.2793" width="72.9881" height="38.4283" fill="#DFEAF4" />
          <path
            d="M140.453 88.5566C140.971 88.5566 141.392 88.9763 141.392 89.4939V89.4939C141.392 90.0116 140.971 90.4312 140.453 90.4312C137.119 90.4312 135.917 90.4312 130.076 90.4312C124.038 90.4312 124.893 90.4312 119.311 90.4312C118.794 90.4312 118.369 90.0116 118.369 89.4939V89.4939C118.369 88.9763 118.79 88.5566 119.308 88.5566C123.339 88.5566 124.151 88.5566 130.076 88.5566C135.998 88.5566 136.474 88.5566 140.453 88.5566Z"
            fill="#7DB0DE"
          />
          <path
            d="M140.453 83.8691C140.971 83.8691 141.392 84.2888 141.392 84.8064V84.8064C141.392 85.3241 140.971 85.7437 140.453 85.7437C137.119 85.7437 135.917 85.7437 130.076 85.7437C124.038 85.7437 124.893 85.7437 119.311 85.7437C118.794 85.7437 118.369 85.3241 118.369 84.8064V84.8064C118.369 84.2888 118.79 83.8691 119.308 83.8691C123.339 83.8691 124.151 83.8691 130.076 83.8691C135.998 83.8691 136.474 83.8691 140.453 83.8691Z"
            fill="#7DB0DE"
          />
          <path
            d="M160.046 74.498C160.564 74.498 160.986 74.9177 160.986 75.4353V75.4353C160.986 75.953 160.563 76.3726 160.045 76.3726C153.248 76.3726 151.304 76.3726 140.039 76.3726C128.581 76.3726 130.534 76.3726 119.312 76.3726C118.794 76.3726 118.369 75.953 118.369 75.4353V75.4353C118.369 74.9177 118.791 74.498 119.309 74.498C127.475 74.498 128.692 74.498 140.039 74.498C151.384 74.498 151.931 74.498 160.046 74.498Z"
            fill="#7DB0DE"
          />
          <path
            d="M140.453 93.2441C140.971 93.2441 141.392 93.6638 141.392 94.1814V94.1814C141.392 94.6991 140.971 95.1187 140.453 95.1187C137.119 95.1187 135.917 95.1187 130.076 95.1187C124.038 95.1187 124.893 95.1187 119.311 95.1187C118.794 95.1187 118.369 94.6991 118.369 94.1814V94.1814C118.369 93.6638 118.79 93.2441 119.308 93.2441C123.339 93.2441 124.151 93.2441 130.076 93.2441C135.998 93.2441 136.474 93.2441 140.453 93.2441Z"
            fill="#7DB0DE"
          />
          <path
            d="M140.453 97.9297C140.971 97.9297 141.392 98.3493 141.392 98.867V98.867C141.392 99.3846 140.971 99.8042 140.453 99.8042C137.119 99.8042 135.917 99.8042 130.076 99.8042C124.038 99.8042 124.893 99.8042 119.311 99.8042C118.794 99.8042 118.369 99.3846 118.369 98.867V98.867C118.369 98.3493 118.79 97.9297 119.308 97.9297C123.339 97.9297 124.151 97.9297 130.076 97.9297C135.998 97.9297 136.474 97.9297 140.453 97.9297Z"
            fill="#7DB0DE"
          />
          <path
            d="M176.379 99.9076C181.133 95.1529 181.077 87.3852 176.252 82.5605L167.642 91.1708L176.379 99.9076Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.267247"
            strokeMiterlimit="10"
          />
          <path
            d="M176.253 82.5614C171.498 77.8066 163.745 77.8492 158.937 82.6564C154.13 87.4636 154.088 95.217 158.842 99.9718L176.253 82.5614Z"
            fill="#F4F4F4"
            stroke="#261E47"
            strokeWidth="0.267247"
            strokeMiterlimit="10"
          />
          <path
            d="M167.643 91.1699L158.842 99.9701C163.667 104.795 171.52 104.766 176.379 99.9068L167.643 91.1699Z"
            fill="#19A0EC"
            stroke="#261E47"
            strokeWidth="0.267247"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_16263_69359">
          <rect x="25.0117" y="11.293" width="72.9881" height="41.7075" rx="1.39025" fill="white" />
        </clipPath>
        <clipPath id="clip1_16263_69359">
          <rect x="112" y="11.293" width="72.9881" height="41.7075" rx="1.39025" fill="white" />
        </clipPath>
        <clipPath id="clip2_16263_69359">
          <rect x="25.0117" y="67" width="72.9881" height="41.7075" rx="1.39025" fill="white" />
        </clipPath>
        <clipPath id="clip3_16263_69359">
          <rect x="112" y="67" width="72.9881" height="41.7075" rx="1.39025" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
