import isEqual from 'lodash/isEqual'
import { FC, useEffect, useState } from 'react'

import { isPresentationDestination } from '@features/destinations'
import { useGetDestinationThemeColors, useUpdateDestinationThemeColors } from '@features/destinations/api/api'
import { Destination } from '@features/destinations/types'
import { ThemePicker } from '@features/theme/components/theme-picker'
import { DestinationThemeColors } from '@features/theme/types'

import { Button, Spinner } from '@shared/components'

interface DestinationThemeProps {
  destination: Destination
}

export const DestinationTheme: FC<DestinationThemeProps> = ({ destination }) => {
  const { data: theme, isLoading } = useGetDestinationThemeColors(destination.id, {
    enabled: isPresentationDestination(destination),
  })
  const [localTheme, updateLocalTheme] = useState<DestinationThemeColors | undefined>()
  const { mutateAsync: updateThemeAsync, isLoading: isUpdatingTheme } = useUpdateDestinationThemeColors()

  useEffect(() => {
    if (theme) {
      updateLocalTheme(theme)
    }
  }, [theme])

  if (isLoading) return <Spinner />
  if (!theme || !localTheme) return null

  const updateTheme = () => {
    if (!localTheme) return

    updateThemeAsync({
      destinationId: destination.id,
      theme: localTheme,
    })
  }

  const discardChanges = () => updateLocalTheme(theme)

  return (
    <div className="flex justify-center flex-col gap-10 items-center">
      <ThemePicker themeColors={localTheme} onThemeColorChange={updateLocalTheme} destinationType={destination.type} />
      <div className="flex gap-4 w-80 justify-between">
        <Button text="Discard" secondaryGray onClick={discardChanges} disabled={isEqual(theme, localTheme)} fullWidth />
        <Button text="Save" onClick={updateTheme} loading={isUpdatingTheme} fullWidth />
      </div>
    </div>
  )
}
