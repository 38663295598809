import { useContext } from 'react'

import { Destination } from '@features/destinations/types'
import { FreeTrialContext } from '@features/free-trial/free-trial-provider'
import { useUpdateSyncsById } from '@features/syncs/api'

export const useSyncUpdate = (destination: Destination) => {
  const { freeTrial } = useContext(FreeTrialContext)
  const { mutateAsync: updateSyncsById, isLoading } = useUpdateSyncsById({
    destination,
  })

  const updateSyncs = async (syncIds: string[]) => {
    return freeTrial(
      async () => {
        return updateSyncsById({ syncIds, destinationId: destination.id, type: destination.type })
      },
      { allowedExtraFreeUpdate: true }
    )
  }

  return { updateSyncs, isUpdating: isLoading }
}
