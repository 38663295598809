import { parseDuration } from './utils'
import { NestedKeyOf, resolveNestedKey } from './utils/nested-keys/nested-keys'

enum ENodeEnv {
  Development = 'development',
  Production = 'production',
  Test = 'test',
}
interface Config {
  auth0: {
    clientId: string
    domain: string
    loadingTimeout: number
  }
  nodeEnv: string
  apiHost: string
  chromeExtensionId?: string
  sentryDsn?: string
  appVersion?: string
  google: {
    clientId: string
    apiKey: string
    redirectUri: string
  }
  microsoft: {
    auth0ConnectionName: string
    clientId: string
    loginRedirectUri: string
    oneDriveBaseUrl: string
    redirectUri: string
    tenantId: string
  }
  posthogToken: string
  beamerProductId: string
  pendoApiKey: string
  hubspot: {
    id: string
  }
  crispWebsiteId: string
  authVersion: number
  jackson: {
    apiUrl: string
    product: string
    redirectUri: string
  }
  integrationTests: string
}

export const isDev = import.meta.env.VITE_NODE_ENV === ENodeEnv.Development
export const isProd = import.meta.env.VITE_NODE_ENV === ENodeEnv.Production

const apiHost = import.meta.env.VITE_API_HOST
const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI

export const config: Config = {
  nodeEnv: import.meta.env.VITE_NODE_ENV || ENodeEnv.Production,
  auth0: {
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    loadingTimeout: import.meta.env.VITE_AUTH0_LOADING_TIMEOUT
      ? parseInt(import.meta.env.VITE_AUTH0_LOADING_TIMEOUT)
      : parseDuration('5s'),
  },
  chromeExtensionId: import.meta.env.VITE_CHROME_EXTENSION_ID,
  apiHost: `${apiHost}/v1`,
  appVersion: import.meta.env.VITE_VERSION,
  google: {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    redirectUri,
  },
  posthogToken: import.meta.env.VITE_POSTHOG_TOKEN,
  beamerProductId: import.meta.env.VITE_BEAMER_PRODUCT_ID,
  pendoApiKey: import.meta.env.VITE_PENDO_API_KEY,
  crispWebsiteId: import.meta.env.VITE_CRISP_WEBSITE_ID,
  authVersion: parseInt(import.meta.env.VITE_AUTH_VERSION ?? '6'),
  jackson: {
    apiUrl: import.meta.env.VITE_JACKSON_API_URL ?? 'https://jackson.rollstack.com',
    product: import.meta.env.VITE_JACKSON_PRODUCT ?? 'rollstack',
    redirectUri: redirectUri.replace(/\/+$/, ''),
  },
  microsoft: {
    auth0ConnectionName: import.meta.env.VITE_MICROSOFT_AUTH0_CONNECTION_NAME ?? 'azure-ad-connection',
    clientId: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
    loginRedirectUri: import.meta.env.VITE_MICROSOFT_LOGIN_REDIRECT_URI,
    oneDriveBaseUrl: import.meta.env.VITE_MICROSOFT_ONEDRIVE_BASE_URL,
    redirectUri: import.meta.env.VITE_MICROSOFT_REDIRECT_URI,
    tenantId: import.meta.env.VITE_MICROSOFT_TENANT_ID,
  },
  hubspot: {
    id: import.meta.env.VITE_HUBSPOT_ID ?? '39987244',
  },
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  integrationTests: import.meta.env.VITE_INTEGRATION_TESTS || false,
}

const checkConfig = () => {
  const required: NestedKeyOf<Config>[] = [
    'apiHost',
    'appVersion',
    'auth0.clientId',
    'auth0.domain',
    'auth0.loadingTimeout',
    'authVersion',
    'crispWebsiteId',
    'google.apiKey',
    'google.clientId',
    'microsoft.clientId',
    'microsoft.tenantId',
    'sentryDsn',
  ]

  required.forEach(key => {
    if (!resolveNestedKey<Config>(key, config)) {
      throw new Error(`Missing config value for ${key}`)
    }
  })
}

checkConfig()
