import { EditorContent } from '@tiptap/react'
import { useContext } from 'react'

import { useTemplatePreviewSettings } from '@features/emails/components/templates/hooks/use-template-preview-settings'
import { EmailTemplateEditorContext } from '@features/emails/components/templates/providers/email-template-editor-provider'
import { CustomEmailTemplateVariable, EmailTemplate } from '@features/emails/types'

export const EmailTemplatePreview = ({
  template,
  recipients = [],
  variables = [],
}: {
  template: EmailTemplate
  recipients?: string[]
  variables: CustomEmailTemplateVariable[]
}) => {
  const { syncs } = useContext(EmailTemplateEditorContext)
  const { editor, parsedContent } = useTemplatePreviewSettings({
    template,
    recipients,
    variables,
    syncs,
  })

  return (
    <div className="h-full min-h-fit p-4 border border-solid border-color-[#e3e3e5] rounded flex flex-col gap-8">
      <EditorContent className="h-fit" editor={editor} />
      <iframe title={template.subject} srcDoc={parsedContent} className="h-fit min-h-full"></iframe>
    </div>
  )
}
