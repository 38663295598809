import { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Paths, buildCollectionPath } from '@pages/routes/paths'

import { CollectionVariantType } from '@features/collections/types'
import { useActiveDestinationContext } from '@features/destinations/active-destination-provider'
import {
  useGetDestinationById,
  useGetDestinationEmailConfig,
  useGetDestinationSlides,
} from '@features/destinations/api/api'
import { WithDestinationUpdateProps, WithDestinationVersionProps } from '@features/destinations/hocs'
import { Destination } from '@features/destinations/types'
import { getLastSuccessfulUpdate, getNextUpdate } from '@features/destinations/utils'
import { FreeTrialContext } from '@features/free-trial'
import { useDestinationsStatuses } from '@features/notch/destinations-status-provider'
import { useAuthStore } from '@features/users'

import { useBoolean } from '@shared/hooks'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import { useDestinationRights } from './use-destination-rights'

export interface IDestinationDoc {
  id: string
  driveId?: string | null
  name: string
  serviceId: string
  type: string
  mimeType?: string
}

export const useDestinationSettings = ({
  createVersion,
  updateDestination,
  updateLoading,
  versionLoading,
}: WithDestinationUpdateProps & WithDestinationVersionProps) => {
  const { setActiveDestination } = useActiveDestinationContext()
  const navigate = useNavigate()
  const { destinationId } = useParams()
  const [searchParams] = useSearchParams()
  const slideId = searchParams.get('slideId')
  const { user } = useAuthStore()
  const [templateExplainerModal, toggleTemplateExplainerModal] = useBoolean()
  const [visualizationConceptModal, toggleVisualizationConceptModal] = useBoolean()
  const [isUpdateRunning, toggleIsUpdateRunning] = useBoolean()
  const [showFileLockWarning, toggleShowFileLockWarning] = useBoolean()
  const [isDestinationSendingOpen, toggleDestinationSendingOpen] = useBoolean(false)

  const { data: destination, isLoading } = useGetDestinationById({ destinationId })
  const { data: slides, isLoading: loadingSlides } = useGetDestinationSlides(destination)

  const isTemplate = destination?.variantInCollection?.type === CollectionVariantType.template
  const isVariant = destination?.variantInCollection?.type === CollectionVariantType.variant
  const isDestinationSendingEnabled = isFeatureEnabled('destination-sending') && !isVariant
  const { data: destinationEmailConfig, isLoading: loadingDestinationEmailConfig } = useGetDestinationEmailConfig(
    destination?.id,
    {
      enabled: isDestinationSendingEnabled,
    }
  )

  const allDataLoaded = useMemo(() => !!destination && !!slides, [destination, slides])

  const { viewer, canEdit } = useDestinationRights(destination)

  const lastSuccessfulUpdateAt = getLastSuccessfulUpdate(destination)
  const nextUpdateAt = getNextUpdate(destination)

  const back = () =>
    destination?.variantInCollection
      ? navigate(buildCollectionPath(destination.variantInCollection.collectionId))
      : navigate(Paths.DESTINATIONS)

  const { isDestinationUpdateRunning } = useDestinationsStatuses()

  const { freeTrial } = useContext(FreeTrialContext)

  useEffect(() => {
    toggleIsUpdateRunning(updateLoading || versionLoading)
  }, [toggleIsUpdateRunning, updateLoading, versionLoading])

  useEffect(() => {
    if (destination) {
      toggleIsUpdateRunning(isDestinationUpdateRunning(destination.id))
    } else {
      toggleIsUpdateRunning(false)
    }
  }, [toggleIsUpdateRunning, isDestinationUpdateRunning, destination])

  const onUpdate = (destination: Destination) => {
    freeTrial(
      () => {
        toggleIsUpdateRunning(true)
        updateDestination(destination).catch(() => {
          toggleIsUpdateRunning(false)
        })
      },
      { allowedExtraFreeUpdate: true }
    )
  }

  const onArchiveAndUpdate = (destination: Destination) => {
    freeTrial(
      () => {
        toggleIsUpdateRunning(true)
        createVersion(destination).catch(() => {
          toggleIsUpdateRunning(false)
        })
      },
      { allowedExtraFreeUpdate: true }
    )
  }

  useEffect(() => {
    if (allDataLoaded && isTemplate && user?.showTemplateExplainerModal) {
      toggleTemplateExplainerModal(true)
    }
  }, [allDataLoaded, user, isTemplate, toggleTemplateExplainerModal])

  useEffect(() => {
    if (allDataLoaded && user?.showVisualizationConceptModal) {
      toggleVisualizationConceptModal(true)
    }
  }, [allDataLoaded, user, toggleVisualizationConceptModal])

  const canSendDestinationEmail = useMemo(() => {
    if (!destination) {
      return false
    }
    if (!!destination.variantInCollection) {
      return true
    }
    if (!!destinationEmailConfig) {
      return true
    }
    return false
  }, [destination, destinationEmailConfig])

  useEffect(() => {
    if (!destination) return

    setActiveDestination(destination)
  }, [destination, setActiveDestination])

  return {
    destination: {
      isLoading,
      viewer,
      canEdit,
      nextUpdateAt,
      lastSuccessfulUpdateAt,
      destination,
      isUpdateRunning,
      back,
      onUpdate,
      onArchiveAndUpdate,
    },
    modals: {
      showFileLockWarning,
      templateExplainerModal,
      visualizationConceptModal,
      isDestinationSendingOpen,
      toggleShowFileLockWarning,
      toggleTemplateExplainerModal,
      toggleVisualizationConceptModal,
      toggleDestinationSendingOpen,
    },
    slideId: slideId || undefined,
    slides,
    loadingSlides,
    isDestinationSendingEnabled,
    destinationEmailConfig,
    loadingDestinationEmailConfig,
    canSendDestinationEmail,
  }
}
