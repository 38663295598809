import { FC } from 'react'

import { IIcon } from '@assets/types'

export const DestinationIcon: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M2.5 18.3333L17.5 18.3333" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
      <path d="M2.5 15L17.5 15" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
      <path d="M2.5 1.66669L6.66667 1.66669" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
      <path d="M2.5 5L4.16667 5" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
      <path d="M2.5 8.33331L4.16667 8.33331" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
      <path d="M2.5 11.6667L6.66667 11.6667" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
      <path
        d="M17.9413 8.61101C17.6229 9.36399 17.1249 10.0275 16.4908 10.5436C15.8567 11.0596 15.1059 11.4125 14.3039 11.5713C13.502 11.7302 12.6733 11.6901 11.8904 11.4547C11.1075 11.2193 10.3942 10.7957 9.81278 10.2209C9.2314 9.64615 8.79967 8.93771 8.55534 8.15753C8.31101 7.37736 8.26152 6.54922 8.41118 5.7455C8.56085 4.94178 8.90512 4.18695 9.4139 3.54702C9.92267 2.90709 10.5805 2.40154 11.3298 2.07456M17.9557 4.74878C18.1561 5.23249 18.279 5.74386 18.3207 6.26411C18.331 6.39264 18.3361 6.4569 18.3106 6.51479C18.2893 6.56315 18.2471 6.6089 18.2006 6.63402C18.1449 6.66409 18.0754 6.66409 17.9363 6.66409H13.7321C13.592 6.66409 13.5219 6.66409 13.4684 6.63681C13.4213 6.61282 13.383 6.57454 13.359 6.52745C13.3317 6.47392 13.3317 6.40384 13.3317 6.26369V2.05954C13.3317 1.92047 13.3317 1.85093 13.3618 1.79527C13.3869 1.74877 13.4327 1.70654 13.481 1.68522C13.5389 1.6597 13.6032 1.66485 13.7317 1.67515C14.252 1.71686 14.7633 1.83977 15.247 2.04012C15.8543 2.29165 16.406 2.66031 16.8708 3.12506C17.3355 3.58981 17.7042 4.14155 17.9557 4.74878Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
