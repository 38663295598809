import { FC } from 'react'

import { EDataTestId } from '@tests/constants'

import { ExternalLinkIcon, EyeIcon } from '@assets'

import { ViewerDestinationTooltip } from '@features/destinations/components/tooltips/viewer-destination-tooltip'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { Destination } from '@features/destinations/types'

import { NewTabLink } from '@shared/components'

interface IDestinationTitle {
  destination?: Destination
  viewer?: boolean
}

export const DestinationTitle: FC<IDestinationTitle> = ({ destination, viewer = false }) => {
  const handle = destinationHandlers(destination!)

  if (!destination) return <div>Add destination</div>

  const url = handle.getDestinationUrl()

  return (
    <div className="flex gap-5 items-center">
      {handle.getSmallIcon()}
      <div className="max-w-[500px] whitespace-nowrap overflow-hidden text-ellipsis">{destination.name}</div>
      {viewer && (
        <ViewerDestinationTooltip show={viewer} position="right">
          <div className="flex flex-row gap-1 text-gray-400 font-semibold text-sm">
            <EyeIcon /> Viewer
          </div>
        </ViewerDestinationTooltip>
      )}
      {url && (
        <NewTabLink link={url} data-testid={EDataTestId.THREE_DOTS_ACTION_OPEN}>
          <ExternalLinkIcon />
        </NewTabLink>
      )}
    </div>
  )
}
