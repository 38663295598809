import { User } from '@sentry/react'

import { JsonObjectLooker } from '@features/embedding/looker/types'
import { JsonObjectMetabase } from '@features/embedding/metabase/types'
import { JsonObjectTableau } from '@features/embedding/tableau/types'

import { ILookerDataSource } from './looker-types'
import { IMetabaseDataSource } from './metabase-types'
import { ITableauDataSource } from './tableau-types'

export enum EDataSourceType {
  Tableau = 'Tableau',
  Looker = 'Looker',
  Metabase = 'Metabase',
  GoogleSheets = 'GoogleSheet',
}

export type IDataSource = ILookerDataSource | ITableauDataSource | IMetabaseDataSource

export type JsonObject = JsonObjectTableau | JsonObjectLooker | JsonObjectMetabase

export interface IDataSourceValidationErrors {
  baseUrlError?: string
}

export type CoreDataSource = {
  id: string
  name: string
  type: EDataSourceType
  baseUrl: string
  userId: number | null
  user?: Pick<User, 'id' | 'name' | 'email'> | null
  defaultAcrossOrganization: boolean
  autoProvision: boolean

  isActive?: boolean

  childCredentials?: Array<{
    id: string
    name: string
    type: EDataSourceType
    baseUrl: string
    userId: number | null
    user?: Pick<User, 'id' | 'name' | 'email'> | null
    defaultAcrossOrganization: boolean
    autoProvision: boolean
    isActive?: boolean
  }>
} & TableauCoreDataSource &
  MetabaseCoreDataSource

type TableauCoreDataSource = {
  siteName?: string
}

type MetabaseCoreDataSource = {
  username?: string
  hasMetabasePassword?: boolean
}

export interface ISpreadsheet {
  fileId: string
  name: string
  spreadsheetUrl?: string
  baseUrl: string
  id: string
  type: EDataSourceType
}
