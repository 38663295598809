import { FC, useEffect, useState } from 'react'

import { ChevronDownUp } from '@assets/icons/arrows'
import { CollectionIcon } from '@assets/icons/collection'
import { UsersPlusIcon } from '@assets/icons/users'

import { SharingCollectionPermissions } from '@features/collections/types'
import {
  RevokeShareDestinationParams,
  ShareDestinationParams,
  UpdateShareDestinationParams,
  isEmailDestination,
} from '@features/destinations'
import {
  Destination,
  DestinationSharingPermissions as SharingDestinationPermissions,
  UserSharedPresentation,
} from '@features/destinations/types'
import { isOAuthMissingError } from '@features/drive/error-handler'
import { useGetOrganizationUsers, useOrganizationsStore } from '@features/organizations'
import { OrganizationUser } from '@features/organizations/types'
import { useAuthStore } from '@features/users'

import { ActionModal, Avatar, Badge, Button, EBadgeColor, Label, SelectInput } from '@shared/components'
import { useBoolean } from '@shared/hooks'

import styles from './share-modal.module.css'

const sharingOptions = [
  { value: SharingDestinationPermissions.view, label: 'Can view' },
  { value: SharingDestinationPermissions.edit, label: 'Can edit' },
]

interface IShareItemModalProps {
  open: boolean
  toggle: () => void
  destination: Destination
  shareItem: (params: ShareDestinationParams) => Promise<any>
  updatePermissions: (params: UpdateShareDestinationParams) => Promise<any>
  revokePermissions: (params: RevokeShareDestinationParams) => Promise<any>
  isInviteLoading?: boolean
  isUpdatePermissionsLoading?: boolean
  isRevokePermissionLoading?: boolean
}

type PermissionChangeAction = (typeof sharingOptions)[number]['value']

const VIEW_OPTION = sharingOptions[0]
const EDIT_OPTION = sharingOptions[1]

export const DestinationShareModal: FC<IShareItemModalProps> = ({
  open,
  toggle,
  destination,
  shareItem,
  updatePermissions,
  revokePermissions,
  isInviteLoading,
  isUpdatePermissionsLoading,
  isRevokePermissionLoading,
}) => {
  const { user } = useAuthStore()
  const { activeOrganization } = useOrganizationsStore()
  const { data: organizationMembers } = useGetOrganizationUsers(activeOrganization?.organizationId)
  const [emailList, setEmailList] = useState<OrganizationUser[]>([])

  const [selectedUsers, setSelectedUsers] = useState<OrganizationUser[]>([])
  const [error, setError] = useState('')
  const [updatedUserId, setUpdatedUserId] = useState<number | null>(null)
  const [userPermissions, setUserPermissions] = useState<UserSharedPresentation[]>([])
  const [selectedSharingOption, setSelectedSharingOption] = useState<(typeof sharingOptions)[number]>(VIEW_OPTION)
  const [showCollectionUsers, toggleCollectionUsers] = useBoolean(false)

  const isCollectionVariant = destination?.variantInCollection?.collection
  const collectionAdmins = destination?.variantInCollection?.collection?.sharedWith?.filter(
    sharedWith => sharedWith.permission === SharingCollectionPermissions.admin
  )
  const countCollectionOwnerAndAdmins = (collectionAdmins?.length ?? 0) + 1

  const inviteClick = async () => {
    setError('')
    const shareItemPayload = selectedUsers.map(user => ({
      email: user.email,
      permission: isEmailDestination(destination) ? EDIT_OPTION.value : selectedSharingOption.value,
    }))

    try {
      await shareItem({
        data: shareItemPayload,
      })
    } catch (e: any) {
      if (isOAuthMissingError(e)) {
        return
      }
      setError(e?.response?.data?.message || e?.message)
    } finally {
      reset()
    }
  }

  const changeUserPermissions = async (userPermissionIdx: number, permissionChangeAction?: PermissionChangeAction) => {
    if (!permissionChangeAction) return

    const userId = userPermissions[userPermissionIdx].userId
    setUpdatedUserId(userId)

    setError('')

    try {
      const updatePermissionPayload = {
        userId,
        permission: permissionChangeAction,
      }
      await updatePermissions({
        data: updatePermissionPayload,
      })

      if (updatePermissionPayload.userId === user?.id && updatePermissionPayload.permission === 'view') {
        toggle()
      }

      setUserPermissions(prev => {
        const newPermissions = [...prev]
        newPermissions[userPermissionIdx].permission = permissionChangeAction || SharingDestinationPermissions.view
        return newPermissions
      })
    } catch (e: any) {
      if (isOAuthMissingError(e)) {
        return
      }
      setError(e?.response?.data?.message || e?.message)
    } finally {
      setUpdatedUserId(null)
    }
  }

  const handleRemove = async (userId: any) => {
    setUpdatedUserId(userId)
    try {
      await revokePermissions({ userId })
    } catch (e: any) {
      if (isOAuthMissingError(e)) {
        return
      }
      setError(e?.response?.data?.message || e?.message)
    } finally {
      setUpdatedUserId(null)
    }
  }

  const reset = () => {
    setSelectedUsers([])
    setSelectedSharingOption(sharingOptions[0])
  }

  useEffect(() => {
    if (destination) {
      setUserPermissions(destination.sharedWith || [])
    }
  }, [destination])

  useEffect(() => {
    if (!organizationMembers || !activeOrganization || !destination) {
      return
    }

    const { userId: activeUserId } = activeOrganization
    const sharedWithUserIds = new Set((destination.sharedWith || []).map(member => member.userId))

    const isEligibleMember = (member: OrganizationUser) => {
      return (
        member.userId !== destination.userId &&
        member.userId !== activeUserId &&
        member.userId &&
        !sharedWithUserIds.has(member.userId)
      )
    }

    const isCollectionContributorWithoutAccess = (member: OrganizationUser) => {
      return (
        destination.variantInCollection?.collection?.sharedWith?.find(s => s.userId === member.userId && s.permission)
          ?.permission === SharingCollectionPermissions.contributor &&
        destination.sharedWith?.find(s => s.userId === member.userId) === undefined
      )
    }

    let updatedEmailList = organizationMembers.filter(isEligibleMember)
    if (isCollectionVariant) {
      updatedEmailList = updatedEmailList.filter(isCollectionContributorWithoutAccess)
    }

    setEmailList(updatedEmailList)
  }, [organizationMembers, activeOrganization, isCollectionVariant, destination])

  useEffect(() => {
    reset()
    setError('')
  }, [])

  return (
    <ActionModal
      open={open}
      onBackgroundClick={toggle}
      className={'max-w-[700px]'}
      Icon={UsersPlusIcon}
      title={`Share ${destination.name}`}
      subtitle="The following users have access:"
    >
      {error && <p className="my-2 text-red-600 font-medium text-sm">{error}</p>}

      <div className="flex flex-col gap-4">
        {isCollectionVariant && (
          <div className="flex flex-col gap-3">
            <div className="text-sm font-medium flex flex-row items-center w-full justify-between">
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center cursor-pointer" onClick={toggleCollectionUsers}>
                  <ChevronDownUp isUp={showCollectionUsers} />
                  <CollectionIcon />
                  <div className="font-semibold">Collection owner and all admins of this collection</div>
                </div>
                <Badge
                  color={EBadgeColor.LIGHTGRAY}
                  text={`${countCollectionOwnerAndAdmins} user${countCollectionOwnerAndAdmins > 1 ? 's' : ''}`}
                />
              </div>
            </div>

            {showCollectionUsers && (
              <div className="ml-8 flex flex-col gap-3">
                <div className="text-sm font-medium flex flex-row items-center w-full justify-between">
                  <Avatar name={destination.variantInCollection?.collection?.user?.name}>
                    <div className="flex flex-col">
                      <span className="font-semibold text-sm">
                        {destination.variantInCollection?.collection?.user?.name}
                      </span>
                      <span className="font-normal text-xs">
                        {destination.variantInCollection?.collection?.user?.email}
                      </span>
                    </div>
                  </Avatar>
                  <p className="w-[240px]">Collection owner</p>
                </div>
                {collectionAdmins
                  ?.sort((a, b) => a.userId - b.userId)
                  ?.map(admin => (
                    <div className="text-sm font-medium flex flex-row items-center w-full justify-between">
                      <Avatar name={admin.user.name}>
                        <div className="flex flex-col">
                          <span className="font-semibold text-sm">{admin.user.name}</span>
                          <span className="font-normal text-xs">{admin.user.email}</span>
                        </div>
                      </Avatar>
                      <p className="w-[240px]">Collection admin</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col gap-3">
          <div className="text-sm font-medium flex flex-row items-center w-full justify-between">
            <Avatar name={destination.user.name}>
              <div className="flex flex-col">
                <span className="font-semibold text-sm">{destination.user.name}</span>
                <span className="font-normal text-xs">{destination.user.email}</span>
              </div>
            </Avatar>
            <Label sm medium className={styles.owner}>
              Owner
            </Label>
          </div>
          {destination.sharedWith
            ?.sort((a, b) => a.userId - b.userId)
            ?.filter(sharedWith => sharedWith.userId !== destination.userId)
            .map(({ user }, idx) =>
              user ? (
                <div key={user.id} className={styles.row}>
                  <>
                    <div>
                      <Avatar name={user.name}>
                        <div className="flex flex-col">
                          <span className="font-semibold text-sm">{user.name}</span>
                          <span className="font-normal text-xs">{user.email}</span>
                        </div>
                      </Avatar>
                    </div>
                    <div className={styles.roleAndAction}>
                      {isEmailDestination(destination) ? (
                        <Label sm medium className={styles.owner}>
                          Can edit
                        </Label>
                      ) : (
                        <SelectInput
                          className={styles.roleSelect}
                          value={sharingOptions.find(
                            sharingOption => sharingOption.value === userPermissions[idx]?.permission
                          )}
                          options={sharingOptions}
                          onChange={option => changeUserPermissions(idx, option?.value)}
                          isDisabled={user.id === updatedUserId || isUpdatePermissionsLoading}
                        />
                      )}
                      <Button
                        text="Remove"
                        onClick={() => handleRemove(user.id)}
                        loading={user.id === updatedUserId || isRevokePermissionLoading}
                        tertiaryError
                      />
                    </div>
                  </>
                </div>
              ) : (
                <></>
              )
            )}
        </div>
      </div>

      <p className={styles.addUserTitle}>Add another user</p>
      <div className={styles.addAnotherUser}>
        <SelectInput
          isMulti
          isSearchable
          className={styles.userSelect}
          value={selectedUsers}
          options={emailList}
          onChange={option => setSelectedUsers(option)}
          getOptionLabel={option => option.email}
          getOptionValue={option => option.email}
          placeholder="Select user"
        />
        <div className={styles.roleAndAction}>
          {isEmailDestination(destination) ? (
            <Label sm medium className={styles.owner}>
              Can edit
            </Label>
          ) : (
            <SelectInput
              className={styles.roleSelect}
              value={selectedSharingOption}
              options={sharingOptions}
              onChange={option => setSelectedSharingOption(option || sharingOptions[0])}
              isDisabled={!selectedUsers.length}
            />
          )}
          <Button
            text="Add user"
            onClick={inviteClick}
            disabled={!selectedUsers.length}
            loading={isInviteLoading}
            tertiaryColor
          />
        </div>
      </div>
    </ActionModal>
  )
}
