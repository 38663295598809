import { FC, useMemo, useRef } from 'react'

import { TableauWorkbook } from '@features/data-sources'
import { isLookerDataSource, isMetabaseDataSource, isTableauDataSource } from '@features/data-sources/utils'
import { ILookerDashboard } from '@features/embedding/looker/types'
import { IMetabaseDashboard } from '@features/embedding/metabase/types'
import { SyncEmbedLooker } from '@features/syncs/components/sync-embed/sync-embed-looker'
import { SyncEmbedMetabase } from '@features/syncs/components/sync-embed/sync-embed-metabase'
import { SyncEmbedTableau } from '@features/syncs/components/sync-embed/sync-embed-tableau'
import { SyncEmbedRef } from '@features/syncs/components/sync-embed/types'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { Button, Modal, Switch, Tooltip } from '@shared/components'
import { useBoolean } from '@shared/hooks'

import styles from './sync-embed.module.css'

interface SyncEmbedProps {
  onSave?: () => void
  onClose?: () => void
}

export const SyncEmbed: FC<SyncEmbedProps> = ({ onSave, onClose }) => {
  const embedRef = useRef<SyncEmbedRef>(null)
  const [localFiltersChanged, toggleFiltersChanged] = useBoolean()
  const {
    dataSource,
    filtersSelectModal,
    vizSelectModal,
    fullDashboard,
    dashboardSelected,
    filtersLoading,
    updateVanityData,
    toggleFullDashboard,
  } = useSyncSettingsContext()

  const embedView = useMemo(() => {
    if (!dataSource || !dashboardSelected) return null

    const props = { ref: embedRef, toggleFiltersChanged }

    if (isLookerDataSource(dataSource)) {
      return <SyncEmbedLooker {...props} dataSource={dataSource} dashboard={dashboardSelected as ILookerDashboard} />
    } else if (isTableauDataSource(dataSource)) {
      return <SyncEmbedTableau {...props} dataSource={dataSource} dashboard={dashboardSelected as TableauWorkbook} />
    } else if (isMetabaseDataSource(dataSource)) {
      return (
        <SyncEmbedMetabase {...props} dataSource={dataSource} dashboard={dashboardSelected as IMetabaseDashboard} />
      )
    }
  }, [dataSource, dashboardSelected, toggleFiltersChanged])

  const closeModal = () => {
    updateVanityData({ filtersSelectModal: false, vizSelectModal: false })
    onClose && onClose()
  }

  const handleSaveFilters = () => {
    embedRef.current?.saveFiltersToContext()
    updateVanityData({ filtersSelectModal: false, filtersChanged: true, filtersRemoved: false })
    onSave && onSave()
  }

  const disableSaveFilters = !localFiltersChanged && !filtersLoading

  return (
    <Modal
      open
      visible={filtersSelectModal || vizSelectModal}
      className={styles.container}
      onBackgroundClick={closeModal}
    >
      {vizSelectModal && (
        <div className={styles.fullDash}>
          <Switch value={fullDashboard} text="Select full dashboard" onChange={toggleFullDashboard} />
        </div>
      )}

      <div className={styles.embedContainer}>{embedView}</div>

      {filtersSelectModal && (
        <div className={styles.btnsWrapper}>
          <Button secondaryGray text="Cancel" onClick={closeModal} fullWidth />

          <Tooltip
            title="You can save the changes once the filters have been modified"
            show={disableSaveFilters}
            position="top"
            fullWidth
          >
            <Button
              text="Save"
              loading={filtersLoading}
              disabled={disableSaveFilters}
              onClick={handleSaveFilters}
              fullWidth
            />
          </Tooltip>
        </div>
      )}
    </Modal>
  )
}
