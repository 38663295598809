import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { LookerData, MetabaseData, TableauData } from '@features/data-sources/types'
import { isLookerDataSource, isMetabaseDataSource, isTableauDataSource } from '@features/data-sources/utils'
import { destinationHandlers } from '@features/destinations'
import { useActiveDestinationContext } from '@features/destinations/active-destination-provider'
import { Destination } from '@features/destinations/types'
import { useAddSync, useDeleteAppliedFilters, useUpdateSync } from '@features/syncs/api'
import { BatchAddSyncRequest, ISync, SyncUpdateType } from '@features/syncs/types'
import {
  getDashboardElement,
  getDashboardElementName,
  getSyncDataSource,
  getSyncIntegrationDashboard,
  isLookerDashboardElement,
  isMetabaseDashboardElement,
  isTableauDashboardElement,
  syncToAddLookerData,
  syncToAddMetabaseData,
  syncToAddTableauData,
} from '@features/syncs/utils'

import { addSyncToast, updateSyncToast } from '@shared/components'
import { useStateReducer } from '@shared/hooks'
import { queryClient } from '@shared/http'

import { ISyncSettingsContext, IntegrationDashboardElement, SyncData, initialState, initialSyncData } from './types'
import { useFiltersLogic } from './use-filters-logic'
import { useTableauLogic } from './use-tableau-logic'
import { VanityData, initialVanityData, useVanityData } from './use-vanity-data'
import { getSyncNameWithFullDashboard } from './utils'

export const SyncSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setActiveDestination } = useActiveDestinationContext()
  const [destination, setDestination] = useState<null | Destination>(null)
  const [originalSync, setOriginalSync] = useState<null | ISync>(null)
  const [syncData, updateSyncData] = useStateReducer<SyncData>(initialSyncData)

  const addSync = useAddSync()
  const { mutateAsync: updateSync } = useUpdateSync()
  const { mutateAsync: deleteAppliedFilters } = useDeleteAppliedFilters()
  const vanityData = useVanityData({ originalSync, syncData })
  const { tableauViewSelectCallback } = useTableauLogic({ syncData, updateSyncData })
  const filtersLogic = useFiltersLogic({
    originalSync,
    syncData,
    updateVanityData: vanityData.updateVanityData,
    updateSyncData,
  })

  const {
    dataSource,
    fullDashboard,
    dashboardSelected,
    sheetSelected,
    cellRanges,
    selectedVis,
    slideData,
    lookerData,
    metabaseData,
    tableauData,
    spreadsheetsData,
  } = syncData

  const { canSaveSync, embedDataChanged, filtersChanged, filtersRemoved, isNewSync, updateVanityData } = vanityData

  const syncs = useMemo(() => destination?.syncs ?? [], [destination?.syncs])

  const clearVisSelectData = useCallback(
    () =>
      updateSyncData({
        selectedVis: [],
        fullDashboard: false,
        updateType: SyncUpdateType.image,
        lookerData: undefined,
        metabaseData: undefined,
        tableauData: undefined,
      }),
    [updateSyncData]
  )

  const toggleFullDashboard = () => {
    const newValue = !fullDashboard
    updateSyncData({ fullDashboard: newValue })

    if (!isNewSync && newValue) {
      updateVanityData({ vizSelectModal: false, embedDataChanged: true })
    }
  }

  const toggleSyncUpdateType = () => {
    const updateType = syncData.updateType === SyncUpdateType.text ? SyncUpdateType.image : SyncUpdateType.text
    updateSyncData({ updateType })
  }

  const visSelectCallback = async (dashboardElement: IntegrationDashboardElement | null) => {
    if (isNewSync || !dashboardElement) return

    updateSyncData({ selectedVis: [dashboardElement] })
    updateVanityData({ vizSelectModal: false, embedDataChanged: true })
  }

  const syncNameWithFullDashboard = getSyncNameWithFullDashboard(dashboardSelected, tableauData?.viewName)

  const addSyncs = async () => {
    if (!destination || !dataSource) return
    updateVanityData({ isSavingSync: true })

    const commonData = {
      presentationId: destination.id,
      slideId: slideData.slideId,
      slideIndex: slideData.slideIndex,
      type: dataSource.type,
      cropOption: destination.cropOption,
      updateType: syncData.updateType,
      ...destinationHandlers(destination).getGroupData(slideData.slideIndex),
    }

    let syncsToAdd: BatchAddSyncRequest['syncs'] = []
    let spreadsheetsDataBatch: BatchAddSyncRequest['spreadsheetsData'] = undefined
    if (fullDashboard) {
      syncsToAdd = [
        {
          name: syncNameWithFullDashboard,
          ...(tableauData && { fullDashboard }),
          ...(lookerData && { fullDashboard }),
          ...(metabaseData && { fullDashboard }),
        },
      ]
    } else if (sheetSelected && cellRanges) {
      spreadsheetsDataBatch = {
        sheetId: sheetSelected.sheetId.toString(),
        sheetTitle: sheetSelected.title,
        spreadsheetId: dataSource.id,
      }
      syncsToAdd = cellRanges.map(range => {
        return {
          name: sheetSelected.title,
          spreadsheetsData: {
            cellRange: range,
            gridlines: spreadsheetsData?.gridlines,
            freezeRows: spreadsheetsData?.freezeRows,
            collapsedRows: spreadsheetsData?.collapsedRows,
          },
        }
      })
    } else {
      syncsToAdd = selectedVis.map(el => {
        let lookerElementData
        let metabaseElementData
        let tableauElementData

        if (isLookerDataSource(dataSource) && isLookerDashboardElement(el)) {
          lookerElementData = {
            elementId: el.id,
            elementName: el.title,
            elementIndex: el.index,
          }
        } else if (isMetabaseDataSource(dataSource) && isMetabaseDashboardElement(el)) {
          metabaseElementData = {
            elementId: el.id,
            elementName: el.title,
            elementIndex: el.index,
          }
        } else if (isTableauDataSource(dataSource) && isTableauDashboardElement(el)) {
          tableauElementData = {
            objectId: el.id,
            sheetName: el.name,
            objectType: el.type,
          }
        }

        return {
          name: getDashboardElementName(el),
          tableauData: tableauElementData,
          lookerData: lookerElementData,
          metabaseData: metabaseElementData,
        }
      })
    }

    const batchAddSyncData: BatchAddSyncRequest = {
      syncs: syncsToAdd,
      ...commonData,
      tableauData,
      lookerData,
      metabaseData,
      spreadsheetsData: spreadsheetsDataBatch,
    }

    try {
      await addSync.mutateAsync(batchAddSyncData)
      updateVanityData(initialVanityData)
      updateSyncData({ selectedVis: [], fullDashboard: false })
      addSyncToast()
    } catch (e) {
      updateVanityData({ isSavingSync: false })
    } finally {
      await queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
    }
  }

  const updateCurrentSync = async () => {
    if (!originalSync || !destination || !dataSource) return

    try {
      updateVanityData({ isSavingSync: true })

      let updateData = {
        id: originalSync.id,
        updatedAt: originalSync.updatedAt,
        type: dataSource.type,
        updateType: syncData.updateType,
      }

      if (embedDataChanged || filtersChanged) {
        if (fullDashboard) {
          Object.assign(updateData, {
            name: syncNameWithFullDashboard,
            lookerData: lookerData
              ? Object.assign(lookerData, { fullDashboard, updatedAt: originalSync.lookerData?.updatedAt })
              : undefined,
            metabaseData: metabaseData
              ? Object.assign(metabaseData, { fullDashboard, updatedAt: originalSync.metabaseData?.updatedAt })
              : undefined,
            tableauData: tableauData
              ? Object.assign(tableauData, {
                  fullDashboard,
                  updatedAt: originalSync.tableauData?.updatedAt,
                  sheetName: '',
                })
              : undefined,
          })
        } else {
          if (selectedVis.length !== 1) {
            throw new Error('Only one element can be selected')
          }

          const el = selectedVis[0]
          let lookerElementData
          let metabaseElementData
          let tableauElementData

          if (isLookerDataSource(dataSource) && isLookerDashboardElement(el) && lookerData) {
            lookerElementData = {
              ...lookerData,
              elementId: el.id,
              elementName: el.title,
              elementIndex: el.index,
              fullDashboard,
              updatedAt: originalSync.lookerData?.updatedAt,
            }
          } else if (isMetabaseDataSource(dataSource) && isMetabaseDashboardElement(el) && metabaseData) {
            metabaseElementData = {
              ...metabaseData,
              elementId: el.id,
              elementName: el.title,
              elementIndex: el.index,
              fullDashboard,
              updatedAt: originalSync.metabaseData?.updatedAt,
            }
          } else if (isTableauDataSource(dataSource) && isTableauDashboardElement(el) && tableauData) {
            tableauElementData = {
              ...tableauData,
              objectId: el.id,
              sheetName: el.name,
              objectType: el.type,
              fullDashboard,
              updatedAt: originalSync.tableauData?.updatedAt,
            }
          }

          Object.assign(updateData, {
            name: getDashboardElementName(el),
            tableauData: tableauElementData,
            lookerData: lookerElementData,
            metabaseData: metabaseElementData,
          })
        }
      } else if (cellRanges) {
        Object.assign(updateData, {
          name: sheetSelected?.title,
          spreadsheetsData: cellRanges
            ? {
                sheetId: sheetSelected?.sheetId,
                cellRange: cellRanges[0],
                gridlines: spreadsheetsData?.gridlines,
                freezeRows: spreadsheetsData?.freezeRows,
                collapsedRows: spreadsheetsData?.collapsedRows,
              }
            : undefined,
        })
      }

      if (filtersRemoved) {
        await deleteAppliedFilters(originalSync.id)
      }

      await updateSync(updateData)
      await queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
      updateVanityData(initialVanityData)
      updateSyncData({ selectedVis: [], fullDashboard: false })
      updateSyncToast()
    } finally {
      updateVanityData({ isSavingSync: false })
    }
  }

  const saveSync = async () => {
    if (!canSaveSync) return

    if (isNewSync) {
      await addSyncs()
      updateSyncData({
        updateType: SyncUpdateType.image,
      })
    } else {
      await updateCurrentSync()
    }
  }

  const clearSyncSettings = useCallback(() => {
    setOriginalSync(null)
    updateVanityData(initialVanityData)
    const syncDataToUpdate: Partial<SyncData> = { ...initialSyncData }
    delete syncDataToUpdate.slideData
    updateSyncData(syncDataToUpdate)
  }, [setOriginalSync, updateVanityData, updateSyncData])

  const updateSyncSettings = useCallback(
    (sync: ISync, vanityData?: VanityData) => {
      const ld = sync.lookerData && syncToAddLookerData(sync as ISync & { lookerData: LookerData })
      const md = sync.metabaseData && syncToAddMetabaseData(sync as ISync & { metabaseData: MetabaseData })
      const td = sync.tableauData && syncToAddTableauData(sync as ISync & { tableauData: TableauData })
      const sd = sync.spreadsheetsData
      const isFullDashboard = Boolean(ld?.fullDashboard || md?.fullDashboard || td?.fullDashboard)
      const syncElement = getDashboardElement(sync)
      const selectedElements = syncElement ? [syncElement] : []

      setOriginalSync(sync)
      if (vanityData) {
        updateVanityData(vanityData)
      }

      updateSyncData({
        dataSource: getSyncDataSource(sync),
        dashboardSelected: getSyncIntegrationDashboard(sync),
        selectedVis: selectedElements,
        fullDashboard: isFullDashboard,
        lookerData: ld,
        metabaseData: md,
        tableauData: td,
        spreadsheetsData: sd,
        cropOption: sync.cropOption,
        manualCropValues: sync.manualCropValues,
        sheetSelected: sd ? { sheetId: sd.sheetId, title: sd.sheetTitle } : null,
        updateType: sync.updateType,
        cellRanges: sd ? [sd.cellRange] : null,
      })
    },
    [updateVanityData, updateSyncData]
  )

  const updateSyncSettingsIfChanged = useCallback(
    (sync: ISync | null) => {
      if (sync === originalSync) {
        return
      }

      if (!sync) {
        clearSyncSettings()
      } else {
        updateSyncSettings(sync, initialVanityData)
      }
    },
    [originalSync, clearSyncSettings, updateSyncSettings]
  )

  useEffect(() => {
    if (fullDashboard) {
      updateSyncData({ selectedVis: [] })
    }
  }, [fullDashboard, updateSyncData])

  useEffect(() => {
    if (destination) {
      setActiveDestination(destination)
    }
  }, [destination, setActiveDestination])

  return (
    <SyncSettingsContext.Provider
      value={{
        ...syncData,
        ...vanityData,
        ...filtersLogic,
        destination,
        originalSync,
        syncs,
        tableauViewSelectCallback,
        saveSync,
        setDestination,
        updateSyncData,
        setOriginalSync,
        clearSyncSettings,
        updateSyncSettings,
        updateSyncSettingsIfChanged,
        visSelectCallback,
        clearVisSelectData,
        toggleFullDashboard,
        toggleSyncUpdateType,
      }}
    >
      {children}
    </SyncSettingsContext.Provider>
  )
}

export const SyncSettingsContext = createContext<ISyncSettingsContext>(initialState)

export const useSyncSettingsContext = () => useContext(SyncSettingsContext)
