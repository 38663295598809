import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 19

export const GSlidesLogo: FC<IIcon> = ({ size = defaultSize }) => {
  return (
    <svg width={size} viewBox="0 0 152 31">
      <path
        d="M45.4616 9.11906C45.4627 9.12326 45.4642 9.12797 45.4663 9.13322C45.4674 9.13532 45.4595 9.14372 45.4427 9.15841L44.4353 10.1391C44.4101 10.1634 44.3765 10.1772 44.3415 10.1778C44.3064 10.1784 44.2725 10.1657 44.2464 10.1422C41.8412 7.97471 38.1705 8.12739 35.9636 10.4964C33.8197 12.7977 33.8733 16.5408 36.0471 18.7933C37.9171 20.731 40.9802 21.1245 43.2831 19.8417C44.598 19.1102 45.3944 17.8982 45.6725 16.2056C45.6744 16.1954 45.6739 16.185 45.6712 16.175C45.6685 16.165 45.6636 16.1558 45.6569 16.1478C45.6501 16.1399 45.6417 16.1334 45.6322 16.129C45.6226 16.1246 45.6122 16.1222 45.6017 16.1221H40.4041C40.3624 16.1221 40.3223 16.1055 40.2928 16.076C40.2633 16.0465 40.2467 16.0065 40.2467 15.9647V14.1183C40.2467 13.9357 40.3375 13.8444 40.519 13.8444L47.8353 13.8381C47.8867 13.8371 47.9156 13.8623 47.9219 13.9137C48.2052 15.9946 47.8495 18.4014 46.4848 20.0762C43.3838 23.8776 37.5912 23.7013 34.2526 20.3328C31.5279 17.586 31.0903 13.2967 33.2704 10.0635C36.0817 5.89535 42.1498 4.93832 45.8724 8.5398C45.9144 8.57968 45.9155 8.6206 45.8756 8.66257L45.4805 9.07813C45.4637 9.09492 45.4574 9.10856 45.4616 9.11906Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M86.132 6.91406H84.0417C83.9782 6.91406 83.9268 6.96551 83.9268 7.02897V22.4203C83.9268 22.4837 83.9782 22.5352 84.0417 22.5352H86.132C86.1955 22.5352 86.2469 22.4837 86.2469 22.4203V7.02897C86.2469 6.96551 86.1955 6.91406 86.132 6.91406Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M59.4331 16.6107C59.5542 17.3047 59.5372 18.0158 59.3831 18.7034C59.2289 19.391 58.9407 20.0416 58.5347 20.6181C58.1288 21.1946 57.6131 21.6857 57.0172 22.0634C56.4213 22.4411 55.7567 22.6979 55.0615 22.8192C53.6575 23.0643 52.2138 22.7425 51.0481 21.9247C49.8823 21.107 49.09 19.8602 48.8454 18.4586C48.7242 17.7646 48.7412 17.0535 48.8954 16.366C49.0495 15.6784 49.3378 15.0277 49.7437 14.4512C50.1497 13.8747 50.6653 13.3836 51.2613 13.0059C51.8572 12.6283 52.5217 12.3714 53.2169 12.2501C54.621 12.0051 56.0646 12.3268 57.2304 13.1446C58.3962 13.9624 59.1885 15.2092 59.4331 16.6107ZM54.1616 20.741C54.555 20.7382 54.9439 20.6526 55.3062 20.489C55.6685 20.3254 55.997 20.0871 56.2731 19.7875C56.5492 19.488 56.7674 19.1332 56.9152 18.7433C57.063 18.3535 57.1376 17.9362 57.1346 17.5153C57.1287 16.6654 56.8074 15.8524 56.2415 15.2554C55.6755 14.6583 54.9113 14.326 54.1169 14.3315C53.7235 14.3342 53.3346 14.4199 52.9723 14.5835C52.61 14.747 52.2814 14.9854 52.0054 15.2849C51.7293 15.5845 51.5111 15.9393 51.3633 16.3292C51.2155 16.719 51.1409 17.1363 51.1438 17.5572C51.1498 18.4071 51.4711 19.22 52.037 19.8171C52.6029 20.4142 53.3672 20.7465 54.1616 20.741Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M97.076 20.532C96.1363 22.1375 94.027 23.0001 92.2688 22.8663C88.1289 22.5515 86.1503 18.1016 88.0927 14.6607C89.1836 12.7293 91.5352 11.8022 93.6587 12.3783C95.4452 12.8615 96.6746 14.3899 97.1657 16.1529C97.1814 16.2075 97.1631 16.2458 97.1106 16.2678L90.2587 19.1043C90.2387 19.1125 90.2207 19.1248 90.2058 19.1405C90.1909 19.1561 90.1794 19.1747 90.1722 19.195C90.1649 19.2153 90.162 19.2369 90.1636 19.2585C90.1652 19.28 90.1713 19.3009 90.1815 19.3199C91.296 21.3458 93.9625 21.1065 95.2201 19.3294C95.2558 19.278 95.2999 19.2696 95.3524 19.3042L97.0524 20.4297C97.0688 20.4403 97.0805 20.457 97.0849 20.476C97.0893 20.4951 97.0861 20.5152 97.076 20.532ZM94.3088 15.0794C93.697 14.5704 93.0715 14.3165 92.4325 14.3175C90.8726 14.3207 89.7581 15.6964 89.7077 17.2406C89.7073 17.2526 89.7098 17.2645 89.7152 17.2752C89.7206 17.2859 89.7287 17.2951 89.7386 17.3019C89.7485 17.3086 89.76 17.3128 89.7719 17.3139C89.7839 17.315 89.7959 17.3131 89.8069 17.3083C91.2246 16.7206 92.5993 16.1477 93.931 15.5894C94.049 15.5406 94.1419 15.4272 94.2505 15.4021C94.2829 15.3948 94.3121 15.3781 94.3347 15.354C94.3574 15.3299 94.3726 15.2993 94.3787 15.2657C94.3847 15.2321 94.3814 15.1969 94.369 15.1639C94.3567 15.131 94.3358 15.1017 94.3088 15.0794Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M61.8331 21.1741C59.5334 18.643 60.1095 14.3773 63.2404 12.8237C65.8691 11.5187 69.1856 12.3247 70.5519 15.051C72.0725 18.0858 70.5236 21.9281 67.1252 22.7262C65.1387 23.1921 63.3888 22.6978 61.8756 21.2434C61.8609 21.2297 61.852 21.2161 61.8489 21.2025C61.8468 21.193 61.8415 21.1836 61.8331 21.1741ZM65.8416 20.7392C66.6355 20.7253 67.391 20.3743 67.9419 19.7633C68.4928 19.1522 68.7939 18.3313 68.7791 17.481C68.7717 17.06 68.687 16.6445 68.5298 16.2582C68.3726 15.8718 68.1459 15.5223 67.8627 15.2294C67.5796 14.9366 67.2455 14.7062 66.8795 14.5514C66.5135 14.3967 66.1228 14.3205 65.7297 14.3274C64.9358 14.3412 64.1803 14.6923 63.6294 15.3033C63.0785 15.9143 62.7774 16.7353 62.7922 17.5855C62.7995 18.0065 62.8843 18.4221 63.0415 18.8084C63.1987 19.1947 63.4254 19.5443 63.7085 19.8371C63.9917 20.13 64.3258 20.3604 64.6918 20.5152C65.0578 20.6699 65.4485 20.7461 65.8416 20.7392Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M73.9887 21.6541C70.8563 18.909 71.7535 13.8845 75.6603 12.4537C76.97 11.9741 78.2429 12.1152 79.4791 12.8771C79.6144 12.9621 79.7703 13.1998 79.978 13.2769C80.04 13.3 80.0709 13.2785 80.0709 13.2124V12.6189C80.0709 12.5665 80.0971 12.5402 80.1496 12.5402H82.2274C82.283 12.5402 82.3108 12.5681 82.3108 12.6237C82.3171 15.8495 82.3176 19.0721 82.3124 22.2916C82.3077 24.6937 81.209 26.8203 78.829 27.4499C76.3199 28.1141 73.5873 27.0879 72.5248 24.5662C72.5017 24.5095 72.518 24.4696 72.5736 24.4466L74.4515 23.6548C74.5197 23.6265 74.5711 23.6448 74.6057 23.7099C75.3455 25.1003 76.417 25.6854 77.82 25.465C79.7781 25.1565 80.1779 23.4108 80.0615 21.7329C80.0609 21.7264 80.0585 21.7202 80.0545 21.715C80.0506 21.7099 80.0453 21.7059 80.0392 21.7036C80.0332 21.7013 80.0266 21.7008 80.0202 21.7021C80.0138 21.7033 80.0079 21.7064 80.0032 21.7108C78.3221 23.4014 75.6903 23.1464 73.9887 21.6541ZM77.3756 20.749C78.1425 20.745 78.8762 20.4032 79.4153 19.7987C79.9544 19.1943 80.2548 18.3767 80.2503 17.5259C80.2458 16.6752 79.9369 15.8608 79.3915 15.262C78.8461 14.6633 78.1089 14.3292 77.342 14.3332C76.5751 14.3372 75.8414 14.679 75.3023 15.2835C74.7632 15.8879 74.4628 16.7054 74.4672 17.5562C74.4717 18.407 74.7806 19.2214 75.326 19.8201C75.8714 20.4189 76.6087 20.753 77.3756 20.749Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M118.163 10.5797C118.896 10.5797 119.49 9.98558 119.49 9.25273C119.49 8.51987 118.896 7.92578 118.163 7.92578C117.43 7.92578 116.836 8.51987 116.836 9.25273C116.836 9.98558 117.43 10.5797 118.163 10.5797Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M105.928 7.82184C108.048 7.57471 110.058 8.40109 111.01 10.386C111.03 10.4263 111.04 10.47 111.042 10.5146C111.044 10.5591 111.037 10.6035 111.021 10.6451C111.005 10.6868 110.981 10.7247 110.951 10.7567C110.92 10.7888 110.883 10.8142 110.842 10.8315L109.52 11.3887C109.464 11.4128 109.423 11.3976 109.395 11.343C108.626 9.84033 107.406 9.29726 105.736 9.71387C104.278 10.0775 103.425 11.894 104.794 12.9675C105.294 13.3589 105.853 13.6512 106.472 13.8443C108.904 14.603 111.475 15.5442 111.546 18.5318C111.59 20.4176 110.587 21.8484 108.834 22.4906C105.896 23.5642 102.498 22.1648 101.694 19.0198C101.678 18.961 101.699 18.9196 101.757 18.8955L103.272 18.2737C103.334 18.2485 103.377 18.2674 103.4 18.3304C104.207 20.6128 106.273 21.7996 108.604 20.5734C108.749 20.4968 108.93 20.3279 109.148 20.0666C109.863 19.2103 109.74 17.877 109.027 17.1089C108.668 16.7227 108.109 16.3943 107.349 16.1235C105.583 15.4955 103.142 14.9099 102.402 12.9124C101.457 10.3624 103.43 8.11304 105.928 7.82184Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M114.9 8.09375H113.319C113.234 8.09375 113.165 8.16281 113.165 8.24801V22.3832C113.165 22.4684 113.234 22.5375 113.319 22.5375H114.9C114.985 22.5375 115.054 22.4684 115.054 22.3832V8.24801C115.054 8.16281 114.985 8.09375 114.9 8.09375Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M121.464 20.4188C119.888 17.5996 120.901 13.4913 124.25 12.4996C125.801 12.0415 127.734 12.4807 128.728 13.9021C128.734 13.9106 128.742 13.917 128.752 13.9205C128.762 13.9239 128.772 13.9241 128.782 13.9211C128.791 13.9181 128.8 13.912 128.806 13.9038C128.812 13.8955 128.815 13.8855 128.815 13.8753L128.776 8.21967C128.776 8.13572 128.818 8.09375 128.902 8.09375L130.565 8.09848C130.64 8.09848 130.677 8.13572 130.677 8.21023V22.4352C130.677 22.4489 130.674 22.4626 130.669 22.4753C130.664 22.488 130.656 22.4995 130.646 22.5092C130.636 22.5188 130.625 22.5265 130.612 22.5316C130.599 22.5367 130.585 22.5393 130.572 22.539L128.982 22.5312C128.905 22.5312 128.867 22.4929 128.867 22.4163V21.316C128.867 21.3028 128.862 21.29 128.855 21.2793C128.847 21.2686 128.836 21.2606 128.824 21.2564C128.811 21.2523 128.798 21.2521 128.786 21.256C128.773 21.2599 128.763 21.2677 128.755 21.2782C126.755 23.9274 122.945 23.0711 121.464 20.4188ZM125.753 21.1664C126.166 21.1657 126.575 21.0728 126.956 20.893C127.338 20.7131 127.684 20.4499 127.975 20.1183C128.267 19.7868 128.498 19.3933 128.655 18.9605C128.812 18.5276 128.893 18.0639 128.892 17.5957C128.891 17.1275 128.809 16.664 128.65 16.2317C128.492 15.7994 128.259 15.4068 127.967 15.0763C127.674 14.7457 127.327 14.4837 126.945 14.3052C126.563 14.1267 126.154 14.0352 125.741 14.0359C125.328 14.0366 124.919 14.1295 124.538 14.3094C124.157 14.4892 123.81 14.7524 123.519 15.084C123.227 15.4156 122.996 15.809 122.839 16.2419C122.682 16.6747 122.601 17.1385 122.602 17.6067C122.603 18.0749 122.685 18.5383 122.844 18.9706C123.003 19.4029 123.235 19.7955 123.528 20.1261C123.82 20.4566 124.167 20.7187 124.549 20.8972C124.931 21.0757 125.34 21.1672 125.753 21.1664Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M134.124 18.0383C134.385 21.1864 138.272 22.441 139.947 19.5384C139.976 19.488 140.017 19.4744 140.071 19.4974L141.502 20.0987C141.559 20.1229 141.582 20.1659 141.569 20.2278C141.54 20.3621 141.473 20.5111 141.37 20.6749C139.177 24.1205 133.713 23.3933 132.528 19.5069C131.92 17.5157 132.237 15.4096 133.657 13.8276C135.486 11.7908 138.93 11.8175 140.723 13.8638C141.617 14.8838 142.024 16.5083 141.917 17.8683C141.915 17.8966 141.902 17.923 141.881 17.9423C141.86 17.9616 141.833 17.9723 141.804 17.9722H134.185C134.141 17.9722 134.121 17.9942 134.124 18.0383ZM139.921 16.3084C139.408 13.2704 134.961 13.3271 134.275 16.3241C134.273 16.3329 134.273 16.342 134.275 16.3509C134.277 16.3597 134.281 16.368 134.287 16.3751C134.293 16.3822 134.3 16.388 134.308 16.392C134.317 16.396 134.326 16.3981 134.335 16.3981H139.846C139.907 16.3981 139.932 16.3682 139.921 16.3084Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M150.067 17.7264C150.072 17.7253 150.075 17.7222 150.078 17.7169C150.081 17.7106 150.084 17.7096 150.087 17.7138C151.933 19.5476 150.778 22.0755 148.502 22.6894C146.152 23.3222 143.783 22.4108 142.782 20.1237C142.771 20.0992 142.771 20.0716 142.78 20.0468C142.79 20.0221 142.809 20.0022 142.834 19.9914L144.195 19.4075C144.258 19.3805 144.329 19.3788 144.393 19.4029C144.457 19.4269 144.51 19.4748 144.54 19.5365C145.154 20.7989 146.196 21.3347 147.668 21.1437C148.384 21.0508 149.19 20.4652 149.04 19.6357C148.921 18.9699 148.289 18.6755 147.677 18.526C145.895 18.09 142.812 17.6193 143.084 15.0347C143.204 13.9013 144.099 13.0325 145.149 12.6515C147.167 11.918 149.734 12.3446 150.743 14.4428C150.812 14.5845 150.772 14.6842 150.625 14.7419L149.272 15.2739C149.209 15.2981 149.162 15.2808 149.132 15.222C148.027 13.1379 143.906 14.0572 145.264 15.9288C145.401 16.1176 146.086 16.3522 146.408 16.4214C147.611 16.6764 149.209 16.9188 150.035 17.7138C150.047 17.7253 150.057 17.7295 150.067 17.7264Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M118.931 12.6582H117.395C117.298 12.6582 117.219 12.7371 117.219 12.8345V22.3577C117.219 22.455 117.298 22.534 117.395 22.534H118.931C119.029 22.534 119.108 22.455 119.108 22.3577V12.8345C119.108 12.7371 119.029 12.6582 118.931 12.6582Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M19.7855 30.9998H2.04677C0.921047 30.9998 0 30.0787 0 28.953V3.02724C0 1.90152 0.921047 0.980469 2.04677 0.980469H14.3274L21.8322 8.4853V28.953C21.8322 30.0787 20.9112 30.9998 19.7855 30.9998Z"
        fill="#F8BF08"
      />
      <path d="M14.3271 0.980469L21.832 8.4853H14.3271V0.980469Z" fill="#F59307" />
      <path
        d="M4.09277 12.75V22.3016H17.7379V12.75H4.09277ZM16.0323 20.596H5.79842V14.4556H16.0323V20.596Z"
        fill="#FDFFFF"
      />
    </svg>
  )
}
