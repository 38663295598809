import { PendingIcon, XCircle } from '@assets'
import { SmallSpinner } from '@assets/icons/small-spinner/small-spinner'
import { TickCircleOutline } from '@assets/icons/ticks'

import {
  AddVariantStatusName,
  AddVariantsStatus,
  AddVariantsStatuses,
  DestinationUpdateStatus,
  DestinationUpdateStatusName,
  DestinationsStatuses,
  RegenerateVariantStatus,
  RegenerateVariantsStatus,
  VisUpdateStatus,
  VisUpdateStatusName,
} from '@features/notch/destinations-status-provider'

import { Colors } from '@shared/constants'

const visUpdateStepsCount = 2
const hundredPercent = 100

export const regenerateVariantStatusToIcon = (status: RegenerateVariantStatus['status']) => {
  switch (status) {
    case 'FAILED':
      return <XCircle />
    case 'PENDING':
      return <PendingIcon />
    case 'REGENERATED':
      return <TickCircleOutline color={Colors.green} />
    case 'REGENERATING':
    case 'SYNCING':
      return <SmallSpinner />
  }
}

export const addVariantStatusToIcon = (status: AddVariantStatusName) => {
  switch (status) {
    case 'FAILED':
      return <XCircle />
    case 'PENDING':
      return <PendingIcon />
    case 'CREATING':
    case 'SYNCING':
      return <SmallSpinner />
    case 'CANCELLED':
      return <XCircle color={Colors.gray300} />
    case 'CREATED':
      return <TickCircleOutline color={Colors.green} />
  }
}

export const destinationStatusToIcon = (status: DestinationUpdateStatusName) => {
  switch (status) {
    case 'RUNNING':
      return <SmallSpinner />
    case 'PARTIALLY_COMPLETED':
      return <TickCircleOutline color={Colors.yellow} />
    case 'COMPLETED':
      return <TickCircleOutline color={Colors.green} />
    case 'FAILED':
      return <XCircle />
    case 'CANCELLED':
      return <XCircle color={Colors.gray300} />
  }
}

export const visStatusToIcon = (status: VisUpdateStatusName) => {
  switch (status) {
    case 'PENDING':
      return <PendingIcon />
    case 'RUNNING':
      return <SmallSpinner />
    case 'SCREENSHOT_TAKEN':
      return <TickCircleOutline color={Colors.gray400} />
    case 'COMPLETED':
      return <TickCircleOutline color={Colors.green} />
    case 'FAILED':
      return <XCircle />
    case 'CANCELLED':
      return <XCircle color={Colors.gray300} />
  }
}

export const combinedStatus = (
  destinationsStatuses: DestinationsStatuses,
  addCollectionStatuses: AddVariantsStatus[],
  regenerateStatuses: RegenerateVariantsStatus[]
) => {
  // Status for creating and regenerating variants
  if (
    addCollectionStatuses.some(status => status.status === 'CREATING') ||
    regenerateStatuses.some(status => ['PENDING', 'REGENERATING', 'SYNCING'].includes(status.status))
  ) {
    return (
      <>
        <SmallSpinner />
        Running
      </>
    )
  }
  if (
    addCollectionStatuses.some(status => status.status === 'FAILED') ||
    regenerateStatuses.some(status => status.status === 'FAILED')
  ) {
    return (
      <>
        <XCircle />
        Variants creation failed
      </>
    )
  }
  if (addCollectionStatuses.some(status => status.status === 'CANCELLED')) {
    return (
      <>
        <XCircle />
        Variants cancelled
      </>
    )
  }
  if (
    addCollectionStatuses.some(status => status.status === 'PARTIALLY_CREATED') ||
    regenerateStatuses.some(status => status.status === 'PARTIALLY_REGENERATED')
  ) {
    return (
      <>
        <TickCircleOutline color={Colors.yellow} />
        Variants partially created
      </>
    )
  }
  if (
    (addCollectionStatuses.length && addCollectionStatuses.every(status => status.status === 'CREATED')) ||
    (regenerateStatuses.length && regenerateStatuses.every(status => status.status === 'REGENERATED'))
  ) {
    return (
      <>
        <TickCircleOutline color={Colors.green} />
        Variants created successfully
      </>
    )
  }
  // Status for sync updates
  if (destinationsStatuses.length === 0) {
    return (
      <>
        <SmallSpinner />
        Running
      </>
    )
  } else if (destinationsStatuses.some(destination => destination.status === 'PENDING')) {
    return (
      <>
        <SmallSpinner />
        Running
      </>
    )
  } else if (destinationsStatuses.some(destination => destination.status === 'RUNNING')) {
    return (
      <>
        <SmallSpinner />
        Running
      </>
    )
  } else if (destinationsStatuses.every(destination => destination.status === 'COMPLETED')) {
    return (
      <>
        <TickCircleOutline color={Colors.green} />
        Update successful
      </>
    )
  } else if (
    destinationsStatuses.every(destination => ['COMPLETED', 'PARTIALLY_COMPLETED'].includes(destination.status))
  ) {
    return (
      <>
        <TickCircleOutline color={Colors.yellow} />
        Update partially successful
      </>
    )
  } else if (destinationsStatuses.every(destination => destination.status === 'FAILED')) {
    return (
      <>
        <XCircle />
        Update failed
      </>
    )
  } else if (destinationsStatuses.every(destination => destination.status === 'CANCELLED')) {
    return (
      <>
        <XCircle color={Colors.gray300} />
        Update cancelled
      </>
    )
  }
}

const getVisProgress = (vis: VisUpdateStatus) => {
  switch (vis.status) {
    case 'SCREENSHOT_TAKEN':
      return 1
    case 'COMPLETED':
      return visUpdateStepsCount
    case 'FAILED':
      return visUpdateStepsCount
    default:
      return 0
  }
}

const getProgressFullWidth = (dest: DestinationUpdateStatus) => dest.vises.length * visUpdateStepsCount

const calculateProgress = (dest: DestinationUpdateStatus) =>
  dest.vises.reduce((acc, vis) => acc + getVisProgress(vis), 0)

export const calculatePercentageProgress = (dest: DestinationUpdateStatus) =>
  (calculateProgress(dest) / getProgressFullWidth(dest)) * hundredPercent

export const calculateAllPercentageProgress = (
  destinationStatuses: DestinationsStatuses,
  addVariantsStatuses: AddVariantsStatuses,
  regenerateVariantStatuses: RegenerateVariantsStatus[]
) => {
  // here we are calculating the progress based on the destinations
  // so if we are syncing 1 destination and adding 1 collection with 2 destinations we'll have 0/3

  const finishedDestinationsStatuses = destinationStatuses.filter(destination =>
    ['PARTIALLY_COMPLETED', 'COMPLETED', 'FAILED', 'CANCELLED'].includes(destination.status)
  ).length
  const allDestinationsStatuses = destinationStatuses.length

  const finishedDestinationsFromAddVariantsStatuses = addVariantsStatuses.reduce(
    (acc, addCollection) =>
      acc + (addCollection?.variants ?? []).filter(variant => ['CREATED', 'FAILED'].includes(variant.status)).length,
    0
  )
  const allDestinationsFromAddVariantsStatuses = addVariantsStatuses.reduce(
    (acc, addCollection) => acc + (addCollection?.variants ?? []).length,
    0
  )

  const finishedDestinationsFromRegenerateVariantStatuses = regenerateVariantStatuses.reduce(
    (acc, status) => acc + status.variants.filter(variant => ['REGENERATED', 'FAILED'].includes(variant.status)).length,
    0
  )
  const allDestinationFromRegenerateVariantStatuses = regenerateVariantStatuses.reduce(
    (acc, status) => acc + status.variants.length,
    0
  )

  const finishedTasks =
    finishedDestinationsStatuses +
    finishedDestinationsFromAddVariantsStatuses +
    finishedDestinationsFromRegenerateVariantStatuses
  const allTasks =
    allDestinationsStatuses + allDestinationsFromAddVariantsStatuses + allDestinationFromRegenerateVariantStatuses
  return (finishedTasks / allTasks) * hundredPercent
}
