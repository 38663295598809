import { AxiosError } from 'axios'

import {
  useAddDestinationEmailConfig,
  useGetDestinationEmailConfig,
  useUpdateDestinationEmailConfig,
} from '@features/destinations/api/api'
import { DestinationEmailConfig } from '@features/destinations/types'
import { getEmptyDestinationEmailConfig } from '@features/destinations/utils'

import { useEmailConfigHandler } from '@shared/components/email-config/hooks/use-email-config-handler'
import { UseQueryWithErrorHandlerOptions } from '@shared/http'

export const useDestinationEmailConfigHandler = (
  destinationId: string,
  options: UseQueryWithErrorHandlerOptions<DestinationEmailConfig, AxiosError> = {}
) =>
  useEmailConfigHandler<DestinationEmailConfig>(
    {
      targetId: destinationId,
      useGetEmailConfig: useGetDestinationEmailConfig,
      useAddEmailConfig: useAddDestinationEmailConfig,
      useUpdateEmailConfig: useUpdateDestinationEmailConfig,
      getEmptyConfig: getEmptyDestinationEmailConfig,
    },
    options
  )
