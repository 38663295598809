import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { CoreDataSource } from './types/types'

interface DataSourceState {
  dataSources?: CoreDataSource[]
}

interface DataSourceActions {
  setDataSources: (dataSources: CoreDataSource[]) => void
}

export const useDataSourcesStore = create<DataSourceState & DataSourceActions>()(
  persist(
    immer(set => ({
      setDataSources: dataSources => {
        set({ dataSources })
      },
    })),
    {
      name: 'dataSources',
    }
  )
)
