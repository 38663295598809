import classNames from 'classnames'
import startCase from 'lodash/startCase'
import { FC, useState } from 'react'

import { RollstackLogoFull } from '@assets/icons/logos'

import { useAuthStore } from '@features/users'
import { useSetupUserOrganization } from '@features/users/api/api'

import { Button, Input } from '@shared/components'

import styles from './organization-setup.module.css'

interface OrganizationSetupProps {
  email: string
}

export const OrganizationSetup: FC<OrganizationSetupProps> = ({ email }) => {
  const getOrganizationNameFromEmail = (email: string): string => {
    const domain = email.split('@').pop()!
    return startCase(domain.split('.').slice(0, -1).join(' '))
  }

  const { user, updateUser } = useAuthStore()
  const { mutateAsync, isLoading } = useSetupUserOrganization()

  const [organizationName, setOrganizationName] = useState(getOrganizationNameFromEmail(email))

  const handleSave = async () => {
    const { data } = await mutateAsync({ userId: user!.id, data: { organizationName } })
    updateUser(data)
  }

  return (
    <main className={styles.container}>
      <div className={styles.logoAndHeader}>
        <RollstackLogoFull />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={classNames(styles.header, styles.verticalSpacing)}>Set up your organization</h1>
        <form>
          <Input className={styles.verticalSpacing} label="Email" disabled={true} value={email} />
          <Input
            label="Organization name"
            value={organizationName}
            onChange={e => setOrganizationName(e.target.value)}
            description="You can always rename your organization later"
          />
          <Button
            className={styles.saveBtn}
            disabled={isLoading}
            loading={isLoading}
            text="Next"
            type="button"
            onClick={handleSave}
          />
        </form>
      </div>
    </main>
  )
}
