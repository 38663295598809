import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { queryClient } from '@shared/http/query-client'

import { UserInOrganization, UserOrganizationRole } from './types'

interface OrganizationState {
  organizations?: UserInOrganization[]
  activeOrganization?: UserInOrganization
  isAdmin?: boolean
  isOwner?: boolean
  isMember?: boolean
}

interface OrganizationActions {
  setOrganizations: (organizations: UserInOrganization[]) => void
  setActiveOrganization: (organization?: UserInOrganization, skipQueryInvalidation?: boolean) => void
}

export const useOrganizationsStore = create<OrganizationState & OrganizationActions>()(
  persist(
    immer(set => ({
      setActiveOrganization: (organization, skipQueryInvalidation) => {
        set({
          activeOrganization: organization,
          isAdmin: organization?.role === UserOrganizationRole.admin,
          isOwner: organization?.role === UserOrganizationRole.owner,
          isMember: organization?.role === UserOrganizationRole.member,
        })
        if (skipQueryInvalidation === true) {
          return
        }
        queryClient.invalidateQueries()
      },
      setOrganizations: organizations => {
        set({ organizations })
      },
    })),
    {
      name: 'organizations',
    }
  )
)
