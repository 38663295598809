import { useCallback, useState } from 'react'

// Same as useBoolean, but with null as a possible value
export const useNullBoolean = (initialState: boolean | null = null) => {
  const [value, setValue] = useState<null | boolean>(initialState)

  const toggle = useCallback((val?: any) => {
    setValue(prev => {
      if (typeof val === 'boolean') return val

      return !prev
    })
  }, [])

  return [value, toggle] as const
}
