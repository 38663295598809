import uniqWith from 'lodash/uniqWith'
import { useMemo } from 'react'

import { DashboardOption } from '@features/collections/types'
import { Destination } from '@features/destinations/types'
import { getSyncDashboardOrView, getSyncDataSource } from '@features/syncs'

export const useGetDashboardsFromDestination = (destination?: Destination) => {
  const dashboards = useMemo(
    () =>
      uniqWith(
        destination?.syncs?.map(sync => ({
          sync,
          dashboardData: getSyncDashboardOrView(sync),
          credentialsData: getSyncDataSource(sync),
        })),
        (s1, s2) => s1.sync.type === s2.sync.type && s1.dashboardData?.id === s2.dashboardData?.id
      )
        .map(s => ({
          id: s.dashboardData?.id,
          name: s.dashboardData?.name,
          credentialsId: s.credentialsData?.id,
          type: s.sync.type,

          // Specific for Tableau
          vis: s.sync,
        }))
        .filter(d => d.id && d.name && d.credentialsId && d.type) as DashboardOption[],
    [destination?.syncs]
  )

  return dashboards
}
