import { ColumnDef } from '@tanstack/react-table'
import { NavigateFunction } from 'react-router-dom'

import { buildHistoricalRunDetailsPath } from '@pages/routes/paths'

import { destinationHandlers } from '@features/destinations/destination-handlers'
import { Destination, UpdateMethod } from '@features/destinations/types'
import { DestinationStatus, StatusLabel } from '@features/historical-run/components/status-label/status-label'
import { HistoricalRun } from '@features/historical-run/types'
import { mapTriggerToLabel, mapUpdateMethodToLabel } from '@features/historical-run/utils'

import { Button } from '@shared/components/button'
import { formatDate } from '@shared/utils'

import styles from './historical-runs-table.module.css'

interface RowData {
  id: string
  status: DestinationStatus
  destination: JSX.Element | null
  startedAt: string
  trigger: string
  updateMethod: string
  archiveButton: JSX.Element | null
}

export const historicalRunsTableData = (historicalRuns: HistoricalRun[], navigate: NavigateFunction): RowData[] =>
  historicalRuns.map(run => {
    const destinationConstruct = destinationHandlers({
      type: run.destinationType,
      docId: run.archivedDestinationDocId,
      docUrl: run.archivedDestinationDocUrl,
    } as unknown as Destination)
    const destinationUrl = destinationConstruct.getDestinationUrl()
    const destinationIcon = destinationConstruct.getSmallIcon()

    return {
      id: run.id,
      status: run.status,
      destination: (
        <div className={styles.destination}>
          {destinationConstruct.getSmallIcon()}
          <span className={styles.destinationName}>{run.destinationName}</span>
        </div>
      ),
      startedAt: formatDate(run.startedAt),
      trigger: mapTriggerToLabel(run.trigger),
      updateMethod: mapUpdateMethodToLabel(run.updateMethod),
      archiveButton:
        run.updateMethod === UpdateMethod.Archive && destinationUrl ? (
          <Button
            secondaryGray
            text="Open archive"
            iconTrailing={destinationIcon}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              window.open(destinationUrl, '_blank')
            }}
          />
        ) : null,
      onClick: () => navigate(buildHistoricalRunDetailsPath(run.id)),
    }
  })

export const historicalRunsTableColumns = (showDestinationName: boolean = true): ColumnDef<RowData>[] => {
  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: 'status',
      header: 'Status',
      cell: info => <StatusLabel status={info.getValue() as DestinationStatus} />,
    },
    {
      accessorKey: 'startedAt',
      header: 'Started',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'trigger',
      header: 'Trigger',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'updateMethod',
      header: 'Update Method',
      cell: info => info.getValue(),
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row }) => row.original.archiveButton,
    },
  ]

  if (showDestinationName) {
    columns.splice(1, 0, {
      accessorKey: 'destination',
      header: 'Destination',
      cell: ({ row }) => row.original.destination,
    })
  }

  return columns
}
