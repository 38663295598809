import classNames from 'classnames'
import { FC } from 'react'
import { Link, To, useMatch } from 'react-router-dom'

import {
  Paths,
  buildCollectionPath,
  buildCollectionSettingsPath,
  buildDestinationPath,
  buildLookerDataSourceSettingsPath,
  buildMetabaseDataSourceSettingsPath,
  buildTableauDataSourceSettingsPath,
  buildVisualizationAddPath,
  buildVisualizationEditPath,
} from '@pages/routes/paths'

import { HomeIcon } from '@assets'
import { ChevronRight } from '@assets/icons/arrows'

import { useGetCollectionById } from '@features/collections'
import { useGetLookerDataSource, useGetMetabaseDataSource, useGetTableauDataSource } from '@features/data-sources'
import { useGetDestinationById } from '@features/destinations'

import styles from './breadcrumbs.module.css'

interface IBuildBreadcrumbForPath {
  path?: string
  title: string
}

interface ICustomCrumb {
  [key: string]: string
}

const grayChevron = <ChevronRight color="#D0D5DD" />

const BuildBreadcrumbForPath: FC<IBuildBreadcrumbForPath> = ({ path = '', title }) => {
  const isActive = Boolean(useMatch(path))
  const linkClass = classNames(styles.link, isActive && styles.active)

  return (
    <>
      {grayChevron}
      {isActive ? (
        <span className={linkClass}>{title}</span>
      ) : path ? (
        <Link to={path} className={linkClass}>
          {title}
        </Link>
      ) : (
        <span className={linkClass}>{title}</span>
      )}
    </>
  )
}

export const HomeCrumb = () => {
  return (
    <Link to={Paths.BASE} className={styles.home}>
      <HomeIcon />
    </Link>
  )
}

export const DestinationsCrumb = () => <BuildBreadcrumbForPath title="Destinations" path={Paths.DESTINATIONS} />

export const DestinationsNewCrumb = () => <BuildBreadcrumbForPath title="New" path={Paths.ADD_DESTINATION} />

export const CollectionsCrumb = () => <BuildBreadcrumbForPath title="Collections" path={Paths.COLLECTIONS} />

export const OrganizationSettingsCrumb = () => (
  <BuildBreadcrumbForPath title="Settings" path={Paths.SETTINGS_ORGANIZATION} />
)

export const OrganizationSSOCrumb = () => (
  <BuildBreadcrumbForPath title="Organization SSO" path={Paths.SETTINGS_ORGANIZATION_SSO} />
)

export const OrganizationDirectorySyncCrumb = () => (
  <BuildBreadcrumbForPath title="Organization directory sync" path={Paths.SETTINGS_ORGANIZATION_DIRECTORY_SYNC} />
)

export const AccountSettingsCrumb = () => <BuildBreadcrumbForPath title="Settings" path={Paths.SETTINGS_ACCOUNT} />

export const UsageSettingsCrumb = () => <BuildBreadcrumbForPath title="Settings" path={Paths.SETTINGS_USAGE} />

export const DataSourcesCrumb = () => <BuildBreadcrumbForPath title="Data sources" path={Paths.DATA_SOURCES} />

export const AddDataSourceCrumb = () => <BuildBreadcrumbForPath title="Add data source" path={Paths.ADD_DATA_SOURCE} />

export const DataSourcesNewLookerCrumb = () => <BuildBreadcrumbForPath title="Looker" path={Paths.LOOKER_DATA_SOURCE} />

export const DataSourcesNewMetabaseCrumb = () => (
  <BuildBreadcrumbForPath title="Metabase" path={Paths.METABASE_DATA_SOURCE} />
)

export const DataSourcesNewTableauCrumb = () => (
  <BuildBreadcrumbForPath title="Tableau" path={Paths.TABLEAU_DATA_SOURCE} />
)

export const CollectionCrumb = ({ collectionId }: ICustomCrumb) => {
  const path = buildCollectionPath(collectionId)
  const isActive = Boolean(useMatch(path))
  const { data: collection } = useGetCollectionById({ collectionId })

  const linkClass = classNames(isActive && styles.active)

  return collection ? (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        {collection.name}
      </Link>
    </>
  ) : null
}

export const CollectionSettingsCrumb = ({ collectionId }: ICustomCrumb) => {
  const path = buildCollectionSettingsPath(collectionId)
  const isActive = Boolean(useMatch(path))

  const linkClass = classNames(isActive && styles.active)

  return (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        Collection settings
      </Link>
    </>
  )
}

export const DataSourcesTableauCrumb = ({ sourceId }: ICustomCrumb) => {
  const path = buildTableauDataSourceSettingsPath(sourceId)
  const isActive = Boolean(useMatch(path))
  const { data: ds } = useGetTableauDataSource(sourceId)

  const linkClass = classNames(isActive && styles.active)

  return ds ? (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        {ds.name}
      </Link>
    </>
  ) : null
}

export const DataSourcesLookerCrumb = ({ sourceId }: ICustomCrumb) => {
  const path = buildLookerDataSourceSettingsPath(sourceId)
  const isActive = Boolean(useMatch(path))
  const { data: ds } = useGetLookerDataSource(sourceId)

  const linkClass = classNames(isActive && styles.active)

  return ds ? (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        {ds.name}
      </Link>
    </>
  ) : null
}

export const DataSourcesMetabaseCrumb = ({ sourceId }: ICustomCrumb) => {
  const path = buildMetabaseDataSourceSettingsPath(sourceId)
  const isActive = Boolean(useMatch(path))
  const { data: ds } = useGetMetabaseDataSource(sourceId)

  const linkClass = classNames(isActive && styles.active)

  return ds ? (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        {ds.name}
      </Link>
    </>
  ) : null
}

export const DestinationCrumb = ({ destinationId }: ICustomCrumb) => {
  const path = buildDestinationPath(destinationId)
  const to: To = {
    pathname: path,
    search: window.location.search,
  }
  const isActive = Boolean(useMatch(path))
  const { data: destination } = useGetDestinationById({ destinationId })

  const linkClass = classNames(isActive && styles.active)

  return destination ? (
    <>
      {grayChevron}
      <Link to={to} className={linkClass}>
        {destination.name}
      </Link>
    </>
  ) : null
}

export const VisualizationDetailsCrumb = ({ destinationId, visualizationId }: ICustomCrumb) => {
  const path = buildVisualizationEditPath(destinationId, visualizationId)
  const to: To = {
    pathname: path,
    search: window.location.search,
  }
  const isActive = Boolean(useMatch(path))
  const { data: destination } = useGetDestinationById({ destinationId })

  const activeVisualization = destination?.syncs?.find(sync => sync.id === visualizationId)
  if (!activeVisualization) {
    return null
  }

  const linkClass = classNames(isActive && styles.active)

  return (
    <>
      {grayChevron}
      <Link to={to} className={linkClass}>
        {activeVisualization.name}
      </Link>
    </>
  )
}

export const VisualizationAddCrumb = ({ destinationId }: ICustomCrumb) => {
  const path = buildVisualizationAddPath(destinationId)
  const isActive = Boolean(useMatch(path))

  const linkClass = classNames(isActive && styles.active)

  return (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        New visualization
      </Link>
    </>
  )
}

export const HistoricalRunsCrumb = () => <BuildBreadcrumbForPath title="Update runs" path={Paths.HISTORICAL_RUNS} />

export const HistoricalRunDetailsCrumb = ({ historicalRunId }: ICustomCrumb) => {
  const path = Paths.HISTORICAL_RUNS_DETAILS.replace(':historicalRunId', historicalRunId)
  const isActive = Boolean(useMatch(path))

  const linkClass = classNames(isActive && styles.active)

  return (
    <>
      {grayChevron}
      <Link to={path} className={linkClass}>
        {historicalRunId}
      </Link>
    </>
  )
}
