import { FC } from 'react'

export const LogoFull: FC = () => {
  return (
    <svg width="142" height="32" viewBox="0 0 142 32" fill="none">
      <path
        d="M17.1759 7V11.7554H31.442C32.7556 11.7554 33.8197 10.6913 33.8197 9.37768C33.8197 8.06407 32.7556 7 31.442 7H17.1759Z"
        fill="#0098D9"
      />
      <path
        d="M23.9017 15.73V17.2911H18.773V15.73H23.9017ZM25.4988 14.1329H17.1759V18.8882H25.4988V14.1329Z"
        fill="#0098D9"
      />
      <path
        d="M32.2224 22.8628V24.424H27.0938V22.8628H32.2224ZM33.8195 21.2657H25.4967V26.0211H33.8195V21.2657Z"
        fill="#0098D9"
      />
      <path d="M15.5971 7H14V26.0234H15.5971V7Z" fill="#0098D9" />
      <path
        d="M45.2639 24.4045V8.745H51.5944C52.5626 8.745 53.4171 8.91269 54.1557 9.24808C54.8944 9.58348 55.4713 10.0606 55.8886 10.6795C56.3058 11.2984 56.5154 12.041 56.5154 12.9054V13.151C56.5154 14.1791 56.2699 14.9936 55.7768 15.5886C55.2857 16.1855 54.7027 16.5887 54.0319 16.7964V17.1098C54.569 17.1258 55.0122 17.2994 55.3635 17.6348C55.7129 17.9702 55.8886 18.4733 55.8886 19.1441V24.4005H54.0319V19.3677C54.0319 18.9205 53.9082 18.5771 53.6626 18.3396C53.4171 18.102 53.0477 17.9822 52.5546 17.9822H47.1405V24.4025H45.2619L45.2639 24.4045ZM47.1425 16.2833H51.4147C52.4428 16.2833 53.2374 16.0337 53.7964 15.5347C54.3554 15.0356 54.6349 14.3368 54.6349 13.4425V13.2847C54.6349 12.3904 54.3593 11.6936 53.8064 11.1925C53.2554 10.6935 52.4568 10.4439 51.4127 10.4439H47.1405V16.2833H47.1425Z"
        fill="#261E47"
      />
      <path
        d="M63.3842 24.7181C62.2802 24.7181 61.308 24.4865 60.4655 24.0253C59.6231 23.5642 58.9663 22.9114 58.4971 22.0689C58.028 21.2264 57.7924 20.2302 57.7924 19.0823V18.7909C57.7924 17.6569 58.028 16.6647 58.4971 15.8163C58.9663 14.9658 59.6231 14.311 60.4655 13.8478C61.308 13.3867 62.2802 13.1551 63.3842 13.1551C64.4882 13.1551 65.4605 13.3867 66.3029 13.8478C67.1454 14.311 67.8022 14.9658 68.2714 15.8163C68.7405 16.6667 68.9761 17.6589 68.9761 18.7909V19.0823C68.9761 20.2302 68.7405 21.2264 68.2714 22.0689C67.8022 22.9114 67.1454 23.5642 66.3029 24.0253C65.4605 24.4885 64.4862 24.7181 63.3842 24.7181ZM63.3842 23.129C64.5481 23.129 65.4764 22.7597 66.1692 22.021C66.8619 21.2823 67.2093 20.2881 67.2093 19.0344V18.8328C67.2093 17.5811 66.8619 16.5849 66.1692 15.8462C65.4764 15.1076 64.5481 14.7382 63.3842 14.7382C62.2203 14.7382 61.312 15.1076 60.6113 15.8462C59.9105 16.5849 59.5592 17.5791 59.5592 18.8328V19.0344C59.5592 20.2881 59.9085 21.2823 60.6113 22.021C61.312 22.7597 62.2363 23.129 63.3842 23.129Z"
        fill="#261E47"
      />
      <path d="M71.1945 24.4045V8.74493H72.9613V24.4045H71.1945Z" fill="#261E47" />
      <path d="M75.6561 24.4045V8.74493H77.4229V24.4045H75.6561Z" fill="#261E47" />
      <path
        d="M84.5361 24.7179C83.1786 24.7179 82.0347 24.4045 81.1023 23.7776C80.17 23.1508 79.6071 22.1606 79.4134 20.8031L81.0904 20.4218C81.2102 21.1384 81.4338 21.7014 81.7612 22.1107C82.0886 22.5199 82.4958 22.8114 82.9809 22.9831C83.4661 23.1548 83.9831 23.2406 84.5361 23.2406C85.3566 23.2406 86.0054 23.0769 86.4826 22.7495C86.9597 22.4221 87.1993 21.9809 87.1993 21.4299C87.1993 20.8789 86.9717 20.4637 86.5165 20.2321C86.0613 20.0005 85.4385 19.8109 84.6479 19.6611L83.7316 19.5054C83.0309 19.3856 82.39 19.2 81.8071 18.9464C81.2261 18.6929 80.763 18.3495 80.4196 17.9183C80.0762 17.4851 79.9045 16.9421 79.9045 16.2853C79.9045 15.3011 80.2858 14.5325 81.0445 13.9815C81.8051 13.4305 82.8112 13.153 84.065 13.153C85.3187 13.153 86.3049 13.4324 87.0735 13.9914C87.8421 14.5504 88.3372 15.337 88.5608 16.3511L86.9058 16.7764C86.772 15.9858 86.4506 15.4308 85.9436 15.1094C85.4365 14.788 84.8096 14.6283 84.065 14.6283C83.3203 14.6283 82.7294 14.766 82.2982 15.0415C81.865 15.317 81.6494 15.7243 81.6494 16.2613C81.6494 16.7983 81.855 17.1677 82.2642 17.4132C82.6735 17.6588 83.2225 17.8424 83.9093 17.9622L84.8256 18.1179C85.6002 18.2517 86.2989 18.4354 86.9178 18.6669C87.5367 18.8985 88.0278 19.2299 88.3951 19.6631C88.7604 20.0963 88.9441 20.6633 88.9441 21.364C88.9441 22.4381 88.5448 23.2646 87.7463 23.8475C86.9477 24.4285 85.8777 24.7199 84.5361 24.7199V24.7179Z"
        fill="#261E47"
      />
      <path
        d="M95.0174 24.4045C94.3905 24.4045 93.9094 24.2328 93.574 23.8894C93.2386 23.546 93.0709 23.0769 93.0709 22.48V15.0075H89.7829V13.4643H93.0709V9.57141H94.8377V13.4643H98.4172V15.0075H94.8377V22.2104C94.8377 22.6576 95.0533 22.8812 95.4865 22.8812H97.9241V24.4025H95.0154L95.0174 24.4045Z"
        fill="#261E47"
      />
      <path
        d="M103.524 24.7182C102.763 24.7182 102.07 24.5844 101.443 24.3149C100.817 24.0474 100.325 23.6581 99.9661 23.151C99.6088 22.6439 99.4291 22.0251 99.4291 21.2944C99.4291 20.5637 99.6088 19.9329 99.9661 19.4497C100.323 18.9646 100.817 18.5993 101.443 18.3537C102.07 18.1082 102.771 17.9844 103.546 17.9844H107.237V17.1799C107.237 16.4053 107.009 15.7924 106.554 15.3452C106.099 14.898 105.424 14.6744 104.53 14.6744C103.635 14.6744 102.965 14.886 102.472 15.3112C101.978 15.7365 101.643 16.3074 101.465 17.0221L99.8104 16.4851C99.9901 15.8742 100.272 15.3192 100.661 14.8181C101.048 14.3191 101.567 13.9158 102.216 13.6103C102.865 13.3049 103.643 13.1512 104.554 13.1512C105.955 13.1512 107.045 13.5125 107.82 14.2352C108.594 14.9579 108.984 15.976 108.984 17.2897V22.2327C108.984 22.6799 109.191 22.9035 109.611 22.9035H110.617V24.4027H109.074C108.58 24.4027 108.185 24.265 107.888 23.9895C107.588 23.714 107.441 23.3447 107.441 22.8815V22.7697H107.173C107.009 23.0532 106.79 23.3486 106.512 23.6541C106.237 23.9595 105.859 24.2131 105.382 24.4147C104.905 24.6163 104.286 24.7162 103.526 24.7162L103.524 24.7182ZM103.725 23.1969C104.769 23.1969 105.616 22.8875 106.265 22.2686C106.914 21.6497 107.239 20.7813 107.239 19.6633V19.4178H103.615C102.915 19.4178 102.336 19.5715 101.883 19.8769C101.427 20.1824 101.2 20.6416 101.2 21.2525C101.2 21.8633 101.435 22.3405 101.905 22.6839C102.374 23.0272 102.983 23.1989 103.727 23.1989L103.725 23.1969Z"
        fill="#261E47"
      />
      <path
        d="M116.937 24.7182C115.877 24.7182 114.927 24.4906 114.084 24.0354C113.242 23.5802 112.577 22.9274 112.094 22.077C111.609 21.2265 111.367 20.2203 111.367 19.0564V18.8109C111.367 17.633 111.609 16.6229 112.094 15.7804C112.579 14.9379 113.242 14.2891 114.084 13.8339C114.927 13.3788 115.877 13.1512 116.937 13.1512C117.997 13.1512 118.872 13.3448 119.61 13.7321C120.349 14.1194 120.93 14.6345 121.355 15.2753C121.78 15.9162 122.052 16.6029 122.172 17.3336L120.449 17.6909C120.373 17.1539 120.199 16.6628 119.924 16.2136C119.648 15.7664 119.263 15.4091 118.772 15.1396C118.279 14.872 117.676 14.7363 116.959 14.7363C116.242 14.7363 115.576 14.9 115.003 15.2274C114.428 15.5548 113.975 16.026 113.639 16.6368C113.304 17.2477 113.136 17.9784 113.136 18.8289V19.0305C113.136 19.8809 113.304 20.6116 113.639 21.2225C113.975 21.8334 114.43 22.3045 115.003 22.632C115.578 22.9594 116.228 23.1231 116.959 23.1231C118.047 23.1231 118.88 22.8396 119.455 22.2726C120.03 21.7056 120.383 21.0049 120.517 20.1704L122.261 20.5517C122.098 21.2684 121.798 21.9472 121.367 22.588C120.934 23.2289 120.349 23.7439 119.61 24.1312C118.872 24.5185 117.981 24.7122 116.937 24.7122V24.7182Z"
        fill="#261E47"
      />
      <path
        d="M124.27 24.4045V8.74493H126.036V17.9622H126.35L131.361 13.4664H133.822L127.759 18.7907L134 24.4065H131.562L126.35 19.6411H126.036V24.4065H124.27V24.4045Z"
        fill="#261E47"
      />
    </svg>
  )
}
