import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useGetInvitesByUser } from '@features/users/api/api'

import { SocketEvent, useAuthenticatedSocket } from '@shared/hooks/use-authenticated-socket'

import { Invite, InviteStatus } from './types'

interface IOrganizationInviteContext {
  visible: boolean
  invite?: Invite
  closeInvite: () => void
}

const OrganizationInviteContext = createContext<IOrganizationInviteContext>({
  invite: undefined,
  visible: false,
  closeInvite: () => {},
})

export const OrganizationInviteProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: invitesFromRequest } = useGetInvitesByUser()
  const [invite, setInvite] = useState<Invite>()

  const pendingInvites = useMemo(() => {
    return (invitesFromRequest || []).filter(({ status }) => status === InviteStatus.request)
  }, [invitesFromRequest])

  useEffect(() => {
    const invite = pendingInvites.length ? pendingInvites[0] : undefined
    setInvite(invite)
  }, [pendingInvites])

  const closeInvite = () => setInvite(undefined)

  const events: SocketEvent[] = [
    {
      name: 'organizationInvite',
      handler: async (invite: Invite) => {
        setInvite(invite)
      },
    },
  ]

  useAuthenticatedSocket(events)

  return (
    <OrganizationInviteContext.Provider
      value={{
        invite,
        visible: Boolean(invite),
        closeInvite,
      }}
    >
      {children}
    </OrganizationInviteContext.Provider>
  )
}

export const useOrganizationInviteContext = () => useContext(OrganizationInviteContext)
