import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 20

export const ActivityIcon: FC<Pick<IIcon, 'size'>> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3333 9H15L12.5 16.5L7.49996 1.5L4.99996 9H1.66663"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
