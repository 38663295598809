import { FC, useContext } from 'react'

import { RefreshSingleArrowIcon, UpdateIcon } from '@assets'

import { useRecreateCollectionVariants } from '@features/collections/api/api'
import { ICollection } from '@features/collections/types'
import { destinationHandlers } from '@features/destinations'
import { FreeTrialContext } from '@features/free-trial/free-trial-provider'

import { ActionModal, Button } from '@shared/components'

interface IRecreateVariantsModalProps {
  visible: boolean
  collection: ICollection
  toggle: (param?: boolean) => void
}
export const RecreateVariantsModal: FC<IRecreateVariantsModalProps> = ({ visible, collection, toggle }) => {
  const { freeTrial } = useContext(FreeTrialContext)

  const { isLoading, mutate: recreateCollectionVariants } = useRecreateCollectionVariants(collection.id, {
    onSuccess: () => {
      toggle(false)
    },
  })

  const collectionHandler = destinationHandlers(collection.type)

  const handleRegenerateVariants = async (withUpdate: boolean) => {
    freeTrial(async () => {
      recreateCollectionVariants({ withUpdate })
    })
  }

  return (
    <ActionModal
      open={visible}
      Icon={RefreshSingleArrowIcon}
      title="Recreate all variants"
      subtitle={`Are you sure you want to recreate all the variants? New ${collectionHandler.getTitlePlural()} will be created based on the current template, and you'll lose all the changes made in the variants. The old files will be archived in ${collectionHandler.getCloudTitle()}.`}
      processing={isLoading}
      onBackgroundClick={() => toggle(false)}
      btns={
        <div className="w-full flex gap-4 items-center">
          <Button fullWidth secondaryGray text="Cancel" onClick={() => toggle(false)} disabled={isLoading} />
          <div className="flex flex-col w-full gap-2">
            <Button
              fullWidth
              iconLeading={<UpdateIcon />}
              text="Recreate and update all"
              onClick={() => handleRegenerateVariants(true)}
              loading={isLoading}
              disabled={isLoading}
            />
            <Button
              fullWidth
              secondaryColor
              text="Recreate all"
              onClick={() => handleRegenerateVariants(false)}
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      }
    />
  )
}
