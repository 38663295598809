import { FC } from 'react'

import { IIcon } from '@assets/types'

export const Kargo: FC<IIcon> = () => {
  return (
    <svg width="44" height="27" viewBox="0 0 44 27" fill="none">
      <path
        d="M0.5 13.6528V26.499H22H43.5V13.6528V0.806525H22H0.5V13.6528ZM41.5435 6.26752L41.565 10.1698L41.35 10.0515C40.5545 9.62152 39.3075 9.51402 38.426 9.78277C36.8028 10.2665 35.7815 11.5565 35.674 13.2335C35.502 15.9855 38.211 17.985 40.8663 17.0498C41.221 16.9208 41.522 16.824 41.5328 16.824C41.5543 16.824 41.565 18.5655 41.565 20.694V24.564L22.0323 24.5425L2.48875 24.5103L2.46725 20.2103L2.44575 15.9103L3.04775 15.233C3.37025 14.8568 3.69275 14.5773 3.7465 14.588C3.80025 14.6095 4.2195 15.1793 4.68175 15.8565L5.52025 17.0928L6.7135 17.125C7.38 17.1358 7.9175 17.125 7.9175 17.082C7.9175 17.0175 6.70275 15.2438 5.73525 13.8785C5.45575 13.4808 5.19775 13.0938 5.1655 13.0185C5.13325 12.9433 5.34825 12.6315 5.6385 12.309C5.92875 11.9865 6.53075 11.3093 6.9715 10.804L7.7885 9.89027L6.59525 9.85802L5.41275 9.82577L5.0795 10.2343C4.31625 11.1695 2.61775 13.1153 2.5425 13.1475C2.44575 13.1798 2.4135 2.53727 2.4995 2.39752C2.53175 2.35452 11.3253 2.32227 22.0323 2.33302L41.5113 2.36527L41.5435 6.26752ZM40.662 11.836C41.2103 12.2768 41.4575 12.782 41.4575 13.47C41.4575 14.631 40.6513 15.4265 39.458 15.4265C38.899 15.4265 38.512 15.2545 38.1143 14.8138C37.6843 14.3515 37.5553 14.0398 37.5553 13.4378C37.5553 12.825 37.7488 12.395 38.2003 11.965C38.6303 11.5565 38.9528 11.449 39.6515 11.4813C40.146 11.5135 40.3503 11.578 40.662 11.836Z"
        fill="black"
      />
      <path
        d="M29.7939 9.76099C27.0957 10.535 26.1067 13.846 27.9449 15.9637C28.8587 17.0172 30.2239 17.4257 32.2019 17.243C33.7607 17.1032 33.6102 17.3075 33.6102 15.2542V13.4912H32.6427H31.6752V14.4587V15.4262H31.0517C29.7079 15.4262 28.9232 14.6092 29.0199 13.3192C29.0414 12.9537 29.1489 12.513 29.2564 12.341C29.7079 11.5777 30.8152 11.2552 31.7827 11.61C32.0514 11.7067 32.3417 11.8035 32.4277 11.8357C32.5352 11.868 32.7394 11.653 33.0404 11.1585L33.4919 10.4275L33.2554 10.2447C32.6104 9.72874 30.7937 9.48149 29.7939 9.76099Z"
        fill="black"
      />
      <path
        d="M11.8517 10.3417C11.7335 10.632 11.3142 11.578 10.9165 12.4702C10.1532 14.1795 9.00295 16.7917 8.91695 17.0067C8.87395 17.1142 9.11045 17.1465 9.90595 17.1465H10.938L11.1745 16.609L11.411 16.0715H12.9912H14.5714L14.8079 16.609L15.0337 17.1465H16.0442C16.7107 17.1465 17.0547 17.1035 17.0547 17.0282C17.0547 16.91 16.6462 15.964 15.2164 12.7927C14.8724 12.0187 14.4532 11.0512 14.2705 10.6427L13.9587 9.89023L13.002 9.85798L12.056 9.82573L11.8517 10.3417ZM13.647 13.5667L14.0232 14.5665H13.0127C12.4537 14.5665 12.0022 14.545 12.0022 14.5235C12.0022 14.4912 12.7655 12.395 12.9267 11.9865C12.9697 11.879 13.0342 11.9435 13.131 12.2015C13.2062 12.4057 13.4427 13.0292 13.647 13.5667Z"
        fill="black"
      />
      <path
        d="M18.7969 13.4592L18.8291 17.0927L19.8289 17.125L20.8179 17.1572V15.964V14.7815H21.2909H21.7639L22.3551 15.7705C23.2689 17.2862 23.0754 17.1572 24.3331 17.125L25.4296 17.0927L24.5911 15.792L23.7419 14.4912L24.0321 14.3192C24.4299 14.0827 24.8599 13.4377 25.0104 12.8465C25.3006 11.7607 24.7201 10.5567 23.6451 10.0085C23.3656 9.86873 22.9034 9.83648 21.0329 9.83648H18.7754L18.7969 13.4592ZM22.9679 11.664C23.2796 11.9757 23.2581 12.567 22.9141 12.9002C22.6776 13.1475 22.5701 13.169 21.6779 13.169H20.7104V12.309V11.449H21.7316C22.6131 11.449 22.7851 11.4812 22.9679 11.664Z"
        fill="black"
      />
    </svg>
  )
}
