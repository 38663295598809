import { ETutorialSections, TutorialSection } from '@features/settings/components/directory-sync/tutorials/types'

export const OktaTutorialSections: Record<ETutorialSections, TutorialSection> = {
  [ETutorialSections.SETUP]: {
    title: 'Okta SCIM v2.0',
    description:
      'The following guide will walk you through the process of configuring SAML Jackson to use Okta as a directory sync provider.',
    steps: [
      {
        text: 'Click Applications from the left navigation menu and click Browse App Catalog button.',
        imgUrl: 'https://boxyhq.com/assets/images/1-5f3793b0e302c1ed121ea9c0fb570f04.png',
      },
      {
        text: 'Search for SCIM 2.0 Test App (Oauth Bearer Token) from the search box and choose the app called SCIM 2.0 Test App (Oauth Bearer Token).',
        imgUrl: 'https://boxyhq.com/assets/images/2-ce530850c67eeecffdff1e417e410e94.png',
      },
      {
        text: 'From the app page, click the Add Integration button.',
        imgUrl: 'https://boxyhq.com/assets/images/3-1b7265be2483524f803dd274a9ad0cb7.png',
      },
      {
        text: 'Give the app a name and click the Next button. Click Done to finish the creation of the app.',
        imgUrl: 'https://boxyhq.com/assets/images/4-100151144d03cd98e461bfad16bd5420.png',
      },
    ],
  },
  [ETutorialSections.PROVISIONING]: {
    title: 'Enable Okta SCIM Provisioning',
    description: 'In your application, click the Provisioning tab and click Configure API Integration.',
    steps: [
      {
        text: "Check the Enable API Integration checkbox and enter the following information: SCIM 2.0 Base URL, OAuth Bearer Token. You'll receive these information from Jackson when you create the directory sync connection either via the API or the Admin Portal.",
        imgUrl: 'https://boxyhq.com/assets/images/5-596e3682a0cb944285d6588376e54646.png',
      },
      {
        text: 'Click Test API Credentials to test the connection to see if the credentials are correct then click the Save to save the credentials.',
        imgUrl: 'https://boxyhq.com/assets/images/6-4232a6ce7b7525dd4c5ce9762d8c1336.png',
      },
      {
        text: "You'll see a new setting page, click To App and enable the following settings:",
        imgUrl: 'https://boxyhq.com/assets/images/7-43c63f1b3e2c95edd7968578f1028d84.png', // replace with your image path
      },
      {
        text: "At this stage, you've successfully configured the Okta SCIM API integration.",
      },
    ],
  },
}
