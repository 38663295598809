import { FC } from 'react'

import { IIcon } from '@assets/types'

export const Scopely: FC<IIcon> = () => {
  return (
    <svg width="88" height="23" viewBox="0 0 88 23" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30459 0.670898L2.30456 10.9678L7.00288 15.6788L0.0400391 22.6341H0.432672H14.1969V12.3236L9.49622 7.62701L16.4605 0.671935L2.30459 0.670898ZM2.8807 1.01732L15.6983 1.01826L9.2883 7.39149L2.8807 1.01732ZM2.67285 10.7606V1.22517L9.19113 7.7436L13.6198 12.1721L2.67285 10.7606ZM2.94999 11.1329L13.8969 12.5428V22.0798L7.3769 15.5703L2.94999 11.1329ZM7.27952 15.9135L13.6891 22.2877H0.871454L7.27952 15.9135Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7835 8.57011C25.152 8.57011 24.7357 8.79971 24.7357 9.259C24.7357 10.9238 30.0316 9.97658 30.0316 13.5934C30.0316 15.6456 28.2232 16.6934 25.9843 16.6934C24.3051 16.6934 22.5543 16.0762 21.3486 15.086L22.3676 13.0336C23.401 13.9234 24.9654 14.5549 26.0131 14.5549C26.788 14.5549 27.2761 14.2679 27.2761 13.7369C27.2761 12.0289 21.9802 13.0623 21.9802 9.50295C21.9802 7.62292 23.5732 6.43164 25.9987 6.43164C27.477 6.43164 28.9696 6.89093 30.0173 7.56541L29.0269 9.64651C27.965 9.04367 26.6158 8.57011 25.7835 8.57011Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0159 8.7001C34.4659 8.7001 33.289 9.92013 33.289 11.5275C33.289 13.1493 34.4659 14.3835 36.0159 14.3835C36.8483 14.3835 37.8099 13.9243 38.5132 13.2067L40.0488 14.8572C38.9724 15.991 37.365 16.723 35.8724 16.723C32.8441 16.723 30.5908 14.5128 30.5908 11.5562C30.5908 8.65715 32.9015 6.47559 35.9728 6.47559C37.4654 6.47559 39.0298 7.13573 40.0345 8.19786L38.5132 10.0205C37.853 9.21676 36.8914 8.7001 36.0159 8.7001Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.0879 11.5848C43.0879 13.2211 44.3652 14.484 45.8578 14.484C47.3649 14.484 48.5704 13.2211 48.5704 11.5848C48.5704 9.94875 47.3649 8.71444 45.8578 8.71444C44.3508 8.71444 43.0879 9.94875 43.0879 11.5848ZM51.2686 11.5705C51.2686 14.5128 48.9435 16.7229 45.8291 16.7229C42.7148 16.7229 40.3896 14.5128 40.3896 11.5705C40.3896 8.64267 42.7148 6.47559 45.8291 6.47559C48.9435 6.47559 51.2686 8.65715 51.2686 11.5705Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.3855 10.1626C58.3855 9.17227 57.7684 8.64127 56.7063 8.64127H55.0415V11.727H56.7063C57.7684 11.727 58.3855 11.1817 58.3855 10.1626ZM60.8828 10.0764C60.8828 12.4446 59.4045 13.8224 56.8356 13.8224H55.0415V16.6067H52.415V6.5459H56.8356C59.4045 6.5459 60.8828 7.8375 60.8828 10.0764Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0822 6.5459V8.64127H64.7719V10.5214H69.5655V12.6024L64.7719 12.6167V14.5113H70.2257V16.6067H62.1455V6.5459H70.0822Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.8872 6.5459V14.3678H79.1497V16.6067H72.2607V6.5459H74.8872Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.3292 13.3918V16.6067H81.7028V13.4635L78 6.5459H80.6263L83.0089 10.8659L85.3338 6.5459H87.9603L84.3292 13.3918Z"
        fill="black"
      />
    </svg>
  )
}
