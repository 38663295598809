import { useMutation } from 'react-query'

import { FilterType } from '@features/filters/types'

import { getMessage } from '@shared/constants'
import { onError } from '@shared/http'
import { api } from '@shared/http/axios'

export interface FilterArgs {
  dashboardFilterId: string
  type: FilterType
  name: string
}
export type BatchFilterArgs = {
  dashboardId: string
  credentialsId: string
  filters: FilterArgs[]
}

export const useTableauBatchUpsertFilters = () => {
  return useMutation((body: BatchFilterArgs) => api.post(`/filters/tableau/batch-upsert`, body), {
    onError: (error: Error) => {
      onError(error, {
        title: getMessage('TABLEAU_FILTERS_UPSERT_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}
