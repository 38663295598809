import { FC, useMemo } from 'react'

import { EditIcon } from '@assets'

import { useGetSpreadsheetData } from '@features/data-sources'
import { useEditVisualization } from '@features/destinations/hooks/use-edit-visualization'
import { DataSourceCellRange } from '@features/syncs/components/sync-data-source/select-cell-range'
import { SyncEmbed } from '@features/syncs/components/sync-embed/sync-embed/sync-embed'

import { ActionModal, Button, Checkbox, PageContainer, Spinner, Tooltip } from '@shared/components'

import styles from './visualization-select-sheets.module.css'

interface VisualizationSelectSheetsProps {
  open: boolean
  syncId: string
  toggle: () => void
  toggled: boolean
}

export const VisualizationSelectSheets: FC<VisualizationSelectSheetsProps> = ({ open, syncId, toggle, toggled }) => {
  const {
    visualization,
    destination,
    slides,
    isLoading,
    cellRanges,
    spreadsheetsData,
    isSavingSync,
    canSaveSync,
    handleSaveSync,
    handleRangeChange,
    handleGridlinesChange,
    handleFreezeRowsChange,
    handlecollapsedRowsChange,
  } = useEditVisualization({ syncId, toggled, toggle })

  const sheetId = useMemo(() => visualization?.spreadsheetsData?.sheetId, [visualization])

  const { data: spreadsheetInfo } = useGetSpreadsheetData(visualization?.spreadsheetsData?.spreadsheetId)

  const namedRanges = useMemo(
    () => spreadsheetInfo?.namedRanges.filter(range => range.range.sheetId.toString() === sheetId),
    [spreadsheetInfo, sheetId]
  )

  if (isLoading) {
    return (
      <PageContainer loading={isLoading}>
        <Spinner localCenter />
      </PageContainer>
    )
  }

  // If destination or slides not found, can be retried after OAuth permissions
  if (!destination || !slides) {
    return
  }
  return (
    <ActionModal
      open={open}
      className={styles.container}
      onBackgroundClick={toggle}
      Icon={EditIcon}
      title="Cell range"
      children={
        <>
          <DataSourceCellRange cellRange={cellRanges} onCellRangeChange={handleRangeChange} namedRanges={namedRanges} />
          <div className={styles.checkOps}>
            <Tooltip title={'Include sheet gridlines'}>
              <div className={styles.sheetCheckboxTooltip} onClick={() => handleGridlinesChange()}>
                <Checkbox checked={spreadsheetsData?.gridlines} />
                Gridlines
              </div>
            </Tooltip>
            <Tooltip title={`Include rows marked as 'Frozen' in the sheet`}>
              <div className={styles.sheetCheckboxTooltip} onClick={() => handleFreezeRowsChange()}>
                <Checkbox checked={spreadsheetsData?.freezeRows} />
                Freeze Rows
              </div>
            </Tooltip>
            <Tooltip title={`Hide all grouped rows`}>
              <div className={styles.sheetCheckboxTooltip} onClick={() => handlecollapsedRowsChange()}>
                <Checkbox checked={spreadsheetsData?.collapsedRows} />
                Collapse Rows
              </div>
            </Tooltip>
          </div>
          <SyncEmbed />
        </>
      }
      btns={
        <>
          <Button secondaryGray text="Cancel" onClick={toggle} fullWidth />
          <Button text="Save" disabled={!canSaveSync} loading={isSavingSync} onClick={handleSaveSync} fullWidth />
        </>
      }
    />
  )
}
