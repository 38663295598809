import { KeyIcon } from '@assets'

import { Tooltip } from '@shared/components'
import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'
import { getMessage } from '@shared/constants'

export const AuthorizeItem = (props: MenuItemProps) => {
  return (
    <MenuItem {...props}>
      <KeyIcon />
      <Tooltip title={getMessage('DESTINATION_NOT_FOUND_OR_DELETED')}>{'Authorize'}</Tooltip>
    </MenuItem>
  )
}
