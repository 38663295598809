import { ReactNode, createContext, useContext } from 'react'

import { useSaml } from '@features/users'
import { useAuth0 } from '@features/users'

interface IAuthContextProps {
  unifiedSignOut: () => void
}

const UnifiedAuthContext = createContext<IAuthContextProps>({
  unifiedSignOut: () => {},
})

const UnifiedAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const samlAuth = useSaml()
  const auth0Auth = useAuth0()

  const unifiedSignOut = () => {
    samlAuth.signOut(() => {})
    auth0Auth.handleLogout()
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  return (
    <UnifiedAuthContext.Provider value={{ ...samlAuth, ...auth0Auth, unifiedSignOut }}>
      {children}
    </UnifiedAuthContext.Provider>
  )
}

const useUnifiedAuth = () => useContext(UnifiedAuthContext)

export { UnifiedAuthProvider, useUnifiedAuth }
