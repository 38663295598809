import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import CreatableSelect from 'react-select/creatable'

import { INamedRangeInfo } from '@features/data-sources/types'

import styles from './select-data-source.module.css'

interface ISelectCellRange {
  cellRange?: string[] | null
  disabled?: boolean
  onCellRangeChange: (cellRange: string[] | null) => void
  namedRanges?: INamedRangeInfo[]
  multiSelectEnabled?: boolean
}

const patternFull = /^[A-Z]+[1-9][0-9]*:[A-Z]+[1-9][0-9]*$/i

export const DataSourceCellRange: FC<ISelectCellRange> = ({
  disabled,
  cellRange,
  namedRanges,
  multiSelectEnabled,
  onCellRangeChange,
}) => {
  const [currentValue, setCurrentValue] = useState(cellRange ?? [])

  const namedRangeTitles = useMemo(
    () => namedRanges?.map(range => ({ label: range.name, value: range.name })),
    [namedRanges]
  )

  useEffect(() => {
    if (cellRange !== currentValue) {
      setCurrentValue(cellRange ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellRange])

  const handleChange = (newValue: any) => {
    const values = newValue ? (multiSelectEnabled ? newValue.map((option: any) => option.value) : [newValue.value]) : []
    setCurrentValue(values)
    onCellRangeChange(values)
  }

  const isValidOption = (value: string) => isNamedRange(value) || patternFull.test(value)

  const isNamedRange = useCallback(
    (value: string) => {
      return namedRangeTitles?.some(range => range.value === value)
    },
    [namedRangeTitles]
  )

  return (
    <div className={styles.container}>
      <span className="text-sm-regular mb-2">Type a cell range or select an existing named range</span>
      <CreatableSelect
        isClearable
        isMulti={multiSelectEnabled}
        isDisabled={disabled}
        value={
          multiSelectEnabled
            ? currentValue.map(value => ({ label: value, value }))
            : currentValue.length > 0
              ? { label: currentValue[0], value: currentValue[0] }
              : null
        }
        onChange={handleChange}
        options={namedRangeTitles}
        placeholder="Select or create a cell range"
        formatCreateLabel={(inputValue: string) =>
          isValidOption(inputValue) ? `Create "${inputValue}"` : 'Invalid cell range'
        }
        isValidNewOption={(inputValue: string) => isValidOption(inputValue)}
        noOptionsMessage={() => 'Type to create a new cell range'}
      />
    </div>
  )
}
