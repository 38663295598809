import { useState } from 'react'

import { useUpdateDestination } from '@features/destinations/api/api'
import { Destination, IDriveData, LinkOption, UpdateMethod } from '@features/destinations/types'
import { CropOption } from '@features/syncs/types'

import { settingsSavedToast } from '@shared/components'

import { useDestinationSchedule as useScheduleDestination } from './use-destination-schedule'

export enum SuffixGroups {
  DATE = 'DATE',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum SuffixOptions {
  DD_MM_YYYY = 'DD/MM/YYYY',
  MM_DD_YYYY = 'MM/DD/YYYY',
  MMMM_YYYY = 'MMMM YYYY',
  MM_YYYY = 'MM/YYYY',
  Q = '[Q]Q',
  Q_YYYY = '[Q]Q YYYY',
  YYYY = 'YYYY',
}

const mapSuffixOptionsToGroup = {
  [SuffixOptions.DD_MM_YYYY]: SuffixGroups.DATE,
  [SuffixOptions.MM_DD_YYYY]: SuffixGroups.DATE,
  [SuffixOptions.MMMM_YYYY]: SuffixGroups.MONTH,
  [SuffixOptions.MM_YYYY]: SuffixGroups.MONTH,
  [SuffixOptions.Q]: SuffixGroups.QUARTER,
  [SuffixOptions.Q_YYYY]: SuffixGroups.QUARTER,
  [SuffixOptions.YYYY]: SuffixGroups.YEAR,
}

export const mapSuffixOptionsToExample = (delimiter: string = '/') => ({
  [SuffixOptions.DD_MM_YYYY]: `31${delimiter}01${delimiter}2030`,
  [SuffixOptions.MM_DD_YYYY]: `01${delimiter}31${delimiter}2030`,
  [SuffixOptions.MMMM_YYYY]: 'December 2030',
  [SuffixOptions.MM_YYYY]: `12${delimiter}2030`,
  [SuffixOptions.Q]: 'Q1',
  [SuffixOptions.Q_YYYY]: 'Q1 2030',
  [SuffixOptions.YYYY]: '2030',
})

interface IUseDestinationConfiguration {
  destination: Destination
  driveData?: IDriveData
  itemUpdatedAt: string
}

export const useDestinationConfiguration = ({
  destination,
  driveData,
  itemUpdatedAt,
}: IUseDestinationConfiguration) => {
  const updateDestinationQuery = useUpdateDestination(destination)
  const useScheduleProps = useScheduleDestination(destination)
  const [suffixGroup, setSuffixGroup] = useState(
    mapSuffixOptionsToGroup[destination.datestampSuffix as SuffixOptions] ?? SuffixGroups.DATE
  )
  const [suffix, setSuffix] = useState(destination.datestampSuffix)
  const [updateMethod, setUpdateMethod] = useState(destination.updateMethod)
  const [selectedParentFolder, setSelectedParentFolder] = useState<{
    driveId?: string
    folderId: string
    folderName: string
  } | null>(driveData || null)
  const [linkOption, setLinkOption] = useState<LinkOption>(destination.linkOption)
  const [cropOption, setCropOption] = useState<CropOption>(destination.cropOption)
  const handleArchiveUpdateSelection = () => {
    setUpdateMethod(UpdateMethod.Archive)
  }

  const handleSelectSuffixGroup = (group: SuffixGroups) => {
    setSuffixGroup(group)
    switch (group) {
      case SuffixGroups.DATE:
        setSuffix(SuffixOptions.MM_DD_YYYY)
        break
      case SuffixGroups.MONTH:
        setSuffix(SuffixOptions.MMMM_YYYY)
        break
      case SuffixGroups.QUARTER:
        setSuffix(SuffixOptions.Q)
        break
      case SuffixGroups.YEAR:
        setSuffix(SuffixOptions.YYYY)
        break
    }
  }

  const handleSelectSuffix = (suffix: SuffixOptions) => {
    setSuffix(suffix)
  }

  const updateConfiguration = async ({ showToast = false }: { showToast?: boolean } = {}) => {
    const updatedDestination = {
      id: destination.id,
      datestampSuffix: suffix,
      updateMethod,
      updatedAt: itemUpdatedAt,
      parentFolderId: selectedParentFolder?.folderId,
      driveId: selectedParentFolder?.driveId,
      linkOption,
      cropOption,
      updateSchedules: useScheduleProps.getScheduleProps(),
    }
    await updateDestinationQuery.mutateAsync(updatedDestination)
    if (showToast) {
      settingsSavedToast()
    }
  }

  return {
    ...useScheduleProps,
    suffixGroup,
    suffix,
    updateMethod,
    linkOption,
    cropOption,
    isSubmitting: updateDestinationQuery.isLoading,
    setUpdateMethod,
    selectedParentFolder,
    setSelectedParentFolder,
    setLinkOption,
    setCropOption,
    updateConfiguration,
    handleArchiveUpdateSelection,
    handleSelectSuffixGroup,
    handleSelectSuffix,
  }
}
