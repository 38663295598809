import { FC, useMemo } from 'react'

interface IArrow {
  orientation?: 'top' | 'right' | 'bottom' | 'left'
  size?: number
}
const defaultSize = 16

export const Arrow: FC<IArrow> = ({ orientation = 'top', size = defaultSize }) => {
  const rotation = useMemo(() => {
    switch (orientation) {
      case 'top':
        return '180deg'
      case 'right':
        return '270deg'
      case 'bottom':
        return '0deg'
      case 'left':
        return '90deg'
    }
  }, [orientation])

  return (
    <svg width={size} height={size} fill="none" style={{ transform: `rotate(${rotation})` }}>
      <path
        d="M8.00016 3.33203V12.6654M8.00016 12.6654L12.6668 7.9987M8.00016 12.6654L3.3335 7.9987"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
