import { FC, useState } from 'react'

import { RenameIcon } from '@assets'

import { EDestinationType } from '@features/destinations/types'

import { ActionModal, Button, Input } from '@shared/components'

interface IRenameCollection {
  type: EDestinationType
  visible: boolean
  originalName: string
  processing: boolean
  onRename: (name: string) => void
  toggleVisibility: () => void
}

export const RenameCollection: FC<IRenameCollection> = ({
  type,
  visible,
  originalName,
  onRename,
  toggleVisibility,
  processing,
}) => {
  const [name, setName] = useState(originalName)

  return (
    <ActionModal
      open={visible}
      processing={processing}
      onBackgroundClick={toggleVisibility}
      Icon={RenameIcon}
      title={`Rename collection`}
      btns={
        <>
          <Button fullWidth secondaryGray text="Cancel" onClick={toggleVisibility} disabled={processing} />
          <Button fullWidth disabled={!name.trim()} text="Rename" onClick={() => onRename(name)} loading={processing} />
        </>
      }
    >
      <Input autoFocus value={name} label={`New collection name`} onChange={e => setName(e.target.value)} />
    </ActionModal>
  )
}
