import auth0 from 'auth0-js'
import isEmpty from 'lodash/isEmpty'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useAddUser, useLogoutUser } from '@features/users/api/api'
import { useAuthStore } from '@features/users/auth-store'

import { config } from '@shared/config'

type Props = { children: React.ReactNode }

interface Auth0ContextProps {
  user: auth0.Auth0UserProfile | null
  isLoading: boolean
  isAuthenticated: boolean
  existingUserError: boolean
  loginPrompt: 'login' | 'none'
  loginWithGoogle: (email?: string) => void
  loginWithMicrosoft: (email?: string) => void
  handleLogout: () => void
  setExistingError: (value: boolean) => void
}

const Auth0Context = createContext<Auth0ContextProps>({
  user: null,
  isLoading: true,
  isAuthenticated: false,
  existingUserError: false,
  loginPrompt: 'none',
  loginWithGoogle: () => {},
  loginWithMicrosoft: () => {},
  handleLogout: () => {},
  setExistingError: () => {},
})

export function Auth0Provider({ children }: Props) {
  const [authState, setAuthState] = useState({
    user: null as auth0.Auth0UserProfile | null,
    isLoading: true,
    isAuthenticated: false,
    existingUserError: false,
    loginPrompt: 'none' as 'login' | 'none',
  })

  const { user } = useAuthStore()

  const addUserQuery = useAddUser()
  const logoutUserQuery = useLogoutUser()

  const webAuth = useMemo(
    () =>
      new auth0.WebAuth({
        domain: config.auth0.domain,
        clientID: config.auth0.clientId,
        redirectUri: config.google.redirectUri,
        responseType: 'token id_token',
      }),
    []
  )

  useEffect(() => {
    if (!authState.isLoading) return
    if (user) {
      setAuthState(prev => ({ ...prev, isLoading: false }))
      return
    }
    setTimeout(() => {
      setAuthState(prev => ({ ...prev, isLoading: false }))
    }, config.auth0.loadingTimeout)
  }, [authState.isLoading, user])

  const handleAuthResult = useCallback(
    (authResult: auth0.Auth0DecodedHash) => {
      if (authResult && authResult.accessToken) {
        webAuth.client.userInfo(authResult.accessToken, async (error, userProfile) => {
          if (isEmpty(userProfile)) {
            return
          }

          if (error) {
            console.error('Error fetching user profile:', error)
            setAuthState(prev => ({ ...prev, isLoading: false, loginPrompt: 'login' }))
            return
          }

          try {
            await addUserQuery.mutateAsync({
              email: userProfile.email,
              auth0: {
                id: userProfile.sub,
              },
            })
          } catch (error) {
            setAuthState(prev => ({ ...prev, isLoading: false, existingUserError: true }))
            return
          }
          setAuthState({
            user: userProfile,
            isAuthenticated: true,
            isLoading: false,
            existingUserError: false,
            loginPrompt: 'none',
          })
        })
      } else {
        setAuthState(prev => ({ ...prev, isLoading: false }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [webAuth]
  )

  const initiateGoogleLogin = useCallback(
    (email?: string) => {
      setAuthState(prev => ({ ...prev, isLoading: true }))
      webAuth.authorize({
        connection: 'google-oauth2',
        login_hint: email,
        redirectUri: config.google.redirectUri,
        prompt: authState.loginPrompt,
        state: '',
      })
    },
    [webAuth, authState.loginPrompt]
  )

  const initiateMicrosoftLogin = useCallback(
    (email?: string) => {
      webAuth.authorize({
        connection: config.microsoft.auth0ConnectionName,
        login_hint: email,
        redirectUri: config.microsoft.loginRedirectUri,
        prompt: authState.loginPrompt,
        state: '',
      })
    },
    [webAuth, authState.loginPrompt]
  )

  const handleLogout = useCallback(async () => {
    await logoutUserQuery.mutateAsync()
    webAuth.logout({ returnTo: config.google.redirectUri })
    setAuthState({
      user: null,
      isAuthenticated: false,
      isLoading: false,
      existingUserError: false,
      loginPrompt: 'none',
    })
  }, [logoutUserQuery, webAuth])

  useEffect(() => {
    const handleResponse: auth0.Auth0Callback<
      auth0.Auth0DecodedHash | any,
      auth0.Auth0Error | auth0.Auth0ParseHashError
    > = (error, authResult) => {
      if (error) {
        const update: Partial<typeof authState> = { isLoading: false }

        if (error.error === 'login_required') {
          update.loginPrompt = 'login'
        }
        setAuthState(prev => ({ ...prev, ...update }))
        return
      }

      handleAuthResult(authResult as auth0.Auth0DecodedHash)
    }

    if (window.location.hash) {
      webAuth.parseHash({ hash: window.location.hash }, handleResponse)
      window.history.replaceState({}, document.title, window.location.pathname + window.location.search)
    } else {
      webAuth.checkSession({}, handleResponse)
    }
  }, [webAuth, handleAuthResult])

  const contextValue = {
    ...authState,
    isLoading: authState.isLoading || addUserQuery.isLoading,
    loginWithGoogle: initiateGoogleLogin,
    loginWithMicrosoft: initiateMicrosoftLogin,
    handleLogout,
    setExistingError: (value: boolean) => setAuthState(prev => ({ ...prev, existingUserError: value })),
  }

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>
}

export const useAuth0 = () => useContext(Auth0Context)
