import { defaultPreviewHeight, defaultPreviewWidth } from '.'

import { MsWordIcon } from '@assets'

import { ViewIdOptions } from '@features/drive/google-drive/types'
import { MSWord } from '@features/embedding'

import { AbstractDestinationHandler } from './destination-handler'

const defaultIconSize = 14

export class MicrosoftWordHandler extends AbstractDestinationHandler {
  override getTitle() {
    return 'document'
  }

  override getTitlePlural() {
    return 'documents'
  }

  override getCloudAppTitle(): string {
    return 'Word'
  }

  override getCloudTitle(): string {
    return 'OneDrive'
  }

  override getShortNamePlural() {
    return 'word'
  }

  override getGPickerViewId(): ViewIdOptions {
    return 'DOCS'
  }

  override getDestinationTitle(isFileDeleted = false) {
    return isFileDeleted ? 'File Not Found In OneDrive' : 'Open destination'
  }

  override getDestinationUrl() {
    return this.presentation?.docUrl ?? ''
  }

  override getGroupData() {
    return {
      group: '',
      groupIndex: 1,
    }
  }

  override getGPickerCustomViews() {
    return [google.picker.ViewId.DOCUMENTS]
  }

  override getSmallIcon(iconSize = defaultIconSize, className?: string) {
    return <MsWordIcon size={iconSize} className={className} />
  }

  override getPreview(
    itemsInPageCount?: number,
    width = defaultPreviewWidth,
    height = defaultPreviewHeight,
    className?: string
  ) {
    return this.presentation ? <MSWord destinationId={this.presentation.id} height={height} width={width} /> : null
  }

  override getAddVizStep1() {
    return { title: 'Destination placement', subtitle: 'The image will be placed at the bottom of your document.' }
  }

  override getDateDelimiter() {
    return '-'
  }
}
