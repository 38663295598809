import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { buildDestinationPath } from '@pages/routes/paths'

import { useGetHistoricalRunById } from '@features/historical-run/api'
import { StatusLabel } from '@features/historical-run/components/status-label/status-label'
import { mapTriggerToLabel } from '@features/historical-run/utils'

import { Button, PageContainer } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { formatDate } from '@shared/utils'

import { historicalRunDetailsColumns, historicalRunDetailsTableData } from './historical-run-details-table'

import styles from './historical-run-details.module.css'

export const HistoricalRunDetails: FC = () => {
  const { historicalRunId } = useParams()
  const { isLoading, data: historicalRunDetails } = useGetHistoricalRunById(historicalRunId)

  const navigate = useNavigate()

  if (!historicalRunDetails) {
    return null
  }

  const tableData = historicalRunDetailsTableData(historicalRunDetails.historicalRunSyncs)

  return (
    <PageContainer title="Update run details" loading={isLoading}>
      <section className={styles.topRowWrapper}>
        <p className={styles.topRowItem}>
          <span className={styles.topRowLabelItem}>Status: </span>
          <StatusLabel status={historicalRunDetails.status} />
        </p>
        <p className={styles.topRowItem}>
          <span className={styles.topRowLabelItem}>Destination: </span>
          <Button
            text={historicalRunDetails.destinationName!}
            onClick={() => navigate(buildDestinationPath(historicalRunDetails.destinationId!))}
            linkColor
          />
        </p>
        <p className={styles.topRowItem}>
          <span className={styles.topRowLabelItem}>Start time: </span>
          <span className={styles.topRowValueItem}>{formatDate(historicalRunDetails.startedAt)}</span>
        </p>
        <p className={styles.topRowItem}>
          <span className={styles.topRowLabelItem}>Trigger: </span>
          <span className={styles.topRowValueItem}>{mapTriggerToLabel(historicalRunDetails.trigger)}</span>
        </p>
      </section>
      <section>
        <DataGrid data={tableData} columns={historicalRunDetailsColumns} EmptyState="No visualizations updated" />
      </section>
    </PageContainer>
  )
}
