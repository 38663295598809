import * as Sentry from '@sentry/react'
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios'

import { useOrganizationsStore } from '@features/organizations'
import { useAuthStore } from '@features/users/auth-store'

import { config } from '@shared/config'
import { parseDuration } from '@shared/utils'

import { formatApiErrorFromAxios } from './error-handler'

type FailedQueueItem = {
  resolve: (value?: string | PromiseLike<string>) => void
  reject: (reason?: any) => void
}

let failedQueue: Array<FailedQueueItem> = []

let axiosInstance: AxiosInstance
let isRefreshingToken = false

const MAX_RETRIES = 3
export const sleep = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds))

const processQueue = (error: Error | null, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else if (token !== null) {
      prom.resolve(token)
    } else {
      prom.reject(new Error('Token is null'))
    }
  })
  failedQueue = []
}

const createAxiosInstance = (): AxiosInstance => {
  const { refreshToken } = useAuthStore.getState()

  axiosInstance = axios.create({
    baseURL: config.apiHost,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'ngrok-skip-browser-warning': Math.random().toFixed(0),
    },
  })

  axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { user } = useAuthStore.getState()
    const { activeOrganization } = useOrganizationsStore.getState()

    if (!user || !user.tokens) return config

    let accessToken = user.tokens.access

    if (!activeOrganization) {
      console.warn('No active organization')
    }

    if (config.headers) {
      config.headers.Authorization = `Bearer ${accessToken.token}`
      config.headers['x-rollstack-organization'] = activeOrganization?.organization.id
      config.headers['x-rollstack-user-id'] = user.id
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    response => response,
    async (error: AxiosError<{ code: number; message: string; metadata?: { code: string } }>) => {
      const { logoutUser, setIsLoggingOut } = useAuthStore.getState()
      const { setActiveOrganization, setOrganizations } = useOrganizationsStore.getState()

      const handleLogout = () => {
        logoutUser()
        setActiveOrganization(undefined)
        setOrganizations([])
        setTimeout(() => {
          setIsLoggingOut(false)
        }, parseDuration('2s'))
      }

      Sentry.captureException(error)

      if (error && error.config && error.config.url === '/datasources/auth') {
        return
      }

      const originalRequest = error.config as InternalAxiosRequestConfig & {
        _retry?: boolean
        _retryCount?: number
      }
      if (!originalRequest) {
        return Promise.reject(error)
      }

      // Add retry logic for network errors
      if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        originalRequest._retryCount = (originalRequest._retryCount || 0) + 1
        if (originalRequest._retryCount <= MAX_RETRIES) {
          console.log(`Retrying request (${originalRequest._retryCount}/3)`)
          return new Promise(resolve => setTimeout(resolve, parseDuration('1s'))).then(() =>
            axiosInstance(originalRequest)
          )
        }
      }

      if (!originalRequest) {
        return Promise.reject(error)
      }

      originalRequest._retry = true
      originalRequest._retryCount = 0

      const apiError = formatApiErrorFromAxios(error)

      const code = apiError.metadata?.code

      if (code === 'missing-or-invalid-token') {
        handleLogout()
        return Promise.reject(error)
      }
      if (code === 'token-expired') {
        if (isRefreshingToken) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject })
          }).then(token => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`
            return axiosInstance(originalRequest)
          })
        }

        isRefreshingToken = true
        try {
          const newToken = await refreshToken()
          const { access } = newToken
          const { updateUser } = useAuthStore.getState()
          updateUser({ tokens: newToken })

          processQueue(null, access.token)

          originalRequest.headers['Authorization'] = `Bearer ${access.token}`
          return await axiosInstance(originalRequest)
        } catch (error) {
          const err = error as Error
          processQueue(err, null)
          console.error('Failed to refresh token', err)
          handleLogout()
          return Promise.reject(err)
        } finally {
          isRefreshingToken = false
        }
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

const getAxios = (): AxiosInstance => {
  if (!axiosInstance) {
    axiosInstance = createAxiosInstance()
  }
  return axiosInstance
}

export const api = getAxios()
