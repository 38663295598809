import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC } from 'react'

import { SmallSpinner, UpdateIcon } from '@assets'

import { WithCollectionUpdateProps, useCollection } from '@features/collections'
import { withCollectionUpdate } from '@features/collections/hocs'
import { ICollection } from '@features/collections/types'

import { Button } from '@shared/components'

import { CollectionMenuButton } from './collection-menu-button'

dayjs.extend(localizedFormat)

type ICollectionActionButtons = {
  collection: ICollection
  isGrid: boolean
} & WithCollectionUpdateProps

const RawCollectionActionButtons: FC<ICollectionActionButtons> = ({ collection, isGrid, updateAll }) => {
  const { cancelIsLoading, isUpdateRunning, onCancelCollectionUpdate, onUpdate } = useCollection({ updateAll })

  const onButtonClick = (collection: ICollection) => {
    if (isUpdateRunning) {
      onCancelCollectionUpdate(collection)
    } else {
      onUpdate(collection)
    }
  }

  return (
    <div className="flex flex-row gap-2.5">
      {isUpdateRunning || cancelIsLoading ? (
        <Button
          secondaryColor={isGrid}
          secondaryGray={!isGrid}
          fullWidth={isGrid}
          text="Cancel"
          iconLeading={<SmallSpinner />}
          loading={cancelIsLoading}
          onClick={() => onButtonClick(collection)}
          stopPropagation
        />
      ) : (
        <Button
          iconLeading={<UpdateIcon />}
          onClick={() => onButtonClick(collection)}
          secondaryColor={isGrid}
          secondaryGray={!isGrid}
          fullWidth={isGrid}
          text="Update all"
          stopPropagation
        />
      )}
      <CollectionMenuButton collection={collection} />
    </div>
  )
}
export const CollectionActionButtons = withCollectionUpdate(RawCollectionActionButtons)
