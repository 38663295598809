import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider as MicrosoftProvider } from '@azure/msal-react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { PostHogProvider } from 'posthog-js/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'

import { App } from '@pages/app'
import { AppGate } from '@pages/app-gate'

import { CollaborationProvider } from '@features/collaboration/collaboration-provider'
import { DataSourcesProvider } from '@features/data-sources'
import { DestinationsProvider } from '@features/destinations'
import { ActiveDestinationProvider } from '@features/destinations/active-destination-provider'
import { SlidesThumbnailsListener } from '@features/destinations/slides-thumbnails-listener'
import { FreeTrialProvider } from '@features/free-trial/free-trial-provider'
import { DestinationsStatusesProvider } from '@features/notch/destinations-status-provider'
import { OrganizationsProvider } from '@features/organizations'
import { OrganizationInviteProvider } from '@features/organizations'
import { OAuthProvider } from '@features/users'
import { SamlProvider } from '@features/users'
import { Auth0Provider } from '@features/users'
import { UnifiedAuthProvider } from '@features/users'
import { msalConfig } from '@features/users/hooks/use-microsoft-login'
import { FilePickerProvider } from '@features/users/providers/file-picker-provider'

import { TooltipProvider } from '@shared/components/tooltip/tooltip-provider'
import { TooltipProvider as ShadcnTooltipProvider } from '@shared/components/ui/tooltip'
import { config } from '@shared/config'
import { queryClient } from '@shared/http/query-client'
import { SocketProvider } from '@shared/http/socket-provider'
import { posthog } from '@shared/product-tooling/posthog/posthog'

import './@shared/product-tooling/sentry'
import reportWebVitals from './reportWebVitals'

import './global.css'
import './index.css'
import 'destyle.css'
import 'react-image-crop/dist/ReactCrop.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'rollstack-crop.css'

const msalInstance = new PublicClientApplication(msalConfig)
const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <ToastContainer style={{ width: 400, transform: 'translate(20px, -20px)' }} position="bottom-left" />
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={config.google.clientId}>
        <SamlProvider>
          <Auth0Provider>
            <UnifiedAuthProvider>
              <MicrosoftProvider instance={msalInstance}>
                <SocketProvider>
                  <PostHogProvider client={posthog}>
                    <AppGate>
                      <OrganizationsProvider>
                        <FreeTrialProvider>
                          <DataSourcesProvider>
                            <DestinationsProvider>
                              <DestinationsStatusesProvider>
                                <CollaborationProvider>
                                  <SlidesThumbnailsListener>
                                    <OrganizationInviteProvider>
                                      <TooltipProvider>
                                        <ShadcnTooltipProvider>
                                          <OAuthProvider>
                                            <ActiveDestinationProvider>
                                              <FilePickerProvider>
                                                <App />
                                              </FilePickerProvider>
                                            </ActiveDestinationProvider>
                                          </OAuthProvider>
                                        </ShadcnTooltipProvider>
                                      </TooltipProvider>
                                    </OrganizationInviteProvider>
                                  </SlidesThumbnailsListener>
                                </CollaborationProvider>
                              </DestinationsStatusesProvider>
                            </DestinationsProvider>
                          </DataSourcesProvider>
                        </FreeTrialProvider>
                      </OrganizationsProvider>
                    </AppGate>
                  </PostHogProvider>
                </SocketProvider>
              </MicrosoftProvider>
            </UnifiedAuthProvider>
          </Auth0Provider>
        </SamlProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
