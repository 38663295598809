import { ColumnDef } from '@tanstack/react-table'
import { Info } from 'lucide-react'

import {
  CropIcon,
  DataSourceIcon,
  DuplicateIcon,
  EditIcon,
  ExternalLinkIcon,
  KeyIcon,
  MoveToIcon,
  TrashIcon,
  UpdateIcon,
} from '@assets'
import { MenuHorizontalIcon } from '@assets/icons/menu'
import { SwitchHorizontal } from '@assets/icons/switch-horizontal'

import { EDataSourceType } from '@features/data-sources/types/types'
import { EDestinationType } from '@features/destinations/types'
import { isDocumentDestination, isPresentationDestination } from '@features/destinations/utils'
import { getFiltersViewFromString } from '@features/filters/utils'
import { StatusLabel } from '@features/historical-run/components/status-label/status-label'
import { parseSyncProperties } from '@features/syncs'
import { ISync, SyncCapabilities, SyncStatus, SyncUpdateType } from '@features/syncs/types'
import { User } from '@features/users'

import { Checkbox, ImageWithFallback, MenuItem, NewTabLink, Tooltip } from '@shared/components'
import { Button } from '@shared/components/button'
import { TextPreview } from '@shared/components/text-preview/text-preview'
import { Button as ShadcnButton } from '@shared/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@shared/components/ui/dropdown-menu'
import { getSynCapabilitiesTooltipMessage } from '@shared/constants'
import { formatDate, toTitleCase } from '@shared/utils'

export enum EDestinationVizColumn {
  SELECT = 'select',
  FILTERS = 'vizAppliedFilters',
  IMAGE_UPDATED_AT = 'imageUpdatedAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  DASHBOARD_NAME = 'dashboardName',
  NAME = 'name',
  TYPE = 'type',
  IMAGE_URL = 'imageUrl',
  OWNER = 'owner',
}

export interface RowData {
  id: string
  sectionHeader?: string
  imageUrl?: string
  imageUpdatedAt?: string
  name?: string
  type?: string
  dashboardName?: string
  dashboardUrl?: string
  capabilities?: SyncCapabilities
  vizAppliedFilters?: { [x: string]: any } | {}
  status?: SyncStatus
  statusMessage?: string
  text?: string | null
  updateType?: SyncUpdateType
  user?: {
    id: number
    name: string | null
    email: string
  }
}

export const vizsTableData = (syncs: ISync[], destinationType: EDestinationType) => {
  const formattedData: RowData[] = []
  let previousSlideIndex: number | null = null
  const uniqueSlideIndices = isPresentationDestination({ type: destinationType })
    ? new Set<number>(syncs.map(s => s.slideIndex))
    : new Set<number>([])
  const multipleSections = uniqueSlideIndices.size > 1

  syncs.forEach(sync => {
    if (sync.slideIndex !== previousSlideIndex && multipleSections) {
      formattedData.push({
        id: `header-${sync.id}-${sync.slideIndex}`,
        sectionHeader: `Slide ${sync.slideIndex}`,
      })
    }
    previousSlideIndex = sync.slideIndex

    formattedData.push({
      id: sync.id,
      imageUrl: sync.imageUrl,
      name: sync.name,
      type: sync.type,
      dashboardName: parseSyncProperties(sync).dashboard || sync.spreadsheetsData?.spreadsheet?.name,
      dashboardUrl: parseSyncProperties(sync).dashboardUrl,
      capabilities: sync.capabilities,
      vizAppliedFilters: parseSyncProperties(sync).filters,
      imageUpdatedAt: sync.imageUpdatedAt,
      status: sync.status,
      statusMessage: sync.statusMessage,
      text: sync.text,
      updateType: sync.updateType,
      user: sync.user,
    })
  })
  return formattedData
}

interface vizsTableColumnsProps {
  user?: User
  destinationType: EDestinationType
  handleModifySync: (syncId: string, type?: string, slideId?: string) => void
  openSyncCrop: (syncId: string) => void
  openSyncLocation: (syncId: string) => void
  handleUpdateSync: (syncId: string) => Promise<void>
  handleDuplicateSync: (syncId: string) => Promise<string[]>
  handleDeleteSync: (syncId: string) => void
  handleTransferSync: (syncId: string) => void
  slideId?: string
  hideCheckboxes: boolean
  authorizeSheetsFile: (id: string) => void
}

export const vizsTableColumns = ({
  user,
  destinationType,
  handleModifySync,
  openSyncCrop,
  openSyncLocation,
  handleUpdateSync,
  handleDuplicateSync,
  handleDeleteSync,
  handleTransferSync,
  slideId,
  hideCheckboxes,
  authorizeSheetsFile,
}: vizsTableColumnsProps) => {
  const vizsTableColumns: ColumnDef<RowData>[] = [
    {
      id: EDestinationVizColumn.SELECT,
      header: ({ table }) =>
        hideCheckboxes ? null : (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
            onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
      cell: ({ row }) =>
        hideCheckboxes ? null : (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={value => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
      size: 40,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: EDestinationVizColumn.IMAGE_URL,
      accessorKey: 'imageUrl',
      header: 'Preview',
      cell: ({ row }) => {
        const isTextMode = row.original.updateType === SyncUpdateType.text
        if (isTextMode) {
          return <TextPreview text={row.original.text} />
        }
        return (
          <ImageWithFallback
            key={row.id}
            src={row.getValue('imageUrl')}
            imageSize={{ width: 80, height: 40 }}
            placeholderSize={{ width: 80, height: 40 }}
            alt="Visualization"
            dataTestId={row.original.name}
          />
        )
      },
      size: 100,
    },
    {
      id: EDestinationVizColumn.NAME,
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <div key={row.getPinnedIndex()} className="flex flex-col gap-1.5">
          <div className="font-semibold">{row.getValue('name')}</div>
        </div>
      ),
      enableHiding: false,
    },
    {
      id: EDestinationVizColumn.TYPE,
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => (
        <div key={row.getPinnedIndex()} className="flex flex-col gap-1.5">
          <DataSourceIcon type={row.getValue('type') as EDataSourceType} />
        </div>
      ),
      enableHiding: true,
    },
    {
      id: EDestinationVizColumn.DASHBOARD_NAME,
      accessorKey: 'dashboardName',
      header: 'Dashboard',
      cell: ({ row }) => (
        <div className="flex flex-col gap-1.5">
          <div className="pt-0.5">{row.getValue('dashboardName')}</div>
        </div>
      ),
    },
    {
      id: EDestinationVizColumn.FILTERS,
      header: 'Filters',
      cell: ({ row }) => {
        const filters = row.original.vizAppliedFilters
        const filtersView = getFiltersViewFromString(filters)
        return <div className="flex flex-col gap-1.5">{filtersView}</div>
      },
    },
    {
      id: EDestinationVizColumn.STATUS,
      header: 'Last Status',
      cell: ({ row }) => {
        const { status, statusMessage } = row.original
        return (
          <div className="flex flex-col gap-1.5">
            <div className="flex gap-2 pt-0.5">
              {status && <StatusLabel status={status} />}

              {statusMessage && (
                <Tooltip title={toTitleCase(statusMessage)} position="top">
                  <Info size={20} />
                </Tooltip>
              )}
            </div>
          </div>
        )
      },
    },
    {
      id: EDestinationVizColumn.IMAGE_UPDATED_AT,
      header: 'Last Updated At',
      cell: ({ row }) => (
        <div className="flex flex-col gap-1.5">
          <div className="pt-0.5">
            {row.original.imageUpdatedAt ? formatDate(new Date(row.original.imageUpdatedAt)) : ''}
          </div>
        </div>
      ),
    },
    {
      id: EDestinationVizColumn.OWNER,
      header: 'Owner',
      cell: ({ row }) => (
        <div className="flex flex-col gap-1.5">
          <div className="pt-0.5">{row.original.user ? row.original.user.name || row.original.user.email : ''}</div>
        </div>
      ),
    },
    {
      id: EDestinationVizColumn.ACTIONS,
      header: 'Actions',
      size: 250,
      cell: ({ row }) => {
        const sync = row.original
        const syncCapabilities = sync.capabilities
        const userNeedsToAuthorizeGoogleSheet =
          sync.type === EDataSourceType.GoogleSheets &&
          !syncCapabilities?.editViz.value &&
          syncCapabilities?.editViz.code === 'missing-credentials' &&
          user?.id !== sync.user?.id
        return (
          <div className="flex items-center gap-2.5">
            {userNeedsToAuthorizeGoogleSheet ? (
              <Tooltip title={'Authorize Google Sheets file'} position="top">
                <Button
                  secondaryGray
                  iconLeading={<KeyIcon />}
                  onClick={() => authorizeSheetsFile(sync.id)}
                />
              </Tooltip>
            ) : (
              <Tooltip title={getSynCapabilitiesTooltipMessage('Edit', syncCapabilities?.editViz.code)} position="top">
                <Button
                  secondaryGray
                  iconLeading={<EditIcon />}
                  disabled={!syncCapabilities?.editViz.value}
                  onClick={() => handleModifySync(row.original.id, row.original.type, slideId)}
                />
              </Tooltip>
            )}

            <Tooltip title={getSynCapabilitiesTooltipMessage('Crop', syncCapabilities?.crop.code)} position="top">
              <Button
                secondaryGray
                iconLeading={<CropIcon />}
                disabled={!syncCapabilities?.crop.value}
                onClick={() => openSyncCrop(row.original.id)}
              />
            </Tooltip>

            {!isDocumentDestination({ type: destinationType }) && (
              <Tooltip
                title={getSynCapabilitiesTooltipMessage('Move to', syncCapabilities?.moveToSlide.code)}
                position="top"
              >
                <Button
                  secondaryGray
                  iconLeading={<MoveToIcon />}
                  disabled={!syncCapabilities?.moveToSlide.value}
                  onClick={() => openSyncLocation(row.original.id)}
                />
              </Tooltip>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ShadcnButton size="sm" variant="outline">
                  {<MenuHorizontalIcon />}
                </ShadcnButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <Tooltip
                  show={!syncCapabilities?.update.value}
                  title={getSynCapabilitiesTooltipMessage('Update', syncCapabilities?.update.code)}
                  position="left"
                >
                  <MenuItem
                    onClick={() => handleUpdateSync(row.original.id)}
                    disabled={!syncCapabilities?.update.value}
                  >
                    <UpdateIcon />
                    Update
                  </MenuItem>
                </Tooltip>

                <Tooltip
                  show={!syncCapabilities?.duplicate.value}
                  title={getSynCapabilitiesTooltipMessage('Duplicate', syncCapabilities?.duplicate.code)}
                  position="left"
                >
                  <MenuItem
                    onClick={async () => handleDuplicateSync(row.original.id)}
                    disabled={!syncCapabilities?.duplicate.value}
                  >
                    <DuplicateIcon />
                    Duplicate
                  </MenuItem>
                </Tooltip>
                <MenuItem>
                  <NewTabLink className="flex flex-row gap-2.5" link={row.original.dashboardUrl}>
                    <ExternalLinkIcon />
                    Open {row.original.type === EDataSourceType.GoogleSheets ? 'Spreadsheet' : 'Dashboard'}
                  </NewTabLink>
                </MenuItem>

                <Tooltip
                  show={!syncCapabilities?.transferOwnership.value}
                  title={getSynCapabilitiesTooltipMessage(
                    'Transfer ownership',
                    syncCapabilities?.transferOwnership.code
                  )}
                  position="left"
                >
                  <MenuItem
                    onClick={() => handleTransferSync(row.original.id)}
                    disabled={!syncCapabilities?.transferOwnership.value}
                  >
                    <SwitchHorizontal />
                    Transfer ownership
                  </MenuItem>
                </Tooltip>
                <Tooltip
                  show={!syncCapabilities?.delete.value}
                  title={getSynCapabilitiesTooltipMessage('Delete', syncCapabilities?.delete.code)}
                  position="left"
                >
                  <MenuItem
                    onClick={async () => handleDeleteSync(row.original.id)}
                    disabled={!syncCapabilities?.delete.value}
                  >
                    <TrashIcon />
                    Delete
                  </MenuItem>
                </Tooltip>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]
  return vizsTableColumns
}
