import { useCallback, useState } from 'react'

export const useCarouselSteps = (length: number) => {
  const [step, setStep] = useState(0)

  const goToPrevious = () => {
    const index = step - 1
    setStep(index < 0 ? length - 1 : index)
  }

  const goToNext = () => {
    setStep((step + 1) % length)
  }

  const goToStep = useCallback((index: number) => {
    setStep(index)
  }, [])

  return {
    step,
    maxStep: length - 1,
    goToStep,
    goToPrevious,
    goToNext,
  }
}
