import { isMicrosoftDestination } from '@features/destinations'
import { DestinationEmailConfigSharingPermission, EDestinationType } from '@features/destinations/types'

export const mapPermissionTypeToLabel = (permission?: DestinationEmailConfigSharingPermission | null): string => {
  switch (permission) {
    case DestinationEmailConfigSharingPermission.commenter:
      return 'Commenter'
    case DestinationEmailConfigSharingPermission.editor:
      return 'Editor'
    case DestinationEmailConfigSharingPermission.viewer:
      return 'Viewer'
    default:
      return 'None'
  }
}

export const createSharingPermissionOptions = (item: { type: EDestinationType }) => {
  const isMicrosoft = isMicrosoftDestination(item)

  return [
    {
      label: 'Editor',
      value: DestinationEmailConfigSharingPermission.editor,
    },
    {
      label: 'Viewer',
      value: DestinationEmailConfigSharingPermission.viewer,
    },
    ...(isMicrosoft
      ? []
      : [
          {
            label: 'Commenter',
            value: DestinationEmailConfigSharingPermission.commenter,
          },
        ]),
  ]
}

export const DEFAULT_SHARING_PERMISSION = DestinationEmailConfigSharingPermission.viewer
