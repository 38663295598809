import { FC } from 'react'

import { Section } from '@features/syncs/components/sync-settings/section/section'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { Badge, Button, Checkbox, Divider, EBadgeColor, InputButton, Tooltip } from '@shared/components'
import { SyncUpdateType } from '@features/syncs/types'

import styles from './visualization-select.module.css'
import { FeatureFlag } from '@shared/components/feature-flag/feature-flag'
import { isPowerPointDestination } from '@shared/utils/destinations'
import { isLookerDataSource } from '@features/data-sources/utils'

interface Props {
  index?: number
}

export const VisualizationSelect: FC<Props> = ({ index }) => {
  const { updateVanityData, isNewSync, syncDisplayName, dashboardSelected, isSyncNameLoading, toggleSyncUpdateType, updateType, dataSource, destination } =
    useSyncSettingsContext()

  const selectVis = () =>
    updateVanityData(isNewSync ? { filtersSelectModal: true, newSyncEmbedModal: true } : { vizSelectModal: true })

  const selectVizDisabled = !dashboardSelected || isSyncNameLoading
  const textModeDisabled = selectVizDisabled || !syncDisplayName
  return (
    <Section
      title={isNewSync ? `${index}) Visualizations` : 'Visualizations'}
      subtitle="Select one or multiple visualizations"
      className="w-[500px]"
    >
      <InputButton
        tooltip
        value={syncDisplayName}
        inactive={selectVizDisabled}
        nonEditable={!isNewSync && (isSyncNameLoading || !dashboardSelected)}
        label="Visualizations"
        placeholder={'No visualizations selected'}
        onClick={selectVis}
      >
        <Button
          text={syncDisplayName ? 'Modify' : 'Select'}
          className={styles.selectBtn}
          tertiaryColor={Boolean(syncDisplayName)}
          dataTestId="visualizations"
        />
      </InputButton>
      {dataSource && isLookerDataSource(dataSource) && isPowerPointDestination(destination) && (
        <FeatureFlag flag="looker-text-viz">
          <Divider className="mt-3 mb-3" />
          <div>
            <div className="text-sm-regular mb-2">{"Select if you want the visualization to appear as a textbox instead of an image"}</div>
            <div className="flex max-w-full items-center" onClick={!textModeDisabled ? toggleSyncUpdateType : undefined}>
              <Checkbox disabled={textModeDisabled} checked={updateType === SyncUpdateType.text}  />
              <span className={`text-sm-medium ml-1 mr-2 ${textModeDisabled ? "opacity-25" : ""}`}>{" Enable text mode"}</span>
              <Tooltip title={"This feature is currently in Beta phase, meaning it's under active development and testing"}>
                <Badge text="Beta" color={EBadgeColor.SECONDARY} />
              </Tooltip>
            </div>
          </div>
        </FeatureFlag>
      )}
    </Section>
  )
}
