import { FC, KeyboardEventHandler, useState } from 'react'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

export interface IInputList {
  isClearable?: boolean
  isMulti?: boolean
  placeholder?: string
  separator?: string[]
  value: string[]
  onChange: (value: string[]) => void
  onError?: (error: string) => void
  validateNewOption?: (inputValue: string) => boolean
}

const components = {
  DropdownIndicator: null,
}

const DEFAULT_SEPARATORS = [',', 'Enter']

interface Option {
  readonly label: string
  readonly value: string
}

export const InputList: FC<IInputList> = ({
  isClearable,
  isMulti,
  placeholder,
  separator,
  value,
  onChange,
  onError,
  validateNewOption,
}) => {
  const createOption = (label: string): Option => ({
    label,
    value: label,
  })

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) {
      return
    }
    if ((separator || DEFAULT_SEPARATORS).includes(event.key)) {
      if (validateNewOption && !validateNewOption(inputValue)) {
        if (onError) {
          onError(inputValue)
        }
        event.preventDefault()
        return
      }
      if (!value.includes(inputValue)) {
        onChange([...value, inputValue])
      }
      setInputValue('')
      event.preventDefault()
    }
  }

  const handleChange = (newValue: MultiValue<Option>) => {
    onChange(newValue.map(option => option.value))
  }

  const handleBlur = () => {
    if (!inputValue) {
      return
    }

    if (validateNewOption && !validateNewOption(inputValue)) {
      if (onError) {
        onError(inputValue)
      }
      return
    }

    if (!value.includes(inputValue)) {
      onChange([...value, inputValue])
    }
    setInputValue('')
  }

  return (
    <CreatableSelect
      styles={{
        container: base => ({ ...base, marginTop: '8px' }),
        control: base => ({ ...base, borderRadius: '8px' }),
        multiValue: base => ({
          ...base,
          borderRadius: '6px',
          backgroundColor: 'var(--primary-50)',
          paddingLeft: '4px',
          color: 'var(--Gray-700)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '18px',
        }),
        multiValueRemove: base => ({ ...base, color: 'var(--primary-700)' }),
      }}
      components={components}
      inputValue={inputValue}
      isClearable={isClearable}
      isMulti={isMulti}
      menuIsOpen={false}
      onInputChange={newValue => setInputValue(newValue)}
      onChange={newValue => handleChange(newValue as MultiValue<Option>)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      placeholder={placeholder}
      value={value.map(createOption)}
    />
  )
}
