import classNames from 'classnames'
import { AnchorHTMLAttributes, FC } from 'react'
import { Link, LinkProps, To } from 'react-router-dom'

import styles from './link-button.module.css'

export interface ILinkButton extends LinkProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  to: To
  text?: string
  fill?: string
  stroke?: string
  fullWidth?: boolean
  secondaryColor?: boolean
  secondaryGray?: boolean
  tertiaryColor?: boolean
  tertiaryGray?: boolean
  linkColor?: boolean
  linkGray?: boolean
  primaryError?: boolean
  secondaryError?: boolean
  tertiaryError?: boolean
  iconLeading?: JSX.Element | null
  iconTrailing?: JSX.Element | null
  dataTestId?: string
}

export const LinkButton: FC<ILinkButton> = ({
  className,
  text,
  fill,
  stroke,
  secondaryColor,
  secondaryGray,
  tertiaryColor,
  tertiaryGray,
  linkColor,
  linkGray,
  primaryError,
  secondaryError,
  tertiaryError,
  iconLeading,
  iconTrailing,
  fullWidth,
  dataTestId,
  to,
  ...props
}) => {
  const defaultClass = classNames(
    styles.linkButton,
    secondaryColor && styles.secondaryColor,
    secondaryGray && styles.secondaryGray,
    tertiaryColor && styles.tertiaryColor,
    tertiaryGray && styles.tertiaryGray,
    linkColor && styles.linkColor,
    linkGray && styles.linkGray,
    primaryError && styles.primaryError,
    secondaryError && styles.secondaryError,
    tertiaryError && styles.tertiaryError,
    fullWidth && styles.fullWidth,
    className
  )

  return (
    <Link className={defaultClass} style={{ fill, stroke }} to={to} data-testid={dataTestId} {...props}>
      {iconLeading}
      {text && <span>{text}</span>}
      {iconTrailing}
    </Link>
  )
}
