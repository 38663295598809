import posthog, { Properties } from 'posthog-js'

import { User } from '@features/users'

import { config } from '@shared/config'
import { Event } from '@shared/product-tooling/posthog/events'

import { FeatureFlagName } from './flags'

posthog.init(config.posthogToken, {
  autocapture: false,
})

export { posthog }

export const constructPersonProperties = (user: User) => {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    orgNames: user.organizations.map(userOrg => userOrg.organization.name),
  }
}

export const isFeatureEnabled = (flag: FeatureFlagName) => {
  return posthog.isFeatureEnabled(flag)
}

export const captureEvent = async (eventName: Event, properties?: Properties) => {
  try {
    return await posthog.capture(eventName, properties)
  } catch (err) {
    console.error('Error capturing event', err)
  }
}
