import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildDestinationPath } from '@pages/routes/paths'

import { DestinationsPlaceholder, PlusIcon } from '@assets'

import { useDestinations } from '@features/destinations'

import { Button, EmptyPageTemplate, PageContainer, SortAndFilterHeader } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { useLayout } from '@shared/hooks'

import {
  DestinationsDataGridColumns,
  DestinationsDataGridData,
  DestinationsDataGridElements,
} from './destinations-table'

export const Destinations: FC = () => {
  const {
    destinations,
    isLoading,
    sortingOption,
    isAscendingOrder,
    handleAddDestination,
    setSearchQuery,
    handleSortTypeChange,
    handleSortOrderChange,
    filteringOption,
    handleFilterTypeChange,
  } = useDestinations()

  const addButton = <Button text="Add destination" iconLeading={<PlusIcon />} onClick={handleAddDestination} />

  const navigate = useNavigate()
  const { isGridLayout, setIsGridLayout } = useLayout()
  const tableViewRowOnClickHandler = (destinationId: string) => navigate(buildDestinationPath(destinationId))

  const EmptyState = (
    <EmptyPageTemplate
      icon={<DestinationsPlaceholder />}
      label="No destinations in the workspace"
      description="View of your presentations and documents"
      button={addButton}
    />
  )

  return (
    <PageContainer
      hideBackButton
      title="Destinations"
      rightActionButtons={addButton}
      subtitle="Update and manage destinations"
      loading={isLoading}
    >
      <div className="flex flex-col gap-5 w-full">
        <SortAndFilterHeader
          sortingOption={sortingOption}
          isAscendingOrder={isAscendingOrder}
          setSearchQuery={setSearchQuery}
          handleSortTypeChange={handleSortTypeChange}
          handleSortOrderChange={handleSortOrderChange}
          filteringOption={filteringOption}
          handleFilterTypeChange={handleFilterTypeChange}
          isGridLayout={isGridLayout}
          setIsGridLayout={setIsGridLayout}
        />
        <div className="flex flex-1 flex-col w-full justify-between">
          <DataGrid
            data={DestinationsDataGridData(destinations, tableViewRowOnClickHandler)}
            columns={isGridLayout ? DestinationsDataGridElements(destinations.length) : DestinationsDataGridColumns()}
            clickableRows
            EmptyState={EmptyState}
            isGrid={isGridLayout}
          />
        </div>
      </div>
    </PageContainer>
  )
}
