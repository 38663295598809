import { isEmailDestination } from '@features/destinations'
import { EDestinationType } from '@features/destinations/types'
import {
  EEmailTemplateType,
  ESelectEmailTemplateEditorMode,
  EmailTemplate,
  SelectTemplateOption,
} from '@features/emails/types'
import { ISync } from '@features/syncs/types'

import { useBoolean } from '@shared/hooks'

import { useEditorContainerSettings } from './use-editor-container-settings'

const mapTemplateToOption = (template: EmailTemplate) => ({
  label: template.name,
  value: template.id,
})

export const useSelectEmailTemplateSettings = ({
  onSelectTemplate,
  destinationType,
  templateType,
  editorMode = ESelectEmailTemplateEditorMode.SELECT_ONLY,
  targetId,
  syncs = [],
  emailTemplates,
  templateInSelect,
  setTemplateInEditor,
}: {
  onSelectTemplate: (templateId?: string | null) => void
  destinationType: EDestinationType
  templateType: EEmailTemplateType
  editorMode?: ESelectEmailTemplateEditorMode
  targetId: string
  syncs?: ISync[]
  emailTemplates: EmailTemplate[]
  templateInSelect?: EmailTemplate
  setTemplateInEditor: (template?: EmailTemplate) => void
}) => {
  const [openEmailTemplateModal, toggleOpenEmailTemplateModal] = useBoolean(false)

  const hideActionButtons = editorMode === ESelectEmailTemplateEditorMode.SELECT_ONLY
  const EMAIL_TEMPLATE_DEFAULT_OPTION = {
    label: 'Rollstack Default Email',
    value: undefined,
  }

  const emailTemplateOptions = emailTemplates
    .sort((a, b) => {
      // Latest ones at the top
      if (a.createdAt && b.createdAt && a.createdAt <= b.createdAt) return 1
      return -1
    })
    .map(mapTemplateToOption)

  if (!isEmailDestination({ type: destinationType })) {
    emailTemplateOptions.unshift(EMAIL_TEMPLATE_DEFAULT_OPTION)
  }

  const isEditorModalMode = editorMode === ESelectEmailTemplateEditorMode.MODAL
  const isEditorInlineMode = editorMode === ESelectEmailTemplateEditorMode.INLINE

  const { upsertTemplate } = useEditorContainerSettings({
    destinationType,
    templateType,
    targetId,
    syncs,
  })

  const currentTemplateOption = emailTemplateOptions.find(t => t.value === templateInSelect?.id)
  const emailTemplateDefaultOption = isEmailDestination({ type: destinationType })
    ? null
    : EMAIL_TEMPLATE_DEFAULT_OPTION
  const selectValue = templateInSelect ? currentTemplateOption : emailTemplateDefaultOption

  const onSelectChange = (option: SelectTemplateOption) => {
    const templateId = option.value || null
    const template = emailTemplates.find(t => t.id === option.value)

    setTemplateInEditor(template)
    onSelectTemplate(templateId)
  }

  const createEmptyTemplate = () => {
    setTemplateInEditor()
    onSelectTemplate(null)
  }

  return {
    upsertTemplate,
    templateInSelect,
    openEmailTemplateModal,
    toggleOpenEmailTemplateModal,
    emailTemplateOptions,
    currentTemplateOption,
    isEditorModalMode,
    isEditorInlineMode,
    hideActionButtons,
    emailTemplateDefaultOption,
    selectValue,
    onSelectChange,
    createEmptyTemplate,
  }
}
