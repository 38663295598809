import classNames from 'classnames'
import { FC } from 'react'

import { IIcon } from '@assets/types'

import { IModal, Modal, ModalIcon } from '@shared/components'

import styles from './action-modal.module.css'

export interface ActionModalProps extends IModal {
  Icon?: FC<IIcon>
  title?: string
  subtitle?: string | React.ReactNode
  btns?: React.ReactNode
  childrenClassName?: string
}

export const ActionModal: FC<ActionModalProps> = ({
  Icon,
  title,
  subtitle,
  btns,
  children,
  childrenClassName,
  ...modalProps
}) => {
  const iconView = Icon && (
    <ModalIcon>
      <Icon size={20} />
    </ModalIcon>
  )

  return (
    <Modal {...modalProps}>
      <div className={styles.header}>
        {iconView}
        {title && <div className="text-lg-semibold">{title}</div>}
      </div>

      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

      <div className={classNames(styles.children, childrenClassName)}>{children}</div>

      {btns && (
        <>
          <div className={styles.divider} />
          <div className={styles.btns}>{btns}</div>
        </>
      )}
    </Modal>
  )
}
