import debounce from 'lodash/debounce'
import { FC, useEffect, useMemo, useState } from 'react'

import { useGetLookerFolderDashboards, useGetLookerFolders, useSearchLookerDashboards } from '@features/data-sources'
import { LookerCoreDataSource } from '@features/data-sources/types'
import { DashboardFile } from '@features/syncs/components/sync-dashboard-select/dashboard-file/dashboard-file'
import { IntegrationDashboardSelect } from '@features/syncs/components/sync-dashboard-select/dashboard-select-modal'

import { FilePicker } from '@shared/components'
import { queryClient } from '@shared/http'

import { getFileFolderId, getFileName, getFolderId, getFolderName, getParentFolderId } from './getters'

const SEARCH_QUERY_DEBOUNCE_MS = 300

export const DashboardSelectLooker: FC<IntegrationDashboardSelect<LookerCoreDataSource>> = ({
  dataSource,
  searchQuery,
}) => {
  const [folderId, setFolderId] = useState<string>('root')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery)

  const { data: lookerFolders, isLoading: foldersLoading } = useGetLookerFolders({
    credentialId: dataSource.id,
    disabled: Boolean(searchQuery),
  })
  const { data: lookerFolderDashboards, isLoading: folderDashboardsLoading } = useGetLookerFolderDashboards({
    credentialId: dataSource.id,
    folderId,
    disabled: Boolean(searchQuery),
  })
  const { data: dashboardsSearchResults, isLoading: dashboardSearchLoading } = useSearchLookerDashboards({
    credentialId: dataSource.id,
    search: debouncedSearchQuery,
    disabled: !debouncedSearchQuery,
  })

  const handleSearchQueryChange = debounce(newValue => {
    setDebouncedSearchQuery(newValue)
  }, SEARCH_QUERY_DEBOUNCE_MS)

  const isLoading = foldersLoading || dashboardSearchLoading || folderDashboardsLoading

  const folders = lookerFolders?.filter(collection => collection.id !== 'root')

  const files = useMemo(() => {
    if (debouncedSearchQuery) {
      return dashboardsSearchResults
    }

    return lookerFolderDashboards
  }, [debouncedSearchQuery, dashboardsSearchResults, lookerFolderDashboards])

  useEffect(() => {
    if (!searchQuery) {
      queryClient.cancelQueries('looker/search-dashboards')
    }
  }, [searchQuery])

  useEffect(() => {
    handleSearchQueryChange(searchQuery)

    return () => {
      handleSearchQueryChange.cancel()
    }
  }, [searchQuery, handleSearchQueryChange])

  return (
    <FilePicker
      folders={folders}
      files={files}
      FileComponent={DashboardFile}
      searchQuery={searchQuery}
      getFolderId={getFolderId}
      getFolderName={getFolderName}
      getParentFolderId={getParentFolderId}
      getFileName={getFileName}
      getFileFolderId={getFileFolderId}
      isLoading={isLoading}
      setFolderId={setFolderId}
    />
  )
}
