import { PercentCrop } from 'react-image-crop'

import { EDataSourceType } from '@features/data-sources/types/types'
import { appendSheetIdToSpreadsheetUrl } from '@features/data-sources/utils'
import { FilterType } from '@features/filters/types'
import { CropOption, CropValues, ISync } from '@features/syncs/types'

const hundredPercent = 100

export const parseSyncProperties = (sync?: ISync) => {
  if (sync) {
    const { jsonFilters, jsonParams } = buildJsonFilters(sync)
    switch (sync?.type) {
      case EDataSourceType.Tableau:
        return {
          ...sync,
          dashboardId: sync.tableauData?.viewId,
          dataSourceId: sync.tableauData?.tableauCredentialsId,
          dashboard: sync.tableauData?.workbookName,
          dashboardUrl: sync.tableauData?.workbookExternalUrl ?? sync.tableauData?.workbookUrl,
          filters: { ...jsonFilters, ...jsonParams },
          tab: sync?.tableauData?.viewName,
          fullDashboard: sync.tableauData?.fullDashboard,
        }
      case EDataSourceType.Looker:
        return {
          ...sync,
          dashboardId: sync.lookerData?.dashboardId,
          dataSourceId: sync.lookerData?.lookerCredentialsId,
          dashboard: sync.lookerData?.dashboardName,
          dashboardUrl: sync.lookerData?.dashboardUrl,
          filters: jsonFilters,
          tab: '',
          fullDashboard: sync.lookerData?.fullDashboard,
        }
      case EDataSourceType.Metabase:
        return {
          ...sync,
          dashboardId: sync.metabaseData?.dashboardId,
          dataSourceId: sync.metabaseData?.metabaseCredentialsId,
          dashboard: sync.metabaseData?.dashboardName,
          dashboardUrl: sync.metabaseData?.dashboardUrl,
          filters: jsonFilters,
          tab: '',
          fullDashboard: sync.metabaseData?.fullDashboard,
        }
      case EDataSourceType.GoogleSheets:
        const dashboardUrl = appendSheetIdToSpreadsheetUrl({
          url: sync.spreadsheetsData?.spreadsheet.baseUrl,
          sheetId: sync.spreadsheetsData?.sheetId,
        })
        return {
          ...sync,
          dashboardId: sync.spreadsheetsData?.sheetId,
          dataSourceId: sync.spreadsheetsData?.spreadsheetId,
          dashboard: sync.spreadsheetsData?.spreadsheet?.name,
          dashboardUrl,
          filters: jsonFilters,
          tab: '',
          fullDashboard: false,
        }
    }
  }

  return {
    dataSourceId: undefined,
    dashboardId: undefined,
    dashboard: '',
    dashboardUrl: '',
    filters: {},
    tab: '',
    fullDashboard: false,
  }
}

/**
 * @description Build JSON filters and parameters based on the applied filters data.
 * The JSON format is used in the embed (app and embedding-app).
 *
 * @param sync - The sync data including applied filters.
 */
export const buildJsonFilters = (sync?: ISync | null) => {
  const jsonFilters: Record<string, any> = {}
  const jsonParams: Record<string, any> = {}

  if (sync && sync.appliedFilters) {
    if (sync.type === EDataSourceType.Tableau) {
      const appliedFilters = sync.appliedFilters.filter(
        appliedFilter => appliedFilter.filter.type === FilterType.TableauFilter
      )
      for (const appliedFilter of appliedFilters) {
        jsonFilters[appliedFilter.filter.name] = appliedFilter.value
      }
      const appliedParams = sync.appliedFilters.filter(
        appliedFilter => appliedFilter.filter.type === FilterType.TableauParams
      )
      for (const appliedParam of appliedParams) {
        jsonParams[appliedParam.filter.name] = appliedParam.value
      }
    } else {
      for (const appliedFilter of sync.appliedFilters) {
        jsonFilters[appliedFilter.filter.name] = appliedFilter.value
      }
    }
  }

  return {
    jsonFilters,
    jsonParams,
  }
}

export const deleteNullFilters = (filter?: Record<string, string>) => {
  const newFilter = { ...filter } as Record<string, any>
  for (const key in filter) {
    const value = filter[key]
    if (value === null) {
      delete newFilter[key]
    }
  }
  return newFilter
}

export const getCropOptionLabel = (c: CropOption) => {
  switch (c) {
    case 'auto':
      return 'Automatic crop'
    case 'manual':
      return 'Manual crop'
    case 'none':
      return 'No crop'
  }
}

export const getCropOptionDescription = (c: CropOption) => {
  switch (c) {
    case 'auto':
      return 'Crops the whitespace around the image'
    case 'manual':
      return 'Allows you to manually crop the image'
    case 'none':
      return 'Does not crop the image'
  }
}

export const fullCrop: PercentCrop = {
  x: 0,
  y: 0,
  width: hundredPercent,
  height: hundredPercent,
  unit: '%',
}

export const cropValuesToPercentCrop = (
  cropValues?: CropValues | null,
  imgWidth?: number,
  imgHeight?: number
): PercentCrop | undefined => {
  if (!cropValues || !imgWidth || !imgHeight) return

  const { left, top, width, height } = cropValues

  return {
    x: (left / imgWidth) * hundredPercent,
    y: (top / imgHeight) * hundredPercent,
    width: (width / imgWidth) * hundredPercent,
    height: (height / imgHeight) * hundredPercent,
    unit: '%',
  }
}

export const percentCropToCropValues = (percentCrop: PercentCrop, imgWidth: number, imgHeight: number): CropValues => {
  const { x, y, width, height } = percentCrop

  return {
    left: Math.round((x * imgWidth) / hundredPercent),
    top: Math.round((y * imgHeight) / hundredPercent),
    width: Math.round((width * imgWidth) / hundredPercent),
    height: Math.round((height * imgHeight) / hundredPercent),
  }
}

export const MAX_GOOGLE_PIXELS = 2048
