import axios from 'axios'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { create } from 'zustand'
import { PersistStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UserInOrganization } from '@features/organizations/types'

import { config } from '@shared/config'

const COOKIES_KEY = '__rs_tokens'

export interface User {
  id: number
  name: string
  email: string
  onBoarded: boolean
  subscribed: boolean
  auth0Id: string
  createdAt: string
  updatedAt: string
  picture: string
  stripeId: string
  stripeSubscriptionId: string
  stripePlan: string
  organizations: UserInOrganization[]

  // Rollstack API Tokens
  tokens: ApiToken

  // OAuth
  googleOAuth?: GoogleOAuth
  microsoftOAuth?: MicrosoftOAuth

  onboardingStepsCompleted: EOnboardingStep[]

  showCollectionExplainerModal: boolean
  showTemplateExplainerModal: boolean
  showTemplateModificationModal: boolean
  showVisualizationConceptModal: boolean
  showVisualizationExplainerModal: boolean
  showDataSourcesModal: boolean

  freeUpdatesRemaining: number

  firstLogin?: boolean
}

export enum EOnboardingStep {
  organizationSetup = 'organizationSetup',
  acceptOrganizationInvite = 'acceptOrganizationInvite',
  datasourceAdded = 'datasourceAdded',
  presentationAdded = 'presentationAdded',
  syncAdded = 'syncAdded',
}

export interface ApiToken {
  access: Token
  refresh: Token
}

interface Token {
  token: string
  expires: Date
}
export enum OAuthType {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
}

export interface GoogleOAuth {
  accessToken: string
  refreshToken: string
  expiredAt: string
  scopes: string
  numScopes: number

  createdAt: string
  updatedAt: string
}

export interface MicrosoftOAuth {
  mail: string
  driveUrl: string
  refreshToken: string
  accessToken: string
  expiredAt: string
  numScopes: number
  allScopes: boolean
  scopes: string

  createdAt: string
  updatedAt: string
}

export interface State {
  user?: User
  isLoggingOut?: boolean
  refreshTimer?: ReturnType<typeof setTimeout>
}

export interface Actions {
  loginUser: (user: User) => void
  updateUser: (user: Partial<User>) => void
  logoutUser: () => void
  setIsLoggingOut: (isLoggingOut: boolean) => void
  startRefreshTimer: (expiresIn: Date) => void
  refreshToken: () => Promise<ApiToken>
}

export type AnyObject = Record<string, any>

// TODO remove this and use resolveNested instead
/**
 * Parse date fields from a given object
 * @param {AnyObject} obj - Object to parse
 * @param {string[]} fields - Fields to parse
 */
const parseDateFields = (obj: AnyObject, fields: string[]): void => {
  fields.forEach(field => {
    const keys = field.split('.')
    let current: AnyObject | undefined = obj

    for (let i = 0; i < keys.length - 1; i++) {
      current = current[keys[i]] as AnyObject
      if (!current) return
    }

    const lastKey = keys[keys.length - 1]
    if (current[lastKey]) {
      current[lastKey] = dayjs(current[lastKey])
    }
  })
}

export const storage: PersistStorage<State> = {
  getItem: key => {
    const state = localStorage.getItem(key)
    if (!state) return null

    const parsedState = JSON.parse(state)
    const cookieTokens = Cookies.get(COOKIES_KEY)

    if (parsedState.state.user && cookieTokens) {
      const tokens = JSON.parse(cookieTokens)
      try {
        parsedState.state.user.tokens = tokens
      } catch (e) {
        console.error('Error parsing tokens', e)
      }
    }

    const dateFields = [
      'user.createdAt',
      'user.updatedAt',
      'user.oauth.expiredAt',
      'user.tokens.access.expires',
      'user.tokens.refresh.expires',
    ]

    parseDateFields(parsedState, dateFields)

    return parsedState
  },
  setItem: (name, value) => {
    if (value?.state?.user?.tokens) {
      const tokens = JSON.stringify(value.state.user.tokens)

      Cookies.set(COOKIES_KEY, tokens, {
        secure: true,
        sameSite: 'Strict',
      })
    }

    const copiedValue = JSON.parse(JSON.stringify(value))

    if (copiedValue?.state?.user?.tokens) {
      const descriptor = Object.getOwnPropertyDescriptor(copiedValue.state.user, 'tokens')
      if (descriptor?.configurable) {
        delete (copiedValue.state.user as { tokens?: unknown }).tokens
      } else {
        console.warn('Cannot delete property "tokens": Property is not configurable')
      }
    }

    localStorage.setItem(name, JSON.stringify(copiedValue))
  },
  removeItem: name => {
    name === 'tokens' ? Cookies.remove(COOKIES_KEY) : localStorage.removeItem(name)
  },
}

export const TIME_TO_REFRESH_IN_MIN = 5

export const useAuthStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      startRefreshTimer: expiresIn => {
        if (get().refreshTimer) {
          clearTimeout(get().refreshTimer)
        }
        const timeout = dayjs(expiresIn).diff(dayjs().add(TIME_TO_REFRESH_IN_MIN, 'minutes'), 'milliseconds')
        set(state => {
          state.refreshTimer = setTimeout(() => get().refreshToken(), timeout)
        })
      },
      refreshToken: async () => {
        const user = get().user

        if (!user) {
          throw new Error('User not found')
        }

        try {
          const response = await axios.post<ApiToken>(`${config.apiHost}/auth/refresh-tokens`, {
            refreshToken: user.tokens.refresh.token,
          })
          const tokens = response.data
          get().updateUser({
            tokens,
          })

          get().startRefreshTimer(response.data.access.expires)
          return tokens
        } catch (error: any) {
          console.error(error)
          throw new Error(error.response?.data?.message)
        }
      },
      loginUser: (user: User) => {
        set(state => {
          const currentUser = get().user
          state.user = {
            ...currentUser,
            ...user,
          }
        })
        get().startRefreshTimer(user.tokens.access.expires)
      },
      updateUser: (user: Partial<User>) => {
        set(state => {
          const currentUser = get().user
          if (!currentUser) return
          state.user = {
            ...currentUser,
            ...user,
          }
        })
        if (user.tokens && user.tokens.access) {
          get().startRefreshTimer(user.tokens.access.expires)
        }
      },
      logoutUser: () => {
        set(state => {
          state.isLoggingOut = true
          state.user = undefined
        })
        storage.removeItem('tokens')
      },
      setIsLoggingOut: (isLoggingOut: boolean) =>
        set(state => {
          state.isLoggingOut = isLoggingOut
        }),
    })),
    {
      name: 'auth',
      version: config.authVersion,
      storage,
      partialize: state => Object.fromEntries(Object.entries(state).filter(([key]) => !['isLoggingOut'].includes(key))),
    }
  )
)
