import { FC } from 'react'

import { FilterIcon, UpdateIcon } from '@assets'

import { useRefreshFilters } from '@features/destinations/api/api'
import { useDestinationRights } from '@features/destinations/hooks'
import { Destination } from '@features/destinations/types'

import { ActionModal, Button } from '@shared/components'
import { useBoolean } from '@shared/hooks'

interface IProps {
  destination: Destination
}

export const RefreshFilters: FC<IProps> = ({ destination }) => {
  const [refreshModal, toggleRefreshModal] = useBoolean()
  const { viewer } = useDestinationRights(destination)

  const { mutateAsync: refreshFilters, isLoading: isRefreshingFilters } = useRefreshFilters()

  const handleRefreshFilters = async () => {
    await refreshFilters(destination)
    toggleRefreshModal()
  }

  return (
    <div>
      <Button
        iconLeading={<UpdateIcon />}
        onClick={toggleRefreshModal}
        text="Refresh filters from data source"
        disabled={viewer}
      />

      <ActionModal
        open={refreshModal}
        Icon={FilterIcon}
        title="Refresh filters"
        subtitle="This will fetch new values from the filters you’re using in this presentation. This may take a while."
        processing={isRefreshingFilters}
        onBackgroundClick={toggleRefreshModal}
        btns={
          <>
            <Button fullWidth secondaryGray text="Cancel" onClick={toggleRefreshModal} disabled={isRefreshingFilters} />
            <Button fullWidth text="Refresh all" onClick={handleRefreshFilters} loading={isRefreshingFilters} />
          </>
        }
      />
    </div>
  )
}
