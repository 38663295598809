import { useContext } from 'react'
import { useQuery } from 'react-query'

import { TableauVariantsContext } from '@pages/collections/collection-setup/tableau-context'

import { GetFilterValuesParams } from '@features/collections/types'
import { queryFilterValues } from '@features/collections/workers/query-filter-values'
import { queryFilterValuesCombinations } from '@features/collections/workers/query-filter-values-combinations'

export const getTableauFilterValuesQueryKey = (params: GetFilterValuesParams) => ['tableau/filters-values', params]

export const useGetTableauFilterValues = (params: GetFilterValuesParams, queryOptions?: { enabled: boolean }) => {
  const { primaryFilterValues, filterValuesCombinations, filters } = useContext(TableauVariantsContext)

  const getTableauFilterValues = async (args: GetFilterValuesParams) => {
    const filterIndex = filters?.findIndex(f => f.dashboardFilterId === args.filterId)

    if (filterIndex === 0) {
      return queryFilterValues({ values: primaryFilterValues, query: args.query })
    }

    if (filterIndex === 1) {
      return queryFilterValuesCombinations({
        values: filterValuesCombinations,
        filteredByFilterValue: args.filteredByFilterValue,
        query: args.query,
      })
    }

    return []
  }

  const { data, isLoading } = useQuery<string[]>(
    getTableauFilterValuesQueryKey(params),
    () => getTableauFilterValues(params),
    {
      enabled: queryOptions?.enabled,
      useErrorBoundary: false,
    }
  )

  return {
    data,
    isLoading,
    getTableauFilterValues,
    getTableauFilterValuesQueryKey,
  }
}
