import { TrashIcon } from '@assets'

import { MenuItem } from '@shared/components/menu-item/menu-item'

export const DeleteItem = ({ onClick, visible = true }: { onClick?: () => void; visible?: boolean }) => {
  return (
    <MenuItem onClick={onClick} visible={visible}>
      <TrashIcon />
      {'Delete'}
    </MenuItem>
  )
}
