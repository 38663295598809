import { FC } from 'react'

import { ChevronUp } from '@assets/icons/arrows'

import { useHover } from '@shared/hooks/use-hover'

import styles from './account-info.module.css'

interface AccountInfoProps {
  organizationName: string
  name: string
  showChevron: boolean
  onClick?: () => void
  fullVersion: boolean
}

export const AccountInfo: FC<AccountInfoProps> = ({ organizationName, name, showChevron, onClick, fullVersion }) => {
  const { hoverRef, isHover } = useHover()

  const isChevronShown = showChevron || isHover

  return (
    <div className={styles.avatarAndDetails} onClick={onClick} ref={hoverRef} data-testid="avatarAndDetails">
      <div className={styles.avatar}>{name?.[0] || 'U'}</div>
      {fullVersion && (
        <div className={styles.details}>
          <div className={styles.orgNameAndChevron}>
            <p title={organizationName} className={styles.organizationName}>
              {organizationName}
            </p>
            {isChevronShown && <ChevronUp />}
          </div>
          <p title={name} className={styles.name}>
            {name}
          </p>
        </div>
      )}
    </div>
  )
}
