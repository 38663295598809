import { defaultPreviewHeight, defaultPreviewWidth } from '.'

import { GSheetsIcon } from '@assets'

import { ViewIdOptions } from '@features/drive/google-drive/types'
import { GSheets, GSheetsBaseURL } from '@features/embedding'

import { AbstractDestinationHandler } from './destination-handler'

export class GoogleSheetsHandler extends AbstractDestinationHandler {
  override getTitle() {
    return 'spreadsheet'
  }

  override getTitlePlural() {
    return 'spreadsheets'
  }

  override getCloudAppTitle(): string {
    return 'Google Sheets'
  }

  override getCloudTitle(): string {
    return 'Google Drive'
  }

  override getShortNamePlural() {
    return 'sheets'
  }

  override getDestinationTitle(isFileDeleted = false) {
    return isFileDeleted ? 'File Not Found Or Not Authorized In Drive' : 'Open destination'
  }

  override getDestinationUrl() {
    return `${GSheetsBaseURL}${this.presentation?.docId}`
  }

  override getGroupData() {
    return {
      group: '',
      groupIndex: 1,
    }
  }

  override getGPickerCustomViews() {
    return [google.picker.ViewId.SPREADSHEETS]
  }

  override getGPickerViewId(): ViewIdOptions {
    return 'SPREADSHEETS'
  }

  override getSmallIcon() {
    return <GSheetsIcon />
  }

  override getPreview(
    itemsInPageCount?: number,
    width = defaultPreviewWidth,
    height = defaultPreviewHeight,
    className?: string
  ) {
    if (!this.presentation?.docId) return null
    return this.presentation ? (
      <GSheets
        itemsInPageCount={itemsInPageCount}
        docId={this.presentation.docId}
        width={width}
        height={height}
        className={className}
      />
    ) : null
  }

  override getAddVizStep1() {
    return { title: 'Destination placement', subtitle: 'The image will be placed at the bottom of your spreadsheet.' }
  }

  override getDateDelimiter() {
    return '/'
  }
}
