import { IMetabaseCollection, IMetabaseDashboard } from '@features/embedding/metabase/types'

import { rootFolderId } from '@shared/components'

export const getFolderName = (folder: IMetabaseCollection) => folder.name
export const getFolderId = (folder: IMetabaseCollection) => folder.id.toString()
export const getParentFolderId = (collection: IMetabaseCollection): string => {
  const locationSegments = collection.location?.split('/').filter(segment => segment)

  return locationSegments && locationSegments.length > 0 ? locationSegments[locationSegments.length - 1] : rootFolderId
}

export const getFileName = (dashboard: IMetabaseDashboard) => dashboard.title
export const getFileFolderId = (dashboard: IMetabaseDashboard) =>
  dashboard.folder.id ? dashboard.folder.id.toString() : rootFolderId
