import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 20

export const ActivityDeleteVisIcon: FC<Pick<IIcon, 'size'>> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="none">
      <path
        d="M9.99996 1.5H13.5C14.9001 1.5 15.6002 1.5 16.1349 1.77248C16.6053 2.01217 16.9878 2.39462 17.2275 2.86502C17.5 3.3998 17.5 4.09987 17.5 5.5V12.5C17.5 13.9001 17.5 14.6002 17.2275 15.135C16.9878 15.6054 16.6053 15.9878 16.1349 16.2275C15.6002 16.5 14.9001 16.5 13.5 16.5H6.49996C5.09983 16.5 4.39976 16.5 3.86498 16.2275C3.39458 15.9878 3.01213 15.6054 2.77244 15.135C2.49996 14.6002 2.49996 13.9001 2.49996 12.5V9M6.66663 9.83333V13.1667M13.3333 8.16667V13.1667M9.99996 4.83333V13.1667M1.66663 3.16667H6.66663"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
