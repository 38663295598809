import { FC, PropsWithChildren, useEffect } from 'react'

import { useDestinationsStore } from '@features/destinations'

import { useGetDestinations } from './api/api'

export const DestinationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: destinations } = useGetDestinations()
  const { setDestinations } = useDestinationsStore()

  useEffect(() => {
    if (!destinations) {
      setDestinations([])
    } else {
      setDestinations(destinations)
    }
  }, [destinations, setDestinations])

  return <>{children}</>
}
