import { FC, PropsWithChildren, useEffect } from 'react'

import { useDataSourcesStore } from '@features/data-sources'
import { useGetDataSources } from '@features/data-sources'

export const DataSourcesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: dataSources } = useGetDataSources()
  const { setDataSources } = useDataSourcesStore()

  useEffect(() => {
    if (!dataSources) {
      setDataSources([])
    } else {
      setDataSources(dataSources)
    }
  }, [dataSources, setDataSources])

  return <>{children}</>
}
