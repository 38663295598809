import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { useGetCollectionById } from '@features/collections'
import { invalidateCollectionVariants } from '@features/collections/utils'
import { useActiveDestinationContext } from '@features/destinations/active-destination-provider'
import { Destination, EDestinationType } from '@features/destinations/types'
import {
  BatchAddSyncRequest,
  IBatchApplyFilterRequest,
  IBatchApplyFilterResponse,
  ISync,
  ISyncUpdateRequest,
} from '@features/syncs/types'

import {
  batchApplyFilterPartialFailureToast,
  changeSyncFiltersToast,
  deleteSyncToast,
  duplicateSyncToast,
} from '@shared/components/toasts/toasts'
import { getMessage } from '@shared/constants/text'
import { UseMutationWithErrorHandlerOptions, onError, queryClient, useMutationWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'

import { handleSyncUpdateError, handleTransferSyncError } from './error-handler'

export const useAddSync = () => {
  const { destinationId, collectionId } = useActiveDestinationContext()
  const { data: collection } = useGetCollectionById({ collectionId })
  const isCollectionTemplate = collection ? collection.collectionVariantTemplate.destinationId === destinationId : false

  return useMutation<ISync, Error, BatchAddSyncRequest>(
    async syncRequest => {
      const response = await api.post(
        isCollectionTemplate ? `collections/${collectionId}/template/syncs/batch-add` : '/syncs/batch-add',
        JSON.stringify(syncRequest)
      )
      return response.data
    },
    {
      onSuccess: async (_, sync) => {
        await queryClient.invalidateQueries(['syncs'])
        if (isCollectionTemplate && collection) {
          await invalidateCollectionVariants(collection)
        } else {
          await queryClient.invalidateQueries(['activities-logs', { destinationId: sync.presentationId }])
          await queryClient.invalidateQueries(['presentation', { destinationId: sync.presentationId }])
          await queryClient.invalidateQueries(['presentations'])
        }
      },
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('SYNC_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useUpdateSync = () => {
  const { destinationId, collectionId } = useActiveDestinationContext()
  const { data: collection } = useGetCollectionById({ collectionId })
  const isCollectionTemplate = collection ? collection.collectionVariantTemplate.destinationId === destinationId : false

  return useMutationWithErrorHandler(
    (sync: ISyncUpdateRequest) => {
      return api.patch(
        isCollectionTemplate ? `collections/${collectionId}/template/syncs/${sync.id}` : `/syncs/${sync.id}`,
        JSON.stringify(sync)
      )
    },
    {
      onSuccess: (data: any) => {
        const { id, presentationId } = data.data
        queryClient.invalidateQueries(['syncs'])
        queryClient.invalidateQueries(['sync', id])
        if (isCollectionTemplate && collection) {
          invalidateCollectionVariants(collection)
        } else {
          queryClient.invalidateQueries(['presentation', { destinationId: presentationId }])
        }
      },
      errorHandlers: [handleSyncUpdateError],
      errorTitle: getMessage('SYNC_UPDATE_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useDuplicateSync = ({
  destinationId,
  collectionId,
}: {
  destinationId?: string
  collectionId?: string
}) => {
  const { data: collection } = useGetCollectionById({ collectionId })
  const isCollectionTemplate = collection?.collectionVariantTemplate.destinationId === destinationId

  return useMutation<ISync | ISync[], any, string>(
    async (syncId: string) => {
      const response = await api.post(
        isCollectionTemplate
          ? `collections/${collectionId}/template/syncs/${syncId}/duplicate`
          : `/syncs/${syncId}/duplicate`
      )
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['syncs'])

        if (isCollectionTemplate && collection) {
          invalidateCollectionVariants(collection)
        } else {
          duplicateSyncToast()
          queryClient.invalidateQueries(['presentation', { destinationId }])
        }
      },
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('SYNCS_DUPLICATE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export interface DeleteSyncsParams {
  syncIds: string[] | string
}
export const useDeleteSyncs = ({
  destination,
  collectionId,
  onSuccess,
}: {
  destination?: Destination
  collectionId?: string
  onSuccess: () => void
}) => {
  const { data: collection } = useGetCollectionById({ collectionId })
  const isCollectionTemplate = collection?.collectionVariantTemplate.destinationId === destination?.id

  const queryFn = ({ syncIds }: DeleteSyncsParams) => {
    const syncIdsArray = Array.isArray(syncIds) ? syncIds : [syncIds]
    return api.post(
      `${isCollectionTemplate ? `/collections/${collectionId}/template` : ''}/syncs/batch-delete`,
      JSON.stringify(syncIdsArray)
    )
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess: (data: any, variables: DeleteSyncsParams) => {
      onSuccess && onSuccess()
      const deletedSyncs = data.data
      queryClient.invalidateQueries(['syncs'])
      if (deletedSyncs.length) {
        queryClient.invalidateQueries(['activities-logs', { destinationId: deletedSyncs[0].presentationId }])
        Promise.all(
          deletedSyncs.map(({ presentationId }: ISync) =>
            queryClient.invalidateQueries(['presentation', { destinationId: presentationId }])
          )
        )
      }
      if (isCollectionTemplate && collection) {
        invalidateCollectionVariants(collection)
      }
      deleteSyncToast(variables.syncIds.length > 1)
    },
    filePickerOptions: {
      itemId: destination?.docId,
      itemName: destination?.name,
      type: destination?.type,
    },
    useErrorBoundary: false,
  })
}

interface IUpdateSelectedSyncsRequest {
  destinationId: string
  syncIds: string[]
  type: EDestinationType
}
export const useUpdateSyncsById = ({ destination }: { destination?: Destination } = {}) => {
  return useMutationWithErrorHandler(
    async ({ syncIds, destinationId }: IUpdateSelectedSyncsRequest) => {
      const response = await api.post(`/presentations/${destinationId}/update-selected-syncs`, JSON.stringify(syncIds))
      return response.data
    },
    {
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
      },
      errorTitle: getMessage('PRESENTATIONS_SYNC_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useBatchApplyFilters = ({
  destinationId,
  collectionId,
}: {
  destinationId?: string
  collectionId?: string
}) => {
  const { data: collection } = useGetCollectionById({ collectionId })
  const isCollectionTemplate = collection?.collectionVariantTemplate.destinationId === destinationId

  return useMutation(
    (data: IBatchApplyFilterRequest) =>
      api.patch(
        `${isCollectionTemplate ? `/collections/${collectionId}/template` : ''}/syncs/batch-apply-filter`,
        JSON.stringify(data)
      ),
    {
      onSuccess: async data => {
        const result = data.data as IBatchApplyFilterResponse
        if (result.failedSyncs.length) {
          batchApplyFilterPartialFailureToast(result.failedSyncs.map(s => s.name))
        }
        if (isCollectionTemplate && collection) {
          await queryClient.invalidateQueries(['collection', { collectionId }])
          invalidateCollectionVariants(collection)
        }
        queryClient.invalidateQueries(['syncs'])
        changeSyncFiltersToast()
      },
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('SYNC_BATCH_APPLY_FILTERS_ERROR'),
          message: error.message,
        })
      },
    }
  )
}

export const useDeleteAppliedFilters = () => {
  const { destinationId, collectionId } = useActiveDestinationContext()
  const { data: collection } = useGetCollectionById({ collectionId })
  const isCollectionTemplate = collection?.collectionVariantTemplate.destinationId === destinationId

  return useMutation(
    (syncId: string) =>
      api.delete(
        isCollectionTemplate
          ? `collections/${collectionId}/template/syncs/${syncId}/applied-filters`
          : `/syncs/${syncId}/applied-filters`
      ),
    {
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('SYNC_DELETE_FILTERS_ERROR'),
          message: error.message,
        })
      },
    }
  )
}

export interface ITransferSyncRequest {
  ignoreMissingFilters?: boolean
}
export const useTransferSync = (
  destinationId: string,
  syncId: string,
  options: UseMutationWithErrorHandlerOptions<ISync, AxiosError, ITransferSyncRequest, any> = {}
) => {
  return useMutationWithErrorHandler<ISync, AxiosError, ITransferSyncRequest, any>(
    async (body: ITransferSyncRequest = {}) => {
      const result = await api.post(`/syncs/${syncId}/transfer`, JSON.stringify(body))
      return result.data
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['syncs'])
        queryClient.invalidateQueries(['presentation', { destinationId }])
        options.onSuccess && options.onSuccess(data, variables, context)
      },
      errorTitle: getMessage('SYNC_TRANSFER_OWNERSHIP_ERROR'),
      errorHandlers: [handleTransferSyncError],
      useErrorBoundary: false,
    }
  )
}
