import { FC } from 'react'

import { DestinationTabs, buildDestinationPath } from '@pages/routes/paths'

import {
  ActivityIcon,
  DestinationIcon,
  EmailIcon,
  FilterIcon,
  SendRightIcon,
  UpdateIcon,
  VisualizationIcon,
} from '@assets'
import { ClockRewind } from '@assets/icons/clock-rewind'
import { SettingsIcon } from '@assets/icons/settings'
import { ThemeIcon } from '@assets/icons/theme'
import { UsersPlusIcon } from '@assets/icons/users'

import { DestinationActivity } from '@features/activity-log'
import { TemplateExplainerModal } from '@features/collections'
import { SharingCollectionPermissions } from '@features/collections/types'
import {
  DestinationFilters,
  DestinationSettings,
  DestinationTitle,
  DestinationVizTab,
  MicrosoftLockWarning,
  ViewerDestinationTooltip,
  VisualizationConceptModal,
  WithDestinationShareProps,
  WithDestinationUpdateProps,
  WithDestinationVersionProps,
  isEmailDestination,
  isPresentationDestination,
  useDestinationSettings,
  withDestinationShare,
  withDestinationUpdate,
  withDestinationVersion,
} from '@features/destinations'
import { DestinationSendingConfirmModal } from '@features/destinations/components/destination-sending-confirm-modal/destination-sending-confirm-modal'
import { DestinationSending } from '@features/destinations/components/destination-sending/destination-sending'
import { DestinationTemplate } from '@features/destinations/components/destination-template/destination-template'
import { UpdateMethod } from '@features/destinations/types'
import { DestinationHistoricalRuns } from '@features/historical-run'
import { DestinationTheme } from '@features/theme/components/destination-theme'
import { useAuthStore } from '@features/users'

import { Avatar, Button, OverlayingComponents, PageContainer, Tooltip } from '@shared/components'
import { RelativeDateTime } from '@shared/components/relative-date-time'
import { RoutedTabs, Tab } from '@shared/components/routed-tabs/routed-tabs'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import styles from './destination.module.css'

const NOT_FOUND_USER_NAME = 'Deleted User'

type DestinationTab = Omit<Tab, 'tabIndex'>

const DestinationRaw: FC<WithDestinationUpdateProps & WithDestinationVersionProps & WithDestinationShareProps> = ({
  createVersion,
  shareDestination,
  updateDestination,
  updateLoading,
  versionLoading,
}) => {
  const {
    destination: {
      isLoading,
      viewer,
      canEdit,
      nextUpdateAt,
      lastSuccessfulUpdateAt,
      destination,
      isUpdateRunning,
      back,
      onUpdate,
      onArchiveAndUpdate,
    },
    modals: {
      showFileLockWarning,
      templateExplainerModal,
      visualizationConceptModal,
      isDestinationSendingOpen,
      toggleShowFileLockWarning,
      toggleTemplateExplainerModal,
      toggleVisualizationConceptModal,
      toggleDestinationSendingOpen,
    },
    slideId,
    slides,
    loadingSlides,
    isDestinationSendingEnabled,
    destinationEmailConfig,
    loadingDestinationEmailConfig,
    canSendDestinationEmail,
  } = useDestinationSettings({
    createVersion,
    updateDestination,
    updateLoading,
    versionLoading,
  })

  const { user } = useAuthStore()

  const collectionOwner = {
    userId: destination?.variantInCollection?.collection?.userId,
    user: destination?.variantInCollection?.collection?.user,
  }
  const collectionAdminAndContributorsWithAccess = (
    destination?.variantInCollection?.collection?.sharedWith ?? []
  ).filter(
    sharedWith =>
      sharedWith.permission === SharingCollectionPermissions.admin ||
      (sharedWith.permission === SharingCollectionPermissions.contributor &&
        destination?.sharedWith?.find(sharedDestinationWith => sharedDestinationWith.userId === sharedWith.userId))
  )
  const sharedWithUsers = destination?.variantInCollection
    ? [collectionOwner, ...collectionAdminAndContributorsWithAccess].filter(
        sharedWith => sharedWith.userId !== user?.id
      )
    : (destination?.sharedWith ?? []).filter(sharedUser => sharedUser.user?.id !== user?.id)

  if (!destination) {
    return <PageContainer loading />
  }

  const visualizationsTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <VisualizationIcon />
        Visualizations
      </div>
    ),
    tabPanel: (key: React.Key) => (
      <DestinationVizTab key={key} destination={destination} slideId={slideId} slides={slides} />
    ),
    path: buildDestinationPath(destination.id, DestinationTabs.VISUALIZATIONS),
  }

  const filtersTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <FilterIcon />
        Filters
      </div>
    ),
    tabPanel: (key: React.Key) => <DestinationFilters key={key} destination={destination} />,
    path: buildDestinationPath(destination.id, DestinationTabs.FILTERS),
  }

  const settingsTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <SettingsIcon />
        Settings
      </div>
    ),
    tabPanel: (key: React.Key) => <DestinationSettings key={key} destination={destination} />,
    path: buildDestinationPath(destination.id, DestinationTabs.SETTINGS),
  }

  const sendingTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <EmailIcon />
        Sending
      </div>
    ),
    tabPanel: (key: React.Key) => (
      <DestinationSending key={key} destination={destination} isLoading={loadingDestinationEmailConfig} />
    ),
    path: buildDestinationPath(destination.id, DestinationTabs.SENDING),
  }

  const runsTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <ClockRewind />
        Runs
      </div>
    ),
    tabPanel: (key: React.Key) => <DestinationHistoricalRuns key={key} destinationId={destination.id} />,
    path: buildDestinationPath(destination.id, DestinationTabs.HISTORICAL_RUNS),
  }

  const activityTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <ActivityIcon />
        Activity
      </div>
    ),
    tabPanel: (key: React.Key) => (
      <DestinationActivity key={key} destination={destination} notFoundUserName={NOT_FOUND_USER_NAME} />
    ),
    path: buildDestinationPath(destination.id, DestinationTabs.ACTIVITY_LOG),
  }

  const themeTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <ThemeIcon />
        Theme colors
      </div>
    ),
    tabPanel: (key: React.Key) => <DestinationTheme key={key} destination={destination} />,
    path: buildDestinationPath(destination.id, DestinationTabs.THEME),
  }

  const templateTab: DestinationTab = {
    tabComponent: (
      <div className={styles.tab}>
        <DestinationIcon />
        Template
      </div>
    ),
    tabPanel: (key: React.Key) => (
      <DestinationTemplate key={key} destination={destination} initialEmailConfig={destinationEmailConfig} />
    ),
    path: buildDestinationPath(destination.id, DestinationTabs.TEMPLATE),
  }

  // TODO: improve this logic
  const sendingTabAvailable = isDestinationSendingEnabled && canEdit ? [sendingTab] : []
  const themeTabAvailable =
    isPresentationDestination(destination) && canEdit && isFeatureEnabled('theme-colors') ? [themeTab] : []
  const settingsTabAvailable = !isEmailDestination(destination) && canEdit ? [settingsTab] : []

  const firstFewTabs = isEmailDestination(destination)
    ? [visualizationsTab, templateTab, ...sendingTabAvailable, filtersTab]
    : [visualizationsTab, filtersTab, ...sendingTabAvailable]

  const tabs = [...firstFewTabs, runsTab, activityTab, ...themeTabAvailable, ...settingsTabAvailable]

  const updateButton =
    destination.updateMethod === UpdateMethod.Update ? (
      <Button
        iconLeading={<UpdateIcon />}
        text="Update"
        disabled={viewer || !destination.syncs?.length}
        loading={isUpdateRunning}
        onClick={() => onUpdate(destination)}
      />
    ) : destination.updateMethod === UpdateMethod.Archive ? (
      <Button
        iconLeading={<UpdateIcon />}
        text="Archive & Update"
        disabled={viewer}
        loading={isUpdateRunning}
        onClick={() => onArchiveAndUpdate(destination)}
      />
    ) : destination.updateMethod === UpdateMethod.UpdateVisualizations ? (
      <Tooltip
        position="top"
        title={'You can add visualizations in the "Visualizations" tab'}
        show={!destination.syncs || destination.syncs.length === 0}
      >
        <Button
          secondaryColor
          iconLeading={<UpdateIcon />}
          text="Update visualizations"
          disabled={!destination.syncs || destination.syncs.length === 0}
          loading={isUpdateRunning}
          onClick={() => onUpdate(destination)}
        />
      </Tooltip>
    ) : null

  const sendButton = isDestinationSendingEnabled && (
    <Tooltip
      position="top"
      title="Destination can only be sent after the email sending is configured for it"
      show={!canSendDestinationEmail}
    >
      <Button
        secondaryColor
        iconLeading={<SendRightIcon size={20} />}
        text="Send"
        disabled={!canSendDestinationEmail || !canEdit}
        onClick={() => toggleDestinationSendingOpen(true)}
      />
    </Tooltip>
  )

  return (
    <PageContainer
      back={back}
      title={<DestinationTitle destination={destination} viewer={viewer} />}
      rightActionButtons={
        <div>
          <ViewerDestinationTooltip show={viewer}>
            <div className={styles.actionButtons}>
              <OverlayingComponents
                renderMoreComponents={hiddenCount => (
                  <Avatar name={`+ ${hiddenCount.toString()}`} size={30} className="text-xs-medium" />
                )}
              >
                <Avatar key={user?.id} name={user?.name} size={30} className="text-xs-medium" />
                {sharedWithUsers.map(sharedUser =>
                  sharedUser.user ? (
                    <Avatar key={sharedUser.userId} name={sharedUser.user.name} size={30} className="text-xs-medium" />
                  ) : (
                    <></>
                  )
                )}
              </OverlayingComponents>
              <Button
                secondaryGray
                iconLeading={<UsersPlusIcon />}
                text="Share"
                onClick={() => shareDestination(destination.id)}
                disabled={viewer}
              />

              {isEmailDestination(destination) ? (
                <>
                  {updateButton}
                  {sendButton}
                </>
              ) : (
                <>
                  {sendButton}
                  {updateButton}
                </>
              )}
            </div>
          </ViewerDestinationTooltip>
        </div>
      }
      loading={isLoading || loadingSlides}
    >
      <div className={styles.container}>
        <div className={styles.summary}>
          {!isEmailDestination(destination) && (
            <div className={styles.summarySection}>
              <span className={styles.summarySectionTitle}>Update method</span>
              <div className={styles.summarySectionData}>
                {destination.updateMethod === UpdateMethod.Update ? 'Same file' : 'Archive and update'}
              </div>
            </div>
          )}

          <div className={styles.summarySection}>
            <span className={styles.summarySectionTitle}>Scheduling</span>
            <div className={styles.summarySectionData}>
              {Boolean(destination.updateSchedules.length) ? 'Enabled' : 'Disabled'}
            </div>
          </div>

          {lastSuccessfulUpdateAt && (
            <div className={styles.summarySection}>
              <span className={styles.summarySectionTitle}>Last full update</span>
              <div className={styles.summarySectionData}>
                <RelativeDateTime date={lastSuccessfulUpdateAt} />
              </div>
            </div>
          )}

          {nextUpdateAt && (
            <div className={styles.summarySection}>
              <span className={styles.summarySectionTitle}>Next update</span>
              <div className={styles.summarySectionData}>
                <RelativeDateTime date={nextUpdateAt} />
              </div>
            </div>
          )}
        </div>

        <div className={styles.moduleContainer}>
          <RoutedTabs
            tabs={tabs.map((tab, idx) => ({ ...tab, tabIndex: idx }))}
            defaultTabIndex={0}
            defaultRoute={tabs[0].path}
          />
        </div>
      </div>

      <MicrosoftLockWarning
        open={showFileLockWarning}
        onClose={toggleShowFileLockWarning}
        onOk={toggleShowFileLockWarning}
        type={destination.type}
      />

      <TemplateExplainerModal
        visible={templateExplainerModal}
        setModalOpen={toggleTemplateExplainerModal}
        type={destination.type}
      />

      <VisualizationConceptModal visible={visualizationConceptModal} setModalOpen={toggleVisualizationConceptModal} />

      {destinationEmailConfig && (
        <DestinationSendingConfirmModal
          destination={destination}
          destinationEmailConfig={destinationEmailConfig}
          isOpen={isDestinationSendingOpen}
          onClose={() => toggleDestinationSendingOpen(false)}
        />
      )}
    </PageContainer>
  )
}

export const Destination = withDestinationShare(withDestinationVersion(withDestinationUpdate(DestinationRaw)))
