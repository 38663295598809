import { SingleValue } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { VariantTableSelectProps } from '@features/collections/components/collection-setup/variant-select-table/variant-select-table'
import { useGetFilterValues } from '@features/collections/hooks/use-get-filter-values'
import { VariantOption } from '@features/collections/types'

import { primaryStyle } from '@shared/components'

export const PrimaryFilterValuesSelect = ({
  credentialsId,
  primaryFilter,
  value,
  onChange,
}: Pick<VariantTableSelectProps, 'primaryFilter'> & {
  credentialsId?: string
  value: VariantOption | null
  onChange: (option: SingleValue<VariantOption>) => void
}) => {
  const { handleLoadOptions } = useGetFilterValues(primaryFilter.type, {
    credentialsId: credentialsId,
    dashboardId: primaryFilter.dashboardId,
    filterId: primaryFilter.dashboardFilterId,
  })

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions
      value={value}
      loadOptions={handleLoadOptions}
      onChange={onChange}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      isSearchable={true}
      styles={primaryStyle()}
    />
  )
}
