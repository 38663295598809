import { AxiosError, AxiosResponse } from 'axios'
import httpStatus from 'http-status'
import { UseMutationResult, useMutation } from 'react-query'

import { CoreDataSource, EDataSourceType, IDataSource } from '@features/data-sources/types/types'

import { infoToast, successToast } from '@shared/components/toasts/toasts'
import { getMessage } from '@shared/constants'
import { useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'
import { onError, queryClient } from '@shared/http/query-client'

import { useAddChildLookerDataSource } from './looker/looker-api'
import { useAddChildMetabaseDataSource } from './metabase/metabase-api'
import { useAddChildTableauDataSource } from './tableau/tableau-api'

export const useGetDataSources = (active = false) => {
  return useQueryWithErrorHandler<CoreDataSource[], AxiosError>(
    ['datasources', active],
    async () => {
      const response = await api.get('datasources', { params: { active } })
      if (!response) {
        return []
      }
      return response.data
    },
    {
      errorTitle: getMessage('DATA_SOURCES_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useAddChildDataSource = (
  dataSource: IDataSource
): UseMutationResult<AxiosResponse<IDataSource, any> | undefined, any, any, unknown> => {
  const tableauEndpoint = useAddChildTableauDataSource(dataSource.id)
  const metabaseEndpoint = useAddChildMetabaseDataSource(dataSource.id)
  const lookerEndpoint = useAddChildLookerDataSource(dataSource.id)

  switch (dataSource.type) {
    case EDataSourceType.Tableau:
      return tableauEndpoint
    case EDataSourceType.Metabase:
      return metabaseEndpoint
    case EDataSourceType.Looker:
      return lookerEndpoint
    default:
      throw new Error('Invalid data source type')
  }
}

type DataSourceAccessResponse = {
  status: number
  expiresAt: string
  dataSourceId?: string
}

export const useRequestDataSource = () => {
  return useMutation<AxiosResponse<DataSourceAccessResponse>, any, string | undefined>(
    dataSourceId => api.post('/datasources/access', JSON.stringify({ dataSourceId })),
    {
      onSuccess: ({ data }) => {
        if (data.status === httpStatus.OK) {
          infoToast('Request already sent')
        } else if (data.status === httpStatus.CREATED) {
          successToast('Data source requested')
        }
        const key = `dataSourcesAccessRequestExpiry${data.dataSourceId ? `/${data.dataSourceId}` : ''}`
        localStorage.setItem(key, data.expiresAt.toString())
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('DATA_SOURCES_REQUEST_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export type ManageDefaultDataSourceRequest = {
  dataSourceId: string
  isDefault: boolean
}

export const useManageDefaultDataSource = () => {
  return useMutation<ManageDefaultDataSourceRequest, any, any>(
    ({ dataSourceId, isDefault }) => api.post(`/datasources/${dataSourceId}`, { isDefault }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['looker'])
        queryClient.invalidateQueries(['metabase'])
        queryClient.invalidateQueries(['tableau'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('DATA_SOURCES_DEFAULT_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}
