import classNames from 'classnames'
import { FC } from 'react'

import { RollstackLogoFull } from '@assets/icons/logos'

import { useAuthStore } from '@features/users'

import { Button } from '@shared/components'

import styles from './organization-joined.module.css'

interface OrganizationJoinedProps {
  organizationName: string
}

export const OrganizationJoined: FC<OrganizationJoinedProps> = ({ organizationName }) => {
  const { updateUser } = useAuthStore()

  const onNextClick = () => {
    updateUser({ firstLogin: false })
  }

  return (
    <main className={styles.container}>
      <section className={styles.logoAndHeader}>
        <RollstackLogoFull />
      </section>
      <section className={styles.content}>
        <h1 className={classNames(styles.header, styles.verticalSpacing)}>Welcome</h1>
        <p className={styles.verticalSpacing}>
          You have successfully joined <span className={styles.orgName}>{organizationName}</span> organization on
          Rollstack
        </p>
        <Button className={styles.nextBtn} text="Next" onClick={onNextClick} />
      </section>
    </main>
  )
}
