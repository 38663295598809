import classNames from 'classnames'
import { FC } from 'react'

import { SmallSpinner } from '@assets'

import { useHideLoadingPreview } from '@shared/hooks/use-hide-loading-preview'

import styles from './g-docs.module.css'

interface IGDocs {
  docId: string
  title?: string
  itemsInPageCount?: number
  width?: number
  height?: number
  className?: string
  showFullDocument?: boolean
}

export const GDocsBaseURL = 'https://docs.google.com/document/d/'

const defaultWidth = 380
const defaultHeight = 470

const three = 3
const four = 4
const mBottomOffset = 50
const bRadius = 12
const bRadiusSmall = 4

export const GDocs: FC<IGDocs> = ({
  docId,
  className,
  itemsInPageCount,
  width = defaultWidth,
  height = defaultHeight,
  title = docId,
  showFullDocument = false,
}) => {
  const classes = classNames(styles.iframe, className)
  const url = `${GDocsBaseURL}${docId}/preview`

  const position = showFullDocument ? 'static' : 'relative'
  const transform = showFullDocument ? 'scale(0.29)' : ''
  const marginTop = showFullDocument ? `-${height / three}px` : 0
  const marginBottom = showFullDocument ? `-${height / four + mBottomOffset}px` : 0
  const borderRadius = showFullDocument ? bRadius : bRadiusSmall
  const borderColor = showFullDocument ? '#98a2b3' : '#d0d5dd'

  const hidden = useHideLoadingPreview(itemsInPageCount)

  return (
    <div
      className={styles.container}
      style={{
        position,
        transform,
        height,
        minWidth: width,
        marginTop,
        marginBottom,
      }}
    >
      <iframe
        hidden={hidden}
        className={classes}
        src={url}
        title={title}
        width={width}
        height={height}
        style={{ borderRadius }}
        id={'react-google-docs-iframe'}
      />

      <div className={styles.insideBorder} style={{ width, height }} />
      <div className={styles.border} style={{ width, height, borderRadius, borderColor }} />
      <div className={styles.spinnerContainer} hidden={!hidden} style={{ height: `${height}px` }}>
        <SmallSpinner />
      </div>
    </div>
  )
}
