import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 24

export const XClose: FC<IIcon> = ({ size = defaultSize, color = 'currentColor', className, onClick }) => {
  return (
    <svg width={size} viewBox="0 0 24 24" fill="none" className={className} onClick={onClick}>
      <path d="M18 6L6 18M6 6L18 18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
