import { FolderIcon } from '@assets'

import styles from './folder.module.css'

interface FolderProps<T> {
  folder: T
  setCurrentFolderId: (id: string) => void
  getFolderName: (folder: T) => string
  getFolderId: (folder: T) => string
}

export const Folder = <T,>({ folder, setCurrentFolderId, getFolderName, getFolderId }: FolderProps<T>) => {
  return (
    <div className={styles.container} onClick={() => setCurrentFolderId(getFolderId(folder))}>
      <FolderIcon />
      {getFolderName(folder)}
    </div>
  )
}
