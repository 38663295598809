import React from 'react'

import { Button as ShadcnButton } from '@shared/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@shared/components/ui/dropdown-menu'

export interface BaseFilterOption {
  label: string
  value: any
}

interface FilterDropdownProps<T extends BaseFilterOption> {
  icon?: React.ReactNode
  label: string
  options: T[]
  selectedOption: T
  onSelect: (option: T) => void
  highlightNonDefaultValue?: boolean
}

export const FilterOrStorterDropdown = <T extends BaseFilterOption>({
  icon,
  label,
  options,
  selectedOption,
  onSelect,
  highlightNonDefaultValue = true,
}: FilterDropdownProps<T>) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadcnButton
          variant={highlightNonDefaultValue && selectedOption.value !== options[0].value ? 'secondary' : 'outline'}
          className="gap-2 w-[150px]"
        >
          {icon} {label}: <span className="text-ellipsis">{selectedOption.label}</span>
        </ShadcnButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {options.map(option => (
          <DropdownMenuCheckboxItem
            key={option.value}
            checked={option.value === selectedOption.value}
            onClick={() => onSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
