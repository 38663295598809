import classNames from 'classnames'
import React from 'react'

import { ChevronLeft, ChevronRight } from '@assets/icons/arrows'

import styles from './carousel.module.css'

export interface CarouselContent {
  imageUrl: string
  content?: React.ReactNode
}

interface CarouselProps {
  contents: CarouselContent[]
  width: number
  height: number
  step: number
  maxStep: number
  onClickPrevious: () => void
  onClickNext: () => void
  onClickStep: (index: number) => void
}

export const Carousel: React.FC<CarouselProps> = ({
  contents,
  width,
  height,
  step,
  maxStep,
  onClickStep,
  onClickPrevious,
  onClickNext,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.carousel}>
        <div className={styles.carouselInner}>
          {contents.map(({ imageUrl }, index) => (
            <img
              key={imageUrl}
              src={imageUrl}
              alt={`Slide ${index}`}
              width={width}
              height={height}
              className={styles.carouselItem}
              style={{ display: index === step ? 'block' : 'none' }}
            />
          ))}
        </div>
        {contents.length > 1 && (
          <>
            <button
              disabled={step === 0}
              className={classNames(step === 0 && styles.disabledBtn, styles.carouselControlPrev)}
              onClick={onClickPrevious}
            >
              <ChevronLeft />
            </button>
            <button
              disabled={step === maxStep}
              className={classNames(step === maxStep && styles.disabledBtn, styles.carouselControlNext)}
              onClick={onClickNext}
            >
              <ChevronRight />
            </button>
          </>
        )}
      </div>

      <div className={styles.contentAndIndicators}>
        {contents[step].content && <div className={styles.carouselContent}>{contents[step].content}</div>}

        {contents.length > 1 && (
          <div className={styles.carouselIndicators}>
            {contents.map((_, index) => (
              <span key={index} className={index === step ? styles.active : ''} onClick={() => onClickStep(index)} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
