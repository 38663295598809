import { Editor } from '@tiptap/react'
import { useState } from 'react'

import { ActionModal, Button, Input } from '@shared/components'

interface Props {
  editor: Editor
  open: boolean
  closeModal: () => void
}

export const AddImageLinkModal = ({ editor, open, closeModal }: Props) => {
  const [input, setInput] = useState('')

  const setLink = (url: string) => {
    editor.chain().focus().extendMarkRange('link').setImage({ src: url }).run()
  }

  const handleSubmit = () => {
    setLink(input)
    closeModal()
  }

  return (
    <ActionModal open={open} onBackgroundClick={closeModal} title="Add image">
      <div className="flex flex-col gap-1">
        <Input onChange={e => setInput(e.target.value)} placeholder="Image URL" />
        <div className="w-full flex justify-end">
          <Button text="Add" onClick={() => handleSubmit()} disabled={!input}>
            Add
          </Button>
        </div>
      </div>
    </ActionModal>
  )
}
