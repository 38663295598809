import { FC, Fragment } from 'react'
import { useMatches } from 'react-router-dom'

import styles from './breadcrumbs.module.css'

export const Breadcrumbs: FC = () => {
  let matches = useMatches()

  let crumbs = matches
    .filter(match => Boolean((match.handle as any)?.crumb))
    .map((match, idx) => <Fragment key={idx}>{(match.handle as any).crumb(match.params)}</Fragment>)

  return <div className={styles.container}>{crumbs}</div>
}
