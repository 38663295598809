import { useContext, useEffect, useState } from 'react'

import { useCollectionRights } from '@features/collections'
import { ICollection } from '@features/collections/types'
import { isSharedItem, useDestinationRights } from '@features/destinations'
import { Destination, UpdateMethod } from '@features/destinations/types'
import { FreeTrialContext } from '@features/free-trial/free-trial-provider'
import { useDestinationsStatuses } from '@features/notch/destinations-status-provider'
import { useAuthStore } from '@features/users'

interface IUseDestination {
  destination: Destination
  collection?: ICollection
  updateDestination: (destination: Destination) => Promise<void>
  createVersion: (destination: Destination) => Promise<void>
}

export const useDestination = ({ destination, collection, updateDestination, createVersion }: IUseDestination) => {
  const { freeTrial } = useContext(FreeTrialContext)
  const { viewer: destinationViewer, editor: destinationEditor, owner: isOwner } = useDestinationRights(destination)
  const { contributor: collectionContributor, admin: collectionAdmin } = useCollectionRights(collection)
  const { isDestinationUpdateRunning } = useDestinationsStatuses()
  const [isUpdateRunning, setIsUpdateRunning] = useState<boolean>(false)

  const isViewer = destinationViewer || collectionContributor
  const isEditor = destinationEditor || collectionAdmin

  const user = useAuthStore(state => state.user)
  const isShared = isSharedItem(destination, user)

  useEffect(() => {
    if (destination.id) {
      setIsUpdateRunning(isDestinationUpdateRunning(destination.id))
    } else {
      setIsUpdateRunning(false)
    }
  }, [isDestinationUpdateRunning, destination.id])

  const onUpdate = (destination: Destination) => {
    freeTrial(
      () => {
        setIsUpdateRunning(true)
        updateDestination(destination).catch(() => {
          setIsUpdateRunning(false)
        })
      },
      { allowedExtraFreeUpdate: true }
    )
  }

  const onArchiveAndUpdate = (destination: Destination) => {
    freeTrial(
      () => {
        setIsUpdateRunning(true)
        createVersion(destination).catch(() => {
          setIsUpdateRunning(false)
        })
      },
      { allowedExtraFreeUpdate: true }
    )
  }

  const isArchiveAndUpdate = destination.updateMethod === UpdateMethod.Archive

  const onUpdateClick = () => (isArchiveAndUpdate ? onArchiveAndUpdate(destination) : onUpdate(destination))

  return {
    isShared,
    isViewer,
    isEditor,
    isOwner,
    isUpdateRunning,
    onUpdateClick,
  }
}
