import { FC, useMemo, useRef } from 'react'

import { TableauWorkbook } from '@features/data-sources'
import { isLookerDataSource, isMetabaseDataSource, isTableauDataSource } from '@features/data-sources/utils'
import { ILookerDashboard } from '@features/embedding/looker/types'
import { IMetabaseDashboard } from '@features/embedding/metabase/types'
import { useSyncSettingsContext } from '@features/syncs'
import { SyncEmbedLooker } from '@features/syncs/components/sync-embed/sync-embed-looker'
import { SyncEmbedMetabase } from '@features/syncs/components/sync-embed/sync-embed-metabase'
import { SyncEmbedTableau } from '@features/syncs/components/sync-embed/sync-embed-tableau'
import { SyncEmbedRef } from '@features/syncs/components/sync-embed/types'

import { Modal, Switch } from '@shared/components'
import { useBoolean } from '@shared/hooks'

import { ProgressSteps } from './progress-steps'

import styles from './new-sync-embed.module.css'

export const NewSyncEmbed: FC = () => {
  const embedRef = useRef<SyncEmbedRef>(null)
  const [vizSelected, setVizSelected] = useBoolean()
  const {
    dataSource,
    vizSelectModal,
    filtersSelectModal,
    dashboardSelected,
    fullDashboard,
    newSyncEmbedModal,
    updateVanityData,
    toggleFullDashboard,
  } = useSyncSettingsContext()

  const embedView = useMemo(() => {
    if (!dataSource || !dashboardSelected) return null

    const props = { ref: embedRef, setVizSelected }

    if (isLookerDataSource(dataSource)) {
      return <SyncEmbedLooker {...props} dataSource={dataSource} dashboard={dashboardSelected as ILookerDashboard} />
    } else if (isTableauDataSource(dataSource)) {
      return <SyncEmbedTableau {...props} dataSource={dataSource} dashboard={dashboardSelected as TableauWorkbook} />
    } else if (isMetabaseDataSource(dataSource)) {
      return (
        <SyncEmbedMetabase {...props} dataSource={dataSource} dashboard={dashboardSelected as IMetabaseDashboard} />
      )
    }
  }, [dataSource, dashboardSelected, setVizSelected])

  const closeModal = () =>
    updateVanityData({ newSyncEmbedModal: false, filtersSelectModal: false, vizSelectModal: false })

  const handleNextStep = () => {
    if (filtersSelectModal) {
      updateVanityData({ vizSelectModal: true, filtersSelectModal: false })
    } else {
      embedRef.current?.saveEmbedDataToProvider()
      closeModal()
    }
  }

  const handlePrevClick = () => {
    if (vizSelectModal) {
      updateVanityData({ vizSelectModal: false, filtersSelectModal: true })
    } else {
      updateVanityData({ vizSelectModal: false, filtersSelectModal: false, newSyncEmbedModal: false })
      closeModal()
    }
  }

  const confirmDisabled = vizSelectModal && !fullDashboard && !vizSelected

  return (
    <Modal open visible={newSyncEmbedModal} className={styles.container} onBackgroundClick={closeModal}>
      <ProgressSteps
        step={filtersSelectModal ? 'filters' : 'viz'}
        onNextClick={handleNextStep}
        onPreviousClick={handlePrevClick}
        nextDisabled={confirmDisabled}
      />

      <div className={styles.fullDash}>
        {vizSelectModal && <Switch value={fullDashboard} text="Select full dashboard" onChange={toggleFullDashboard} />}
      </div>

      <div className={styles.embedContainer}>{embedView}</div>
    </Modal>
  )
}
