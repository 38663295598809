import { AxiosError } from 'axios'

import {
  useAddCollectionEmailConfig,
  useGetCollectionEmailConfig,
  useUpdateCollectionEmailConfig,
} from '@features/collections/api/api'
import { ICollectionEmailConfig } from '@features/collections/types'
import { getEmptyCollectionEmailConfig } from '@features/collections/utils'

import { useEmailConfigHandler } from '@shared/components/email-config/hooks/use-email-config-handler'
import { UseQueryWithErrorHandlerOptions } from '@shared/http'

export const useCollectionEmailConfigHandler = (
  collectionId?: string,
  options: UseQueryWithErrorHandlerOptions<ICollectionEmailConfig, AxiosError> = {}
) =>
  useEmailConfigHandler<ICollectionEmailConfig>(
    {
      targetId: collectionId,
      useGetEmailConfig: useGetCollectionEmailConfig,
      useAddEmailConfig: useAddCollectionEmailConfig,
      useUpdateEmailConfig: useUpdateCollectionEmailConfig,
      getEmptyConfig: getEmptyCollectionEmailConfig,
    },
    options
  )
