import { AxiosError } from 'axios'

import { warnToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import { formatApiErrorFromAxios, onError } from '@shared/http'

import { ITableauApiError } from './tableau-api'

export const TABLEAU_API_ERROR_CODES = {
  INVALID_CREDENTIALS: '401001',
  ACCESS_DENIED_VIEW: '404011',
  ACCESS_DENIED: '403004',
} as const

export const TABLEAU_API_DETAIL_ERROR_CODES = {
  LOGIN_FAILED: '16',
  VERIFY_SECRET_VALUE: '10084',
  VERIFY_CLIENT_ID_SECRET_ID: '10085',
  VERIFY_CLIENT_ID_SECRET_ID_AGAIN: '10089',
  CONNECTED_APP_NOT_ENABLED: '10090',
} as const

export type TableauApiErrorCodes = (typeof TABLEAU_API_ERROR_CODES)[keyof typeof TABLEAU_API_ERROR_CODES]
export type TableauApiDetailErrorCodes =
  (typeof TABLEAU_API_DETAIL_ERROR_CODES)[keyof typeof TABLEAU_API_DETAIL_ERROR_CODES]

export const TABLEAU_API_ERROR_MESSAGES: Record<TableauApiErrorCodes, string> = {
  [TABLEAU_API_ERROR_CODES.INVALID_CREDENTIALS]: 'Your Tableau credentials are invalid.',
  [TABLEAU_API_ERROR_CODES.ACCESS_DENIED_VIEW]: "Your Tableau credentials don't have access to this view.",
  [TABLEAU_API_ERROR_CODES.ACCESS_DENIED]: "Your Tableau credentials don't have access to this view.",
}

export const TABLEAU_API_DETAIL_ERROR_MESSAGES: Record<TableauApiDetailErrorCodes, string> = {
  [TABLEAU_API_DETAIL_ERROR_CODES.LOGIN_FAILED]:
    'Login failed with your Tableau credentials. Please verify your Tableau credentials.',
  [TABLEAU_API_DETAIL_ERROR_CODES.VERIFY_SECRET_VALUE]: 'Please verify your Secret Value.',
  [TABLEAU_API_DETAIL_ERROR_CODES.VERIFY_CLIENT_ID_SECRET_ID]: 'Please verify your Client Id and Secret Id.',
  [TABLEAU_API_DETAIL_ERROR_CODES.VERIFY_CLIENT_ID_SECRET_ID_AGAIN]: 'Please verify your Client Id and Secret Id',
  [TABLEAU_API_DETAIL_ERROR_CODES.CONNECTED_APP_NOT_ENABLED]:
    'Connected app is not enabled. Please make sure to enable your connected app.',
}

const FALLBACK_TABLEAU_API_ERROR =
  'Something went wrong when accessing Tableau. Please verify your Tableau credentials, to make sure you have access to this view.'

const FALLBACK_TABLEAU_API_DETAILED_ERROR =
  'Please verify your Tableau credentials. Please verify your site name. If you are using Tableau Server, please try with an empty site name'

export const extractTableauErrorMessage = ({ code, detailedCode, message }: ITableauApiError = {}): string => {
  const specificErrorMessage =
    TABLEAU_API_ERROR_MESSAGES[code as keyof typeof TABLEAU_API_ERROR_MESSAGES] || message || FALLBACK_TABLEAU_API_ERROR
  const errorDetails =
    TABLEAU_API_DETAIL_ERROR_MESSAGES[detailedCode as keyof typeof TABLEAU_API_DETAIL_ERROR_MESSAGES] ||
    FALLBACK_TABLEAU_API_DETAILED_ERROR

  return [specificErrorMessage, errorDetails].join(' ')
}

export const handleTableauApiErrors = (
  error: AxiosError,
  errorTitle = getMessage('TABLEAU_API_ERROR_GENERIC'),
  hideSubtext = false
) => {
  const apiError = formatApiErrorFromAxios(error)
  const tableauCode = apiError.metadata.code as keyof typeof TABLEAU_API_ERROR_MESSAGES
  const tableauDetailCode = apiError.metadata.detailedCode as keyof typeof TABLEAU_API_DETAIL_ERROR_MESSAGES

  if (tableauCode) {
    if (tableauCode === TABLEAU_API_ERROR_CODES.INVALID_CREDENTIALS) {
      const subtitle = TABLEAU_API_DETAIL_ERROR_MESSAGES[tableauDetailCode] || FALLBACK_TABLEAU_API_DETAILED_ERROR
      warnToast({title: TABLEAU_API_ERROR_MESSAGES[tableauCode], subtitle, toastId: tableauCode})
      return
    }

    const specificErrorMessage = extractTableauErrorMessage(apiError.metadata)
    onError(error, {
      title: errorTitle,
      message: specificErrorMessage,
      hideSubtext: true,
    })
    return
  }
  onError(error, {
    title: errorTitle,
    message: apiError.message,
    hideSubtext,
  })
}
