import { FC } from 'react'

import { IIcon } from '@assets/types'

import { numbers } from '@shared/constants/numbers'

export const DatabaseIcon: FC<IIcon> = ({ size = numbers.defaultIconSize, color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 4.16669C17.5 5.5474 14.1421 6.66669 10 6.66669C5.85786 6.66669 2.5 5.5474 2.5 4.16669M17.5 4.16669C17.5 2.78598 14.1421 1.66669 10 1.66669C5.85786 1.66669 2.5 2.78598 2.5 4.16669M17.5 4.16669V15.8334C17.5 17.2167 14.1667 18.3334 10 18.3334C5.83333 18.3334 2.5 17.2167 2.5 15.8334V4.16669M17.5 8.1002C17.5 9.48353 14.1667 10.6002 10 10.6002C5.83333 10.6002 2.5 9.48353 2.5 8.1002M17.5 12.0334C17.5 13.4167 14.1667 14.5334 10 14.5334C5.83333 14.5334 2.5 13.4167 2.5 12.0334"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
