import { AccessContext, OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce'
import dayjs from 'dayjs'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { User, useAddUser, useExchangeSamlCode, useSamlClient } from '@features/users'

import { config } from '@shared/config'

interface ProviderProps {
  children: ReactNode
}

export enum AuthStatus {
  Unknown = 'UNKNOWN',
  Fetching = 'FETCHING',
  Loaded = 'LOADED',
}

interface SamlContextInterface {
  authStatus: AuthStatus
  // TODO: type user
  user: User | null
  isLoading: boolean
  tenant: string | null
  signIn: (tenant: string) => void
  signOut: (callback: VoidFunction) => void
  setTenant: React.Dispatch<React.SetStateAction<string | null>>
  setClientId: React.Dispatch<React.SetStateAction<string>>
}

// localstorage key to store from url
const APP_FROM_URL = 'appFromUrl'
const SamlContext = createContext<SamlContextInterface>({} as SamlContextInterface)

const SamlProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [tenant, setTenant] = useState<string | null>(null)
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.Unknown)
  const [clientId, setClientId] = useState<string>('')
  const addUserQuery = useAddUser()

  const exchangeCodeQuery = useExchangeSamlCode()
  let from = localStorage.getItem(APP_FROM_URL) || '/'

  const redirectUrl = config.jackson.redirectUri.replace(/\/+$/, '') + from
  const product = config.jackson.product

  const samlClient = useSamlClient({ redirectUrl, clientId })

  const handleToken = async (token: AccessContext) => {
    if (!token.token?.value) {
      console.error('no token found')
      return
    }

    const response = await addUserQuery.mutateAsync({
      sso: { token: token.token.value },
    })
    const user = response.data
    setUser(user)
  }

  /**
   * @description Check cred after user is redirected back from the SSO Provider.
   * @param {OAuth2AuthCodePKCE | null} samlClient - SAML OAuth2AuthCodePKCE instance.
   * @returns {Promise<void>}
   */
  const handleRedirectedAuth = async (samlClient: OAuth2AuthCodePKCE | null) => {
    if (!samlClient) {
      console.debug('No SAML client provided...')
      return
    }

    try {
      const hasAuthCode = await samlClient.isReturningFromAuthServer()

      if (!hasAuthCode) {
        console.debug('No auth code detected...')
        return
      }

      const token = await samlClient.getAccessToken()

      if (token) {
        localStorage.removeItem(APP_FROM_URL)
        await handleToken(token)
        window.history.replaceState({}, document.title, window.location.pathname)
        return
      }
    } catch (e) {
      console.error('Error while retrieving token using SAML client:', e)
    }

    // Fallback to custom code exchange if SAML client fails
    try {
      const authCode = OAuth2AuthCodePKCE.extractParamFromUrl(window.location.href, 'code')
      const { data: token } = await exchangeCodeQuery.mutateAsync(authCode)

      if (token) {
        await handleToken({
          token: {
            value: token.access_token,
            expiry: dayjs().add(token.expires_in, 'second').toISOString(),
          },
        })
        window.history.replaceState({}, document.title, window.location.pathname)
      }
    } catch (err) {
      console.error('Error during custom code exchange:', err)
    }
  }

  useEffect(() => {
    let didCancel = false

    const checkAuthorization = async () => {
      if (!samlClient || didCancel) return

      setAuthStatus(AuthStatus.Fetching)

      const isClientAuthorized = samlClient.isAuthorized()

      if (isClientAuthorized) {
        const token = await samlClient.getAccessToken()
        if (!token || samlClient.isAccessTokenExpired()) {
          await signOut(() => window.location.reload())
          return
        }

        localStorage.removeItem(APP_FROM_URL)
        await handleToken(token)
        setAuthStatus(AuthStatus.Loaded)
      } else {
        await handleRedirectedAuth(samlClient)
        setAuthStatus(AuthStatus.Loaded)
      }
    }

    checkAuthorization()

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samlClient])

  const signIn = async (orgTenant: string) => {
    // Store the from url before redirecting
    // We need this to correctly initialize the oauthClient after getting redirected back from SSO Provider.
    localStorage.setItem(APP_FROM_URL, from)
    // Initiate the login flow
    setTenant(orgTenant)
    if (!orgTenant) {
      return
    }
    await samlClient?.fetchAuthorizationCode({
      tenant: orgTenant,
      product,
    })
  }

  const signOut = async (callback: VoidFunction) => {
    samlClient?.reset()
    setUser(null)
    callback()
  }

  const value = {
    authStatus,
    user,
    isLoading: addUserQuery.isLoading,
    tenant,
    setTenant,
    setClientId,
    signIn,
    signOut,
  }

  return <SamlContext.Provider value={value}>{children}</SamlContext.Provider>
}

const useSaml = () => useContext(SamlContext)

export { SamlProvider, useSaml }
