import { AxiosError } from 'axios'

import { EmailTemplateCreateRequest } from '@features/emails/types'
import { EEmailTemplateType, EmailTemplate, EmailTemplateUpdateRequest } from '@features/emails/types'
import { useAuthStore } from '@features/users'

import { getMessage } from '@shared/constants'
import {
  UseMutationWithErrorHandlerOptions,
  UseQueryWithErrorHandlerOptions,
  api,
  useMutationWithErrorHandler,
  useQueryWithErrorHandler,
} from '@shared/http'
import { queryClient } from '@shared/http'
import { WithId } from '@shared/utils/types'

export const useGetEmailTemplatesByType = (
  {
    templateType,
    onlyTemplatesForTarget = false,
    targetId,
  }: { templateType: EEmailTemplateType; onlyTemplatesForTarget?: boolean; targetId?: string },
  options: UseQueryWithErrorHandlerOptions<EmailTemplate[], AxiosError> = {}
) => {
  const { user } = useAuthStore()
  return useQueryWithErrorHandler<EmailTemplate[], AxiosError>(
    ['email-templates', templateType, targetId, onlyTemplatesForTarget],
    async () => {
      const response = await api.get('/email/templates', {
        params: {
          templateType,
          onlyTemplatesForTarget,
          targetId,
        },
      })
      return response.data || []
    },
    {
      errorTitle: getMessage('EMAIL_TEMPLATE_GET_ERROR'),
      onSuccess: options.onSuccess,
      enabled: Boolean(user?.organizations?.length),
    }
  )
}

export const useAddEmailTemplate = (
  { type, targetId }: { type: EEmailTemplateType; targetId: string },
  options: UseMutationWithErrorHandlerOptions<WithId<EmailTemplate>, AxiosError, EmailTemplateCreateRequest, any> = {}
) => {
  return useMutationWithErrorHandler<WithId<EmailTemplate>, AxiosError, EmailTemplateCreateRequest, any>(
    async data => {
      data.type = type
      if (type === EEmailTemplateType.collectionUpdate) {
        data.collectionId = targetId
      } else {
        data.destinationId = targetId
      }
      const result = await api.post('/email/templates', JSON.stringify(data))
      return result.data
    },
    {
      onSuccess: async (...args) => {
        if (options.onSuccess) {
          await options.onSuccess(...args)
        }
        await queryClient.invalidateQueries(['email-templates', type])
      },
      errorTitle: getMessage('EMAIL_TEMPLATE_ADD_ERROR'),
      useErrorBoundary: false,
    }
  )
}

interface IUpdateEmailTemplateVariables {
  templateId: string
  data: EmailTemplateUpdateRequest
}

export const useUpdateEmailTemplate = (
  type: EEmailTemplateType,
  options: UseMutationWithErrorHandlerOptions<EmailTemplate, AxiosError, IUpdateEmailTemplateVariables, any> = {}
) => {
  return useMutationWithErrorHandler<EmailTemplate, AxiosError, IUpdateEmailTemplateVariables, any>(
    async ({ templateId, data }) => {
      const result = await api.patch(`/email/templates/${templateId}`, JSON.stringify(data))
      return result.data
    },
    {
      onSuccess: async (...args) => {
        if (options.onSuccess) {
          await options.onSuccess(...args)
        }
        await queryClient.invalidateQueries(['email-templates', type])
      },
      errorTitle: getMessage('EMAIL_TEMPLATE_UPDATE_ERROR'),
      useErrorBoundary: false,
    }
  )
}
