import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'

import { IActivityLog } from '@features/activity-log/types'

import { buildActivityItem } from './helpers'

import styles from './destination-activity-table.module.css'

interface IActivityRowData {
  id: string
  date: string
  user: string
  details: IActivityLog
}

export const activityTableData = (activityLogs: IActivityLog[] = []): IActivityRowData[] =>
  activityLogs.map(activity => ({
    id: activity.id.toString(),
    date: dayjs(activity.createdAt).format('MMM D, h:mm A'),
    user: activity.user?.name || 'Unknown User',
    details: activity,
  }))

export const activityTableColumns = (): ColumnDef<IActivityRowData>[] => [
  {
    id: 'type',
    header: 'Type',
    cell: ({ row }) => {
      const activity = row.original.details
      const components = buildActivityItem(activity) || { icon: null }
      return components.icon
    },
    size: 10,
  },
  {
    accessorKey: 'date',
    header: 'Date',
    cell: info => info.getValue(),
    size: 40,
  },

  {
    id: 'message',
    header: 'Activity',
    cell: ({ row }) => {
      const activity = row.original.details
      const user = row.original.user
      const components = buildActivityItem(activity) || { message: null }
      return (
        <div className={styles.message}>
          {user}
          {components.message}
        </div>
      )
    },
  },
]
