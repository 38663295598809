import classNames from 'classnames'
import snakeCase from 'lodash/snakeCase'
import { DetailedHTMLProps, FC, InputHTMLAttributes, PropsWithChildren, ReactNode, useState } from 'react'

import { EyeIcon, EyeSlashIcon, XClose } from '@assets'

import { Callout } from '@shared/components/callout/callout'
import { Colors } from '@shared/constants'
import { useAutoFocus } from '@shared/hooks/use-auto-focus'

import styles from './input.module.css'

interface IInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    PropsWithChildren {
  id?: string
  icon?: JSX.Element
  label?: string
  description?: ReactNode
  secret?: boolean
  viewable?: boolean
  onCrossClick?: () => void
  errorMessage?: string | false
  warningMessage?: string | false
  info?: string
}

const crossSize = 18

export const Input: FC<IInputProps> = ({
  id,
  label,
  description,
  icon,
  className,
  secret,
  viewable = true,
  onCrossClick,
  children,
  errorMessage,
  warningMessage,
  info,
  ...props
}) => {
  const inputRef = useAutoFocus<HTMLInputElement>(props.autoFocus)
  const [isHidden, setIsHidden] = useState(secret)

  const toggleHidden = () => {
    setIsHidden(!isHidden)
  }

  const insideId = id || snakeCase(label)
  const inputType = isHidden ? 'password' : 'text'
  const inputClass = classNames(styles.input, icon && styles.inputIcon, secret && styles.inputSecret, className)

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.inputWrapper}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <input ref={inputRef} id={insideId} type={inputType} className={inputClass} {...props} />

        {onCrossClick && (
          <XClose className={styles.cross} color={Colors.black} size={crossSize} onClick={onCrossClick} />
        )}
        {secret && viewable && (
          <div className={styles.eyeIcon} onClick={toggleHidden} title={`${isHidden ? 'show' : 'hide'} secret`}>
            {isHidden ? <EyeIcon /> : <EyeSlashIcon />}
          </div>
        )}
        {children}
      </div>
      {info && <Callout open type="info" title={info} />}
      {errorMessage && <Callout open type="error" title={errorMessage} />}
      {warningMessage && <Callout open type="warning" title={warningMessage} compact/>}
      {description && <span className={styles.description}>{description}</span>}
    </div>
  )
}
