import { useEffect, useState } from 'react'

import { PADDING_COUNT } from '@features/embedding/metabase/metabase-embed/dashboard-element-overlay-wrapper'

import { MetabaseDashboardDetails, MetabaseOrderedCard } from './types'
import { FILTER_HEIGHT, FILTER_PADDING, FOOTER_HEIGHT, PADDING, RANDOM_FOUR, TAB_PADDING } from './use-metabase'

const rowHeight = 54
const colWidth = 61
const spacing = 6
const minIframeWidth = 800

export const hideDashboardFilters = (dashboard: MetabaseDashboardDetails) => {
  const value = Object.keys(dashboard.embedding_params ?? {}).join(',')
  return `&hide_parameters=${value}`
}

export const useGetDashboardGrid = (orderedCards?: MetabaseOrderedCard[]) => {
  if (!orderedCards || !(orderedCards.length > 0)) return [0, 0]
  const columnWidths = orderedCards.reduce(
    (acc, curr) => {
      for (let i = curr.row; i < curr.row + (curr.size_y ?? curr.sizeY!); i++) {
        acc[i] = (curr.size_x ?? curr.sizeX!) + (acc[i] || 0)
      }
      return acc
    },
    {} as { [key: number]: number }
  )
  const cols = Math.max(...Object.values(columnWidths))

  const rowHeights = orderedCards.reduce(
    (acc, curr) => {
      for (let i = curr.col; i < curr.col + (curr.size_x ?? curr.sizeX!); i++) {
        acc[i] = (curr.size_y ?? curr.sizeY!) + (acc[i] || 0)
      }
      return acc
    },
    {} as { [key: number]: number }
  )
  const rows = Math.max(...Object.values(rowHeights))

  return [rows, cols]
}

export const useGetIframeSize = (
  rows: number,
  columns: number,
  hasFilters: boolean,
  hideFilters: boolean,
  hasTabs: boolean
) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const filtersHeight = () => {
      if (hasFilters) {
        return hideFilters ? FILTER_PADDING * PADDING_COUNT : FILTER_HEIGHT + FILTER_PADDING * PADDING_COUNT
      }
      return 0
    }
    const tabsHeight = () => {
      return hasTabs ? TAB_PADDING * PADDING_COUNT : 0
    }
    const w = Math.max(columns * colWidth, minIframeWidth) + RANDOM_FOUR
    const h =
      rows * rowHeight + rows * spacing + FOOTER_HEIGHT + PADDING * PADDING_COUNT + filtersHeight() + tabsHeight()
    setWidth(w)
    setHeight(h)
  }, [rows, columns, hasFilters, hideFilters, hasTabs])

  return [width, height]
}

export function extractMetabaseFiltersFromUrl(url: URL): Record<string, string> {
  if (!url?.search) return {}
  const filters = Array.from(url.searchParams.keys()).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: url.searchParams.getAll(key).join(','),
      }
    },
    {} as Record<string, string>
  )
  return filters
}

export const parseTabId = (tab?: string): number | undefined => {
  if (!tab) return
  try {
    return parseInt(tab.split('-')[0])
  } catch (e) {
    return
  }
}
