import { useCallback, useState } from 'react'

export const useBoolean = (initialState = false) => {
  const [value, setValue] = useState(initialState)

  const toggle = useCallback((val?: any) => {
    setValue(prev => {
      if (typeof val === 'boolean') return val

      return !prev
    })
  }, [])

  return [value, toggle, setValue] as const
}
