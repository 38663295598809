import { FC } from 'react'

import { IIcon } from '@assets/types'

export const RollstackWallPaper: FC<IIcon> = () => {
  return (
    <svg width="341" height="400" viewBox="0 0 341 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M257.94 20.8027V38.5191C257.94 49.7115 248.867 58.7849 237.674 58.7849H135.534C124.342 58.7849 115.269 67.8576 115.269 79.0508V142.398"
        stroke="black"
        strokeWidth="8.91697"
      />
      <path
        d="M257.94 17.5605V38.5195C257.94 49.7119 248.867 58.7853 237.674 58.7853H135.534C124.342 58.7853 115.269 67.8579 115.269 79.0511V142.398"
        stroke="white"
        strokeWidth="7.2957"
      />
      <path
        d="M29.3418 70.252V90.3111C29.3418 101.504 38.4152 110.577 49.6076 110.577H139.588C150.78 110.577 159.854 119.65 159.854 130.843V162.664"
        stroke="black"
        strokeWidth="8.91697"
      />
      <path
        d="M29.3418 70.252V90.3111C29.3418 101.504 38.4152 110.577 49.6076 110.577H139.588C150.78 110.577 159.854 119.65 159.854 130.843V162.664"
        stroke="white"
        strokeWidth="7.2957"
      />
      <path
        d="M94.1927 114.026C95.9835 114.026 97.4353 112.574 97.4353 110.784C97.4353 108.993 95.9835 107.541 94.1927 107.541C92.4019 107.541 90.9502 108.993 90.9502 110.784C90.9502 112.574 92.4019 114.026 94.1927 114.026Z"
        fill="#FFD400"
      />
      <path
        d="M140.399 62.1452C142.19 62.1452 143.641 60.6935 143.641 58.9027C143.641 57.1119 142.19 55.6602 140.399 55.6602C138.608 55.6602 137.156 57.1119 137.156 58.9027C137.156 60.6935 138.608 62.1452 140.399 62.1452Z"
        fill="#FFD400"
      />
      <path
        d="M1.375 20.6342C1.375 17.8176 3.65839 15.5342 6.47502 15.5342H52.7205C55.5366 15.5342 57.8202 17.8176 57.8202 20.6342V80.0922C57.8202 82.9092 55.5366 85.1919 52.7205 85.1919H6.47502C3.65839 85.1919 1.375 82.9092 1.375 80.0922V20.6342Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3281 31.3691H48.8654V36.3237H10.3281V31.3691Z"
        fill="#FFD400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3281 42.3809H48.8654V47.3355H10.3281V42.3809Z"
        fill="#FFD400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3281 53.3916H48.8654V58.3462H10.3281V53.3916Z"
        fill="#FFD400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3281 64.4023H48.8654V69.3569H10.3281V64.4023Z"
        fill="#FFD400"
      />
      <path
        d="M228.758 332.896V321.68C228.758 310.488 219.684 301.415 208.492 301.415H122.565C111.372 301.415 102.299 292.341 102.299 281.149V260.75"
        stroke="black"
        strokeWidth="8.91697"
      />
      <path
        d="M228.758 332.896V321.68C228.758 310.488 219.684 301.415 208.492 301.415H122.565C111.372 301.415 102.299 292.341 102.299 281.149V260.75"
        stroke="white"
        strokeWidth="7.2957"
      />
      <path
        d="M283.88 128.212H30.9627C26.7096 128.212 23.2617 131.66 23.2617 135.913V277.774C23.2617 282.027 26.7096 285.475 30.9627 285.475H283.88C288.133 285.475 291.581 282.027 291.581 277.774V135.913C291.581 131.66 288.133 128.212 283.88 128.212Z"
        fill="#0098D9"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M95.0033 156.584H58.5248C56.5102 156.584 54.877 158.217 54.877 160.232V178.066C54.877 180.08 56.5102 181.714 58.5248 181.714H95.0033C97.0179 181.714 98.6511 180.08 98.6511 178.066V160.232C98.6511 158.217 97.0179 156.584 95.0033 156.584Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M23.667 183.74H35.4212C40.5695 183.74 44.7435 187.914 44.7435 193.063C44.7435 198.211 40.5695 202.385 35.4212 202.385H23.667V183.74Z"
        fill="#222236"
      />
      <path d="M69.873 169.148H90.9495" stroke="#CDD6E6" strokeWidth="3.24253" />
      <path
        d="M145.262 156.584H108.784C106.769 156.584 105.136 158.217 105.136 160.232V178.066C105.136 180.08 106.769 181.714 108.784 181.714H145.262C147.277 181.714 148.91 180.08 148.91 178.066V160.232C148.91 158.217 147.277 156.584 145.262 156.584Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M262.804 156.584H226.326C224.311 156.584 222.678 158.217 222.678 160.232V178.066C222.678 180.08 224.311 181.714 226.326 181.714H262.804C264.819 181.714 266.452 180.08 266.452 178.066V160.232C266.452 158.217 264.819 156.584 262.804 156.584Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path d="M119.322 169.148H140.399" stroke="#CDD6E6" strokeWidth="3.24253" />
      <path d="M236.864 169.148H257.941" stroke="#CDD6E6" strokeWidth="3.24253" />
      <path
        d="M38.6645 147.667C40.903 147.667 42.7177 145.852 42.7177 143.614C42.7177 141.375 40.903 139.561 38.6645 139.561C36.426 139.561 34.6113 141.375 34.6113 143.614C34.6113 145.852 36.426 147.667 38.6645 147.667Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M51.6342 147.667C53.8727 147.667 55.6874 145.852 55.6874 143.614C55.6874 141.375 53.8727 139.561 51.6342 139.561C49.3957 139.561 47.5811 141.375 47.5811 143.614C47.5811 145.852 49.3957 147.667 51.6342 147.667Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M65.4155 147.667C67.654 147.667 69.4686 145.852 69.4686 143.614C69.4686 141.375 67.654 139.561 65.4155 139.561C63.177 139.561 61.3623 141.375 61.3623 143.614C61.3623 145.852 63.177 147.667 65.4155 147.667Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M111.824 170.364V169.858H110.156L112.331 167.683L113.419 168.77C113.466 168.818 113.531 168.845 113.598 168.845C113.664 168.845 113.729 168.818 113.776 168.77L116.384 166.163L116.026 165.805L113.598 168.233L112.51 167.145C112.462 167.098 112.398 167.072 112.331 167.072C112.264 167.072 112.2 167.098 112.152 167.145L109.798 169.5V167.831H109.291V170.364H111.824Z"
        fill="#010203"
      />
      <path
        d="M231.392 166.312V166.818H233.06L230.885 168.993L229.797 167.906C229.75 167.858 229.685 167.831 229.618 167.831C229.552 167.831 229.487 167.858 229.44 167.906L226.832 170.513L227.19 170.871L229.618 168.443L230.706 169.531C230.754 169.578 230.818 169.604 230.885 169.604C230.952 169.604 231.017 169.578 231.064 169.531L233.418 167.176V168.845H233.925V166.312H231.392Z"
        fill="#010203"
      />
      <path
        d="M195.522 194.684H58.5248C56.5102 194.684 54.877 196.317 54.877 198.331V263.182C54.877 265.197 56.5102 266.83 58.5248 266.83H195.522C197.536 266.83 199.17 265.197 199.17 263.182V198.331C199.17 196.317 197.536 194.684 195.522 194.684Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path d="M65.8203 207.248V255.886" stroke="black" strokeWidth="0.810633" strokeLinecap="square" />
      <path d="M90.9502 207.248V255.886" stroke="black" strokeWidth="0.810633" strokeLinecap="square" />
      <path d="M115.269 207.248V255.886" stroke="black" strokeWidth="0.810633" strokeLinecap="square" />
      <path d="M140.398 207.248V255.886" stroke="black" strokeWidth="0.810633" strokeLinecap="square" />
      <path d="M164.718 207.248V255.886" stroke="black" strokeWidth="0.810633" strokeLinecap="square" />
      <path d="M189.848 207.248V255.886" stroke="black" strokeWidth="0.810633" strokeLinecap="square" />
      <path d="M65.8203 258.318H69.8735" stroke="#CDD6E6" strokeWidth="1.62127" />
      <path d="M90.9502 258.318H95.0034" stroke="#CDD6E6" strokeWidth="1.62127" />
      <path d="M115.269 258.318H119.322" stroke="#CDD6E6" strokeWidth="1.62127" />
      <path d="M140.398 258.318H144.452" stroke="#CDD6E6" strokeWidth="1.62127" />
      <path d="M164.718 258.318H168.771" stroke="#CDD6E6" strokeWidth="1.62127" />
      <path d="M189.848 258.318H193.901" stroke="#CDD6E6" strokeWidth="1.62127" />
      <path d="M65.8203 202.385H69.8735" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M90.1396 202.385H94.1928" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M114.458 202.385H118.511" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M139.588 202.385H143.641" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M163.907 202.385H167.96" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M189.036 202.385H193.089" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M65.8203 204.006H72.3054" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M90.1396 204.006H96.6247" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M114.458 204.006H120.943" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M139.588 204.006H146.073" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M163.907 204.006H170.392" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M189.036 204.006H195.521" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path d="M65.8203 202.385H69.8735" stroke="#CDD6E6" strokeWidth="0.810633" />
      <path
        d="M65.8252 233.212C67.5227 234.519 72.4416 236.932 78.54 236.135C86.164 235.139 91.7501 231.691 102.416 225.778C113.08 219.865 122.74 219.847 130.367 220.816C137.995 221.784 151.734 228.634 160.893 233.036C170.052 237.439 180.225 240.367 189.883 239.367"
        stroke="#FFD400"
        strokeWidth="1.62127"
        strokeLinecap="round"
      />
      <path
        d="M65.8203 227.505C77.5745 217.381 92.2988 216.698 101.488 228.325C107.469 235.892 113.176 239.282 124.451 238.828C135.726 238.376 141.486 229.154 147.903 223.881C152.864 219.804 171.608 205.627 189.847 228.325"
        stroke="#0098D9"
        strokeWidth="1.62127"
        strokeLinecap="round"
      />
      <path
        d="M36.4352 195.697H30.3555V195.022H36.4352V195.697ZM32.0443 191.982H30.6933V194.346H32.0443V191.982ZM34.0708 189.617H32.7198V194.346H34.0708V189.617ZM36.0972 190.63H34.7464V194.346H36.0972V190.63Z"
        fill="white"
      />
      <path
        d="M35.557 169.885L36.9885 167.41L37.573 167.748L35.8066 170.804L33.6079 169.537L31.9968 172.324H37.5835V173H30.8281V166.92H31.5036V171.831L33.3614 168.608L35.557 169.885Z"
        fill="#010203"
      />
      <mask
        id="mask0_16256_7004"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="30"
        y="211"
        width="9"
        height="9"
      >
        <path d="M38.2587 211.303H30.1523V219.409H38.2587V211.303Z" fill="white" />
      </mask>
      <g mask="url(#mask0_16256_7004)">
        <path
          d="M31.165 213.801V212.518C31.165 212.465 31.1864 212.413 31.2244 212.375C31.2624 212.337 31.314 212.315 31.3677 212.315H33.3268C33.3805 212.315 33.4321 212.337 33.47 212.375C33.508 212.413 33.5294 212.465 33.5294 212.518V213.801C33.5294 213.856 33.508 213.907 33.47 213.945C33.4321 213.983 33.3805 214.004 33.3268 214.004H31.3677C31.314 214.004 31.2624 213.983 31.2244 213.945C31.1864 213.907 31.165 213.856 31.165 213.801ZM34.8804 218.193V216.909C34.8804 216.855 34.9018 216.804 34.9398 216.766C34.9778 216.728 35.0293 216.707 35.0828 216.707H37.0421C37.0956 216.707 37.1475 216.728 37.1856 216.766C37.2237 216.804 37.2448 216.855 37.2448 216.909V218.193C37.2448 218.246 37.2237 218.298 37.1856 218.336C37.1475 218.374 37.0956 218.395 37.0421 218.395H35.0828C35.0293 218.395 34.9778 218.374 34.9398 218.336C34.9018 218.298 34.8804 218.246 34.8804 218.193ZM34.8804 215.491V212.518C34.8804 212.465 34.9018 212.413 34.9398 212.375C34.9778 212.337 35.0293 212.315 35.0828 212.315H37.0421C37.0956 212.315 37.1475 212.337 37.1856 212.375C37.2237 212.413 37.2448 212.465 37.2448 212.518V215.491C37.2448 215.544 37.2237 215.596 37.1856 215.633C37.1475 215.671 37.0956 215.693 37.0421 215.693H35.0828C35.0293 215.693 34.9778 215.671 34.9398 215.633C34.9018 215.596 34.8804 215.544 34.8804 215.491ZM31.165 218.193V215.22C31.165 215.166 31.1864 215.115 31.2244 215.077C31.2624 215.039 31.314 215.017 31.3677 215.017H33.3268C33.3805 215.017 33.4321 215.039 33.47 215.077C33.508 215.115 33.5294 215.166 33.5294 215.22V218.193C33.5294 218.246 33.508 218.298 33.47 218.336C33.4321 218.374 33.3805 218.395 33.3268 218.395H31.3677C31.314 218.395 31.2624 218.374 31.2244 218.336C31.1864 218.298 31.165 218.246 31.165 218.193Z"
          stroke="#010203"
          strokeWidth="0.506646"
        />
      </g>
      <path
        d="M210.113 152.53H159.043C157.029 152.53 155.396 154.163 155.396 156.178V182.118C155.396 184.133 157.029 185.766 159.043 185.766H210.113C212.128 185.766 213.761 184.133 213.761 182.118V156.178C213.761 154.163 212.128 152.53 210.113 152.53Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M176.702 170.524V169.31H178.836V167.346H180.073V169.31H182.195V170.524H180.073V172.487H178.836V170.524H176.702ZM186.209 173.339C185.687 173.339 185.214 173.248 184.791 173.066C184.367 172.877 184.026 172.59 183.769 172.204C183.511 171.818 183.375 171.334 183.361 170.751H184.791C184.798 171.137 184.923 171.462 185.165 171.727C185.415 171.984 185.763 172.113 186.209 172.113C186.632 172.113 186.958 171.995 187.185 171.761C187.412 171.527 187.526 171.232 187.526 170.876C187.526 170.46 187.374 170.146 187.072 169.934C186.777 169.714 186.394 169.605 185.926 169.605H185.335V168.413H185.937C186.323 168.413 186.644 168.322 186.902 168.141C187.159 167.959 187.287 167.691 187.287 167.335C187.287 167.04 187.188 166.806 186.992 166.631C186.803 166.45 186.538 166.359 186.198 166.359C185.827 166.359 185.536 166.469 185.324 166.688C185.12 166.908 185.006 167.176 184.984 167.494H183.565C183.595 166.76 183.849 166.182 184.325 165.758C184.809 165.334 185.433 165.122 186.198 165.122C186.743 165.122 187.2 165.22 187.571 165.417C187.949 165.606 188.233 165.86 188.422 166.178C188.618 166.495 188.717 166.847 188.717 167.233C188.717 167.68 188.593 168.057 188.343 168.368C188.1 168.67 187.798 168.874 187.435 168.981C187.881 169.071 188.244 169.291 188.524 169.639C188.804 169.979 188.944 170.411 188.944 170.933C188.944 171.371 188.838 171.772 188.627 172.136C188.414 172.499 188.105 172.79 187.696 173.01C187.295 173.229 186.799 173.339 186.209 173.339ZM194.002 173.202V171.727H190.087V170.57L193.809 165.258H195.455V170.467H196.499V171.727H195.455V173.202H194.002ZM191.641 170.467H194.093V166.858L191.641 170.467ZM201.272 173.202V171.727H197.357V170.57L201.08 165.258H202.725V170.467H203.769V171.727H202.725V173.202H201.272ZM198.912 170.467H201.363V166.858L198.912 170.467Z"
        fill="black"
      />
      <path
        d="M167.96 165.906V166.717H170.629L167.149 170.197L165.409 168.457C165.333 168.381 165.23 168.338 165.123 168.338C165.015 168.338 164.912 168.381 164.836 168.457L160.664 172.629L161.237 173.202L165.123 169.317L166.862 171.057C166.938 171.132 167.041 171.175 167.149 171.175C167.257 171.175 167.36 171.132 167.436 171.057L171.202 167.29V169.959H172.013V165.906H167.96Z"
        fill="#010203"
      />
      <mask
        id="mask1_16256_7004"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="58"
        y="165"
        width="9"
        height="9"
      >
        <path d="M66.6317 165.097H58.5254V173.203H66.6317V165.097Z" fill="white" />
      </mask>
      <g mask="url(#mask1_16256_7004)">
        <path
          d="M62.9162 165.789V166.813C64.0608 166.978 64.9427 167.958 64.9427 169.15C64.9427 169.454 64.8819 169.741 64.7733 170.008L65.6585 170.525C65.8474 170.106 65.956 169.64 65.956 169.15C65.956 167.401 64.6217 165.958 62.9162 165.789ZM62.5781 171.515C61.9515 171.515 61.35 171.265 60.9066 170.821C60.4632 170.378 60.2135 169.777 60.2135 169.15C60.2135 167.958 61.0955 166.978 62.2401 166.813V165.789C60.5313 165.958 59.2002 167.397 59.2002 169.15C59.2002 170.046 59.5561 170.905 60.19 171.538C60.5037 171.852 60.8758 172.101 61.286 172.271C61.6953 172.44 62.1347 172.528 62.5781 172.528C63.6927 172.528 64.6825 171.984 65.297 171.147L64.4191 170.629C64.1994 170.907 63.9197 171.131 63.6011 171.284C63.2817 171.438 62.9324 171.516 62.5781 171.515Z"
          fill="#010203"
        />
      </g>
      <mask
        id="mask2_16256_7004"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="205"
        y="194"
        width="79"
        height="74"
      >
        <path
          d="M279.018 194.277H209.303C207.065 194.277 205.25 196.092 205.25 198.331V263.181C205.25 265.42 207.065 267.234 209.303 267.234H279.018C281.256 267.234 283.071 265.42 283.071 263.181V198.331C283.071 196.092 281.256 194.277 279.018 194.277Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask2_16256_7004)">
        <path
          d="M279.017 194.683H209.302C207.287 194.683 205.654 196.316 205.654 198.33V263.181C205.654 265.196 207.287 266.829 209.302 266.829H279.017C281.031 266.829 282.664 265.196 282.664 263.181V198.33C282.664 196.316 281.031 194.683 279.017 194.683Z"
          fill="white"
          stroke="black"
          strokeWidth="0.810633"
        />
        <path
          d="M207.503 238.129C206.145 236.2 205.602 235.717 201.254 234.27C198.054 233.205 192.543 234.125 192.345 237.491L190.907 261.885C190.771 264.19 192.585 266.142 194.893 266.177L319.09 268.003C320.776 268.028 322.316 267.011 322.827 265.403C324.459 260.273 325.729 253.095 321.892 252.122C316.186 250.675 309.394 227.997 306.133 227.997C302.872 227.997 302.872 219.313 296.351 219.795C289.831 220.278 288.2 245.85 283.853 245.85C279.505 245.85 280.321 253.57 273.799 253.57C267.279 253.57 266.464 206.768 261.573 207.251C256.682 207.733 254.509 227.997 249.074 227.997C243.641 227.997 247.987 245.367 239.565 245.85C231.141 246.332 232.772 211.11 227.338 211.11C221.904 211.11 219.187 253.57 215.654 253.57C212.122 253.57 208.862 240.06 207.503 238.129Z"
          stroke="#CDD6E6"
          strokeWidth="0.810633"
        />
        <path
          d="M279.017 194.683H209.302C207.287 194.683 205.654 196.316 205.654 198.33V263.181C205.654 265.196 207.287 266.829 209.302 266.829H279.017C281.031 266.829 282.664 265.196 282.664 263.181V198.33C282.664 196.316 281.031 194.683 279.017 194.683Z"
          stroke="black"
          strokeWidth="0.810633"
        />
      </g>
      <path
        d="M247.402 233.189C249.193 233.189 250.644 231.737 250.644 229.947C250.644 228.156 249.193 226.704 247.402 226.704C245.611 226.704 244.159 228.156 244.159 229.947C244.159 231.737 245.611 233.189 247.402 233.189Z"
        fill="#0098D9"
      />
      <path
        d="M219.03 245.348C220.82 245.348 222.272 243.897 222.272 242.106C222.272 240.315 220.82 238.863 219.03 238.863C217.239 238.863 215.787 240.315 215.787 242.106C215.787 243.897 217.239 245.348 219.03 245.348Z"
        fill="#222236"
      />
      <path
        d="M196.332 281.827C198.123 281.827 199.575 280.375 199.575 278.584C199.575 276.794 198.123 275.342 196.332 275.342C194.542 275.342 193.09 276.794 193.09 278.584C193.09 280.375 194.542 281.827 196.332 281.827Z"
        fill="#FFC36A"
      />
      <path
        d="M163.096 304.524C164.887 304.524 166.339 303.072 166.339 301.282C166.339 299.491 164.887 298.039 163.096 298.039C161.305 298.039 159.854 299.491 159.854 301.282C159.854 303.072 161.305 304.524 163.096 304.524Z"
        fill="#FFC36A"
      />
      <path
        d="M173.909 1.34766H316.032C321.556 1.34766 326.034 5.82527 326.034 11.3493V100.833C326.034 106.357 321.556 110.835 316.032 110.835H173.909C168.385 110.835 163.907 106.357 163.907 100.833V11.3493C163.907 5.82527 168.385 1.34766 173.909 1.34766Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M317.306 23.11H174.022C172.65 23.11 171.535 21.9954 171.535 20.6229C171.535 19.2504 172.65 18.1357 174.022 18.1357H317.302C318.675 18.1357 319.789 19.2504 319.789 20.6229C319.789 21.9954 318.675 23.11 317.302 23.11H317.306Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M306.576 13.7868H299.057C297.685 13.7868 296.57 12.6721 296.57 11.2997C296.57 9.9272 297.685 8.8125 299.057 8.8125H306.576C307.948 8.8125 309.063 9.9272 309.063 11.2997C309.063 12.6721 307.948 13.7868 306.576 13.7868Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M291.574 13.7868H282.349C280.977 13.7868 279.862 12.6721 279.862 11.2997C279.862 9.9272 280.977 8.8125 282.349 8.8125H291.574C292.946 8.8125 294.061 9.9272 294.061 11.2997C294.061 12.6721 292.946 13.7868 291.574 13.7868Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M317.233 15.1323C319.346 14.264 320.355 11.8472 319.486 9.73418C318.618 7.62119 316.202 6.61219 314.088 7.48046C311.976 8.34881 310.967 10.7657 311.835 12.8787C312.703 14.9917 315.12 16.0007 317.233 15.1323Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M314.314 21.1459L315.657 19.8037L316.999 21.1459"
        stroke="black"
        strokeWidth="0.810633"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M172.502 11.0273H178.973" stroke="black" strokeWidth="0.810633" />
      <path d="M180.134 11.0273H186.605" stroke="black" strokeWidth="0.810633" />
      <path d="M187.766 11.0273H194.234" stroke="black" strokeWidth="0.810633" />
      <path
        d="M198.114 30.5879H175.168C173.154 30.5879 171.521 32.2211 171.521 34.2357V45.5371C171.521 47.5518 173.154 49.1849 175.168 49.1849H198.114C200.129 49.1849 201.762 47.5518 201.762 45.5371V34.2357C201.762 32.2211 200.129 30.5879 198.114 30.5879Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M314.007 30.5879H212.875C210.861 30.5879 209.228 32.2211 209.228 34.2357V82.6891C209.228 84.7037 210.861 86.3369 212.875 86.3369H314.007C316.021 86.3369 317.655 84.7037 317.655 82.6891V34.2357C317.655 32.2211 316.021 30.5879 314.007 30.5879Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M198.114 54.9854H175.168C173.154 54.9854 171.521 56.6185 171.521 58.6332V69.9346C171.521 71.9492 173.154 73.5824 175.168 73.5824H198.114C200.129 73.5824 201.762 71.9492 201.762 69.9346V58.6332C201.762 56.6185 200.129 54.9854 198.114 54.9854Z"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path
        d="M273.241 60.2708C273.241 63.4671 272.293 66.5913 270.517 69.2494C268.741 71.9074 266.217 73.9786 263.264 75.2019C260.31 76.4251 257.061 76.7453 253.926 76.1219C250.79 75.4986 247.911 73.9592 245.651 71.6983C243.39 69.4383 241.851 66.5589 241.227 63.4234C240.604 60.2886 240.924 57.0388 242.147 54.0857C243.371 51.1325 245.442 48.6082 248.1 46.8321C250.758 45.056 253.882 44.1084 257.079 44.1084V60.2708H273.241Z"
        fill="#FFD400"
      />
      <path
        d="M276.585 57.4836C276.585 55.4343 276.167 53.4053 275.355 51.5117C274.542 49.6188 273.352 47.8987 271.851 46.4493C270.351 45.0007 268.569 43.8512 266.608 43.0665C264.647 42.2826 262.546 41.8789 260.423 41.8789V57.4836H276.585Z"
        fill="#0098D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.768 43.501C302.187 43.501 299.283 46.4047 299.283 49.986V64.1721V69.2167C299.283 69.9309 300.14 70.2956 300.656 69.8012L306.284 64.3983C306.435 64.2532 306.636 64.1721 306.846 64.1721H334.14C337.722 64.1721 340.625 61.2684 340.625 57.6871V49.986C340.625 46.4047 337.722 43.501 334.14 43.501H305.768Z"
        fill="#222236"
      />
      <path d="M309.821 56.6855H330.087" stroke="white" strokeWidth="1.62127" strokeLinecap="round" />
      <path d="M309.821 51.0107H321.981" stroke="white" strokeWidth="1.62127" strokeLinecap="round" />
      <path
        d="M249.834 327.628H207.681C203.428 327.628 199.98 331.076 199.98 335.329V390.951C199.98 395.204 203.428 398.652 207.681 398.652H249.834C254.088 398.652 257.535 395.204 257.535 390.951V335.329C257.535 331.076 254.088 327.628 249.834 327.628Z"
        fill="white"
        stroke="black"
        strokeWidth="0.810633"
      />
      <path d="M211.36 355.844V370.996" stroke="#FFD400" strokeWidth="4.8638" />
      <path d="M220.34 353.037V370.996" stroke="#FFD400" strokeWidth="4.8638" />
      <path d="M228.758 348.548V370.996" stroke="#FFD400" strokeWidth="4.8638" />
      <path d="M237.737 353.037V370.996" stroke="#FFD400" strokeWidth="4.8638" />
      <path d="M246.154 348.548V370.996" stroke="#FFD400" strokeWidth="4.8638" />
      <path d="M207.993 378.854H217.533" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M218.656 378.854H228.197" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M229.319 378.854H238.861" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M239.981 378.854H249.522" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M207.993 385.026H217.533" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M218.656 385.026H228.197" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M229.319 385.026H238.861" stroke="#CDD6E6" strokeWidth="4.05317" />
      <path d="M239.981 385.026H249.522" stroke="#CDD6E6" strokeWidth="4.05317" />
    </svg>
  )
}
