import { FC } from 'react'

export const ArrowRight: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4.16602 9.99935H15.8327M15.8327 9.99935L9.99935 4.16602M15.8327 9.99935L9.99935 15.8327"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
