import React, { useState } from 'react'

import { useUpdateCollection } from '@features/collections/api/api'
import { RenameCollection } from '@features/collections/components/rename-collection/rename-collection'
import { ICollection } from '@features/collections/types'

import { ModalUpdating, renameCollectionToast } from '@shared/components'

export interface WithCollectionRenameProps {
  renameCollection: (Collection: ICollection, showModal?: boolean) => void
}

enum ProcessSteps {
  Inactive,
  AssignName,
  RenameOperation,
  Completed,
}

export const withCollectionRename = <T,>(WrappedComponent: React.ComponentType<T & WithCollectionRenameProps>) => {
  return (props: T) => {
    const [step, setStep] = useState(ProcessSteps.Inactive)
    const [collection, setCollection] = useState<ICollection | null>(null)

    const closeProcess = () => setStep(ProcessSteps.Inactive)

    const updateCollectionQuery = useUpdateCollection({
      onSuccess: () => {
        setStep(ProcessSteps.Completed)
        renameCollectionToast()
      },
    })

    const renameCollection = async (collection: ICollection, showModal?: boolean) => {
      setCollection(collection)
      if (showModal) {
        setStep(ProcessSteps.AssignName)
      }
    }

    const onRename = async (name: string) => {
      if (!collection) {
        return
      }

      updateCollectionQuery.mutate({
        id: collection.id,
        name,
        updatedAt: collection.updatedAt,
      })
    }

    return (
      <>
        <WrappedComponent {...props} renameCollection={renameCollection} />

        {collection && (
          <>
            <RenameCollection
              type={collection.type}
              visible={step === ProcessSteps.AssignName}
              originalName={collection.name}
              toggleVisibility={closeProcess}
              onRename={onRename}
              processing={updateCollectionQuery.isLoading}
            />
            <ModalUpdating visible={step === ProcessSteps.RenameOperation} title={'Renaming the collection'} />
          </>
        )}
      </>
    )
  }
}
