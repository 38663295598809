import classNames from 'classnames'
import { FC } from 'react'

import { Tooltip } from '@shared/components'

import styles from './icon-label.module.css'

interface IIconLabel {
  Icon?: any
  label: string
  hideLabel: boolean
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const IconLabel: FC<IIconLabel> = ({ Icon, label, active = false, disabled = false, onClick, hideLabel }) => {
  const containerStyles = classNames(
    styles.container,
    active && styles.active,
    disabled && styles.disabled,
    hideLabel && styles.centered
  )

  return (
    <Tooltip show={hideLabel} title={label} position="right" secondary>
      <div className={containerStyles} onClick={onClick}>
        {Icon && <Icon />}
        {!hideLabel && <span className={styles.label}>{label}</span>}
      </div>
    </Tooltip>
  )
}
