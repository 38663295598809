import { FC, PropsWithChildren } from 'react'

import { TickCircleOutline } from '@assets/icons/ticks'

import { Colors } from '@shared/constants'

import styles from './confirm-box.module.css'

interface ConfirmBoxProps extends PropsWithChildren {
  text: string
}

export const ConfirmBox: FC<ConfirmBoxProps> = ({ text, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.tick}>
        <TickCircleOutline size={20} color={Colors.success700} />
        {text}
      </div>
      {children}
    </div>
  )
}
