import { useMatch } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

export const useRoutes = () => {
  const isBase = Boolean(useMatch(Paths.BASE))
  const isSettings = Boolean(useMatch({ path: Paths.SETTINGS, end: false }))
  const isDataSource = Boolean(useMatch({ path: Paths.DATA_SOURCES, end: false }))
  const isDestination = Boolean(useMatch({ path: Paths.DESTINATIONS, end: false }))
  const isCollections = Boolean(useMatch({ path: Paths.COLLECTIONS, end: false }))
  const isHistoricalRuns = Boolean(useMatch({ path: Paths.HISTORICAL_RUNS, end: false }))
  const isOrganizationSSO = Boolean(useMatch({ path: Paths.SETTINGS_ORGANIZATION_SSO, end: false }))
  const isOrganizationDirectorySync = Boolean(
    useMatch({ path: Paths.SETTINGS_ORGANIZATION_DIRECTORY_SYNC, end: false })
  )

  return {
    isBase,
    isSettings,
    isDataSource,
    isCollections,
    isHistoricalRuns,
    isDestination,
    isOrganizationSSO,
    isOrganizationDirectorySync,
  }
}
