import { Children, FC, PropsWithChildren } from 'react'

import styles from './overlaying-components.module.css'

interface OverlayingComponentsProps extends PropsWithChildren {
  overlaySize?: number
  maxComponents?: number
  renderMoreComponents?: (hiddenCount: number) => JSX.Element
}

const defaultOverlay = 8
const defaultMaxComponents = 5

export const OverlayingComponents: FC<OverlayingComponentsProps> = ({
  children,
  maxComponents = defaultMaxComponents,
  overlaySize = defaultOverlay,
  renderMoreComponents,
}) => {
  const childArray = Children.toArray(children)

  const hiddenComponentsCount = childArray.length > maxComponents ? childArray.length - maxComponents : 0
  const shownComponents = childArray.slice(0, childArray.length < maxComponents ? childArray.length : maxComponents)

  return (
    <div className={styles.container}>
      {shownComponents.map((child, index) => {
        return (
          <div key={index} className="flex justify-center" style={{ marginLeft: index && -overlaySize }}>
            {child}
          </div>
        )
      })}
      {hiddenComponentsCount > 0 && renderMoreComponents && (
        <div className="flex justify-center" style={{ marginLeft: -defaultOverlay }}>
          {renderMoreComponents(hiddenComponentsCount)}
        </div>
      )}
    </div>
  )
}
