import { Editor } from '@tiptap/react'
import clsx from 'clsx'
import { PlusIcon } from 'lucide-react'
import { EventHandler, FC, MouseEvent, useContext } from 'react'

import { IIcon } from '@assets/types'

import { isEmailDestination } from '@features/destinations'
import { useEditorMenuBarSettings } from '@features/emails/components/templates/hooks/use-editor-menu-bar-settings'
import { EmailTemplateEditorContext } from '@features/emails/components/templates/providers/email-template-editor-provider'
import { EmailTemplateSelectContext } from '@features/emails/components/templates/providers/email-template-select-provider'

import { Button, ColorPicker, ImageWithFallback, Label, SelectInput, Tooltip, errorToast } from '@shared/components'
import { captureEvent } from '@shared/product-tooling/posthog/posthog'

import { AddImageLinkModal } from './components/add-image-link-modal'

import styles from './email-template-rich-text-menu-bar.module.css'

interface SyncOption {
  value: string
  label?: string
  image?: string
}

const insertVisOption: SyncOption = {
  value: 'INSERT_A_VISUALIZATION',
}

const MenuBarButton = (props: {
  icon?: FC<IIcon> | null
  text?: string
  onClick?: EventHandler<MouseEvent>
  disabled?: boolean
  isActive?: boolean
  hide?: boolean
}) => {
  return (
    <Button
      className={clsx(props.hide && 'hidden')}
      iconLeading={props.icon ? <props.icon size={13} /> : null}
      text={props.text}
      onClick={props.onClick}
      disabled={props.disabled}
      secondaryColor={props.isActive}
      tertiaryColor={!props.isActive}
    />
  )
}

const MenuBarGroup = ({ children, hide }: { children: React.ReactNode; hide?: boolean }) => {
  return (
    <div
      className={clsx('inline-block flex gap-1', {
        hidden: hide,
      })}
      style={{ flexFlow: 'wrap' }}
    >
      {children}
    </div>
  )
}

export const EmailTemplateMenuBar = ({
  editor,
  toggleHTMLEditorMode,
  isHTMLEditorMode = false,
}: {
  editor: Editor
  toggleHTMLEditorMode: () => void
  isHTMLEditorMode?: boolean
}) => {
  const { targetId, syncs, destinationType } = useContext(EmailTemplateEditorContext)
  const { toggleOpenSaveChangesModal } = useContext(EmailTemplateSelectContext)
  const { settingGroups, isAddImageLinkModalOpen, closeAddImageLinkModal } = useEditorMenuBarSettings({
    editor,
    isHTMLEditorMode,
    toggleHTMLEditorMode,
  })

  if (!editor) return null

  const syncOptions: SyncOption[] = syncs.map(sync => ({
    value: sync.id,
    label: sync.name,
    image: sync.imageUrl,
  }))

  syncOptions.push(insertVisOption)

  return (
    <>
      <AddImageLinkModal open={isAddImageLinkModalOpen} closeModal={closeAddImageLinkModal} editor={editor} />
      <div className={clsx('flex gap-1 sticky bg-white', styles.menuBarContainer)}>
        <div className="flex flex-col gap-1 py-1">
          <div className={clsx('flex gap-1')} style={{ flexFlow: 'wrap' }}>
            {settingGroups.map((group, groupIndex) => (
              <MenuBarGroup key={groupIndex} hide={group.hide}>
                {group.items.map((item, itemIndex) =>
                  item.type === 'select' ? (
                    <SelectInput
                      key={`${groupIndex}_${itemIndex}`}
                      options={item.options}
                      placeholder={item.placeholder}
                      onChange={item.onChange}
                      value={item.value}
                    />
                  ) : (
                    <Tooltip title={item.tooltip} show={!!item.tooltip} key={`${groupIndex}_${itemIndex}`}>
                      <span>
                        {item.type === 'color' ? (
                          <ColorPicker color={item.color} onColorChange={item.onColorChange}>
                            <MenuBarButton icon={item.icon} />
                          </ColorPicker>
                        ) : (
                          <MenuBarButton
                            icon={item.icon}
                            onClick={() => {
                              void captureEvent('email_template_menu_bar_button', {
                                button: item.tooltip,
                              })
                              item.onClick?.()
                            }}
                            disabled={item.disabled}
                            isActive={item.isActive}
                          />
                        )}
                      </span>
                    </Tooltip>
                  )
                )}
              </MenuBarGroup>
            ))}
          </div>
          {isEmailDestination({ type: destinationType }) && !isHTMLEditorMode ? (
            <div className="flex justify-end mb-1.5">
              <Tooltip title="You need to update visualizations before inserting them" className="z-[100]">
                <SelectInput<SyncOption>
                  containerClassName="text-primary-700 hover:text-primary-800"
                  options={syncOptions}
                  isOptionDisabled={option => option.value !== insertVisOption.value && !option.image}
                  formatOptionLabel={option => formatVisOption(option)}
                  placeholder={'Insert a visualization'}
                  value={null}
                  onChange={option => {
                    if (option.value === insertVisOption.value) {
                      toggleOpenSaveChangesModal(true)
                    } else if (option.image) {
                      const sync = syncs.find(sync => sync.id === option.value)
                      if (sync?.presentationId === targetId) {
                        editor
                          .chain()
                          .focus()
                          .setImage({
                            src: option.image,
                            /**
                             * This is a custom attribute but I couldn't find official support from TipTap to update the types.
                             * There's an open issue here: https://github.com/ueberdosis/tiptap/issues/2969
                             */
                            // @ts-ignore
                            'data-sync-id': option.value,
                          })
                          .run()
                      } else {
                        console.error('Sync is not compatible with this destination', {
                          syncId: option.value,
                          destinationId: targetId,
                        })
                        errorToast('An error occurred while inserting the visualization')
                      }
                    }
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

const formatVisOption = (option: SyncOption) => {
  return option.value === insertVisOption.value ? (
    <span className="text-primary-700 flex gap-1 items-center">
      <PlusIcon />
      <Label sm semibold className="text-primary-700">
        Add a visualization
      </Label>
    </span>
  ) : (
    <div className="h-full w-full p-1 flex gap-2 items-center">
      <ImageWithFallback
        src={option.image}
        imageSize={{ width: 50, height: 40 }}
        placeholderSize={{ width: 50, height: 40 }}
        alt={option.label}
      />
      <Label xs semibold>
        {option.label}
      </Label>
    </div>
  )
}
