export class ApiError extends Error {
  code: string

  statusCode: number

  message: string

  metadata: Record<string, any>

  constructor(message: string, code: string, statusCode: number, metadata: Record<string, any> = {}) {
    super(message)

    this.code = code
    this.statusCode = statusCode
    this.message = message
    this.metadata = metadata
  }
}
