import { isIP } from 'is-ip'

export const validateURLAndGetError = (value: string): string | undefined => {
  let url
  try {
    url = new URL(value)
  } catch {
    return `Please enter a valid URL`
  }
  if (isIP(url.hostname)) {
    return `You can't use an IP as an URL`
  }
  if (url.protocol !== 'https:') {
    return `You need to use https`
  }
  if (url.pathname && url.pathname !== '/') {
    return `Please remove the pathname: "${url.pathname}"`
  }
}
