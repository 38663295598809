import { FC } from 'react'
import { OptionProps, SingleValueProps, components } from 'react-select'

import { useGetSpreadsheetData } from '@features/data-sources'
import { ISheetInfo } from '@features/data-sources/types'
import { IDataSource } from '@features/data-sources/types/types'

import { SelectInput, primaryStyle } from '@shared/components/select-input/select-input'

import styles from './select-data-source.module.css'

interface ISelectSheet {
  disabled?: boolean
  spreadsheetId: string
  sheet: ISheetInfo | null
  handleSheetChange: (sheet: ISheetInfo | null) => void
}

export const SelectSheet: FC<ISelectSheet> = ({ disabled = false, sheet, spreadsheetId, handleSheetChange }) => {
  const { data, isLoading } = useGetSpreadsheetData(spreadsheetId)
  return (
    <div className={styles.container}>
      <SelectInput<ISheetInfo>
        label="Sheet"
        placeholder={isLoading ? 'Loading sheets...' : 'No sheet selected'}
        isMulti={false}
        isLoading={isLoading}
        isDisabled={disabled}
        options={data?.sheets}
        value={sheet}
        isSearchable={true}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.sheetId.toString()}
        className={styles.select}
        styles={primaryStyle(disabled)}
        onChange={handleSheetChange}
        Option={Option}
        SingleValue={SingleValue}
        dataTestId="datasource"
      />
    </div>
  )
}

const SingleValue = (props: SingleValueProps<IDataSource>) => {
  return <components.SingleValue {...props} />
}

const Option = (props: OptionProps<IDataSource>) => {
  return <components.Option {...props} />
}
