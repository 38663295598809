import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import { Collapse } from 'react-collapse'

import styles from './collapsable.module.css'

interface ICollapsable extends PropsWithChildren {
  open: boolean
  className?: string
  outerClassName?: string
  hideWhenClosed?: boolean
}

export const Collapsable: FC<ICollapsable> = ({ children, open, outerClassName, className, hideWhenClosed }) => {
  const outerClass = classNames(styles.collapse, outerClassName, hideWhenClosed && !open && styles.hidden)

  return (
    <Collapse isOpened={open} theme={{ collapse: outerClass, content: className }}>
      {children}
    </Collapse>
  )
}
