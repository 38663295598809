import { destinationModalsAssetsUrlsBase } from '@assets'

import { destinationHandlers } from '@features/destinations/destination-handlers'
import { EDestinationType } from '@features/destinations/types'

import { TutorialModal } from '@shared/components/tutorial-modal/tutorial-modal'

interface RefreshSlidesExplainerModalProps {
  visible: boolean
  toggle: () => void
  type: EDestinationType
}

export const RefreshSlidesExplainerModal = ({ visible, toggle, type }: RefreshSlidesExplainerModalProps) => {
  const handle = destinationHandlers(type)
  const cloudApp = handle.getCloudAppTitle()

  const handleDismiss = () => {
    toggle()
  }

  return (
    <TutorialModal
      visible={visible}
      onDismiss={handleDismiss}
      contents={[
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/refresh-explainer-1.png`,
          title: 'Automatic sync',
          description: `Rollstack continuously syncs with your ${cloudApp} to reflect any changes made there, including changing slides order, moving visualizations between slides, and cut & paste operations.`,
        },
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/refresh-explainer-2.png`,
          title: 'Manual refresh',
          description: `Sometimes, ${cloudApp} may take time to propagate these information to Rollstack. You can speed up things by manually clicking the refresh button in Rollstack.`,
        },
      ]}
    />
  )
}
