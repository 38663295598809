import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildUserManagementRoute } from '@pages/routes/paths'

import { ArrowRight } from '@assets'

import { useAddParentMetabaseDataSource, useUpdateMetabaseDataSource } from '@features/data-sources/api'
import { DataSourceTestStatus } from '@features/data-sources/components/connection-test/data-source-test-status'
import { useMetabaseConnectionTest } from '@features/data-sources/hooks/use-metabase-connection-test'
import { IMetabaseDataSource } from '@features/data-sources/types'
import { EDataSourceType } from '@features/data-sources/types/types'

import { ActionModal, ActionModalProps, Button, Input, updateDataSourceToast } from '@shared/components'
import { useBoolean } from '@shared/hooks'

import styles from './metabase-password-modal.module.css'

interface MetabasePasswordModalProps extends ActionModalProps {
  dataSource: Pick<IMetabaseDataSource, 'name' | 'baseUrl' | 'autoProvision'> &
    Partial<Pick<IMetabaseDataSource, 'id' | 'username' | 'password' | 'embedSecret'>>
  toggle: () => void
}

export const MetabasePasswordModal: FC<MetabasePasswordModalProps> = ({ dataSource, toggle, ...modalProps }) => {
  const { id, name, baseUrl, embedSecret, autoProvision } = dataSource

  const navigate = useNavigate()
  const [username, setUsername] = useState(dataSource.username || '')
  const [password, setPassword] = useState(dataSource.password || '')
  const [isTesting, toggleTesting] = useBoolean()

  const { mutateAsync: updateDataSource, isLoading: isUpdateLoading } = useUpdateMetabaseDataSource()
  const { mutateAsync: addMetabaseDataSource, isLoading: isAddLoading } = useAddParentMetabaseDataSource()
  const { iframeDashboardRef, currentTest, stage, connectionTestsMessages, testConnection } =
    useMetabaseConnectionTest()

  const isLoading = isTesting || isUpdateLoading || isAddLoading

  const saveDataSource = async () => {
    if (!id) {
      const { data } = await addMetabaseDataSource({
        name,
        baseUrl,
        embedSecret,
        username,
        password,
        type: EDataSourceType.Metabase,
        autoProvision,
      })
      updateDataSourceToast(false)
      navigate(buildUserManagementRoute(EDataSourceType.Metabase, data.id))
    } else {
      await updateDataSource({
        id,
        name,
        baseUrl,
        embedSecret,
        username,
        password,
        autoProvision,
      })
      updateDataSourceToast(true)
    }
    toggle()
  }

  const testConnectionAndSave = async () => {
    toggleTesting(true)
    const isConnectionSuccessful = await testConnection({
      baseUrl,
      username,
      password,
    })
    toggleTesting(false)

    if (isConnectionSuccessful) {
      await saveDataSource()
    }
  }

  return (
    <ActionModal
      {...modalProps}
      onBackgroundClick={toggle}
      btns={
        <>
          <Button text="Cancel" secondaryGray fullWidth onClick={toggle} />
          <Button
            fullWidth
            text="Test data source"
            onClick={testConnectionAndSave}
            iconTrailing={<ArrowRight />}
            disabled={!Boolean(password)}
            loading={isLoading}
          />
        </>
      }
    >
      <div className={styles.container}>
        <Input
          autoFocus
          label="Metabase username"
          placeholder="Username"
          name="username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <Input
          label="Metabase password"
          placeholder="Password"
          name="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          secret
        />
      </div>
      {stage && currentTest && (
        <DataSourceTestStatus
          stage={stage}
          currentTest={currentTest}
          connectionTestMessages={connectionTestsMessages}
        />
      )}
      <div id="dashboard" hidden ref={iframeDashboardRef} />
    </ActionModal>
  )
}
