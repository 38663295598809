import { FC } from 'react'

import { IIcon } from '@assets/types'

export const DuplicateIcon: FC<IIcon> = ({ className, color = 'currentColor' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
      <path
        d="M6.66667 6.66675V4.33341C6.66667 3.39999 6.66667 2.93328 6.84832 2.57676C7.00811 2.26316 7.26308 2.00819 7.57668 1.8484C7.9332 1.66675 8.39991 1.66675 9.33333 1.66675H15.6667C16.6001 1.66675 17.0668 1.66675 17.4233 1.8484C17.7369 2.00819 17.9919 2.26316 18.1517 2.57676C18.3333 2.93328 18.3333 3.39999 18.3333 4.33341V10.6667C18.3333 11.6002 18.3333 12.0669 18.1517 12.4234C17.9919 12.737 17.7369 12.992 17.4233 13.1518C17.0668 13.3334 16.6001 13.3334 15.6667 13.3334H13.3333M4.33333 18.3334H10.6667C11.6001 18.3334 12.0668 18.3334 12.4233 18.1518C12.7369 17.992 12.9919 17.737 13.1517 17.4234C13.3333 17.0669 13.3333 16.6002 13.3333 15.6667V9.33341C13.3333 8.39999 13.3333 7.93328 13.1517 7.57676C12.9919 7.26316 12.7369 7.00819 12.4233 6.8484C12.0668 6.66675 11.6001 6.66675 10.6667 6.66675H4.33333C3.39991 6.66675 2.9332 6.66675 2.57668 6.8484C2.26308 7.00819 2.00811 7.26316 1.84832 7.57676C1.66667 7.93328 1.66667 8.39999 1.66667 9.33341V15.6667C1.66667 16.6002 1.66667 17.0669 1.84832 17.4234C2.00811 17.737 2.26308 17.992 2.57668 18.1518C2.9332 18.3334 3.39991 18.3334 4.33333 18.3334Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
