import { FC } from 'react'

import { AutoProvisioning } from '@pages/data-sources/common/auto-provisioning'

import { ArrowRight, DatabaseIcon, tableauAssetsUrlsBase } from '@assets'

import { ConfirmBox, InputBox, TestTableauConnectionModal, useTableauDataSourcesSettings } from '@features/data-sources'
import { isPublicTableau } from '@features/embedding'

import { Button, Input, PageContainer } from '@shared/components'

import styles from './tableau-data-sources-settings.module.css'

const tableau1Url = `${tableauAssetsUrlsBase}settings.png`
const tableau2Url = `${tableauAssetsUrlsBase}create_connected_app.png`
const tableau3Url = `${tableauAssetsUrlsBase}setup_connected_app.png`
const tableau4Url = `${tableauAssetsUrlsBase}copy_connected_app_details.png`
const tableau5Url = `${tableauAssetsUrlsBase}enable_connected_app.png`

export const TableauDataSourceSettings: FC = () => {
  const {
    state,
    isLoading,
    isUpdate,
    testModal,
    isFormValid,
    validationErrors,
    toggleTestModal,
    handleBack,
    handleConfirm,
    handleInputChange,
    toggleAutoProvision,
  } = useTableauDataSourcesSettings()

  const buttonText = isUpdate ? 'Update data source' : 'Continue'

  const guidedView = (
    <>
      <span>Choose a data source name and fill in your company’s Tableau base url:</span>
      <InputBox>
        <Input
          value={state.name}
          name="name"
          label="Data source name"
          placeholder="Data source name"
          onChange={e => handleInputChange(e)}
        />
        <Input
          name="baseUrl"
          value={state.baseUrl}
          placeholder="Tableau base url"
          onChange={e => handleInputChange(e)}
          label={isPublicTableau(state.baseUrl) ? 'Tableau public dashboard url' : 'Tableau base url'}
          description={
            'This is the URL you see when you go to your Tableau instance. Example: https://acme.online.tableau.com'
          }
          errorMessage={validationErrors.baseUrlError}
        />
        <Input
          value={state.siteName}
          name="siteName"
          onChange={e => handleInputChange(e)}
          label="Tableau Site name"
          placeholder="Tableau Site name"
          description="
                  Your tableau Site name. This can be found in the URL of your tableau instance: https://online.tableau.com/#/site/<site-name>/workbooks. (for example: acme). 
                  If you are using Tableau Server, you may leave this field empty.
                "
          info={!state.siteName ? `An empty site name means you are using Tableau Server` : undefined}
        />
      </InputBox>
      <span>In your Tableau homepage, go to Settings</span>
      <img src={tableau1Url} alt="Find the Setting panel at the bottom of your Tableau homepage" />
      <span>Click the “Connected Apps tab”, then “New Connected App”. Finally, select “Direct Trust”</span>
      <img src={tableau2Url} alt="Select Direct Trust when creating a new Connected App" />
      <span>
        Choose a Name, set Access level to{' '}
        <b>
          <i>All projects</i>
        </b>{' '}
        and set Domain allowlist to{' '}
        <b>
          <i>All domains</i>
        </b>
      </span>
      <img
        src={tableau3Url}
        alt="Set Access level to All projects, and Domain allowlist to All domain when setting up the Connected App"
      />
      <span>Copy these 3 values: Secret ID, Secret Value and Client ID</span>
      <img src={tableau4Url} alt="Copy the Secret ID, Secret Value and Client ID values" />
      <span>Paste the Connected App information here:</span>
      <InputBox>
        <Input
          value={state.secretId}
          name="secretId"
          placeholder="Secret ID"
          onChange={handleInputChange}
          label="Secret Id"
          secret
        />
        <Input
          value={state.secretValue}
          name="secretValue"
          placeholder="Secret Value"
          onChange={handleInputChange}
          label="Secret Value"
          secret
        />
        <Input
          value={state.clientId}
          name="clientId"
          placeholder="Client ID"
          onChange={handleInputChange}
          label="Client ID"
          secret
        />
      </InputBox>

      <span>Go back to the Connected Apps list view, and enable the previously created Connected App.</span>
      <img
        src={tableau5Url}
        alt="Enable the Connected App by clicking on the dots below in the Actions column, and clicking on Enable"
      />

      <AutoProvisioning toggleAutoProvision={toggleAutoProvision} state={state} />

      <ConfirmBox text="Click here to Test & Save your Connection.">
        <Button
          text={buttonText}
          loading={isLoading}
          disabled={isLoading || !isFormValid}
          onClick={handleConfirm}
          iconTrailing={<ArrowRight />}
        />
      </ConfirmBox>
    </>
  )

  return (
    <PageContainer hideBreadcrumb={false} back={handleBack}>
      <div className={styles.container}>{guidedView}</div>
      {testModal && (
        <TestTableauConnectionModal
          title="Test and save data source"
          subtitle="In order to properly test your data source connection, you need to provide your Tableau username"
          open={testModal}
          Icon={DatabaseIcon}
          dataSource={state}
          toggle={toggleTestModal}
        />
      )}
    </PageContainer>
  )
}
