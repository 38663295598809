import { AxiosResponse } from 'axios'
import React, { useState } from 'react'

import { GoogleIcon, MicrosoftIcon, SamlIcon } from '@assets'
import { RollstackLogoFull } from '@assets/icons/logos'

import { useSaml } from '@features/users'
import { useAuth0 } from '@features/users'
import { useVerifyTenantByEmail } from '@features/users/api/api-saml'
import { SSOConnection } from '@features/users/types/types-saml'

import { Input } from '@shared/components'
import { Button } from '@shared/components/button/button'

import { Footer } from './footer/footer'

export const Login = () => {
  const [ssoError, setSsoError] = useState<string>('')
  const [email, setEmail] = useState<string | null>()
  const [isSsoMode, setIsSsoMode] = useState<boolean>(false)
  const { loginWithGoogle, loginWithMicrosoft } = useAuth0()
  const { signIn, setClientId } = useSaml()
  const verifyTenantQuery = useVerifyTenantByEmail()

  const handleVerifyEmail = async () => {
    if (!email) return
    verifyTenantQuery.mutate(email, {
      onSuccess: (response: AxiosResponse<SSOConnection[]>) => {
        const { data: connections } = response
        if (!connections.length) {
          setSsoError('No SSO connection found for this email')
          return
        }
        setClientId(connections[0].clientID)
        const orgTenant = connections[0].tenant
        signIn(orgTenant)
      },
      onError: () => {
        setSsoError('SSO is not enabled for your organization, please use other login methods')
      },
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value)
  }

  const handleSSOButton = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    if (isSsoMode) {
      await handleVerifyEmail()
    } else {
      setIsSsoMode(true)
    }
  }

  return (
    <main className="flex flex-col items-center justify-center min-h-screen p-5 gap-8 relative">
      <RollstackLogoFull size={40} />

      <h1 className="text-lg-regular">Welcome to Rollstack</h1>

      <form className="flex flex-col w-[320px] gap-5">
        {isSsoMode && (
          <>
            <div className="text-sm-regular">
              Single sign-on allows you to sign in to Rollstack using your organization's identity provider.
            </div>
            <Input onChange={handleInputChange} placeholder="Enter your email" name="email" />
            {ssoError && <div className="text-red-600 text-xs text-center">{ssoError}</div>}
          </>
        )}

        {!isSsoMode && (
          <>
            <div className="text-sm-regular flex flex-col items-center justify-center gap-5">
              <Button
                secondaryGray
                iconLeading={<GoogleIcon />}
                text="Continue with Google"
                onClick={async e => {
                  e.preventDefault()
                  loginWithGoogle()
                }}
                className="justify-center w-full p-6"
              />
            </div>
            <div className="text-sm-regular flex flex-col items-center justify-center w-85 gap-5">
              <Button
                secondaryGray
                iconLeading={<MicrosoftIcon />}
                text="Continue with Microsoft"
                onClick={async e => {
                  e.preventDefault()
                  loginWithMicrosoft()
                }}
                className="justify-center w-full p-6"
              />
            </div>
          </>
        )}
        <div className="text-sm-regular flex flex-col items-center justify-center w-85 gap-5">
          <Button
            secondaryGray={!isSsoMode}
            iconLeading={isSsoMode ? undefined : <SamlIcon />}
            text={'Continue with SSO'}
            onClick={handleSSOButton}
            className="justify-center w-full p-6"
            loading={verifyTenantQuery.isLoading}
            disabled={isSsoMode && !email}
          />

          {isSsoMode && (
            <Button
              linkColor
              text={'Back'}
              onClick={async e => {
                e.preventDefault()
                setIsSsoMode(false)
              }}
            />
          )}
        </div>
      </form>
      <div className="flex gap-1">
        <span className="text-sm-regular">Need help logging in? Contact us at </span>
        <a href="mailto:support@rollstack.com">
          <Button linkColor text="support@rollstack.com" />
        </a>
      </div>
      <Footer />
    </main>
  )
}
