declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement> & TableauVizProps, HTMLElement>
      'custom-parameter': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & TableauCustomParameterProps,
        HTMLElement
      >
    }
  }
}

export enum ETableauScriptUrl {
  Public = 'https://public.tableau.com',
  Server = 'https://online.tableau.com',
}

export type JsonTableauMark = { [key: string]: string | string[] }
export type JsonTableauParameter = { [key: string]: ITableauDataValueType | ITableauDataValueType[] }
export type JsonTableauFilter = { [key: string]: IFilterValues | IRangeValues | IRelativeDate | ITableauRangeFilter }
export type JsonObjectTableau = JsonTableauFilter | JsonTableauParameter | JsonTableauMark

export interface IColumn {
  _fieldName: string
  _dataType: string
  _fieldId: string
  dataType: string
  index: number
}

export interface IDataValue {
  _formattedValue: string
  _value: string
  _nativeValue: string
}

export interface IDataTable {
  _columns: IColumn[]
  _data: IDataValue[][]
}

export interface IRangeValues {
  minValue: string
  maxValue: string
  typeValue: string
  // date specific fields
  dataType?: ETableauDataType
  datePeriodType?: ETableauPeriodType
  dateRangeType?: ETableauDateRangeType
  dateRangeN?: number
}

export interface IFilterValues {
  values: string[]
  isExcludeMode: boolean
  isAllSelected?: boolean
}

export enum EDashboardObjectType {
  Blank = 'blank',
  Extension = 'extension',
  Worksheet = 'worksheet',
  QuickFilter = 'quick-filter',
  ParameterControl = 'parameter-control',
  Image = 'image',
  Text = 'text',
  PageFilter = 'pageFilter',
  Legend = 'legend',
}

interface TableauVizProps {
  width?: string
  height?: string
  'hide-tabs': boolean
  'touch-optimize': boolean
  'disable-url-actions': boolean
  src?: string
  device: string
  toolbar: string
  token?: string
}

interface TableauCustomParameterProps {
  name: string
  value: string
}

export enum SelectionUpdateType {
  ADD = 'select-add',
  REMOVE = 'select-remove',
  REPLACE = 'select-replace',
}

export enum FilterUpdateType {
  /** Adds all values to the filter. Equivalent to checking the (All) value in a quick filter. */
  ALL = 'all',
  /** Replaces the current filter values with new ones specified in the call */
  REPLACE = 'replace',
  /** Adds the filter values as specified in the call to the current filter values. Equivalent to checking a value in a quick filter. */
  ADD = 'add',
  /** Removes the filter values as specified in the call from the current filter values. Equivalent to unchecking a value in a quick filter. */
  REMOVE = 'remove',
}

export enum ETableauFilterType {
  /** Categorical filters are used to filter to a set of values within the domain. */
  CATEGORICAL = 'categorical',
  /** Quantitative filters are used to filter to a range of values from a continuous domain. */
  QUANTITATIVE = 'quantitative',
  /** Hierarchical filters are used to filter to a set of values organized into a hierarchy within the domain. */
  HIERARCHICAL = 'hierarchical',
  /** Relative date filters are used to filter a date/time domain to a range of values relative to a fixed point in time. */
  RELATIVE_DATE = 'relative-date',
  RANGE = 'range',
}

export enum ETableauDataType {
  INT = 'int',
  FLOAT = 'float',
  STRING = 'string',
  BOOL = 'bool',
  DATE = 'date',
  DATETIME = 'datetime',
}

export enum TableauRangeValueType {
  date = 'date',
  any = 'any',
}

export enum TableauEvent {
  FirstInteractive = 'firstinteractive',
  FirstVizSizeKnown = 'firstvizsizeknown',
  OnLoad = 'onload',
  ParameterChanged = 'parameterchanged',
  MarkSelectionChanged = 'markselectionchanged',
  FilterChanged = 'filterchanged',
  UrlAction = 'urlaction',
  TabSwitched = 'tabswitched',
}

export enum ETableauSizingBehavior {
  automatic = 'automatic',
  exactly = 'exactly',
  atleast = 'atleast',
  range = 'range',
}

export interface INativeSheetSize {
  behavior: ETableauSizingBehavior
  maxSize?: ITableauSize
  minSize?: ITableauSize
}

export interface IEmbedSheetSize {
  behavior: ETableauSizingBehavior
  size: ITableauSize
}

export interface ITableauObject {
  id: number
  name: string
  size: ITableauSize
  position: ITableauPosition
  type: EDashboardObjectType
}

export interface ITableauPosition {
  x: number
  y: number
}

export interface ITableauSize {
  width: number
  height: number
}

// ============================================ TABLEAU PARAMETER ============================================
export interface ITableauParameter {
  id: string
  name: string
  dataType: ETableauDataType
  currentValueType: ITableauDataValue
  allowableValues: ITableauAllowableValue
  parameterImpl: ITableauParameterImpl
}

export interface ITableauParameterImpl {
  _registryId: number
  _parameterInfo: TableauParameterInfoSchema
  _globalFieldName: string
  _allowableValues: ITableauDataValue[]
}

export interface TableauParameterInfoSchema {
  name: string
  fieldName: string
  dataType: string
  currentValue: ITableauDataValue
  allowableValuesType: string
  minValue?: ITableauDataValue
  maxValue?: ITableauDataValue
  stepSize?: number
  allowableValues: ITableauDataValue[]
}
// ============================================ TABLEAU FILTER ============================================
export interface ITableauBaseFilter {
  _fieldId: string
  _fieldName: string
  _filterType: ETableauFilterType
  _worksheetName: string
  dataType?: ETableauDataType
  domain?: ITableauDomain
  getDomainAsync: (domainType?: ETableauDomainType) => Promise<ITableauDomain>
}

export interface ITableauRangeFilter {
  includeNullValues: boolean
  minValue: any
  maxValue: any
  _min: any
  _max: any
  // date specific fields
  datePeriodType?: ETableauPeriodType
  dateRangeType?: EDateRangeType
  dateRangeN?: number
}

export interface ITableauCategoricalFilter {
  appliedValues?: ITableauDataValue[]
  _appliedValues?: ITableauDataValue[]
  isExcludeMode: boolean
  isAllSelected: boolean
}

export interface ITableauRelativeDateFilter {
  anchorDate: any
  periodType: ETableauPeriodType
  rangeType: ETableauDateRangeType
  rangeN: string
  _anchorDate: any
  _periodType: ETableauPeriodType
  _rangeType: ETableauDateRangeType
  _rangeN: string
}

export enum ETableauPeriodType {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  quarters = 'quarters',
  years = 'years',
}

export enum ETableauDateRangeType {
  current = 'current',
  last = 'last',
  next = 'next',
  lastN = 'last-n',
  nextN = 'next-n',
  toDate = 'to-date',
}

export enum EDateRangeType {
  toDate = 'to date',
  last = 'last',
}

export type ICategorical = ITableauBaseFilter &
  ITableauCategoricalFilter & { _filterType: ETableauFilterType.CATEGORICAL }
export type IRelativeDate = ITableauBaseFilter &
  ITableauRelativeDateFilter & { _filterType: ETableauFilterType.RELATIVE_DATE }
export type IRange = ITableauBaseFilter & ITableauRangeFilter & { _filterType: ETableauFilterType.RANGE }

export type ITableauFilter = ICategorical | IRelativeDate | IRange
export type ITableauFilterObject = ITableauFilter & { object?: ITableauObject }
export type ITableauParameterObject = ITableauParameter & { object?: ITableauObject }

export interface ITableauDomain {
  type: ETableauDomainType
  values: ITableauDataValue[]
}

export enum ETableauDomainType {
  database = 'database',
  relevant = 'relevant',
}

export interface ITableauAllowableValue {
  allowableValues: ITableauDataValue[]
  dataStepPeriod: ETableauPeriodType
  maxValue: ITableauDataValue
  minValue: ITableauDataValue
  stepSize?: number
  type: ETableauParamType
}

export enum ETableauParamType {
  string = 'string',
  integer = 'integer',
  float = 'float',
  boolean = 'boolean',
  date = 'date',
  list = 'list',
}

export type ITableauDataValueType = string | number | boolean | Date
export interface ITableauDataValue {
  formattedValue: string
  value: ITableauDataValueType
  nativeValue: ITableauDataValueType
  _formattedValue: string
  _value: ITableauDataValueType
  _nativeValue: ITableauDataValueType
}

export interface ITableauSwitchedEvent {
  oldSheetName: string
  newSheetName: string
}

export interface ITableauFirstVizSizeKnownEvent {
  _vizSize: {
    _sheetSize: INativeSheetSize
    _chromeHeight: number
  }
}

export interface ITableauFilterChangedEvent {
  fieldName: string
  sheet: tableau.Sheet
  worksheet: tableau.Worksheet
  getFilterAsync: () => Promise<ITableauFilter>
}
