import { FC, useMemo } from 'react'

import { useCollectionEmailConfigHandler } from '@features/collections/hooks/use-collection-email-config-handler'
import { useDestinationEmailConfigHandler } from '@features/destinations/hooks/use-destination-email-config-handler'
import { Destination, DestinationEmailConfig, EDestinationType } from '@features/destinations/types'
import { getEmptyDestinationEmailConfig, isEmailDestination } from '@features/destinations/utils'
import { SelectEmailTemplate } from '@features/emails/components/select-email-template'
import { getUpdateDestinationTemplateVariables } from '@features/emails/components/templates/utils'
import { EEmailTemplateType, ESelectEmailTemplateEditorMode } from '@features/emails/types'

import { Spinner } from '@shared/components'

interface Props {
  destination: Destination
  initialEmailConfig?: DestinationEmailConfig
}

export const DestinationTemplate: FC<Props> = ({ destination }) => {
  const {
    emailConfig: destinationEmailConfig,
    setEmailConfig,
    upsertEmailConfig,
    isGetEmailConfigLoading,
  } = useDestinationEmailConfigHandler(destination.id)

  const isInCollection = useMemo(() => !!destination.variantInCollection, [destination])

  const { emailConfig: collectionEmailConfig } = useCollectionEmailConfigHandler(
    destination.variantInCollection?.collectionId,
    { enabled: isInCollection }
  )

  return (
    <div className="flex flex-col gap-7" key={destination.id}>
      {isGetEmailConfigLoading ? (
        <Spinner center={false} className="w-full flex items-center justify-center" />
      ) : (
        <SelectEmailTemplate
          targetId={destination.id}
          onlyTemplatesForTarget
          selectClassName="max-w-96 w-full"
          syncs={destination.syncs}
          editorMode={ESelectEmailTemplateEditorMode.INLINE}
          templateType={
            isEmailDestination(destination) ? EEmailTemplateType.emailDestination : EEmailTemplateType.destinationUpdate
          }
          onSaveTemplate={async templateId => {
            if (templateId) {
              const newConfig = {
                ...getEmptyDestinationEmailConfig(destination.id),
                ...destinationEmailConfig,
                emailTemplateId: templateId,
              }

              await upsertEmailConfig(newConfig, { showToast: false })
            }
          }}
          destinationType={EDestinationType.Email}
          initialTemplateId={destinationEmailConfig?.emailTemplateId}
          onSelectTemplate={(emailTemplateId?: string | null) => setEmailConfig({ emailTemplateId })}
          variables={getUpdateDestinationTemplateVariables(
            destination,
            isInCollection ? collectionEmailConfig : destinationEmailConfig
          )}
        />
      )}
    </div>
  )
}
