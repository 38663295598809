import { FC } from 'react'

import { Paths } from '@pages/routes/paths'

import { OrganizationIcon } from '@assets'

import { useOrganizationInviteContext } from '@features/organizations'
import { useOrganizationsStore } from '@features/organizations'
import { useAcceptInvite, useDeclineInvite } from '@features/organizations/api'

import { ActionModal, Button } from '@shared/components'

import styles from './organization-invite-modal.module.css'

export const OrganizationInviteModal: FC = () => {
  const { visible, invite, closeInvite } = useOrganizationInviteContext()
  const { setActiveOrganization } = useOrganizationsStore()
  const { isLoading: isLoadingAccept, mutateAsync: acceptInvite } = useAcceptInvite()
  const { isLoading: isLoadingDecline, mutateAsync: declineInvite } = useDeclineInvite()
  const isLoading = isLoadingAccept || isLoadingDecline

  const handleAccept = async () => {
    if (!invite) return
    await acceptInvite(invite.id)
    setActiveOrganization({
      userId: invite.userId,
      organization: invite.organization,
      organizationId: invite.organizationId,
      role: invite.role,
    })

    window.location.href = Paths.SETTINGS_ORGANIZATION
    closeInvite()
  }

  const handleDecline = async () => {
    if (!invite) return
    await declineInvite(invite.id)
    closeInvite()
  }

  return (
    <ActionModal
      cross={false}
      title={`Join ${invite?.organization?.name} organization`}
      subtitle={`${invite?.user?.name} invited you to join the organization ${invite?.organization?.name}`}
      Icon={OrganizationIcon}
      open={visible}
      className={styles.container}
      btns={
        <>
          <Button onClick={handleDecline} fullWidth secondaryGray text="Decline" disabled={isLoading} />
          <Button onClick={handleAccept} fullWidth text="Accept" loading={isLoading} />
        </>
      }
    />
  )
}
