import cloneDeep from 'lodash/cloneDeep'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { AlertIcon } from '@assets'

import { Destination, EDestinationType } from '@features/destinations/types'
import { useGetUser } from '@features/users/api/api'

import { Button } from '@shared/components/button/button'
import { ActionModal } from '@shared/components/modal/action/action-modal'
import { Colors } from '@shared/constants'
import { SocketEvent, useAuthenticatedSocket } from '@shared/hooks/use-authenticated-socket'
import { useBoolean } from '@shared/hooks/use-boolean'
import { queryClient } from '@shared/http/query-client'

export type DestinationUpdateStatusName =
  | 'PENDING'
  | 'RUNNING'
  | 'PARTIALLY_COMPLETED'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELLED'
export type CollectionUpdateStatusName = 'RUNNING' | 'PARTIALLY_COMPLETED' | 'COMPLETED' | 'FAILED' | 'CANCELLED'
export type VisUpdateStatusName =
  | 'PENDING'
  | 'RUNNING'
  | 'SCREENSHOT_TAKEN'
  | 'INACTIVE'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELLED'
export type AddVariantsStatusName = 'CREATING' | 'CREATED' | 'PARTIALLY_CREATED' | 'FAILED' | 'CANCELLED'
export type AddVariantStatusName = 'PENDING' | 'CREATING' | 'SYNCING' | 'CREATED' | 'FAILED' | 'CANCELLED'
export type DuplicateDestinationStatusName = 'PENDING' | 'RUNNING' | 'COMPLETED' | 'FAILED' | 'PARTIALLY_COMPLETED'

export interface VisUpdateStatus {
  id: string
  name: string
  status: VisUpdateStatusName
  error?: any
}

export interface CollectionUpdateStatus {
  id: string
  name: string
  status: CollectionUpdateStatusName
  destinations: DestinationUpdateStatus[]
  error?: string
}

export interface DestinationUpdateStatus {
  id: string
  name: string
  type: EDestinationType
  status: DestinationUpdateStatusName
  vises: VisUpdateStatus[]
  error?: any
  collectionId?: string | null
}

export interface DuplicateDestinationVisResult {
  originalId: string
  id?: string
  name: string
  status: 'COMPLETED' | 'FAILED'
  reason?: string
}

export interface DuplicateDestinationStatus {
  originalId: string
  id?: string
  originalName: string
  name?: string
  status: DuplicateDestinationStatusName
  duplicationVisResults: DuplicateDestinationVisResult[]
  presentation?: Destination
  errorCode?: string
  error?: string
}

export interface AddVariantStatus {
  id: string
  name: string
  status: AddVariantStatusName
  error?: string
}

export interface AddVariantsStatus {
  collectionId: string
  status: AddVariantsStatusName
  variants: AddVariantStatus[]
  error?: string
}

export type DestinationsStatuses = DestinationUpdateStatus[]

export type CollectionStatuses = CollectionUpdateStatus[]

export type AddVariantsStatuses = AddVariantsStatus[]

export interface RegenerateVariantStatus {
  destinationId: string
  name: string
  status: 'PENDING' | 'REGENERATING' | 'SYNCING' | 'REGENERATED' | 'FAILED'
  error?: string
}

export interface RegenerateVariantsStatus {
  collectionId: string
  variants: RegenerateVariantStatus[]
  status: 'REGENERATING' | 'REGENERATED' | 'PARTIALLY_REGENERATED' | 'FAILED'
  error?: string
}

interface IDestinationsStatusContext {
  addVariantsStatuses: AddVariantsStatuses
  allFinished: boolean
  allSuccessful: boolean
  duplicateDestinationStatus?: DuplicateDestinationStatus
  setDuplicateDestinationStatus?: (duplicateDestinationStatus?: DuplicateDestinationStatus) => void
  cancelCollectionUpdate: (collectionId: string) => void
  cancelAddVariants: () => void
  cancelDestinationUpdate: (destinationId: string) => void
  cancelAllDestinationUpdates: () => void
  collectionStatuses: CollectionStatuses
  destinationsStatuses: DestinationsStatuses
  hideNotchAndClear: () => void
  isCollectionUpdateRunning: (destinationId: string) => boolean
  isDestinationUpdateRunning: (destinationId: string) => boolean
  notchOpen: boolean
  notchVisible: boolean
  regenerateVariantsStatuses: RegenerateVariantsStatus[]
  toggle: (val?: boolean) => void
}

const DestinationsStatusContext = createContext<IDestinationsStatusContext>({
  addVariantsStatuses: [],
  allFinished: true,
  allSuccessful: true,
  duplicateDestinationStatus: undefined,
  cancelCollectionUpdate: () => {},
  cancelAddVariants: () => {},
  cancelDestinationUpdate: () => {},
  cancelAllDestinationUpdates: () => {},
  collectionStatuses: [],
  destinationsStatuses: [],
  hideNotchAndClear: () => {},
  isCollectionUpdateRunning: () => false,
  isDestinationUpdateRunning: () => false,
  notchOpen: true,
  notchVisible: false,
  regenerateVariantsStatuses: [],
  toggle: () => {},
})

const updateFinished = (dest: DestinationUpdateStatus) =>
  ['COMPLETED', 'PARTIALLY_COMPLETED', 'FAILED', 'CANCELLED'].includes(dest.status)

const addVariantsFinished = (addCollectionStatus: AddVariantsStatus) =>
  ['CREATED', 'PARTIALLY_CREATED', 'FAILED', 'CANCELLED'].includes(addCollectionStatus.status)

const regenerateVariantsFinished = (regenerateVariantsStatus: RegenerateVariantsStatus) =>
  ['REGENERATED', 'FAILED'].includes(regenerateVariantsStatus.status)

const errorModalInitialState = {
  title: '',
  subtitle: '',
  open: false,
}
export const DestinationsStatusesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [destinationsStatuses, setDestinationsStatuses] = useState<DestinationsStatuses>([])
  const [duplicateDestinationStatus, setDuplicateDestinationStatus] = useState<DuplicateDestinationStatus>()
  const [collectionStatuses, setCollectionStatuses] = useState<CollectionStatuses>([])
  const [addVariantsStatuses, setAddVariantsStatuses] = useState<AddVariantsStatus[]>([])
  const [regenerateVariantsStatuses, setRegenerateVariantsStatuses] = useState<RegenerateVariantsStatus[]>([])
  const [notchVisible, toggleNotchVisible] = useBoolean()
  const [notchOpen, toggle] = useBoolean(false)
  const [errorModalProps, setErrorModalProps] = useState(errorModalInitialState)

  const { refetch: refetchUser } = useGetUser()

  const hideNotchAndClear = () => {
    toggleNotchVisible(false)
    setDestinationsStatuses([])
    setCollectionStatuses([])
    setAddVariantsStatuses([])
    setRegenerateVariantsStatuses([])
  }

  const isDestinationUpdateRunning = (destinationId: string) =>
    destinationsStatuses.some(
      destination =>
        destination.id === destinationId && (destination.status === 'PENDING' || destination.status === 'RUNNING')
    )

  const isCollectionUpdateRunning = (collectionId: string) =>
    collectionStatuses.some(collection => collection.id === collectionId && collection.status === 'RUNNING')

  const addDestinationStatus = (destinationUpdate: DestinationUpdateStatus) => {
    setDestinationsStatuses(prev => {
      const newDestinationsStatuses = [...prev]
      const destinationIdx = prev.findIndex(dest => dest.id === destinationUpdate.id)
      if (destinationIdx !== -1) {
        newDestinationsStatuses[destinationIdx] = destinationUpdate
      } else {
        newDestinationsStatuses.push(destinationUpdate)
      }
      return newDestinationsStatuses
    })
  }

  const addCollectionStatus = (collectionUpdate: CollectionUpdateStatus) => {
    setCollectionStatuses(prev => {
      const newCollectionStatuses = [...prev]
      const collectionIndex = prev.findIndex(collection => collection.id === collectionUpdate.id)
      if (collectionIndex !== -1) {
        newCollectionStatuses[collectionIndex] = collectionUpdate
      } else {
        newCollectionStatuses.push(collectionUpdate)
      }
      return newCollectionStatuses
    })
  }

  const showFileLockedMessage = (destinationUpdate: DestinationUpdateStatus) => {
    if (destinationUpdate.error === 'file_locked') {
      setErrorModalProps({
        title: 'File is being used by another application',
        subtitle: 'If you use the file in another application, please close it and try again.',
        open: true,
      })
    }
  }

  const closeErrorModal = () => {
    setErrorModalProps(errorModalInitialState)
  }

  const events: SocketEvent[] = [
    {
      name: 'syncUpdateStatus',
      handler: async (visUpdate: VisUpdateStatus) => {
        if (destinationsStatuses) {
          let visIndex
          const destination = destinationsStatuses.find(destinationStatus => {
            visIndex = destinationStatus.vises.findIndex(destinationVis => destinationVis.id === visUpdate.id)
            return visIndex > -1
          })
          if (destination && visIndex !== undefined && visIndex > -1) {
            const clonedDestination = cloneDeep(destination)
            clonedDestination.vises[visIndex] = visUpdate
            if (visUpdate.status === 'FAILED') {
              clonedDestination.status = 'FAILED'
            } else if (visUpdate.status === 'COMPLETED') {
              if (clonedDestination.vises.every(vis => vis.status === 'COMPLETED')) {
                clonedDestination.status = 'COMPLETED'
              } else {
                clonedDestination.status = 'PARTIALLY_COMPLETED'
              }
            } else {
              clonedDestination.status = 'RUNNING'
            }
            addDestinationStatus(clonedDestination)
            await refetchUser()
            return
          }
        }
      },
    },
    {
      name: 'destinationUpdateStatus',
      handler: async (destinationUpdate: DestinationUpdateStatus) => {
        toggleNotchVisible(true)

        addDestinationStatus(destinationUpdate)

        if (updateFinished(destinationUpdate)) {
          await queryClient.invalidateQueries(['presentation', { destinationId: destinationUpdate.id }])
          await queryClient.invalidateQueries(['versions', { destinationId: destinationUpdate.id }])
          await queryClient.invalidateQueries(['activities-logs', { destinationId: destinationUpdate.id }])
          await queryClient.invalidateQueries(['destination-thumbnails', destinationUpdate.id])
          await queryClient.invalidateQueries(['slides', { presentationId: destinationUpdate.id }])
          await refetchUser()
          if (!destinationUpdate.collectionId) {
            await queryClient.invalidateQueries(['presentations'])
          } else {
            await queryClient.invalidateQueries(['collections'])
            await queryClient.invalidateQueries(['collection', { collectionId: destinationUpdate.collectionId }])
          }
        }

        showFileLockedMessage(destinationUpdate)
      },
    },
    {
      name: 'collectionUpdateStatus',
      handler: async (collectionUpdate: CollectionUpdateStatus) => {
        toggleNotchVisible(true)

        for (const destination of collectionUpdate.destinations) {
          addDestinationStatus(destination)
          if (['COMPLETED', 'FAILED', 'PARTIALLY_COMPLETED'].includes(destination.status)) {
            await queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
            await queryClient.invalidateQueries(['versions', { destinationId: destination.id }])
            await queryClient.invalidateQueries(['activities-logs', { destinationId: destination.id }])
          }
        }

        addCollectionStatus(collectionUpdate)

        if (['COMPLETED', 'FAILED', 'PARTIALLY_COMPLETED'].includes(collectionUpdate.status)) {
          await queryClient.invalidateQueries(['collections'])
          await queryClient.invalidateQueries(['collection', { collectionId: collectionUpdate.id }])
        }
      },
    },
    {
      name: 'addVariantsStatus',
      handler: async (addVariantsStatus: AddVariantsStatus) => {
        toggleNotchVisible(true)
        setAddVariantsStatuses(prev => {
          const newAddCollectionStatuses = [...prev]
          const statusIdx = prev.findIndex(status => status.collectionId === addVariantsStatus.collectionId)
          if (statusIdx !== -1) {
            newAddCollectionStatuses[statusIdx] = addVariantsStatus
          } else {
            newAddCollectionStatuses.push(addVariantsStatus)
          }
          return newAddCollectionStatuses
        })
        // we can optimize this
        await queryClient.invalidateQueries(['collection', { collectionId: addVariantsStatus.collectionId }])
      },
    },
    {
      name: 'regenerateVariantsStatus',
      handler: async (regenerateVariantsStatus: RegenerateVariantsStatus) => {
        toggleNotchVisible(true)
        setRegenerateVariantsStatuses(prev => {
          const newRegenerateVariantsStatuses = [...prev]
          const statusIdx = prev.findIndex(status => status.collectionId === regenerateVariantsStatus.collectionId)
          if (statusIdx !== -1) {
            newRegenerateVariantsStatuses[statusIdx] = regenerateVariantsStatus
          } else {
            newRegenerateVariantsStatuses.push(regenerateVariantsStatus)
          }
          return newRegenerateVariantsStatuses
        })

        for (const variant of regenerateVariantsStatus.variants) {
          if (variant.status === 'REGENERATED') {
            const destinationId = variant.destinationId
            await queryClient.invalidateQueries(['presentation', { destinationId }])
            await queryClient.invalidateQueries(['versions', { destinationId }])
            await queryClient.invalidateQueries(['activities-logs', { destinationId }])
            await queryClient.invalidateQueries(['destination-thumbnails', destinationId])
            await queryClient.invalidateQueries(['slides', { presentationId: destinationId }])
          }
        }
      },
    },
    {
      name: 'duplicateDestinationStatus',
      handler: async (destinationUpdate: DuplicateDestinationStatus) => {
        setDuplicateDestinationStatus(destinationUpdate)

        if (destinationUpdate.status === 'COMPLETED' || destinationUpdate.status === 'PARTIALLY_COMPLETED') {
          queryClient.invalidateQueries(['presentations'])
        }
      },
    },
  ]

  const { socket, connected } = useAuthenticatedSocket(events)

  const allFinished = useMemo(
    () =>
      destinationsStatuses.every(updateFinished) &&
      addVariantsStatuses.every(addVariantsFinished) &&
      regenerateVariantsStatuses.every(regenerateVariantsFinished),
    [destinationsStatuses, addVariantsStatuses, regenerateVariantsStatuses]
  )
  const allSuccessful = useMemo(
    () =>
      destinationsStatuses.every(({ status }) => status === 'COMPLETED') &&
      addVariantsStatuses.every(({ status }) => status === 'CREATED') &&
      regenerateVariantsStatuses.every(({ status }) => status === 'REGENERATED'),
    [destinationsStatuses, addVariantsStatuses, regenerateVariantsStatuses]
  )

  useEffect(() => {
    if (connected) {
      socket.emit('allDestinationsStatuses')
      socket.emit('allCollectionsStatuses')
      socket.emit('allAddVariantsStatuses')
      socket.emit('allRegenerateVariantsStatuses')
    }
  }, [socket, connected])

  const cancelDestinationUpdate = (destinationId: string) => {
    const destinationsStatusesCopy = cloneDeep(destinationsStatuses)
    const destinationIndex = destinationsStatusesCopy.findIndex(destination => destination.id === destinationId)
    const destinationStatus = destinationsStatusesCopy[destinationIndex]
    destinationsStatusesCopy[destinationIndex] = {
      ...destinationStatus,
      status: 'CANCELLED',
      vises: destinationStatus.vises.map(vis => ({
        ...vis,
        status: vis.status === 'COMPLETED' ? 'COMPLETED' : 'CANCELLED',
      })),
    }
    setDestinationsStatuses(destinationsStatusesCopy)
    if (destinationsStatusesCopy.length === 0) {
      toggleNotchVisible(false)
    }
  }

  const cancelAllDestinationUpdates = () => {
    const destinationsStatusesCopy = cloneDeep(destinationsStatuses)
    const destinationIds = destinationsStatusesCopy.map(destination => destination.id)
    const destinationsToCancel = destinationsStatusesCopy.filter(destination => destinationIds.includes(destination.id))
    destinationsToCancel.forEach(destination => {
      destination.status = 'CANCELLED'
      destination.vises = destination.vises.map(vis => ({
        ...vis,
        status: vis.status === 'COMPLETED' ? 'COMPLETED' : 'CANCELLED',
      }))
    })
    setDestinationsStatuses(destinationsStatusesCopy)
    if (destinationsStatusesCopy.length === 0) {
      toggleNotchVisible(false)
    }
  }

  const cancelCollectionUpdate = (collectionId: string) => {
    const collectionStatusesCopy = cloneDeep(collectionStatuses)
    const collectionIndex = collectionStatusesCopy.findIndex(collection => collection.id === collectionId)
    const collection = collectionStatusesCopy[collectionIndex]
    collectionStatusesCopy[collectionIndex] = {
      ...collectionStatusesCopy[collectionIndex],
      status: 'CANCELLED',
      destinations: collection
        ? collection.destinations.map(destination => ({
            ...destination,
            status: 'CANCELLED',
            vises: [],
          }))
        : [],
    }
    setCollectionStatuses(collectionStatusesCopy)
    if (!collection) {
      return
    }
    const destinationsStatusesCopy = cloneDeep(destinationsStatuses)
    const destinationIds = collection.destinations.map(destination => destination.id)
    const destinationsToCancel = destinationsStatusesCopy.filter(destination => destinationIds.includes(destination.id))
    destinationsToCancel.forEach(destination => {
      destination.status = 'CANCELLED'
      destination.vises = []
    })
    setDestinationsStatuses(destinationsStatusesCopy)
  }

  const cancelAddVariants = () => {
    const addVariantsStatusesCopy = cloneDeep(addVariantsStatuses)
    addVariantsStatusesCopy.forEach(addVariantStatus => {
      addVariantStatus.status = 'CANCELLED'
      addVariantStatus.variants.forEach(variant => {
        variant.status = variant.status === 'PENDING' ? 'CANCELLED' : variant.status
      })
    })

    setAddVariantsStatuses(addVariantsStatusesCopy)

    const destinationsStatusesCopy = cloneDeep(destinationsStatuses)
    const collectionIds = addVariantsStatusesCopy.map(({ collectionId }) => collectionId)
    const destinationsToCancel = destinationsStatusesCopy.filter(
      destination =>
        destination.collectionId && collectionIds.includes(destination.collectionId) && destination.status === 'RUNNING'
    )
    destinationsToCancel.forEach(destination => {
      destination.status = 'CANCELLED'
      destination.vises.forEach(vis => {
        vis.status = 'CANCELLED'
      })
    })

    setDestinationsStatuses(destinationsStatusesCopy)
  }

  return (
    <DestinationsStatusContext.Provider
      value={{
        addVariantsStatuses,
        allFinished,
        allSuccessful,
        cancelCollectionUpdate,
        cancelAddVariants,
        cancelDestinationUpdate,
        cancelAllDestinationUpdates,
        duplicateDestinationStatus,
        setDuplicateDestinationStatus,
        collectionStatuses,
        destinationsStatuses,
        hideNotchAndClear,
        isCollectionUpdateRunning,
        isDestinationUpdateRunning,
        notchOpen,
        notchVisible,
        regenerateVariantsStatuses,
        toggle,
      }}
    >
      {children}
      <ActionModal
        open={errorModalProps.open}
        title={errorModalProps.title}
        subtitle={errorModalProps.subtitle}
        onBackgroundClick={closeErrorModal}
        Icon={() => <AlertIcon color={Colors.error500} />}
        btns={<Button secondaryColor text="OK" onClick={closeErrorModal} />}
      />
    </DestinationsStatusContext.Provider>
  )
}

export const useDestinationsStatuses = () => useContext(DestinationsStatusContext)
