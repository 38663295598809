import classNames from 'classnames'
import Select from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'

import { Colors } from '@shared/constants'
import { cn } from '@shared/utils'

export const primaryStyle = (isDisabled?: boolean) => {
  return {
    control: (base: any) => ({
      ...base,
      borderRadius: 8,
      borderColor: Colors.gray300,
      '&:hover': { borderColor: Colors.gray400 },
      boxShadow: 'none',
      backgroundColor: isDisabled ? Colors.gray50 : Colors.white,
      cursor: 'pointer',
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: Colors.gray700,
      borderRadius: 4,
      '&:hover': {
        backgroundColor: Colors.gray100,
        cursor: state.isDisabled ? 'inherit' : 'pointer',
      },
      backgroundColor: state.isSelected ? Colors.primary100 : state.isDisabled ? Colors.gray200 : Colors.white,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: isDisabled ? Colors.gray400 : Colors.gray700,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: Colors.gray500,
    }),
  }
}

type ISelectInputProps<T> = Omit<StateManagerProps<T>, 'onChange' | 'isMulti'> & {
  label?: string
  leadingIcon?: any
  trailingIcon?: any
  Option?: any
  SingleValue?: any
  onClick?: (e: React.MouseEvent) => void
  dataTestId?: string
  isDisabled?: boolean
  requiredStar?: boolean
  containerClassName?: string
} & (
    | {
        isMulti?: false
        onChange: (option: T) => void
      }
    | {
        isMulti: true
        onChange: (option: T[]) => void
      }
  )

export const SelectInput = <T,>({
  label,
  isSearchable = false,
  leadingIcon,
  trailingIcon,
  SingleValue,
  Option,
  className,
  containerClassName,
  onChange,
  onClick,
  dataTestId,
  isMulti = false,
  isDisabled = false,
  requiredStar = false,
  ...props
}: ISelectInputProps<T>) => {
  let customComponents: Partial<SelectComponents<any, false, any>> = {
    IndicatorSeparator: () => trailingIcon,
  }
  if (Option) customComponents.Option = Option
  if (SingleValue) customComponents.SingleValue = SingleValue

  const selectClass = classNames('text-sm-regular', className)

  return (
    <div className={cn('flex flex-col gap-1', containerClassName)} onClick={onClick}>
      {label && (
        <label className="text-xs-medium">
          {label}
          {requiredStar && <sup>*</sup>}
        </label>
      )}
      <span className="w-full" data-testid={dataTestId}>
        <Select
          menuPlacement="auto"
          maxMenuHeight={180}
          isSearchable={isSearchable}
          className={selectClass}
          onChange={(arg: any) => {
            if (isMulti) {
              return onChange(arg as any)
            }
            return onChange(arg as any)
          }}
          styles={primaryStyle(isDisabled)}
          isDisabled={isDisabled}
          isMulti={isMulti}
          {...props}
          components={customComponents}
        />
      </span>
    </div>
  )
}
