import { FC, PropsWithChildren } from 'react'

import { cn } from '@shared/utils'

interface SectionProps extends PropsWithChildren {
  title: string
  subtitle: string
  className?: string
}

export const Section: FC<SectionProps> = ({ title, subtitle, children, className }) => (
  <section className={cn('flex flex-col rounded-md border border-gray-300 bg-white h-full p-[23px]', className)}>
    <span className="text-lg-semibold">{title}</span>
    <span className="text-sm-regular mb-8">{subtitle}</span>
    {children}
  </section>
)
