import { FC } from 'react'

import { AutoProvisioning } from '@pages/data-sources/common/auto-provisioning'

import { ArrowRight, DatabaseIcon, DuplicateIcon, lookerAssetsUrlsBase } from '@assets'

import { LookerAPIKeysModal, useLookerDataSourcesSettings } from '@features/data-sources'
import { ConfirmBox } from '@features/data-sources/components/settings/confirm-box/confirm-box'
import { InputBox } from '@features/data-sources/components/settings/input-box/input-box'

import { Button, Input } from '@shared/components'
import { PageContainer } from '@shared/components/page-container/page-container'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import styles from './looker-data-sources-settings.module.css'

const looker1Url = `${lookerAssetsUrlsBase}looker1.png`
const looker2Url = `${lookerAssetsUrlsBase}looker2.png`
const looker3Url = `${lookerAssetsUrlsBase}looker3.png`
const lookerCookielessUrl = `${lookerAssetsUrlsBase}cookieless.png`

const rollstackDomain = 'https://*.rollstack.com'

interface ILookerDataSourceSettings {
  setupBack?: () => void
}

export const LookerDataSourceSettings: FC<ILookerDataSourceSettings> = () => {
  const {
    state,
    isFormValid,
    isLoading,
    isUpdate,
    testModal,
    validationErrors,
    testAndSave,
    copy,
    toggleTestModal,
    handleInputChange,
    toggleAutoProvision,
  } = useLookerDataSourcesSettings()

  const isCookielessEmbed = isFeatureEnabled('cookieless-embed')

  const buttonText = isUpdate ? 'Update data source' : 'Continue'

  return (
    <PageContainer hideBreadcrumb={false}>
      <div className={styles.container}>
        <span>Choose a data source name and fill in your company’s Looker base url:</span>
        <InputBox>
          <Input
            label="Data source name"
            placeholder="Data source name"
            name="name"
            value={state.name}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <Input
            label="Looker Base url"
            placeholder="Looker Base url"
            name="baseUrl"
            value={state.baseUrl}
            errorMessage={validationErrors.baseUrlError}
            onChange={handleInputChange}
          />
        </InputBox>
        <span>In your Looker admin tab, go to Embed page</span>
        <img src={looker1Url} alt="looker1Url" />
        <span>Add this Url to the embedded domain allowlist:</span>
        <InputBox>
          <Input value={rollstackDomain} label="Copy this Rollstack domain URL" disabled>
            <Button
              onClick={() => copy(rollstackDomain)}
              value={rollstackDomain}
              secondaryGray
              text="Copy"
              iconLeading={<DuplicateIcon />}
              className={styles.copyBtn}
            />
          </Input>
        </InputBox>
        <img src={looker2Url} alt="looker2Url" />

        {isCookielessEmbed ? (
          <>
            <span>Enable Cookieless Embed</span>
            <img className={styles.border} src={lookerCookielessUrl} alt="Enable cookieless embed" />
          </>
        ) : (
          <>
            <span>Generate an embed secret and copy the value</span>
            <img src={looker3Url} alt="looker3Url" />
            <span>Paste the Embed secret here:</span>
            <InputBox>
              <Input
                label="Embed Secret"
                placeholder="Embed Secret"
                name="embedSecret"
                value={state.embedSecret}
                onChange={handleInputChange}
                secret
              />
            </InputBox>
          </>
        )}

        <AutoProvisioning toggleAutoProvision={toggleAutoProvision} state={state} />

        <ConfirmBox text="Save your Embedding secret key">
          <Button
            text={buttonText}
            disabled={isLoading || !isFormValid}
            loading={isLoading}
            onClick={testAndSave}
            iconTrailing={<ArrowRight />}
          />
        </ConfirmBox>
      </div>

      {testModal && state && (
        <LookerAPIKeysModal
          testDashboardEmbed
          isAdminView
          open={testModal}
          title="Test and save data source"
          Icon={DatabaseIcon}
          dataSource={state}
          toggle={toggleTestModal}
        />
      )}
    </PageContainer>
  )
}
