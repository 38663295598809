import { AxiosError } from 'axios'

import { warnToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import { formatApiErrorFromAxios, onError } from '@shared/http'

const METABASE_API_ERROR_CODES = {
  'metabase-fail-retrieve-session': 'Failed to retrieve Metabase session. Please check your Metabase credentials.',
}

const FALLBACK_METABASE_API_ERROR =
  'Something went wrong when accessing Metabase. Please verify your Metabase credentials.'

export const handleMetabaseApiErrors = (error: AxiosError, errorTitle = getMessage('METABASE_API_ERROR_GENERIC')) => {
  const apiError = formatApiErrorFromAxios(error)
  const errorCode = apiError.code as keyof typeof METABASE_API_ERROR_CODES

  if (errorCode) {
    if (errorCode === 'metabase-fail-retrieve-session') {
      warnToast({title: getMessage('INVALID_CREDENTIALS'), subtitle: 'Please check your Metabase credentials', toastId: errorCode})
      return
    }

    const specificErrorMessage =
      METABASE_API_ERROR_CODES[apiError.metadata.code as keyof typeof METABASE_API_ERROR_CODES] ||
      apiError.metadata.message ||
      FALLBACK_METABASE_API_ERROR

    onError(error, {
      title: errorTitle,
      message: specificErrorMessage,
      hideSubtext: true,
    })
    return
  }

  onError(error, {
    title: errorTitle,
    message: apiError.message,
  })
}
