import { ElementOptionItems } from '@looker/embed-sdk'
import { IDashboardElement } from '@looker/sdk'

import {
  BASE_FOOTER_HEIGHT,
  FILTER_HEIGHT_WITHOUT_FILTERS,
  FILTER_HEIGHT_WITH_FILTERS,
  RANDOM_FOUR,
  TITLE_HEIGHT,
} from './looker-embed'

import styles from './dashboard-element-overlay-wrapper.module.css'

export type LookerElementOverlay = {
  id: string
  element: IDashboardElement
  row: number
  column: number
  width: number
  height: number
}

type DashboardElementOverlayWrapperProps = {
  numRows: number
  iframeWidth: number
  iframeHeight: number
  elements: LookerElementOverlay[]
  hasFilters?: boolean
  render: (e: {
    id: string
    index: number
    element: ElementOptionItems
    rowStart: number
    rowEnd: number
    columnStart: number
    columnEnd: number
  }) => React.ReactNode
}

export const iframeWidthLimit = 1210
export const footerHeight = 40

export const DashboardElementOverlayWrapper = ({
  numRows,
  iframeWidth,
  iframeHeight,
  elements,
  hasFilters,
  render,
}: DashboardElementOverlayWrapperProps) => {
  const FOOTER_HEIGHT = BASE_FOOTER_HEIGHT + (iframeWidth > iframeWidthLimit ? footerHeight : 0)
  const FILTER_HEIGHT = hasFilters ? FILTER_HEIGHT_WITH_FILTERS : FILTER_HEIGHT_WITHOUT_FILTERS

  return (
    <div
      className={styles.root}
      style={{
        top: TITLE_HEIGHT + FILTER_HEIGHT,
        left: 0,
        height: iframeHeight - TITLE_HEIGHT - FILTER_HEIGHT - FOOTER_HEIGHT + RANDOM_FOUR,
      }}
    >
      <div className={styles.wrapper} style={{ gridTemplateRows: `repeat(${numRows}, 1fr)` }}>
        {elements
          .sort((a, b) => (a.row === b.row ? a.column - b.column : a.row - b.row))

          .map(({ row, column, height, width, element, id }, index) => {
            const rowStart = row + 1
            const rowEnd = row + height + 1
            const columnStart = column + 1
            const columnEnd = column + width + 1

            return render({
              id,
              element,
              rowStart,
              rowEnd,
              columnStart,
              columnEnd,
              index: index + 1,
            })
          })}
      </div>
    </div>
  )
}
