/* eslint-disable max-lines */
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import { useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import {
  IView,
  TableauWorkbook,
  useGetTableauWorkbookViews,
  useGetTableauWorkbooks,
  useGetToken,
} from '@features/data-sources'
import { TableauCoreDataSource } from '@features/data-sources/types'
import { useTableauScript } from '@features/embedding'
import { fixTableauFilterValues, getWorkbookUrl, isPublicTableau, isRangeValues } from '@features/embedding/tableau'
import { FilterType } from '@features/filters/types'

import { selectVisualisationToast, tableauStoryWarningToast } from '@shared/components'
import { useStateReducer } from '@shared/hooks'
import { findClosestString } from '@shared/utils'

import { useTableauBatchUpsertFilters } from './api'
import {
  EDashboardObjectType,
  EDateRangeType,
  ETableauDomainType,
  ETableauFilterType,
  ETableauSizingBehavior,
  FilterUpdateType,
  IColumn,
  IDataTable,
  IDataValue,
  IEmbedSheetSize,
  IFilterValues,
  INativeSheetSize,
  IRangeValues,
  IRelativeDate,
  ITableauFilter,
  ITableauFilterChangedEvent,
  ITableauFilterObject,
  ITableauFirstVizSizeKnownEvent,
  ITableauObject,
  ITableauParameter,
  ITableauParameterObject,
  ITableauRangeFilter,
  ITableauSwitchedEvent,
  JsonTableauFilter,
  JsonTableauMark,
  JsonTableauParameter,
  SelectionUpdateType,
  TableauEvent,
  TableauRangeValueType,
} from './types'

interface ITableauState {
  viewId: string
  viewContentUrl: string
  viewUrlName: string
  params: JsonTableauParameter
  filters: JsonTableauFilter
  marks: JsonTableauMark

  viewName: string
  selectedSheets: ITableauObject[]
  workbookId: string
  workbookUrl: string
  embedUrl: string
  workbookName: string
  workbookContentUrl: string
  fullDashboard: boolean
  originalWorkbookUrl: string
  objectId: number
  objectType: EDashboardObjectType
}

const initialTableauState: ITableauState = {
  workbookId: '',
  workbookUrl: '',
  workbookName: '',
  workbookContentUrl: '',
  viewId: '',
  viewContentUrl: '',
  viewUrlName: '',
  viewName: '',
  selectedSheets: [],
  marks: {},
  params: {},
  filters: {},
  fullDashboard: false,
  objectId: 0,
  originalWorkbookUrl: '',
  embedUrl: '',
  objectType: EDashboardObjectType.Worksheet,
}

const defaultSize = { width: 1000, height: 1000 }

const initDashboardSize: IEmbedSheetSize = {
  behavior: ETableauSizingBehavior.exactly,
  size: defaultSize,
}

export const exactDashboardMargin = 20
const timeoutToTokenRefresh = 540000 // 9 minutes

// TODO move marks, filter and params to a separate hook
// TODO move overlay & selected sheets to a separate hook
// TODO move types to types.ts file
// TODO make sure there's only one way street -> state & selected*

interface TableauProps {
  dataSource?: TableauCoreDataSource | null
  singleSelect?: boolean
  fetchDomain?: boolean
  viewSelectCallback?: (view: IView, resetFilters?: boolean) => void
  visSelectCallback?: (element: ITableauObject) => void
}

export const useTableau = ({
  dataSource,
  singleSelect = false,
  fetchDomain = false,
  viewSelectCallback,
  visSelectCallback,
}: TableauProps) => {
  const tableauRef = useRef<any>(null)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const [tableauFilterLoading, setTableauFilterLoading] = useState<boolean>(false)

  const [tableauVizKey, setTableauVizKey] = useState<string>(uuid())
  const [tableauViz, setTableauViz] = useState<any>(null)
  const [state, updateState] = useStateReducer<ITableauState>(initialTableauState)
  const [workbookOptions, setWorkbookOptions] = useState<TableauWorkbook[]>([])
  const [viewOptions, setViewOptions] = useState<IView[]>([])
  const [sheetOptions, setSheetOptions] = useState<string[]>([])
  const [selectedView, setSelectedView] = useState<IView | null>(null)
  const [selectedWorkbook, setSelectedWorkbook] = useState<TableauWorkbook | null>(null)

  const [dashboardSize, setDashboardSize] = useState<IEmbedSheetSize>(initDashboardSize)
  const [sheetObjects, setSheetObjects] = useState<ITableauObject[]>()
  const [viewParameters, setViewParameters] = useState<ITableauParameterObject[]>([])
  const [viewFilters, setViewFilters] = useState<ITableauFilterObject[]>([])
  const [filtersLoaded, setFiltersLoaded] = useState<boolean>(false)

  const [allMarks, _setAllMarks] = useState<JsonTableauMark>({})
  const [allParams, _setAllParams] = useState<JsonTableauParameter>({})
  const [allFilters, _setAllFilters] = useState<JsonTableauFilter>({})
  const allMarksRef = useRef(allMarks)
  const allParamsRef = useRef(allParams)
  const allFiltersRef = useRef(allFilters)

  const { data: workbooksData, isLoading: workbooksLoading } = useGetTableauWorkbooks(dataSource?.id)
  const workbookId = state.workbookId || workbookOptions.find(w => w.contentUrl === state.workbookContentUrl)?.id
  const { data: workbookViewsData } = useGetTableauWorkbookViews(dataSource?.id, workbookId)
  const { data: tableauToken, refetch: refetchToken } = useGetToken(dataSource?.id)

  useTableauScript(dataSource?.baseUrl)

  const { mutateAsync: batchUpsertFilters } = useTableauBatchUpsertFilters()

  const getActiveSheet = useCallback(() => {
    try {
      return tableauViz?.workbook?.activeSheet
    } catch (e) {
      return
    }
  }, [tableauViz])

  useEffect(() => {
    const credentialsId = dataSource?.id
    const dashboardId = state.viewId
    if (filtersLoaded && credentialsId && dashboardId && (viewFilters.length || viewParameters.length)) {
      const upsertFilters = viewFilters.map(f => ({
        dashboardFilterId: f._fieldId,
        type: FilterType.TableauFilter,
        name: f._fieldName,
      }))
      const upsertParams = viewParameters.map(p => ({
        dashboardFilterId: p.parameterImpl._globalFieldName,
        type: FilterType.TableauParams,
        name: p.parameterImpl._parameterInfo.name,
      }))
      const filters = upsertFilters.concat(upsertParams)
      batchUpsertFilters({
        dashboardId,
        credentialsId,
        filters,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewFilters, viewParameters])

  const parsePublicTableauUrl = useCallback(
    (workbookUrl: string) => {
      const workbookName = workbookUrl.split('/views/')[1].split('/')[0]
      const workbookContentUrl = workbookName
      const viewName = workbookUrl.split('/views/')[1].split('/')[1]
      const viewId = viewName
      const project = { id: workbookName, name: workbookName }
      const workbook = {
        id: workbookName,
        name: workbookName,
        contentUrl: workbookContentUrl,
        project,
        isDisabled: false,
      }
      const view = { id: viewId, name: viewName, contentUrl: viewName, viewUrlName: viewName }
      updateState({ workbookUrl, embedUrl: workbookUrl, workbookName, workbookContentUrl, viewName, viewId })
      setWorkbookOptions([workbook])
      setViewOptions([view])
    },
    [updateState, setWorkbookOptions, setViewOptions]
  )

  useEffect(() => {
    if (!dataSource) {
      return
    }

    const { baseUrl } = dataSource

    if (isPublicTableau(baseUrl)) {
      parsePublicTableauUrl(baseUrl)
      return
    }

    const workbooks = workbooksData?.workbooks?.workbook

    if (!workbooks) {
      return
    }

    workbooks.forEach(workbook => {
      if (!workbook.project) {
        workbook.project = { id: '', name: '' }
      } else if (!workbook.project.name) {
        workbook.project.name = ''
      }
    })

    setWorkbookOptions(workbooks)
  }, [dataSource, workbooksData, parsePublicTableauUrl])

  const getViews = useCallback(async () => {
    if (!dataSource) {
      return
    }

    if (isPublicTableau(dataSource.baseUrl)) {
      return
    }

    if (!workbookViewsData) {
      return
    }

    const views = workbookViewsData?.views

    setViewOptions(views)

    if (views.length) {
      const view = views.find(v => v.id === state.viewId) ?? views[0]
      handleSelectView(view)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, workbookViewsData, state.workbookContentUrl, state.workbookId, workbookOptions])

  const getSheets = useCallback(() => {
    let selectedSheet = tableauViz.workbook.activeSheet
    const worksheets = selectedSheet.worksheets ? selectedSheet.worksheets : [selectedSheet]
    const activeWorksheets = []
    for (const worksheet of worksheets) {
      activeWorksheets.push(worksheet.name)
    }
    setSheetOptions(activeWorksheets)
  }, [tableauViz])

  const resetTableauData = () => {
    setDashboardSize(initDashboardSize)
    updateState({
      workbookName: '',
      workbookId: '',
      workbookUrl: '',
      embedUrl: '',
      viewName: '',
      viewId: '',
      fullDashboard: false,
      selectedSheets: [],
    })
    setSelectedWorkbook(null)
    setWorkbookOptions([])
    setSheetOptions([])
    setViewOptions([])
  }

  const switchFullDashboard = () => {
    if (state.fullDashboard) {
      updateState({
        selectedSheets: [],
      })
    }
    updateState({ fullDashboard: !state.fullDashboard })
  }

  const setAllParams = useCallback(
    (params: typeof allParams) => {
      allParamsRef.current = params
      updateState({ params })
      _setAllParams(params)
    },
    [updateState]
  )

  const setAllFilters = useCallback(
    (filters: typeof allFilters) => {
      allFiltersRef.current = filters
      updateState({ filters })
      _setAllFilters(filters)
    },
    [updateState]
  )

  const setAllMarks = useCallback(
    (marks: typeof allMarks) => {
      allMarksRef.current = marks
      updateState({ marks })
      _setAllMarks(marks)
    },
    [updateState]
  )

  const resetTableauConfig = useCallback(() => {
    setAllFilters({})
    setAllMarks({})
    setAllParams({})
    setSheetObjects([])
    setViewParameters([])
    setViewFilters([])
    setFiltersLoaded(false)
  }, [setAllFilters, setAllMarks, setAllParams])

  const setCurrentFilters = useCallback(
    async (filters?: JsonTableauFilter) => {
      let selectedSheet = getActiveSheet()
      if (!selectedSheet) return
      const worksheets = selectedSheet.worksheets ? selectedSheet.worksheets : [selectedSheet]
      const allSheetFilters = await selectedSheet.getFiltersAsync()
      const currentFilters = filters ?? state.filters
      setAllFilters({ ...allFiltersRef.current, ...currentFilters })
      const promises = []

      for (const [fieldName, fieldValues] of Object.entries(currentFilters)) {
        const filterWorksheetNames = allSheetFilters
          .filter((filter: any) => filter._fieldName === fieldName)
          .map((filter: any) => filter._worksheetName)
        const filterWorksheets = worksheets.filter((sheet: any) => filterWorksheetNames.includes(sheet.name))

        for (const worksheet of filterWorksheets) {
          if ((fieldValues as IFilterValues).values) {
            const { values, isExcludeMode, isAllSelected } = fieldValues as IFilterValues
            const fixedValues = values.map(fixTableauFilterValues)
            if (isAllSelected) {
              promises.push(worksheet.applyFilterAsync(fieldName, fixedValues, FilterUpdateType.ALL))
            } else if (isExcludeMode) {
              promises.push(
                worksheet.applyFilterAsync(fieldName, fixedValues, FilterUpdateType.REPLACE, { isExcludeMode })
              )
            } else {
              promises.push(worksheet.applyFilterAsync(fieldName, fixedValues, FilterUpdateType.REPLACE))
            }
          } else if (isRangeValues(fieldValues)) {
            const { typeValue, minValue, maxValue } = fieldValues as IRangeValues
            if (!maxValue && !minValue) {
              continue
            }
            promises.push(
              worksheet.applyRangeFilterAsync(fieldName, {
                min: getRangeValue(typeValue, minValue),
                max: getRangeValue(typeValue, maxValue),
              })
            )
          } else {
            const { periodType, anchorDate, rangeType, rangeN } = fieldValues as IRelativeDate
            let appliedValues: any = { periodType, rangeType, rangeN }
            if (anchorDate) {
              appliedValues = { ...appliedValues, anchorDate: new Date(Date.parse(anchorDate)) }
            }
            promises.push(worksheet.applyRelativeDateFilterAsync(fieldName, appliedValues))
          }
        }
      }

      const results = await Promise.allSettled(promises)
      results.forEach(result => {
        if (result.status === 'rejected') {
          console.log(result.reason)
        }
      })
    },
    [state.filters, setAllFilters, getActiveSheet]
  )

  const setCurrentMarks = useCallback(
    async (marks?: JsonTableauMark) => {
      let selectedSheet = getActiveSheet()
      if (!selectedSheet) {
        return
      }
      const currentMarks = marks ?? state.marks
      setAllMarks({ ...allMarksRef.current, ...currentMarks })
      if (!isEmpty(currentMarks)) {
        const worksheets = selectedSheet.worksheets ? selectedSheet.worksheets : [selectedSheet]
        for (const worksheet of worksheets) {
          const marks = Object.entries(currentMarks).map(([fieldName, fieldValues]) => ({
            fieldName,
            value: [fieldValues],
          }))
          await worksheet.selectMarksByValueAsync(marks, SelectionUpdateType.REPLACE)
        }
      }
    },
    [state.marks, setAllMarks, getActiveSheet]
  )

  const setCurrentParams = useCallback(
    async (params?: JsonTableauParameter) => {
      const selectedSheet = getActiveSheet()
      if (!selectedSheet) return
      if (!tableauViz?.workbook) return
      const currentParams = params ?? state.params
      setAllParams({ ...allParamsRef.current, ...currentParams })
      for (const [paramName, paramValue] of Object.entries(currentParams)) {
        if (Array.isArray(paramValue)) {
          paramValue.forEach(async (value: any) => {
            try {
              await tableauViz?.workbook.changeParameterValueAsync(paramName, value)
            } catch (e) {
              console.log(e)
            }
          })
        } else {
          await tableauViz?.workbook.changeParameterValueAsync(paramName, paramValue)
        }
      }
    },
    [setAllParams, getActiveSheet, state.params, tableauViz]
  )

  const handleSelectWorkbook = (workbook: TableauWorkbook | null) => {
    setSelectedWorkbook(workbook)
    if (!workbook) return
    const { name, contentUrl } = workbook
    if (name === state.workbookName && contentUrl === state.workbookContentUrl) return

    const workbookId = workbook.id
    const workbookName = workbook.name
    const workbookContentUrl = workbook.contentUrl

    resetTableauConfig()
    updateState({
      workbookName,
      workbookContentUrl,
      workbookId,
      workbookUrl: '',
      embedUrl: '',
      viewName: '',
      viewId: '',
      selectedSheets: [],
    })
    setSheetOptions([])
  }

  const handleSelectView = useCallback(
    (view: IView | null) => {
      if (!dataSource || !view || !view.id || !view.viewUrlName || isEqual(view, selectedView)) {
        return
      }

      setSelectedView(view)
      viewSelectCallback && viewSelectCallback(view)

      const workbookUrl = getWorkbookUrl(
        dataSource.baseUrl,
        dataSource.siteName,
        state.workbookContentUrl,
        view.viewUrlName
      )

      if (selectedView && view.id !== selectedView.id) {
        resetTableauConfig()
      }

      updateState({
        viewName: view.name,
        viewId: view.id,
        viewContentUrl: view.contentUrl,
        viewUrlName: view.viewUrlName,
        workbookUrl,
        embedUrl: workbookUrl,
        selectedSheets: [],
      })
      setSheetOptions([])
      setDashboardSize(initDashboardSize)
    },
    [
      dataSource,
      selectedView,
      state.workbookContentUrl,
      viewSelectCallback,
      updateState,
      setSheetOptions,
      resetTableauConfig,
    ]
  )

  const handleSelectSheet = (tableauSheet: ITableauObject | null) => {
    if (!tableauSheet) return
    selectVisualisationToast(tableauSheet.name)

    if (singleSelect) {
      updateState({ selectedSheets: [tableauSheet] })
      visSelectCallback && visSelectCallback(tableauSheet)
      return
    }

    const selectedSheets = [...state.selectedSheets]
    selectedSheets.push(tableauSheet)
    updateState({ selectedSheets })
  }

  const handleUnselectSheet = (tableauSheet: ITableauObject) => {
    const selectedSheets = [...state.selectedSheets]
    const index = selectedSheets.indexOf(tableauSheet)
    if (index > -1) {
      selectedSheets.splice(index, 1)
    }

    updateState({ selectedSheets })
  }

  const getRangeValue = (typeValue: string, value: string) => {
    return typeValue === TableauRangeValueType.any ? value : new Date(Date.parse(value))
  }

  const getSummaryData = useCallback(
    async (worksheetName: string) => {
      const selectedSheet = tableauViz.workbook.activeSheet
      const worksheets = selectedSheet.worksheets ? selectedSheet.worksheets : [selectedSheet]
      for (const worksheet of worksheets) {
        try {
          if (worksheet.name !== worksheetName) continue
          const summaryData: IDataTable = await worksheet.getSummaryDataAsync()
          const tables = await worksheet.getUnderlyingTablesAsync()
          let dataTable
          for (const table of tables) {
            const tableId = table.id
            dataTable = await worksheet.getUnderlyingTableDataAsync(tableId, {
              maxRows: 10,
              ignoreAliases: false,
              ignoreSelection: true,
              includeAllColumns: false,
            })
          }
          return { summaryData, dataTable }
        } catch (e) {
          console.log(e)
        }
      }
    },
    [tableauViz]
  )

  // todo: parse event to get the selected marks
  const handleMarkSelection = useCallback(
    async (event: any) => {
      const worksheetName = event.detail.worksheet.name
      const markEvent = await event.detail.getMarksAsync()
      const currentMarks: typeof allMarks = {}
      const data = await getSummaryData(worksheetName)
      if (!data) return
      const { summaryData, dataTable } = data
      markEvent.data[0]._columns.forEach((column: any, idx: number) => {
        const fieldName = column._fieldName
        const fieldValue = markEvent.data[0]._data[0][idx]
        let formattedValue = fieldValue._formattedValue
        let colIndex = summaryData._columns.findIndex((col: IColumn) => col._fieldName === fieldName)
        const dataSourceColumns = dataTable._columns.map((col: IColumn) => col._fieldName)
        if (!dataSourceColumns.includes(fieldName)) {
          return
        }
        if (summaryData) {
          summaryData._data.forEach((row: IDataValue[]) => {
            if (row[colIndex]._value === fieldValue._value) {
              formattedValue = row[colIndex]._formattedValue
            }
          })
        }
        currentMarks[fieldName] = formattedValue
      })
      setAllMarks(currentMarks)
    },
    [getSummaryData, setAllMarks]
  )

  const addFilterIfNotInView = (filter: any) => {
    setViewFilters(viewFilters => {
      const filterFound = viewFilters.find(viewFilter => viewFilter._fieldName === filter._fieldName)
      if (filterFound) {
        return viewFilters
      }
      const newFilter = { ...filter }
      delete newFilter._appliedValues
      delete newFilter.appliedValues
      return [...viewFilters, newFilter]
    })
  }

  const handleFilterChanged = useCallback(
    async (event: CustomEvent<ITableauFilterChangedEvent>) => {
      setTableauFilterLoading(true)
      const filterEvent = await event.detail.getFilterAsync()
      addFilterIfNotInView(filterEvent)
      if (!filterEvent._filterType) {
        setTableauFilterLoading(false)
        return
      }
      const fieldName = filterEvent._fieldName
      let appliedValues
      switch (filterEvent._filterType) {
        case ETableauFilterType.RANGE:
          const { _min, _max } = filterEvent
          const minValue = _min._value
          const maxValue = _max._value
          const typeValue = _max._value instanceof Date ? TableauRangeValueType.date : TableauRangeValueType.any
          appliedValues = { minValue, maxValue, typeValue }
          break
        case ETableauFilterType.RELATIVE_DATE:
          const { _anchorDate, _periodType, _rangeType, _rangeN } = filterEvent
          appliedValues = {
            anchorDate: _anchorDate._value,
            periodType: _periodType,
            rangeType: _rangeType,
            rangeN: _rangeN,
          }
          break
        // categorical or hierarchical filter
        default:
          appliedValues = {
            values: (filterEvent._appliedValues ?? []).map(value => value._formattedValue),
            isExcludeMode: filterEvent.isExcludeMode,
            isAllSelected: filterEvent.isAllSelected,
          }
      }
      setAllFilters({ ...allFiltersRef.current, [fieldName]: appliedValues } as JsonTableauFilter)
      setTableauFilterLoading(false)
    },
    [setAllFilters]
  )

  const addParameterIfNotInView = (parameter: any) => {
    setViewParameters(viewParameters => {
      const parameterFound = viewParameters.find(viewParameter => viewParameter.name === parameter.name)
      if (parameterFound) {
        return viewParameters
      }
      return [...viewParameters, parameter]
    })
  }

  const handleParameterChanged = useCallback(
    async (event: any) => {
      setTableauFilterLoading(true)
      const paramEvent = await event.detail.getParameterAsync()
      addParameterIfNotInView(paramEvent)
      const { name, currentValue } = paramEvent
      let value
      if (paramEvent.allowableValues.type === 'list') {
        value = currentValue._formattedValue
      } else {
        value = currentValue._value
      }

      setAllParams({
        ...allParamsRef.current,
        [name]: value,
      })
      setTableauFilterLoading(false)
    },
    [setAllParams]
  )

  const getTableauParameters = useCallback(
    async (parameterObjects: ITableauObject[]) => {
      const dashboardParams = (await tableauViz.workbook.getParametersAsync()) as ITableauParameter[]

      const viewParams: ITableauParameterObject[] = dashboardParams
        .filter((dashboardParam: ITableauParameter) => dashboardParam.allowableValues)
        .map((dashboardParam: ITableauParameter) => {
          let parameterObject = parameterObjects.find(paramObj => paramObj.name === dashboardParam.name)
          if (!parameterObject) {
            const closestMatchName = findClosestString(
              dashboardParam.name,
              parameterObjects.map(obj => obj.name)
            )
            if (closestMatchName) {
              parameterObject = parameterObjects.find(paramObj => paramObj.name === closestMatchName)
            }
          }
          const paramWithObject: ITableauParameterObject = {
            id: dashboardParam.id,
            name: dashboardParam.name,
            dataType: dashboardParam.dataType,
            currentValueType: dashboardParam.currentValueType,
            allowableValues: dashboardParam.allowableValues,
            parameterImpl: dashboardParam.parameterImpl,
            ...(parameterObject ? { object: parameterObject } : {}),
          }

          return paramWithObject
        })

      return viewParams
    },
    [tableauViz]
  )

  const updateDateRangeFilter = (filter: ITableauFilterObject) => {
    const fieldName = filter._fieldName
    if (filter._filterType === ETableauFilterType.RANGE) {
      const { datePeriodType, dateRangeType, dateRangeN } = filter
      if (dateRangeType === EDateRangeType.toDate || (datePeriodType && dateRangeType && dateRangeN)) {
        const appliedValues = {
          ...allFilters[fieldName],
          datePeriodType,
          dateRangeType,
          dateRangeN,
        } as ITableauRangeFilter
        setAllFilters({ ...allFiltersRef.current, [fieldName]: appliedValues })
      } else {
        const updatedFilters = { ...allFiltersRef.current }
        delete updatedFilters[fieldName]
        setAllFilters(updatedFilters)
      }
    }
  }

  const getTableauFilters = useCallback(
    async (filterObjects: ITableauObject[]) => {
      setFiltersLoaded(false)
      let selectedSheet = tableauViz.workbook.activeSheet
      const worksheet = selectedSheet.worksheets ? selectedSheet.worksheets[0] : selectedSheet
      if (!worksheet || typeof worksheet.getFiltersAsync !== 'function') {
        setFiltersLoaded(true)
        return []
      }

      let dashboardFilters = (await selectedSheet.getFiltersAsync()) as ITableauFilter[]

      dashboardFilters = uniqWith(dashboardFilters, (filter1, filter2) => filter1._fieldId === filter2._fieldId)

      dashboardFilters = dashboardFilters
        .filter((filter: ITableauFilter) => {
          if (filter._filterType !== ETableauFilterType.CATEGORICAL) {
            return true
          }
          return (filter.appliedValues?.length ?? 0) > 0 || filter.isAllSelected
        })
        .map((filter: ITableauFilter) => {
          if (filter._filterType !== ETableauFilterType.CATEGORICAL) {
            return filter
          }
          if (filter._appliedValues) {
            delete filter._appliedValues
          }
          return filter
        })

      const viewFiltersPromises = dashboardFilters.map(async (dashboardFilter: ITableauFilter) => {
        let filterObject = filterObjects.find(obj => obj.name === dashboardFilter._fieldName)
        if (!filterObject) {
          const closestMatchName = findClosestString(
            dashboardFilter._fieldName,
            filterObjects.map(obj => obj.name)
          )
          if (closestMatchName) {
            filterObject = filterObjects.find(obj => obj.name === closestMatchName)
          }
        }
        if (
          fetchDomain &&
          (dashboardFilter._filterType === ETableauFilterType.CATEGORICAL ||
            dashboardFilter._filterType === ETableauFilterType.RANGE) &&
          typeof dashboardFilter.getDomainAsync === 'function'
        ) {
          let domain = await dashboardFilter.getDomainAsync()
          if (domain && !domain.values?.length) {
            domain = await dashboardFilter.getDomainAsync(ETableauDomainType.database)
          }
          dashboardFilter.domain = domain
        }
        return {
          ...dashboardFilter,
          ...(filterObject && { object: filterObject }),
        }
      })

      const viewFiltersResults = await Promise.allSettled(viewFiltersPromises)
      const viewFilters = viewFiltersResults
        .filter(result => result.status === 'fulfilled')
        .map(result => (result as any).value)

      setFiltersLoaded(true)

      return viewFilters
    },
    [tableauViz, fetchDomain]
  )

  const overlaySheets = useCallback(async () => {
    if (!tableauViz) return
    let selectedSheet = tableauViz.workbook.activeSheet
    let objectsInfo: ITableauObject[] = []
    // case when workbook has only one sheet
    if (!selectedSheet?.objects) {
      objectsInfo = [
        {
          id: 1,
          name: selectedSheet.name,
          type: EDashboardObjectType.Worksheet,
          size: dashboardSize.size,
          position: { x: 0, y: 0 },
        },
      ]
      setSheetObjects(objectsInfo)
    } else {
      // case when workbook has more than one sheet
      objectsInfo = selectedSheet.objects.map((object: ITableauObject) => {
        const { id, name, type, size, position } = object
        return { id, name, type, size, position } as ITableauObject
      })

      const worksheets = objectsInfo.filter(
        (object: ITableauObject) =>
          object.type === EDashboardObjectType.Worksheet || object.type === EDashboardObjectType.Legend
      )
      setSheetObjects(worksheets)
    }

    const parameters = objectsInfo.filter(
      (object: ITableauObject) => object.type === EDashboardObjectType.ParameterControl
    )
    const filters = objectsInfo.filter((object: ITableauObject) => object.type === EDashboardObjectType.QuickFilter)

    const viewParams = await getTableauParameters(parameters)
    const viewFilters = await getTableauFilters(filters)

    setViewParameters(viewParams)
    setViewFilters(viewFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableauRef, dashboardSize, tableauViz])

  const adjustSize = useCallback(
    ({ behavior, maxSize = defaultSize, minSize = defaultSize }: INativeSheetSize) => {
      let { width, height } = dashboardSize.size

      switch (behavior) {
        case ETableauSizingBehavior.exactly:
          width = maxSize.width + exactDashboardMargin
          height = maxSize.height + exactDashboardMargin
          break
        case ETableauSizingBehavior.atleast:
          width = minSize.width ?? defaultSize.width
          height = minSize.height ?? defaultSize.height
          break
        case ETableauSizingBehavior.automatic:
        case ETableauSizingBehavior.range:
        default:
          width = minSize.width
          height = minSize.height
          break
      }

      const newSize = { size: { width, height }, behavior }
      if (isEqual(newSize, dashboardSize)) return
      setDashboardSize(newSize)
      setTableauVizKey(uuid())
    },
    [dashboardSize]
  )

  const checkIfTableauStory = useCallback(() => {
    if (!tableauViz) return
    const activeSheet = tableauViz.workbook.activeSheet
    return activeSheet?.sheetType === 'story'
  }, [tableauViz])

  const initConnection = useCallback(async () => {
    if (checkIfTableauStory()) {
      tableauStoryWarningToast()
    }
    await overlaySheets()
    getSheets()

    if (state.workbookUrl === state.originalWorkbookUrl) {
      await setCurrentFilters()
      await setCurrentMarks()
      await setCurrentParams()
    }
  }, [
    overlaySheets,
    getSheets,
    state.workbookUrl,
    state.originalWorkbookUrl,
    setCurrentFilters,
    setCurrentMarks,
    setCurrentParams,
    checkIfTableauStory,
  ])

  useEffect(() => {
    if (!state.workbookContentUrl) return

    getViews()
  }, [state.workbookContentUrl, dataSource, workbookOptions, getViews])

  const handleSelectTab = useCallback(
    async (view: IView | null) => {
      if (!dataSource || !view || !view.id || !view.viewUrlName || isEqual(view, selectedView)) {
        return
      }
      const workbookUrl = getWorkbookUrl(
        dataSource.baseUrl,
        dataSource.siteName,
        state.workbookContentUrl,
        view.viewUrlName
      )
      updateState({
        viewName: view.name,
        viewId: view.id,
        viewContentUrl: view.contentUrl,
        viewUrlName: view.viewUrlName,
        workbookUrl,
        selectedSheets: [],
      })
      await overlaySheets()
      getSheets()
    },
    [dataSource, selectedView, state.workbookContentUrl, updateState, overlaySheets, getSheets]
  )

  const handleTabSwitched = useCallback(
    (e: CustomEvent<ITableauSwitchedEvent>) => {
      const selectedTab = viewOptions.find(view => view.name === e.detail.newSheetName)
      if (selectedTab) {
        handleSelectTab(selectedTab)
      }
    },
    [viewOptions, handleSelectTab]
  )

  const handleFirstVizSizeKnown = useCallback(
    (e: CustomEvent<ITableauFirstVizSizeKnownEvent>) => {
      const sheetSize = e.detail._vizSize._sheetSize
      adjustSize(sheetSize)
    },
    [adjustSize]
  )

  useEffect(() => {
    if (!tableauViz || !tableauRef.current || !state.workbookUrl) return

    const vizEl = tableauRef.current
    vizEl.addEventListener(TableauEvent.FirstInteractive, initConnection)
    vizEl.addEventListener(TableauEvent.FirstVizSizeKnown, handleFirstVizSizeKnown)
    vizEl.addEventListener(TableauEvent.OnLoad, initConnection)
    vizEl.addEventListener(TableauEvent.ParameterChanged, handleParameterChanged)
    vizEl.addEventListener(TableauEvent.MarkSelectionChanged, handleMarkSelection)
    vizEl.addEventListener(TableauEvent.FilterChanged, handleFilterChanged)
    vizEl.addEventListener(TableauEvent.TabSwitched, handleTabSwitched)
    return () => {
      if (vizEl) {
        vizEl.removeEventListener(TableauEvent.FirstInteractive, initConnection)
        vizEl.removeEventListener(TableauEvent.FirstVizSizeKnown, handleFirstVizSizeKnown)
        vizEl.removeEventListener(TableauEvent.OnLoad, initConnection)
        vizEl.removeEventListener(TableauEvent.ParameterChanged, handleParameterChanged)
        vizEl.removeEventListener(TableauEvent.MarkSelectionChanged, handleMarkSelection)
        vizEl.removeEventListener(TableauEvent.FilterChanged, handleFilterChanged)
        vizEl.removeEventListener(TableauEvent.TabSwitched, handleTabSwitched)
      }
    }
  }, [
    tableauRef,
    tableauViz,
    state.workbookUrl,
    handleFilterChanged,
    handleFirstVizSizeKnown,
    handleMarkSelection,
    handleParameterChanged,
    handleTabSwitched,
    initConnection,
  ])

  useEffect(() => {
    // Function to fetch token and schedule a refresh
    const fetchTokenAndScheduleRefresh = async () => {
      await refetchToken()
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current)
      }
      // Set the timeout to refresh the token 9 minutes after it was fetched (540000 seconds)
      timeoutId.current = setTimeout(fetchTokenAndScheduleRefresh, timeoutToTokenRefresh)
    }

    fetchTokenAndScheduleRefresh()

    return () => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, state.workbookName, state.viewName])

  return {
    state,
    tableauRef,
    tableauVizKey,
    tableauViz,
    tableauToken,
    selectedWorkbook,
    selectedView,
    workbooksLoading,
    workbookOptions,
    viewOptions,
    sheetOptions,
    sheetObjects,
    viewParameters,
    viewFilters,
    filtersLoaded,
    dashboardSize,
    tableauFilterLoading,
    updateState,
    resetTableauData,
    setTableauViz,
    handleSelectWorkbook,
    handleSelectView,
    handleSelectSheet,
    handleUnselectSheet,
    updateDateRangeFilter,
    switchFullDashboard,
    setCurrentFilters,
    setCurrentMarks,
    setCurrentParams,
    getActiveSheet,
    resetTableauConfig,
  }
}
