import { useEffect, useRef } from 'react'

export const useAutoFocus = <T extends HTMLElement>(autoFocus = false) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (ref.current && autoFocus) {
      ref.current.focus()
    }
  }, [autoFocus, ref])

  return ref
}
