import { ColumnDef } from '@tanstack/react-table'

import { Frequency } from '@features/destinations/types'
import { HistoricalRunOverviewItem, HistoricalRunTrigger } from '@features/historical-run/types'

import { Avatar } from '@shared/components'
import { formatDate } from '@shared/utils'

interface RowData {
  id: string
  userName: string | null | undefined
  anonymizedDestinationName: string | null
  historicalRunSyncsCount: number
  trigger: HistoricalRunTrigger
  frequency: Frequency | null | undefined
  startedAt: Date
}

export const usageTableData = (historicalRunOverviewList: HistoricalRunOverviewItem[]): RowData[] =>
  historicalRunOverviewList.map((historicalRun: HistoricalRunOverviewItem) => ({
    id: historicalRun.id,
    userName: historicalRun.userName,
    anonymizedDestinationName: historicalRun.anonymizedDestinationName,
    historicalRunSyncsCount: historicalRun.historicalRunSyncsCount,
    trigger: historicalRun.trigger,
    frequency: historicalRun.destinationFrequency,
    startedAt: historicalRun.startedAt,
  }))

export const usageTableColumns = (): ColumnDef<RowData>[] => [
  {
    accessorKey: 'userName',
    header: 'User',
    size: 200,
    cell: ({ row }) => (
      <Avatar name={row.original.userName || 'Anonymous user'}>{row.original.userName || 'Anonymous user'}</Avatar>
    ),
    enableSorting: true,
  },
  {
    accessorKey: 'anonymizedDestinationName',
    header: 'Destination',
    size: 200,
    cell: info => info.getValue(),
    enableSorting: true,
  },
  {
    accessorKey: 'historicalRunSyncsCount',
    header: 'Visualizations count',
    size: 200,
    cell: info => info.getValue(),
    enableSorting: true,
  },
  {
    accessorKey: 'trigger',
    header: 'Trigger',
    size: 200,
    cell: info => info.getValue(),
    enableSorting: true,
  },
  {
    accessorKey: 'frequency',
    header: 'Frequency',
    size: 200,
    cell: info => info.getValue(),
    enableSorting: true,
  },
  {
    accessorKey: 'startedAt',
    header: 'Started at',
    size: 200,
    cell: info => formatDate(info.getValue() as Date),
    enableSorting: true,
  },
]
