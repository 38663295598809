import { useEffect, useRef, useState } from 'react'

export const useHover = <T = HTMLDivElement>() => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const hoverRef = useRef<T | null>(null)

  const handleMouseOver = () => setIsHover(true)
  const handleMouseOut = () => setIsHover(false)

  useEffect(() => {
    const node: any = hoverRef.current

    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)
      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [hoverRef])

  return { hoverRef, isHover }
}
