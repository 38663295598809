import { Paths } from '@pages/routes/paths'

import { LinkButton } from '@shared/components/button/link-button/link-button'

import styles from './not-found.module.css'

export const NotFound = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Resource not found</h2>
      <div className={styles.content}>
        The resource you are looking for doesn't exist or you don’t have the necessary permissions to view it.
      </div>
      <LinkButton text="Go Home" to={Paths.DESTINATIONS} />
    </div>
  )
}
