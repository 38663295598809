import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { EditorContent, generateHTML, generateJSON, useEditor } from '@tiptap/react'
import xml from 'highlight.js/lib/languages/xml'
import { createLowlight } from 'lowlight'
import pretty from 'pretty'
import { useEffect } from 'react'

import { getBaseEditorOptions } from '@features/emails/components/templates/editors/tiptap-settings'
import { EmailTemplate } from '@features/emails/types'

import { EmailTemplateMenuBar } from './email-template-menu-bar'

import styles from './email-template-html-editor.module.css'
import 'highlight.js/styles/agate.min.css'

const lowlight = createLowlight()

lowlight.register({ xml })

export const EmailTemplateHTMLEditor = ({
  content,
  setContent,
  toggleHTMLEditorMode,
}: Pick<EmailTemplate, 'content'> & {
  setContent: (content: string) => void
  toggleHTMLEditorMode: () => void
}) => {
  const editorOptions = getBaseEditorOptions(content)
  const { extensions = [] } = editorOptions

  const editor = useEditor(
    {
      ...editorOptions,
      extensions: [...(editorOptions.extensions || []), CodeBlockLowlight.configure({ lowlight })],
    },
    []
  )

  useEffect(() => {
    const formattedContent = pretty(content)
    const parsedContent = formattedContent.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
    editor?.commands.setContent(`<pre><code class="language-xml">${parsedContent}</code></pre>`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  editor?.on('update', ({ editor }) => {
    const rawContent = editor.getHTML()
    const docBlock = generateJSON(rawContent, extensions)
    const isEmpty =
      docBlock.content.length === 1 && docBlock.content[0].type === 'paragraph' && !docBlock.content[0].content
    if (isEmpty) {
      editor?.commands.setContent('<pre><code class="language-xml"></code></pre>')
    } else {
      const html = generateHTML(docBlock, extensions)
      const newContent = html.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
      setContent(newContent)
    }
  })

  if (!editor) return null
  return (
    <div className="h-full flex flex-col">
      <EmailTemplateMenuBar editor={editor} toggleHTMLEditorMode={toggleHTMLEditorMode} isHTMLEditorMode />
      <EditorContent className={styles.editor} editor={editor} />
    </div>
  )
}
