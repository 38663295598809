import debounce from 'lodash/debounce'
import React, { useState } from 'react'

import { Destination } from '@features/destinations/types'
import { useDeleteSyncs } from '@features/syncs/api'

import { ActionModal, Button } from '@shared/components'
import { useModalWithData } from '@shared/hooks'

export interface DeleteSyncsMutation {
  syncIds: string[] | string
  destination: Destination
  collectionId?: string
  callback?: () => void
}

export interface WithSyncDeleteProps {
  isDeleting: boolean
  deleteSyncsWithConfirmation: (props: DeleteSyncsMutation) => void
}

const debounceTimeout = 250

export const withSyncDelete = <T,>(WrappedComponent: React.ComponentType<T & WithSyncDeleteProps>) => {
  return (props: T) => {
    const [collectionId, setCollectionId] = useState<string | undefined>()
    const [destination, setDestination] = useState<Destination | undefined>()
    const [syncCount, setSyncCount] = useState(1)
    const [modal, syncsToDelete, toggleModal, openModalWithData] = useModalWithData<string[]>()
    const deleteSyncsQuery = useDeleteSyncs({
      destination: destination,
      collectionId,
      onSuccess: () => {
        toggleModal(false)
      },
    })

    const deleteSyncs = () => {
      if (!syncsToDelete || !syncsToDelete.length) return

      deleteSyncsQuery.mutate({
        syncIds: syncsToDelete,
      })
    }

    const triggerDeleteSyncs = ({ syncIds, destination, collectionId, callback }: DeleteSyncsMutation) => {
      setDestination(destination)
      setCollectionId(collectionId)

      if (Array.isArray(syncIds)) {
        setSyncCount(syncIds.length)
        openModalWithData(syncIds)
      } else {
        setSyncCount(1)
        openModalWithData([syncIds])
      }
      callback && callback()
    }

    const debouncedDeleteSyncs = debounce(deleteSyncs, debounceTimeout, { leading: true, trailing: false })

    const deleteModalTitle = syncCount === 1 ? `Delete a visualization` : `Delete ${syncCount} visualizations`
    const dynamicText = syncCount === 1 ? 'this visualization' : 'these visualizations'
    const deleteModalSubtitle = `Are you sure you want to delete ${dynamicText}? This will also delete ${dynamicText} from your deck.`

    return (
      <>
        <WrappedComponent
          {...props}
          deleteSyncsWithConfirmation={triggerDeleteSyncs}
          isDeleting={deleteSyncsQuery.isLoading}
        />
        <ActionModal
          open={modal}
          title={deleteModalTitle}
          subtitle={deleteModalSubtitle}
          onBackgroundClick={toggleModal}
          btns={
            <>
              <Button secondaryGray text="Cancel" onClick={toggleModal} fullWidth />
              <Button
                fullWidth
                primaryError
                text="Delete"
                onClick={debouncedDeleteSyncs}
                loading={deleteSyncsQuery.isLoading}
              />
            </>
          }
        />
      </>
    )
  }
}
