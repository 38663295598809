import { FC } from 'react'

import { Modal, Spinner } from '@shared/components'

import styles from './modal-updating.module.css'

interface IModalUpdating {
  title?: string
  visible?: boolean
}

export const ModalUpdating: FC<IModalUpdating> = ({ visible = false, title = 'Updating the Presentation' }) => {
  return (
    <Modal open={visible} cross={false} className={styles.container}>
      <div className={styles.modalContainer}>
        <Spinner center={false} />
        <span className={styles.modalTitle}>{title}</span>
      </div>
    </Modal>
  )
}
