import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'

import { isLookerDataSource, isMetabaseDataSource, isTableauDataSource } from '@features/data-sources/utils'
import { useGetSyncsWhereFiltersAreApplicable } from '@features/destinations'
import { useActiveDestinationContext } from '@features/destinations/active-destination-provider'
import { JsonTableauParameter } from '@features/embedding/tableau/types'
import { useBatchApplyFilters } from '@features/syncs/api'
import { buildJsonFilters } from '@features/syncs/hooks'
import { IBatchApplyFilterRequest, ISync } from '@features/syncs/types'

import { queryClient } from '@shared/http'

import { IntegrationDataKey, SyncData } from './types'
import { VanityData } from './use-vanity-data'

interface FiltersLogicProps {
  originalSync: ISync | null
  syncData: SyncData
  updateVanityData: React.Dispatch<Partial<VanityData>>
  updateSyncData: React.Dispatch<Partial<SyncData>>
}

export const useFiltersLogic = ({ originalSync, syncData, updateVanityData, updateSyncData }: FiltersLogicProps) => {
  const { destinationId, collectionId } = useActiveDestinationContext()
  const { mutateAsync: batchApplyFiltersMutation } = useBatchApplyFilters({ destinationId, collectionId })

  const { tableauData, lookerData, metabaseData, dataSource } = syncData

  const paramsSelected: JsonTableauParameter = useMemo(
    () => (tableauData?.params ? JSON.parse(tableauData.params) : {}),
    [tableauData?.params]
  )
  const filtersSelected = useMemo(() => {
    if (!dataSource) return {}

    if (isLookerDataSource(dataSource) && lookerData?.filters) {
      return JSON.parse(lookerData.filters ?? '{}')
    } else if (isMetabaseDataSource(dataSource) && metabaseData?.filters) {
      return JSON.parse(metabaseData.filters ?? '{}')
    } else if (isTableauDataSource(dataSource) && tableauData?.filters) {
      return JSON.parse(tableauData.filters ?? '{}')
    }

    return {}
  }, [dataSource, lookerData?.filters, metabaseData?.filters, tableauData?.filters])

  const newFiltersSelected = useMemo(() => {
    let changes: any = {}

    if (originalSync) {
      const { jsonFilters: originalFilters } = buildJsonFilters(originalSync)

      for (const key of Object.keys(filtersSelected)) {
        const originalValue = originalFilters[key]
        const selectedValue = filtersSelected[key]
        if (!isEqual(originalValue, selectedValue)) {
          changes[key] = selectedValue
        }
      }
    }

    return changes
  }, [filtersSelected, originalSync])

  const newParamsSelected = useMemo(() => {
    let changes: any = {}

    if (originalSync) {
      const { jsonParams: originalParams } = buildJsonFilters(originalSync)

      for (const key of Object.keys(paramsSelected)) {
        const originalValue = originalParams[key]
        const selectedValue = paramsSelected[key]
        if (!isEqual(originalValue, selectedValue)) {
          changes[key] = selectedValue
        }
      }
    }

    return changes
  }, [paramsSelected, originalSync])

  const { data: validSyncsForSelectedFilters, isLoading: isLoadingValidSyncsForSelectedFilters } =
    useGetSyncsWhereFiltersAreApplicable({
      destinationId: originalSync?.presentationId,
      fromSyncId: originalSync?.id,
      filters: JSON.stringify(newFiltersSelected),
      params: JSON.stringify(newParamsSelected),
    })

  const batchApplyFilters = async (selectedSyncs: ISync[], ignoreOriginalSyncUpdate = false) => {
    updateVanityData({ isSavingSync: true })

    if (originalSync && selectedSyncs.length && dataSource) {
      const selectedSyncIds = selectedSyncs.map(({ id }) => id)

      if (!ignoreOriginalSyncUpdate) {
        selectedSyncIds.push(originalSync.id)
      }

      const applyFiltersPayload: IBatchApplyFilterRequest = {
        fromSyncId: originalSync.id,
        jsonFilters: syncData.tableauData?.filters || syncData.lookerData?.filters || syncData.metabaseData?.filters,
        syncIds: selectedSyncIds,
      }

      if (isTableauDataSource(dataSource)) {
        applyFiltersPayload.jsonParams = syncData.tableauData?.params
      }

      await batchApplyFiltersMutation(applyFiltersPayload)

      queryClient.invalidateQueries(['presentation'])
      queryClient.invalidateQueries(['presentations'])
    }
    updateVanityData({ isSavingSync: false })
  }

  const clearSyncDataFilters = (dataKey: IntegrationDataKey) => {
    if (syncData[dataKey]) {
      updateSyncData({
        [dataKey]: {
          ...syncData[dataKey],
          filters: '',
          params: '',
        },
      })
    }
  }
  const handleRemoveFilter = (filterKey: string) => {
    const updatedFilters = { ...filtersSelected }
    const updatedParams = { ...paramsSelected }

    delete updatedFilters[filterKey]
    delete updatedParams[filterKey]

    if (syncData.lookerData) {
      updateSyncData({
        lookerData: {
          ...syncData.lookerData,
          filters: JSON.stringify(updatedFilters),
        },
      })
    } else if (syncData.tableauData) {
      updateSyncData({
        tableauData: {
          ...syncData.tableauData,
          filters: JSON.stringify(updatedFilters),
          params: JSON.stringify(updatedParams),
        },
      })
    } else if (syncData.metabaseData) {
      updateSyncData({
        metabaseData: {
          ...syncData.metabaseData,
          filters: JSON.stringify(updatedFilters),
        },
      })
    }
    updateVanityData({ filtersChanged: true })
  }

  const removeFilters = () => {
    if (!dataSource) return

    updateVanityData({ filtersChanged: false, filtersRemoved: true })
    clearSyncDataFilters('tableauData')
    clearSyncDataFilters('lookerData')
    clearSyncDataFilters('metabaseData')
  }

  return {
    filtersSelected,
    paramsSelected,
    validSyncsForSelectedFilters,
    isLoadingValidSyncsForSelectedFilters,
    newFiltersSelected,
    newParamsSelected,
    batchApplyFilters,
    handleRemoveFilter,
    removeFilters,
  }
}
