import { FC, PropsWithChildren, createContext, useState } from 'react'

import { AuthModal, GOOGLE_DRIVE_SCOPES, MICROSOFT_DRIVE_SCOPES } from '@features/drive'
import { OAuthType, useAuthStore } from '@features/users'

export interface ShowAuthModalParams {
  type: OAuthType
  title?: string
  message?: string
  successCallback?: () => void
  handleClose?: () => void
}

export interface OAuthContextProps {
  showAuthModal: (params: ShowAuthModalParams) => void
  hideAuthModal: () => void
}

export const OAuthContext = createContext<OAuthContextProps>({
  showAuthModal: () => {},
  hideAuthModal: () => {},
})

const googleDefaultMessage = 'We need additional authorization to access your Google Drive. Please sign in to continue.'
const microsoftDefaultMessage =
  'We need additional authorization to access your Microsoft OneDrive. Please sign in to continue.'

export const OAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuthStore()

  const [authModalVisible, setAuthModalVisible] = useState(false)
  const [type, setType] = useState<OAuthType>(OAuthType.GOOGLE)
  const [authTitle, setAuthTitle] = useState<string | undefined>()
  const [authContent, setAuthContent] = useState<string>(googleDefaultMessage)
  const [successCallback, setSuccessCallback] = useState<() => void | undefined>()
  const [handleClose, setHandleClose] = useState<() => void | undefined>()

  const showAuthModal = ({ type, title, message, successCallback, handleClose }: ShowAuthModalParams) => {
    setType(type)
    setAuthContent(message || (type === OAuthType.GOOGLE ? googleDefaultMessage : microsoftDefaultMessage))
    setAuthTitle(title)
    if (successCallback) {
      setSuccessCallback(() => successCallback)
    } else {
      setSuccessCallback(undefined)
    }
    if (handleClose) {
      setHandleClose(() => handleClose)
    } else {
      setHandleClose(undefined)
    }
    setAuthModalVisible(true)
  }

  const hideAuthModal = () => {
    setAuthModalVisible(false)
  }

  return (
    <OAuthContext.Provider value={{ showAuthModal, hideAuthModal }}>
      {children}
      {authModalVisible && (
        <AuthModal
          authModalVisible={authModalVisible}
          scopes={type === OAuthType.GOOGLE ? GOOGLE_DRIVE_SCOPES : MICROSOFT_DRIVE_SCOPES}
          email={user?.email}
          setAuthModalVisible={open => setAuthModalVisible(open)}
          authTitle={authTitle}
          authContent={authContent}
          type={type}
          successCallback={successCallback}
          handleClose={handleClose}
          skipAuthorizeDriveToast={{ onError: true, onSuccess: true }}
        />
      )}
    </OAuthContext.Provider>
  )
}
