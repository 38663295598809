import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 60

export const EmailDestinationIcon: FC<IIcon> = ({ size = DEFAULT_SIZE }) => (
  <svg width={size} viewBox="0 0 60 57" fill="none">
    <path
      d="M0.0761719 18.9819C0.152707 18.6774 0.266695 18.2605 0.569577 17.8046C0.948995 17.2346 1.40495 16.8927 1.67038 16.7038L27.9104 0.944126C29.1643 0.185291 30.7585 0.185291 32.0498 0.944126L57.6434 16.3993C58.6693 17.0067 59.4655 17.956 59.9215 19.0568L59.998 19.3222L30.1902 38.0049C20.1267 31.6639 10.1022 25.3213 0.0778006 18.9803L0.0761719 18.9819Z"
      fill="url(#paint0_linear_17838_90903)"
    />
    <path
      d="M51.1888 38.9567H8.3179C6.98913 38.9567 5.92578 37.8933 5.92578 36.5646V20.73C5.92578 19.4012 6.98913 18.3379 8.3179 18.3379H51.1888C52.5176 18.3379 53.5809 19.4012 53.5809 20.73V36.5646C53.5809 37.8933 52.5176 38.9567 51.1888 38.9567Z"
      fill="#FBF7EC"
    />
    <path
      d="M59.9973 52.967V19.2852L30.1895 37.6633L58.5936 55.5871C59.429 55.0562 59.9989 54.0678 59.9989 52.967H59.9973Z"
      fill="#6AC5F8"
    />
    <path
      d="M0.0765344 18.9824C0.0390812 19.1339 0 19.2869 0 19.4758V52.9671C0 54.107 0.607393 55.1313 1.5193 55.6638L30.1514 37.6651L0.0765344 18.9824Z"
      fill="#6AC5F8"
    />
    <path
      d="M56.8086 56.1187C57.4534 56.1187 58.0625 55.9282 58.5559 55.5879L30.1517 37.6641L1.51953 55.6628C2.01294 55.9673 2.54542 56.1187 3.15282 56.1187H56.8086Z"
      fill="#0B8AD1"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17838_90903"
        x1="13.8182"
        y1="6.97898"
        x2="62.6328"
        y2="41.703"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#509EE3" />
        <stop offset="0.19" stopColor="#4A91D0" />
        <stop offset="0.34" stopColor="#3D7CB4" />
        <stop offset="0.89" stopColor="#3B75A9" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
)
