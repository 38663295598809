export const numbers = {
  steps: {
    zero: 0,
    one: 1,
    two: 2,
  },
  time: {
    hoursInDay: 24,
    hoursInHalfDay: 12,
  },
  defaultIconSize: 20,
}
