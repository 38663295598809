export enum EmailConfigTrigger {
  all = 'all',
  none = 'none',
}

export interface IUpdateEmailConfigVariables<T> {
  emailConfigId: string
  data: T
}

export type EmailRecipientConfig = { recipient_email: string; recipient_name?: string } & {
  [key: string]: string
}

export type BaseEmailConfig = {
  recipientConfig: EmailRecipientConfig[]
}
