import { useEffect } from 'react'

import { Button } from '@shared/components/button/button'
import { Carousel } from '@shared/components/carousel/carousel'
import { ActionModal } from '@shared/components/modal/action/action-modal'
import { useCarouselSteps } from '@shared/hooks/use-carousel-steps'

import styles from './tutorial-modal.module.css'

interface TutorialModalProps {
  contents: Array<{ title?: string; description: string; imageUrl: string }>
  visible: boolean
  onDismiss: () => void
}

export const TutorialModal = ({ contents, visible, onDismiss }: TutorialModalProps) => {
  const { step, maxStep, goToStep, goToPrevious, goToNext } = useCarouselSteps(contents.length)

  // When opening the modal, go to step 0.
  useEffect(() => {
    goToStep(0)
  }, [visible, goToStep])

  return (
    <ActionModal
      open={visible}
      btns={<Button fullWidth text="Dismiss" secondaryColor onClick={onDismiss} />}
      className={styles.modal}
      childrenClassName={styles.modalChildren}
      cross={false}
    >
      <Carousel
        contents={contents.map(content => {
          return {
            imageUrl: content.imageUrl,
            content: (
              <div className={styles.modalContent}>
                {content.title && <h3 className={styles.modalTitle}>{content.title}</h3>}
                {content.description}
              </div>
            ),
          }
        })}
        width={650}
        height={420}
        step={step}
        maxStep={maxStep}
        onClickStep={goToStep}
        onClickPrevious={goToPrevious}
        onClickNext={goToNext}
      />
    </ActionModal>
  )
}
