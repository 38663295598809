import classNames from 'classnames'
import { FC } from 'react'

import { SmallSpinner } from '@assets/icons'

import { useHideLoadingPreview } from '@shared/hooks/use-hide-loading-preview'

import styles from './g-slides.module.css'

interface IGSlides {
  docId: string
  title?: string
  itemsInPageCount?: number
  width?: number
  height?: number
  className?: string
  slideIndex?: number
  showControls?: boolean
}

export const GSlidesBaseURL = 'https://docs.google.com/presentation/d/'

const defaultWidth = 640
const defaultHeight = 480

export const GSlides: FC<IGSlides> = ({
  docId,
  className,
  itemsInPageCount,
  width = defaultWidth,
  height = defaultHeight,
  title = docId,
  slideIndex = 1,
  showControls = false,
}) => {
  const classes = classNames(styles.slides, className)

  const url = `${GSlidesBaseURL}${docId}/embed?loop=true${!showControls ? '&rm=minimal' : ''}${
    slideIndex ? `&slide=${slideIndex}` : ''
  }`

  const hidden = useHideLoadingPreview(itemsInPageCount)

  return (
    <div className={classes} style={{ width: width, height: height }}>
      <iframe
        hidden={hidden}
        src={url}
        title={title}
        width={`${width}px`}
        height={`${height}px`}
        allowFullScreen={true}
        id={'react-google-slides-iframe'}
      />
      <div className={styles.spinnerContainer} hidden={!hidden} style={{ height: `${height}px` }}>
        <SmallSpinner />
      </div>
    </div>
  )
}
