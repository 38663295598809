import { ColumnDef } from '@tanstack/react-table'

import { EDataSourceType, IDataSource } from '@features/data-sources/types/types'
import { getDataSourceStatusBadge, isDataSourceActive } from '@features/data-sources/utils'

import { Button, Switch } from '@shared/components'

interface RowData {
  id: string
  childDataSource: IDataSource
  userId: number | undefined
  userRealName: string
  username: string | undefined
  isActive: boolean
}

export const dataSourceUsersTableData = (
  childDataSources: IDataSource[] = [],
  defaultDataSourceId: string
): RowData[] =>
  childDataSources.map(childDataSource => ({
    id: childDataSource.id,
    childDataSource: childDataSource,
    userId: childDataSource.userId,
    userRealName: childDataSource.user?.name,
    username: childDataSource.type === EDataSourceType.Looker ? childDataSource.user?.email : childDataSource.username,
    isActive: !Boolean(defaultDataSourceId && defaultDataSourceId !== childDataSource.id),
  }))

interface DataSourceUsersTableColumnsProps {
  userId: number | undefined
  openTestModal: (data: IDataSource) => void
  openDeleteModal: (data: IDataSource) => void
  handleSharedDSChange: (isDefault: boolean, dataSourceId: string) => Promise<void>
}

export const dataSourceUsersTableColumns = ({
  userId,
  openTestModal,
  openDeleteModal,
  handleSharedDSChange,
}: DataSourceUsersTableColumnsProps): ColumnDef<RowData>[] => [
  {
    accessorKey: 'name',
    header: 'Name',
    size: 200,
    cell: ({ row }) => {
      let text = row.original.userRealName
      if (row.original.userId === userId) {
        text += ' (You)'
      }
      return <span className="text-sm-medium">{text}</span>
    },
  },
  {
    accessorKey: 'username',
    header: 'Username',
    size: 200,
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 100,
    cell: ({ row }) => getDataSourceStatusBadge(row.original.childDataSource, row.original.isActive),
  },
  {
    accessorKey: 'actions1',
    header: 'Actions',
    size: 100,
    cell: ({ row }) => {
      const modifyButton = (
        <Button
          disabled={!row.original.isActive}
          text="Modify"
          tertiaryColor
          onClick={() => openTestModal(row.original.childDataSource)}
        />
      )
      const enterUsernameButton = (
        <Button
          disabled={!row.original.isActive}
          text="Enter username"
          tertiaryColor
          onClick={() => openTestModal(row.original.childDataSource)}
        />
      )
      const enterCredentialButton = (
        <Button
          disabled={!row.original.isActive}
          text="Enter credentials"
          tertiaryColor
          onClick={() => openTestModal(row.original.childDataSource)}
        />
      )

      switch (row.original.childDataSource.type) {
        case EDataSourceType.Metabase:
          if (row.original.userId !== userId) return null

          return row.original.username && row.original.childDataSource.password ? modifyButton : enterCredentialButton

        case EDataSourceType.Looker:
          return row.original.childDataSource.clientId && row.original.childDataSource.clientSecret
            ? modifyButton
            : enterCredentialButton

        case EDataSourceType.Tableau:
        default:
          return row.original.username ? modifyButton : enterUsernameButton
      }
    },
  },
  {
    accessorKey: 'actions2',
    header: '',
    size: 100,
    cell: ({ row }) => {
      return (
        <Button
          disabled={!row.original.isActive}
          text="Delete"
          tertiaryError
          onClick={() => openDeleteModal(row.original.childDataSource)}
        />
      )
    },
  },
  {
    accessorKey: 'actions3',
    header: '',
    size: 300,
    cell: ({ row }) => {
      return (
        <Switch
          disabled={!isDataSourceActive(row.original.childDataSource) || !row.original.isActive}
          className="text-sm-medium"
          text="Use as service account"
          value={row.original.childDataSource.defaultAcrossOrganization}
          onChange={v => handleSharedDSChange(v, row.original.id)}
        />
      )
    },
  },
]
