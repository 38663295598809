import { FC, useEffect } from 'react'

import { IIcon } from '@assets/types'

import styles from './small-spinner.module.css'

const isCSSAnimation = (animation: CSSAnimation | Animation): animation is CSSAnimation =>
  (animation as CSSAnimation).animationName !== undefined

const DEFAULT_SIZE = 20

export const SmallSpinner: FC<Pick<IIcon, 'size' | 'color' | 'className'>> = ({ size = DEFAULT_SIZE, className }) => {
  useEffect(() => {
    let spinners = document
      .getAnimations()
      .filter(animation => isCSSAnimation(animation))
      .filter(animation => (animation as CSSAnimation).animationName.includes('spinnerRotation'))
    spinners.forEach(spinner => (spinner.currentTime = spinners[0].currentTime))
  }, [])

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        fill="currentColor"
        opacity=".25"
      />
      <path
        d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        className={styles.spinner}
        fill="currentColor"
      />
    </svg>
  )
}
