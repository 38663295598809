import { FC } from 'react'

export const MoveToIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 17.5V2.5M2.5 10H14.1667M14.1667 10L8.33333 4.16667M14.1667 10L8.33333 15.8333"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
