import { FC, useMemo } from 'react'

import { GOOGLE_DRIVE_SCOPES, useDrivePicker } from '@features/drive/google-drive'
import { useOpenOneDrivePicker } from '@features/drive/one-drive'
import { OneDrivePickerType } from '@features/drive/one-drive/types'
import { OAuthType, useAuthStore, useRefreshGoogleAccessToken } from '@features/users'

import { Button, InputButton } from '@shared/components'
import { config } from '@shared/config'

import styles from './select-folder.module.css'

interface IGoogleDriveFolder {
  driveId?: string
  folderId: string
  folderName: string
}

interface ISelectFolder {
  label?: string
  selectedParentFolder: IGoogleDriveFolder | null
  setSelectedParentFolder: React.Dispatch<React.SetStateAction<IGoogleDriveFolder | null>>
  oauthType: OAuthType
}

export const SelectFolder: FC<ISelectFolder> = ({
  label,
  oauthType,
  selectedParentFolder,
  setSelectedParentFolder,
}) => {
  const refreshAccessTokenQuery = useRefreshGoogleAccessToken()

  const user = useAuthStore(state => state.user)
  const { clientId, apiKey } = config.google

  const { openOneDrivePicker } = useOpenOneDrivePicker(res => {
    setSelectedParentFolder({
      driveId: res[0].driveId,
      folderId: res[0].id,
      folderName: res[0].name,
    })
  })

  const { openPicker } = useDrivePicker()

  const openGDrivePicker = async () => {
    if (!user || !user.googleOAuth) {
      throw new Error('User is not authenticated')
    }

    let { googleOAuth } = user
    let accessToken = googleOAuth.accessToken
    if (Date.parse(googleOAuth.expiredAt) < Date.now()) {
      const response = await refreshAccessTokenQuery.mutateAsync()
      accessToken = response.data.accessToken
    }

    const rootView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
      .setMode(google.picker.DocsViewMode.LIST)
      .setParent('root')
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes('application/vnd.google-apps.folder')

    const sharedView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
      .setMode(google.picker.DocsViewMode.LIST)
      .setIncludeFolders(true)
      .setEnableDrives(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes('application/vnd.google-apps.folder')

    const customViews = [(rootView as any).setLabel('My Drive'), (sharedView as any).setLabel('Shared drives')]
    openPicker({
      multiselect: false,
      clientId,
      appId: clientId,
      developerKey: apiKey,
      disableDefaultView: true,
      token: accessToken,
      customScopes: GOOGLE_DRIVE_SCOPES,
      customViews,
      callbackFunction: (data: google.picker.ResponseObject) => {
        if (data.action === google.picker.Action.PICKED) {
          const { docs } = data
          const folder = docs[0]
          setSelectedParentFolder({
            folderId: folder.id,
            folderName: folder.name,
          })
        }
      },
    })
  }

  const openFolderPicker = async () => {
    switch (oauthType) {
      case OAuthType.GOOGLE:
        await openGDrivePicker()
        return
      case OAuthType.MICROSOFT:
        await openOneDrivePicker(OneDrivePickerType.Folder)
        return
      default:
        console.warn('Unknown OAuth type', oauthType)
    }
  }

  const inputLabel = useMemo<string>(() => {
    if (label) {
      return label
    }
    switch (oauthType) {
      case OAuthType.GOOGLE:
        return 'Files location in Google Drive'
      case OAuthType.MICROSOFT:
        return 'Files location in OneDrive'
      default:
        console.warn('Unknown OAuth type', oauthType)
        return 'Files location in your Drive'
    }
  }, [label, oauthType])

  return (
    <InputButton tooltip value={selectedParentFolder?.folderName} label={inputLabel} onClick={() => openFolderPicker()}>
      <Button tertiaryColor text="Modify" className={styles.modifyBtn} />
    </InputButton>
  )
}
