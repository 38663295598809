import debounce from 'lodash/debounce'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FilterIcon, SearchIconMd } from '@assets'
import { UpdateRunsPlaceholder } from '@assets/icons/update-runs-placeholder'

import { useGetHistoricalRuns } from '@features/historical-run/api'
import {
  historicalRunsTableColumns,
  historicalRunsTableData,
} from '@features/historical-run/components/destination-historical-runs/historical-runs-table'
import { HistoricalRunStatus, HistoricalRunTrigger } from '@features/historical-run/types'

import { EmptyPageTemplate, Input, PageContainer, Spinner } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { FilterOrStorterDropdown } from '@shared/components/filter-or-sorter-dropdown/filter-or-sorter-dropdown'

interface StatusOption {
  label: string
  value: HistoricalRunStatus | undefined
}

interface TriggerOption {
  label: string
  value: HistoricalRunTrigger | undefined
}

const DEBOUNCE_TIME = 300

export const HistoricalRuns: FC = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState<number>(1)
  const [searchInput, setSearchInput] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const statusOptions: StatusOption[] = [
    { label: 'All', value: undefined },
    { label: 'Succeeded', value: 'succeeded' },
    { label: 'Failed', value: 'failed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Partially Succeeded', value: 'partially-succeeded' },
    { label: 'Inactive', value: 'inactive' },
  ]

  const triggerOptions: TriggerOption[] = [
    { label: 'All', value: undefined },
    { label: 'Manual', value: 'manual' },
    { label: 'Scheduled', value: 'scheduled' },
  ]

  const [selectedStatus, setSelectedStatus] = useState<StatusOption>(statusOptions[0])
  const [selectedTrigger, setSelectedTrigger] = useState<TriggerOption>(triggerOptions[0])

  const { isLoading, data: historicalRunsResponse } = useGetHistoricalRuns({
    status: selectedStatus.value,
    trigger: selectedTrigger.value,
    q: searchQuery,
    page,
  })

  useEffect(() => {
    setPage(1)
  }, [selectedStatus, selectedTrigger, searchQuery])

  const debouncedUpdateQuery = debounce(query => {
    setSearchQuery(query)
  }, DEBOUNCE_TIME)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchInput(value)
    debouncedUpdateQuery(value)
  }

  const columns = historicalRunsTableColumns()
  const data = historicalRunsTableData(historicalRunsResponse?.items || [], navigate)

  const EmptyState = (
    <EmptyPageTemplate
      icon={<UpdateRunsPlaceholder />}
      label="No runs yet"
      description="List of all your destinations update runs"
    />
  )

  return (
    <PageContainer title="Update runs" subtitle="A history of all your updates" hideBackButton>
      <section className="flex justify-start flex-wrap items-center gap-4">
        <FilterOrStorterDropdown
          icon={<FilterIcon />}
          label="Status"
          options={statusOptions}
          selectedOption={selectedStatus}
          onSelect={option => setSelectedStatus(option as StatusOption)}
        />
        <FilterOrStorterDropdown
          icon={<FilterIcon />}
          label="Trigger"
          options={triggerOptions}
          selectedOption={selectedTrigger}
          onSelect={option => setSelectedTrigger(option as TriggerOption)}
        />
        <Input icon={<SearchIconMd />} value={searchInput} onChange={handleSearchChange} placeholder="Search" />
      </section>
      <section>
        {isLoading ? (
          <Spinner localCenter />
        ) : (
          <DataGrid
            columns={columns}
            data={data}
            enablePagination
            customPagination={{
              currentPage: page,
              totalPages: historicalRunsResponse?.totalPages || 1,
              onPageChange: setPage,
            }}
            EmptyState={EmptyState}
            clickableRows
          />
        )}
      </section>
    </PageContainer>
  )
}
