import { EDestinationType } from '@features/destinations/types'
import { getDefaultEmailTemplate, updateEmailTemplateSetting } from '@features/emails/components/templates/utils'
import { EmailTemplate } from '@features/emails/types'

import { ColorPicker, Input, Label } from '@shared/components'

interface Props {
  template: EmailTemplate<'updateDestination'>
  setTemplate: (template: EmailTemplate<'updateDestination'>) => void
  type: EDestinationType
}

export const EditTemplateSettings = ({ template, setTemplate, type }: Props) => {
  const defaultTemplate = getDefaultEmailTemplate(type)
  const templateSettings = {
    button: {
      bgColor: template.settings?.button.bgColor || defaultTemplate.settings.button.bgColor,
      textColor: template.settings?.button.textColor || defaultTemplate.settings.button.textColor,
      text: template.settings?.button.text || defaultTemplate.settings.button.text,
    },
  }

  if (type === EDestinationType.Email) {
    return <></>
  }

  return (
    <div>
      <Label xs medium>
        Open button
      </Label>
      <div className="flex items-center gap-7">
        <Input
          className="h-10"
          value={templateSettings.button.text}
          onChange={e => {
            const newTemplate = updateEmailTemplateSetting(template, 'button', 'text', e.target.value)
            setTemplate({
              ...newTemplate,
              dirty: true,
            })
          }}
        />
        <div className="flex items-center gap-7 p-3 border border-gray-300 rounded-lg">
          <span className="flex gap-1 items-center">
            <ColorPicker
              color={templateSettings.button.textColor}
              onColorChange={(color: string) => {
                const newTemplate = updateEmailTemplateSetting(template, 'button', 'textColor', color)
                setTemplate({
                  ...newTemplate,
                  dirty: true,
                })
              }}
            />

            <Label xs regular className="inline">
              Text color
            </Label>
          </span>
          <span className="flex gap-1 items-center">
            <ColorPicker
              color={templateSettings.button.bgColor}
              onColorChange={(color: string) => {
                const newTemplate = updateEmailTemplateSetting(template, 'button', 'bgColor', color)
                setTemplate({
                  ...newTemplate,
                  dirty: true,
                })
              }}
            />
            <Label xs regular className="inline">
              Background color
            </Label>
          </span>
        </div>
      </div>
    </div>
  )
}
