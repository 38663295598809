import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 20

export const TickCircleOutline: FC<IIcon> = ({ color = 'white', size = defaultSize }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 21" fill="none">
      <path
        d="M6.25033 10.4974L8.75033 12.9974L13.7503 7.9974M18.3337 10.4974C18.3337 15.0998 14.6027 18.8307 10.0003 18.8307C5.39795 18.8307 1.66699 15.0998 1.66699 10.4974C1.66699 5.89502 5.39795 2.16406 10.0003 2.16406C14.6027 2.16406 18.3337 5.89502 18.3337 10.4974Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
