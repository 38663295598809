import { captureException } from '@sentry/react'

const SITE_NAME_PATH_INDEX = 2

export async function getOneDriveFolderName(folderId: string, driveId: string, accessToken: string) {
  try {
    const res = await fetch(`https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await res.json()
    const name = data.name
    if (name === 'root') {
      const driveData = await fetch(`https://graph.microsoft.com/v1.0/drives/${driveId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const driveDataJson = await driveData.json()
      const webUrl = new URL(driveDataJson.webUrl)
      const pathComponents = webUrl.pathname.split('/')
      return pathComponents?.length > SITE_NAME_PATH_INDEX ? pathComponents[SITE_NAME_PATH_INDEX] : driveDataJson.name
    }
    return data.name
  } catch (error) {
    console.error('Error fetching folder details:', error)
    captureException(error)
    return '<<We could not retrieve the folder name>>'
  }
}
