import { Dispatch, FC, PropsWithChildren, createContext, useEffect, useState } from 'react'

import { isEmailDestination } from '@features/destinations'
import { EDestinationType } from '@features/destinations/types'
import { useGetEmailTemplatesByType } from '@features/emails/api/api'
import { getDefaultEmailTemplate } from '@features/emails/components/templates/utils'
import { EEmailTemplateType, EmailTemplate } from '@features/emails/types'
import { ISync } from '@features/syncs/types'

import { PartialBy } from '@shared/utils/types'

interface IEmailTemplateEditorContext {
  targetId: string
  onlyTemplatesForTarget?: boolean
  destinationType: EDestinationType
  templateType: EEmailTemplateType
  syncs: ISync[]
  isTemplateValid: boolean
  template: EmailTemplate
  setTemplate: Dispatch<EmailTemplate | undefined>
  initialTemplateId?: string | null
}

export const EmailTemplateEditorContext = createContext<IEmailTemplateEditorContext>({
  targetId: '',
  template: null as unknown as EmailTemplate,
  setTemplate: () => {},
  destinationType: null as unknown as EDestinationType,
  templateType: null as unknown as EEmailTemplateType,
  syncs: [],
  isTemplateValid: false,
  initialTemplateId: null,
})

export const EmailTemplateEditorProvider: FC<
  PropsWithChildren<
    Omit<PartialBy<IEmailTemplateEditorContext, 'syncs'>, 'template' | 'setTemplate' | 'isTemplateValid'>
  >
> = props => {
  const defaultTemplate = getDefaultEmailTemplate(props.destinationType)
  const [template, setTemplate] = useState<EmailTemplate>(defaultTemplate)

  const { data: emailTemplates = [] } = useGetEmailTemplatesByType({
    templateType: props.templateType,
    onlyTemplatesForTarget: props.onlyTemplatesForTarget,
    targetId: props.targetId,
  })

  const initialTemplate = emailTemplates.find(template => template.id === props.initialTemplateId)

  useEffect(() => {
    if (initialTemplate) {
      setTemplate(initialTemplate)
    } else {
      setTemplate(defaultTemplate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialTemplateId, initialTemplate?.id])

  const isTemplateValid =
    Boolean(template.subject.length > 0 && template.content.length > 0) &&
    (isEmailDestination({ type: props.destinationType }) || template.name?.length > 0)

  return (
    <EmailTemplateEditorContext.Provider
      value={{
        ...props,
        template,
        setTemplate: value => {
          if (value) {
            return setTemplate({ ...value })
          } else {
            return setTemplate(defaultTemplate)
          }
        },
        syncs: props.syncs || [],
        isTemplateValid,
      }}
    >
      {props.children}
    </EmailTemplateEditorContext.Provider>
  )
}
