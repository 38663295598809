import { useState } from 'react'

import { useDeleteCollection } from '@features/collections/api/api'

import { ActionModal, Button, deleteCollectionToast } from '@shared/components'
import { useModalWithData } from '@shared/hooks'
import { queryClient } from '@shared/http'

export interface WithCollectionDeleteProps {
  deleteCollection: (collectionId: string) => void
}

export const withCollectionDelete = <T,>(WrappedComponent: React.ComponentType<T & WithCollectionDeleteProps>) => {
  return (props: T) => {
    const deleteCollectionQuery = useDeleteCollection()
    const [processing, setProcessing] = useState(false)
    const [modal, collectionId, toggleModal, openModalWithData] = useModalWithData<string>()

    const deleteCollection = async () => {
      if (!collectionId) return

      setProcessing(true)
      await deleteCollectionQuery.mutateAsync(collectionId)
      await queryClient.invalidateQueries('presentations')
      setProcessing(false)
      deleteCollectionToast()
      toggleModal()
    }

    return (
      <>
        <WrappedComponent {...props} deleteCollection={openModalWithData} />
        <ActionModal
          open={modal && !deleteCollectionQuery.isSuccess}
          title="Delete collection"
          subtitle="Are you sure you want to delete this collection?"
          onBackgroundClick={toggleModal}
          processing={processing}
          btns={
            <>
              <Button secondaryGray text="Cancel" onClick={toggleModal} fullWidth />
              <Button
                primaryError
                text="Delete"
                onClick={deleteCollection}
                fullWidth
                loading={deleteCollectionQuery.isLoading}
              />
            </>
          }
        />
      </>
    )
  }
}
