// historical-run-details-table.tsx
import { ColumnDef } from '@tanstack/react-table'

import { StatusLabel } from '@features/historical-run/components/status-label/status-label'
import { HistoricalRunSyncDetails } from '@features/historical-run/types'

import { ImageWithFallback } from '@shared/components'

import styles from './historical-run-details.module.css'

interface RowData {
  id: string
  status: JSX.Element
  screenshot: JSX.Element
  visualizationName: string | null
  details: JSX.Element
}

export const historicalRunDetailsTableData = (historicalRunSyncs: HistoricalRunSyncDetails[]): RowData[] => {
  return historicalRunSyncs.map(historicalSync => ({
    id: historicalSync.id,
    status: <StatusLabel status={historicalSync.status} />,
    screenshot: <ImageWithFallback src={historicalSync.screenshotUrl} alt="screenshot" className={styles.screenshot} />,
    visualizationName: historicalSync.visualizationName, // This can now be string or null without error
    details: (
      <>
        {historicalSync.visualizationId ? (
          <span>{historicalSync.details}</span>
        ) : (
          <>
            <span className={styles.errorLabel}>Visualization was deleted</span>
            <br />
            <span>{historicalSync.details}</span>
          </>
        )}
      </>
    ),
  }))
}

export const historicalRunDetailsColumns: ColumnDef<RowData>[] = [
  {
    accessorKey: 'status',
    header: 'Status',
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'screenshot',
    header: 'Visualization preview',
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'visualizationName',
    header: 'Visualization name',
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'details',
    header: 'Details',
    cell: info => info.getValue(),
  },
]
