import isEqual from 'lodash/isEqual'
import { forwardRef, useEffect, useImperativeHandle } from 'react'

import { MetabaseCoreDataSource } from '@features/data-sources/types'
import { useMetabase } from '@features/embedding'
import { MetabaseEmbed } from '@features/embedding/metabase/metabase-embed/metabase-embed'
import { IMetabaseDashboard, MetabaseDashboardElement } from '@features/embedding/metabase/types'
import { buildJsonFilters, deleteNullFilters } from '@features/syncs/hooks/utils'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { SyncEmbedProps, SyncEmbedRef } from './types'

export const SyncEmbedMetabase = forwardRef<
  SyncEmbedRef,
  SyncEmbedProps<MetabaseCoreDataSource, IMetabaseDashboard>
>(({ dashboard, dataSource, toggleFiltersChanged, setVizSelected }, ref) => {
  const {
    isNewSync,
    metabaseData,
    originalSync,
    fullDashboard,
    selectedVis,
    dashboardSelected,
    filtersSelectModal,
    vizSelectModal,
    toggleFullDashboard,
    visSelectCallback,
    updateSyncData,
  } = useSyncSettingsContext()

  const initialFilters = buildJsonFilters(originalSync).jsonFilters

  const {
    handleIframeLocationChange,
    handleSelectElement,
    handleUnselectElement,
    orderedCards,
    dashboardDetails,
    elementsSelected,
    filters,
    hasFilters,
    hasTabs,
    iframeHeight,
    iframeWidth,
    dashboardUrl,
    loading,
    numCols,
    numRows,
  } = useMetabase({
    dataSource,
    dashboardId: dashboard.id,
    showOverlays: vizSelectModal,
    initialFilters: JSON.stringify(initialFilters),
    singleSelect: !isNewSync,
    syncId: originalSync?.id,
    initialElements: selectedVis as MetabaseDashboardElement[],
    visSelectCallback,
  })

  const selectedFilters = deleteNullFilters(filters)
  const localFiltersChanged = !isEqual(initialFilters, selectedFilters)

  useEffect(() => {
    toggleFiltersChanged?.(localFiltersChanged)
  }, [toggleFiltersChanged, localFiltersChanged])

  useEffect(() => {
    setVizSelected?.(elementsSelected.length > 0)
  }, [elementsSelected, setVizSelected])

  useImperativeHandle(ref, () => ({
    saveEmbedDataToProvider: () => {
      if (!dashboardSelected || !elementsSelected || !dashboardUrl) return

      updateSyncData({
        selectedVis: elementsSelected,
        metabaseData: {
          metabaseCredentialsId: dataSource.id,
          filters: JSON.stringify(selectedFilters),
          fullDashboard,
          dashboardId: dashboard.id,
          dashboardName: (dashboardSelected as IMetabaseDashboard).title,
          dashboardUrl,
        },
      })
    },
    saveFiltersToContext: () => {
      if (!metabaseData) {
        console.log('Error: No metabase data when saving filters to context')
        return
      }
      updateSyncData({
        metabaseData: {
          ...metabaseData,
          filters: JSON.stringify(selectedFilters),
        },
      })
    },
  }))

  return (
    <MetabaseEmbed
      dashboardId={dashboard.id}
      dashboardDetails={dashboardDetails}
      loading={loading}
      fullDashboard={fullDashboard}
      hasFilters={hasFilters}
      hasTabs={hasTabs}
      hideOverlay={loading || filtersSelectModal}
      numRows={numRows}
      numCols={numCols}
      iframeUrl={dashboardUrl}
      iframeWidth={iframeWidth}
      iframeHeight={iframeHeight}
      elementsSelected={elementsSelected}
      elementsOverlays={orderedCards.map(orderedCard => ({
        id: orderedCard.id.toString(),
        title: orderedCard.card.name,
        row: orderedCard.row,
        column: orderedCard.col,
        width: (orderedCard.size_x ?? orderedCard.sizeX!) + 1,
        height: orderedCard.size_y ?? orderedCard.sizeY!,
      }))}
      handleIframeLocationChange={handleIframeLocationChange}
      handleSelectElement={handleSelectElement}
      handleUnselectElement={handleUnselectElement}
      fullDashboardClick={toggleFullDashboard}
    />
  )
})
