import { Navigate } from 'react-router-dom'

import { ErrorPage } from '@pages/error-page/error-page'
import { Settings } from '@pages/settings/settings'

import {
  AccountSettingsCrumb,
  OrganizationDirectorySyncCrumb,
  OrganizationSSOCrumb,
  OrganizationSettingsCrumb,
  UsageSettingsCrumb,
} from '@shared/components/breadcrumbs/breadcrumbs'

import { PathSegments, Paths } from './paths'

export const settingsRoutes = {
  path: PathSegments.SETTINGS,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Navigate to={Paths.SETTINGS_USAGE} replace={true} />,
    },
    {
      path: PathSegments.USAGE,
      element: <Settings />,
      handle: { crumb: UsageSettingsCrumb },
    },
    {
      path: PathSegments.ACCOUNT,
      element: <Settings />,
      handle: { crumb: AccountSettingsCrumb },
    },
    {
      path: Paths.SETTINGS_ORGANIZATION,
      element: <Settings />,
      handle: { crumb: OrganizationSettingsCrumb },
    },
    {
      path: Paths.SETTINGS_ORGANIZATION_SSO,
      element: <Settings />,
      handle: { crumb: OrganizationSSOCrumb },
    },
    {
      path: Paths.SETTINGS_ORGANIZATION_DIRECTORY_SYNC,
      element: <Settings />,
      handle: { crumb: OrganizationDirectorySyncCrumb },
    },
  ],
}
