import { User } from '@features/users'

import { config } from '@shared/config'

export const initializeBeamer = (user: User) => {
  const script = document.createElement('script')
  script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
  script.async = true

  script.onload = () => {
    try {
      ;(window as any).beamer_config = {
        product_id: config.beamerProductId,
        user_firstname: user.name,
        user_email: user.email,
      }
      console.log('Beamer initialized successfully')
    } catch (error) {
      console.error('Beamer initialization failed', error)
    }
  }

  script.onerror = error => {
    console.error('Failed to load Beamer script', error)
  }

  document.head.appendChild(script)

  return () => {
    if (script.parentNode) {
      script.parentNode.removeChild(script)
    }
  }
}
