import { CollectionIcon } from '@assets/icons/collection'

import { Tooltip } from '@shared/components'
import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'
import { getMessage } from '@shared/constants'

interface StartCollectionItemProps extends MenuItemProps {
  hasRunUpdate?: boolean
  hasVisualizations?: boolean
  shared?: boolean
}

export const StartCollectionItem = ({
  hasRunUpdate = false,
  hasVisualizations = false,
  shared,
  visible,
  ...menuItemProps
}: StartCollectionItemProps) => {
  if (!visible) {
    return null
  }

  let warningMessage = null

  if (shared) {
    warningMessage = getMessage('COLLECTION_ADD_SHARED')
  } else if (!hasVisualizations) {
    warningMessage = getMessage('COLLECTION_ADD_NO_SYNCS')
  } else if (!hasRunUpdate) {
    warningMessage = getMessage('COLLECTION_ADD_NO_UPDATE')
  }

  if (warningMessage) {
    return (
      <Tooltip title={warningMessage}>
        <MenuItem {...menuItemProps} disabled>
          <CollectionIcon />
          Create a collection
        </MenuItem>
      </Tooltip>
    )
  }

  return (
    <MenuItem {...menuItemProps}>
      <CollectionIcon />
      Create a collection
    </MenuItem>
  )
}
