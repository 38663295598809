import { useRef } from 'react'

import { ConnectionTestMessages, ConnectionTestStatus } from '@features/data-sources/components/connection-test/utils'
import { IMetabaseCredentials } from '@features/data-sources/types'
import { IMetabaseDashboard } from '@features/embedding/metabase/types'

import { useStateReducer } from '@shared/hooks'
import { api } from '@shared/http/axios'

type MetabaseCredentialTest = Pick<IMetabaseCredentials, 'baseUrl' | 'username' | 'password'>

export const useMetabaseConnectionTest = () => {
  const iframeDashboardRef = useRef<HTMLDivElement | null>(null)
  const [testsStatus, updateTestsStatus] = useStateReducer<ConnectionTestStatus | null>(null)
  const [connectionTestsMessages, updateConnectionTestsMessages] = useStateReducer<Partial<ConnectionTestMessages>>({})

  const getTestDashboard = async ({ baseUrl, username, password }: MetabaseCredentialTest) => {
    try {
      const response = await api.post<IMetabaseDashboard>(`datasources/metabase-test/dashboard`, {
        baseUrl,
        username,
        password,
      })
      return response.data
    } catch (err) {
      console.log(err)
      const errorMessage = 'Unable to connect to Metabase API'
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'failed' })
      updateConnectionTestsMessages({ API_TEST: { failed: errorMessage } })
      throw new Error(errorMessage)
    }
  }

  const makeDashboard = async (dashboardId: string) => {
    return new Promise((resolve, reject) => {
      if (typeof dashboardId !== 'undefined') {
        resolve({})
      }

      updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'failed' })
      reject()
    })
  }

  const testConnection = async (credentials: MetabaseCredentialTest) => {
    try {
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'running' })
      const dashboard = await getTestDashboard(credentials)
      updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'running' })
      await makeDashboard(dashboard.id)
      updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'success' })
      return true
    } catch (err) {
      return false
    }
  }

  return {
    ...testsStatus,
    connectionTestsMessages,
    iframeDashboardRef,
    testConnection,
  }
}
