import classNames from 'classnames'
import { ButtonHTMLAttributes, FC } from 'react'

import styles from './badge.module.css'

export enum EBadgeColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  YELLOW = 'yellow',
  RED = 'red',
  GREEN = 'green',
  LIGHTGRAY = 'lightGray',
  DARKGRAY = 'darkGray',
}

interface IBadge extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
  color: EBadgeColor
}

export const Badge: FC<IBadge> = ({ color, text, className }) => {
  const badgeStyles = classNames(styles.badge, styles[color], className)
  return (
    <div className={badgeStyles}>
      <span className="truncate">{text}</span>
    </div>
  )
}
