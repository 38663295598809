import { SyncCoreDataSource } from '@features/syncs/types'

import { Badge, EBadgeColor } from '@shared/components/badge/badge'
import { Tooltip } from '@shared/components/tooltip/tooltip'

import {
  ILookerDataSource,
  IMetabaseDataSource,
  ITableauDataSource,
  LookerCoreDataSource,
  TableauCoreDataSource,
} from './types'
import { EDataSourceType, IDataSource } from './types/types'

export const isLookerDataSource = (ds: SyncCoreDataSource): ds is LookerCoreDataSource =>
  ds.type === EDataSourceType.Looker

export const isTableauDataSource = (ds: SyncCoreDataSource): ds is TableauCoreDataSource =>
  ds.type === EDataSourceType.Tableau

export const isMetabaseDataSource = (ds: SyncCoreDataSource): ds is IMetabaseDataSource =>
  ds.type === EDataSourceType.Metabase

export const isSpreadsheetsDataSource = ({ type }: { type: EDataSourceType }) => {
  return type === EDataSourceType.GoogleSheets
}

export const cleanUrl = (url: string): string => {
  const parsedUrl = new URL(url)
  let baseUrl = parsedUrl.origin + parsedUrl.pathname

  // Remove trailing slash if it exists
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1)
  }

  return baseUrl
}

export const isDataSourceActive = (ds: IDataSource) => {
  switch (ds.type) {
    case EDataSourceType.Metabase:
      return Boolean(ds.password)
    case EDataSourceType.Tableau:
      return ds.status === 'valid'
    case EDataSourceType.Looker:
      return Boolean(ds.clientId && ds.clientSecret)
  }
}

const getTableauStatusView = (ds: ITableauDataSource, active = true) => {
  if (isDataSourceActive(ds)) {
    return <Badge color={EBadgeColor.GREEN} text="Active" />
  }

  return (
    <Tooltip show={active && Boolean(ds.statusMessage)} title={ds.statusMessage}>
      <Badge color={EBadgeColor.LIGHTGRAY} text="Inactive" />
    </Tooltip>
  )
}

const getMetabaseStatusView = (ds: IMetabaseDataSource) => {
  if (isDataSourceActive(ds)) return <Badge color={EBadgeColor.GREEN} text="Active" />

  return <Badge color={EBadgeColor.LIGHTGRAY} text="Inactive" />
}

const getLookerStatusView = (ds: ILookerDataSource) => {
  if (isDataSourceActive(ds)) {
    return <Badge color={EBadgeColor.GREEN} text="Active" />
  }

  return <Badge color={EBadgeColor.LIGHTGRAY} text="Inactive" />
}

export const getDataSourceStatusBadge = (dataSource: IDataSource, active = true) => {
  switch (dataSource.type) {
    case EDataSourceType.Metabase:
      return getMetabaseStatusView(dataSource)
    case EDataSourceType.Tableau:
      return getTableauStatusView(dataSource, active)
    case EDataSourceType.Looker:
      return getLookerStatusView(dataSource)
  }
}

export const appendSheetIdToSpreadsheetUrl = ({ url, sheetId }: { url?: string; sheetId?: string }) => {
  try {
    if (!url) return
    const parsedUrl = new URL(url)
    if (parsedUrl.hostname !== 'docs.google.com' || !parsedUrl.pathname.includes('/spreadsheets/d/')) {
      throw new Error('Invalid Google Sheets URL')
    }
    parsedUrl.search = ''
    parsedUrl.hash = ''
    if (!parsedUrl.pathname.endsWith('/edit')) {
      parsedUrl.pathname += '/edit'
    }
    parsedUrl.hash = `gid=${sheetId}`

    return parsedUrl.toString()
  } catch (error) {
    console.error('Error processing URL', { error, url, sheetId })
    return url
  }
}

export const extractSpreadsheetIdFromUrl = (url?: string) => {
  try {
    if (!url) return
    const urlObj = new URL(url)
    const pathSegments = urlObj.pathname.split('/')
    const spreadsheetId = pathSegments[3]
    return spreadsheetId
  } catch (error) {
    console.error('Invalid Spreadsheet URL', error)
    return
  }
}
