import { ETutorialSections, ITutorialDataSections } from '@features/settings/components/directory-sync/tutorials/types'

export const AzureTutorialSections: ITutorialDataSections = {
  [ETutorialSections.SETUP]: {
    title: 'Microsoft Entra ID SCIM v2.0',
    description:
      'The following guide will walk you through the process of configuring SAML Jackson to use the Microsoft Entra ID SCIM v2.0 directory provider.',
    steps: [
      {
        text: 'Click Azure Active Directory from the Microsoft Azure Portal.',
        imgUrl: 'https://boxyhq.com/assets/images/1-11157dcd886a039d3938691bcc395508.png',
      },
      {
        text: 'Click Enterprise Applications from the left navigation menu.',
        imgUrl: 'https://boxyhq.com/assets/images/2-4622a632a1836ba69f47b30bed1fdc82.png',
      },
      {
        text: "If you haven't created your application yet, click New application button.",
        imgUrl: 'https://boxyhq.com/assets/images/9-c4e811d12b1d7cc855d51cd26fe88a0d.png',
      },
      {
        text: 'From the next screen, click Create your own application, give your application a name and click the Create button.',
        imgUrl: 'https://boxyhq.com/assets/images/3-8d7b1886408e669bd85f153eb479d8b4.png',
      },
    ],
  },
  [ETutorialSections.PROVISIONING]: {
    title: 'Enable Azure SCIM Provisioning',
    description: 'Enable Azure SCIM Provisioning',
    steps: [
      {
        text: 'Click Provisioning from the left navigation menu then click Get started.',
        imgUrl: 'https://boxyhq.com/assets/images/4-dd3833309f02d749f5d61acf2bfa8133.png',
      },
      {
        text: 'Select Automatic from the Provisioning Mode dropdown and enter the following information under Admin Credentials section. Next, click the Test Connection button to test the connection to see if the credentials are correct, then click Save to save the credentials',
        imgUrl: 'https://boxyhq.com/assets/images/5-58574327adaa17f97000766615a46c43.png',
      },
      {
        text: 'Expand the Mappings section and ensure group and user attribute mappings are enabled for your app. The default mapping should work.',
        imgUrl: 'https://boxyhq.com/assets/images/7-229c6f6ab8d9738b78658812a971c089.png',
      },
      {
        text: 'Expand the Settings section and make the following changes',
        imgUrl: 'https://boxyhq.com/assets/images/8-1cee959df8f2671a80d2d5145ba4e550.png',
      },
    ],
  },
}
