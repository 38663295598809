import { config } from '@shared/config'

export type ViewIdOptions =
  | 'DOCS'
  | 'DOCS_IMAGES'
  | 'DOCS_IMAGES_AND_VIDEOS'
  | 'DOCS_VIDEOS'
  | 'DOCUMENTS'
  | 'DRAWINGS'
  | 'FOLDERS'
  | 'FORMS'
  | 'PDFS'
  | 'SPREADSHEETS'
  | 'PRESENTATIONS'

export enum GoogleOAuthScopes {
  PROFILE = 'profile',
  EMAIL = 'email',
  OPENID = 'openid',
  DRIVE = 'https://www.googleapis.com/auth/drive',
  DRIVE_FILE = 'https://www.googleapis.com/auth/drive.file',
  PRESENTATIONS = 'https://www.googleapis.com/auth/presentations',
  DOCUMENTS = 'https://www.googleapis.com/auth/documents',
}

export const GDriveMimeTypes = {
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gslides: 'application/vnd.google-apps.presentation',
}

export type PickerConfiguration = {
  clientId: string
  developerKey: string
  viewId?: ViewIdOptions
  viewMimeTypes?: string
  setIncludeFolders?: boolean
  setSelectFolderEnabled?: boolean
  disableDefaultView?: boolean
  token?: string
  multiselect?: boolean
  disabled?: boolean
  appId: string
  supportDrives?: boolean
  showUploadView?: boolean
  showUploadFolders?: boolean
  setParentFolder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customViews?: any[]
  query?: string
  locale?: string
  customScopes?: string[]
  title?: string
  callbackFunction: (data: google.picker.ResponseObject) => any
}

const { clientId } = config.google

export const defaultConfiguration: PickerConfiguration = {
  clientId,
  developerKey: '',
  viewId: 'DOCS',
  appId: clientId,
  callbackFunction: () => null,
}
