export const queryFilterValues = (params: { values: string[]; query?: string }) => {
  return new Promise<string[]>((resolve, reject) => {
    const worker = new Worker(new URL('./query-filter-values.worker.ts', import.meta.url), {
      type: 'module',
    })

    worker.postMessage(params)

    worker.onmessage = function (e) {
      resolve(e.data)
      worker.terminate()
    }

    worker.onerror = function (e) {
      reject(e)
      console.error(e)
      worker.terminate()
    }
  })
}
