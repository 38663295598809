import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import {
  useCollectionCancelUpdate,
  useGetCollectionById,
  useGetCollectionEmailConfig,
} from '@features/collections/api/api'
import { WithCollectionUpdateProps } from '@features/collections/hocs'
import { ICollection } from '@features/collections/types'
import { FreeTrialContext } from '@features/free-trial'
import { useDestinationsStatuses } from '@features/notch/destinations-status-provider'

import { useCollectionRights } from './use-collection-rights'

export const useCollection = ({ updateAll }: WithCollectionUpdateProps) => {
  const { freeTrial } = useContext(FreeTrialContext)

  const navigation = useNavigate()
  const { collectionId } = useParams()
  const { isCollectionUpdateRunning } = useDestinationsStatuses()
  const [isUpdateRunning, setIsUpdateRunning] = useState(false)
  const [recreateVariantsModal, setRecreateVariantsModal] = useState(false)

  const { isLoading, data: collection } = useGetCollectionById({ collectionId })
  const { data: collectionEmailSettings, isLoading: collectionEmailSettingsLoading } = useGetCollectionEmailConfig(
    collection?.id
  )
  const templateDestination = collection?.collectionVariantTemplate?.destination

  const { contributor, admin, owner } = useCollectionRights(collection)
  const collectionCancelUpdateMutation = useCollectionCancelUpdate()
  const { cancelCollectionUpdate: cancelCollectionUpdateInNotch } = useDestinationsStatuses()
  const [isCollectionSendingOpen, setIsCollectionSendingOpen] = useState(false)

  const canSendCollectionEmail = useMemo(() => {
    if (!collection) {
      return false
    }
    if (!!collectionEmailSettings) {
      return true
    }
    return false
  }, [collection, collectionEmailSettings])

  const toggleRecreateVariantsModal = () => setRecreateVariantsModal(prev => !prev)

  const goBack = () => navigation(Paths.COLLECTIONS)

  const onUpdate = (collection: ICollection) => {
    freeTrial(async () => {
      await updateAll(collection)
      setIsUpdateRunning(true)
    })
  }

  const cancelAll = async (collection: ICollection) => {
    collectionCancelUpdateMutation.mutateAsync(collection).finally(() => {
      setIsUpdateRunning(false)
      cancelCollectionUpdateInNotch(collection.id)
    })
  }

  const onCancelCollectionUpdate = (collection: ICollection) => {
    setIsUpdateRunning(false)
    cancelAll(collection)
  }

  const collectionUpdateRunning = collection ? isCollectionUpdateRunning(collection.id) : false

  useEffect(() => {
    if (collectionUpdateRunning) {
      setIsUpdateRunning(collectionUpdateRunning)
    } else {
      setIsUpdateRunning(false)
    }
  }, [collectionUpdateRunning])

  return {
    cancelIsLoading: collectionCancelUpdateMutation.isLoading,
    canSendCollectionEmail,
    collection,
    collectionEmailSettings,
    collectionEmailSettingsLoading,
    isCollectionSendingOpen,
    setIsCollectionSendingOpen,
    templateDestination,
    admin,
    goBack,
    isLoading,
    isUpdateRunning,
    onCancelCollectionUpdate,
    onUpdate,
    recreateVariantsModal,
    toggleRecreateVariantsModal,
    contributor,
    owner,
  }
}
