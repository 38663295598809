import { User, captureException } from '@sentry/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'

import { CollectionVariantType, ICollection } from '@features/collections/types'
import { OAuthType } from '@features/users'

import { Period } from '@shared/components'
import { getEmptyEmailConfig } from '@shared/components/email-config/utils'

import {
  Destination,
  DestinationEmailConfigCreateRequest,
  DestinationEmailConfigSharingFormat,
  DestinationWithDoc,
  EDestinationType,
  Frequency,
  ITimeOption,
} from './types'
import { EDataSourceType } from '@features/data-sources/types/types'

dayjs.extend(relativeTime)
dayjs.extend(isBetween)
dayjs.extend(advancedFormat)

const quarterInMonths = 3
const minExpectedPathnameParts = 2

export const getLastUpdate = (destination?: Destination | ICollection | null) => {
  if (!destination || !destination.lastSyncedAt) return null

  return dayjs(destination.lastSyncedAt)
}

export const getLastSuccessfulUpdate = (destination?: Destination | null) => {
  if (!destination || !destination.lastSuccessfulUpdateAt) return null

  return dayjs(destination.lastSuccessfulUpdateAt)
}

const getAddInterval = (frequency: Frequency): { amount: number; unit: 'day' | 'week' | 'month' } => {
  switch (frequency) {
    case Frequency.DAILY:
      return { amount: 1, unit: 'day' }
    case Frequency.WEEKLY:
      return { amount: 1, unit: 'week' }
    case Frequency.MONTHLY:
      return { amount: 1, unit: 'month' }
    case Frequency.QUARTERLY:
      return { amount: quarterInMonths, unit: 'month' }
    default:
      throw new Error('Invalid frequency')
  }
}

export const getNextUpdate = (destination?: Destination | null) => {
  const schedule = destination?.updateSchedules?.[0]
  const { frequency, startDate, endDate } = schedule || {}

  if (!frequency || !startDate) {
    return null
  }

  const now = dayjs.utc()

  if (endDate && !now.isBetween(dayjs.utc(startDate), dayjs.utc(endDate), 'minute', '[]')) {
    return null
  }

  const interval = getAddInterval(frequency)
  let nextDate = dayjs.utc(startDate)

  while (nextDate.isBefore(now)) {
    nextDate = nextDate.add(interval.amount, interval.unit).local()
  }

  return nextDate
}

export const getLastRefreshedAt = (destination?: Destination) => {
  if (!destination || !destination.lastRefreshedAt) return null

  return dayjs(destination.lastRefreshedAt)
}

const nameSorter = (a: { name: string }, b: { name: string }) => {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return -1
  }
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return 1
  }
  return 0
}

type UpdateDateSorterParams = {
  lastSyncedAt?: string | null
}

const updateDateSorter = (a: UpdateDateSorterParams, b: UpdateDateSorterParams) => {
  if (dayjs(a.lastSyncedAt).isBefore(dayjs(b.lastSyncedAt))) {
    return 1
  } else if (dayjs(b.lastSyncedAt).isBefore(dayjs(a.lastSyncedAt))) {
    return -1
  }
  return 0
}

type CreationDateSorterParams = {
  createdAt?: string | null
}

export const creationDateSorter = (a: CreationDateSorterParams, b: CreationDateSorterParams) => {
  if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) {
    return 1
  } else if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) {
    return -1
  }
  return 0
}

export const moveTemplateToFirstPosition = (destinationsList: Destination[]) => {
  const template = destinationsList.find(
    variant => variant.variantInCollection?.type === CollectionVariantType.template
  )
  destinationsList.sort(function (a, b) {
    return a === template ? -1 : b === template ? 1 : 0
  })
  return destinationsList
}

export const sortingOptions = [
  { value: 'name', label: 'Name', sorter: nameSorter },
  { value: 'update_date', label: 'Update date', sorter: updateDateSorter },
  { value: 'creation_date', label: 'Creation date', sorter: creationDateSorter },
]

export const formatVizName = (name?: string | null) => name || 'Unnamed visualization'

export const getPresentationTypeByServiceIdAndOauthType = (
  serviceId: string,
  oauthType: OAuthType
): EDestinationType | null => {
  if (serviceId === 'pres') {
    if (oauthType === OAuthType.GOOGLE) return EDestinationType.GoogleSlides
    return EDestinationType.MicrosoftPowerPoint
  }
  if (serviceId === 'doc') {
    if (oauthType === OAuthType.GOOGLE) return EDestinationType.GoogleDocs
    return EDestinationType.MicrosoftWord
  }
  return null
}

export const extractDriveNameFromUrl = (url: string | null | undefined) => {
  try {
    if (!url) return null
    const urlObject = new URL(url)
    if (urlObject.hostname.includes('.sharepoint.')) {
      const pathNameParts = urlObject.pathname?.split?.('/')
      if (pathNameParts.length > minExpectedPathnameParts) return pathNameParts[2]
    }
    return null
  } catch (error) {
    captureException(error)
    return null
  }
}

export const isSharedItem = (item: Destination | ICollection, user?: User) => {
  const isMicrosoft = isMicrosoftDestination({ type: item.type })
  const rollstackShared = item.userId !== user?.id
  if (!isMicrosoft) {
    return rollstackShared
  }
  if (rollstackShared) {
    return true
  }

  const userDriveName = extractDriveNameFromUrl(user?.microsoftOAuth?.driveUrl)

  if (!userDriveName) {
    return false
  }

  if ((item as ICollection)?.folderUrl) {
    const folderDriveName = extractDriveNameFromUrl((item as ICollection).folderUrl)
    return userDriveName !== folderDriveName
  }

  const itemDriveName = extractDriveNameFromUrl((item as Destination).docUrl)
  return userDriveName !== itemDriveName
}

export const isGoogleDestination = (destination: { type: EDestinationType }): destination is DestinationWithDoc => {
  return destination.type === EDestinationType.GoogleDocs || destination.type === EDestinationType.GoogleSlides
}

export const isGoogleDocsDestination = (destination: { type: EDestinationType }): destination is DestinationWithDoc => {
  return destination.type === EDestinationType.GoogleDocs
}

export const isMicrosoftDestination = (destination: { type: EDestinationType | EDataSourceType }): destination is DestinationWithDoc => {
  return (
    destination.type === EDestinationType.MicrosoftPowerPoint || destination.type === EDestinationType.MicrosoftWord
  )
}

export const isPresentationDestination = (destination: {
  type: EDestinationType
}): destination is DestinationWithDoc => {
  return destination.type === EDestinationType.GoogleSlides || destination.type === EDestinationType.MicrosoftPowerPoint
}

export const isDocumentDestination = (destination: { type: EDestinationType }): destination is DestinationWithDoc => {
  return destination.type === EDestinationType.GoogleDocs || destination.type === EDestinationType.MicrosoftWord
}

export const isEmailDestination = ({ type }: { type: EDestinationType }) => {
  return type === EDestinationType.Email
}

export const documentHasDoc = (destination: { type: EDestinationType }): destination is DestinationWithDoc => {
  return isGoogleDestination(destination) || isMicrosoftDestination(destination)
}

export const getScheduleDescription = (schedule: {
  frequency: Frequency
  selectedTime: ITimeOption
  selectedPeriod: Period
  startDate: string
}): string => {
  const { frequency, selectedTime, selectedPeriod, startDate } = schedule
  const time = `${selectedTime.value}${selectedPeriod}`
  const ordinalDate = dayjs(startDate).format('Do')

  switch (frequency) {
    case Frequency.DAILY:
      return `every day at ${time}`
    case Frequency.WEEKLY:
      return `every week at ${time}`
    case Frequency.MONTHLY:
      return `every month on the ${ordinalDate} at ${time}`
    case Frequency.QUARTERLY:
      return `every quarter on the ${ordinalDate} at ${time}`
  }
}

export const getEmptyDestinationEmailConfig = (destinationId?: string): DestinationEmailConfigCreateRequest => {
  if (!destinationId) {
    throw new Error('destinationId is required')
  }
  return {
    destinationId: destinationId,
    sharingFormat: DestinationEmailConfigSharingFormat.link,
    ...getEmptyEmailConfig(),
  }
}
