export enum Colors {
  white = '#FFFFFF',
  black = '#000000',

  blue = '#00A3FF',
  green = '#00B051',
  yellow = '#FFC300',

  base3 = '#FFD400',

  gray50 = '#F9FAFB',
  gray100 = '#F2F4F7',
  gray200 = '#EAECF0',
  gray300 = '#D0D5DD',
  gray400 = '#98a2b3',
  gray500 = '#667085',
  gray600 = '#475467',
  gray700 = '#344054',
  gray800 = '#1D2939',
  gray900 = '#101828',

  primary50 = '#F2FAFF',
  primary100 = '#ECF8FF',
  primary200 = '#D9F1FF',
  primary300 = '#BFE8FF',
  primary400 = '#91D5FC',
  primary500 = '#6AC5F8',
  primary600 = '#19A0EC',
  primary700 = '#0B8AD1',
  primary800 = '#096EA7',
  primary900 = '#065988',
  borderBlue = '#2684FF',

  error100 = '#FDE8E7',
  error200 = '#FECDCA',
  error300 = '#FDA29B',
  error400 = '#F97066',
  error500 = '#F1270B',
  error600 = '#F03224',
  error700 = '#E11A0C',
  error800 = '#BB1408',
  error900 = '#951703',

  success50 = '#ECFDF3',
  success500 = '#07CB79',
  success700 = '#07CB79',

  warning50 = '#FFFAEB',
  warning500 = '#FFB800',
  warning700 = '#D5A301',

  text = gray700,
  SVGColor = white,
}
