import { useEditor } from '@tiptap/react'
import { useEffect } from 'react'

import { getBaseEditorOptions } from '@features/emails/components/templates/editors/tiptap-settings'
import { decodeHTML } from '@features/emails/components/templates/utils'
import { CustomEmailTemplateVariable, EEmailTemplateType, EmailTemplate } from '@features/emails/types'
import { ISync } from '@features/syncs/types'

import { Colors } from '@shared/constants'

interface Args {
  template: EmailTemplate
  recipients?: string[]
  variables?: CustomEmailTemplateVariable[]
  syncs?: ISync[]
}

export const useTemplatePreviewSettings = ({ template, recipients = [], variables = [] }: Args) => {
  const getToStatement = () => (recipients.length > 0 ? `<p><b>To:</b> ${recipients[0]}</p>` : '')

  const parseVariables = (text: string) => {
    let parsedText = text

    for (const variable of variables) {
      if (!variable.disabled) {
        parsedText = parsedText.replaceAll(`{{${variable.id}}}`, variable.defaultValue || '')
      }
    }

    return parsedText
  }

  const addParagraphSpacing = (content: string) => {
    return `
    <html>
      <head>
        <style>
          p:empty:before {
            content: ' ';
            white-space: pre;
          }
        </style>
      </head>
      <body>
        ${content}
      </body>
    </html>
    `
  }

  const parseContent = (content: string) => {
    const bgColor = template.settings?.button.bgColor || Colors.primary600
    const textColor = template.settings?.button.textColor || Colors.white
    let decodedHTML = decodeHTML(content)
    if (template.type !== EEmailTemplateType.emailDestination) {
      decodedHTML = decodedHTML.replace(
        '{{open_button}}',
        `
    <td style="border-radius: 8px;" bgcolor="${bgColor}">
              <a
                style="
                  border-radius: 8px;
                  border: 1px solid ${bgColor};
                  background: ${bgColor};
                  padding: 8px 12px;
                  font-size: 14px;
                  color: ${textColor};
                  text-decoration: none;                  
                  display: inline-block;
                "                
                target="_blank"
              >
                ${template.settings?.button.text || 'Open Presentation'}
              </a>
          </td>
    `
      )
    }
    const htmlWithVariables = parseVariables(decodedHTML)
    const contentWithSpacing = addParagraphSpacing(htmlWithVariables)
    return contentWithSpacing
  }

  const getRecipientAndSubject = () => {
    return `
      ${getToStatement()}
      <p><b>Subject:</b> ${parseVariables(template.subject)}</p>
      `
  }

  const editorOptions = getBaseEditorOptions(getRecipientAndSubject())
  const editor = useEditor({
    ...editorOptions,
    content: getRecipientAndSubject(),
    editable: false,
  })

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      editor.commands.setContent(getRecipientAndSubject())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.subject, editor])

  return {
    editor,
    parsedContent: parseContent(template.content),
  }
}
