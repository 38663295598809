import { DataSources } from '@pages/data-sources/data-sources'
import { DataSourceOptions } from '@pages/data-sources/data-sources-options/data-sources-options'
import { LookerDataSourceSettings } from '@pages/data-sources/settings/looker/looker-data-sources-settings'
import { MetabaseDataSourceSettings } from '@pages/data-sources/settings/metabase/metabase-data-sources-settings'
import { TableauDataSourceSettings } from '@pages/data-sources/settings/tableau/tableau-data-sources-settings'
import { LookerUserManagement } from '@pages/data-sources/user-management/looker-user-management'
import { MetabaseUserManagement } from '@pages/data-sources/user-management/metabase-user-management'
import { TableauUserManagement } from '@pages/data-sources/user-management/tableau-user-management'

import { UserOrganizationRole } from '@features/organizations/types'

import {
  AddDataSourceCrumb,
  DataSourcesCrumb,
  DataSourcesLookerCrumb,
  DataSourcesMetabaseCrumb,
  DataSourcesNewLookerCrumb,
  DataSourcesNewMetabaseCrumb,
  DataSourcesNewTableauCrumb,
  DataSourcesTableauCrumb,
} from '@shared/components/breadcrumbs/breadcrumbs'

import { PathSegments } from './paths'
import { ProtectedRoute } from './protected-route'

export const dataSourceRoutes = {
  path: PathSegments.DATA_SOURCES,
  handle: { crumb: DataSourcesCrumb },
  children: [
    {
      index: true,
      element: <DataSources />,
    },
    {
      path: PathSegments.ADD,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <DataSourceOptions />
            </ProtectedRoute>
          ),
          handle: { crumb: AddDataSourceCrumb },
        },
      ],
    },
    {
      path: PathSegments.TABLEAU,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
              <TableauDataSourceSettings />
            </ProtectedRoute>
          ),
          handle: { crumb: DataSourcesNewTableauCrumb },
        },
        {
          path: PathSegments.SOURCE_ID,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
                  <TableauDataSourceSettings />
                </ProtectedRoute>
              ),
              handle: { crumb: DataSourcesTableauCrumb },
            },
            {
              path: PathSegments.USERS,
              element: (
                <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
                  <TableauUserManagement />
                </ProtectedRoute>
              ),
              handle: { crumb: DataSourcesTableauCrumb },
            },
          ],
        },
      ],
    },
    {
      path: PathSegments.LOOKER,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
              <LookerDataSourceSettings />
            </ProtectedRoute>
          ),
          handle: { crumb: DataSourcesNewLookerCrumb },
        },
        {
          path: PathSegments.SOURCE_ID,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
                  <LookerDataSourceSettings />
                </ProtectedRoute>
              ),
              handle: { crumb: DataSourcesLookerCrumb },
            },
            {
              path: PathSegments.USERS,
              element: (
                <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
                  <LookerUserManagement />
                </ProtectedRoute>
              ),
              handle: { crumb: DataSourcesLookerCrumb },
            },
          ],
        },
      ],
    },
    {
      path: PathSegments.METABASE,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
              <MetabaseDataSourceSettings />
            </ProtectedRoute>
          ),
          handle: { crumb: DataSourcesNewMetabaseCrumb },
        },
        {
          path: PathSegments.SOURCE_ID,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
                  <MetabaseDataSourceSettings />
                </ProtectedRoute>
              ),
              handle: { crumb: DataSourcesMetabaseCrumb },
            },
            {
              path: PathSegments.USERS,
              element: (
                <ProtectedRoute requiredRole={UserOrganizationRole.admin}>
                  <MetabaseUserManagement />
                </ProtectedRoute>
              ),
              handle: { crumb: DataSourcesMetabaseCrumb },
            },
          ],
        },
      ],
    },
  ],
}
