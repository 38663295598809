import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { DestinationTabs, buildDestinationPath, buildSlideIdSearchParam } from '@pages/routes/paths'

import { TemplateModificationModal } from '@features/collections'
import { CollectionVariantType } from '@features/collections/types'
import { EDataSourceType } from '@features/data-sources/types/types'
import { destinationHandlers, useGetDestinationById, useGetDestinationSlides } from '@features/destinations'
import { useSyncSettingsContext } from '@features/syncs'
import { NewSyncEmbed } from '@features/syncs/components/sync-embed/new-sync-embed/new-sync-embed'
import { CellRanges } from '@features/syncs/components/sync-settings/cell-ranges-select/cell-ranges-select'
import { DataSourceSelect } from '@features/syncs/components/sync-settings/data-source-select/data-source-select'
import { FiltersSelect } from '@features/syncs/components/sync-settings/filters-select/filters-select'
import { SlidePreviewSection } from '@features/syncs/components/sync-settings/slide-preview/slide-preview-section'
import { VisualizationSelect } from '@features/syncs/components/sync-settings/visualization-select/visualization-select'
import { useAuthStore } from '@features/users'

import { Button, PageContainer, Spinner } from '@shared/components'

import styles from './add-visualization.module.css'

enum StepsOrder {
  SlideSelect = 1,
  DataSourceSelect,
  VisualizationSelect,
  FiltersSelect,
}

export const AddVisualization: FC = () => {
  const {
    canSaveSync,
    isLoadingValidSyncsForSelectedFilters,
    isSavingSync,
    slideData,
    isNewSync,
    dataSource,
    clearVisSelectData,
    clearSyncSettings,
    saveSync,
    setDestination,
    updateVanityData,
    updateSyncData,
  } = useSyncSettingsContext()
  const { destinationId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { user } = useAuthStore()

  const initialSlideId = searchParams.get('slideId')

  const [templateModificationModal, setTemplateModificationModal] = useState(false)

  useEffect(() => {
    if (!isNewSync) {
      clearVisSelectData()
      clearSyncSettings()
      updateVanityData({ isNewSync: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: destination, isLoading: isDestinationLoading } = useGetDestinationById({ destinationId })
  const { data: slides, isLoading: isSlidesLoading } = useGetDestinationSlides(destination)
  const isLoading = useMemo(() => isDestinationLoading || isSlidesLoading, [isDestinationLoading, isSlidesLoading])

  useEffect(() => {
    if (slides && slides.length > 0) {
      const foundSlideIndex = slides.findIndex(slide => slide.id === initialSlideId)
      if (initialSlideId && foundSlideIndex !== -1) {
        updateSyncData({ slideData: { slideId: initialSlideId, slideIndex: foundSlideIndex + 1 } })
      } else {
        const defaultSlide = slides[0]
        updateSyncData({ slideData: { slideId: defaultSlide.id, slideIndex: 1 } })
      }
    }
  }, [slides, initialSlideId, updateSyncData])

  useEffect(() => {
    if (destination) {
      setDestination(destination)
    }
  }, [destination, setDestination])

  const goToDestinationsPage = useCallback(() => {
    navigate({
      pathname: buildDestinationPath(destinationId, DestinationTabs.VISUALIZATIONS),
      search: buildSlideIdSearchParam(slideData.slideId),
    })
  }, [navigate, destinationId, slideData.slideId])

  if (isLoading) {
    return (
      <PageContainer title="Add visualization" loading={isLoading}>
        <Spinner localCenter />
      </PageContainer>
    )
  }

  if (!destination || !slides) {
    return
  }

  const handleSlideIndexChange = (index: number) => {
    const slideId = slides[index - 1].id
    updateSyncData({ slideData: { slideId, slideIndex: index } })
  }

  const handleSaveSync = async () => {
    await saveSync()
    goToDestinationsPage()
  }

  const handleClickSave = async () => {
    if (
      user?.showTemplateModificationModal &&
      destination.variantInCollection?.type === CollectionVariantType.template
    ) {
      setTemplateModificationModal(true)
    } else {
      await handleSaveSync()
    }
  }

  const destinationHandler = destinationHandlers(destination)
  const step1 = destinationHandler.getAddVizStep1()

  return (
    <PageContainer title="Add visualization" back={goToDestinationsPage}>
      <div className={styles.container}>
        <div className="grid grid-cols-2 grid-rows-2 gap-5">
          <SlidePreviewSection
            destination={destination}
            slides={slides}
            slideIndex={slideData.slideIndex}
            slideId={slideData.slideId}
            setSlideIndex={handleSlideIndexChange}
            index={StepsOrder.SlideSelect}
          />
          <DataSourceSelect index={step1 ? StepsOrder.DataSourceSelect : StepsOrder.DataSourceSelect - 1} />

          {dataSource?.type === EDataSourceType.GoogleSheets ? (
            <CellRanges index={step1 ? StepsOrder.VisualizationSelect : StepsOrder.VisualizationSelect - 1} />
          ) : (
            <>
              <VisualizationSelect
                index={step1 ? StepsOrder.VisualizationSelect : StepsOrder.VisualizationSelect - 1}
              />
              <FiltersSelect index={step1 ? StepsOrder.FiltersSelect : StepsOrder.FiltersSelect - 1} />
            </>
          )}
        </div>

        <div className={styles.btnsContainer}>
          <Button className="flex w-24 justify-center" secondaryGray text="Back" onClick={goToDestinationsPage} />
          <Button
            className="flex w-24 justify-center"
            text="Save"
            onClick={handleClickSave}
            loading={isSavingSync}
            disabled={!canSaveSync || isLoadingValidSyncsForSelectedFilters}
          />
        </div>

        <NewSyncEmbed />

        <TemplateModificationModal
          visible={templateModificationModal}
          setModalOpen={setTemplateModificationModal}
          onConfirm={handleSaveSync}
        />
      </div>
    </PageContainer>
  )
}
