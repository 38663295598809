import { FC } from 'react'

import { IIcon } from '@assets/types'

export const RenameIcon: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M7.50065 5.83333H14.1673M10.834 5.83333V14.1667M7.33398 17.5H14.334C15.7341 17.5 16.4342 17.5 16.969 17.2275C17.4394 16.9878 17.8218 16.6054 18.0615 16.135C18.334 15.6002 18.334 14.9001 18.334 13.5V6.5C18.334 5.09987 18.334 4.3998 18.0615 3.86502C17.8218 3.39462 17.4394 3.01217 16.969 2.77248C16.4342 2.5 15.7341 2.5 14.334 2.5H7.33398C5.93385 2.5 5.23379 2.5 4.69901 2.77248C4.2286 3.01217 3.84615 3.39462 3.60647 3.86502C3.33398 4.3998 3.33398 5.09987 3.33398 6.5V13.5C3.33398 14.9001 3.33398 15.6002 3.60647 16.135C3.84615 16.6054 4.2286 16.9878 4.69901 17.2275C5.23379 17.5 5.93385 17.5 7.33398 17.5Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
