import isEmpty from 'lodash/isEmpty'
import { CornerDownRightIcon, Trash2 } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'

import { useGetSyncsWhereFiltersAreApplicable } from '@features/destinations/api/api'
import { useDestinationRights } from '@features/destinations/hooks'
import { useDestinationSelectFilters } from '@features/destinations/hooks/use-destination-select-filters'
import { Destination } from '@features/destinations/types'
import { SelectVisualizationsModal, useSyncSettingsContext } from '@features/syncs'
import { SyncEmbed } from '@features/syncs/components/sync-embed/sync-embed/sync-embed'
import { initialVanityData } from '@features/syncs/sync-settings-provider/use-vanity-data'
import { ISync } from '@features/syncs/types'

import { Button } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { useBoolean, useSelectable } from '@shared/hooks'

import { filtersTableColumns, filtersTableData } from './filters-table'
import { RefreshFilters } from './refresh-filters'

interface IProps {
  destination: Destination
}

export const DestinationFilters: FC<IProps> = ({ destination }) => {
  const [visualizationsModal, toggleVisualizationsModal] = useBoolean()
  const [filteredSyncIds, setFilteredSyncIds] = useState<string[]>([])
  const syncs = new Map((destination.syncs ?? []).map(sync => [sync.id, sync]))

  const isCollectionVariant = !!destination.variantInCollection
  const { viewer: isViewer } = useDestinationRights(destination)

  const {
    clearSyncSettings,
    updateSyncSettings,
    setDestination,
    batchApplyFilters,
    originalSync,
    newFiltersSelected,
    newParamsSelected,
    isSavingSync,
  } = useSyncSettingsContext()

  const {
    rowSelection,
    setRowSelection,
    applicableForSyncs,
    applyToSelectable,
    applyToModalVisible,
    closeApplyToModal,
    disableDeselect,
    disableRemove,
    disableApplyTo,
    showHowManyVisMessage,
    errorMessage,
    handleApplyTo,
    handleApplyToSave,
    isApplying,
    handleRemove,
    isRemoving,
    handleDeselect,
  } = useDestinationSelectFilters(destination)

  const noFilterSelected = isEmpty(newFiltersSelected) && isEmpty(newParamsSelected)

  const { data: validSyncIdsForSelectedFilters, isLoading: isLoadingSyncs } = useGetSyncsWhereFiltersAreApplicable({
    destinationId: originalSync?.presentationId,
    fromSyncId: originalSync?.id,
    filters: JSON.stringify(newFiltersSelected),
    params: JSON.stringify(newParamsSelected),
  })

  // Only syncs that have a certain filter value
  const filteredSyncsIds = (validSyncIdsForSelectedFilters ?? []).filter(syncId => filteredSyncIds.includes(syncId))
  const filteredSyncs = filteredSyncsIds.map(syncId => syncs.get(syncId)!).filter(Boolean)

  const modifyFilterSelectable = useSelectable(filteredSyncsIds)

  const sortedSyncs = useMemo(() => {
    return filteredSyncs.sort((a, b) => a.groupIndex - b.groupIndex)
  }, [filteredSyncs])

  const clearSelection = () => {
    setFilteredSyncIds([])
    clearSyncSettings()
  }

  const closeVisualizationModal = () => {
    clearSelection()
    toggleVisualizationsModal()
  }

  const handleModify = (syncIds: string[]) => {
    handleDeselect()

    setFilteredSyncIds(syncIds)

    // Select a sync out of the syncIds to open the embed
    const sync = destination.syncs?.find(sync => sync.id === syncIds[0])

    if (!sync) {
      return
    }

    setDestination(destination)
    updateSyncSettings(sync, {
      ...initialVanityData,
      filtersSelectModal: true,
    })
  }

  const handleSave = async () => {
    const applyToSyncs = (destination.syncs ?? []).filter((sync: ISync) => modifyFilterSelectable.selected[sync.id])
    await batchApplyFilters(applyToSyncs, true)
    closeVisualizationModal()
    clearSelection()
  }

  useEffect(() => {
    // Reset the context after opening an embed in the visualization tab
    clearSyncSettings()
  }, [clearSyncSettings])

  const hideActions = disableApplyTo && disableRemove
  const hideCheckbox = isViewer || isCollectionVariant
  const hideModify = isViewer || isCollectionVariant

  const preHeader = (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center gap-3">
        {hideActions ? null : (
          <>
            <Button
              text="Apply to"
              iconLeading={<CornerDownRightIcon />}
              secondaryGray
              disabled={disableApplyTo}
              onClick={handleApplyTo}
              loading={isApplying}
            />
            <div className="w-[450px] truncate">
              {showHowManyVisMessage && (
                <span>Selection is applicable to {applicableForSyncs.length} visualizations</span>
              )}
              {!!errorMessage && <span className="text-red-600">{errorMessage}</span>}
            </div>
            <Button
              text="Remove"
              iconLeading={<Trash2 />}
              secondaryGray
              disabled={disableRemove}
              loading={isRemoving}
              onClick={handleRemove}
            />
            <Button text="Deselect" linkGray disabled={disableDeselect} onClick={handleDeselect} />
          </>
        )}
      </div>
      <RefreshFilters destination={destination} />
    </div>
  )

  return (
    <div>
      <DataGrid
        data={filtersTableData(destination)}
        columns={filtersTableColumns({ syncs, hideCheckbox, hideModify, handleModify })}
        EmptyState="No filters are applied to your visualizations"
        preHeader={preHeader}
        enableRowSelection
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      <SelectVisualizationsModal
        visible={visualizationsModal && !noFilterSelected}
        syncs={sortedSyncs}
        selected={modifyFilterSelectable.selected}
        destination={destination}
        isLoadingSyncs={isLoadingSyncs}
        isAllSelected={modifyFilterSelectable.allSelected}
        isSaving={isSavingSync}
        handleAllSelect={modifyFilterSelectable.handleAllSelectClick}
        handleSelectOption={modifyFilterSelectable.handleSelectClick}
        handleBack={closeVisualizationModal}
        handleSave={handleSave}
      />

      <SelectVisualizationsModal
        visible={applyToModalVisible}
        syncs={applicableForSyncs}
        selected={applyToSelectable.selected}
        destination={destination}
        isLoadingSyncs={false}
        isSaving={isApplying}
        isAllSelected={applyToSelectable.allSelected}
        handleAllSelect={applyToSelectable.handleAllSelectClick}
        handleSelectOption={applyToSelectable.handleSelectClick}
        handleBack={closeApplyToModal}
        handleSave={handleApplyToSave}
      />

      <SyncEmbed onSave={toggleVisualizationsModal} onClose={clearSelection} />
    </div>
  )
}
