import { useMemo } from 'react'

import { EDataSourceType } from '@features/data-sources/types/types'
import { isPublicTableau } from '@features/embedding'
import { ISync } from '@features/syncs/types'
import { getDashboardElementName } from '@features/syncs/utils'

import { useStateReducer } from '@shared/hooks'

import { SyncData } from './types'
import { getSyncNameWithFullDashboard } from './utils'

export interface VanityData {
  newSyncEmbedModal: boolean
  vizSelectModal: boolean
  filtersSelectModal: boolean
  showDashboardModal: boolean
  showFilterModificationModal: boolean
  isSavingSync: boolean
  filtersLoading: boolean
  filtersChanged: boolean
  filtersRemoved: boolean
  embedDataChanged: boolean
  isNewSync: boolean
  syncDisplayName: string | null
  dashboardSelectEnabled: boolean
  canSaveSync: boolean
  isSyncNameLoading: boolean
}

export type UseSyncSettingsVanityData = VanityData & { updateVanityData: React.Dispatch<Partial<VanityData>> }

export const initialVanityData: VanityData = {
  newSyncEmbedModal: false,
  filtersSelectModal: false,
  vizSelectModal: false,
  isNewSync: true,
  syncDisplayName: null,
  dashboardSelectEnabled: true,
  canSaveSync: true,
  isSyncNameLoading: false,
  showDashboardModal: false,
  showFilterModificationModal: false,
  filtersChanged: false,
  filtersRemoved: false,
  isSavingSync: false,
  filtersLoading: false,
  embedDataChanged: false,
}

interface UseVanityDataProps {
  originalSync: ISync | null
  syncData: SyncData
}

export const useVanityData = ({ syncData }: UseVanityDataProps): UseSyncSettingsVanityData => {
  const [vanityData, updateVanityData] = useStateReducer<VanityData>(initialVanityData)
  const { embedDataChanged } = vanityData
  const {
    dataSource,
    fullDashboard,
    dashboardSelected,
    sheetSelected,
    cellRanges,
    selectedVis,
    lookerData,
    metabaseData,
    tableauData,
  } = syncData

  const dashboardSelectEnabled = useMemo(() => {
    if (!dataSource) return false

    if (dataSource.type === EDataSourceType.Tableau && isPublicTableau(dataSource.baseUrl)) return false

    return true
  }, [dataSource])

  const syncDisplayName = useMemo(() => {
    if (!dashboardSelected) {
      return null
    }

    if (fullDashboard) {
      return `${getSyncNameWithFullDashboard(dashboardSelected, tableauData?.viewName)} (Full dashboard)`
    }

    if (!selectedVis.length) {
      return null
    }

    return selectedVis.map(getDashboardElementName).join(', ')
  }, [dashboardSelected, fullDashboard, selectedVis, tableauData])

  const isSyncNameLoading = !vanityData.isNewSync && !Boolean(dashboardSelected)

  const canSaveSync = useMemo(
    () =>
      Boolean(
        dataSource &&
          ((dashboardSelected &&
            (lookerData || metabaseData || tableauData) &&
            (selectedVis.length || fullDashboard)) ||
            (sheetSelected && cellRanges && cellRanges.length > 0))
      ),
    [
      dataSource,
      dashboardSelected,
      sheetSelected,
      cellRanges,
      lookerData,
      metabaseData,
      tableauData,
      selectedVis,
      fullDashboard,
    ]
  )

  return {
    ...vanityData,
    updateVanityData,
    canSaveSync,
    dashboardSelectEnabled,
    isSyncNameLoading,
    syncDisplayName,
    embedDataChanged,
  }
}
