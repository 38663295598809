import { FC, MouseEventHandler } from 'react'

import { PresentationPlaceholderIcon } from '@assets/icons/presentation-placeholder'

import { ImageWithFallback } from '@shared/components'

interface SlidePreviewProps {
  syncCount?: number
  thumbnail?: string
  selected?: boolean
  notFound?: boolean
  dataTestId?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const SlidePreview: FC<SlidePreviewProps> = ({
  thumbnail,
  syncCount,
  selected = false,
  notFound,
  dataTestId,
  onClick,
}) => {
  return (
    <div
      className="bg-primary-25 relative overflow-hidden border-solid border-[3px]  data-[selected=false]:border-gray-200 data-[selected=false]:hover:border-primary-400 data-[selected=true]:border-primary-600 flex w-40 h-[100px] justify-center gap-1 px-0 py-4 rounded-xl"
      onClick={onClick}
      data-testid={dataTestId}
      data-selected={selected}
    >
      {!!syncCount && (
        <div
          className={`absolute inline-flex items-center top-2 right-2 px-2 py-1 text-center text-white rounded text-xs-bold bg-base-1 z-10 `}
        >
          {syncCount}
        </div>
      )}
      {notFound ? (
        <div className="flex items-center justify-center text-sm-semibold">Slide not found</div>
      ) : (
        <ImageWithFallback
          src={thumbnail}
          imageSize={{ height: 100, width: 160 }}
          fallback={
            <div className="flex items-center justify-center opacity-50">
              <PresentationPlaceholderIcon size={160} />
            </div>
          }
        />
      )}
    </div>
  )
}
