/* eslint-disable max-lines */
import { AxiosError } from 'axios'
import httpStatus from 'http-status'
import { UseMutationOptions, useInfiniteQuery, useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { EActivityAction, IActivityLog } from '@features/activity-log/types'
import {
  CreateDestinationRequest,
  Destination,
  DestinationEmailConfig,
  DestinationEmailConfigCreateRequest,
  DestinationSharingPermissions,
  DestinationTestEmailRequest,
  EDestinationType,
  IDriveData,
  IUpdateDestinationEmailConfigVariables,
} from '@features/destinations/types'
import { isEmailDestination } from '@features/destinations/utils'
import { EEmailTemplateType } from '@features/emails/types'
import { FilterType } from '@features/filters/types'
import { ISync } from '@features/syncs/types'
import { DestinationThemeColors } from '@features/theme/types'
import { useAuthStore } from '@features/users'

import {
  addVariantsCancelledToast,
  allUpdatesCancelledToast,
  batchApplyFilterPartialFailureToast,
  deleteSlideToast,
  errorToast,
  presentationCancelledToast,
  refreshFiltersToast,
  sharingFailedInDriveToast,
  successToast,
} from '@shared/components'
import { getMessage } from '@shared/constants'
import {
  ApiError,
  UseMutationWithErrorHandlerOptions,
  UseQueryWithErrorHandlerOptions,
  createNotFoundOrForbiddenErrorHandler,
  onError,
  queryClient,
  silentNotFoundOrForbiddenHandler,
  useMutationWithErrorHandler,
  useQueryWithErrorHandler,
} from '@shared/http'
import { api } from '@shared/http/axios'
import { ApiErrorHandlerParams } from '@shared/http/types'
import { CollaborationApiResponse, DrivePermission, DrivePermissionApiResponse } from '@shared/types'
import { Page } from '@shared/types/pagination'

import { handleDestinationGetError, handlePresentationUpdateError } from './error-handler'

interface IGetDestinationById {
  destinationId?: string
}
export interface ISlide {
  id: string
}

export const useGetDestinationById = ({ destinationId }: IGetDestinationById) => {
  const navigate = useNavigate()
  return useQueryWithErrorHandler<Destination, AxiosError>(
    ['presentation', { destinationId }],
    async () => {
      if (!destinationId) {
        return {} as Destination
      }

      const response = await api.get(`presentations/${destinationId}`)

      if (!response) {
        return []
      }

      return response.data
    },
    {
      errorHandlers: [handleDestinationGetError, createNotFoundOrForbiddenErrorHandler(navigate)],
      errorTitle: getMessage('PRESENTATION_GET_ERROR'),
      onError: () => {
        queryClient.invalidateQueries(['presentations'])
      },
    }
  )
}

export interface IGetDriveDataByDestinationId {
  destination: Destination
  enabled?: boolean
  onSuccess?: (data: IDriveData) => void
}
export const useGetDriveDataByDestinationId = ({ destination, enabled, onSuccess }: IGetDriveDataByDestinationId) => {
  const navigate = useNavigate()

  return useQueryWithErrorHandler<IDriveData, AxiosError>(
    ['drive-data', { destinationId: destination.id }],
    async () => {
      if (!destination.id) {
        return null
      }

      const response = await api.get(`presentations/${destination.id}/drive-data`)

      if (!response) {
        return null
      }

      return response.data
    },
    {
      enabled,
      errorHandlers: [handleDestinationGetError, silentNotFoundOrForbiddenHandler],
      errorTitle: getMessage('DESTINATION_DRIVE_DATA_GET_ERROR'),
      onSuccess: data => {
        queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
        onSuccess && onSuccess(data)
      },
      oauthOptions: {
        onCancel: () => {
          navigate(-1)
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
          queryClient.invalidateQueries(['slides', { presentationId: destination.id }])
          queryClient.invalidateQueries(['drive-data', { destinationId: destination.id }])
        },
      },
      filePickerOptions: {
        itemId: destination.docId,
        itemName: destination.name,
        type: destination.type,
        onCancel: () => {
          navigate(-1)
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
          queryClient.invalidateQueries(['slides', { presentationId: destination.id }])
          queryClient.invalidateQueries(['drive-data', { destinationId: destination.id }])
        },
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetDestinations = (fetch: boolean = true) => {
  const { user } = useAuthStore()

  return useQueryWithErrorHandler<Destination[], AxiosError>(
    ['presentations', { userId: user?.id }],
    async () => {
      if (!fetch || !user) return

      const response = await api.get('presentations')

      if (!response) return

      const { data } = response
      return data
    },
    {
      errorTitle: getMessage('PRESENTATIONS_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useAddDestination = () => {
  return useMutationWithErrorHandler<
    DrivePermissionApiResponse<Destination>,
    AxiosError,
    CreateDestinationRequest,
    any
  >(
    async destination => {
      const result = await api.post('/presentations', JSON.stringify(destination))
      return result.data
    },
    {
      onSuccess: (data: DrivePermissionApiResponse<Destination>) => {
        queryClient.invalidateQueries(['presentations'])
      },
      onError: (error: Error) => {
        const statusCode = (error as AxiosError).response?.status
        if (statusCode === httpStatus.FORBIDDEN) {
          return
        }
        onError(error, {
          title: getMessage('PRESENTATIONS_ADD_ERROR'),
          message: error.message,
        })
      },
      insufficientPermissionOptions: {
        message: getMessage('DESTINATION_INSUFFICIENT_PERMISSIONS_ERROR'),
      },
      useErrorBoundary: false,
    }
  )
}

type IPresentationUpdateRequest = Partial<Destination> & { id: string; updatedAt: string }
export const useUpdateDestination = (destination?: Destination | null) => {
  const queryFn = async ({ id, ...presentation }: IPresentationUpdateRequest) => {
    const result = await api.patch(`/presentations/${id}`, JSON.stringify(presentation))
    return result.data
  }

  const onSuccess = async (destination: Destination) => {
    queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
    queryClient.invalidateQueries(['presentations'])
    const collectionId = destination?.variantInCollection?.collectionId
    if (collectionId) {
      queryClient.invalidateQueries(['collection', { collectionId }])
    }
  }

  return useMutationWithErrorHandler(queryFn, {
    filePickerOptions: {
      itemId: destination?.docId,
      itemName: destination?.name,
      type: destination?.type,
    },
    mutationKey: 'update-destination',
    onSuccess,
    errorHandlers: [handlePresentationUpdateError],
    errorTitle: getMessage('PRESENTATION_UPDATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useSyncDestination = ({ destination }: { destination?: Destination } = {}) => {
  const queryFn = async (destination: Destination) => {
    const response = await api.post(`/presentations/${destination.id}/sync`)
    return response.data
  }

  const onSuccess = (data: DrivePermissionApiResponse, destination: Destination) => {
    queryClient.invalidateQueries(['activities-logs', { destinationId: destination.id }])
    queryClient.invalidateQueries(['email-templates', EEmailTemplateType.emailDestination, destination.id])
  }

  return useMutationWithErrorHandler((destination: Destination) => queryFn(destination), {
    filePickerOptions: {
      itemId: destination?.docId,
      itemName: destination?.name,
      type: destination?.type,
    },
    onSuccess,
    errorTitle: getMessage('PRESENTATIONS_SYNC_ERROR'),
    useErrorBoundary: false,
  })
}

export const useArchiveAndUpdate = ({ destination }: { destination?: Destination } = {}) => {
  const queryFn = async ({ id }: Pick<Destination, 'id'>) => {
    const result = await api.post(`/presentations/${id}/archive-and-update`)
    return result.data
  }
  const onSuccess = (data: DrivePermissionApiResponse) => {
    queryClient.invalidateQueries(['presentations'])
  }

  return useMutationWithErrorHandler(queryFn, {
    filePickerOptions: {
      itemId: destination?.docId,
      itemName: destination?.name,
      type: destination?.type,
    },
    onSuccess,
    errorTitle: getMessage('PRESENTATION_ARCHIVE_UPDATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useRefreshDestinations = () => {
  const queryFn = (presentation: Destination) => api.post(`/presentations/${presentation.id}/refresh`)

  const onSuccess = (_: any, presentation: Destination) => {
    const destinationId = presentation.id
    queryClient.invalidateQueries(['presentation', { destinationId }])
    queryClient.invalidateQueries(['presentations'])
    queryClient.invalidateQueries(['slides', { presentationId: destinationId }])
    queryClient.invalidateQueries(['slides-thumbnails', destinationId])
  }

  return useMutationWithErrorHandler(queryFn, {
    onSuccess,
    errorTitle: getMessage('PRESENTATIONS_REFRESH_ERROR'),
    useErrorBoundary: false,
  })
}

type IDestinationDuplicateRequest = Pick<Destination, 'name' | 'id'>
export const useDuplicateDestination = () => {
  const queryFn = ({ id, name }: IDestinationDuplicateRequest) =>
    api.post(`/presentations/${id}/duplicate`, JSON.stringify({ name }))

  return useMutationWithErrorHandler(queryFn, {
    errorTitle: getMessage('PRESENTATION_DUPLICATE_ERROR'),
    useErrorBoundary: false,
  })
}

export const useDeleteDestinations = () => {
  return useMutation((id: string) => api.delete(`/presentations/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['presentations'])
    },
    onError: (error: AxiosError) => {
      console.error(error)
    },
    useErrorBoundary: false,
  })
}

export const useGetSharedDestinations = () => {
  return useQuery<Destination[], Error>(
    ['shared-presentations'],
    async () => {
      const response = await api.get('presentations/shared')

      if (!response) return

      const { data } = response
      return data
    },
    {
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('SHARED_DESTINATIONS_GET_ERROR'),
          message: error.message,
        })
      },
      refetchOnMount: 'always',
      useErrorBoundary: false,
    }
  )
}

export const useCancelDestinationSync = () => {
  return useMutation((destinationId: string) => api.post(`/presentations/${destinationId}/cancel-sync`), {
    onSuccess: () => {
      presentationCancelledToast()
    },
    onError: (error: Error) => {
      onError(error, {
        title: getMessage('DESTINATION_UPDATE_CANCEL_FAILED_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useCancelAllDestinationUpdates = () => {
  return useMutation(() => api.post(`/presentations/cancel-updates`), {
    onSuccess: () => {
      allUpdatesCancelledToast()
    },
    onError: (error: Error) => {
      onError(error, {
        title: getMessage('DESTINATIONS_UPDATE_CANCEL_FAILED_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useCancelAddVariants = () => {
  return useMutation(() => api.post(`/collections/cancel-add-variants`), {
    onSuccess: () => {
      addVariantsCancelledToast()
    },
    onError: (error: Error) => {
      onError(error, {
        title: getMessage('COLLECTIONS_ADD_VARIANTS_CANCEL_FAILED_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useGetActivitiesLogs = ({
  destinationId,
  actions,
}: {
  destinationId: string
  actions?: EActivityAction[]
}) => {
  return useInfiniteQuery<Page<IActivityLog[]>, Error>(
    ['activities-logs', { destinationId, actions }],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams()
      if (pageParam) {
        params.append('cursor', pageParam)
      }
      if (actions) {
        actions.forEach(action => {
          params.append('actions', action)
        })
      }
      const response = await api.get(`/presentations/${destinationId}/activities-logs?` + params.toString())
      if (!response) return
      return response.data
    },
    {
      getNextPageParam: lastPage => lastPage.nextCursor,
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('ACTIVITIES_LOGS_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetSyncsWhereFiltersAreApplicable = ({
  destinationId,
  fromSyncId,
  filters,
  params,
}: {
  destinationId?: string
  fromSyncId?: string
  filters?: string
  params?: string
}) => {
  return useQuery<string[], Error>(
    ['syncs-filters-applicable', { destinationId, fromSyncId, filters, params }],
    async () => {
      if (!destinationId || !fromSyncId) {
        return []
      }
      const response = await api.get(`presentations/${destinationId}/syncs-filters-applicable`, {
        params: {
          fromSyncId,
          jsonFilters: filters ?? '',
          jsonParams: params ?? '',
        },
      })
      return response.data
    },
    {
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('PRESENTATION_GET_SYNCS_FILTER_APPLICABLE'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetDestinationThumbnails = (presentationId: string) => {
  return useQueryWithErrorHandler<
    {
      height: number
      width: number
      url: string
    },
    AxiosError
  >(
    ['destination-thumbnails', presentationId],
    async () => {
      const response = await api.get(`/presentations/${presentationId}/thumbnails`)

      if (!response) return

      const { data } = response
      return data
    },
    {
      errorTitle: getMessage('DESTINATION_THUMBNAIL_GET_ERROR'),
      oauthOptions: {
        ignore: true,
      },
      filePickerOptions: {
        ignore: true,
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetDestinationSlides = (
  destination?: Destination,
  options?: UseQueryWithErrorHandlerOptions<ISlide[], AxiosError>
) => {
  const navigate = useNavigate()

  return useQueryWithErrorHandler<ISlide[], AxiosError>(
    ['slides', { presentationId: destination?.id }],
    async () => {
      if (!destination?.id) {
        return []
      }
      if (
        destination.type !== EDestinationType.GoogleSlides &&
        destination.type !== EDestinationType.MicrosoftPowerPoint
      ) {
        return []
      }
      const response = await api.get(`/presentations/${destination.id}/slides`)
      if (!response) return
      const { data } = response
      return data
    },
    {
      errorTitle: getMessage('SLIDES_GET_ERROR'),
      oauthOptions: {
        onCancel: () => {
          navigate(-1)
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['drive-data', { destinationId: destination?.id }])
          queryClient.invalidateQueries(['slides', { presentationId: destination?.id }])
        },
      },
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
        onCancel: () => {
          navigate(-1)
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['drive-data', { destinationId: destination?.id }])
          queryClient.invalidateQueries(['slides', { presentationId: destination?.id }])
        },
      },
      onSuccess: data => {
        options?.onSuccess && options.onSuccess(data)
      },
      enabled: !!destination?.id,
      useErrorBoundary: false,
    }
  )
}

export const useRefreshFilters = () => {
  return useMutation((presentation: Destination) => api.post(`/presentations/${presentation.id}/refresh-filters`), {
    onSuccess: async () => {
      queryClient.invalidateQueries(['presentation'])
      refreshFiltersToast()
    },
    onError: (error: AxiosError) => {
      const data = error.response?.data as any
      const errorMessage = data?.metadata?.partiallyFailed ? data?.message : error.message
      onError(error, {
        title: getMessage('PRESENTATIONS_REFRESH_ERROR'),
        message: errorMessage,
      })
    },
    useErrorBoundary: false,
  })
}

export interface DuplicateSlidesRequest {
  slideIds: string[]
}
export const useDuplicateSlides = (
  destinationId?: string,
  options: UseMutationOptions<any, AxiosError, DuplicateSlidesRequest> = {}
) => {
  return useMutationWithErrorHandler(
    ({ slideIds }: DuplicateSlidesRequest) =>
      api.post(`/presentations/${destinationId}/duplicate-slides`, JSON.stringify(slideIds)),
    {
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context)
        successToast('Slides duplicated')
        queryClient.invalidateQueries(['presentation'])
        queryClient.invalidateQueries(['presentations'])
        queryClient.invalidateQueries(['slides', { presentationId: destinationId }])
      },
      errorTitle: getMessage('PRESENTATION_DUPLICATE_SLIDES_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export interface DeleteSlidesRequest {
  slideIds: string[]
}
export const useDeleteSlides = (
  destinationId?: string,
  options: UseMutationOptions<any, AxiosError, DeleteSlidesRequest> = {}
) => {
  return useMutationWithErrorHandler(
    ({ slideIds }: DeleteSlidesRequest) =>
      api.post(`/presentations/${destinationId}/delete-slides`, JSON.stringify(slideIds)),
    {
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context)
        deleteSlideToast(variables.slideIds.length > 1)
        queryClient.invalidateQueries(['presentation', { destinationId }])
        queryClient.invalidateQueries(['presentations'])
        queryClient.invalidateQueries(['slides', { presentationId: destinationId }])
      },
      onSettled: (data, error, variables, context) => {
        options.onSettled && options.onSettled(data, error, variables, context)
      },
      errorTitle: getMessage('PRESENTATION_DELETE_SLIDES_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export interface ShareDestinationParams {
  data: {
    email: string
    permission: DestinationSharingPermissions
  }[]
}
export const useShareDestination = (id: string, destination?: Destination) => {
  return useMutationWithErrorHandler<CollaborationApiResponse<Destination>, AxiosError, ShareDestinationParams, any>(
    async ({ data }: ShareDestinationParams) => {
      const response = await api.post(`/presentations/${id}/share`, JSON.stringify(data))
      return response.data
    },
    {
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
      },
      onSuccess: async (response, variables) => {
        await queryClient.invalidateQueries(['presentation', { destinationId: id }])
        await queryClient.invalidateQueries(['activities-logs', { destinationId: id }])
        if (!response.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast(
            `We couldn't share the presentation in your Drive. Please make sure that the following users have access to the files. Emails: ${variables.data.map(({ email }) => email).join(',')}`
          )
        }
      },
    }
  )
}

export interface UpdateShareDestinationParams {
  data: {
    userId: number
    permission: DestinationSharingPermissions
  }
}
export const useUpdateSharePermission = (id: string, destination?: Destination) => {
  return useMutationWithErrorHandler<
    CollaborationApiResponse<Destination>,
    AxiosError,
    UpdateShareDestinationParams,
    any
  >(
    async ({ data }: UpdateShareDestinationParams) => {
      const response = await api.patch(`/presentations/${id}/share/${data.userId}`, JSON.stringify(data))
      return response.data
    },
    {
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
      },
      onSuccess: async result => {
        await queryClient.invalidateQueries(['presentation', { destinationId: id }])
        await queryClient.invalidateQueries(['shared-presentations'])
        await queryClient.invalidateQueries(['activities-logs', { destinationId: id }])
        if (!result.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast()
        }
      },
      useErrorBoundary: false,
    }
  )
}

export interface RevokeShareDestinationParams {
  userId: number
}
export const useRevokeSharePermission = (id: string, destination?: Destination) => {
  return useMutationWithErrorHandler<
    CollaborationApiResponse<Destination>,
    AxiosError,
    RevokeShareDestinationParams,
    any
  >(
    async ({ userId }: RevokeShareDestinationParams) => {
      const response = await api.delete(`/presentations/${id}/share/${userId}`)
      return response.data
    },
    {
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
      },
      onSuccess: async result => {
        await queryClient.invalidateQueries(['presentation', { destinationId: id }])
        if (!result.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast()
        }
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetDestinationThemeColors = (
  destinationId?: string,
  options: UseQueryWithErrorHandlerOptions<DestinationThemeColors, AxiosError> = {}
) => {
  return useQueryWithErrorHandler<DestinationThemeColors, AxiosError>(
    ['themeColors', destinationId],
    async () => {
      if (!destinationId) {
        return
      }
      const result = await api.get(`/presentations/${destinationId}/theme-colors`)
      return result.data
    },
    {
      oauthOptions: {
        ignore: true,
      },
      filePickerOptions: {
        ignore: true,
      },
      errorTitle: getMessage('DESTINATION_THEME_COLORS_GET_ERROR'),
      enabled: options?.enabled,
    }
  )
}

type UpdateDestinationThemeColorsParams = {
  destinationId: string
  theme: DestinationThemeColors
}
export const useUpdateDestinationThemeColors = () => {
  return useMutationWithErrorHandler<any, any, UpdateDestinationThemeColorsParams, any>(
    async ({ destinationId, theme }) => {
      const result = await api.patch(`/presentations/${destinationId}/theme-colors`, JSON.stringify(theme))
      return result.data
    },
    {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries(['themeColors', variables.destinationId])
        successToast('Theme updated')
      },
    }
  )
}

export const useGetDestinationEmailConfig = (
  destinationId?: string,
  options: UseQueryWithErrorHandlerOptions<DestinationEmailConfig, AxiosError> = {}
) => {
  return useQueryWithErrorHandler<DestinationEmailConfig, AxiosError>(
    ['destination-email-config', destinationId],
    async () => {
      if (!destinationId) {
        return
      }
      const response = await api.get(`/presentations/${destinationId}/email/configs`)
      return response.data
    },
    {
      errorTitle: getMessage('DESTINATION_EMAIL_CONFIG_GET_ERROR'),
      useErrorBoundary: false,
      onSuccess: options.onSuccess,
      enabled: options.enabled && !!destinationId,
    }
  )
}

export const useAddDestinationEmailConfig = (
  destinationId?: string,
  options: UseMutationWithErrorHandlerOptions<
    DestinationEmailConfig,
    AxiosError,
    DestinationEmailConfigCreateRequest,
    any
  > = {}
) => {
  return useMutationWithErrorHandler<DestinationEmailConfig, AxiosError, DestinationEmailConfigCreateRequest, any>(
    async data => {
      if (!destinationId) {
        return
      }
      const result = await api.post(`/presentations/${destinationId}/email/configs`, JSON.stringify(data))
      return result.data
    },
    {
      onSuccess: async (...args) => {
        if (options.onSuccess) {
          await options.onSuccess(...args)
        }
        await queryClient.invalidateQueries(['destination-email-config', destinationId])
      },
      errorTitle: getMessage('DESTINATION_EMAIL_CONFIG_ADD_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useUpdateDestinationEmailConfig = (
  destinationId?: string,
  options: UseMutationWithErrorHandlerOptions<
    DestinationEmailConfig,
    AxiosError,
    IUpdateDestinationEmailConfigVariables,
    any
  > = {}
) => {
  return useMutationWithErrorHandler<DestinationEmailConfig, AxiosError, IUpdateDestinationEmailConfigVariables, any>(
    async ({ emailConfigId, data }) => {
      if (!destinationId) {
        return
      }
      const result = await api.patch(
        `/presentations/${destinationId}/email/configs/${emailConfigId}`,
        JSON.stringify(data)
      )
      return result.data
    },
    {
      onSuccess: async (...args) => {
        if (options.onSuccess) {
          await options.onSuccess(...args)
        }
        await queryClient.invalidateQueries(['destination-email-config', destinationId])
      },
      errorTitle: getMessage('DESTINATION_EMAIL_CONFIG_UPDATE_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useSendDestinationTestEmail = (
  destination: Destination,
  options: UseMutationWithErrorHandlerOptions<any, AxiosError, DestinationTestEmailRequest, any> = {}
) => {
  return useMutationWithErrorHandler<any, AxiosError, DestinationTestEmailRequest, any>(
    data => api.post(`/presentations/${destination.id}/send-test-email`, JSON.stringify(data)),
    {
      onSuccess: options.onSuccess,
      errorTitle: getMessage('DESTINATION_TEST_EMAIL_SEND_ERROR'),
      useErrorBoundary: false,
      errorHandlers: [
        (apiError: ApiError, _params?: ApiErrorHandlerParams) => {
          if (isEmailDestination(destination) && apiError.metadata?.code === 'destination-email-not-synced') {
            errorToast("Can't send email for destination. Please add a visualization, update it, and try again.")
            return true
          }
          return false
        },
      ],
    }
  )
}

export const useSendDestinationEmail = (
  destinationId: string,
  options: UseMutationWithErrorHandlerOptions<CollaborationApiResponse, AxiosError, any, any> = {}
) => {
  return useMutationWithErrorHandler<CollaborationApiResponse, AxiosError, any, any>(
    async () => {
      const result = await api.post(`/presentations/${destinationId}/send-email`)

      return result.data
    },
    {
      onSuccess: (data, variables, context) => {
        if (!data.drivePermission?.appliedSharing) {
          sharingFailedInDriveToast()
        }
        options.onSuccess && options.onSuccess(data, variables, context)
      },
      errorTitle: getMessage('DESTINATION_EMAIL_SEND_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export type SlideThumbnail = {
  slideId: string
  thumbnail: string
}

export const useGetSlidesThumbnails = (
  destinationId?: string,
  options: Partial<UseQueryWithErrorHandlerOptions<SlideThumbnail[], AxiosError>> = {}
) => {
  return useQueryWithErrorHandler<SlideThumbnail[], AxiosError>(
    ['slides-thumbnails', destinationId],
    async () => {
      if (!destinationId) {
        return []
      }

      const response = await api.get(`/presentations/${destinationId}/slides-thumbnails`)

      if (!response) return []

      return response.data
    },
    {
      errorTitle: getMessage('PRESENTATION_GET_SLIDES_THUMBNAILS_ERROR'),
      useErrorBoundary: false,
      ...options,
    }
  )
}

type BatchRemoveAppliedFiltersParams = {
  destinationId: string
  filters: { filterName: string; filterType: FilterType; filterValue: string; syncIds: string[] }[]
}

export const useBatchRemoveAppliedFilters = () => {
  const queryFn = async ({ destinationId, filters }: BatchRemoveAppliedFiltersParams) => {
    await api.post(`/presentations/${destinationId}/batch-remove-applied-filters`, JSON.stringify(filters))
  }

  const onSuccess = async (_: void, params: BatchRemoveAppliedFiltersParams) => {
    queryClient.invalidateQueries(['presentation', { destinationId: params.destinationId }])
  }

  return useMutationWithErrorHandler(queryFn, {
    mutationKey: 'batch-remove-applied-filters',
    onSuccess,
    errorTitle: getMessage('DESTINATIONS_BATCH_REMOVE_APPLIED_FILTERS_FAILED_ERROR'),
    useErrorBoundary: false,
  })
}

type BatchApplyAppliedFiltersParams = {
  destinationId: string
  params: {
    filters: { filterName: string; filterType: FilterType; filterValue: string }[]
    syncIds: string[]
  }
}

type BatchApplyAppliedFiltersResponse = {
  failedSyncs: ISync[]
  updatedSyncs: ISync[]
}

export const useBatchApplyAppliedFilters = () => {
  const queryFn = async ({
    destinationId,
    params,
  }: BatchApplyAppliedFiltersParams): Promise<BatchApplyAppliedFiltersResponse> => {
    const response = await api.post(
      `/presentations/${destinationId}/batch-apply-applied-filters`,
      JSON.stringify(params)
    )
    return response.data
  }

  const onSuccess = async (data: BatchApplyAppliedFiltersResponse, params: BatchApplyAppliedFiltersParams) => {
    queryClient.invalidateQueries(['presentation', { destinationId: params.destinationId }])
    if (data.failedSyncs.length) {
      batchApplyFilterPartialFailureToast(data.failedSyncs.map(sync => sync.name))
    }
  }

  return useMutationWithErrorHandler(queryFn, {
    mutationKey: 'batch-apply-applied-filters',
    onSuccess,
    errorTitle: getMessage('DESTINATIONS_BATCH_APPLY_APPLIED_FILTERS_FAILED_ERROR'),
    useErrorBoundary: false,
  })
}

export const useGetDrivePermissionCheckForCopy = ({
  destination,
  onSuccess,
}: {
  destination: Destination | null
  onSuccess: () => void
}) => {
  return useQueryWithErrorHandler<DrivePermission, AxiosError>(
    ['drive-permission-check', { destinationId: destination?.id }],
    async () => {
      if (!destination) {
        return null
      }

      const response = await api.get(`presentations/${destination.id}/drive-permission-check`, {
        params: {
          permission: 'copy',
        },
      })

      return response.data
    },
    {
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
        onSuccess,
      },
      cacheTime: 0,
      errorTitle: getMessage('DESTINATION_DRIVE_PERMISSION_CHECK_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}
