import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { getMessage } from '@shared/constants'
import { createNotFoundOrForbiddenErrorHandler, onError, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'
import { PaginatedResponse } from '@shared/types'

import {
  HistoricalRun,
  HistoricalRunDetails,
  HistoricalRunOverview,
  HistoricalRunOverviewAggregate,
  HistoricalRunOverviewList,
  HistoricalRunStatus,
  HistoricalRunTrigger,
} from './types'

export type IGetHistoricalRunsParams = {
  status?: HistoricalRunStatus
  trigger?: HistoricalRunTrigger
  q?: string
  page?: number
}

export const useGetHistoricalRuns = (params: IGetHistoricalRunsParams) => {
  return useQuery<PaginatedResponse<HistoricalRun>, AxiosError>(
    ['historical-runs', params],
    async () => {
      const response = await api.get('historical-runs', {
        params: { ...params, sortField: 'startedAt', sortOrder: 'desc', limit: '10' },
      })
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('HISTORICAL_RUN_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetHistoricalRunsByDestinationId = (
  destinationId: string,
  params: Pick<IGetHistoricalRunsParams, 'page'>
) => {
  return useQuery<PaginatedResponse<HistoricalRun>, AxiosError>(
    ['historical-runs', { destinationId, ...params }],
    async () => {
      const response = await api.get(`historical-runs/presentations/${destinationId}`, {
        params: { ...params, sortField: 'startedAt', sortOrder: 'desc', limit: '10' },
      })
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('HISTORICAL_RUN_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetHistoricalRunById = (historicalRunId: string | undefined) => {
  const navigate = useNavigate()

  return useQueryWithErrorHandler<HistoricalRunDetails, AxiosError>(
    ['historical-run', { historicalRunId }],
    async () => {
      if (!historicalRunId) {
        throw new AxiosError(getMessage('HISTORICAL_RUN_NOT_FOUND'), '404')
      }

      const response = await api.get(`historical-runs/${historicalRunId}`)
      if (!response) {
        throw new AxiosError(getMessage('HISTORICAL_RUN_NOT_FOUND'), '404')
      }

      return response.data
    },
    {
      errorHandlers: [createNotFoundOrForbiddenErrorHandler(navigate)],
      errorTitle: getMessage('HISTORICAL_RUN_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useGetHistoricalRunOverview = () => {
  return useQuery<HistoricalRunOverview, AxiosError>(
    'historical-run-overview',
    async () => {
      const response = await api.get('historical-runs/overview')
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('HISTORICAL_RUN_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export interface IUserRoleParams {
  isAdmin?: boolean
  isOwner?: boolean
}

export interface IGetHistoricalRunOverviewAggregateParams {
  time?: 'month' | null
  userId?: number | null
}

export const useGetHistoricalRunOverviewAggregate = (
  params: IGetHistoricalRunOverviewAggregateParams,
  userRole: IUserRoleParams
) => {
  const checkRequestEnabled = (
    params: IGetHistoricalRunOverviewAggregateParams,
    userRole: IUserRoleParams
  ): boolean => {
    if (userRole.isAdmin || userRole.isOwner) {
      return true
    }
    if (params.userId !== undefined && params.userId !== null) {
      return true
    }
    return false
  }
  return useQuery<HistoricalRunOverviewAggregate, AxiosError>(
    ['historical-run-overview-aggregate', params],
    async () => {
      const response = await api.get('historical-runs/overview-aggregate', {
        params,
      })
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('HISTORICAL_RUN_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
      enabled: checkRequestEnabled(params, userRole),
    }
  )
}

export interface IGetHistoricalRunOverviewListParams extends IGetHistoricalRunOverviewAggregateParams {
  page?: number
}

export const useGetHistoricalRunOverviewList = (
  params: IGetHistoricalRunOverviewListParams,
  userRole: IUserRoleParams
) => {
  return useQuery<HistoricalRunOverviewList, AxiosError>(
    ['historical-run-overview-list', params],
    async () => {
      const response = await api.get('historical-runs/overview-list', {
        params: { ...params, sortField: 'startedAt', sortOrder: 'desc', limit: '10' },
      })
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('HISTORICAL_RUN_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
      enabled: userRole.isAdmin || userRole.isOwner,
    }
  )
}
