import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC, useMemo } from 'react'

import {
  WithCollectionDeleteProps,
  WithCollectionRenameProps,
  WithCollectionShareProps,
  WithCollectionUpdateProps,
  useCollectionRights,
} from '@features/collections'
import {
  withCollectionDelete,
  withCollectionRename,
  withCollectionShare,
  withCollectionUpdate,
} from '@features/collections/hocs'
import { ICollection } from '@features/collections/types'
import { isSharedItem } from '@features/destinations'
import { getGooglePickerViewId, useDrivePicker } from '@features/drive'
import { useAuthStore } from '@features/users'

import { config } from '@shared/config'

import { CollectionMenu } from './collection-menu/collection-menu'

dayjs.extend(localizedFormat)

type ICollectionMenuButton = {
  collection: ICollection
} & WithCollectionDeleteProps &
  WithCollectionUpdateProps &
  WithCollectionShareProps &
  WithCollectionRenameProps

const RawCollectionMenuButton: FC<ICollectionMenuButton> = ({
  collection,
  renameCollection,
  shareCollection,
  deleteCollection,
}) => {
  const user = useAuthStore(state => state.user)

  const { contributor, admin, owner } = useCollectionRights(collection)

  const deleteCollectionClick = () => deleteCollection(collection.id)
  const shareCollectionClick = () => shareCollection(collection.id)
  const renameClick = () => renameCollection(collection, true)
  const isShared = useMemo(() => isSharedItem(collection, user), [collection, user])

  const { openPicker } = useDrivePicker()
  const { clientId, apiKey } = config.google

  const authorizeCollection = () => {
    openPicker({
      appId: clientId,
      clientId,
      developerKey: apiKey,
      token: user?.googleOAuth?.accessToken,
      query: collection.name,
      viewId: getGooglePickerViewId(collection.type),
      setIncludeFolders: true,
      setSelectFolderEnabled: true,
      multiselect: true,
      title: `Select all the files and folders for the collection: ${collection.name}`,
      callbackFunction: async (data: google.picker.ResponseObject) => {
        console.log(data)
      },
    })
  }

  return (
    <CollectionMenu
      collection={collection}
      admin={admin}
      handleDeleteCollection={deleteCollectionClick}
      renameClick={renameClick}
      shareCollection={shareCollectionClick}
      shared={isShared}
      contributor={contributor}
      owner={owner}
      authorizeCollection={authorizeCollection}
    />
  )
}
export const CollectionMenuButton = withCollectionShare(
  withCollectionUpdate(withCollectionDelete(withCollectionRename(RawCollectionMenuButton)))
)
