import compact from 'lodash/compact'

import { Destination, EDestinationType } from '@features/destinations/types'
import { isEmailDestination, isPresentationDestination } from '@features/destinations/utils'
import {
  CustomEmailTemplateVariable,
  EEmailTemplateType,
  EmailTemplate,
  EmailTemplateSettings,
} from '@features/emails/types'
import { ISync } from '@features/syncs/types'

import { BaseEmailConfig } from '@shared/components/email-config/types'
import { Colors } from '@shared/constants'

export const getDefaultEmailTemplate = (type: EDestinationType): EmailTemplate => ({
  name: '',
  content: '',
  subject: '',
  type: isEmailDestination({ type }) ? EEmailTemplateType.emailDestination : EEmailTemplateType.destinationUpdate,
  settings: {
    button: {
      text: isPresentationDestination({ type }) ? 'Open Presentation' : 'Open Document',
      bgColor: Colors.primary600,
      textColor: Colors.white,
    },
  },
})

export const decodeHTML = (html: string): string => {
  return html.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
}

export const updateEmailTemplateSetting = <T extends keyof EmailTemplateSettings>(
  template: EmailTemplate<T>,
  settingName: keyof EmailTemplateSettings[T],
  key: keyof EmailTemplateSettings[T][typeof settingName],
  value: any
): EmailTemplate<T> => {
  if (!template.settings) {
    template.settings = {} as EmailTemplateSettings[T]
  }

  if (!template.settings[settingName]) {
    template.settings[settingName] = {} as EmailTemplateSettings[T][typeof settingName]
  }

  template.settings[settingName][key] = value

  return template
}

export const getUpdateDestinationTemplateVariables = (
  destination: Destination,
  emailConfig?: BaseEmailConfig
): CustomEmailTemplateVariable[] => {
  const { type, variantInCollection } = destination

  const recipientConfig = emailConfig?.recipientConfig || []
  const customVariables = Object.entries(recipientConfig[0] || {})
    .filter(([key]) => !['recipient_name', 'recipient_email', 'variant_name'].includes(key))
    .map(([key, value]) => ({
      id: key,
      description: `Example: ${value}`,
      defaultValue: value,
    }))

  return compact([
    !isEmailDestination({ type }) && {
      id: 'open_button',
      description: `(Only for body) A button redirecting to your ${isPresentationDestination({ type }) ? 'presentation' : 'document'}`,
    },
    variantInCollection
      ? {
          id: 'variant_name',
          description: 'The name of the variant',
          defaultValue: variantInCollection.code,
        }
      : null,
    ...customVariables,
  ])
}

export const mapSyncToVariable = (syncId: string) => `{{ visualization_${syncId} }}`

export const parseSyncsToVariables = (template: EmailTemplate, syncs: ISync[]) => {
  let content = template.content
  for (const sync of syncs) {
    if (sync.imageUrl) {
      content = content.replaceAll(sync.imageUrl, mapSyncToVariable(sync.id))
    }
  }
  return { ...template, content }
}
