import { FC, PropsWithChildren, createContext } from 'react'

import { SocketEvent, useAuthenticatedSocket } from '@shared/hooks/use-authenticated-socket'
import { queryClient } from '@shared/http'

interface CollaborationUpdate {
  id: string
  organization: {
    id: string | null
  }
}

export interface SyncUpdated extends CollaborationUpdate {
  presentationId: string
}

export interface SyncDeleted extends CollaborationUpdate {
  presentationId: string
}

export interface SyncAdded extends CollaborationUpdate {
  presentationId: string
}

export interface PresentationUpdated extends CollaborationUpdate {}

export interface PresentationDeleted extends CollaborationUpdate {}

export interface CollectionUpdated extends CollaborationUpdate {}

export interface CollectionDeleted extends CollaborationUpdate {}

interface ICollaborationContext {}

const CollaborationContext = createContext<ICollaborationContext>({})

export const CollaborationProvider: FC<PropsWithChildren> = ({ children }) => {
  const events: SocketEvent[] = [
    {
      name: 'syncUpdated',
      handler: async (event: SyncUpdated) => {
        queryClient.invalidateQueries(['sync', event.id])
        queryClient.invalidateQueries(['presentation', { destinationId: event.presentationId }])
      },
    },
    {
      name: 'syncDeleted',
      handler: async (event: SyncDeleted) => {
        queryClient.invalidateQueries(['sync', event.id])
        queryClient.invalidateQueries(['presentation', { destinationId: event.presentationId }])
      },
    },
    {
      name: 'syncAdded',
      handler: async (event: SyncAdded) => {
        queryClient.invalidateQueries(['sync', event.id])
        queryClient.invalidateQueries(['presentation', { destinationId: event.presentationId }])
      },
    },
    {
      name: 'presentationUpdated',
      handler: async (event: PresentationUpdated) => {
        queryClient.invalidateQueries(['presentation', { destinationId: event.id }])
        queryClient.invalidateQueries(['presentations'])
        queryClient.invalidateQueries(['shared-presentations'])
      },
    },
    {
      name: 'presentationDeleted',
      handler: async (event: PresentationDeleted) => {
        queryClient.invalidateQueries(['presentation', { destinationId: event.id }])
        queryClient.invalidateQueries(['presentations'])
        queryClient.invalidateQueries(['shared-presentations'])
      },
    },
    {
      name: 'collectionUpdated',
      handler: async (event: CollectionUpdated) => {
        queryClient.invalidateQueries(['collection', { collectionId: event.id }])
        queryClient.invalidateQueries(['collections'])
        queryClient.invalidateQueries(['shared-collections'])
      },
    },
    {
      name: 'collectionDeleted',
      handler: async (event: CollectionDeleted) => {
        queryClient.invalidateQueries(['collection', { collectionId: event.id }])
        queryClient.invalidateQueries(['collections'])
        queryClient.invalidateQueries(['shared-collections'])
      },
    },
  ]

  useAuthenticatedSocket(events)

  return <CollaborationContext.Provider value={{}}>{children}</CollaborationContext.Provider>
}
