import isEqual from 'lodash/isEqual'
import { forwardRef, useEffect, useImperativeHandle } from 'react'

import { LookerCoreDataSource } from '@features/data-sources/types'
import { LookerDashboardElement, useLooker } from '@features/embedding'
import { LookerEmbed } from '@features/embedding/looker/looker-embed/looker-embed'
import { ILookerDashboard, JsonObjectLooker } from '@features/embedding/looker/types'
import { deleteNullFilters } from '@features/syncs/hooks'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { SyncEmbedProps, SyncEmbedRef } from './types'

export const SyncEmbedLooker = forwardRef<SyncEmbedRef, SyncEmbedProps<LookerCoreDataSource, ILookerDashboard>>(
  ({ dataSource, dashboard, toggleFiltersChanged, setVizSelected }, ref) => {
    const {
      isNewSync,
      lookerData,
      selectedVis,
      vizSelectModal,
      filtersSelectModal,
      fullDashboard,
      filtersSelected: filtersSelectedFromContext,
      updateSyncData,
      visSelectCallback,
      toggleFullDashboard,
    } = useSyncSettingsContext()

    const initialFilters = filtersSelectedFromContext as JsonObjectLooker

    const {
      numRows,
      hasFilters,
      iframeRect,
      loadingDash,
      dashboardUrl,
      filtersSelected,
      elementsOverlays,
      elementsSelected,
      loadingEmbedData,
      loadingDashWithoutFilters,
      privateIframeRef,
      privateIframeSrc,
      embedType,
      loadingFilters,
      handleSelectElement,
      handleUnselectElement,
      makeDashboardForSignedEmbed,
      makeDashboardWithoutFiltersForSignedEmbed,
    } = useLooker({
      dataSource,
      dashboardId: dashboard.id,
      initialFilters,
      showOverlays: vizSelectModal,
      singleSelect: !isNewSync,
      initialElements: selectedVis as LookerDashboardElement[],
      visSelectCallback,
    })

    const localFiltersChanged = !isEqual(initialFilters, deleteNullFilters(filtersSelected))

    useEffect(() => {
      toggleFiltersChanged?.(localFiltersChanged)
    }, [toggleFiltersChanged, localFiltersChanged])

    useEffect(() => {
      setVizSelected?.(elementsSelected.length > 0)
    }, [elementsSelected, setVizSelected])

    const isLoading = loadingDash || loadingDashWithoutFilters || loadingEmbedData || loadingFilters

    useImperativeHandle(ref, () => ({
      saveEmbedDataToProvider: () => {
        if (!elementsSelected || !dashboardUrl) {
          return
        }

        updateSyncData({
          selectedVis: elementsSelected,
          lookerData: {
            lookerCredentialsId: dataSource.id,
            filters: JSON.stringify(deleteNullFilters(filtersSelected)),
            fullDashboard,
            dashboardId: dashboard.id,
            dashboardName: dashboard.title,
            dashboardUrl,
          },
        })
      },
      saveFiltersToContext: () => {
        if (!lookerData) {
          console.log('Error: No looker data when saving filters to context')
          return
        }

        // if filters window opened and closed without any actions filtersSelected is undefined
        if (!filtersSelected) return
        if (!dashboardUrl) return

        updateSyncData({
          lookerData: {
            ...lookerData,
            filters: JSON.stringify(deleteNullFilters(filtersSelected)),
            dashboardUrl,
          },
        })
      },
    }))

    return (
      <LookerEmbed
        embedType={embedType}
        numRows={numRows}
        makeDashboardWithoutFiltersForSignedEmbed={makeDashboardWithoutFiltersForSignedEmbed}
        makeDashboardForSignedEmbed={makeDashboardForSignedEmbed}
        loading={isLoading}
        showOverlays={vizSelectModal && (!loadingDash || !loadingDashWithoutFilters)}
        hasFilters={hasFilters}
        dashboardId={dashboard.id}
        iframeWidth={iframeRect.width}
        iframeHeight={iframeRect.height}
        fullDashboard={fullDashboard}
        elementsOverlays={elementsOverlays}
        elementsSelected={elementsSelected}
        fullDashboardClick={toggleFullDashboard}
        handleSelectElement={handleSelectElement}
        handleUnselectElement={handleUnselectElement}
        modifyFiltersOnly={!isNewSync && filtersSelectModal}
        visSelectOnly={!isNewSync && vizSelectModal}
        privateIframeSrc={privateIframeSrc}
        privateIframeRef={privateIframeRef}
      />
    )
  }
)
