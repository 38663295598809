import { FC } from 'react'

import { IIcon } from '@assets/types'

export const GDocsIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path
        d="M16.1717 19.9846H4.36259C3.61316 19.9846 3 19.3715 3 18.622V1.36259C3 0.613165 3.61316 0 4.36259 0H12.5381L17.5343 4.99616V18.622C17.5343 19.3715 16.9211 19.9846 16.1717 19.9846Z"
        fill="#3086F6"
      />
      <path d="M12.5381 0L17.5343 4.99616H12.5381V0Z" fill="#0C67D6" />
      <path
        d="M14.3549 8.85682H6.17936V7.72133H14.3549V8.85682ZM14.3549 10.4465H6.17936V11.582H14.3549V10.4465ZM12.0839 13.1717H6.17936V14.3072H12.0839V13.1717Z"
        fill="#FDFFFF"
      />
    </svg>
  )
}
