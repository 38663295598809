import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import startCase from 'lodash/startCase'
import { useCallback, useMemo, useState } from 'react'
import { SingleValue } from 'react-select'
import { v4 as uuid } from 'uuid'

import {
  Destination,
  Frequency,
  IFormattedSchedule,
  IFreqOption,
  ITimeOption,
  IUpdateSchedule,
} from '@features/destinations/types'

import { Period } from '@shared/components/period-switch/period-switch'
import { numbers } from '@shared/constants/numbers'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const { hoursInDay: HOURS_IN_DAY, hoursInHalfDay: HALF_DAY_IN_HOURS } = numbers.time
const DEFAULT_HOUR = 6

const freqOptions: IFreqOption[] = Object.values(Frequency).map(freq => ({
  label: startCase(freq),
  value: freq,
}))

const timeOptions: ITimeOption[] = Array.from({ length: HOURS_IN_DAY }, (_, i) => ({
  label: `${(i + 1) % HALF_DAY_IN_HOURS || HALF_DAY_IN_HOURS}:00`,
  value: `${(i + 1) % HALF_DAY_IN_HOURS || HALF_DAY_IN_HOURS}:00`,
}))

const formatDate = (date: string | null | undefined, format = 'YYYY-MM-DD'): string =>
  date ? dayjs.utc(date).format(format) : ''

const getStartingDate = (schedule: Partial<IUpdateSchedule>): string =>
  formatDate(schedule.startDate) || dayjs.utc().format('YYYY-MM-DD')

const getEndingDate = (schedule: Partial<IUpdateSchedule>, startingDay?: string): string =>
  formatDate(schedule.endDate) ||
  (startingDay
    ? dayjs.utc(startingDay).add(1, 'day').format('YYYY-MM-DD')
    : dayjs.utc().add(1, 'day').format('YYYY-MM-DD'))

const getInitialTime = (schedule: Partial<IUpdateSchedule>): ITimeOption => {
  const time = schedule.startDate ? dayjs.utc(schedule.startDate).local().format('h:mm') : `${DEFAULT_HOUR}:00`
  return { label: time, value: time }
}

const getInitialPeriod = (schedule: Partial<IUpdateSchedule>): Period =>
  (schedule.startDate ? dayjs.utc(schedule.startDate).local().format('A').toLowerCase() : 'am') as Period

const updateStartDateWithPeriod = (startDate: string, period: Period): string => {
  let date = dayjs.utc(startDate).local()
  const currentPeriod = date.format('A').toLowerCase()

  if (period !== currentPeriod) {
    date = date.add(period === 'pm' ? HALF_DAY_IN_HOURS : -HALF_DAY_IN_HOURS, 'hour')
  }

  return date.utc().toISOString()
}

const formatSchedule = (schedule: IUpdateSchedule): IFormattedSchedule => ({
  id: schedule.id,
  startDate: getStartingDate(schedule),
  endDate: formatDate(schedule.endDate),
  enableEndDate: Boolean(schedule.endDate),
  selectedTime: getInitialTime(schedule),
  selectedPeriod: getInitialPeriod(schedule),
  frequency: schedule.frequency || Frequency.WEEKLY,
  parentUpdateScheduleId: schedule.parentUpdateScheduleId,
})

export const useDestinationSchedule = (destination: Destination) => {
  const [schedules, setSchedules] = useState<IUpdateSchedule[]>(destination.updateSchedules || [])
  const [expandedScheduleId, setExpandedScheduleId] = useState<string | null>(null)

  const formattedSchedules = useMemo(() => schedules.map(formatSchedule), [schedules])

  const addSchedule = useCallback(() => {
    const newSchedule: IUpdateSchedule = {
      id: uuid(),
      name: '',
      startDate: getStartingDate({}),
      endDate: null,
      frequency: Frequency.WEEKLY,
      destinationId: destination.id,
      selectedSlideIds: [],
      selectedSyncIds: [],
      scheduled: true,
    }
    setExpandedScheduleId(newSchedule.id)
    setSchedules(prev => [...prev, newSchedule])
  }, [destination.id])

  const removeSchedule = useCallback((scheduleId: string) => {
    setSchedules(prev => prev.filter(schedule => schedule.id !== scheduleId))
  }, [])

  const updateSchedule = useCallback((updatedSchedule: IUpdateSchedule) => {
    setSchedules(prev => prev.map(schedule => (schedule.id === updatedSchedule.id ? updatedSchedule : schedule)))
  }, [])

  const handleTimeChange = useCallback(
    (scheduleId: string, option: SingleValue<ITimeOption>) => {
      if (!option) return

      setSchedules(prev =>
        prev.map(schedule => {
          if (schedule.id !== scheduleId) return schedule

          const period = formattedSchedules.find(s => s.id === scheduleId)?.selectedPeriod
          let hour = parseInt(option.value.split(':')[0])

          if (period === 'pm' && hour < HALF_DAY_IN_HOURS) {
            hour += HALF_DAY_IN_HOURS
          } else if (period === 'am' && hour === HALF_DAY_IN_HOURS) {
            hour = 0
          }

          const updatedStartDate = dayjs(schedule.startDate).set('hour', hour).toISOString()
          return { ...schedule, startDate: updatedStartDate }
        })
      )
    },
    [formattedSchedules]
  )

  const handlePeriodChange = useCallback((scheduleId: string, period: Period) => {
    setSchedules(prev =>
      prev.map(schedule =>
        schedule.id === scheduleId
          ? { ...schedule, startDate: updateStartDateWithPeriod(schedule.startDate, period) }
          : schedule
      )
    )
  }, [])

  const handleFrequencyChange = useCallback((scheduleId: string, option: SingleValue<IFreqOption>) => {
    if (!option) return

    setSchedules(prev =>
      prev.map(schedule => (schedule.id === scheduleId ? { ...schedule, frequency: option.value } : schedule))
    )
  }, [])

  const handleScheduleChange = useCallback((scheduleId: string, updatedSchedule: Partial<IUpdateSchedule>) => {
    setSchedules(prev =>
      prev.map(schedule => (schedule.id === scheduleId ? { ...schedule, ...updatedSchedule } : schedule))
    )
  }, [])

  const handleStartDateChange = useCallback((scheduleId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartingDate = dayjs.utc(e.target.value)
    setSchedules(prev =>
      prev.map(schedule => {
        if (schedule.id !== scheduleId) return schedule

        const existingStartingDate = dayjs(schedule.startDate)
        const updatedDate = newStartingDate
          .set('hour', existingStartingDate.hour())
          .set('minute', existingStartingDate.minute())
          .set('second', existingStartingDate.second())
          .set('millisecond', existingStartingDate.millisecond())
          .toISOString()

        if (schedule.endDate && dayjs(schedule.endDate).isBefore(newStartingDate)) {
          const newEndDate = newStartingDate.add(1, 'day').toISOString()
          return { ...schedule, startDate: updatedDate, endDate: newEndDate }
        }

        return { ...schedule, startDate: updatedDate }
      })
    )
  }, [])

  const handleEndDateChange = useCallback((scheduleId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = dayjs.utc(e.target.value).format('YYYY-MM-DD')
    setSchedules(prev =>
      prev.map(schedule => (schedule.id === scheduleId ? { ...schedule, endDate: newDate } : schedule))
    )
  }, [])

  const toggleEndDate = useCallback((scheduleId: string) => {
    setSchedules(prev =>
      prev.map(schedule => {
        if (schedule.id !== scheduleId) return schedule

        if (schedule.endDate) {
          return { ...schedule, endDate: null }
        } else {
          const newEndDate = getEndingDate(schedule)
          return { ...schedule, endDate: newEndDate }
        }
      })
    )
  }, [])

  const getScheduleProps = useCallback(() => {
    return schedules.map(schedule => {
      const { startDate, endDate } = schedule
      const scheduledHour = `${getInitialTime(schedule).value} ${getInitialPeriod(schedule)}`
      const hour24hformat = dayjs(scheduledHour, 'h:mm a')

      const scheduledStartingDate = dayjs(startDate)
        .set('hour', hour24hformat.hour())
        .set('minute', hour24hformat.minute())
        .set('second', 0)
        .set('millisecond', 0)
        .utc()
        .toISOString()

      const scheduledEndDate =
        endDate && endDate !== 'Invalid Date'
          ? dayjs(endDate)
              .set('hour', hour24hformat.hour())
              .set('minute', hour24hformat.minute())
              .set('second', 0)
              .set('millisecond', 0)
              .utc()
              .toISOString()
          : null

      return {
        ...schedule,
        startDate: scheduledStartingDate,
        endDate: scheduledEndDate,
      }
    })
  }, [schedules])

  return {
    schedules,
    formattedSchedules,
    expandedScheduleId,
    freqOptions,
    timeOptions,
    addSchedule,
    removeSchedule,
    updateSchedule,
    handleTimeChange,
    handlePeriodChange,
    handleFrequencyChange,
    handleScheduleChange,
    handleStartDateChange,
    handleEndDateChange,
    toggleEndDate,
    getScheduleProps,
    setExpandedScheduleId,
  }
}
