import { FC } from 'react'

export const UpdateRunsPlaceholder: FC = () => {
  return (
    <svg width="136" height="100" viewBox="0 0 136 100" fill="none">
      <g opacity="0.6">
        <g clipPath="url(#clip0_16380_61717)">
          <rect x="0.595703" width="135" height="100" fill="#DFEAF4" />
          <path
            d="M50.9149 46.8154C52.5718 46.8154 53.9152 48.1586 53.9152 49.8154V49.8154C53.9152 51.4723 52.57 52.8154 50.9131 52.8154C45.6953 52.8154 43.0727 52.8154 33.1141 52.8154C22.4965 52.8154 23.4756 52.8154 14.5981 52.8154C12.9413 52.8154 11.5957 51.4723 11.5957 49.8154V49.8154C11.5957 48.1586 12.9393 46.8154 14.5962 46.8154C20.9439 46.8154 22.8782 46.8154 33.1141 46.8154C43.3333 46.8154 44.73 46.8154 50.9149 46.8154Z"
            fill="#7DB0DE"
          />
          <path
            d="M50.9149 32C52.5718 32 53.9152 33.3431 53.9152 35V35C53.9152 36.6569 52.57 38 50.9131 38C45.6953 38 43.0727 38 33.1141 38C22.4965 38 23.4756 38 14.5981 38C12.9413 38 11.5957 36.6569 11.5957 35V35C11.5957 33.3431 12.9393 32 14.5962 32C20.9439 32 22.8782 32 33.1141 32C43.3333 32 44.73 32 50.9149 32Z"
            fill="#7DB0DE"
          />
          <path
            d="M50.9149 17C52.5718 17 53.9152 18.3431 53.9152 20V20C53.9152 21.6569 52.57 23 50.9131 23C45.6953 23 43.0727 23 33.1141 23C22.4965 23 23.4756 23 14.5981 23C12.9413 23 11.5957 21.6569 11.5957 20V20C11.5957 18.3431 12.9393 17 14.5962 17C20.9439 17 22.8782 17 33.1141 17C43.3333 17 44.73 17 50.9149 17Z"
            fill="#7DB0DE"
          />
          <path
            d="M50.9149 61.8154C52.5718 61.8154 53.9152 63.1586 53.9152 64.8154V64.8154C53.9152 66.4723 52.57 67.8154 50.9131 67.8154C45.6953 67.8154 43.0727 67.8154 33.1141 67.8154C22.4965 67.8154 23.4756 67.8154 14.5981 67.8154C12.9413 67.8154 11.5957 66.4723 11.5957 64.8154V64.8154C11.5957 63.1586 12.9393 61.8154 14.5962 61.8154C20.9439 61.8154 22.8782 61.8154 33.1141 61.8154C43.3333 61.8154 44.73 61.8154 50.9149 61.8154Z"
            fill="#7DB0DE"
          />
          <path
            d="M50.9149 76.8154C52.5718 76.8154 53.9152 78.1586 53.9152 79.8154V79.8154C53.9152 81.4723 52.57 82.8154 50.9131 82.8154C45.6953 82.8154 43.0727 82.8154 33.1141 82.8154C22.4965 82.8154 23.4756 82.8154 14.5981 82.8154C12.9413 82.8154 11.5957 81.4723 11.5957 79.8154V79.8154C11.5957 78.1586 12.9393 76.8154 14.5962 76.8154C20.9439 76.8154 22.8782 76.8154 33.1141 76.8154C43.3333 76.8154 44.73 76.8154 50.9149 76.8154Z"
            fill="#7DB0DE"
          />
          <path
            d="M77.4291 46.8154C79.086 46.8154 80.4301 48.1586 80.4301 49.8154V49.8154C80.4301 51.4723 79.0868 52.8154 77.43 52.8154C75.7214 52.8154 74.1241 52.8154 70.3882 52.8154C65.9811 52.8154 65.7126 52.8154 63 52.8154C61.3432 52.8154 60 51.4723 60 49.8154V49.8154C60 48.1586 61.3439 46.8154 63.0007 46.8154C65.0098 46.8154 66.3817 46.8154 70.3882 46.8154C74.3449 46.8154 75.562 46.8154 77.4291 46.8154Z"
            fill="#7DB0DE"
          />
          <path
            d="M77.4291 32C79.086 32 80.4301 33.3431 80.4301 35V35C80.4301 36.6569 79.0868 38 77.43 38C75.7214 38 74.1241 38 70.3882 38C65.9811 38 65.7126 38 63 38C61.3432 38 60 36.6569 60 35V35C60 33.3431 61.3439 32 63.0007 32C65.0098 32 66.3817 32 70.3882 32C74.3449 32 75.562 32 77.4291 32Z"
            fill="#7DB0DE"
          />
          <path
            d="M77.4291 17C79.086 17 80.4301 18.3431 80.4301 20V20C80.4301 21.6569 79.0868 23 77.43 23C75.7214 23 74.1241 23 70.3882 23C65.9811 23 65.7126 23 63 23C61.3432 23 60 21.6569 60 20V20C60 18.3431 61.3439 17 63.0007 17C65.0098 17 66.3817 17 70.3882 17C74.3449 17 75.562 17 77.4291 17Z"
            fill="#7DB0DE"
          />
          <path
            d="M77.4291 61.8154C79.086 61.8154 80.4301 63.1586 80.4301 64.8154V64.8154C80.4301 66.4723 79.0868 67.8154 77.43 67.8154C75.7214 67.8154 74.1241 67.8154 70.3882 67.8154C65.9811 67.8154 65.7126 67.8154 63 67.8154C61.3432 67.8154 60 66.4723 60 64.8154V64.8154C60 63.1586 61.3439 61.8154 63.0007 61.8154C65.0098 61.8154 66.3817 61.8154 70.3882 61.8154C74.3449 61.8154 75.562 61.8154 77.4291 61.8154Z"
            fill="#7DB0DE"
          />
          <path
            d="M77.4291 76.8154C79.086 76.8154 80.4301 78.1586 80.4301 79.8154V79.8154C80.4301 81.4723 79.0868 82.8154 77.43 82.8154C75.7214 82.8154 74.1241 82.8154 70.3882 82.8154C65.9811 82.8154 65.7126 82.8154 63 82.8154C61.3432 82.8154 60 81.4723 60 79.8154V79.8154C60 78.1586 61.3439 76.8154 63.0007 76.8154C65.0098 76.8154 66.3817 76.8154 70.3882 76.8154C74.3449 76.8154 75.562 76.8154 77.4291 76.8154Z"
            fill="#7DB0DE"
          />
        </g>
        <path
          d="M128.399 53L124.4 49L120.399 53M124.999 50C124.999 59.9411 116.94 68 106.999 68C97.0579 68 88.999 59.9411 88.999 50C88.999 40.0589 97.0579 32 106.999 32C113.603 32 119.376 35.5563 122.508 40.8582M106.999 40V50L112.999 54"
          stroke="#7DB0DE"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16380_61717">
          <rect x="0.142578" width="135.714" height="100" rx="5.71429" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
