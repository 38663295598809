import { useEffect } from 'react'

import { MetabaseDashboardDetails } from '@features/embedding/metabase/types'

import { Spinner } from '@shared/components'

import { DashboardElementOverlay } from './dashboard-element-overlay'
import { DashboardElementOverlayWrapper, MetabaseElementOverlay } from './dashboard-element-overlay-wrapper'
import { DashboardFullOverlay } from './dashboard-full-overlay'

import styles from './metabase-embed.module.css'

interface IMetabaseEmbed {
  dashboardDetails?: MetabaseDashboardDetails
  dashboardId: string
  elementsOverlays: MetabaseElementOverlay[]
  elementsSelected: any[]
  fullDashboard?: boolean
  hasFilters?: boolean
  hasTabs?: boolean
  hideOverlay: boolean
  iframeHeight: number
  iframeUrl?: string
  iframeWidth: number
  loading?: boolean
  numCols: number
  numRows: number
  handleIframeLocationChange: (msg: MessageEvent<any>) => void
  fullDashboardClick?: () => void
  handleSelectElement: (element: any) => void
  handleUnselectElement: (element: Pick<any, 'id'>) => void
}

export const RANDOM_FOUR = 4
export const FILTER_EMPTY_PADDING = 32
export const BORDER_COUNT = 2
export const BORDER_WIDTH = 2

export const MetabaseEmbed = ({
  dashboardId,
  elementsOverlays,
  elementsSelected,
  fullDashboard = false,
  hasFilters,
  hasTabs,
  hideOverlay = false,
  iframeHeight,
  iframeUrl,
  iframeWidth,
  loading = false,
  numCols,
  numRows,
  handleIframeLocationChange,
  handleSelectElement,
  handleUnselectElement,
  fullDashboardClick,
}: IMetabaseEmbed) => {
  useEffect(() => {
    window.addEventListener('message', handleIframeLocationChange)

    return () => {
      window.removeEventListener('message', handleIframeLocationChange)
    }
  }, [handleIframeLocationChange])
  if (!iframeUrl) return null

  return (
    <div key={dashboardId} className={styles.root}>
      {loading && (
        <div className={styles.spinnerContainer}>
          <Spinner center={false} />
        </div>
      )}

      <div className={styles.dashboardWrapper} style={{ width: iframeWidth }}>
        <iframe
          title="Metabase Dashboard"
          src={iframeUrl}
          style={{ border: '0' }}
          width={iframeWidth}
          height={iframeHeight}
        />
        {!hideOverlay && fullDashboard && (
          <DashboardFullOverlay width={iframeWidth} height={iframeHeight} onClick={fullDashboardClick} />
        )}
        {!hideOverlay && !fullDashboard && (
          <DashboardElementOverlayWrapper
            numRows={numRows}
            numCols={numCols}
            iframeWidth={iframeWidth}
            iframeHeight={iframeHeight}
            elements={elementsOverlays}
            hasFilters={hasFilters}
            hasTabs={hasTabs}
            render={element => (
              <DashboardElementOverlay
                key={element.id}
                {...element}
                isSelected={!!elementsSelected.find(e => e.id === element.id)}
                handleSelectElement={handleSelectElement}
                handleUnselectElement={handleUnselectElement}
              />
            )}
          />
        )}
      </div>
    </div>
  )
}
