import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

// ================================ Jaccard Index ================================
// https://en.wikipedia.org/wiki/Jaccard_index
// https://stackoverflow.com/questions/2265503/string-similarity-algorithm-in-javascript
const JaccardMinLength = 3

const jaccardIndex = (s1: string, s2: string, minLength = JaccardMinLength): number => {
  function createSubstrings(s: string, minLength: number): Set<string> {
    const substrings = new Set<string>()
    for (let i = 0; i < s.length - minLength + 1; i++) {
      for (let j = i + minLength; j <= s.length; j++) {
        substrings.add(s.substring(i, j))
      }
    }
    return substrings
  }

  const set1 = createSubstrings(s1, minLength)
  const set2 = createSubstrings(s2, minLength)
  const intersection = new Set([...set1].filter(x => set2.has(x)))
  const union = new Set([...set1, ...set2])
  return intersection.size / union.size
}

export const findClosestString = (input: string, strings: string[]): string => {
  let closestString = strings[0]
  let maxSimilarity = -1

  for (const str of strings) {
    const similarity = jaccardIndex(input.toLowerCase(), str.toLowerCase())
    if (similarity > maxSimilarity) {
      maxSimilarity = similarity
      closestString = str
    }
  }

  return closestString
}

export const removeHttp = (url: string) => {
  const pattern = /^(http:\/\/|https:\/\/)/
  return url.replace(pattern, '')
}

export function toTitleCase(text: string) {
  return startCase(toLower(text))
}

export const filterNotNullOrUndefined = <T>(item: T | null | undefined): item is T =>
  item !== undefined && item !== null
