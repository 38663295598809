import { useEffect } from 'react'

import { User } from '@features/users'

import { config, isProd } from '@shared/config'

import { HubSpotTrackingConsent } from './types'

declare global {
  interface Window {
    _hsq: any[]
    _hsp: any[]
  }
}

export const useHubSpotTracking = () => {
  useEffect(() => {
    if (!isProd) {
      return
    }

    window._hsq = window._hsq || []
    window._hsp = window._hsp || []

    const script = document.createElement('script')
    script.src = `//js.hs-scripts.com/${config.hubspot.id}.js`
    script.type = 'text/javascript'
    script.id = 'hs-script-loader'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    script.onload = () => {
      console.log('HubSpot tracking script loaded.')
      setupCrossDomainTracking()
    }

    script.onerror = () => {
      console.error('Error loading HubSpot tracking script.')
    }

    return () => {
      script.remove()
    }
  }, [])

  const setupCrossDomainTracking = () => {
    if (!window._hsq) {
      return
    }

    window._hsq.push([
      'addIdentityListener',
      function (hstc: string, hssc: string, hsfp: string) {
        let crossDomainTrackingParams = `&__hstc=${hstc}&__hssc=${hssc}&__hsfp=${hsfp}`
        console.log('Cross-domain tracking parameters:', crossDomainTrackingParams)
      },
    ])
  }

  useEffect(() => {
    if (!window._hsp) {
      console.warn('HubSpot privacy script not loaded.')
      return
    }
    const setupCookieConsentListeners = () => {
      window._hsp.push([
        'addPrivacyConsentListener',
        function (consent: HubSpotTrackingConsent) {
          if (!consent.allowed) {
            doNotTrack(true)
          }
        },
      ])
    }
    setupCookieConsentListeners()
  }, [])

  const revokeCookieConsent = () => window._hsp.push(['revokeCookieConsent'])

  const doNotTrack = (track = false) => window._hsq.push(['doNotTrack', { track }])

  const showBanner = () => window._hsp.push(['showBanner'])

  const identifyUser = (user: User) => {
    if (!window._hsq) {
      console.warn('HubSpot tracking queue not initialized.')
      return
    }

    window._hsq.push([
      'identify',
      {
        email: user.email,
        id: user.id,
      },
    ])
  }

  return { revokeCookieConsent, doNotTrack, showBanner, identifyUser }
}
