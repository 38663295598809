import { destinationModalsAssetsUrlsBase } from '@assets'

import { useShowTutorialModals } from '@features/users'

import { TutorialModal } from '@shared/components/tutorial-modal/tutorial-modal'

interface VisualizationConceptModalProps {
  visible: boolean
  setModalOpen: (param: boolean) => void
}

export const VisualizationConceptModal = ({ visible, setModalOpen }: VisualizationConceptModalProps) => {
  const { mutateAsync: updateShowTutorialModals, isLoading } = useShowTutorialModals()

  const handleDismiss = async () => {
    if (isLoading) {
      return
    }
    await updateShowTutorialModals({
      data: {
        showVisualizationConceptModal: false,
      },
    })
    setModalOpen(false)
  }

  return (
    <TutorialModal
      visible={visible}
      onDismiss={handleDismiss}
      contents={[
        {
          imageUrl: `${destinationModalsAssetsUrlsBase}/visualization-concept-explainer-1.png`,
          title: 'Visualizations',
          description:
            'A visualization is a chart, a metric or any piece of data you want to send to your destination.',
        },
      ]}
    />
  )
}
