import { LogLevel } from '@azure/msal-browser'
import { useEffect } from 'react'

import { config } from '@shared/config'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${config.microsoft.tenantId}`,
    clientId: config.microsoft.clientId,
    redirectUri: config.microsoft.redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: any, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          default:
            return
        }
      },
    },
  },
}

type UseMicrosoftLoginProps = {
  onSuccess: (response: any) => Promise<void>
  onError: (error: unknown) => void
}

export interface MicrosoftLoginResponse {
  code?: string | null
  state?: string | null
  error?: string | null
  errorDescription?: string | null
}

const scopes = ['https://graph.microsoft.com/.default']

export const useMicrosoftLogin = ({ onSuccess, onError }: UseMicrosoftLoginProps) => {
  const login = async () => {
    const url = new URL('https://login.microsoftonline.com/common/oauth2/v2.0/authorize')
    url.searchParams.append('client_id', config.microsoft.clientId)
    url.searchParams.append('response_type', 'code')
    url.searchParams.append('redirect_uri', config.microsoft.redirectUri)
    url.searchParams.append('response_mode', 'query')
    url.searchParams.append('scope', scopes.join(' '))
    url.searchParams.append('prompt', 'select_account')

    window.open(url.toString(), '_blank', 'width=500,height=600')
  }

  useEffect(() => {
    let handlingPostMessage = false
    const handleResponse = async (event: MessageEvent<MicrosoftLoginResponse>) => {
      if (handlingPostMessage) {
        return
      }
      handlingPostMessage = true
      try {
        if (event.origin !== window.location.origin) {
          return
        }
        if (event.data.error) {
          return onError(event.data.errorDescription)
        }

        if (event.data.code) {
          return onSuccess(event.data.code)
        }

        console.warn('Unknown response', event.data)
        return onError('Unknown error')
      } finally {
        handlingPostMessage = false
      }
    }
    window.addEventListener('message', handleResponse, false)
    return () => {
      window.removeEventListener('message', handleResponse)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return login
}
