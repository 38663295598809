import { FC, PropsWithChildren, createContext } from 'react'

import { EDestinationType } from '@features/destinations/types'
import { useGetEmailTemplatesByType } from '@features/emails/api/api'
import { EEmailTemplateType, EmailTemplate } from '@features/emails/types'

import { useBoolean } from '@shared/hooks'

interface IEmailTemplateSelectContext {
  targetId: string
  onlyTemplatesForTarget?: boolean
  destinationType: EDestinationType
  templateType: EEmailTemplateType
  initialTemplateId?: string | null
  template?: EmailTemplate
  areTemplatesLoading: boolean
  emailTemplates: EmailTemplate[]
  openSaveChangesModal: boolean
  toggleOpenSaveChangesModal: (value?: boolean) => void
}

export const EmailTemplateSelectContext = createContext<IEmailTemplateSelectContext>({
  targetId: '',
  initialTemplateId: null,
  template: null as unknown as EmailTemplate,
  destinationType: EDestinationType.Email,
  templateType: null as unknown as EEmailTemplateType,
  onlyTemplatesForTarget: false,
  areTemplatesLoading: false,
  emailTemplates: [],
  openSaveChangesModal: false,
  toggleOpenSaveChangesModal: () => {},
})

export const EmailTemplateSelectProvider: FC<
  PropsWithChildren<
    Omit<
      IEmailTemplateSelectContext,
      | 'template'
      | 'setTemplate'
      | 'areTemplatesLoading'
      | 'emailTemplates'
      | 'openSaveChangesModal'
      | 'toggleOpenSaveChangesModal'
    >
  >
> = props => {
  const [openSaveChangesModal, toggleOpenSaveChangesModal] = useBoolean(false)

  const { data: emailTemplates = [], isLoading: areTemplatesLoading } = useGetEmailTemplatesByType({
    templateType: props.templateType,
    onlyTemplatesForTarget: props.onlyTemplatesForTarget,
    targetId: props.targetId,
  })

  const template = emailTemplates.find(template => template.id === props.initialTemplateId)

  return (
    <EmailTemplateSelectContext.Provider
      value={{
        ...props,
        template,
        areTemplatesLoading,
        emailTemplates,
        openSaveChangesModal,
        toggleOpenSaveChangesModal,
      }}
    >
      {props.children}
    </EmailTemplateSelectContext.Provider>
  )
}
