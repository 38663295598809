import { RefreshSingleArrowIcon } from '@assets'

import { Tooltip } from '@shared/components'
import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'

interface RecreateItemProps extends MenuItemProps {
  cloudTitle: string
}

export const RecreateItem = ({ cloudTitle, visible, ...menuItemProps }: RecreateItemProps) => {
  if (!visible) {
    return null
  }

  return (
    <Tooltip
      title={`If you wish to see the changes made in ${cloudTitle} reflected in the variants, you can recreate them. Be aware that specific changes made to variants will be lost.`}
      width={300}
      position="left"
    >
      <MenuItem {...menuItemProps}>
        <RefreshSingleArrowIcon />
        {'Recreate'}
      </MenuItem>
    </Tooltip>
  )
}
