import { FC, PropsWithChildren } from 'react'

import styles from './new-tab-link.module.css'

interface NewTabLinkProps extends React.HTMLAttributes<HTMLAnchorElement>, PropsWithChildren {
  link?: string
}

export const NewTabLink: FC<NewTabLinkProps> = ({ children, link, ...props }) => {
  return (
    <a className={styles.root} href={link} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  )
}
