import { IIcon } from '@assets/types'

import { EDataSourceType } from '@features/data-sources/types/types'

import { GSheetsLogo } from './gsheets-logo'
import { LookerIcon } from './looker-icon'
import { MetabaseIcon } from './metabase-logo'
import { TableauIcon } from './tableau-logo'

interface IDataSourceIcon extends IIcon {
  type: EDataSourceType
}
export const DataSourceIcon = ({ type, ...rest }: IDataSourceIcon) => {
  switch (type) {
    case EDataSourceType.Tableau:
      return <TableauIcon {...rest} />
    case EDataSourceType.Looker:
      return <LookerIcon {...rest} />
    case EDataSourceType.Metabase:
      return <MetabaseIcon {...rest} />
    case EDataSourceType.GoogleSheets:
      return <GSheetsLogo {...rest} />
    default:
      throw new Error(`Data source type ${type} not not supported`)
  }
}
