import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import {
  CalendarIcon,
  DatabaseIcon,
  DestinationIcon,
  InviteIcon,
  Logo,
  LogoFull,
  OpenBookIcon,
  Rocket,
  StarsIcon,
} from '@assets/icons'
import { ClockRewind } from '@assets/icons/clock-rewind'
import { CollectionIcon } from '@assets/icons/collection'
import { SettingsIcon } from '@assets/icons/settings'

import { useDataSourcesStore } from '@features/data-sources'
import { useDestinationsStore } from '@features/destinations'
import { FreeTrialBanner } from '@features/free-trial/components/free-trial-banner/free-trial-banner'
import { useOrganizationsStore } from '@features/organizations'
import { useRoot } from '@features/root'
import { IconLabel } from '@features/root/components/icon-label/icon-label'
import { isUserFinishedOnboard, useAuthStore } from '@features/users'
import { Account } from '@features/users/components/account/account'

import { Tooltip } from '@shared/components/tooltip/tooltip'
import { config } from '@shared/config'
import { BOOK_A_DEMO_URL, ROLLSTACK_DOCS_URL } from '@shared/constants/urls'
import { useScreenSize } from '@shared/hooks'

import styles from './root.module.css'

const SIDEBAR_COLLAPSE_LIMIT = 1400

export const Root: FC = () => {
  const {
    onboardingProps,
    inviteProps,
    settingsProps,
    showAppVersion,
    collectionsProps,
    historicalRunsProps,
    dataSourcesProps,
    presentationProps,
    handleInviteClick,
    handleSettingsClick,
    handleCollectionsClick,
    handleHistoricalRunsClick,
    handleDataSourcesClick,
    handlePresentationsClick,
  } = useRoot()

  const { user } = useAuthStore()
  const { activeOrganization } = useOrganizationsStore()
  const { dataSources } = useDataSourcesStore()
  const { destinations } = useDestinationsStore()
  const screenSize = useScreenSize()
  const collapsedSidebar = screenSize.width < SIDEBAR_COLLAPSE_LIMIT

  const [showOnboarding, setShowOnboarding] = useState(false)
  const [isBannerVisible, setIsBannerVisible] = useState(false)

  useEffect(() => {
    setShowOnboarding(!isUserFinishedOnboard(user, dataSources, destinations))
  }, [user, dataSources, destinations])

  const logoClass = classNames(styles.logoFull, styles.logoMargins, collapsedSidebar && styles.logo)

  return (
    <>
      <FreeTrialBanner organization={activeOrganization?.organization} setIsBannerVisible={setIsBannerVisible} />
      <div className={classNames(styles.container, isBannerVisible && styles.withBanner)}>
        <nav className={styles.navContainer} style={{ width: collapsedSidebar ? '60px' : '220px' }}>
          <Link to={Paths.BASE} className={logoClass}>
            {showAppVersion ? (
              <Tooltip title={config.appVersion} position="right">
                {collapsedSidebar ? <Logo /> : <LogoFull />}
              </Tooltip>
            ) : collapsedSidebar ? (
              <Logo />
            ) : (
              <LogoFull />
            )}
          </Link>
          <div className={classNames(styles.options, collapsedSidebar && styles.optionsCentered)}>
            <div className={styles.topLinks}>
              {showOnboarding ? (
                <Link to={Paths.BASE}>
                  <IconLabel {...onboardingProps} Icon={Rocket} hideLabel={collapsedSidebar} />
                </Link>
              ) : undefined}
              <Link to={Paths.DESTINATIONS} onClick={handlePresentationsClick}>
                <IconLabel {...presentationProps} Icon={DestinationIcon} hideLabel={collapsedSidebar} />
              </Link>
              <Link to={Paths.COLLECTIONS} onClick={handleCollectionsClick}>
                <IconLabel {...collectionsProps} Icon={CollectionIcon} hideLabel={collapsedSidebar} />
              </Link>
              <Link to={Paths.HISTORICAL_RUNS} onClick={handleHistoricalRunsClick}>
                <IconLabel {...historicalRunsProps} Icon={ClockRewind} hideLabel={collapsedSidebar} />
              </Link>
              <Link to={Paths.DATA_SOURCES} onClick={handleDataSourcesClick}>
                <IconLabel {...dataSourcesProps} Icon={DatabaseIcon} hideLabel={collapsedSidebar} />
              </Link>
              <Link to={Paths.SETTINGS_USAGE} onClick={handleSettingsClick}>
                <IconLabel {...settingsProps} Icon={SettingsIcon} hideLabel={collapsedSidebar} />
              </Link>
            </div>
            <div className={styles.bottomLinks}>
              <Link to={Paths.SETTINGS_ORGANIZATION} onClick={handleInviteClick}>
                <IconLabel {...inviteProps} Icon={InviteIcon} hideLabel={collapsedSidebar} />
              </Link>
              <a href={ROLLSTACK_DOCS_URL} target="_blank" rel="noreferrer">
                <IconLabel Icon={OpenBookIcon} label="Documentation" hideLabel={collapsedSidebar} />
              </a>
              <div className="beamerTrigger">
                <IconLabel Icon={StarsIcon} label="What's new" hideLabel={collapsedSidebar} />
              </div>
              <a href={BOOK_A_DEMO_URL} target="_blank" rel="noreferrer">
                <IconLabel Icon={CalendarIcon} label="Schedule a call" hideLabel={collapsedSidebar} />
              </a>
            </div>
          </div>
          <Account collapsedSidebar={collapsedSidebar} />
        </nav>
        <div className={styles.pageContent}>
          <Outlet />
        </div>
      </div>
    </>
  )
}
