import { FC } from 'react'

import { PlusIcon, TrashIcon } from '@assets'

import { PrimaryFilterValuesSelect } from '@features/collections/components/collection-setup/primary-filter-values-select/primary-filter-values-select'
import { SecondaryFilterValuesSelect } from '@features/collections/components/collection-setup/secondary-filter-values-select/secondary-filter-values-select'
import { FilterOption, VariantOption, VariantOptionRow } from '@features/collections/types'

import { Button } from '@shared/components'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shared/components/ui/table'

export type GetFilterValuesParams = {
  filterId?: string
  dashboardId?: string
  credentialsId?: string
  filteredByFilterId?: string
  filteredByFilterValue?: string
  query?: string
}

export interface VariantTableSelectProps {
  credentialId?: string
  primaryFilter: FilterOption
  secondaryFilter: FilterOption
  rows: VariantOptionRow[]
  addRowRef: React.RefObject<HTMLDivElement>
  onChangePrimaryValue: (option: VariantOption, i: number) => void
  onChangeSecondaryValue: (option: VariantOption[], i: number) => void
  onSelectAll: (options: VariantOption[], i: number) => void
  onAdd: () => void
  onDelete: (i: number) => void
}

export const VariantSelectTable: FC<VariantTableSelectProps> = ({
  credentialId,
  primaryFilter,
  secondaryFilter,
  rows,
  addRowRef,
  onChangePrimaryValue,
  onChangeSecondaryValue,
  onSelectAll,
  onAdd,
  onDelete,
}) => {
  return (
    <div className="border rounded-lg">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[250px]">{primaryFilter.name}</TableHead>
            <TableHead className="w-[400px]">{secondaryFilter.name}</TableHead>
            <TableHead className="w-[100px] text-right"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i} className="hover:none">
              <TableCell className="font-medium">
                <PrimaryFilterValuesSelect
                  credentialsId={credentialId}
                  primaryFilter={primaryFilter}
                  value={row.primaryValue}
                  onChange={option => onChangePrimaryValue(option as VariantOption, i)}
                />
              </TableCell>
              <TableCell>
                <SecondaryFilterValuesSelect
                  credentialsId={credentialId}
                  primaryFilter={primaryFilter}
                  secondaryFilter={secondaryFilter}
                  onSelectAll={onSelectAll}
                  onChangeSecondaryValue={onChangeSecondaryValue}
                  index={i}
                  row={row}
                />
              </TableCell>
              <TableCell>
                <div className="flex justify-center items-center">
                  <Button
                    iconLeading={<TrashIcon />}
                    linkGray
                    onClick={() => onDelete(i)}
                    disabled={rows.length === 1}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div ref={addRowRef} className="flex justify-center my-4">
        <Button iconLeading={<PlusIcon />} linkColor text="Add row" onClick={onAdd} />
      </div>
    </div>
  )
}
