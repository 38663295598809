import { TableauWorkbook } from '@features/data-sources'
import {
  AddLookerData,
  AddMetabaseData,
  AddTableauData,
  LookerData,
  MetabaseData,
  TableauData,
} from '@features/data-sources/types'
import { formatVizName } from '@features/destinations'
import { LookerDashboardElement } from '@features/embedding'
import { ILookerDashboard } from '@features/embedding/looker/types'
import { IMetabaseDashboard, MetabaseDashboardElement } from '@features/embedding/metabase/types'
import { ITableauObject } from '@features/embedding/tableau/types'
import { IntegrationDashboardElement } from '@features/embedding/types'

import { buildJsonFilters } from './hooks'
import { ISync, IntegrationDashboard, SyncCoreDataSource } from './types'

export const isTableauDashboard = (dash: IntegrationDashboard): dash is TableauWorkbook =>
  (dash as TableauWorkbook).name !== undefined

const isMetabaseDashboard = (dash: IntegrationDashboard): dash is IMetabaseDashboard =>
  (dash as IMetabaseDashboard).enableEmbedding !== undefined

export const isTableauDashboardElement = (el: IntegrationDashboardElement): el is ITableauObject =>
  (el as ITableauObject).name !== undefined

export const isLookerDashboardElement = (el: IntegrationDashboardElement): el is LookerDashboardElement =>
  !isTableauDashboardElement(el)

export const isMetabaseDashboardElement = (el: IntegrationDashboardElement): el is MetabaseDashboardElement =>
  !isTableauDashboardElement(el)

export const syncToAddLookerData = (sync: ISync & { lookerData: LookerData }): AddLookerData => ({
  lookerCredentialsId: sync.lookerData.lookerCredentialsId,
  dashboardId: sync.lookerData.dashboardId,
  dashboardName: sync.lookerData.dashboardName,
  dashboardUrl: sync.lookerData.dashboardUrl,
  elementId: sync.lookerData.elementId,
  elementIndex: sync.lookerData.elementIndex,
  elementName: sync.lookerData.elementName,
  filters: JSON.stringify(buildJsonFilters(sync).jsonFilters),
  fullDashboard: sync.lookerData.fullDashboard,
  updatedAt: sync.lookerData.updatedAt,
})

export const syncToAddMetabaseData = (sync: ISync & { metabaseData: MetabaseData }): AddMetabaseData => ({
  metabaseCredentialsId: sync.metabaseData.metabaseCredentialsId,
  dashboardId: sync.metabaseData.dashboardId,
  dashboardUrl: sync.metabaseData.dashboardUrl,
  dashboardName: sync.metabaseData.dashboardName,
  filters: JSON.stringify(buildJsonFilters(sync).jsonFilters),
  fullDashboard: sync.metabaseData.fullDashboard,
})

export const syncToAddTableauData = (sync: ISync & { tableauData: TableauData }): AddTableauData => ({
  tableauCredentialsId: sync.tableauData.tableauCredentialsId,
  workbookContentUrl: sync.tableauData.workbookContentUrl,
  workbookName: sync.tableauData.workbookName,
  workbookUrl: sync.tableauData.workbookUrl,
  workbookId: sync.tableauData.workbookId,
  viewId: sync.tableauData.viewId,
  viewUrlName: sync.tableauData.viewUrlName,
  viewContentUrl: sync.tableauData.viewContentUrl,
  viewName: sync.tableauData.viewName,
  sheetName: sync.tableauData.sheetName,
  objectId: sync.tableauData.objectId,
  objectType: sync.tableauData.objectType,
  marks: JSON.stringify(sync.tableauData.jsonMarks),
  params: JSON.stringify(buildJsonFilters(sync).jsonParams),
  filters: JSON.stringify(buildJsonFilters(sync).jsonFilters),
  fullDashboard: sync.tableauData.fullDashboard,
  updatedAt: sync.tableauData.updatedAt,
})

export const isIntegrationDashboardDisabled = (dashboard: IntegrationDashboard) => {
  if (!dashboard) return false

  if (isMetabaseDashboard(dashboard)) {
    return !dashboard.enableEmbedding
  }

  return false
}

export const getIntegrationDashboardName = (dashboard: IntegrationDashboard) => {
  if (!dashboard) return ''

  if (isTableauDashboard(dashboard)) {
    return dashboard.name
  }

  return dashboard.title
}

export const getDashboardElementName = (el: IntegrationDashboardElement): string => {
  if (!el) return ''

  if (isTableauDashboardElement(el)) {
    return el.name
  }

  return formatVizName(el.title)
}

export const getSyncDashboardOrView = (sync: ISync) =>
  sync.lookerData
    ? {
        id: sync.lookerData.dashboardId,
        name: sync.lookerData.dashboardName,
      }
    : sync.metabaseData
      ? {
          id: sync.metabaseData.dashboardId,
          name: sync.metabaseData.dashboardName,
        }
      : sync.tableauData
        ? {
            id: sync.tableauData.viewId,
            name: sync.tableauData.viewName ?? '',
          }
        : null

export const getSyncDataSource = (sync?: ISync): SyncCoreDataSource | null => {
  if (!sync) return null

  if (sync.tableauData) {
    return sync.tableauData.tableauCredentials
  }

  if (sync.lookerData) {
    return sync.lookerData.lookerCredentials
  }

  if (sync.metabaseData) {
    return sync.metabaseData.metabaseCredentials
  }

  if (sync.spreadsheetsData) {
    return sync.spreadsheetsData.spreadsheet
  }

  return null
}

export const getSyncIntegrationDashboard = (sync: ISync): IntegrationDashboard => {
  if (sync.lookerData) {
    const dashboard: ILookerDashboard = {
      id: sync.lookerData.dashboardId,
      title: sync.lookerData.dashboardName,
      folder: {},
    }

    return dashboard
  }

  if (sync.metabaseData) {
    const dashboard: IMetabaseDashboard = {
      id: sync.metabaseData.dashboardId,
      title: sync.metabaseData.dashboardName,
      enableEmbedding: true,
      folder: {
        id: '',
        name: '',
      },
    }

    return dashboard
  }

  if (sync.tableauData) {
    const dashboard: TableauWorkbook = {
      id: sync.tableauData.workbookId,
      name: sync.tableauData.workbookName,
      isDisabled: false,
      contentUrl: sync.tableauData.workbookContentUrl,
      project: {
        id: '',
        name: '',
      },
    }

    return dashboard
  }

  return null
}

export const getDashboardElement = (sync: ISync): IntegrationDashboardElement | null =>
  sync.tableauData
    ? {
        id: sync.tableauData.objectId,
        name: sync.tableauData.sheetName || '',
        type: sync.tableauData.objectType,
        position: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
      }
    : sync.lookerData
      ? {
          id: sync.lookerData.elementId || '',
          index: sync.lookerData.elementIndex,
          title: sync.lookerData.elementName,
        }
      : sync.metabaseData
        ? {
            id: sync.metabaseData.elementId || '',
            index: sync.metabaseData.elementIndex,
            title: sync.metabaseData.elementName || '',
          }
        : null
