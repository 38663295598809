import { useEffect, useRef } from 'react'

import { useOrganizationsStore } from '@features/organizations'
import { useGetSetupLink } from '@features/users'

import styles from './single-sign-on.module.css'

export const SingleSignOn = () => {
  const { activeOrganization } = useOrganizationsStore()
  const organizationId = activeOrganization?.organizationId
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { data: setupLink } = useGetSetupLink(organizationId)

  useEffect(() => {
    if (setupLink) {
      const { url } = setupLink
      iframeRef.current?.setAttribute('src', url)
    }
  }, [setupLink])

  return (
    <div className={styles.container}>
      {setupLink && (
        <div className={styles.alternativeLink}>
          <p>If you're experiencing issues with setting up your Single Sign-On (SSO) connection below,</p>
          <p>
            <a href={setupLink.url} target="_blank" rel="noopener noreferrer">
              Click here
            </a>{' '}
            for an alternative method.
          </p>
        </div>
      )}
      <iframe ref={iframeRef} title="sso" width={1000} height={2000}></iframe>
    </div>
  )
}
