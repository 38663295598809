import React from 'react'

import { useCollectionArchiveSync, useCollectionSync } from '@features/collections/api/api'
import { ICollection } from '@features/collections/types'
import { UpdateMethod } from '@features/destinations/types'

export interface WithCollectionUpdateProps {
  updateAll: (collection: ICollection) => Promise<void>
}

export const withCollectionUpdate = <T,>(WrappedComponent: React.ComponentType<T & WithCollectionUpdateProps>) => {
  return (props: T) => {
    const collectionSyncMutation = useCollectionSync()
    const collectionArchiveSyncMutation = useCollectionArchiveSync()

    const updateAll = async (collection: ICollection) => {
      const templateDestination = collection.collectionVariantTemplate.destination
      const { updateMethod } = templateDestination
      await (updateMethod === UpdateMethod.Archive
        ? collectionArchiveSyncMutation.mutateAsync({ collection })
        : collectionSyncMutation.mutateAsync({ collection }))
    }

    return <WrappedComponent {...props} updateAll={updateAll} />
  }
}
