import classNames from 'classnames'
import { FC, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { TooltipContext } from './tooltip-provider'

import styles from './tooltip.module.css'

type Position = 'top' | 'right' | 'bottom' | 'left'

export interface ITooltip extends PropsWithChildren {
  title?: string | JSX.Element | JSX.Element[]
  width?: number
  position?: Position
  show?: boolean
  className?: string
  onClick?: (e: React.MouseEvent) => void
  secondary?: boolean
  fullWidth?: boolean
  showOnOverflow?: boolean
}

const tooltipRoot = document.body

const OFFSET = 16
const HALF = 0.5
const UID_LENGTH = 10

export const Tooltip: FC<ITooltip> = ({
  title,
  width,
  show = true,
  position = 'top',
  className,
  children,
  secondary = false,
  fullWidth,
  showOnOverflow,
  onClick,
}) => {
  const { activeTooltipId, handleMouseOut, handleMouseOver } = useContext(TooltipContext)
  const tooltipId = useRef<string>(Math.random().toString(UID_LENGTH)).current
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [isOverflow, setIsOverflow] = useState(false)
  const triggerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const isTooltipActive = activeTooltipId === tooltipId

  const boxClass = classNames(
    styles.box,
    styles[position],
    className,
    isTooltipActive && show && styles.visible,
    isTooltipActive && showOnOverflow && isOverflow && styles.visible,
    secondary && styles.boxSecondary
  )

  const tooltipClass = classNames(styles.tooltip, fullWidth && styles.fullWidth)

  const tooltipContent = (
    <div ref={tooltipRef} style={{ ...tooltipPosition, width }} className={boxClass}>
      {title}
    </div>
  )

  const getTooltipPosition = useCallback(() => {
    if (!triggerRef.current || !tooltipRef.current) return

    const rect = triggerRef.current.getBoundingClientRect()
    const rect2 = tooltipRef.current.getBoundingClientRect()

    const leftOffset = (rect.width - rect2.width) * HALF
    const topOffset = (rect.height - rect2.height) * HALF

    const positions = {
      top: { top: rect.top - rect2.height - OFFSET, left: rect.left + leftOffset },
      right: { top: rect.top + topOffset, left: rect.left + rect.width + OFFSET },
      left: { top: rect.top + topOffset, left: rect.left - rect2.width - OFFSET },
      bottom: { top: rect.top + rect.height + OFFSET, left: rect.left + leftOffset },
    }

    const computedPosition = positions[position]

    // Ensure the tooltip is inside the viewport
    if (computedPosition.left < 0) computedPosition.left = OFFSET
    if (computedPosition.top < 0) computedPosition.top = OFFSET
    if (computedPosition.left + rect2.width > window.innerWidth)
      computedPosition.left = window.innerWidth - rect2.width - OFFSET
    if (computedPosition.top + rect2.height > window.innerHeight)
      computedPosition.top = window.innerHeight - rect2.height - OFFSET

    return computedPosition
  }, [position])

  useEffect(() => {
    const position = getTooltipPosition()

    if (position) {
      setTooltipPosition(position)
    }
  }, [getTooltipPosition, isTooltipActive])

  useEffect(() => {
    const el = triggerRef.current
    if (!el) {
      return
    }
    const firstChildScrollWidth = el.firstElementChild?.scrollWidth
    const firstChildClientWidth = el.firstElementChild?.clientWidth

    if (!firstChildScrollWidth || !firstChildClientWidth) {
      return
    }

    setIsOverflow(firstChildScrollWidth > firstChildClientWidth)
  }, [children])

  return (
    <>
      {createPortal(tooltipContent, tooltipRoot)}
      <div
        ref={triggerRef}
        key={tooltipId}
        className={tooltipClass}
        onClick={onClick}
        onMouseOver={e => handleMouseOver(e, tooltipId)}
        onMouseOut={handleMouseOut}
      >
        {children}
      </div>
    </>
  )
}
