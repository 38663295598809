import { useContext } from 'react'

import { EmailIcon } from '@assets'

import { SaveTemplateButton } from '@features/emails/components/save-template.button'

import { ActionModal, Button } from '@shared/components'

import { EmailTemplateEditor, EmailTemplateEditorProps } from './email-template-editor'
import { useEditorContainerSettings } from './hooks/use-editor-container-settings'
import { EmailTemplateEditorContext } from './providers/email-template-editor-provider'

type Props = EmailTemplateEditorProps & {
  open: boolean
  closeModal: () => void
}

export const EmailTemplateModal = ({ open, closeModal, recipients, variables }: Props) => {
  const { destinationType, syncs, templateType, targetId } = useContext(EmailTemplateEditorContext)
  const { upsertTemplate } = useEditorContainerSettings({
    destinationType,
    templateType,
    onSuccess: () => closeModal(),
    targetId,
    syncs,
  })

  return (
    <ActionModal
      Icon={EmailIcon}
      open={open}
      className="max-w-[90vw] overflow-y-auto"
      title="Email template"
      onBackgroundClick={closeModal}
    >
      <div className="flex flex-col justify-between">
        <EmailTemplateEditor recipients={recipients} variables={variables} />
        <div className="w-full h-20 flex gap-2 items-center">
          <Button text="Cancel" secondaryColor fullWidth onClick={() => closeModal()} />
          <SaveTemplateButton upsertTemplate={upsertTemplate} fullWidth />
        </div>
      </div>
    </ActionModal>
  )
}
