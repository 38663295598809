import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAddDestination } from '@features/destinations/api/api'
import { EDestinationType } from '@features/destinations/types'

import { ActionModal, Button, Input } from '@shared/components'

interface Props {
  open: boolean
  onClose: () => void
}

export const AddEmailDestinationModal: FC<Props> = ({ open, onClose }) => {
  const [name, setName] = useState('')

  const { mutateAsync: addDestination, isLoading } = useAddDestination()

  const navigate = useNavigate()

  const onClickHandler = async () => {
    const destination = await addDestination({ name, type: EDestinationType.Email })
    navigate(`/destinations/${destination.data.id}`)
  }

  return (
    <ActionModal
      open={open}
      onBackgroundClick={() => {
        onClose()
      }}
      title="Add Destination"
    >
      <div className="flex flex-col gap-2">
        <Input label="Name" value={name} onChange={e => setName(e.target.value)} />
        <div className="w-full flex justify-end">
          <Button text="Continue" disabled={isLoading} onClick={onClickHandler} loading={isLoading} />
        </div>
      </div>
    </ActionModal>
  )
}
