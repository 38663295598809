import classNames from 'classnames'
import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { FilterIcon } from '@assets'

import {
  useGetHistoricalRunOverview,
  useGetHistoricalRunOverviewAggregate,
  useGetHistoricalRunOverviewList,
} from '@features/historical-run/api'
import { useGetOrganizationUsers, useOrganizationsStore } from '@features/organizations'
import { useAuthStore } from '@features/users'

import { Button, Spinner } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { FilterOrStorterDropdown } from '@shared/components/filter-or-sorter-dropdown/filter-or-sorter-dropdown'

import { usageTableColumns, usageTableData } from './usage-table'

export const Usage: FC = () => {
  const { user } = useAuthStore()
  const { activeOrganization, isMember, isAdmin, isOwner } = useOrganizationsStore()

  const { isLoading: historicalRunOverviewLoading, data: historicalRunOverview } = useGetHistoricalRunOverview()

  const { isLoading: usersInOrganizationLoading, data: users } = useGetOrganizationUsers(
    activeOrganization?.organizationId
  )

  const timeFilterOptions: { label: string; value: 'month' | null }[] = [
    {
      label: 'All time',
      value: null,
    },
    {
      label: 'This month',
      value: 'month',
    },
  ]
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(timeFilterOptions[0])

  const userFilterOptions = [
    {
      label: 'All users',
      value: null,
      disabled: false,
    },
    ...(users
      ? users.map(user => ({ label: user.name || user.email, value: user.userId, disabled: !user.userId }))
      : []),
  ]
  const [selectedUserFilter, setSelectedUserFilter] = useState(userFilterOptions[0])

  const [page, setPage] = useState(1)

  const { isLoading: historicalRunOverviewAggregateLoading, data: historicalRunOverviewAggregate } =
    useGetHistoricalRunOverviewAggregate(
      { time: selectedTimeFilter.value, userId: selectedUserFilter?.value },
      { isAdmin, isOwner }
    )
  const { isLoading: historicalRunOverviewListLoading, data: historicalRunOverviewList } =
    useGetHistoricalRunOverviewList(
      { time: selectedTimeFilter.value, userId: selectedUserFilter?.value, page },
      { isAdmin, isOwner }
    )

  const monthlyUsageText = useMemo(() => {
    if (!historicalRunOverview?.monthlyUsage) {
      return ''
    }

    if (!historicalRunOverview.monthlyUsage.limit || historicalRunOverview.monthlyUsage.limit < 0) {
      return `${historicalRunOverview.monthlyUsage.historicalRunSyncCount} visualizations`
    }

    return `${historicalRunOverview.monthlyUsage.historicalRunSyncCount} of ${historicalRunOverview.monthlyUsage.limit} visualizations`
  }, [historicalRunOverview?.monthlyUsage])

  useEffect(() => {
    if (user && isMember) {
      setSelectedUserFilter({
        label: user.name || user.email,
        value: user.id,
        disabled: false,
      })
    }
  }, [user, isMember])

  const getPercentage = (value: number, total: number): number => {
    const PERCENTAGE_MAX = 100
    const percentage = (value / total) * PERCENTAGE_MAX

    if (!total) {
      return PERCENTAGE_MAX
    }

    if (percentage > PERCENTAGE_MAX) {
      return PERCENTAGE_MAX
    }

    if (percentage < 0) {
      return 0
    }

    return Math.round(percentage)
  }

  useEffect(() => {
    setPage(1)
  }, [selectedTimeFilter, selectedUserFilter])

  if (usersInOrganizationLoading || historicalRunOverviewLoading || !historicalRunOverview) {
    return (
      <section className="min-h-[500px]">
        <Spinner localCenter />
      </section>
    )
  }

  return (
    <div className="w-full flex flex-col items-center">
      <section className="inline-flex flex-col items-start gap-6">
        <div className="flex justify-center items-center gap-6 flex-wrap">
          <div
            className={classNames(
              'relative min-w-[300px] w-[300px] h-[172px] p-6 shadow-sm rounded-lg border border-gray-200'
            )}
          >
            <h2 className="text-sm-medium text-gray-600 mb-2">Users</h2>
            <p className="display-md-semibold text-gray-900">{historicalRunOverview.userCount}</p>
            <div className="absolute w-full left-0 bottom-0 h-[52px] p-4 text-right border-t border-gray-200">
              <Link to="/settings/organization">
                <Button linkColor text="Manage users" />
              </Link>
            </div>
          </div>
          <div
            className={classNames(
              'relative min-w-[300px] w-[300px] h-[172px] p-6 shadow-sm rounded-lg border border-gray-200'
            )}
          >
            <h2 className="text-sm-medium text-gray-600 mb-2">All time updated destinations</h2>
            <p className="display-md-semibold text-gray-900">{historicalRunOverview.historicalRunCount}</p>
            <div className="absolute w-full left-0 bottom-0 h-[52px] p-4 text-right border-t border-gray-200">
              <Link to="/destinations">
                <Button linkColor text="Manage destinations" />
              </Link>
            </div>
          </div>
          <div
            className={classNames(
              'relative min-w-[300px] w-[300px] h-[172px] p-6 shadow-sm rounded-lg border border-gray-200'
            )}
          >
            <h2 className="text-sm-medium text-gray-600 mb-2">Lifetime updated visualizations</h2>
            <p className="display-md-semibold text-gray-900">{historicalRunOverview.historicalRunSyncCount}</p>
          </div>
        </div>
        <div
          className={classNames(
            'flex p-6 flex-col items-start gap-2 self-stretch shadow-sm rounded-lg border border-gray-200'
          )}
        >
          <h2 className="text-sm-medium text-gray-600 mb-2">This month’s updated visualizations</h2>
          <p className="text-sm-medium text-gray-900 mb-3">{monthlyUsageText}</p>
          <div className="w-full rounded bg-gray-200 h-2">
            <div
              className="rounded bg-primary-600 h-2"
              style={{
                width: `${getPercentage(
                  historicalRunOverview.monthlyUsage.historicalRunSyncCount,
                  historicalRunOverview.monthlyUsage.limit
                )}%`,
              }}
            />
          </div>
        </div>
      </section>
      <section className="w-full mt-16">
        <div className="flex items-center justify-start gap-5 flex-wrap">
          <FilterOrStorterDropdown
            icon={<FilterIcon />}
            label="Owner"
            options={userFilterOptions}
            selectedOption={selectedUserFilter}
            onSelect={option => setSelectedUserFilter(option)}
          />
          <FilterOrStorterDropdown
            icon={<FilterIcon />}
            label="Time"
            options={timeFilterOptions}
            selectedOption={selectedTimeFilter}
            onSelect={option => setSelectedTimeFilter(option)}
          />
        </div>
        <section className="w-full relative mt-5">
          {historicalRunOverviewAggregateLoading ? (
            <Spinner localCenter />
          ) : (
            <div className="flex items-center justify-start gap-5 flex-wrap">
              <div className="p-6">
                <h2 className="text-sm-medium text-gray-600">Updated visualizations</h2>
                <p className="display-xs-semibold mt-5 text-gray-900">
                  {historicalRunOverviewAggregate?.historicalRunSyncCount}
                </p>
              </div>
              <div className="p-6">
                <h2 className="text-sm-medium text-gray-600">Updated destinations</h2>
                <p className="display-xs-semibold mt-5 text-gray-900">
                  {historicalRunOverviewAggregate?.historicalRunCount}
                </p>
              </div>
            </div>
          )}
        </section>
      </section>
      {isAdmin || isOwner ? (
        <section className="w-full relative mt-5">
          {historicalRunOverviewListLoading ? (
            <Spinner localCenter />
          ) : (
            <DataGrid
              data={usageTableData(historicalRunOverviewList!.items)}
              columns={usageTableColumns()}
              EmptyState="No update triggered yet"
              enablePagination
              customPagination={{
                currentPage: page,
                totalPages: historicalRunOverviewList?.totalPages || 1,
                onPageChange: setPage,
              }}
            />
          )}
        </section>
      ) : null}
    </div>
  )
}
