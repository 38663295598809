import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 20

export const UpdateIcon: FC<IIcon> = ({ size = defaultSize, color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M17.0443 10.7449C16.8128 12.9198 15.5805 14.9579 13.5413 16.1352C10.1534 18.0912 5.82132 16.9304 3.86531 13.5425L3.65698 13.1817M2.95527 9.25651C3.18677 7.0816 4.4191 5.04349 6.45825 3.86618C9.84616 1.91018 14.1783 3.07096 16.1343 6.45886L16.3426 6.81971M2.91113 15.0557L3.52118 12.779L5.79788 13.3891M14.2022 6.61234L16.4789 7.22239L17.0889 4.94568"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
