import { useEffect, useRef, useState } from 'react'

export const useLoadExternalScript = (
  src: string,
  options: { removeOnUnmount?: boolean } & Record<string, any> = {}
) => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'ready' | 'error'>(() => {
    if (!src) {
      return 'idle'
    }

    return 'loading'
  })

  const cachedScriptStatuses: Record<string, any> = useRef({})

  useEffect(() => {
    if (!src) return

    const cachedScriptStatus = cachedScriptStatuses.current[src]
    if (cachedScriptStatus === 'ready' || cachedScriptStatus === 'error') {
      setStatus(cachedScriptStatus)
      return
    }

    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`)

    if (!script) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      document.body.appendChild(script)
    }

    const handleScriptStatus = (event: Event) => {
      const newStatus = event.type === 'load' ? 'ready' : 'error'
      setStatus(newStatus)
      cachedScriptStatuses.current[src] = newStatus
    }

    script.addEventListener('load', handleScriptStatus)
    script.addEventListener('error', handleScriptStatus)

    return () => {
      script?.removeEventListener('load', handleScriptStatus)
      script?.removeEventListener('error', handleScriptStatus)

      if (options.removeOnUnmount === true) {
        script?.remove()
      }
    }
  }, [src, options.removeOnUnmount])

  return status
}
