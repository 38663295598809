import { useMemo, useState } from 'react'

import { TableauVariantsContextData } from '@pages/collections/collection-setup/tableau-context'

import { FilterOption, ParsedVariant, VariantOption } from '@features/collections/types'
import { useGetLookerFilterValues, useGetMetabaseFilterValues } from '@features/data-sources'
import { FilterType } from '@features/filters/types'

import { useBoolean } from '@shared/hooks'

type GetAllFilterValuesParams = {
  filterId?: string
  dashboardId?: string
  credentialsId?: string
  type?: FilterType
}

const useGetAllFilterValues = (args: GetAllFilterValuesParams, tableauContextData: TableauVariantsContextData) => {
  const { data: allValuesForLooker, isLoading: isLoadingAllValuesForLooker } = useGetLookerFilterValues(args, {
    enabled: args.type === FilterType.LookerFilter,
  })

  const { data: allValuesForMetabase, isLoading: isLoadingAllForMetabase } = useGetMetabaseFilterValues(args, {
    enabled: args.type === FilterType.MetabaseFilter,
  })

  const { allValues, isLoading } = useMemo(() => {
    switch (args.type) {
      case FilterType.TableauFilter:
      case FilterType.TableauParams:
        return {
          allValues: tableauContextData.primaryFilterValues.map(variant => ({ primaryValue: variant })),
          isLoading: !tableauContextData.isLoadingFilterValuesCombinations,
        }
      case FilterType.LookerFilter:
        return {
          allValues: (allValuesForLooker ?? []).map(variant => ({ primaryValue: variant })),
          isLoading: isLoadingAllValuesForLooker,
        }
      case FilterType.MetabaseFilter:
        return {
          allValues: (allValuesForMetabase ?? []).map(variant => ({ primaryValue: variant })),
          isLoading: isLoadingAllForMetabase,
        }
      default:
        return { allValues: [], isLoading: false }
    }
  }, [
    args.type,
    tableauContextData.primaryFilterValues,
    tableauContextData.isLoadingFilterValuesCombinations,
    allValuesForLooker,
    isLoadingAllValuesForLooker,
    allValuesForMetabase,
    isLoadingAllForMetabase,
  ])

  return {
    allValues,
    isLoading,
  }
}

export const useOneVariantValueField = ({
  credentialsId,
  filter,
  tableauContextData,
}: {
  credentialsId?: string
  filter?: FilterOption | null
  tableauContextData: TableauVariantsContextData
}) => {
  const [selectedVariants, setSelectedVariants] = useState<VariantOption[]>([])
  const [selectedAllVariants, toggleSelectedAllVariants] = useBoolean()

  const { allValues, isLoading } = useGetAllFilterValues(
    {
      credentialsId,
      dashboardId: filter?.dashboardId,
      filterId: filter?.dashboardFilterId,
      type: filter?.type,
    },
    tableauContextData
  )

  const parsedVariants: ParsedVariant[] = selectedAllVariants
    ? allValues
    : selectedVariants.map(variant => ({ primaryValue: variant.value }))

  const handleChangeOptions = (options: VariantOption[]) => {
    setSelectedVariants(options)
  }

  const handleToggleSelectAll = () => {
    toggleSelectedAllVariants()
  }

  const reset = () => {
    setSelectedVariants([])
    toggleSelectedAllVariants(false)
  }

  return {
    parsedVariants,
    isLoading,
    selectedVariants,
    selectedAllVariants,
    handleChangeOptions,
    handleToggleSelectAll,
    reset,
  }
}
