import { FC } from 'react'

export const EditIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.39735 15.0973C2.43564 14.7527 2.45478 14.5804 2.50691 14.4194C2.55316 14.2765 2.61851 14.1405 2.70118 14.0152C2.79436 13.8739 2.91694 13.7513 3.16209 13.5061L14.1673 2.5009C15.0878 1.58043 16.5802 1.58043 17.5007 2.5009C18.4211 3.42138 18.4211 4.91376 17.5007 5.83424L6.49542 16.8395C6.25027 17.0846 6.1277 17.2072 5.98639 17.3004C5.86102 17.383 5.72506 17.4484 5.58219 17.4946C5.42115 17.5468 5.24887 17.5659 4.90429 17.6042L2.08398 17.9176L2.39735 15.0973Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
