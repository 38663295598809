import classNames from 'classnames'
import { FC } from 'react'

import { FancyTick } from '@assets/icons/ticks'

import styles from './dot.module.css'

interface DotProps {
  done?: boolean
  active?: boolean
  className?: string
}

export const Dot: FC<DotProps> = ({ active, done, className }) => {
  const outerClass = classNames(styles.outer, active && styles.active, done && styles.done, className)

  return <div className={outerClass}>{done ? <FancyTick /> : <div className={styles.inner} />}</div>
}
