import { useNavigate } from 'react-router-dom'

import { ArrowLeft } from '@assets'

import { Button } from '@shared/components'

import styles from './add-collection-not-allowed.module.css'

export const AddCollectionNotAllowed = ({ message }: { message: string }) => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <div className={styles.addCollectionNotAllowed}>
      {message}
      <Button className={styles.backButton} text="Back" onClick={goBack} tertiaryColor iconLeading={<ArrowLeft />} />
    </div>
  )
}
