import { FC, useMemo } from 'react'

import { getFiltersViewFromString } from '@features/filters/utils'
import { Section } from '@features/syncs/components/sync-settings/section/section'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { Button } from '@shared/components'

interface Props {
  index?: number
}

export const FiltersSelect: FC<Props> = ({ index }) => {
  const {
    dashboardSelected,
    isNewSync,
    filtersSelected,
    paramsSelected,
    removeFilters,
    handleRemoveFilter,
    updateVanityData,
  } = useSyncSettingsContext()

  const allFilters: Object = useMemo(
    () => ({
      ...JSON.parse(JSON.stringify(filtersSelected)),
      ...JSON.parse(JSON.stringify(paramsSelected)),
    }),
    [filtersSelected, paramsSelected]
  )

  const filtersList = useMemo(
    () => getFiltersViewFromString(allFilters, handleRemoveFilter),
    [allFilters, handleRemoveFilter]
  )

  const editFilters = () => updateVanityData({ filtersSelectModal: true, newSyncEmbedModal: true })

  return (
    <Section
      title={isNewSync ? `${index}) Filters` : 'Filters'}
      subtitle="Set filters then select a visualization"
      className="w-[500px]"
    >
      <div className="flex flex-row align-center gap-20 mb-4">
        <span className="text-xs-medium">Filters</span>
        <Button linkColor disabled={!dashboardSelected} text="Modify all" onClick={editFilters} />
        {!isNewSync && <Button linkError disabled={!dashboardSelected} text="Remove all" onClick={removeFilters} />}
      </div>
      <div className="flex flex-col max-w-full">
        {Boolean(filtersList.length) ? filtersList : <span className="text-sm-regular">No filters applied</span>}
      </div>
    </Section>
  )
}
