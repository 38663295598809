import { TableauProject, TableauWorkbook } from '@features/data-sources'

import { rootFolderId } from '@shared/components'

export const getFolderId = (project: TableauProject) => project.id.toString()
export const getParentFolderId = (project: TableauProject) => project.parentProjectId?.toString() || rootFolderId
export const getFolderName = (project: TableauProject) => project.name

export const getFileName = (dashboard: TableauWorkbook) => dashboard.name
export const getFileFolderId = (dashboard: TableauWorkbook) =>
  dashboard.project?.id ? dashboard.project.id.toString() : rootFolderId
