import { useState } from 'react'

import { ICollection } from '@features/collections/types'
import { Destination } from '@features/destinations/types'

import { BaseFilterOption } from '@shared/components/filter-or-sorter-dropdown/filter-or-sorter-dropdown'

const allOption = {
  value: null,
  label: 'All',
}

export const useVariantOwnerFilter = (collection: ICollection) => {
  const [variantOwnerOption, setVariantOwnerOption] = useState<BaseFilterOption>(allOption)

  const variantOwnerOptions: BaseFilterOption[] = [
    allOption,
    // owner
    { label: collection.user.name, value: collection.userId },
    // admin and contributors
    ...(collection.sharedWith ?? []).map(sharedWith => ({
      label: sharedWith.user.name,
      value: sharedWith.userId,
    })),
  ]

  const handleVariantOwnerChange = (option: BaseFilterOption) => {
    setVariantOwnerOption(option)
  }

  const filterOwner = (destination: Destination) =>
    variantOwnerOption.value ? destination.userId === variantOwnerOption.value : true

  return {
    filterOwner,
    handleVariantOwnerChange,
    variantOwnerOption,
    variantOwnerOptions,
  }
}
