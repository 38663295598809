import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildUserManagementRoute } from '@pages/routes/paths'

import { ArrowRight } from '@assets'

import {
  useAddParentTableauDataSource,
  useGetTableauApiToken,
  useUpdateTableauDataSource,
} from '@features/data-sources/api'
import {
  TABLEAU_API_DETAIL_ERROR_MESSAGES,
  TableauApiDetailErrorCodes,
  TableauApiErrorCodes,
} from '@features/data-sources/api/tableau/error-handler'
import { DataSourceTestStatus } from '@features/data-sources/components/connection-test/data-source-test-status'
import { ConnectionTestMessages, ConnectionTestStatus } from '@features/data-sources/components/connection-test/utils'
import { ITableauDataSource, TableauCredentials } from '@features/data-sources/types'
import { EDataSourceType } from '@features/data-sources/types/types'
import { getJWT, isPublicTableau } from '@features/embedding'

import { ActionModal, ActionModalProps, Button, Input, updateDataSourceToast } from '@shared/components'
import { useBoolean, useStateReducer } from '@shared/hooks'
import { formatApiErrorFromAxios } from '@shared/http'

interface TableauTestConnectionModalProps extends ActionModalProps {
  dataSource: TableauCredentials & Pick<ITableauDataSource, 'name' | 'autoProvision' > & Partial<Pick<ITableauDataSource, 'id'>>
  toggle: () => void
}

export const TestTableauConnectionModal: FC<TableauTestConnectionModalProps> = ({
  dataSource,
  toggle,
  ...modalProps
}) => {
  const { id, name, baseUrl, clientId, secretId, secretValue, siteName, autoProvision } = dataSource

  const navigate = useNavigate()
  const [username, setUsername] = useState(dataSource.username || '')
  const [testsStatus, updateTestsStatus] = useStateReducer<ConnectionTestStatus | null>(null)
  const [connectionTestsMessages, updateConnectionTestsMessages] = useStateReducer<Partial<ConnectionTestMessages>>({})
  const [isTesting, toggleTesting] = useBoolean()

  const { mutateAsync: getTableauApiToken } = useGetTableauApiToken()
  const { mutateAsync: addDataSource, isLoading: isAddLoading } = useAddParentTableauDataSource()
  const { mutateAsync: updateDataSource, isLoading: isUpdateLoading } = useUpdateTableauDataSource()

  const updateQueryLoading = isUpdateLoading || isAddLoading

  const handleSave = async () => {
    if (id) {
      await updateDataSource({
        id,
        baseUrl,
        clientId,
        secretId,
        username,
        siteName,
        name,
        secretValue,
        autoProvision,
        status: 'valid',
        statusMessage: '',
      })
      updateDataSourceToast(true)
    } else {
      const { data } = await addDataSource({
        baseUrl,
        clientId,
        secretId,
        username,
        siteName,
        name,
        secretValue,
        autoProvision,
      })
      updateDataSourceToast(false)
      navigate(buildUserManagementRoute(EDataSourceType.Tableau, data.id))
    }
    toggle()
  }

  const getToken = async () => {
    updateTestsStatus({ currentTest: 'API_TEST', stage: 'running' })
    const token = await getJWT({ ...dataSource, username })
    try {
      await getTableauApiToken({
        baseUrl,
        contentUrl: siteName,
        jwt: token,
        value: secretValue,
      })

      updateTestsStatus({ currentTest: 'API_TEST', stage: 'success' })
      return true
    } catch (err) {
      if (err instanceof AxiosError) {
        const apiError = formatApiErrorFromAxios(err)
        const tableauCode = apiError.metadata.code as TableauApiErrorCodes
        const tableauDetailedCode = apiError.metadata.detailedCode as TableauApiDetailErrorCodes

        if (tableauCode) {
          updateConnectionTestsMessages({
            API_TEST: { failed: TABLEAU_API_DETAIL_ERROR_MESSAGES[tableauDetailedCode] },
          })
        }
      }
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'failed' })
      return false
    }
  }

  const testConnection = async () => {
    if (isPublicTableau(baseUrl)) {
      updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'running' })
      return true
    } else {
      return getToken()
    }
  }

  const handleTestAndSave = async () => {
    toggleTesting(true)
    const isConnectionSuccessful = await testConnection()
    toggleTesting(false)

    if (isConnectionSuccessful) {
      handleSave()
    }
  }

  return (
    <ActionModal
      {...modalProps}
      onBackgroundClick={toggle}
      btns={
        <>
          <Button text="Cancel" secondaryGray fullWidth onClick={toggle} />
          <Button
            fullWidth
            text="Test data source"
            iconTrailing={<ArrowRight />}
            onClick={handleTestAndSave}
            disabled={!username}
            loading={isTesting || updateQueryLoading}
          />
        </>
      }
    >
      <Input
        autoFocus
        name="username"
        value={username}
        label="Tableau username"
        placeholder="Tableau username"
        onChange={e => setUsername(e.target.value)}
      />
      {testsStatus && (
        <DataSourceTestStatus
          stage={testsStatus.stage}
          currentTest={testsStatus.currentTest}
          connectionTestMessages={connectionTestsMessages}
        />
      )}
    </ActionModal>
  )
}
