import { useAuthStore, useRefreshGoogleAccessToken } from '@features/users'

import { config } from '@shared/config'

import { GoogleOAuthScopes, ViewIdOptions } from './types'
import { useDrivePicker } from './use-drive-picker'

interface IOpenGDrivePicker {
  customScopes: GoogleOAuthScopes[]
  viewId?: ViewIdOptions
  query?: string
  customViews: google.picker.ViewId[]
  includeFolders?: boolean
  selectFolderEnabled?: boolean
  multiselect?: boolean
}

export const useGDrivePicker = (cb: (docs: google.picker.DocumentObject[]) => void, cancelCb?: () => void) => {
  const { openPicker } = useDrivePicker()
  const refreshAccessTokenQuery = useRefreshGoogleAccessToken()

  const { clientId, apiKey } = config.google

  const { getState } = useAuthStore

  const openGDrivePicker = async ({
    customScopes,
    viewId,
    customViews,
    query,
    includeFolders,
    selectFolderEnabled,
    multiselect = false,
  }: IOpenGDrivePicker) => {
    const { user } = getState()
    if (!user || !user.googleOAuth) {
      throw new Error('User is not authenticated')
    }

    let { googleOAuth } = user
    let accessToken = googleOAuth.accessToken
    if (Date.parse(googleOAuth.expiredAt) < Date.now()) {
      const response = await refreshAccessTokenQuery.mutateAsync()
      accessToken = response.data.accessToken
    }

    openPicker({
      multiselect: multiselect,
      clientId,
      supportDrives: true,
      appId: clientId,
      developerKey: apiKey,
      disableDefaultView: !viewId,
      token: accessToken,
      customScopes,
      customViews,
      query,
      setIncludeFolders: includeFolders,
      setSelectFolderEnabled: selectFolderEnabled,
      viewId,
      callbackFunction: (data: google.picker.ResponseObject) => {
        if (data.action === google.picker.Action.CANCEL) {
          cancelCb && cancelCb()
          return
        }

        if (data.action === google.picker.Action.PICKED) {
          const { docs } = data
          cb(docs)
        }
      },
    })
  }

  return { openGDrivePicker }
}
