import { ICollection, SharingCollectionPermissions } from '@features/collections/types'
import { useAuthStore } from '@features/users'

export const useCollectionRights = (collection?: ICollection | null) => {
  const user = useAuthStore(state => state.user)

  const sharedWith = collection?.sharedWith || []

  const owner = user ? collection?.userId === user.id : false

  const contributor = user
    ? sharedWith.some(
        ({ userId: sharedUserId, permission }) =>
          sharedUserId === user.id && permission === SharingCollectionPermissions.contributor
      )
    : false

  const admin = user
    ? sharedWith.some(
        ({ userId: sharedUserId, permission }) =>
          sharedUserId === user.id && permission === SharingCollectionPermissions.admin
      )
    : false

  return {
    owner,
    contributor,
    admin,
  }
}
