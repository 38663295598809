import { ElementOptionItems } from '@looker/embed-sdk'
import classNames from 'classnames'

import { useHover } from '@shared/hooks/use-hover'

import overlayStyles from './dashboard-element-overlay.module.css'

interface IDashboardElementOverlay {
  id: string
  index: number
  element: ElementOptionItems
  columnStart: number
  columnEnd: number
  rowStart: number
  rowEnd: number
  isSelected: boolean
  handleSelectElement: ({ id, index, element }: { id: string; index: number; element: ElementOptionItems }) => void
  handleUnselectElement: (id: string) => void
}
export const DashboardElementOverlay = ({
  id,
  index,
  element,
  columnStart,
  columnEnd,
  rowStart,
  rowEnd,
  isSelected,
  handleSelectElement,
  handleUnselectElement,
}: IDashboardElementOverlay) => {
  const { hoverRef: outerDivHover, isHover: isOuterDivHover } = useHover()

  const overlayAreaClassName = classNames(
    overlayStyles.overlayArea,
    { [overlayStyles.overlayAreaHovered]: isOuterDivHover },
    { [overlayStyles.overlayAreaSelected]: isSelected }
  )
  const overlayBadgeClassName = classNames(
    overlayStyles.overlayBadge,
    { [overlayStyles.overlayBadgeHovered]: isOuterDivHover },
    { [overlayStyles.overlayBadgeSelected]: isSelected }
  )

  const handleClick = () => {
    isSelected ? handleUnselectElement(id) : handleSelectElement({ id, index, element })
  }

  return (
    <div
      ref={outerDivHover}
      className={overlayAreaClassName}
      style={{
        gridColumnStart: columnStart,
        gridColumnEnd: columnEnd,
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
      }}
      onClick={handleClick}
      data-testid={element.title}
    >
      <div className={overlayBadgeClassName}>
        <div className={overlayStyles.badgeContent}>Selected</div>
      </div>
    </div>
  )
}
