import { ErrorPage } from '@pages/error-page/error-page'
import { HistoricalRunDetails } from '@pages/historical-runs/historical-run-details/historical-run-details'
import { HistoricalRuns } from '@pages/historical-runs/historical-runs'

import { HistoricalRunDetailsCrumb, HistoricalRunsCrumb } from '@shared/components/breadcrumbs/breadcrumbs'

import { PathSegments } from './paths'

export const historicalRunRoutes = {
  path: PathSegments.HISTORICAL_RUNS,
  errorElement: <ErrorPage />,
  handle: { crumb: HistoricalRunsCrumb },
  children: [
    {
      index: true,
      element: <HistoricalRuns />,
    },
    {
      path: PathSegments.HISTORICAL_RUN_ID,
      element: <HistoricalRunDetails />,
      handle: { crumb: HistoricalRunDetailsCrumb },
    },
  ],
}
