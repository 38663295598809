import { FC } from 'react'

import { IIcon } from '@assets/types'

export const MsPowerPointLogo: FC<IIcon> = () => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none">
      <circle cx="29.2188" cy="27.5" r="24.0625" fill="url(#paint0_linear_11956_274582)" />
      <mask id="mask0_11956_274582" maskUnits="userSpaceOnUse" x="5" y="3" width="49" height="49">
        <circle cx="29.2188" cy="27.5" r="24.0625" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_11956_274582)">
        <rect x="30.9375" width="29.2188" height="29.2188" fill="url(#paint1_linear_11956_274582)" />
        <path
          d="M10.3125 20.625C10.3125 17.7773 12.621 15.4688 15.4688 15.4688H29.2187C32.0665 15.4688 34.375 17.7773 34.375 20.625V41.25C34.375 44.0977 32.0665 46.4062 29.2188 46.4062H10.3125V20.625Z"
          fill="black"
          fillOpacity="0.3"
        />
        <rect x="1.71875" width="29.2188" height="29.2188" fill="#EB6C4D" />
      </g>
      <rect y="12.0312" width="30.9375" height="30.9375" rx="3.4375" fill="url(#paint2_linear_11956_274582)" />
      <path
        d="M22.3437 24.8482C22.3437 21.1406 19.903 18.9062 16.0323 18.9062H10.3125V36.0938H13.8627V30.8638H15.8843C19.5085 30.8638 22.3437 28.8013 22.3437 24.8482ZM18.7442 24.9219C18.7442 26.5915 17.6841 27.6719 15.9583 27.6719H13.8627V22.1228H15.9337C17.6595 22.1228 18.7442 23.0313 18.7442 24.9219Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11956_274582"
          x1="5.15625"
          y1="30.819"
          x2="53.2813"
          y2="30.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A73A24" />
          <stop offset="1" stopColor="#F75936" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11956_274582"
          x1="54.1406"
          y1="17.1875"
          x2="30.9375"
          y2="17.1875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB8A3" />
          <stop offset="1" stopColor="#F1876D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11956_274582"
          x1="1.269e-07"
          y1="29.6336"
          x2="30.9375"
          y2="29.6336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A73A24" />
          <stop offset="1" stopColor="#F75936" />
        </linearGradient>
      </defs>
    </svg>
  )
}
