import { FC, useCallback, useMemo } from 'react'

import { useGetSpreadsheetData } from '@features/data-sources'
import { DataSourceCellRange } from '@features/syncs/components/sync-data-source/select-cell-range'
import { Section } from '@features/syncs/components/sync-settings/section/section'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'

import { Checkbox, Tooltip } from '@shared/components'

import styles from './cell-ranges-select.module.css'

interface Props {
  index?: number
}

export const CellRanges: FC<Props> = ({ index }) => {
  const { sheetSelected, dataSource, spreadsheetsData, updateSyncData } = useSyncSettingsContext()
  const { data: sheetsData } = useGetSpreadsheetData(dataSource?.id)

  const namedRanges = useMemo(
    () =>
      (sheetsData?.namedRanges ?? []).filter(
        range => range.range.sheetId.toString() === sheetSelected?.sheetId.toString()
      ),
    [sheetsData, sheetSelected]
  )

  const updateCellRangeInSync = useCallback(
    async (value: string[] | null) => {
      updateSyncData({ cellRanges: value })
    },
    [updateSyncData]
  )

  const updateGridlinesInSync = useCallback(async () => {
    updateSyncData({ spreadsheetsData: { ...spreadsheetsData, gridlines: !spreadsheetsData?.gridlines } })
  }, [spreadsheetsData, updateSyncData])

  const updateFreezeRowsInSync = useCallback(async () => {
    updateSyncData({ spreadsheetsData: { ...spreadsheetsData, freezeRows: !spreadsheetsData?.freezeRows } })
  }, [spreadsheetsData, updateSyncData])

  const updatecollapsedRowsInSync = useCallback(async () => {
    updateSyncData({ spreadsheetsData: { ...spreadsheetsData, collapsedRows: !spreadsheetsData?.collapsedRows } })
  }, [spreadsheetsData, updateSyncData])

  return (
    <Section title={`${index} Cell range`} subtitle="Select and configure your cell range">
      <DataSourceCellRange
        onCellRangeChange={updateCellRangeInSync}
        disabled={!sheetSelected}
        namedRanges={namedRanges}
        multiSelectEnabled={true}
      />
      <div className={styles.checkOps}>
        <Tooltip title={'Include sheet gridlines'}>
          <div className={styles.checkboxTooltip} onClick={() => updateGridlinesInSync()}>
            <Checkbox checked={spreadsheetsData?.gridlines} />
            Gridlines
          </div>
        </Tooltip>
        <Tooltip title={`Include rows marked as 'Frozen' in the sheet`}>
          <div className={styles.checkboxTooltip} onClick={() => updateFreezeRowsInSync()}>
            <Checkbox checked={spreadsheetsData?.freezeRows} />
            Freeze Rows
          </div>
        </Tooltip>
        <Tooltip title={`Hide all grouped rows`}>
          <div className={styles.checkboxTooltip} onClick={() => updatecollapsedRowsInSync()}>
            <Checkbox checked={spreadsheetsData?.collapsedRows} />
            Collapse Rows
          </div>
        </Tooltip>
      </div>
    </Section>
  )
}
