import { isEqual, uniqWith } from 'lodash'
import { useMemo, useRef, useState } from 'react'

import { FilterOption, ParsedVariant, VariantOption, VariantOptionRow } from '@features/collections/types'
import { useGetLookerFilterValuesCombinations, useGetMetabaseFilterValuesCombinations } from '@features/data-sources'
import { FilterType } from '@features/filters/types'

import { useBoolean } from '@shared/hooks'

const createInitialVariantRowState = () => [{ primaryValue: null, secondaryValues: null, secondaryAllSelected: false }]

const SCROLL_TO_ADD_ROW_BTN_TIMEOUT = 50

type GetFilterValuesCombinations = {
  primaryFilterId?: string
  secondaryFilterId?: string
  dashboardId?: string
  credentialsId?: string
  type?: FilterType
}

const useGetFilterValuesCombinations = (
  args: GetFilterValuesCombinations,
  tableauContextData?: {
    filterValuesCombinations: ParsedVariant[]
    isLoadingFilterValuesCombinations: boolean
  }
) => {
  const { data: lookerCombinations, isLoading: isLoadingLookerCombinations } = useGetLookerFilterValuesCombinations({
    ...args,
    queryOptions: { enabled: args.type === FilterType.LookerFilter },
  })

  const { data: metabaseCombinations, isLoading: isLoadingMetabaseCombinations } =
    useGetMetabaseFilterValuesCombinations({
      ...args,
      queryOptions: { enabled: args.type === FilterType.MetabaseFilter },
    })

  const { filterValuesCombinations, isLoading } = useMemo(() => {
    switch (args.type) {
      case FilterType.TableauFilter:
      case FilterType.TableauParams:
        return {
          filterValuesCombinations: tableauContextData?.filterValuesCombinations ?? [],
          isLoading: tableauContextData?.isLoadingFilterValuesCombinations,
        }
      case FilterType.LookerFilter:
        return { filterValuesCombinations: lookerCombinations ?? [], isLoading: isLoadingLookerCombinations }
      case FilterType.MetabaseFilter:
        return { filterValuesCombinations: metabaseCombinations ?? [], isLoading: isLoadingMetabaseCombinations }
      default:
        return { filterValuesCombinations: [], isLoading: false }
    }
  }, [
    args.type,
    tableauContextData?.filterValuesCombinations,
    tableauContextData?.isLoadingFilterValuesCombinations,
    lookerCombinations,
    isLoadingLookerCombinations,
    metabaseCombinations,
    isLoadingMetabaseCombinations,
  ])

  return {
    filterValuesCombinations,
    isLoading,
  }
}

export const useTwoVariantValuesField = (
  {
    credentialsId,
    filters,
    tableauContextData,
    enableScrollToAddRow,
  }: {
    credentialsId?: string
    filters?: FilterOption[] | null
    tableauContextData?: {
      filterValuesCombinations: ParsedVariant[]
      isLoadingFilterValuesCombinations: boolean
    }
    enableScrollToAddRow?: boolean
  } = { enableScrollToAddRow: false }
) => {
  const [variantRows, setVariantRows] = useState<VariantOptionRow[]>(createInitialVariantRowState)
  const [selectedAllVariantsCombinations, toggleAllVariantsCombinations] = useBoolean(false)
  const addRowRef = useRef<HTMLDivElement>(null)

  const { filterValuesCombinations, isLoading } = useGetFilterValuesCombinations(
    {
      credentialsId,
      dashboardId: filters?.[0]?.dashboardId,
      primaryFilterId: filters?.[0]?.dashboardFilterId,
      secondaryFilterId: filters?.[1]?.dashboardFilterId,
      type: filters?.[0].type,
    },
    tableauContextData
  )

  const parsedVariants: ParsedVariant[] = useMemo(() => {
    if (selectedAllVariantsCombinations) {
      return filterValuesCombinations
    }

    const variantCombinations = variantRows
      .map(row =>
        row.secondaryValues?.map(secondaryValue => ({
          primaryValue: row.primaryValue?.value,
          secondaryValue: secondaryValue.value,
        }))
      )
      .flat()
      .filter(variant => variant && variant.primaryValue && variant.secondaryValue) as ParsedVariant[]

    return uniqWith(variantCombinations, isEqual)
  }, [selectedAllVariantsCombinations, filterValuesCombinations, variantRows])

  const handleDelete = (index: number) => {
    setVariantRows([...variantRows].filter((_, i) => i !== index))
  }

  const handleAdd = () => {
    setVariantRows(prevRows => [
      ...prevRows,
      { primaryValue: null, secondaryValues: null, secondaryAllSelected: false },
    ])
    if (enableScrollToAddRow) {
      setTimeout(() => {
        if (addRowRef.current) {
          addRowRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          })
        }
      }, SCROLL_TO_ADD_ROW_BTN_TIMEOUT)
    }
  }

  const handleChangePrimaryValue = (option: VariantOption, index: number) => {
    const newRows = [...variantRows]
    newRows[index].primaryValue = option
    newRows[index].secondaryValues = []
    newRows[index].secondaryAllSelected = false
    setVariantRows(newRows)
  }

  const handleChangeSecondaryValue = (options: VariantOption[], index: number) => {
    const newRows = [...variantRows]
    newRows[index].secondaryValues = options
    setVariantRows(newRows)
  }

  const handleSelectAll = (options: VariantOption[], index: number) => {
    const newRows = [...variantRows]
    newRows[index].secondaryAllSelected = !newRows[index].secondaryAllSelected
    newRows[index].secondaryValues = newRows[index].secondaryAllSelected ? options : []
    setVariantRows(newRows)
  }

  const handleToggleAllCombinations = () => {
    toggleAllVariantsCombinations()
  }

  const reset = () => {
    setVariantRows(createInitialVariantRowState())
  }

  return {
    parsedVariants,
    isLoading,
    variantRows,
    selectedAllVariantsCombinations,
    addRowRef,
    handleDelete,
    handleAdd,
    handleChangePrimaryValue,
    handleChangeSecondaryValue,
    handleSelectAll,
    handleToggleAllCombinations,
    reset,
  }
}
