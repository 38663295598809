import { Checkbox } from '@shared/components'

import styles from './auto-provisioning.module.css'

interface IAutoProvisioningProps<T> {
  toggleAutoProvision: () => void
  state: T & { autoProvision: boolean }
}

export const AutoProvisioning = <T,>({ toggleAutoProvision, state }: IAutoProvisioningProps<T>) => {
  return (
    <div className={styles.pageContainer}>
      <Checkbox checked={state.autoProvision} onClick={toggleAutoProvision} />
      <span className={styles.checkboxText}>
        Enable automatic provisioning for new users.{' '}
        <a href="https://docs.rollstack.com/user-management#TSWrO" target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </span>
    </div>
  )
}
