import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import {
  IMetabaseDataSource,
  IMetabaseDataSourceRequest,
  IMetabaseDataSourceUpdateRequest,
} from '@features/data-sources/types'
import { IMetabaseCollection, IMetabaseDashboard, MetabaseDashboardDetails } from '@features/embedding/metabase/types'

import { getMessage } from '@shared/constants'
import { onError, queryClient, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'

import { handleMetabaseApiErrors } from './error-handler'

export const useGetMetabaseDataSource = (sourceId?: string, includeChildCredentials = false) => {

  return useQueryWithErrorHandler<IMetabaseDataSource, AxiosError>(
    ['metabase', sourceId, includeChildCredentials],
    async () => {
      if (!sourceId) {
        return {} as IMetabaseDataSource
      }
      const response = await api.get(`datasources/metabase/${sourceId}`, {
        params: { includeChildCredentials },
      })

      if (!response) {
        return {} as IMetabaseDataSource
      }
      return response.data
    },
    {
      useErrorBoundary: false,
    }
  )
}

export const useAddParentMetabaseDataSource = () => {
  return useMutation<AxiosResponse<IMetabaseDataSource>, any, any>(
    (datasource: IMetabaseDataSourceRequest) => api.post('/datasources/metabase', JSON.stringify(datasource)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('METABASE_DATA_SOURCE_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useAddChildMetabaseDataSource = (parentDataSource?: string) => {
  return useMutation<AxiosResponse<IMetabaseDataSource> | undefined, any, any>(
    async (userId: number) => {
      if (!parentDataSource) return

      return api.post(`/datasources/metabase/${parentDataSource}/children`, JSON.stringify({ userId }))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['metabase'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('METABASE_DATA_SOURCE_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useUpdateMetabaseDataSource = () => {
  return useMutation(
    (datasource: IMetabaseDataSourceUpdateRequest) =>
      api.patch(`/datasources/metabase/${datasource.id}`, JSON.stringify(datasource)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['metabase'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('METABASE_DATA_SOURCE_UPDATE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useDeleteMetabaseDataSource = () => {
  return useMutation((id: string) => api.delete(`/datasources/metabase/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['metabase'])
      queryClient.invalidateQueries(['datasources'])
    },
    onError: (error: Error) => {
      onError(error, {
        title: getMessage('METABASE_DATA_SOURCE_DELETE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useGetMetabaseCollection = (credentialId?: string) => {
  return useQuery<IMetabaseCollection[], AxiosError>(
    ['metabase/folders', credentialId],
    async () => {
      if (!credentialId) return []

      const response = await api.get(`datasources/metabase/${credentialId}/collections`)
      if (!response) {
        return []
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleMetabaseApiErrors(error, getMessage('METABASE_FOLDERS_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetMetabaseDashboards = (credentialId?: string) => {
  return useQuery<IMetabaseDashboard[], AxiosError>(
    ['metabase/dashboards', credentialId],
    async () => {
      if (!credentialId) return []

      const response = await api.get(`datasources/metabase/${credentialId}/dashboards`)
      if (!response) {
        return []
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleMetabaseApiErrors(error, getMessage('METABASE_DASHBOARDS_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetMetabaseDashboard = (credentialId?: string, dashboardId?: string) => {
  return useQuery<MetabaseDashboardDetails, AxiosError>(
    ['metabase/dashboard', credentialId, dashboardId],
    async () => {
      if (!dashboardId || !credentialId) return {}

      const response = await api.get(`datasources/metabase/${credentialId}/dashboards/${dashboardId}`)
      if (!response) {
        return {}
      }
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleMetabaseApiErrors(error, getMessage('METABASE_DASHBOARD_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetMetabaseDashboardEmbedUrl = (
  dashboardId?: string,
  credentialId?: string,
  hideFilters?: boolean,
  syncId?: string,
  filters?: string,
  { enabled } = { enabled: true }
) => {
  return useQuery<string, AxiosError>(
    ['metabase/dashboard/embed-url', dashboardId, credentialId, hideFilters, syncId, filters],
    async () => {
      if (!dashboardId || !credentialId) return ''

      const response = await api.get(`datasources/metabase/${credentialId}/dashboards/${dashboardId}/embed-url`, {
        params: {
          hide_filters: Boolean(hideFilters),
          sync_id: syncId,
        },
      })

      if (!response) return ''

      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleMetabaseApiErrors(error, getMessage('METABASE_DASHBOARD_EMBED_URL_GET_ERROR'))
      },
      useErrorBoundary: false,
      enabled,
    }
  )
}

type GetFilterValuesParams = {
  filterId?: string
  dashboardId?: string
  credentialsId?: string
  filteredByFilterId?: string
  filteredByFilterValue?: string
  query?: string
}

export const getMetabaseFilterValuesQueryKey = (params: GetFilterValuesParams) => ['metabase/filters-values', params]

export const getMetabaseFilterValues = async ({
  filterId,
  dashboardId,
  credentialsId,
  filteredByFilterId,
  filteredByFilterValue,
  query,
}: GetFilterValuesParams): Promise<string[]> => {
  if (!credentialsId || !dashboardId || !filterId) {
    return []
  }

  const response = await api.get(
    `datasources/metabase/${credentialsId}/dashboards/${dashboardId}/filters/${filterId}/values`,
    {
      params: {
        filteredByFilterId,
        filteredByFilterValue,
        query,
      },
    }
  )

  return response.data
}

export const useGetMetabaseFilterValues = (params: GetFilterValuesParams, queryOptions?: { enabled: boolean }) => {
  return useQuery<string[], AxiosError>(
    getMetabaseFilterValuesQueryKey(params),
    () => getMetabaseFilterValues(params),
    {
      onError: (error: AxiosError) => {
        handleMetabaseApiErrors(error, getMessage('METABASE_FILTERS_VALUES_GET_ERROR'))
      },
      enabled: queryOptions?.enabled,
      useErrorBoundary: false,
    }
  )
}

export const useGetMetabaseFilterValuesCombinations = ({
  dashboardId,
  credentialsId,
  primaryFilterId,
  secondaryFilterId,
  queryOptions = { enabled: true },
}: {
  dashboardId?: string
  credentialsId?: string
  primaryFilterId?: string
  secondaryFilterId?: string
  queryOptions?: { enabled: boolean }
}) => {
  return useQuery<{ primaryValue: string; secondaryValue: string }[], AxiosError>(
    ['metabase/filters-combinations', { credentialsId, dashboardId, primaryFilterId, secondaryFilterId }],
    async () => {
      if (!credentialsId || !dashboardId || !primaryFilterId || !secondaryFilterId) return []

      const response = await api.get(
        `datasources/metabase/${credentialsId}/dashboards/${dashboardId}/filters-combinations?primaryFilterId=${primaryFilterId}&secondaryFilterId=${secondaryFilterId}`
      )

      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleMetabaseApiErrors(error, getMessage('METABASE_FILTERS_VALUES_GET_ERROR'))
      },
      enabled: queryOptions.enabled,
      useErrorBoundary: false,
    }
  )
}
