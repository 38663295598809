/* eslint-disable no-magic-numbers */
import classNames from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildSlideIdSearchParam } from '@pages/routes/paths'

import { DuplicateIcon, OpenBookIcon, PlusIcon, SearchIconMd, TrashIcon, UpdateIcon } from '@assets'

import { TemplateModificationModal, useGetCollectionById } from '@features/collections'
import { CollectionVariantType } from '@features/collections/types'
import { ISlide, getLastRefreshedAt, isPresentationDestination } from '@features/destinations'
import { RefreshSlidesExplainerModal } from '@features/destinations/components/modals/refresh-slides-explainer-modal'
import { VisualizationExplainerModal } from '@features/destinations/components/modals/visualization-explainer-modal'
import { ViewerDestinationTooltip } from '@features/destinations/components/tooltips/viewer-destination-tooltip'
import { useDestinationVisTable } from '@features/destinations/hooks/use-destination-vis-table'
import { Destination } from '@features/destinations/types'
import { SlidePreview } from '@features/syncs/components/slide-preview/slide-preview'
import { SyncCrop } from '@features/syncs/components/sync-crop/sync-crop'
import { SyncLocation } from '@features/syncs/components/sync-location/sync-location'
import { VisualizationSelectSheets } from '@features/syncs/components/sync-settings/visualization-select-sheets/visualization-select-sheets'
import { TransferSyncModal } from '@features/syncs/components/transfer-sync-modal/transfer-sync-modal'
import { WithSlideDeleteProps, WithSyncDeleteProps, withSlideDelete, withSyncDelete } from '@features/syncs/hocs'
import { useAuthStore } from '@features/users'

import { Button, Checkbox, Input, Spinner } from '@shared/components'
import { Callout } from '@shared/components/callout/callout'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/components/ui/tooltip'
import { ROLLSTACK_DOCS_MOVE_TO_URL } from '@shared/constants'
import { DEFAULT_DATE_FORMAT } from '@shared/constants/dates'

import { UpdateVariantsModal } from './update-variants-modal/update-variants-modal'
import { vizsTableColumns, vizsTableData } from './visualizations-table/visualizations-table'

const TOP_OFFSET = 50

type DestinationVisTabProps = {
  destination: Destination
  slideId?: string
  slides?: ISlide[]
} & WithSyncDeleteProps &
  WithSlideDeleteProps

const DestinationVisTabRaw: FC<DestinationVisTabProps> = ({
  destination,
  slideId,
  slides,
  deleteSyncsWithConfirmation,
  deleteSlides,
  isDeleting,
  isDeletingSlides,
}) => {
  const user = useAuthStore(state => state.user)
  const {
    syncs,
    allSlides,
    slideThumbnails,
    isGoogle,
    slidesNotFoundIds,
    viewer,
    syncsGroupedBySlideId,
    collectionId,
    searchQuery,
    editable,
    isUpdating,
    destinationUpdateRunning,
    syncsOwnedByUser,
    slideSelect,
    syncSelect,
    isDuplicating,
    isDuplicatingSlides,
    visualizationExplainerModal,
    visualizationModalFrom,
    refreshSlidesExplainerModal,
    templateModificationModal,
    templateModificationModalFrom,
    refreshDestinationMutation,
    currentSync,
    showSyncCrop,
    showSyncLocation,
    showVisualizationSelectSheets,
    rowSelection,
    columnVisibility,
    openUpdateVariantsModal,
    transferSyncModalOpen,
    closeTransferSyncModal,
    setOpenUpdateVariantsModal,
    toggleShowSyncCrop,
    toggleShowSyncLocation,
    toggleShowVisualizationSelectSheets,
    duplicateSelectedSyncs,
    setSearchQuery,
    handleModifySync,
    openSyncCrop,
    openSyncLocation,
    handleSlideClick,
    handleGroupSelect,
    handleAddSync,
    setVisualizationExplainerModal,
    toggleRefreshSlidesExplainerModal,
    setTemplateModificationModal,
    setVisualizationModalFrom,
    duplicateSelectedSlides,
    handleDuplicateVisualizations,
    handleDeleteVisualizations,
    handleTransferSync,
    setRowSelection,
    handleSyncDeselect,
    handleUpdateSync,
    handleDuplicateSync,
    handleDeleteSync,
    deleteMultipleSyncs,
    onColumnVisibilityChange,
    triggerSyncsUpdate,
    setCollection,
    addGoogleSheetsDataSource,
  } = useDestinationVisTable(destination, deleteSyncsWithConfirmation, slideId, slides)

  const navigate = useNavigate()

  const { data: collection } = useGetCollectionById({ collectionId: destination.variantInCollection?.collectionId })
  const isTemplate = destination.variantInCollection?.type === CollectionVariantType.template

  const isSlidesOperationLoading = isDuplicatingSlides || isUpdating

  const slidesContainerRef = useRef<HTMLDivElement>(null)
  const selectedSlideClass = 'selected-slide'

  useEffect(() => {
    if (slidesContainerRef.current) {
      const selectedSlide = slidesContainerRef.current.querySelector(`.${selectedSlideClass}`) as HTMLElement
      if (selectedSlide) slidesContainerRef.current.scrollTo({ top: selectedSlide.offsetTop - TOP_OFFSET })
    }
  }, [])

  if (!slides || !user) {
    return null
  }

  const handleAddVisualizationClick = () => {
    if (user.showVisualizationExplainerModal) {
      setVisualizationModalFrom('ADD_VIS')
      setVisualizationExplainerModal(true)
    } else {
      handleAddSync(slideId)
    }
  }

  const handleDeleteSlidesClick = () =>
    deleteSlides({
      slideIds: slideSelect.selectedList,
      destinationId: destination.id,
      callback: handleSyncDeselect,
    })

  const isActiveSlideNotFound =
    isPresentationDestination({ type: destination.type }) && !slides.find(slide => slide.id === slideId)

  function handleUpdate() {
    if (collection && isTemplate) {
      setCollection(collection)
      setOpenUpdateVariantsModal(true)
    } else {
      triggerSyncsUpdate()
    }
  }

  return (
    <>
      {collection && isTemplate && (
        <UpdateVariantsModal
          open={openUpdateVariantsModal}
          closeModal={() => setOpenUpdateVariantsModal(false)}
          collectionVariants={collection.collectionVariants}
          onUpdateSelected={selectedVariants => {
            triggerSyncsUpdate(selectedVariants)
          }}
        />
      )}
      <div className="mb-5 flex flex-row-reverse items-center justify-between gap-5">
        <Input
          icon={<SearchIconMd />}
          value={searchQuery}
          className="h-10 w-[300px] rounded-lg m-0 flex-grow-0"
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search by visualization or dashboard"
        />
        {slideSelect.anySelected ? (
          <div className="flex flex-row items-center gap-2.5">
            <Button
              secondaryGray
              text="Update slides"
              disabled={!editable}
              loading={isUpdating}
              iconLeading={<UpdateIcon />}
              onClick={() => handleUpdate()}
            />
            {!collectionId && isPresentationDestination({ type: destination.type }) && (
              <>
                <Button
                  secondaryGray
                  text="Duplicate slides"
                  disabled={!editable}
                  loading={isDuplicatingSlides}
                  onClick={duplicateSelectedSlides}
                  iconLeading={<DuplicateIcon />}
                />
                <Button
                  secondaryGray
                  text="Delete slides"
                  disabled={!editable}
                  loading={isDeletingSlides}
                  iconLeading={<TrashIcon />}
                  onClick={() => handleDeleteSlidesClick()}
                />
              </>
            )}
            <Button tertiaryGray text="Deselect" onClick={slideSelect.deselect} />
          </div>
        ) : (
          <Callout
            open={slidesNotFoundIds.length > 0}
            type="warning"
            title="Missing Slides Detected"
            subtitle="Click the links below to view slides that have been deleted. Please reassign or delete the associated syncs on the following slides:"
          >
            <ul className="list-disc pl-5">
              {' '}
              {slidesNotFoundIds.map((slideId, index) => (
                <li key={index}>
                  <button
                    className="bg-none border-none p-0 cursor-pointer no-underline hover:underline"
                    onClick={() =>
                      navigate({ search: buildSlideIdSearchParam(slideId) }, { relative: 'path', replace: true })
                    }
                  >
                    Slide {slideId}
                  </button>
                </li>
              ))}
            </ul>
            <p>
              Learn more about reassigning or deleting syncs by visiting our{' '}
              <a href={ROLLSTACK_DOCS_MOVE_TO_URL} target="_blank" rel="noopener noreferrer" className="underline">
                documentation.
              </a>
            </p>
          </Callout>
        )}
      </div>

      <div className="w-full mt-5 flex flex-row gap-2.5">
        {isPresentationDestination(destination) && (
          <>
            <section className="flex flex-col gap-8">
              {!viewer && (
                <div className="flex flex-col w-60 gap-2.5 text-sm-regular">
                  {destination.lastRefreshedAt && (
                    <p className="flex gap-1 truncate">
                      Last refreshed
                      <Tooltip>
                        <TooltipTrigger className="truncate">
                          {getLastRefreshedAt(destination)?.fromNow()}
                        </TooltipTrigger>
                        <TooltipContent>{getLastRefreshedAt(destination)?.format(DEFAULT_DATE_FORMAT)}</TooltipContent>
                      </Tooltip>
                    </p>
                  )}
                  <div className="flex gap-2.5">
                    <Button
                      text="Refresh"
                      linkColor
                      onClick={() => refreshDestinationMutation.mutateAsync(destination)}
                      disabled={refreshDestinationMutation.isLoading}
                      loading={refreshDestinationMutation.isLoading}
                      iconLeading={<UpdateIcon />}
                    />
                    <Button text="Learn more" linkGray onClick={toggleRefreshSlidesExplainerModal} />
                  </div>
                </div>
              )}

              <div
                className={classNames('flex flex-row gap-3', syncSelect.anySelected && 'invisible')}
                onClick={slideSelect.handleAllSelectClick}
              >
                <Checkbox checked={slideSelect.allSelected || (slideSelect.anySelected && 'indeterminate')} />
                <span className="text-sm-medium select-none">Select all</span>
              </div>

              <div className="relative">
                <div
                  className={classNames(
                    'flex flex-col gap-5 pr-2 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar',
                    isSlidesOperationLoading && 'opacity-20'
                  )}
                  ref={slidesContainerRef}
                >
                  {allSlides.map(({ id, notFound, index }, idx) => {
                    const image =
                      slideThumbnails && !notFound
                        ? slideThumbnails.find(st => st.slideId === id)?.thumbnail
                        : undefined
                    const badge = syncsGroupedBySlideId[id]?.length

                    let searchedSlide
                    const match = searchQuery.match(/\d+/)
                    if (match) {
                      searchedSlide = parseInt(match[0], 10)
                    }

                    if (Boolean(searchQuery) && !badge && searchedSlide !== index) return null

                    const isSelected = slideSelect.anySelected ? slideSelect.selected[id] : id === slideId

                    return (
                      <div
                        key={id}
                        className={classNames(
                          'flex justify-start w-full gap-3 cursor-pointer',
                          isSelected && selectedSlideClass
                        )}
                      >
                        <Checkbox
                          className={classNames((syncSelect.anySelected || notFound) && 'invisible')}
                          checked={slideSelect.selected[id] || false}
                          onClick={() => handleGroupSelect(id)}
                        />
                        <span className="w-4">{notFound ? '' : index}</span>
                        <SlidePreview
                          notFound={notFound}
                          thumbnail={image}
                          syncCount={syncsGroupedBySlideId[id]?.length ?? 0}
                          selected={isSelected}
                          onClick={e => handleSlideClick(id, notFound)}
                          dataTestId={`slide-${index}`}
                        />
                      </div>
                    )
                  })}
                </div>
                {isSlidesOperationLoading && <Spinner localCenter />}
              </div>
            </section>
            <div className="w-px bg-gray-200" />
          </>
        )}

        <section className="flex flex-1 flex-col items-center pl-5 gap-5">
          <div className="flex flex-row-reverse items-center justify-between w-full">
            <div className="flex items-center justify-between gap-5">
              <Button
                iconLeading={<OpenBookIcon />}
                text="Learn more"
                linkColor
                onClick={() => {
                  setVisualizationModalFrom('LEARN_MORE')
                  setVisualizationExplainerModal(true)
                }}
              />
              <AddAVisualizationButton
                editable={editable}
                isActiveSlideNotFound={isActiveSlideNotFound}
                handleAddVisualizationClick={handleAddVisualizationClick}
              />
            </div>

            <div className="flex items-center gap-7.5">
              {syncSelect.anySelected && (
                <div className="flex flex-row items-center gap-2.5">
                  <ViewerDestinationTooltip show={viewer} position="left">
                    <div className="flex flex-row items-center gap-2.5">
                      <Button
                        secondaryGray
                        text="Update"
                        disabled={!editable}
                        loading={isUpdating || destinationUpdateRunning}
                        iconLeading={<UpdateIcon />}
                        onClick={() => handleUpdate()}
                      />
                      {syncSelect.selectedList.every(syncId => syncsOwnedByUser.some(sync => sync.id === syncId)) && (
                        <Button
                          secondaryGray
                          text="Duplicate"
                          disabled={!editable}
                          loading={isDuplicating}
                          onClick={handleDuplicateVisualizations}
                          iconLeading={<DuplicateIcon />}
                        />
                      )}
                      <Button
                        secondaryGray
                        text="Delete"
                        disabled={!editable}
                        loading={isDeleting}
                        iconLeading={<TrashIcon />}
                        onClick={handleDeleteVisualizations}
                      />
                    </div>
                  </ViewerDestinationTooltip>
                  <Button tertiaryGray text="Deselect" onClick={handleSyncDeselect} />
                </div>
              )}
            </div>
          </div>

          <DataGrid
            data={vizsTableData(syncs, destination.type)}
            columns={vizsTableColumns({
              user,
              destinationType: destination.type,
              slideId,
              handleModifySync,
              openSyncCrop,
              openSyncLocation,
              handleUpdateSync,
              handleDuplicateSync,
              handleDeleteSync,
              handleTransferSync,
              hideCheckboxes: slideSelect.anySelected,
              authorizeSheetsFile: addGoogleSheetsDataSource,
            })}
            enableChangeColumnVisibility
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={onColumnVisibilityChange}
            enableRowSelection
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            EmptyState={
              <AddAVisualizationButton
                editable={editable}
                isActiveSlideNotFound={isActiveSlideNotFound}
                handleAddVisualizationClick={handleAddVisualizationClick}
              />
            }
          />

          {currentSync && (
            <>
              <SyncCrop
                key={currentSync.id}
                open={showSyncCrop}
                toggle={toggleShowSyncCrop}
                sync={currentSync}
                isGoogle={isGoogle}
              />
              <VisualizationSelectSheets
                key={currentSync.spreadsheetsData?.id}
                syncId={currentSync.id}
                open={showVisualizationSelectSheets}
                toggle={toggleShowVisualizationSelectSheets}
                toggled={showVisualizationSelectSheets}
              />
              <TransferSyncModal
                destinationId={destination.id}
                syncId={currentSync.id}
                open={transferSyncModalOpen}
                closeModal={closeTransferSyncModal}
              />
              {showSyncLocation && (
                <SyncLocation
                  destination={destination}
                  slides={slides}
                  open={showSyncLocation}
                  toggle={toggleShowSyncLocation}
                  sync={currentSync}
                />
              )}
            </>
          )}
        </section>
      </div>

      <VisualizationExplainerModal
        visible={visualizationExplainerModal}
        setModalOpen={setVisualizationExplainerModal}
        type={destination.type}
        onDismiss={() => {
          if (visualizationModalFrom === 'ADD_VIS') {
            handleAddSync(slideId)
          }
        }}
      />

      <RefreshSlidesExplainerModal
        visible={refreshSlidesExplainerModal}
        toggle={toggleRefreshSlidesExplainerModal}
        type={destination.type}
      />

      <TemplateModificationModal
        visible={templateModificationModal}
        setModalOpen={setTemplateModificationModal}
        onConfirm={() => {
          if (templateModificationModalFrom === 'DUPLICATE_VIS') {
            duplicateSelectedSyncs()
          } else if (templateModificationModalFrom === 'DELETE_VIS') {
            deleteMultipleSyncs()
          }
        }}
      />
    </>
  )
}

export const DestinationVizTab = withSlideDelete(withSyncDelete(DestinationVisTabRaw))

const AddAVisualizationButton = ({
  editable,
  isActiveSlideNotFound,
  handleAddVisualizationClick,
}: {
  editable: boolean
  isActiveSlideNotFound: boolean
  handleAddVisualizationClick: () => void
}) => {
  return (
    <Button
      text="Add a visualization"
      disabled={!editable || isActiveSlideNotFound}
      iconLeading={<PlusIcon />}
      onClick={handleAddVisualizationClick}
    />
  )
}
