import { FC } from 'react'

export const CropIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_11013_15336)">
        <path
          d="M1.66663 5.00032H12.3333C13.2667 5.00032 13.7334 5.00032 14.0899 5.18198C14.4035 5.34177 14.6585 5.59674 14.8183 5.91034C15 6.26686 15 6.73357 15 7.66699V18.3337M18.3333 15.0003L7.66663 15.0003C6.73321 15.0003 6.2665 15.0003 5.90998 14.8187C5.59637 14.6589 5.3414 14.4039 5.18162 14.0903C4.99996 13.7338 4.99996 13.2671 4.99996 12.3337V1.66699"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11013_15336">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
