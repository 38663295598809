import { AxiosError } from 'axios'
import { HttpStatusCode } from 'axios'
import httpStatus from 'http-status'
import { NavigateFunction } from 'react-router-dom'

import { PathSegments, Paths } from '@pages/routes/paths'

import { ApiError } from './api-error-class'
import { onError } from './query-client'
import { ApiErrorHandlerFn, ApiErrorHandlerParams, WebSocketError } from './types'

const handleApiError = (
  error: ApiError,
  errorHandlers: ApiErrorHandlerFn[],
  { errorTitle, skipToast, ...params }: ApiErrorHandlerParams = {}
) => {
  let errorHandled = false
  for (const errorHandler of errorHandlers) {
    errorHandled = errorHandler(error, params)
    if (errorHandled) {
      return
    }
  }

  const title = errorTitle || 'Something went wrong'
  onError(error, {
    title,
    message: error.metadata?.message || error.message,
    skipToast,
    ...params,
  })
}

export const handleAxiosError = (
  error: AxiosError,
  errorHandlers: ApiErrorHandlerFn[],
  params: ApiErrorHandlerParams = {}
) => {
  const apiError = formatApiErrorFromAxios(error)
  handleApiError(apiError, errorHandlers, params)
}

export const handleWebSocketError = (
  error: WebSocketError,
  errorHandlers: ApiErrorHandlerFn[],
  { errorTitle, ...params }: ApiErrorHandlerParams = {}
) => {
  const apiError = formatApiErrorFromWebSocket(error)

  handleApiError(apiError, errorHandlers, { errorTitle, ...params })
}

export const formatApiErrorFromAxios = (error: AxiosError): ApiError => {
  if (error.response && error.response.data) {
    const errorResponse = error.response.data as Partial<ApiError>
    return new ApiError(
      errorResponse.message || error.message,
      errorResponse.metadata?.code || 'unknown-error',
      error.response.status || httpStatus.INTERNAL_SERVER_ERROR,
      errorResponse.metadata || {}
    )
  }
  return new ApiError(
    error.message,
    'network-error',
    error.response?.status || error.status || httpStatus.INTERNAL_SERVER_ERROR,
    {}
  )
}

export const formatApiErrorFromWebSocket = (error: WebSocketError): ApiError => {
  return new ApiError(
    error.error || 'Something went wrong. Please refresh the page and try again.',
    error.errorCode || 'unknown-error',
    httpStatus.INTERNAL_SERVER_ERROR,
    {}
  )
}

export const createNotFoundOrForbiddenErrorHandler = (navigate: NavigateFunction) => {
  return (apiError: ApiError) => {
    if ([HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(apiError.statusCode)) {
      navigate('/' + PathSegments.NOT_FOUND)
      return true
    }
    return false
  }
}

export const silentNotFoundOrForbiddenHandler = (apiError: ApiError) => {
  if ([HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(apiError.statusCode)) {
    return true
  }
  return false
}

export const createUserWithoutOrganizationErrorHandler = (
  navigate: NavigateFunction,
  setActiveOrganization: (activeOrganization: undefined, skipQueryInvalidation?: boolean) => void,
  setOrganizations: (organizations: []) => void
) => {
  return (apiError: ApiError) => {
    if (apiError.metadata.code === 'no-active-organization') {
      setActiveOrganization(undefined, true)
      setOrganizations([])
      navigate(Paths.ORGANIZATION_NOT_FOUND, { replace: true })
      return true
    }
    return false
  }
}
