import classNames from 'classnames'

import { footerHeight, iframeWidthLimit } from './dashboard-element-overlay-wrapper'
import {
  BASE_FOOTER_HEIGHT,
  FILTER_HEIGHT_WITHOUT_FILTERS,
  FILTER_HEIGHT_WITH_FILTERS,
  RANDOM_FOUR,
  TITLE_HEIGHT,
} from './looker-embed'

import overlayStyles from './dashboard-element-overlay.module.css'

interface DashboardFullOverlayProps {
  width: number
  height: number
  hasFilters?: boolean
  onClick?: () => void
}

export const DashboardFullOverlay = ({ width, height, hasFilters, onClick }: DashboardFullOverlayProps) => {
  const FOOTER_HEIGHT = BASE_FOOTER_HEIGHT + (width > iframeWidthLimit ? footerHeight : 0)
  const FILTER_HEIGHT = hasFilters ? FILTER_HEIGHT_WITH_FILTERS : FILTER_HEIGHT_WITHOUT_FILTERS

  const overlayAreaClassName = classNames(overlayStyles.overlayArea, { [overlayStyles.overlayAreaSelected]: true })
  const overlayBadgeClassName = classNames(overlayStyles.overlayBadge, { [overlayStyles.overlayBadgeSelected]: true })

  return (
    <div
      onClick={onClick}
      className={overlayAreaClassName}
      style={{
        position: 'absolute',
        top: TITLE_HEIGHT,
        left: 0,
        width,
        height: height - TITLE_HEIGHT - FOOTER_HEIGHT + RANDOM_FOUR + FILTER_HEIGHT,
      }}
    >
      <div className={overlayBadgeClassName}>
        <div className={overlayStyles.badgeContent}>Full dashboard</div>
      </div>
    </div>
  )
}
