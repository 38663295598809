import { FC } from 'react'

import { useTransferSync } from '@features/syncs'

import { ActionModal, Button } from '@shared/components'

export interface TransferSyncModalProps {
  destinationId: string
  syncId: string
  open: boolean
  closeModal: () => void
}

export const TransferSyncModal: FC<TransferSyncModalProps> = ({
  destinationId,
  syncId,
  open,
  closeModal,
}: TransferSyncModalProps) => {
  const { mutate, isLoading } = useTransferSync(destinationId, syncId, {
    onSuccess: () => {
      closeModal()
    },
  })

  const onTransferSync = () => {
    mutate({})
  }

  return (
    <ActionModal
      open={open}
      title="Transfer visualization ownership"
      subtitle="Transferring the ownership of this visualization will attach your data source to it, and keep the existing filters. Please make that you have the same level of access to this dashboard as the current owner."
      onBackgroundClick={closeModal}
      btns={
        <>
          <Button secondaryGray text="Cancel" onClick={closeModal} fullWidth />
          <Button fullWidth text="Transfer" onClick={onTransferSync} loading={isLoading} />
        </>
      }
    />
  )
}
