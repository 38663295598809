import { ExternalLinkIcon } from '@assets'

import { MenuItem, MenuItemProps, NewTabLink } from '@shared/components'

interface OpenExternalItemProps extends MenuItemProps {
  dataTestId: string
  link: string
}

export const OpenExternalItem = ({ dataTestId, link, visible = true, ...menuItemProps }: OpenExternalItemProps) => {
  if (!visible) {
    return null
  }

  return (
    <NewTabLink link={link} data-testid={dataTestId}>
      <MenuItem {...menuItemProps}>
        <ExternalLinkIcon />
        {'Open'}
      </MenuItem>
    </NewTabLink>
  )
}
