import { XClose } from '@assets'

import { getReadableFilters } from '@features/embedding'

import { Badge, Button, EBadgeColor, Tooltip } from '@shared/components'

export const getFiltersList = (syncFilters: Record<string, any>) => {
  return getReadableFilters(syncFilters)
    .sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()))
    .map(({ key, displayValue }) => <div>{`${key}: ${displayValue}`}</div>)
}

export const getFiltersViewFromString = (filters?: Object, onFilterCb?: (key: string) => void) => {
  if (!filters) return []

  return getReadableFilters(filters).map(({ key, displayValue }) => (
    <div key={key} className="flex flex-row mb-2 items-center gap-2">
      <Tooltip key={key} title={key + ': ' + displayValue} position="top">
        <div>
          <Badge key={key} color={EBadgeColor.SECONDARY} text={`${key}: ${displayValue}`} />
        </div>
      </Tooltip>
      {onFilterCb && (
        <Button
          className="h-2.5 px-3 flex items-center justify-center"
          linkError
          iconLeading={<XClose size={15} />}
          onClick={() => onFilterCb(key)}
        />
      )}
    </div>
  ))
}
