import { FC, PropsWithChildren, useEffect } from 'react'

import { useOrganizationsStore } from '@features/organizations'

import { useGetUsersInOrganizations } from './api'

export const OrganizationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: userInOrganizations } = useGetUsersInOrganizations()
  const { activeOrganization, setActiveOrganization, setOrganizations } = useOrganizationsStore()

  useEffect(() => {
    if (!userInOrganizations || userInOrganizations.length === 0) return

    setOrganizations(userInOrganizations)

    const activeOrgFromStore = userInOrganizations.find(
      org => org.organization.id === activeOrganization?.organization.id
    )

    if (!activeOrganization || !activeOrgFromStore) {
      setActiveOrganization(userInOrganizations[0])
    } else {
      setActiveOrganization(activeOrgFromStore)
    }
  }, [userInOrganizations, activeOrganization, setOrganizations, setActiveOrganization])

  return <>{children}</>
}
