import { FC } from 'react'
import { useParams } from 'react-router-dom'

import {
  AddChildDataSourceModal,
  useDeleteLookerDataSource,
  useGetLookerDataSource,
  useManageDefaultDataSource,
} from '@features/data-sources'
import { LookerAPIKeysModal } from '@features/data-sources/components/connection-test/looker-api-keys-modal/looker-api-keys-modal'

import { UserManagement } from './user-management'

export const LookerUserManagement: FC = () => {
  const { sourceId } = useParams()

  return (
    <UserManagement
      sourceId={sourceId!}
      useGetDataSource={useGetLookerDataSource}
      useDeleteDataSource={useDeleteLookerDataSource}
      useManageDefaultDataSource={useManageDefaultDataSource}
      TestModalComponent={LookerAPIKeysModal}
      AddChildDataSourceModalComponent={AddChildDataSourceModal}
    />
  )
}
