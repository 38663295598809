import { EDataSourceType } from '@features/data-sources/types/types'
import {
  Destination,
  DestinationEmailConfigSharingPermission,
  EDestinationType,
  IDriveData,
} from '@features/destinations/types'
import { FilterType, IFilter } from '@features/filters/types'
import { DuplicateDestinationVisResult } from '@features/notch/destinations-status-provider'
import { ISync } from '@features/syncs/types'
import { DestinationThemeColors } from '@features/theme/types'
import { User } from '@features/users'

import {
  EmailConfigTrigger,
  EmailRecipientConfig,
  IUpdateEmailConfigVariables,
} from '@shared/components/email-config/types'

export enum CollectionVariantType {
  template = 'template',
  variant = 'variant',
}

export interface UserSharedCollection {
  user: User
  userId: number
  googleShareId: string
  permission: SharingCollectionPermissions
}

export interface CollectionVariantFilter {
  id: string
  value: string
  collectionVariantId: string
  collectionFilterId: string
  collectionFilter: CollectionFilter
}

export interface ICollectionVariant {
  id: string
  type: CollectionVariantType
  collectionVariantFilters: CollectionVariantFilter[]
  code?: string
  syncsToExclude: string[]
  slidesToExclude: string[]
  disabled: boolean
  collectionId: string
  destinationId: string
  createdAt: string
  updatedAt: string
  destination: Destination
  collection?: ICollection
}

export enum EVariantNaming {
  sameName = 'sameName',
  prefix = 'prefix',
  suffix = 'suffix',
}

export interface ICollection {
  id: string
  name: string
  type: EDestinationType
  parentFolderId: string

  collectionVariants: ICollectionVariant[]
  collectionVariantTemplate: ICollectionVariant

  collectionFilters: CollectionFilter[]

  variantNaming: EVariantNaming

  lastSyncedAt: string
  createdAt: string
  updatedAt: string
  // syncing: boolean
  userId: number
  user: User

  sharedWith?: UserSharedCollection[]

  driveData?: IDriveData

  folderUrl?: string
}

export enum CollectionFilterType {
  primary = 'primary',
  secondary = 'secondary',
}

export const collectionFilterTypeToNumber = {
  [CollectionFilterType.primary]: 0,
  [CollectionFilterType.secondary]: 1,
}

export type TableauRequiredEmbedData = {
  workbookUrl?: string | null
  workbookName?: string | null
  workbookContentUrl?: string | null
  viewId?: string | null
  viewName?: string | null
}

export interface CollectionFilter {
  collectionId: string
  filterId: string
  filter: IFilter & { matchingCredentialsId?: string; tableauRequiredEmbedData?: TableauRequiredEmbedData }
  type: CollectionFilterType
}

export interface ICollectionEmailRecipientConfig extends EmailRecipientConfig {
  variant_name: string
  recipient_email: string
  recipient_name: string
}

export interface ICollectionEmailConfig {
  id: string
  createdAt: Date
  updatedAt: Date
  collectionId: string
  emailTemplateId?: string | null
  trigger: EmailConfigTrigger
  recipientConfig: ICollectionEmailRecipientConfig[]
  sharingPermission: DestinationEmailConfigSharingPermission | null
}

export type ICollectionEmailConfigCreateRequest = Omit<ICollectionEmailConfig, 'id' | 'createdAt' | 'updatedAt'>
export type ICollectionEmailConfigUpdateRequest = Partial<ICollectionEmailConfigCreateRequest>

export interface ICollectionTestEmailRequest {
  recipient: string
  variantId: string
  emailTemplateId?: string | null
  name?: string
  subject?: string
}

export interface ICollectionSendEmailResponse {
  drivePermission?: {
    appliedSharing?: boolean
  }
}

export type IAddCollectionRequest = {
  destinationId: string

  primaryFilterId: string
  secondaryFilterId?: string

  variants: ParsedVariant[]

  driveId?: string | null
  parentFolderId?: string
  variantNaming: EVariantNaming
}

export type FieldStatus = 'OPEN' | 'DISABLED' | 'SELECTED'

/* eslint-disable no-magic-numbers */
export enum FieldStep {
  DASHBOARD = 1,
  FILTER = 2,
  VARIANTS = 3,
  NAMING = 4,
  LOCATION = 5,
}
/* eslint-enable no-magic-numbers */

export interface DashboardOption {
  id: string
  name: string
  credentialsId: string
  type: EDataSourceType

  // For tableau variants
  vis: ISync
}

export type FilterOption = Pick<IFilter, 'id' | 'name' | 'type' | 'dashboardFilterId' | 'dashboardId'>

export interface VariantOption {
  label: string
  value: string
}

export interface VariantOptionRow {
  primaryValue: VariantOption | null
  secondaryValues: VariantOption[] | null
  secondaryAllSelected: boolean
}

export interface ParsedVariant {
  primaryValue: string
  secondaryValue?: string
  themeColors?: DestinationThemeColors
}

export interface FolderOption {
  driveId?: string
  folderId: string
  folderName: string
}

export const mapSourceTypeToVariantType = {
  [EDataSourceType.Tableau]: [FilterType.TableauFilter, FilterType.TableauParams],
  [EDataSourceType.Looker]: [FilterType.LookerFilter],
  [EDataSourceType.Metabase]: [FilterType.MetabaseFilter],
  [EDataSourceType.GoogleSheets]: [],
}

export const isTableauFilter = (selectedFilter: { type: FilterType }) =>
  selectedFilter && [FilterType.TableauFilter, FilterType.TableauParams].includes(selectedFilter.type)

export type GetFilterValuesParams = {
  filterId?: string
  dashboardId?: string
  credentialsId?: string
  filteredByFilterId?: string
  filteredByFilterValue?: string
  query?: string
}

export interface IDuplicateVariantOutsideResponse {
  data: Destination
  variants: DuplicateDestinationVisResult[]
}

export enum SharingCollectionPermissions {
  admin = 'admin',
  contributor = 'contributor',
}

export type IUpdateCollectionEmailConfigVariables = IUpdateEmailConfigVariables<ICollectionEmailConfigUpdateRequest>
