import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { DestinationTabs, buildDestinationPath, buildSlideIdSearchParam } from '@pages/routes/paths'

import { CollectionVariantType } from '@features/collections/types'
import { useGetDestinationById, useGetDestinationSlides } from '@features/destinations'
import { getDashboardElementName, parseSyncProperties, useSyncSettingsContext } from '@features/syncs'
import { ISync } from '@features/syncs/types'
import { useAuthStore } from '@features/users'

interface UseEditVisualizationParams {
  syncId?: string
  toggled?: boolean
  toggle?: () => void
}

export const useEditVisualization = ({ syncId, toggled, toggle }: UseEditVisualizationParams) => {
  const {
    canSaveSync,
    filtersChanged,
    fullDashboard,
    isLoadingValidSyncsForSelectedFilters,
    isSavingSync,
    selectedVis,
    showFilterModificationModal,
    validSyncsForSelectedFilters,
    cellRanges,
    spreadsheetsData,
    saveSync,
    setDestination,
    updateVanityData,
    updateSyncSettings,
    updateSyncData,
    batchApplyFilters,
  } = useSyncSettingsContext()

  const destinationId = useParams().destinationId
  const visualizationId = useParams().visualizationId || syncId
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { user } = useAuthStore()

  const [templateModificationModal, setTemplateModificationModal] = useState(false)

  useEffect(() => {
    updateVanityData({ isNewSync: false })
  }, [updateVanityData])

  const { data: destination, isLoading: isDestinationLoading } = useGetDestinationById({ destinationId })
  const { data: slides, isLoading: isSlidesLoading } = useGetDestinationSlides(destination)
  const visualization = useMemo(() => {
    return destination?.syncs?.find(sync => sync.id === visualizationId)
  }, [destination, visualizationId])

  const isLoading = useMemo(() => isDestinationLoading || isSlidesLoading, [isDestinationLoading, isSlidesLoading])

  useEffect(() => {
    if (!toggled) {
      updateSyncData({ spreadsheetsData: undefined })
    } else if (visualization?.spreadsheetsData) {
      updateSyncData({
        spreadsheetsData: {
          ...spreadsheetsData,
          ...visualization?.spreadsheetsData,
        },
      })
    }
    updateVanityData({ isNewSync: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggled])

  useEffect(() => {
    if (destination) {
      setDestination(destination)
    }
    if (visualization) {
      updateSyncSettings(visualization)
    }
  }, [destination, visualization, setDestination, updateSyncSettings])

  const goToDestinationsPage = useCallback(
    (slideId?: string) => {
      navigate({
        pathname: buildDestinationPath(destinationId, DestinationTabs.VISUALIZATIONS),
        search: buildSlideIdSearchParam(searchParams.get('slideId') ?? slideId),
      })
    },
    [navigate, destinationId, searchParams]
  )

  const handleSaveFiltersToOne = async () => {
    if (visualization) {
      await handleSaveFiltersAndApplyToMany([visualization])
      goToDestinationsPage()
    }
  }

  const handleSaveFiltersAndApplyToMany = async (selectedVisualizations: ISync[]) => {
    const { fullDashboard: syncsFullDash } = parseSyncProperties(visualization)
    let syncVisChanged = false

    if (fullDashboard !== syncsFullDash) {
      syncVisChanged = true
    }
    if (
      !fullDashboard &&
      !syncsFullDash &&
      selectedVis.length === 1 &&
      visualization?.name !== getDashboardElementName(selectedVis[0])
    ) {
      syncVisChanged = true
    }

    await Promise.all([batchApplyFilters(selectedVisualizations), ...(syncVisChanged ? [saveSync()] : [])])

    updateVanityData({ showFilterModificationModal: false })
    goToDestinationsPage()
  }

  const handleSaveSync = async () => {
    if (!filtersChanged) {
      await saveSync()
      toggle && toggle()
      goToDestinationsPage()
      return
    }

    if (
      validSyncsForSelectedFilters?.length === 1 &&
      visualization &&
      validSyncsForSelectedFilters[0] === visualization.id
    ) {
      await handleSaveFiltersToOne()
      return
    }

    updateVanityData({ showFilterModificationModal: true })
  }

  const handleClickSave = async () => {
    if (
      user?.showTemplateModificationModal &&
      destination?.variantInCollection?.type === CollectionVariantType.template
    ) {
      setTemplateModificationModal(true)
    } else {
      await handleSaveSync()
    }
  }

  const handleCancelOnFilterModal = () => updateVanityData({ showFilterModificationModal: false })

  const handleRangeChange = (value: string[] | null) => {
    updateSyncData({ cellRanges: value })
  }

  const handleGridlinesChange = () => {
    updateSyncData({ spreadsheetsData: { ...spreadsheetsData, gridlines: !spreadsheetsData?.gridlines } })
  }

  const handleFreezeRowsChange = () => {
    updateSyncData({ spreadsheetsData: { ...spreadsheetsData, freezeRows: !spreadsheetsData?.freezeRows } })
  }

  const handlecollapsedRowsChange = () => {
    updateSyncData({ spreadsheetsData: { ...spreadsheetsData, collapsedRows: !spreadsheetsData?.collapsedRows } })
  }

  return {
    visualization,
    destination,
    slides,
    goToDestinationsPage,
    handleClickSave,
    isSavingSync,
    canSaveSync,
    isLoading,
    isLoadingValidSyncsForSelectedFilters,
    showFilterModificationModal,
    templateModificationModal,
    setTemplateModificationModal,
    handleCancelOnFilterModal,
    handleSaveFiltersToOne,
    handleSaveFiltersAndApplyToMany,
    handleSaveSync,
    cellRanges,
    spreadsheetsData,
    handleRangeChange,
    handleGridlinesChange,
    handleFreezeRowsChange,
    handlecollapsedRowsChange,
  }
}
