import startCase from 'lodash/startCase'
import { Slide, TypeOptions, toast } from 'react-toastify'

import { GreenCheckCircleIcon, InfoIcon } from '@assets'

import { EDestinationType } from '@features/destinations/types'
import { getDriveLongName } from '@features/drive'
import { OAuthMissingToast } from '@features/drive/components/oauth-missing-toast/oauth-missing-toast'
import { RollstackResource } from '@features/drive/error-handler'
import { OAuthType } from '@features/users'

import { DataOutOfDateToast } from '@shared/components/toasts/data-out-of-date/data-out-of-date-toast'
import { Colors } from '@shared/constants'

import { ApiErrorToast } from './api-error/api-error-toast'
import { GenericToast } from './generic-toast/generic-toast'

const shortToastDuration = 5000
const longToastDuration = 10000

export type ApiErrorToastPayload = {
  title: string
  message?: string
  type?: TypeOptions
  hideSubtext?: boolean
  skipToast?: boolean
}

const defaultToastConfig = {
  autoClose: shortToastDuration,
  hideProgressBar: true,
  newestOnTop: true,
  transition: Slide,
}

const successConfig = {
  ...defaultToastConfig,
  type: 'success' as TypeOptions,
  icon: <GreenCheckCircleIcon color={Colors.green} />,
}

const infoConfig = {
  ...defaultToastConfig,
  type: 'info' as TypeOptions,
  icon: <InfoIcon color={Colors.primary600} />,
}

const warnConfig = {
  ...defaultToastConfig,
  type: 'warn' as TypeOptions,
  icon: <InfoIcon color={Colors.warning500} />,
}

const errorConfig = {
  ...defaultToastConfig,
  autoClose: longToastDuration,
  type: 'error' as TypeOptions,
  icon: <InfoIcon color={Colors.error700} />,
}

export const infoToast = (title: string, subtitle?: string) =>
  toast(<GenericToast title={title} subtitle={subtitle} />, infoConfig)

export const errorToast = (title: string, subtitle?: string) =>
  toast(<GenericToast title={title} subtitle={subtitle} />, errorConfig)

export const successToast = (title: string, subtitle?: string) =>
  toast(<GenericToast title={title} subtitle={subtitle} />, successConfig)

export const warnToast = ({ title, subtitle, toastId }: { title: string; subtitle?: string; toastId?: string }) =>
  toast(<GenericToast title={title} subtitle={subtitle} />, { ...warnConfig, toastId })

export const addChildDataSourcesToast = () => toast(<GenericToast title="Data source shared" />, successConfig)

export const copyTextToast = () => toast(<GenericToast title="Copied" />, successConfig)

export const copyTextFailedToast = (error: string) => toast(<GenericToast title={error} />, errorConfig)

export const updateDataSourceToast = (isUpdate: boolean) => {
  const title = isUpdate ? 'Data source updated' : 'Data source added'
  toast(<GenericToast title={title} />, successConfig)
}

export const deleteDataSourceToast = () => toast(<GenericToast title={'Data source deleted'} />, successConfig)

export const addSyncToast = () => toast(<GenericToast title="Visualizations added" />, successConfig)
export const updateSyncToast = () => toast(<GenericToast title="Visualization updated" />, successConfig)
export const duplicateSyncToast = () => toast(<GenericToast title="Visualization duplicated" />, successConfig)
export const changeSyncFiltersToast = () => toast(<GenericToast title="Filters changed" />, successConfig)

export const deleteSyncToast = (multiple: boolean = false) =>
  toast(<GenericToast title={`Visualization${multiple ? 's' : ''} deleted`} />, successConfig)

export const deleteSlideToast = (multiple: boolean = false) =>
  toast(<GenericToast title={`Slide${multiple ? 's' : ''} deleted`} />, successConfig)

export const updateOrgNameToast = () => toast(<GenericToast title="Organization renamed" />, successConfig)
export const updateOrgSlugToast = () => toast(<GenericToast title="Organization slug updated" />, successConfig)

export const settingsSavedToast = () => toast(<GenericToast title="Settings saved" />, successConfig)

export const addMemberToOrgToast = () => toast(<GenericToast title="Invite sent" />, successConfig)

export const refreshFiltersToast = () => toast(<GenericToast title="Filters updated" />, successConfig)

export const removeMemberFromOrgToast = () =>
  toast(<GenericToast title="Member removed from organization" />, successConfig)

export const removeInviteFromOrgToast = () => toast(<GenericToast title="Invite deleted" />, successConfig)

export const renameDestinationToast = (destinationType: string) =>
  toast(
    <GenericToast
      title={`${destinationType === EDestinationType.GoogleSlides ? 'Presentation' : 'Document'} renamed`}
    />,
    successConfig
  )

export const duplicateDestinationToast = (destinationType: string) =>
  toast(
    <GenericToast
      title={`${destinationType === EDestinationType.GoogleSlides ? 'Presentation' : 'Document'} duplicated`}
    />,
    successConfig
  )

export const deleteDestinationToast = (destinationType: string) =>
  toast(
    <GenericToast
      title={`${destinationType === EDestinationType.GoogleSlides ? 'Presentation' : 'Document'} deleted`}
    />,
    successConfig
  )

export const deleteCollectionToast = () => toast(<GenericToast title="Collection deleted" />, successConfig)

export const dataOutOfDateToast = ({
  title,
  message,
  handleDataRefresh,
}: {
  title?: string
  message?: string
  handleDataRefresh: Function
}) => {
  const toastId = toast(
    <DataOutOfDateToast
      title={title}
      message={message}
      handleDataRefresh={() => {
        handleDataRefresh()
        toast.dismiss(toastId)
      }}
    />,
    {
      ...errorConfig,
      autoClose: false,
    }
  )
  return toastId
}

export const apiErrorToast = ({ title, message, type, hideSubtext }: ApiErrorToastPayload) => {
  toast(<ApiErrorToast title={title} message={message} type={type} hideSubtext={hideSubtext} />, {
    ...errorConfig,
    toastId: `${type}__${title}__${message}`,
  })
}

export const presentationCancelledToast = () => {
  toast(<GenericToast title="Presentation update cancelled" />, warnConfig)
}

export const allUpdatesCancelledToast = () => {
  toast(<GenericToast title="All updates cancelled" />, warnConfig)
}

export const addVariantsCancelledToast = () => {
  toast(<GenericToast title="All variants cancelled" />, warnConfig)
}

export const presentationSlidesThumbnailsToast = (subtitle = '') => {
  toast(<GenericToast title="We could not get presentation slide previews" subtitle={subtitle} />, warnConfig)
}

export const emailSentToast = (title = 'Email was sent successfully', subtitle = '') => {
  toast(<GenericToast title={title} subtitle={subtitle} />, successConfig)
}

export const drivePickerWrongFileTypeToast = (filetype: string) => {
  toast(
    <GenericToast title={`File type ${filetype} is not supported yet`} subtitle="Please select another file" />,
    errorConfig
  )
}

export const batchApplyFilterPartialFailureToast = (failedSyncNames: string[]) => {
  toast(
    <GenericToast
      title="We could not apply the filters to some syncs"
      subtitle={`Please check the following syncs: ${failedSyncNames.join(', ')}`}
    />,
    warnConfig
  )
}

export const selectVisualisationToast = (name: string) => {
  toast(<GenericToast title="Visualization selected" subtitle={name} />, successConfig)
}

export const updateMetabaseTabForbiddenToast = () => {
  toast(
    <GenericToast
      title="Update tab not allowed"
      subtitle={'Updating a metabase tab is not allowed when modifying filters'}
    />,
    errorConfig
  )
}

export const oneDrivePickerErrorToast = (subtitle: string) => {
  toast(<GenericToast title="OneDrive picker error" subtitle={subtitle} />, errorConfig)
}

export const authorizeDriveToast = ({ type, success }: { type: OAuthType | null; success: boolean }) => {
  type &&
    toast(
      <GenericToast
        title={success ? `You can now select a document` : `Something went wrong when authorizing your ${type} account`}
      />,
      success ? successConfig : errorConfig
    )
}

export const incorrectFileSelectedToast = ({ itemName }: { itemName?: string }) => {
  const defaultSubtitle = "If you can't find the file that Rollstack requests please reach out to the support team."
  const subTitle = itemName
    ? `Please make sure you select the file with the name: ${itemName}. ${defaultSubtitle}`
    : defaultSubtitle
  toast(<GenericToast title="Incorrect file selected" subtitle={subTitle} />, errorConfig)
}

export const renameCollectionToast = () => toast(<GenericToast title={'Collection renamed'} />, successConfig)

export const oAuthConnectedToast = ({ type }: { type: OAuthType }) => {
  toast(<GenericToast title={`Connected to ${getDriveLongName(type)}`} />, successConfig)
}

export const oAuthMissingToast = ({ type, toastId }: { type: OAuthType; toastId: string }) => {
  toast(<OAuthMissingToast type={type} />, {
    ...warnConfig,
    toastId: toastId,
  })
}

export const oAuthMissingNonOwnerToast = ({ type, resource }: { type: OAuthType; resource?: RollstackResource }) => {
  toast(
    <GenericToast
      title={`Authorization failed`}
      subtitle={`The ${resource ?? 'Resource'} owner needs to reauthorize Rollstack’s ${startCase(type)} drive access`}
    />,
    errorConfig
  )
}

export const googleItemNotFoundNonOwnerToast = ({
  type,
  resource,
}: {
  type: OAuthType
  resource?: RollstackResource
}) => {
  toast(
    <GenericToast
      title={'File access failed'}
      subtitle={`The file you are trying to access is not found, or the ${resource ?? 'Resource'} owner needs to reauthorize Rollstack’s access to the file in ${type} drive`}
    />,
    errorConfig
  )
}

export const insufficientPermissionToast = ({ type, message }: { type: OAuthType; message?: string }) => {
  toast(
    <GenericToast
      title={`You do not have sufficient permission on this file`}
      subtitle={message || `Please make sure you have the correct permissions in your ${startCase(type)} drive.`}
    />,
    errorConfig
  )
}

export const insufficientPermissionNonOwnerToast = ({
  type,
  resource,
}: {
  type: OAuthType
  resource?: RollstackResource
}) => {
  toast(
    <GenericToast
      title={`The ${resource ?? 'Resource'} owner does not have sufficient permission on this file`}
      subtitle={`Please contact the owner to ensure they have the correct permissions in ${startCase(type)} drive.`}
    />,
    errorConfig
  )
}

export const microsoftFileNotFoundToast = ({ type }: { type: OAuthType }) => {
  toast(
    <GenericToast
      title={'File not found'}
      subtitle={`The file you are trying to access is not found, or you don't have access to it, in your ${startCase(type)} account`}
    />,
    errorConfig
  )
}

export const microsoftFileNotFoundNonOwnerToast = ({
  type,
  resource,
}: {
  type: OAuthType
  resource?: RollstackResource
}) => {
  toast(
    <GenericToast
      title={'File not found'}
      subtitle={`The file you are trying to access is not found, or the ${resource ?? 'Resource'} owner does not have access to it, in your ${startCase(type)} account`}
    />,
    errorConfig
  )
}

export const lookerErrorToast = () => {
  toast(
    <GenericToast
      title={'Your Looker session was interrupted or has expired.'}
      subtitle={
        'Please do not log into your Looker instance while using Rollstack. Open another visualization or refresh the page.'
      }
    />,
    errorConfig
  )
}

export const tableauErrorGetValidVariantsToast = ({ subtitle }: { subtitle?: React.ReactNode }) => {
  toast(
    <GenericToast title={'Error getting valid variants from the tableau worksheet'} subtitle={subtitle} />,
    errorConfig
  )
}

export const sharingFailedInDriveToast = (
  subTitle = "We couldn't update the sharing permissions in your Drive. Please check manually, to make sure the updates are applied."
) => {
  toast(<GenericToast title="Failed to update sharing permissions in your Drive." subtitle={subTitle} />, warnConfig)
}

export const failedPermissionCheckToast = (
  subTitle = "We couldn't check if you have the correct permissions in your Drive. Updates and other operations might not work as expected. Please make sure you have write access to the file."
) => {
  toast(<GenericToast title="Failed to check the permissions in your Drive." subtitle={subTitle} />, warnConfig)
}

export const tableauStoryWarningToast = () => {
  toast(
    <GenericToast
      title="Tableau Stories are not supported yet."
      subtitle="Please use the associated view directly. For assistance, please contact our support team."
    />,
    warnConfig
  )
}

export const emailSettingsToast = () => toast(<GenericToast title="Email configuration saved" />, successConfig)

export const transferSyncSuccessToast = () => toast(<GenericToast title="Visualization transferred" />, successConfig)

export const transferSyncCredentialsErrorToast = () => {
  toast(
    <GenericToast
      title="Transfer visualization's ownership failed"
      subtitle="You don't have access to credentials required for this visualization transfer."
    />,
    errorConfig
  )
}

export const duplicateDestinationPartialSuccessToast = (failedVisNames: string[]) => {
  toast(
    <GenericToast
      title="We could not duplicate all the visualization from the selected destination"
      subtitle={`The following visualization will be missing from the duplicated destination: ${failedVisNames.join(', ')}. Please check, and add them manually if needed.`}
    />,
    warnConfig
  )
}

export const duplicateDestinationAfterAuthorizeFileToast = () => {
  toast(<GenericToast title={'Now you can duplicate the destination'} />, successConfig)
}
