import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import {
  useAddParentTableauDataSource,
  useGetTableauDataSource,
  useUpdateTableauDataSource,
} from '@features/data-sources/api'
import { ITableauDataSource, TableauCredentials } from '@features/data-sources/types'
import { IDataSourceValidationErrors } from '@features/data-sources/types/types'
import { cleanUrl } from '@features/data-sources/utils'
import { isPublicTableau, useTableauScript } from '@features/embedding'
import { validateURLAndGetError } from '@features/embedding/shared/utils'

import { updateDataSourceToast } from '@shared/components'
import { useBoolean, useStateReducer } from '@shared/hooks'
import { parseDuration } from '@shared/utils/time'

import { hasNoValidationErrors } from './utils'

const initialState: TableauCredentials & Pick<ITableauDataSource, 'id' | 'name' | 'autoProvision'> = {
  id: '',
  name: '',
  baseUrl: '',
  siteName: '',
  username: '',
  clientId: '',
  secretId: '',
  secretValue: '',
  autoProvision: true,
}

const FIELDS_TO_INVALIDATE_DATA_SOURCE = ['baseUrl', 'siteName', 'clientId', 'secretId', 'secretValue'] as const

export const useTableauDataSourcesSettings = () => {
  const { sourceId } = useParams()
  const navigate = useNavigate()
  const [isUpdate, setIsUpdate] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [state, updateState] = useStateReducer(initialState)
  const [validationErrors, setValidationErrors] = useState<IDataSourceValidationErrors>({})
  const [testModal, toggleTestModal] = useBoolean(false)

  useTableauScript(state?.baseUrl)

  const addDataSourceQuery = useAddParentTableauDataSource()
  const updateDataSourceQuery = useUpdateTableauDataSource()
  const { data: dataSource, isLoading: getDataSourceIsLoading } = useGetTableauDataSource(sourceId)
  const needToRetest = useMemo(
    () =>
      state.id && dataSource
        ? FIELDS_TO_INVALIDATE_DATA_SOURCE.some(field => state[field] !== dataSource[field])
        : true,
    [state, dataSource]
  )

  const isLoading = updateDataSourceQuery.isLoading || addDataSourceQuery.isLoading || getDataSourceIsLoading

  const isFormValid = useMemo(() => {
    const isPublicFormValid = state.name && state.baseUrl

    const noErrors = hasNoValidationErrors(validationErrors)
    if (isPublicTableau(state.baseUrl)) {
      return isPublicFormValid && noErrors
    } else {
      return isPublicFormValid && state.clientId && state.secretId && state.secretValue && noErrors
    }
  }, [state, validationErrors])

  const handleBack = () => navigate(-1)

  const toggleAutoProvision = () => updateState({ autoProvision: !state.autoProvision })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    updateState({ [name]: value })

    if (name === 'baseUrl') {
      const validationError = validateURLAndGetError(value)
      setValidationErrors(err => ({
        ...err,
        baseUrlError: validationError,
      }))
      if (timer) {
        clearTimeout(timer)
      }
      setTimer(
        setTimeout(() => {
          if (!validationError) {
            const rootUrl = cleanUrl(value)
            updateState({ [name]: rootUrl })
          }
        }, parseDuration('2s'))
      )
    }
  }

  const handleSave = async () => {
    if (isUpdate && state.id) {
      await updateDataSourceQuery.mutateAsync({
        ...state,
        id: state.id,
      })
      updateDataSourceToast(isUpdate)
    } else {
      await addDataSourceQuery.mutateAsync(state)
      updateDataSourceToast(isUpdate)
    }
    navigate(Paths.DATA_SOURCES)
  }

  const handleConfirm = () => {
    if (needToRetest) {
      toggleTestModal()
    } else {
      handleSave()
    }
  }

  useEffect(() => {
    if (state.id) {
      setIsUpdate(true)
    }
  }, [state.id])

  useEffect(() => {
    if (dataSource) {
      updateState(dataSource)
    }
  }, [dataSource, updateState])

  return {
    state,
    isLoading,
    isUpdate,
    testModal,
    isFormValid,
    validationErrors,
    toggleTestModal,
    handleBack,
    handleConfirm,
    handleInputChange,
    toggleAutoProvision,
  }
}
