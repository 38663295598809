import { KeyIcon } from '@assets'

import { MenuItem } from '@shared/components/menu-item/menu-item'

export const AuthorizeItem = ({
  disabled = false,
  onClick,
  visible,
}: {
  disabled?: boolean
  onClick?: () => void
  showMicrosoftTooltip?: boolean
  visible: boolean
}) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick} visible={visible}>
      <KeyIcon />
      Authorize
    </MenuItem>
  )
}
