import { useState } from 'react'

import { useGetCollections, useGetSharedCollections } from '@features/collections/api/api'
import { isEmailDestination, useGetDestinations, useGetSharedDestinations } from '@features/destinations'
import { useAuthStore } from '@features/users'

import { useBoolean } from '@shared/hooks/use-boolean'
import { useFilter } from '@shared/hooks/use-filter'
import { useSort } from '@shared/hooks/use-sort'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

export const useCollections = () => {
  const { user } = useAuthStore()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchDestQuery, setSearchDestQuery] = useState('')
  const [addCollectionModal, toggleAddCollection] = useBoolean()
  const [collectionExplainerModal, setCollectionExplainerModal] = useState<boolean>(
    user?.showCollectionExplainerModal || false
  )

  const { data: personalCollections, isLoading: personalCollectionsLoading } = useGetCollections()
  const { data: sharedCollections, isLoading: sharedCollectionsLoading } = useGetSharedCollections()
  const { data: destinations } = useGetDestinations()
  const { data: sharedDestinations } = useGetSharedDestinations()

  const isEnabledForEmailDestinations = isFeatureEnabled('emails-as-a-destination-collections')

  const filesForPicker = [...(destinations || []), ...(sharedDestinations || [])].filter(dest => {
    const isNotEmailDestinationOrIsEnabled = !isEmailDestination(dest) || isEnabledForEmailDestinations
    return (
      isNotEmailDestinationOrIsEnabled &&
      (searchDestQuery ? dest.name.toLowerCase().includes(searchDestQuery.toLowerCase()) : true)
    )
  })

  const { sortingOption, isAscendingOrder, handleSortTypeChange, handleSortOrderChange } = useSort(
    'collectionsSortingType',
    'isCollectionsAscendingOrder'
  )
  const { filteringOption, handleFilterTypeChange } = useFilter('collectionsFilteringType')

  const collections =
    personalCollections && sharedCollections ? [...personalCollections, ...sharedCollections] : undefined
  const isLoading = personalCollectionsLoading || sharedCollectionsLoading

  let sortedAndFiltered = collections
    ? collections
        .filter(collection => collection.name.toLowerCase()?.includes(searchQuery.toLowerCase()))
        .filter(filteringOption.appliedFilter(user!))
        .sort(sortingOption.sorter)
    : []

  if (isAscendingOrder!) sortedAndFiltered = sortedAndFiltered.reverse()

  return {
    isLoading,
    collections: sortedAndFiltered,
    sortingOption,
    isAscendingOrder,
    filteringOption,
    addCollectionModal,
    collectionExplainerModal,
    searchDestQuery,
    setSearchDestQuery,
    filesForPicker,
    toggleAddCollection,
    setCollectionExplainerModal,
    setSearchQuery,
    handleSortTypeChange,
    handleSortOrderChange,
    handleFilterTypeChange,
  }
}
