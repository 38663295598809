import { FC } from 'react'

import { IIcon } from '@assets/types'

import styles from './menu.module.css'

export const MenuHorizontalIcon: FC<IIcon> = ({ onClick }) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" onClick={onClick} className={styles.menu}>
      <path
        d="M10.8281 11.8477C11.2963 11.8477 11.6758 11.4681 11.6758 11C11.6758 10.5319 11.2963 10.1523 10.8281 10.1523C10.36 10.1523 9.98047 10.5319 9.98047 11C9.98047 11.4681 10.36 11.8477 10.8281 11.8477Z"
        stroke="currentColor"
        strokeWidth="1.78008"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7617 11.8477C17.2299 11.8477 17.6094 11.4681 17.6094 11C17.6094 10.5319 17.2299 10.1523 16.7617 10.1523C16.2936 10.1523 15.9141 10.5319 15.9141 11C15.9141 11.4681 16.2936 11.8477 16.7617 11.8477Z"
        stroke="currentColor"
        strokeWidth="1.78008"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.89453 11.8477C5.36268 11.8477 5.74219 11.4681 5.74219 11C5.74219 10.5319 5.36268 10.1523 4.89453 10.1523C4.42638 10.1523 4.04688 10.5319 4.04688 11C4.04688 11.4681 4.42638 11.8477 4.89453 11.8477Z"
        stroke="currentColor"
        strokeWidth="1.78008"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
