import { Tooltip } from '@shared/components'
import { useMemo } from 'react'

const TWENTY_CHARACTERS = 20

export const TextPreview: React.FC<{
  text?: string | null
}> = ({
  text,
}) => {
  const ellipsisText = useMemo(() => {
    if (text && text.length > TWENTY_CHARACTERS) {
      return text.slice(0, TWENTY_CHARACTERS) + '...'
    }
    return text
  }, [text])
  
  return (
    <div className="w-full h-full flex items-center justify-center text-xl text-black bg-transparent">
      <Tooltip title={text ?? "N/A"}>
        {ellipsisText ?? "N/A"}
      </Tooltip>
    </div>
  )
} 