import { Destination } from '@features/destinations/types'
import { ViewIdOptions } from '@features/drive/google-drive/types'

export abstract class AbstractDestinationHandler {
  protected presentation: Destination | null

  constructor(presentation: Destination | null) {
    this.presentation = presentation
  }

  abstract getTitle(): string
  abstract getTitlePlural(): string
  abstract getCloudAppTitle(): string
  abstract getCloudTitle(): string
  abstract getShortNamePlural(): string
  abstract getDestinationTitle(isFileDeleted?: boolean): string
  abstract getDestinationUrl(): string | null
  abstract getGroupData(slideIndex: number): {}
  abstract getGPickerCustomViews(): string[]
  abstract getGPickerViewId(): ViewIdOptions
  abstract getSmallIcon(size?: number, className?: string): JSX.Element
  abstract getPreview(itemsInPageCount?: number, width?: number, height?: number): JSX.Element | null
  abstract getAddVizStep1(): { title: string; subtitle: string } | null
  abstract getDateDelimiter(): string
}
