import { GDocsIcon, GSlidesIcon, MsPowerPointIcon, MsWordIcon } from '@assets'

import { EDestinationType } from '@features/destinations/types'
import { GDocsBaseURL } from '@features/embedding'
import { GSlidesBaseURL } from '@features/embedding/google/g-slides/g-slides'

import { Button } from '@shared/components/button/button'

type ActionType = 'Update' | 'Duplicate' | 'Rename'

interface IBuildModalProps {
  action: ActionType
  destinationType?: EDestinationType
  docId?: string
}

export const buildModalProps = ({ action, destinationType, docId }: IBuildModalProps) => {
  if (!destinationType) return {}

  const loadingLabel: { [key in ActionType]: string } = {
    Update: 'Updating',
    Duplicate: 'Duplicating',
    Rename: 'Renaming',
  }

  const finishedLabel: { [key in ActionType]: string } = {
    Update: 'updated',
    Duplicate: 'duplicated',
    Rename: 'renamed',
  }

  switch (destinationType) {
    case EDestinationType.GoogleSlides:
      return {
        modalUpdatingTitle: `${loadingLabel[action]} the presentation`,
        modalTitle: `Presentation ${finishedLabel[action]}!`,
        modalSubtitle: 'You can follow the link to open your presentation or close this dialog.',
        linkToDestination: (
          <a href={`${GSlidesBaseURL}${docId}`} target="_blank" rel="noopener noreferrer">
            <Button iconLeading={<GSlidesIcon />} text="Open in Slides" />
          </a>
        ),
      }

    case EDestinationType.GoogleDocs:
      return {
        modalUpdatingTitle: `${loadingLabel[action]} the document`,
        modalTitle: `Document ${finishedLabel[action]}!`,
        modalSubtitle: 'You can follow the link to open your document or close this dialog.',
        linkToDestination: (
          <a href={`${GDocsBaseURL}${docId}`} target="_blank" rel="noopener noreferrer">
            <Button iconLeading={<GDocsIcon />} text="Open in Docs" />
          </a>
        ),
      }

    case EDestinationType.MicrosoftPowerPoint:
      return {
        modalUpdatingTitle: `${loadingLabel[action]} the presentation`,
        modalTitle: `Presentation ${finishedLabel[action]}!`,
        modalSubtitle: 'You can follow the link to open your presentation or close this dialog.',
        linkToDestination: (
          <a
            href={`https://office.live.com/start/PowerPoint.aspx?auth=1&nf=1&ctsi=${docId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button iconLeading={<MsPowerPointIcon />} text="Open in PowerPoint" />
          </a>
        ),
      }

    case EDestinationType.MicrosoftWord:
      return {
        modalUpdatingTitle: `${loadingLabel[action]} the document`,
        modalTitle: `Document ${finishedLabel[action]}!`,
        modalSubtitle: 'You can follow the link to open your document or close this dialog.',
        linkToDestination: (
          <a
            href={`https://office.live.com/start/Word.aspx?auth=1&nf=1&ctsi=${docId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button iconLeading={<MsWordIcon />} text="Open in Word" />
          </a>
        ),
      }

    case EDestinationType.Email:
      return {
        modalUpdatingTitle: `${loadingLabel[action]} the email`,
        modalTitle: `Email ${finishedLabel[action]}!`,
      }
  }
}
