import { useContext, useEffect, useState } from 'react'

import { useGetCollectionDriveData } from '@features/collections'
import { useUpdateCollection } from '@features/collections/api/api'
import { EVariantNaming, ICollection } from '@features/collections/types'
import { useDestinationConfiguration } from '@features/destinations'
import { useDestinationRights } from '@features/destinations'
import { EDestinationType, IDriveData } from '@features/destinations/types'
import { FreeTrialContext } from '@features/free-trial/free-trial-provider'
import { FilePickerFolderType } from '@features/users/providers/file-picker-provider'

import { settingsSavedToast } from '@shared/components'
import { useBoolean } from '@shared/hooks/use-boolean'

interface UseCollectionDataSettingsProps {
  collection: ICollection
  itemUpdatedAt: string
}

export const useCollectionSettings = ({ collection, itemUpdatedAt }: UseCollectionDataSettingsProps) => {
  const { freeTrial } = useContext(FreeTrialContext)
  const templateDestination = collection.collectionVariantTemplate.destination
  const { owner } = useDestinationRights(templateDestination)
  const updateCollectionQuery = useUpdateCollection()
  const destinationConfiguration = useDestinationConfiguration({
    destination: templateDestination,
    itemUpdatedAt,
  })
  const [variantNaming, setVariantNaming] = useState(collection?.variantNaming ?? EVariantNaming.sameName)
  const [confirmModal, toggleConfirmModal] = useBoolean(false)

  const { data: collectionDriveData, isLoading } = useGetCollectionDriveData({
    collectionId: collection.id,
    enabled: !!owner,
    itemId: collection.parentFolderId,
    itemName: collection.name,
    type:
      collection.type === EDestinationType.GoogleDocs || collection.type === EDestinationType.GoogleSlides
        ? FilePickerFolderType.GoogleFolder
        : FilePickerFolderType.MicrosoftFolder,
    onSuccess: (driveData: IDriveData) => {
      destinationConfiguration.setSelectedParentFolder(driveData)
    },
  })

  useEffect(() => {
    if (collectionDriveData) {
      destinationConfiguration.setSelectedParentFolder(collectionDriveData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateConfiguration = async () => {
    if (!templateDestination) {
      return
    }

    const updatedDestination = {
      id: collection.id,
      datestampSuffix: destinationConfiguration.suffix,
      updateMethod: destinationConfiguration.updateMethod,
      updatedAt: itemUpdatedAt,
      variantNaming,
      parentFolderId: destinationConfiguration.selectedParentFolder?.folderId,
      driveId: destinationConfiguration.selectedParentFolder?.driveId,
      linkOption: destinationConfiguration.linkOption,
      cropOption: destinationConfiguration.cropOption,
      updateSchedules: destinationConfiguration.getScheduleProps(),
    }

    await updateCollectionQuery.mutateAsync(updatedDestination)
    settingsSavedToast()
  }

  return {
    ...destinationConfiguration,
    isLoading,
    freeTrial,
    confirmModal,
    toggleConfirmModal,
    owner,
    isSubmitting: updateCollectionQuery.isLoading,
    variantNaming,
    setVariantNaming,
    updateConfiguration,
  }
}
