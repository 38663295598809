import { ReactNode, useEffect, useMemo, useState } from 'react'

import { ArrowLeft } from '@assets'

import { Button, Spinner } from '@shared/components'

import { Folder } from './folder/folder'

import styles from './file-picker.module.css'

export interface FileComponentProps<TFile> {
  file: TFile
  folderName?: string
}

interface FilePickerProps<TFile, TFolder> {
  FileComponent: ({ file, folderName }: FileComponentProps<TFile>) => ReactNode
  files?: TFile[]
  folders?: TFolder[]
  isLoading?: boolean
  searchQuery?: string
  getFileName: (file: TFile) => string
  getFileFolderId?: (file: TFile) => string
  getFolderName?: (folder: TFolder) => string
  getFolderId?: (folder: TFolder) => string
  getParentFolderId?: (folder: TFolder) => string | undefined
  setFolderId?: (folderId: string) => void
}

export const rootFolderId = 'root'

export const FilePicker = <TFile, TFolder>({
  files,
  folders = [],
  isLoading,
  searchQuery,
  FileComponent,
  getFileName,
  getFileFolderId = () => rootFolderId,
  getFolderId = () => '',
  getFolderName = () => '',
  getParentFolderId = () => undefined,
  setFolderId,
}: FilePickerProps<TFile, TFolder>) => {
  const [currentFolderId, setCurrentFolderId] = useState<string>(rootFolderId)

  const filteredFiles = useMemo(
    () =>
      files?.filter(file => {
        const fileNameLowercase = getFileName(file).toLowerCase()
        return searchQuery ? fileNameLowercase.includes(searchQuery.toLowerCase()) : true
      }) ?? [],
    [files, searchQuery, getFileName]
  )

  const currentFiles = useMemo(
    () => filteredFiles.filter(file => (Boolean(searchQuery) ? true : currentFolderId === getFileFolderId(file))),
    [currentFolderId, filteredFiles, searchQuery, getFileFolderId]
  )

  const currentFolders = useMemo(
    () => folders.filter(folder => getParentFolderId(folder) === currentFolderId),
    [currentFolderId, folders, getParentFolderId]
  )

  const onBackClick = () => {
    const parentFolder = folders.find(folder => getFolderId(folder) === currentFolderId)
    parentFolder && setCurrentFolderId(getParentFolderId(parentFolder) || 'root')
  }

  useEffect(() => {
    setFolderId?.(currentFolderId)
  }, [currentFolderId, setFolderId])

  if (!files && !folders.length) return null
  if (isLoading) return <Spinner />

  return (
    <div className={styles.wrapper}>
      {folders.length > 0 && (
        <Button
          onClick={onBackClick}
          secondaryColor
          iconLeading={<ArrowLeft />}
          disabled={Boolean(searchQuery) || currentFolderId === 'root'}
        />
      )}

      {!currentFiles.length && (!currentFolders.length || searchQuery) && (
        <div className={styles.noResults}>No results found</div>
      )}

      <div className={styles.container}>
        {!searchQuery &&
          currentFolders.map(folder => (
            <Folder
              key={getFolderId(folder)}
              folder={folder}
              getFolderId={getFolderId}
              getFolderName={getFolderName}
              setCurrentFolderId={setCurrentFolderId}
            />
          ))}

        {currentFiles.map((file, idx) => {
          const folder = folders.find(f => getFolderId(f) === getFileFolderId(file))
          const folderName = folder && getFolderName(folder)

          const key = `${getFileName(file)}-${idx}`
          return <FileComponent key={key} file={file} folderName={folderName} />
        })}
      </div>
    </div>
  )
}
