import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC } from 'react'

import { EDataTestId } from '@tests/constants'

import { DuplicateIcon, ExternalLinkIcon, OpenBookIcon, UpdateIcon } from '@assets'

import { ICollection } from '@features/collections/types'
import { DetailedPreview } from '@features/destinations/components/destination-element/detailed-preview/detailed-preview'
import { useDestination } from '@features/destinations/components/destination-element/use-destination'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import {
  WithDestinationOperationsProps,
  withDestinationOperations,
} from '@features/destinations/hocs/with-destination-operations'
import { Destination } from '@features/destinations/types'
import { getLastSuccessfulUpdate } from '@features/destinations/utils'

import { Button, NewTabLink } from '@shared/components'
import { RelativeDateTime } from '@shared/components/relative-date-time'
import { ThumbnailAndTitle } from '@shared/components/thumbnail-and-title/thumbnail-and-title'

import styles from './template-section.module.css'

dayjs.extend(localizedFormat)

type ITemplateSection = {
  badge?: string
  collection?: ICollection
  template: Destination
  itemsInPageCount: number
  loading?: boolean
  onClickUpdate?: () => void
  setTemplateExplainerModal?: (open: boolean) => void
} & WithDestinationOperationsProps

const RawTemplateSection: FC<ITemplateSection> = ({
  badge,
  collection,
  template,
  itemsInPageCount,
  duplicateOutside,
  updateDestination,
  createVersion,
  setTemplateExplainerModal,
}) => {
  const { isViewer, isUpdateRunning, onUpdateClick } = useDestination({
    destination: template,
    collection,
    updateDestination,
    createVersion,
  })
  const duplicateOutsideClick = () => duplicateOutside(template)

  const { name, isFileDeleted } = template
  const destinationConstruct = destinationHandlers(template)
  const lastSuccessfulUpdate = getLastSuccessfulUpdate(template)

  const handle = destinationHandlers(template)

  const thumbnail = (
    <DetailedPreview
      badge={badge}
      destination={template}
      isInsideCollection={true}
      itemsInPageCount={itemsInPageCount}
    />
  )

  const url = handle.getDestinationUrl()

  return (
    <div className={styles.templateContainer}>
      <ThumbnailAndTitle Thumbnail={thumbnail} TypeIcon={destinationConstruct.getSmallIcon()} title={name} />
      <div className={styles.templateLastUpdate}>
        <span className={styles.templateLastUpdateLabel}>Last full update:</span>
        <span className={styles.templateLastUpdateValue}>
          {lastSuccessfulUpdate ? <RelativeDateTime date={lastSuccessfulUpdate} /> : '--'}
        </span>
      </div>
      <div className={styles.templateLinks}>
        <Button
          linkColor
          text="Update"
          fullWidth={isUpdateRunning}
          iconLeading={<UpdateIcon />}
          onClick={onUpdateClick}
          disabled={isViewer || isFileDeleted}
          loading={isUpdateRunning}
          className={styles.updateButton}
        />
        <Button
          linkColor
          disabled={isViewer || isFileDeleted}
          text="Duplicate as standalone"
          iconLeading={<DuplicateIcon />}
          onClick={duplicateOutsideClick}
        />
        {url && (
          <NewTabLink link={url} data-testid={EDataTestId.THREE_DOTS_ACTION_OPEN + name}>
            <Button linkColor text="Open" iconLeading={<ExternalLinkIcon />} />
          </NewTabLink>
        )}
      </div>
      <div className={styles.templateInfo}>
        Changes made to the template in Rollstack will be reflected in the variants.
        <Button
          iconLeading={<OpenBookIcon />}
          text="Learn more"
          linkColor
          onClick={() => setTemplateExplainerModal && setTemplateExplainerModal(true)}
        />
      </div>
    </div>
  )
}

export const TemplateSection = withDestinationOperations(RawTemplateSection)
