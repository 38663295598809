import { TableauWorkbook } from '@features/data-sources'
import { IntegrationDashboard } from '@features/syncs/types'
import { isTableauDashboard } from '@features/syncs/utils'

export const parsePublicTableauUrl = (workbookUrl: string) => {
  const workbookName = workbookUrl.split('/views/')[1].split('/')[0]
  const workbookContentUrl = workbookName
  const viewName = workbookUrl.split('/views/')[1].split('/')[1]
  const viewId = viewName
  const project = { id: workbookName, name: workbookName }
  const workbook: TableauWorkbook = {
    isDisabled: false,
    id: workbookName,
    name: workbookName,
    contentUrl: workbookContentUrl,
    project,
  }
  const view = { id: viewId, name: viewName, contentUrl: viewName, viewUrlName: viewName }

  return {
    view,
    viewId,
    viewName,
    workbook,
    workbookUrl,
    workbookName,
    workbookContentUrl,
  }
}

export const getSyncNameWithFullDashboard = (dashboard: IntegrationDashboard, viewName?: string) => {
  if (!dashboard) return ''

  if (isTableauDashboard(dashboard)) {
    return viewName ?? ''
  }

  return dashboard.title
}
