import { FC, useDeferredValue, useEffect, useMemo, useState } from 'react'

import { SearchIconMd } from '@assets'

import { isLookerDataSource, isMetabaseDataSource, isTableauDataSource } from '@features/data-sources/utils'
import { SyncCoreDataSource } from '@features/syncs/types'

import { Input, Modal } from '@shared/components'

import { DashboardSelectLooker } from './looker/dashboard-select-looker'
import { DashboardSelectMetabase } from './metabase/dashboard-select-metabase'
import { DashboardSelectTableau } from './tableau/dashboard-select-tableau'

import styles from './dashboard-select.module.css'

interface SyncDashboardProps {
  dataSource: SyncCoreDataSource | null
  disable?: boolean
  visible: boolean
  close: () => void
}

export interface IntegrationDashboardSelect<T> {
  dataSource: T
  searchQuery: string
}

export const DashboardSelectModal: FC<SyncDashboardProps> = ({ dataSource, disable, visible, close }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const deferredQuery = useDeferredValue(searchQuery)

  const pickerView = useMemo(() => {
    if (disable || !dataSource) {
      return null
    }

    if (isLookerDataSource(dataSource)) {
      return <DashboardSelectLooker dataSource={dataSource} searchQuery={deferredQuery} />
    } else if (isMetabaseDataSource(dataSource)) {
      return <DashboardSelectMetabase dataSource={dataSource} searchQuery={deferredQuery} />
    } else if (isTableauDataSource(dataSource)) {
      return <DashboardSelectTableau dataSource={dataSource} searchQuery={deferredQuery} />
    }
  }, [dataSource, disable, deferredQuery])

  useEffect(() => {
    if (!visible) setSearchQuery('')
  }, [visible])

  if (!dataSource) return null

  return (
    <Modal open visible={visible} onBackgroundClick={close} className={styles.container}>
      <div className={styles.topContainer}>
        <span className="text-lg-semibold">Select a dashboard</span>
        <Input
          autoFocus
          icon={<SearchIconMd />}
          key={visible.toString()}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search dashboard by name"
          value={searchQuery}
        />
      </div>
      {pickerView}
    </Modal>
  )
}
