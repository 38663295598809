import { FC, useCallback } from 'react'

import { ISheetInfo } from '@features/data-sources/types'
import { EDataSourceType } from '@features/data-sources/types/types'
import { DashboardSelectModal } from '@features/syncs/components/sync-dashboard-select'
import { SelectDataSource } from '@features/syncs/components/sync-data-source/select-data-source'
import { SelectSheet } from '@features/syncs/components/sync-data-source/select-sheets'
import { Section } from '@features/syncs/components/sync-settings/section/section'
import { useSyncSettingsContext } from '@features/syncs/sync-settings-provider'
import { SyncCoreDataSource } from '@features/syncs/types'
import { getIntegrationDashboardName } from '@features/syncs/utils'

import { Button, InputButton } from '@shared/components'

import styles from './data-source-select.module.css'

interface Props {
  index: number
}

export const DataSourceSelect: FC<Props> = ({ index }) => {
  const {
    showDashboardModal,
    dashboardSelectEnabled,
    isNewSync,
    sheetSelected,
    dashboardSelected,
    dataSource,
    updateVanityData,
    updateSyncData,
  } = useSyncSettingsContext()

  const closeDashboardModal = () => updateVanityData({ showDashboardModal: false })

  const openDashboardModal = () => {
    if (!dataSource || !dashboardSelectEnabled) return

    updateVanityData({ showDashboardModal: true })
  }

  const selectDS = useCallback(
    async (dataSource: SyncCoreDataSource | null) => {
      await updateSyncData({ dataSource, dashboardSelected: null, sheetSelected: null, cellRanges: null })
    },
    [updateSyncData]
  )

  const selectSheet = useCallback(
    async (sheetInfo: ISheetInfo | null) => {
      await updateSyncData({ sheetSelected: sheetInfo })
    },
    [updateSyncData]
  )

  return (
    <>
      <Section
        title={`${index}) Data source`}
        subtitle="Select the data source and the dashboard containing your visualization"
        className="w-[500px]"
      >
        <div className={styles.container}>
          <SelectDataSource dataSource={dataSource} handleDSChange={selectDS} disabled={!isNewSync} />

          {dataSource?.type && dataSource.type !== EDataSourceType.GoogleSheets && (
            <InputButton
              label="Dashboard"
              value={getIntegrationDashboardName(dashboardSelected)}
              inactive={!dataSource}
              nonEditable={!isNewSync}
              placeholder="No dashboard selected"
              onClick={openDashboardModal}
            >
              {isNewSync && (
                <Button
                  text={dashboardSelected ? 'Modify' : 'Select'}
                  className={styles.selectBtn}
                  dataTestId="dashboard"
                  tertiaryColor={Boolean(dashboardSelected)}
                />
              )}
            </InputButton>
          )}
          {dataSource?.type === EDataSourceType.GoogleSheets && dataSource?.id && (
            <SelectSheet
              sheet={sheetSelected}
              spreadsheetId={dataSource?.id}
              handleSheetChange={selectSheet}
              disabled={!isNewSync}
            />
          )}
        </div>
      </Section>

      <DashboardSelectModal
        disable={!isNewSync}
        visible={showDashboardModal}
        dataSource={dataSource}
        close={closeDashboardModal}
      />
    </>
  )
}
