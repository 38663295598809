import { User } from '@features/users'

import { MicrosoftOAuthScopes } from './types'

export const userHasOneDriveScope = (user?: User) => {
  if (!user) return false
  const oauth = user.microsoftOAuth
  return oauth && oauth.scopes.toLocaleLowerCase().includes(MicrosoftOAuthScopes.DEFAULT)
}

export const MICROSOFT_DRIVE_SCOPES = [MicrosoftOAuthScopes.DEFAULT]
