import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 19

export const GDocsLogo: FC<IIcon> = ({ size = defaultSize }) => {
  return (
    <svg width={size} viewBox="0 0 149 31">
      <path
        d="M108.727 7.67844C112.301 7.68841 115.358 9.9503 116.028 13.53C116.723 17.2491 115.228 21.1003 111.459 22.4256C109.434 23.1355 106.305 22.8889 103.879 22.8765C103.806 22.8765 103.77 22.8391 103.77 22.7644L103.807 7.80798C103.807 7.72162 103.849 7.67844 103.934 7.67844C105.532 7.6718 107.129 7.6718 108.727 7.67844ZM114.1 14.5787C113.726 9.74603 109.693 9.40973 105.852 9.5567C105.822 9.55736 105.794 9.56972 105.773 9.59113C105.752 9.61255 105.74 9.64134 105.74 9.6713V20.8687C105.74 20.8995 105.752 20.9292 105.773 20.9515C105.795 20.9738 105.824 20.9869 105.855 20.9882C108.438 21.0854 111.312 21.2548 113.034 18.9904C114 17.7199 114.225 16.1755 114.1 14.5787Z"
        fill="#6F6F6F"
      />
      <path
        d="M122.793 23.2896C122.084 23.2945 121.382 23.1547 120.725 22.8781C120.068 22.6015 119.47 22.1936 118.965 21.6776C118.46 21.1615 118.058 20.5475 117.782 19.8706C117.506 19.1937 117.361 18.4672 117.356 17.7325C117.351 16.9977 117.486 16.2692 117.752 15.5885C118.019 14.9079 118.412 14.2883 118.91 13.7653C119.407 13.2423 120 12.826 120.652 12.5403C121.305 12.2545 122.006 12.1049 122.715 12.0999C123.424 12.095 124.127 12.2348 124.784 12.5114C125.441 12.788 126.039 13.196 126.544 13.712C127.049 14.228 127.45 14.842 127.726 15.5189C128.002 16.1958 128.147 16.9224 128.152 17.6571C128.157 18.3918 128.023 19.1203 127.756 19.801C127.49 20.4817 127.097 21.1012 126.599 21.6242C126.101 22.1473 125.509 22.5635 124.856 22.8493C124.203 23.135 123.502 23.2846 122.793 23.2896ZM122.803 21.4311C123.247 21.4257 123.687 21.3235 124.095 21.1305C124.504 20.9374 124.874 20.6573 125.185 20.306C125.495 19.9548 125.739 19.5393 125.904 19.0833C126.069 18.6273 126.15 18.1397 126.144 17.6484C126.132 16.6561 125.764 15.7089 125.12 15.015C124.476 14.3211 123.61 13.9375 122.711 13.9485C122.266 13.9539 121.827 14.0561 121.418 14.2491C121.009 14.4421 120.639 14.7223 120.329 15.0735C120.019 15.4248 119.774 15.8403 119.61 16.2963C119.445 16.7523 119.363 17.2399 119.369 17.7312C119.381 18.7234 119.75 19.6707 120.394 20.3646C121.038 21.0584 121.904 21.4421 122.803 21.4311Z"
        fill="#6F6F6F"
      />
      <path
        d="M137.807 22.1788C133.468 25.0659 128.346 21.6806 129.325 16.4717C130.242 11.5868 136.893 10.4309 139.09 14.9796C139.099 14.9975 139.104 15.0171 139.105 15.037C139.106 15.057 139.103 15.0769 139.097 15.0957C139.09 15.1145 139.08 15.1318 139.066 15.1465C139.053 15.1612 139.036 15.1731 139.018 15.1814L137.486 15.8664C137.423 15.893 137.376 15.8764 137.344 15.8166C136.763 14.7089 135.949 14.0969 134.903 13.9807C131.191 13.5647 129.886 18.9404 132.711 20.8535C134.41 22.0044 136.437 21.4041 137.414 19.603C137.46 19.5183 137.528 19.4959 137.616 19.5357L139.055 20.1784C139.14 20.2166 139.164 20.2789 139.128 20.3653C138.749 21.2405 138.309 21.845 137.807 22.1788Z"
        fill="#6F6F6F"
      />
      <path
        d="M141.611 17.3262C137.227 13.5074 146.037 9.47435 148.573 14.4366C148.618 14.5229 148.596 14.5844 148.506 14.6209L147.084 15.2138C147.004 15.247 146.938 15.2295 146.884 15.1615C144.58 12.1199 140.599 15.3856 143.492 16.2899C145.649 16.965 149.423 17.2365 148.715 20.6318C148.222 22.9959 145.048 23.6585 143.088 23.0183C141.739 22.5782 140.765 21.7096 140.166 20.4126C140.156 20.3922 140.151 20.3701 140.15 20.3477C140.15 20.3252 140.154 20.3029 140.162 20.2821C140.17 20.2614 140.182 20.2425 140.198 20.2268C140.214 20.2112 140.232 20.1989 140.253 20.1909L141.785 19.5756C141.86 19.5457 141.915 19.5665 141.95 19.6379C142.707 21.199 143.927 21.7653 145.611 21.3368C147.183 20.9382 147.059 19.0425 145.474 18.6938C144.002 18.37 142.518 18.1159 141.611 17.3262Z"
        fill="#6F6F6F"
      />
      <path
        d="M45.5778 9.13794C45.5788 9.14213 45.5804 9.14685 45.5825 9.1521C45.5835 9.1542 45.5757 9.1626 45.5589 9.17729L44.5515 10.1579C44.5262 10.1823 44.4927 10.1961 44.4576 10.1967C44.4226 10.1973 44.3886 10.1846 44.3626 10.1611C41.9574 7.99359 38.2867 8.14627 36.0798 10.5152C33.9359 12.8165 33.9895 16.5597 36.1633 18.8122C38.0333 20.7498 41.0964 21.1434 43.3993 19.8605C44.7141 19.1291 45.5106 17.917 45.7887 16.2244C45.7905 16.2143 45.7901 16.2038 45.7874 16.1939C45.7847 16.1839 45.7798 16.1746 45.773 16.1667C45.7663 16.1587 45.7578 16.1523 45.7483 16.1479C45.7388 16.1434 45.7284 16.1411 45.7179 16.141H40.5203C40.4785 16.141 40.4385 16.1244 40.409 16.0949C40.3795 16.0653 40.3629 16.0253 40.3629 15.9836V14.1372C40.3629 13.9546 40.4536 13.8633 40.6352 13.8633L47.9515 13.857C48.0029 13.8559 48.0317 13.8811 48.038 13.9325C48.3214 16.0135 47.9656 18.4202 46.6009 20.095C43.5 23.8964 37.7074 23.7201 34.3688 20.3516C31.6441 17.6049 31.2065 13.3155 33.3866 10.0824C36.1979 5.91424 42.2659 4.95721 45.9886 8.55868C46.0306 8.59856 46.0316 8.63948 45.9917 8.68145L45.5967 9.09701C45.5799 9.1138 45.5736 9.12744 45.5778 9.13794Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M86.2485 6.93262H84.1581C84.0947 6.93262 84.0432 6.98406 84.0432 7.04752V22.4388C84.0432 22.5022 84.0947 22.5537 84.1581 22.5537H86.2485C86.3119 22.5537 86.3634 22.5022 86.3634 22.4388V7.04752C86.3634 6.98406 86.3119 6.93262 86.2485 6.93262Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M59.5495 16.6287C59.6706 17.3226 59.6536 18.0337 59.4995 18.7213C59.3454 19.4089 59.0571 20.0595 58.6511 20.636C58.2452 21.2125 57.7296 21.7036 57.1336 22.0813C56.5377 22.459 55.8732 22.7158 55.178 22.8371C53.7739 23.0822 52.3303 22.7604 51.1645 21.9426C49.9988 21.1249 49.2064 19.8781 48.9618 18.4765C48.8407 17.7825 48.8577 17.0715 49.0118 16.3839C49.166 15.6963 49.4543 15.0457 49.8602 14.4692C50.2661 13.8927 50.7818 13.4015 51.3777 13.0239C51.9736 12.6462 52.6382 12.3894 53.3334 12.268C54.7374 12.023 56.1811 12.3448 57.3468 13.1625C58.5126 13.9803 59.3049 15.2271 59.5495 16.6287ZM54.278 20.7589C54.6714 20.7561 55.0603 20.6705 55.4226 20.5069C55.7849 20.3433 56.1135 20.105 56.3895 19.8055C56.6656 19.5059 56.8838 19.1511 57.0316 18.7612C57.1794 18.3714 57.254 17.9541 57.251 17.5332C57.2451 16.6833 56.9238 15.8704 56.3579 15.2733C55.792 14.6762 55.0277 14.3439 54.2333 14.3494C53.8399 14.3522 53.451 14.4378 53.0887 14.6014C52.7264 14.765 52.3979 15.0033 52.1218 15.3029C51.8457 15.6024 51.6276 15.9572 51.4797 16.3471C51.3319 16.737 51.2574 17.1542 51.2603 17.5751C51.2662 18.425 51.5875 19.238 52.1534 19.835C52.7194 20.4321 53.4836 20.7644 54.278 20.7589Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M97.1917 20.5498C96.252 22.1554 94.1428 23.018 92.3845 22.8842C88.2447 22.5694 86.2661 18.1195 88.2085 14.6786C89.2993 12.7472 91.651 11.82 93.7744 12.3962C95.561 12.8794 96.7903 14.4078 97.2814 16.1708C97.2972 16.2253 97.2788 16.2636 97.2264 16.2857L90.3744 19.1221C90.3545 19.1304 90.3365 19.1427 90.3216 19.1583C90.3067 19.1739 90.2952 19.1925 90.2879 19.2128C90.2807 19.2332 90.2777 19.2548 90.2794 19.2763C90.281 19.2978 90.2871 19.3188 90.2973 19.3378C91.4117 21.3636 94.0782 21.1244 95.3359 19.3472C95.3716 19.2958 95.4157 19.2874 95.4681 19.3221L97.1681 20.4475C97.1845 20.4582 97.1962 20.4748 97.2006 20.4939C97.205 20.513 97.2018 20.533 97.1917 20.5498ZM94.4245 15.0972C93.8127 14.5883 93.1873 14.3344 92.5482 14.3354C90.9883 14.3386 89.8739 15.7143 89.8235 17.2584C89.823 17.2704 89.8256 17.2824 89.831 17.2931C89.8364 17.3038 89.8444 17.313 89.8544 17.3197C89.8643 17.3265 89.8757 17.3306 89.8877 17.3318C89.8996 17.3329 89.9117 17.3309 89.9227 17.3261C91.3404 16.7385 92.7151 16.1655 94.0467 15.6072C94.1648 15.5585 94.2577 15.4451 94.3663 15.4199C94.3987 15.4126 94.4278 15.396 94.4505 15.3719C94.4731 15.3478 94.4883 15.3172 94.4944 15.2836C94.5005 15.25 94.4971 15.2148 94.4848 15.1818C94.4724 15.1489 94.4516 15.1196 94.4245 15.0972Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M61.9496 21.1937C59.6499 18.6626 60.226 14.3968 63.3568 12.8432C65.9855 11.5383 69.3021 12.3443 70.6683 15.0706C72.1889 18.1054 70.64 21.9477 67.2416 22.7457C65.2551 23.2116 63.5053 22.7174 61.9921 21.2629C61.9774 21.2493 61.9685 21.2357 61.9653 21.222C61.9632 21.2126 61.958 21.2031 61.9496 21.1937ZM65.958 20.7588C66.7519 20.7449 67.5074 20.3938 68.0583 19.7828C68.6092 19.1718 68.9103 18.3509 68.8955 17.5006C68.8881 17.0796 68.8034 16.6641 68.6462 16.2777C68.489 15.8914 68.2623 15.5419 67.9792 15.249C67.696 14.9562 67.3619 14.7258 66.9959 14.571C66.6299 14.4162 66.2392 14.3401 65.8461 14.347C65.0522 14.3608 64.2967 14.7119 63.7458 15.3229C63.1949 15.9339 62.8938 16.7548 62.9086 17.6051C62.916 18.0261 63.0007 18.4416 63.1579 18.828C63.3151 19.2143 63.5418 19.5639 63.825 19.8567C64.1081 20.1495 64.4422 20.3799 64.8082 20.5347C65.1742 20.6895 65.5649 20.7656 65.958 20.7588Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        d="M74.1046 21.6736C70.9722 18.9285 71.8694 13.904 75.7762 12.4732C77.0859 11.9936 78.3588 12.1348 79.5949 12.8966C79.7303 12.9816 79.8861 13.2193 80.0939 13.2964C80.1558 13.3195 80.1868 13.298 80.1868 13.2319V12.6385C80.1868 12.586 80.213 12.5598 80.2655 12.5598H82.3433C82.3989 12.5598 82.4267 12.5876 82.4267 12.6432C82.433 15.869 82.4335 19.0916 82.4283 22.3111C82.4235 24.7132 81.3248 26.8397 78.9448 27.4694C76.4358 28.1336 73.7032 27.1073 72.6407 24.5857C72.6176 24.529 72.6339 24.4891 72.6895 24.466L74.5673 23.6743C74.6356 23.6459 74.687 23.6643 74.7216 23.7294C75.4614 25.1198 76.5328 25.7048 77.9359 25.4845C79.894 25.1759 80.2938 23.4303 80.1773 21.7523C80.1767 21.7459 80.1743 21.7397 80.1704 21.7345C80.1665 21.7294 80.1612 21.7254 80.1551 21.7231C80.149 21.7208 80.1424 21.7203 80.1361 21.7215C80.1297 21.7228 80.1238 21.7258 80.1191 21.7303C78.438 23.4209 75.8061 23.1659 74.1046 21.6736ZM77.4915 20.7685C78.2583 20.7645 78.992 20.4227 79.5311 19.8182C80.0703 19.2138 80.3706 18.3962 80.3662 17.5455C80.3617 16.6947 80.0528 15.8803 79.5074 15.2816C78.962 14.6828 78.2247 14.3487 77.4579 14.3527C76.691 14.3567 75.9573 14.6985 75.4182 15.303C74.8791 15.9074 74.5787 16.7249 74.5831 17.5757C74.5876 18.4265 74.8965 19.2409 75.4419 19.8396C75.9873 20.4384 76.7246 20.7725 77.4915 20.7685Z"
        fill="black"
        fillOpacity="0.541"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8252 30.9402H1.9959C1.49431 30.9402 1.01327 30.7409 0.658603 30.3863C0.303931 30.0316 0.104678 29.5505 0.104678 29.049V2.8863C0.105999 2.38558 0.305835 1.90582 0.660364 1.55223C1.01489 1.19864 1.49518 1.00007 1.9959 1.00007H13.3532L21.7115 9.22861V29.049C21.7121 29.2971 21.6638 29.5429 21.5693 29.7723C21.4748 30.0018 21.336 30.2103 21.1608 30.386C20.9856 30.5616 20.7774 30.701 20.5482 30.7961C20.319 30.8912 20.0734 30.9402 19.8252 30.9402Z"
        fill="#5290F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19487 17.4157H16.1829V16.0684H5.19487V17.4157Z"
        fill="#F1F1F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19487 20.1157H16.1829V18.7634H5.19487V20.1157Z"
        fill="#F1F1F1"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.19487 22.8093H16.1829V21.462H5.19487V22.8093Z" fill="#F1F1F1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.19487 25.5093H13.5532V24.157H5.19487V25.5093Z" fill="#F1F1F1" />
      <path d="M14.1528 16.1687V8.86334H21.7127V16.1687H14.1528Z" fill="black" />
      <path d="M14.1528 16.1677H21.8175V8.89727H14.1528V16.1677Z" fill="url(#pattern0)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2738 9.22362H21.7109L13.3532 1.00007L13.3527 7.30746C13.3527 7.55951 13.4024 7.80909 13.499 8.04189C13.5956 8.2747 13.7372 8.48616 13.9156 8.66416C14.0941 8.84216 14.3059 8.9832 14.539 9.0792C14.7721 9.1752 15.0218 9.22428 15.2738 9.22362Z"
        fill="#A6C5FA"
      />
      <path
        d="M19.7755 30.9965H2.04591C1.77659 30.9959 1.51002 30.9422 1.26145 30.8385C1.01287 30.7348 0.78715 30.5832 0.59717 30.3923C0.40719 30.2014 0.256672 29.9749 0.15421 29.7259C0.0517486 29.4768 -0.000650011 29.21 6.08588e-06 28.9406V3.04243C0.00132372 2.50023 0.217297 1.98061 0.600694 1.59721C0.984091 1.21381 1.50371 0.997839 2.04591 0.996521H14.3164L21.8014 8.48155V28.9406C21.8028 29.4811 21.5907 30.0003 21.2113 30.3853C20.8319 30.7703 20.3159 30.9899 19.7755 30.9965Z"
        fill="#3086F6"
      />
      <path d="M14.3164 0.996521L21.8014 8.48155L14.316 8.48155L14.3164 0.996521Z" fill="#0C67D6" />
      <path
        d="M17.0463 14.2958H4.77083V12.5892H17.0463V14.2958ZM17.0463 16.681H4.77083V18.3826H17.0463V16.681ZM13.6381 20.7728H4.77083V22.4544H13.6381V20.7728Z"
        fill="#FDFFFF"
      />
    </svg>
  )
}
