import { useEffect, useState } from 'react'

const baseWaitTime = 2500
const waitTimePerItem = 100

export const useHideLoadingPreview = (itemsInPageCount = 0) => {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    let waitTime = baseWaitTime + waitTimePerItem * itemsInPageCount

    const timer = setTimeout(() => setHidden(false), waitTime)
    return () => clearTimeout(timer)
  }, [itemsInPageCount])

  return hidden
}
