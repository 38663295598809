import { Paths } from '@pages/routes/paths'

import { OAuthType } from '@features/users'

import styles from './oauth-missing-toast.module.css'

export interface OAuthMissingToastProps {
  type: OAuthType
}

export const OAuthMissingToast = ({ type }: OAuthMissingToastProps) => {
  const goToSettingsPage = () => {
    window.location.href = Paths.SETTINGS_ACCOUNT
  }

  return (
    <div className={styles.container} onClick={() => goToSettingsPage()}>
      <div className={styles.title}>You need additional authorization to access this resource.</div>
      <div className={styles.body}>Click to authorize your {type} drive.</div>
    </div>
  )
}
