import { defaultPreviewHeight, defaultPreviewWidth } from '.'

import { GSlidesIcon } from '@assets'

import { documentHasDoc } from '@features/destinations/utils'
import { ViewIdOptions } from '@features/drive/google-drive/types'
import { GSlides, GSlidesBaseURL } from '@features/embedding'

import { AbstractDestinationHandler } from './destination-handler'

export class GoogleSlidesHandler extends AbstractDestinationHandler {
  override getTitle() {
    return 'presentation'
  }

  override getTitlePlural() {
    return 'presentations'
  }

  override getCloudAppTitle(): string {
    return 'Google Slides'
  }

  override getCloudTitle(): string {
    return 'Google Drive'
  }

  override getShortNamePlural() {
    return 'slides'
  }

  override getDestinationTitle(isFileDeleted = false) {
    return isFileDeleted ? 'File Not Found Or Not Authorized In Drive' : 'Open destination'
  }

  override getDestinationUrl() {
    return `${GSlidesBaseURL}${this.presentation?.docId}`
  }

  override getGroupData(slideIndex: number) {
    return {
      group: `Slide ${slideIndex.toString()}`,
      groupIndex: slideIndex,
    }
  }

  override getGPickerCustomViews() {
    return [google.picker.ViewId.PRESENTATIONS]
  }

  override getGPickerViewId(): ViewIdOptions {
    return 'PRESENTATIONS'
  }

  override getSmallIcon() {
    return <GSlidesIcon />
  }

  override getPreview(
    itemsInPageCount?: number,
    width = defaultPreviewWidth,
    height = defaultPreviewHeight,
    className?: string
  ) {
    return this.presentation && documentHasDoc(this.presentation) ? (
      <GSlides
        itemsInPageCount={itemsInPageCount}
        width={width}
        height={height}
        docId={this.presentation.docId}
        className={className}
      />
    ) : null
  }

  override getAddVizStep1() {
    return { title: 'Destination slide', subtitle: 'Select the slide where the visualization should be inserted.' }
  }

  override getDateDelimiter() {
    return '/'
  }
}
