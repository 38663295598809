import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildCollectionPath } from '@pages/routes/paths'

import { CollectionsPlaceholder, InfoIcon, OpenBookIcon, PlusIcon, SearchIconMd } from '@assets'

import { CollectionExplainerModal, useCollections } from '@features/collections'
import { DestinationFile } from '@features/destinations/components/destination-file/destination-file'
import { useAuthStore } from '@features/users'

import {
  ActionModal,
  Button,
  EmptyPageTemplate,
  FilePicker,
  Input,
  PageContainer,
  SortAndFilterHeader,
} from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { ROLLSTACK_DOCS_START_COLLECTION_URL } from '@shared/constants'
import { useLayout } from '@shared/hooks'

import { CollectionsDataGridColumns, CollectionsDataGridData, CollectionsDataGridElements } from './collections-table'

export const Collections: FC = () => {
  const {
    collections,
    isLoading,
    sortingOption,
    isAscendingOrder,
    addCollectionModal,
    collectionExplainerModal,
    searchDestQuery,
    setSearchDestQuery,
    filesForPicker,
    toggleAddCollection,
    setCollectionExplainerModal,
    setSearchQuery,
    handleSortTypeChange,
    handleSortOrderChange,
    filteringOption,
    handleFilterTypeChange,
  } = useCollections()

  const navigate = useNavigate()
  const user = useAuthStore(state => state.user)
  const { isGridLayout, setIsGridLayout } = useLayout()

  const headerActions = (
    <div className="flex gap-5 items-center">
      <Button
        iconLeading={<OpenBookIcon />}
        text="Learn more"
        linkColor
        onClick={() => setCollectionExplainerModal(true)}
      />
      <Button text="Create collection" iconLeading={<PlusIcon />} onClick={toggleAddCollection} />
    </div>
  )

  const onCollectionDetailsLinkClick = (collectionId: string) => {
    navigate(collectionId)
  }

  const EmptyStateDescription = () => (
    <div className="flex flex-col items-center gap-5">
      Collection enable the creation of multiple presentations or documents using a single template.{' '}
      <Button
        iconLeading={<OpenBookIcon />}
        text="Learn more"
        linkColor
        onClick={() => setCollectionExplainerModal(true)}
      />
    </div>
  )

  const tableViewRowOnClickHandler = (collectionId: string) => navigate(buildCollectionPath(collectionId))

  const EmptyState = (
    <EmptyPageTemplate
      icon={<CollectionsPlaceholder />}
      label="No collections in the workspace"
      description={<EmptyStateDescription />}
    />
  )

  const pageSubtitle = (
    <div className="flex flex-row w-full items-center justify-between">
      Update and manage collections
      <div className="flex gap-1 items-center">
        <InfoIcon size={20} />
        <div>
          More about collections in{' '}
          <a
            href={ROLLSTACK_DOCS_START_COLLECTION_URL}
            className="text-sm-semibold text-primary-700 hover:text-primary-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            the docs
          </a>
        </div>
      </div>
    </div>
  )

  return (
    <PageContainer
      hideBackButton
      title="Collections"
      subtitle={pageSubtitle}
      loading={isLoading}
      rightActionButtons={headerActions}
    >
      <div className="flex flex-col gap-5 w-full">
        <SortAndFilterHeader
          sortingOption={sortingOption}
          isAscendingOrder={isAscendingOrder}
          setSearchQuery={setSearchQuery}
          handleSortTypeChange={handleSortTypeChange}
          handleSortOrderChange={handleSortOrderChange}
          filteringOption={filteringOption}
          handleFilterTypeChange={handleFilterTypeChange}
          isGridLayout={isGridLayout}
          setIsGridLayout={setIsGridLayout}
        />
        <div className="flex flex-1 flex-col w-full justify-between">
          <DataGrid
            data={CollectionsDataGridData(collections, tableViewRowOnClickHandler)}
            columns={
              isGridLayout
                ? CollectionsDataGridElements(user!, onCollectionDetailsLinkClick)
                : CollectionsDataGridColumns()
            }
            clickableRows
            EmptyState={EmptyState}
            isGrid={isGridLayout}
          />
        </div>
      </div>

      <ActionModal
        open={addCollectionModal}
        className="h-[80%] max-w-[1100px] overflow-auto"
        onBackgroundClick={toggleAddCollection}
      >
        <div className="grid grid-cols-3 items-center">
          <span className="text-lg-semibold">Select a destination</span>
          <Input
            autoFocus
            icon={<SearchIconMd />}
            value={searchDestQuery}
            onChange={e => setSearchDestQuery(e.target.value)}
            placeholder="Destination name"
          />
        </div>
        <FilePicker getFileName={file => file.name} files={filesForPicker} FileComponent={DestinationFile} />
      </ActionModal>

      <CollectionExplainerModal visible={collectionExplainerModal} setModalOpen={setCollectionExplainerModal} />
    </PageContainer>
  )
}
