import styles from './generic-toast.module.css'

export const GenericToast = ({ title, subtitle = '' }: { title: string; subtitle?: React.ReactNode }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>{subtitle}</div>
    </div>
  )
}
