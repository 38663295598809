import { useEffect } from 'react'

import { ETableauScriptUrl } from './types'
import { isOnlineTableau, isPublicTableau } from './utils'

export const useTableauScript = (url?: string) => {
  useEffect(() => {
    if (!url) {
      return
    }

    const script = document.createElement('script')
    let scriptSrcRoot = url
    let version = '3.latest'
    if (url) {
      if (isPublicTableau(url)) {
        scriptSrcRoot = ETableauScriptUrl.Public
        version = '3.6.0'
      } else if (isOnlineTableau(url)) {
        scriptSrcRoot = ETableauScriptUrl.Server
        version = '3.6.0'
      }
    }

    const scriptSrc = `${scriptSrcRoot}/javascripts/api/tableau.embedding.${version}.min.js`

    script.src = scriptSrc
    script.type = 'module'
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}
