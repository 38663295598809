import * as Sentry from '@sentry/react'
import PostHog from 'posthog-js'

import { config } from '@shared/config'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const dsn = config.sentryDsn
const dsnUrl = new URL(dsn ?? '')
const projectId = parseInt(dsnUrl.pathname.split('/')[1])
const orgName = 'rollstack-app'

if (config.nodeEnv === 'production') {
  Sentry.init({
    dsn,
    integrations: [
      PostHog.sentryIntegration({
        organization: orgName,
        projectId,
        severityAllowList: ['fatal', 'error', 'warning', 'info', 'log'],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration(),
      Sentry.debugIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.sessionTimingIntegration(),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration(),
    ],
    tracePropagationTargets: [/^https:\/\/api\.rollstack\.com/],
    // release needs to match the one in webpack sentry plugin, otherwise it won't link source maps in UI
    // by default it will be the commit head
    release: config.appVersion,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id })
      // }
      return event
    },
    initialScope: {
      tags: { appVersion: config.appVersion },
    },
    environment: config.nodeEnv,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}
