import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { buildCollectionSetupPath } from '@pages/routes/paths'

import { useDestinationRights } from '@features/destinations'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { Destination } from '@features/destinations/types'

import { FileComponentProps } from '@shared/components'
import { SelectableOption } from '@shared/components/selectable-options/selectable-options'
import { Tooltip } from '@shared/components/tooltip/tooltip'
import { getMessage } from '@shared/constants/text'

import styles from './destination-file.module.css'

export const DestinationFile: FC<FileComponentProps<Destination>> = ({ file: destination }) => {
  const navigate = useNavigate()
  const { viewer } = useDestinationRights(destination)
  const destinationHandle = destinationHandlers(destination)

  const handleSelect = () => navigate(buildCollectionSetupPath(destination.id))

  const [hasSyncs, hasRunUpdate] = useMemo(() => {
    const hasSyncs = destination.syncs && destination.syncs.length > 0
    const hasRunUpdate = destination.syncs && destination.syncs.some(sync => sync.originalImageUrl)
    return [hasSyncs, hasRunUpdate]
  }, [destination.syncs])
  const isDisabled = !hasSyncs || !hasRunUpdate || viewer
  const tooltipTitle = viewer
    ? getMessage('COLLECTION_ADD_VIEWER')
    : !hasSyncs
      ? getMessage('COLLECTION_ADD_NO_SYNCS')
      : !hasRunUpdate
        ? getMessage('COLLECTION_ADD_NO_UPDATE')
        : undefined

  return (
    <Tooltip show={isDisabled} title={tooltipTitle}>
      <SelectableOption className={styles.container} disabled={isDisabled || viewer} onClick={handleSelect}>
        {destinationHandle.getPreview()}
        <div className={styles.name}>
          {destinationHandle.getSmallIcon()}
          {destination.name}
        </div>
      </SelectableOption>
    </Tooltip>
  )
}
