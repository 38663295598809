import { ColumnDef } from '@tanstack/react-table'
import { Dayjs } from 'dayjs'

import { destinationHandlers, getLastSuccessfulUpdate, getNextUpdate } from '@features/destinations'
import { DestinationActionButtons } from '@features/destinations/components/destination-element/destination-action-buttons'
import { DetailedPreview } from '@features/destinations/components/destination-element/detailed-preview/detailed-preview'
import { Destination } from '@features/destinations/types'

import { RelativeDateTime } from '@shared/components/relative-date-time'
import { ThumbnailAndTitle } from '@shared/components/thumbnail-and-title/thumbnail-and-title'

import styles from './destinations-table.module.css'

interface RowData {
  id: string
  destination: Destination
  name: string
  lastSuccessfulUpdateAt: Dayjs | null
  nextUpdateAt: Dayjs | null
  badge: string | undefined
  onClick: () => void
}

export const DestinationsDataGridData = (
  destinations: Destination[],
  onClickHandler: (destinationId: string) => void,
  variantBadge?: (destination: Destination) => string | undefined
): RowData[] =>
  destinations.map(destination => {
    return {
      id: destination.id,
      destination: destination,
      name: destination.name,
      lastSuccessfulUpdateAt: getLastSuccessfulUpdate(destination),
      nextUpdateAt: getNextUpdate(destination),
      onClick: () => onClickHandler(destination.id),
      badge: variantBadge ? variantBadge(destination) : undefined,
    }
  })

export const DestinationsDataGridColumns = (): ColumnDef<RowData>[] => {
  return [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <div className={styles.nameCell}>
          {destinationHandlers(row.original.destination).getSmallIcon()}
          {row.original.name}
        </div>
      ),
    },
    {
      accessorKey: 'lastSuccessfulUpdateAt',
      header: 'Last full update',
      cell: ({ row }) => <RelativeDateTime position="left" date={row.original.lastSuccessfulUpdateAt} />,
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <DestinationActionButtons key={row.original.id} destination={row.original.destination} isGrid={false} />
      ),
    },
  ]
}

export const DestinationsDataGridElements = (
  itemsInPageCount: number,
  isVariant: boolean = false
): ColumnDef<RowData>[] => {
  return [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => {
        const thumbnail = (
          <DetailedPreview
            destination={row.original.destination}
            isInsideCollection={isVariant}
            itemsInPageCount={itemsInPageCount}
            badge={row.original.badge}
          />
        )
        return (
          <ThumbnailAndTitle
            Thumbnail={thumbnail}
            TypeIcon={destinationHandlers(row.original.destination).getSmallIcon()}
            title={row.original.name}
          />
        )
      },
    },
    {
      accessorKey: 'lastSuccessfulUpdateAt',
      header: 'Last full update',
      cell: ({ row }) => (
        <div className={styles.updatesContainer}>
          <span className={styles.updateTitle}>Last full update:</span>
          {row.original.lastSuccessfulUpdateAt ? <RelativeDateTime date={row.original.lastSuccessfulUpdateAt} /> : '--'}
          <span className={styles.updateTitle}>{row.original.nextUpdateAt && 'Next update:'}</span>
          {row.original.nextUpdateAt && <RelativeDateTime date={row.original.nextUpdateAt} />}
        </div>
      ),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <DestinationActionButtons key={row.original.id} destination={row.original.destination} isGrid={true} />
      ),
    },
  ]
}
