import { FC } from 'react'

import { IIcon } from '@assets/types'

export const CollectionIcon: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M7.83398 2.5H4.66732C4.20061 2.5 3.96725 2.5 3.78899 2.59083C3.63219 2.67072 3.50471 2.79821 3.42481 2.95501C3.33398 3.13327 3.33398 3.36662 3.33398 3.83333V7C3.33398 7.46671 3.33398 7.70007 3.42481 7.87833C3.50471 8.03513 3.63219 8.16261 3.78899 8.24251C3.96725 8.33333 4.20061 8.33333 4.66732 8.33333H7.83398C8.30069 8.33333 8.53405 8.33333 8.71231 8.24251C8.86911 8.16261 8.9966 8.03513 9.07649 7.87833C9.16732 7.70007 9.16732 7.46671 9.16732 7V3.83333C9.16732 3.36662 9.16732 3.13327 9.07649 2.95501C8.9966 2.79821 8.86911 2.67072 8.71231 2.59083C8.53405 2.5 8.30069 2.5 7.83398 2.5Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0007 2.5H13.834C13.3673 2.5 13.1339 2.5 12.9557 2.59083C12.7989 2.67072 12.6714 2.79821 12.5915 2.95501C12.5007 3.13327 12.5007 3.36662 12.5007 3.83333V7C12.5007 7.46671 12.5007 7.70007 12.5915 7.87833C12.6714 8.03513 12.7989 8.16261 12.9557 8.24251C13.1339 8.33333 13.3673 8.33333 13.834 8.33333H17.0007C17.4674 8.33333 17.7007 8.33333 17.879 8.24251C18.0358 8.16261 18.1633 8.03513 18.2432 7.87833C18.334 7.70007 18.334 7.46671 18.334 7V3.83333C18.334 3.36662 18.334 3.13327 18.2432 2.95501C18.1633 2.79821 18.0358 2.67072 17.879 2.59083C17.7007 2.5 17.4674 2.5 17.0007 2.5Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0007 11.6667H13.834C13.3673 11.6667 13.1339 11.6667 12.9557 11.7575C12.7989 11.8374 12.6714 11.9649 12.5915 12.1217C12.5007 12.2999 12.5007 12.5333 12.5007 13V16.1667C12.5007 16.6334 12.5007 16.8667 12.5915 17.045C12.6714 17.2018 12.7989 17.3293 12.9557 17.4092C13.1339 17.5 13.3673 17.5 13.834 17.5H17.0007C17.4674 17.5 17.7007 17.5 17.879 17.4092C18.0358 17.3293 18.1633 17.2018 18.2432 17.045C18.334 16.8667 18.334 16.6334 18.334 16.1667V13C18.334 12.5333 18.334 12.2999 18.2432 12.1217C18.1633 11.9649 18.0358 11.8374 17.879 11.7575C17.7007 11.6667 17.4674 11.6667 17.0007 11.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.83398 11.6667H4.66732C4.20061 11.6667 3.96725 11.6667 3.78899 11.7575C3.63219 11.8374 3.50471 11.9649 3.42481 12.1217C3.33398 12.2999 3.33398 12.5333 3.33398 13V16.1667C3.33398 16.6334 3.33398 16.8667 3.42481 17.045C3.50471 17.2018 3.63219 17.3293 3.78899 17.4092C3.96725 17.5 4.20061 17.5 4.66732 17.5H7.83398C8.30069 17.5 8.53405 17.5 8.71231 17.4092C8.86911 17.3293 8.9966 17.2018 9.07649 17.045C9.16732 16.8667 9.16732 16.6334 9.16732 16.1667V13C9.16732 12.5333 9.16732 12.2999 9.07649 12.1217C8.9966 11.9649 8.86911 11.8374 8.71231 11.7575C8.53405 11.6667 8.30069 11.6667 7.83398 11.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
