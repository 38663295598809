import isEqual from 'lodash/isEqual'
import { useEffect, useMemo, useState } from 'react'
import { PercentCrop } from 'react-image-crop'

import { CropOption, CropValues } from '@features/syncs/types'

import { useImageSize } from '@shared/hooks'

import { cropValuesToPercentCrop, fullCrop, percentCropToCropValues } from './utils'

interface IUseSyncCrop {
  imgUrl?: string
  initialCropOption?: CropOption
  autoCropValuesPx?: CropValues
  initialManualCropValuesPx: CropValues | null
  open: boolean
}

export const useSyncCrop = ({
  imgUrl,
  initialCropOption = CropOption.Auto,
  autoCropValuesPx,
  initialManualCropValuesPx,
  open,
}: IUseSyncCrop) => {
  const [imgWidth, imgHeight] = useImageSize(imgUrl)
  const [manualCrop, setManualCrop] = useState<PercentCrop>()
  const [localCropOption, setCropOption] = useState<CropOption>(initialCropOption)
  const autoCrop = cropValuesToPercentCrop(autoCropValuesPx, imgWidth, imgHeight)

  useEffect(() => {
    setCropOption(initialCropOption)
    if (initialManualCropValuesPx) {
      const resetManualCrop = cropValuesToPercentCrop(initialManualCropValuesPx, imgWidth, imgHeight)
      setManualCrop(resetManualCrop)
    } else {
      setManualCrop(fullCrop)
    }
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const manualPxCrop = useMemo(() => {
    if (!manualCrop || !imgWidth || !imgHeight) return

    return percentCropToCropValues(manualCrop, imgWidth, imgHeight)
  }, [manualCrop, imgWidth, imgHeight])

  const cropDimensions = useMemo(() => {
    let dimensions = { width: imgWidth, height: imgHeight }

    if (localCropOption === 'auto' && autoCropValuesPx) {
      dimensions = { width: autoCropValuesPx.width, height: autoCropValuesPx.height }
    } else if (localCropOption === 'manual' && manualPxCrop) {
      dimensions = { width: manualPxCrop.width, height: manualPxCrop.height }
    }

    return dimensions
  }, [localCropOption, autoCropValuesPx, manualPxCrop, imgWidth, imgHeight])

  useEffect(() => {
    if (localCropOption !== 'manual' || !imgWidth || !imgHeight) return

    if (!manualCrop) {
      if (initialManualCropValuesPx) {
        const newManualCrop = cropValuesToPercentCrop(initialManualCropValuesPx, imgWidth, imgHeight)

        if (!isEqual(newManualCrop, manualCrop)) {
          setManualCrop(newManualCrop)
        }
      } else {
        setManualCrop(fullCrop)
      }
    }
  }, [localCropOption, initialManualCropValuesPx, manualCrop, imgWidth, imgHeight])

  useEffect(() => {
    setCropOption(initialCropOption)
  }, [initialCropOption])

  return {
    cropDimensions,
    autoCrop,
    manualCrop,
    manualPxCrop,
    localCropOption,
    setManualCrop,
    setCropOption,
  }
}
