import { IActivityLog } from '@features/activity-log/types'
import { ICollectionVariant } from '@features/collections/types'
import { FilterType } from '@features/filters/types'
import { CropOption, ISync } from '@features/syncs/types'
import { User } from '@features/users'

import { Period } from '@shared/components'
import {
  EmailConfigTrigger,
  EmailRecipientConfig,
  IUpdateEmailConfigVariables,
} from '@shared/components/email-config/types'

export enum DestinationSharingPermissions {
  view = 'view',
  edit = 'edit',
}

export enum Frequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum UpdateMethod {
  Update = 'update',
  Archive = 'archive',
  UpdateVisualizations = 'updateVisualizations',
}

export enum EDestinationType {
  GoogleSlides = 'GoogleSlides',
  GoogleDocs = 'GoogleDocs',
  MicrosoftPowerPoint = 'MicrosoftPowerPoint',
  MicrosoftWord = 'MicrosoftWord',
  Email = 'Email',
}

export interface UserSharedPresentation {
  user: User | null
  userId: number
  googleShareId: string
  permission: DestinationSharingPermissions
}

export interface IDriveData {
  id: string
  folderId: string
  folderName: string
  mimeType: string
  name: string
}

interface FilterValueGroup {
  filterType: FilterType
  filterName: string
  filterValue: string
  filterFormattedValue: string
  syncIds: string[]
}

export interface Destination {
  id: string
  type: EDestinationType
  name: string
  docId?: string
  docUrl?: string
  driveId?: string | null
  userId: number
  user: Pick<User, 'id' | 'name' | 'email'>
  childPresentations?: Destination[]
  version?: number
  parentPresentationId?: string | null
  syncs?: ISync[]
  detachedDeletedSyncs?: ISync[]
  syncsGroupedByFilterValue?: FilterValueGroup[]
  updateMethod: UpdateMethod
  datestampSuffix: string
  lastSyncedAt: string | null
  lastRefreshedAt: string | null
  lastSuccessfulUpdateAt: string | null
  emailTemplateId?: string | null
  updatedAt: string
  createdAt?: string

  variantInCollection?: ICollectionVariant

  sharedWith?: UserSharedPresentation[]
  updateSchedules: IUpdateSchedule[]
  isFileDeleted?: boolean

  activityLogs?: IActivityLog[]
  linkOption: LinkOption
  cropOption: CropOption
}

export enum LinkOption {
  nolink = 'nolink',
  rollstack = 'rollstack',
  external = 'external',
}

export type CreateDestinationRequest = Pick<Destination, 'name' | 'docId' | 'driveId' | 'type'>

export enum DestinationEmailConfigSharingFormat {
  link = 'link',
  pdf = 'pdf',
}

export enum DestinationEmailConfigSharingPermission {
  editor = 'editor',
  viewer = 'viewer',
  commenter = 'commenter',
}

export interface DestinationEmailConfig {
  destinationId: string
  id: string
  createdAt: Date
  updatedAt: Date
  trigger: EmailConfigTrigger
  recipientConfig: EmailRecipientConfig[]
  emailTemplateId?: string | null
  sharingFormat: DestinationEmailConfigSharingFormat
  sharingPermission?: DestinationEmailConfigSharingPermission | null
}

export type DestinationEmailConfigCreateRequest = Omit<DestinationEmailConfig, 'id' | 'createdAt' | 'updatedAt'>
export type DestinationEmailConfigUpdateRequest = Partial<DestinationEmailConfigCreateRequest>

export interface DestinationTestEmailRequest {
  recipient: string
  name?: string
  subject?: string
  emailTemplateId?: string | null
}

export type DestinationWithDoc = Omit<Destination, 'docId' | 'driveId' | 'docUrl'> & {
  docId: string
  driveId: string
  docUrl: string
}

export interface IFreqOption {
  label: string
  value: Frequency
}

export interface ITimeOption {
  label: string
  value: string
}

export interface IUpdateSchedule {
  id: string
  name: string
  startDate: string
  endDate?: string | null
  frequency: Frequency
  destinationId: string
  selectedSlideIds: string[]
  selectedSyncIds: string[]
  scheduled: boolean
  parentUpdateScheduleId?: string
}

export interface IFormattedSchedule {
  id: string
  startDate: string
  endDate: string
  enableEndDate: boolean
  selectedTime: ITimeOption
  selectedPeriod: Period
  frequency: Frequency
  parentUpdateScheduleId?: string
}

export type IUpdateDestinationEmailConfigVariables = IUpdateEmailConfigVariables<DestinationEmailConfigUpdateRequest>
