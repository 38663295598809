import { useState } from 'react'

import { NestedNullableKeyOf, resolveNestedNullableKey } from '@shared/utils'

type SortingOrder = 'asc' | 'desc'

export const useSorting = <T extends object>(
  defaultSorting: NestedNullableKeyOf<T>[] | NestedNullableKeyOf<T>,
  initialOrder: SortingOrder = 'asc'
) => {
  const [sortingIds, setSortingIds] = useState<NestedNullableKeyOf<T>[]>(
    Array.isArray(defaultSorting) ? defaultSorting : [defaultSorting]
  )
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>(initialOrder)

  const handleSortingOrderClick = (id: NestedNullableKeyOf<T>) => {
    if (sortingIds.includes(id)) {
      setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortingOrder('asc')
      setSortingIds([id, ...sortingIds.filter(sortId => sortId !== id)])
    }
  }

  const compareValues = (a: T, b: T, id: NestedNullableKeyOf<T>) => {
    const aValue = resolveNestedNullableKey(id, a)
    const bValue = resolveNestedNullableKey(id, b)
    if (aValue < bValue) {
      return sortingOrder === 'asc' ? -1 : 1
    } else if (aValue > bValue) {
      return sortingOrder === 'asc' ? 1 : -1
    }
    return 0
  }

  const sort = (objects: T[]) => {
    return [...objects].sort((a, b) => {
      for (const id of sortingIds) {
        const result = compareValues(a, b, id)
        if (result !== 0) {
          return result
        }
      }
      return 0
    })
  }

  return {
    setSortingIds,
    setSortingOrder,
    sort,
    handleSortingOrderClick,
    sortingId: sortingIds[0],
    sortingIds,
    sortingOrder,
  }
}
