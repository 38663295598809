import classNames from 'classnames'
import { FC, useMemo } from 'react'

import { TableauVariantsContext } from '@pages/collections/collection-setup/tableau-context'

import { AlertIcon } from '@assets'
import { Loader } from '@assets/icons/loader'

import { useAddCollectionVariants } from '@features/collections/api/api'
import { FilterValuesSelect } from '@features/collections/components/collection-setup/filter-values-select/filter-values-select'
import { VariantSelectTable } from '@features/collections/components/collection-setup/variant-select-table/variant-select-table'
import { MAX_VARIANTS, TWO_FILTERS } from '@features/collections/consts'
import { useOneVariantValueField } from '@features/collections/hooks/use-one-variant-value-field'
import { useTableauVariants } from '@features/collections/hooks/use-tableau-variants'
import { useTwoVariantValuesField } from '@features/collections/hooks/use-two-variant-value-field'
import { ICollection } from '@features/collections/types'
import { sortByCollectionFilter } from '@features/collections/utils'
import { TableauIframe } from '@features/embedding/tableau/tableau-embed/tableau-iframe'
import { FilterType } from '@features/filters/types'

import { ActionModal, Button, Switch } from '@shared/components'

import styles from './add-variants-modal.module.css'

export interface VariantsModalProps {
  collection: ICollection
  visible: boolean
  toggle: (param?: boolean) => void
}

export const AddVariantsModal: FC<VariantsModalProps> = ({ collection, visible, toggle }) => {
  const filters = useMemo(
    () => collection.collectionFilters.sort(sortByCollectionFilter).map(cf => cf.filter),
    [collection]
  )
  const matchingCredentialsId = filters[0].matchingCredentialsId
  const tableauRequiredEmbedData = filters[0].tableauRequiredEmbedData

  const {
    tableauVariantsFromIframe,
    tableauIframeContainerStyle,
    error: variantsError,
  } = useTableauVariants({
    credentialsId: matchingCredentialsId,
    filters,
    tableauRequiredEmbedData,
    enabled: filters?.[0].type === FilterType.TableauFilter || filters?.[0].type === FilterType.TableauParams,
  })

  const tableauContextData = useMemo(
    () => ({
      ...tableauVariantsFromIframe,
      filters: filters,
    }),
    [filters, tableauVariantsFromIframe]
  )

  const oneVariantValuesField = useOneVariantValueField({
    credentialsId: matchingCredentialsId,
    filter: filters?.[0],
    tableauContextData,
  })

  const twoVariantValuesField = useTwoVariantValuesField({
    credentialsId: matchingCredentialsId,
    filters: filters,
    tableauContextData,
    enableScrollToAddRow: true,
  })

  const parsedVariants =
    filters?.length === 1 ? oneVariantValuesField.parsedVariants : twoVariantValuesField.parsedVariants
  const maxVariantsReached = parsedVariants.length > MAX_VARIANTS

  const { mutateAsync: addCollectionVariants, isLoading } = useAddCollectionVariants(collection)

  const handleAddVariants = async () => {
    await addCollectionVariants({
      variantsToAdd: parsedVariants,
      updatedAt: collection.updatedAt,
    })
    toggle(false)
  }

  const isTableau = filters?.[0].type === FilterType.TableauFilter || filters?.[0].type === FilterType.TableauParams
  const errors = variantsError || !matchingCredentialsId || (isTableau && !tableauRequiredEmbedData)

  return (
    <ActionModal
      open={visible}
      title="Add variants"
      processing={false}
      onBackgroundClick={() => toggle(false)}
      className={styles.modal}
      btns={
        <>
          <Button fullWidth secondaryGray text="Cancel" onClick={() => toggle(false)} disabled={isLoading} />
          <Button
            fullWidth
            text="Add variants"
            onClick={handleAddVariants}
            loading={isLoading}
            disabled={parsedVariants.length === 0 || isLoading || maxVariantsReached}
          />
        </>
      }
    >
      <TableauVariantsContext.Provider value={tableauContextData}>
        {errors ? (
          <div className="flex flex-col gap-2 border border-red-200 rounded-lg p-4 text-red-700 bg-red-50 text-sm">
            <div className="flex items-center gap-2 font-semibold ">
              <AlertIcon color="currentColor" size={20} />
              Error fetching variants
            </div>
            <div className="ml-7">
              {variantsError && <div>((variantsError?.response?.data as any)?.message ?? variantsError?.message) </div>}
              {!matchingCredentialsId && (
                <div>Credentials not found for your user. Please verify your credentials.</div>
              )}
              {isTableau && !tableauRequiredEmbedData && <div>Required tableau data not found.</div>}
            </div>
          </div>
        ) : (
          <>
            <div>Select the variants you want to create</div>
            <div
              className={classNames('h-[400px] mt-8', {
                'overflow-scroll': collection.collectionFilters.length === TWO_FILTERS,
              })}
            >
              {(isTableau ? !tableauContextData.isLoadingFilterValuesCombinations : true) ? (
                <div className="flex flex-col gap-2 font-normal">
                  {collection.collectionFilters?.length === 1 && (
                    <div className="flex flex-col gap-4">
                      {!oneVariantValuesField.selectedAllVariants && (
                        <FilterValuesSelect
                          credentialsId={matchingCredentialsId}
                          filter={filters[0]}
                          value={oneVariantValuesField.selectedVariants}
                          onChange={oneVariantValuesField.handleChangeOptions}
                        />
                      )}
                      <Switch
                        value={oneVariantValuesField.selectedAllVariants}
                        onChange={oneVariantValuesField.handleToggleSelectAll}
                        text="Select All"
                      />
                    </div>
                  )}
                  {collection.collectionFilters?.length === TWO_FILTERS && (
                    <VariantSelectTable
                      credentialId={matchingCredentialsId}
                      primaryFilter={filters[0]}
                      secondaryFilter={filters[1]}
                      rows={twoVariantValuesField.variantRows}
                      onChangeSecondaryValue={twoVariantValuesField.handleChangeSecondaryValue}
                      onChangePrimaryValue={twoVariantValuesField.handleChangePrimaryValue}
                      onAdd={twoVariantValuesField.handleAdd}
                      onDelete={twoVariantValuesField.handleDelete}
                      onSelectAll={twoVariantValuesField.handleSelectAll}
                      addRowRef={twoVariantValuesField.addRowRef}
                    />
                  )}
                  {maxVariantsReached && (
                    <div className="flex flex-col gap-2 border border-yellow-200 rounded-lg p-4 text-yellow-700 bg-yellow-50">
                      <div className="flex items-center gap-2 font-semibold ">
                        <AlertIcon color="currentColor" size={20} />
                        Maximum number of variants reached
                      </div>
                      <div className="ml-7">You cannot create more than {MAX_VARIANTS} variants</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex justify-center items-center h-full">
                  <Loader />
                </div>
              )}
              <div style={tableauIframeContainerStyle}>
                <TableauIframe
                  token={tableauVariantsFromIframe.tableauVizToken.token}
                  width={'0'}
                  height={'0'}
                  src={tableauVariantsFromIframe.workbookUrl}
                  ref={el => {
                    tableauVariantsFromIframe.tableauRef.current = el
                    tableauVariantsFromIframe.setTableauViz(el)
                  }}
                />
              </div>
            </div>
          </>
        )}
      </TableauVariantsContext.Provider>
    </ActionModal>
  )
}
