import { OAuthType } from '@features/users'

export const getDriveLongName = (type: OAuthType): string => {
  switch (type) {
    case OAuthType.GOOGLE:
      return 'Google Workspace'
    case OAuthType.MICROSOFT:
      return 'Microsoft 365'
  }
}
