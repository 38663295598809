import { Extensions } from '@tiptap/core'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Color from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import FontFamily from '@tiptap/extension-font-family'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { Node } from '@tiptap/pm/model'

import { TipTapLineHeight } from '@features/emails/components/templates/editors/components/tiptap-extensions/line-height-extension'
import { TipTapResizableImage } from '@features/emails/components/templates/editors/components/tiptap-extensions/resizable-image-extension'

const EDITOR_EXTENSIONS: Extensions = [
  Document.extend({
    addAttributes() {
      return {
        style: {
          parseHTML: element => element.style?.cssText,
        },
      }
    },
    renderHTML(props: { node: Node; HTMLAttributes: Record<string, any> }) {
      return ['table', props.HTMLAttributes, 0]
    },
  }),
  History,
  Heading.configure({
    HTMLAttributes: {
      style: 'font-family: Arial;',
    },
  }),
  Paragraph.configure({
    HTMLAttributes: {
      style: 'font-family: Arial; line-height: 1; margin-block-start: 1em; margin-block-end: 1em',
      class: 'not-prose',
    },
  }),
  Text,
  TextStyle,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  HorizontalRule,
  Color,
  TextAlign.configure({ types: ['heading', 'paragraph', 'image'] }),
  TipTapResizableImage,
  Link.configure({
    HTMLAttributes: {
      class: '!cursor-pointer',
    },
  }),
  FontFamily.configure({
    types: ['textStyle'],
  }),
  HardBreak,
  TipTapLineHeight,
]

export const getBaseEditorOptions = (content: string) => ({
  extensions: EDITOR_EXTENSIONS,
  content,
  editorProps: {
    attributes: {
      class: 'h-full prose max-w-none [&_ol]:list-decimal [&_ul]:list-disc !outline-0',
    },
  },
})

export const getPlaceholderSettings = (placeholder: string) => {
  return Placeholder.configure({
    placeholder,
    emptyEditorClass:
      'first:before:h-0 first:before:content-[attr(data-placeholder)] first:before:text-gray-900 first:before:float-left first:before:pointer-events-none opacity-50',
  })
}
