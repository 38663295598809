import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce'
import { useEffect, useState } from 'react'

import { config } from '@shared/config'

interface SamlClientOptions {
  redirectUrl: string
  clientId: string
}

export const useSamlClient = ({ redirectUrl, clientId }: SamlClientOptions): OAuth2AuthCodePKCE | null => {
  const [samlClient, setSamlClient] = useState<OAuth2AuthCodePKCE | null>(null)

  useEffect(() => {
    console.debug(`initializing new oauthclient with redirectUrl: ${redirectUrl}`)
    setSamlClient(
      new OAuth2AuthCodePKCE({
        authorizationUrl: `${config.jackson.apiUrl}/api/oauth/authorize`,
        tokenUrl: `${config.jackson.apiUrl}/api/oauth/token`,
        clientId,
        redirectUrl,
        scopes: [],
        onAccessTokenExpiry(refreshAccessToken) {
          console.error('Expired! Access token needs to be renewed.')
          alert('We will try to get a new access token via grant code or refresh token.')
          return refreshAccessToken()
        },
        onInvalidGrant(refreshAuthCodeOrRefreshToken) {
          console.error('Expired! Auth code or refresh token needs to be renewed.')
          alert('Redirecting to auth server to obtain a new auth grant code.')
          return refreshAuthCodeOrRefreshToken()
        },
      })
    )
  }, [redirectUrl, clientId])

  return samlClient
}
