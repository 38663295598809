import { FC } from 'react'

export const DataSourcesPlaceholder: FC = () => {
  return (
    <svg width="104" height="100" viewBox="0 0 104 100" fill="none">
      <g opacity="0.5">
        <path
          d="M51.9994 20.2897C80.5604 20.2897 103.714 15.7476 103.714 10.1448C103.714 4.542 80.5604 0 51.9994 0C23.4384 0 0.285156 4.542 0.285156 10.1448C0.285156 15.7476 23.4384 20.2897 51.9994 20.2897Z"
          fill="#CBE4FB"
        />
        <path
          d="M51.9994 49.2759C80.5604 49.2759 103.714 45.0584 103.714 39.8558C103.714 34.6532 80.5604 30.4355 51.9994 30.4355C23.4384 30.4355 0.285156 34.6532 0.285156 39.8558C0.285156 45.0584 23.4384 49.2759 51.9994 49.2759Z"
          fill="#D9EDFF"
        />
        <path
          d="M51.9994 79.7096C80.5604 79.7096 103.714 75.1676 103.714 69.5648C103.714 63.962 80.5604 59.4199 51.9994 59.4199C23.4384 59.4199 0.285156 63.962 0.285156 69.5648C0.285156 75.1676 23.4384 79.7096 51.9994 79.7096Z"
          fill="#D9EDFF"
        />
        <path
          d="M103.714 10.1465V30.4396C103.714 36.0338 80.5608 40.581 51.9994 40.581C23.4381 40.581 0.285156 36.0338 0.285156 30.4396V10.1465C0.285156 15.751 23.4381 20.2879 51.9994 20.2879C80.5608 20.2879 103.714 15.751 103.714 10.1465Z"
          fill="#7DB0DE"
        />
        <path
          d="M103.714 37.6816V58.9411C103.714 64.8016 80.5608 69.5654 51.9994 69.5654C23.4381 69.5654 0.285156 64.8016 0.285156 58.9411V37.6816C0.285156 43.5529 23.4381 48.3059 51.9994 48.3059C80.5608 48.3059 103.714 43.5529 103.714 37.6816Z"
          fill="#7DB0DE"
        />
        <path
          d="M103.714 69.5664V89.8596C103.714 95.4539 80.5608 100.001 51.9994 100.001C23.4381 100.001 0.285156 95.4539 0.285156 89.8596V69.5664C0.285156 75.1709 23.4381 79.7079 51.9994 79.7079C80.5608 79.7079 103.714 75.1709 103.714 69.5664Z"
          fill="#7DB0DE"
        />
      </g>
    </svg>
  )
}
