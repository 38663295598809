import { FC } from 'react'

import { ArrowCircleLeft, ArrowCircleRight } from '@assets'

import { EDestinationType } from '@features/destinations/types'
import { GSlides } from '@features/embedding/google/g-slides/g-slides'
import { SlideData, SyncSlideSelectProps, useSyncSlideSelect } from '@features/syncs/hooks/use-sync-slide-select'

import { Button, SelectInput } from '@shared/components'

import styles from './sync-slide-select.module.css'

const iframeWidth = 300
const iframeHeight = 169

export const SyncSlideSelect: FC<SyncSlideSelectProps> = props => {
  const { slideIds, slideIndex, nextSlideDisabled, formattedSlideIds, nextSlide, prevSlide, selectSlide } =
    useSyncSlideSelect(props)

  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()

  if (!props.docId) return null

  const isGoogle = props.type === EDestinationType.GoogleSlides

  return (
    <div className={styles.container}>
      {isGoogle && <GSlides width={iframeWidth} height={iframeHeight} docId={props.docId} slideIndex={slideIndex} />}

      <div className={styles.arrowsContainer}>
        <Button iconLeading={<ArrowCircleLeft />} secondaryColor onClick={prevSlide} disabled={slideIndex === 1} />

        <SelectInput<SlideData>
          value={{ slideIndex, slideId: slideIds[slideIndex! - 1] }}
          options={formattedSlideIds}
          onChange={selectSlide}
          onClick={stopPropagation}
          placeholder="Select Slide"
          getOptionValue={option => option.slideId}
          getOptionLabel={option => option.slideIndex.toString()}
          isDisabled={formattedSlideIds.length <= 1}
          className={styles.select}
          dataTestId="select-slide"
        />

        <Button iconLeading={<ArrowCircleRight />} secondaryColor onClick={nextSlide} disabled={nextSlideDisabled} />
      </div>
    </div>
  )
}
