import { useCallback, useEffect, useMemo, useState } from 'react'

import { useGetMetabaseDashboard, useGetMetabaseDashboardEmbedUrl } from '@features/data-sources'
import { MetabaseCoreDataSource } from '@features/data-sources/types'
import { IntegrationEmbedCommonProps } from '@features/embedding/types'

import { selectVisualisationToast, updateMetabaseTabForbiddenToast } from '@shared/components'

import { MetabaseDashboardDetails, MetabaseDashboardElement } from './types'
import {
  extractMetabaseFiltersFromUrl,
  hideDashboardFilters,
  parseTabId,
  useGetDashboardGrid,
  useGetIframeSize,
} from './utils'

interface IUseMetabase extends IntegrationEmbedCommonProps<MetabaseDashboardElement> {
  dataSource: MetabaseCoreDataSource | null
  dashboardId?: string
  syncId?: string
  initialFilters?: string
}
export const PADDING = 8
export const FOOTER_HEIGHT = 61
export const FILTER_HEIGHT = 95
export const FILTER_PADDING = 16
export const TAB_PADDING = 25
export const RANDOM_FOUR = 4

export const useMetabase = ({
  syncId,
  dataSource,
  dashboardId,
  singleSelect = false,
  showOverlays = false,
  initialElements = [],
  initialFilters = '',
  visSelectCallback,
}: IUseMetabase) => {
  const [elementsSelected, setElementsSelected] = useState<Array<MetabaseDashboardElement>>([])
  const [iframeLocationUrl, setIframeLocationUrl] = useState<URL>()
  const [filters, setFilters] = useState<Record<string, string>>({})
  const [tab, setTab] = useState<string>()
  const [tabId, setTabId] = useState<number>()
  const search = iframeLocationUrl?.search.replace('?', '')

  const { data: dashboardDetails, isLoading: dashboardLoading } = useGetMetabaseDashboard(dataSource?.id, dashboardId)

  const orderedTabs = useMemo(() => {
    return dashboardDetails?.ordered_tabs ?? dashboardDetails?.tabs ?? []
  }, [dashboardDetails])

  const orderedCards = useMemo(() => {
    if (!tabId || !orderedTabs.length || !dashboardDetails?.ordered_cards) {
      return dashboardDetails?.ordered_cards || []
    }
    const tabIdToFilter = tabId ?? orderedTabs[0].id
    return dashboardDetails?.ordered_cards.filter(card => card.dashboard_tab_id === tabIdToFilter)
  }, [dashboardDetails, tabId, orderedTabs])

  const hasFilters = useMemo(
    () => Object.values(dashboardDetails?.embedding_params ?? {}).filter(v => v === 'enabled').length > 0,
    [dashboardDetails]
  )

  const hasTabs = useMemo(() => orderedTabs.length > 0, [orderedTabs])

  const [numRows, numCols] = useGetDashboardGrid(orderedCards)

  const [iframeWidth, iframeHeight] = useGetIframeSize(numRows, numCols, hasFilters, showOverlays, hasTabs)

  const { data: dashboardUrl, isLoading: embedUrlLoading } = useGetMetabaseDashboardEmbedUrl(
    dashboardId,
    dataSource?.id,
    showOverlays,
    syncId,
    initialFilters,
    { enabled: !!dashboardDetails }
  )

  const handleSelectElement = (el: MetabaseDashboardElement) => {
    selectVisualisationToast(el.title)
    if (singleSelect) {
      setElementsSelected([el])
      visSelectCallback && visSelectCallback(el)
    } else {
      setElementsSelected(prev => [...prev, el])
    }
  }

  const handleUnselectElement = ({ id }: Pick<MetabaseDashboardElement, 'id'>) => {
    setElementsSelected(prev => prev.filter(e => e.id !== id))
  }

  const handleIframeLocationChange = useCallback(
    (msg: MessageEvent<any>) => {
      const type = msg?.data?.metabase?.type
      if (type === 'location') {
        const url = new URL(msg.data.metabase.location.href)
        const currentFilters = extractMetabaseFiltersFromUrl(url)
        const tabName = currentFilters.tab
        // check if user changed tab during modification of filters
        if (syncId && filters.tab && tabName !== filters.tab && !showOverlays) {
          updateMetabaseTabForbiddenToast()
          return
        }
        setTab(tabName)
        setTabId(parseTabId(tabName))
        setFilters(currentFilters)
        setIframeLocationUrl(url)
      }
    },
    [filters.tab, syncId, showOverlays]
  )

  const hideIframeUrlFilters = (url?: string, dashboardDetails?: MetabaseDashboardDetails, search?: string) => {
    if (!url || !dashboardDetails) return url
    const newUrl = new URL(url)
    newUrl.search = search ?? ''
    newUrl.hash = `#bordered=false&titled=false${hideDashboardFilters(dashboardDetails)}`
    if (newUrl.toString() === url) return url
    return newUrl.toString()
  }

  useEffect(() => {
    setElementsSelected(initialElements)
  }, [initialElements])

  useEffect(() => {
    setIframeLocationUrl(undefined)
  }, [dashboardId])

  return {
    setFilters,
    handleIframeLocationChange,
    handleSelectElement,
    handleUnselectElement,
    tab,
    tabId,
    orderedCards,
    dashboardDetails,
    elementsSelected,
    filters,
    hasFilters,
    hasTabs,
    iframeHeight,
    iframeWidth,
    dashboardUrl: showOverlays ? hideIframeUrlFilters(dashboardUrl, dashboardDetails, search) : dashboardUrl,
    loading: dashboardLoading || embedUrlLoading,
    numCols,
    numRows,
  }
}
