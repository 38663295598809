import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'

import { FilterOption, TableauRequiredEmbedData } from '@features/collections/types'
import { useGetTableauDataSource } from '@features/data-sources'
import { getValidVariantsFromWorksheet, isPublicTableau, useTableau } from '@features/embedding'

import { Button, tableauErrorGetValidVariantsToast } from '@shared/components'

export const useTableauVariants = ({
  credentialsId,
  filters,
  tableauRequiredEmbedData,
  enabled,
}: {
  credentialsId?: string
  filters: FilterOption[] | null
  tableauRequiredEmbedData?: TableauRequiredEmbedData
  enabled: boolean
}) => {
  const { data: dataSource, error } = useGetTableauDataSource(credentialsId, false, { enabled })

  const {
    state,
    tableauToken,
    tableauRef,
    viewFilters,
    viewParameters,
    filtersLoaded,
    setTableauViz,
    updateState,
    getActiveSheet,
    resetTableauConfig,
  } = useTableau({
    dataSource,
    fetchDomain: true,
  })

  // Set the initial data
  useEffect(() => {
    if (tableauRequiredEmbedData) {
      resetTableauConfig()
      updateState({
        workbookUrl: tableauRequiredEmbedData.workbookUrl ?? undefined,
        workbookName: tableauRequiredEmbedData.workbookName ?? undefined,
        workbookContentUrl: tableauRequiredEmbedData.workbookContentUrl ?? undefined,
        originalWorkbookUrl: tableauRequiredEmbedData.workbookUrl ?? undefined,
        viewId: tableauRequiredEmbedData.viewId ?? undefined,
        viewName: tableauRequiredEmbedData.viewName ?? undefined,
      })
    }
  }, [tableauRequiredEmbedData, updateState, resetTableauConfig])

  const activeSheet = getActiveSheet()

  const { data, isLoading, refetch } = useQuery(
    ['tableau/filters-combinations', { filters, filtersLoaded, activeSheet: Boolean(activeSheet) }],
    () => {
      if (!filters || !filters?.length || !filtersLoaded || !activeSheet) {
        return
      }

      const worksheets = activeSheet.worksheets ? activeSheet.worksheets : [activeSheet]

      return getValidVariantsFromWorksheet([...filters], worksheets, viewFilters, viewParameters)
    },
    {
      onError() {
        const retry = (
          <div className="flex justify-between items-center">
            Please try again <Button secondaryGray text="Retry" onClick={() => refetch()} />
          </div>
        )
        tableauErrorGetValidVariantsToast({ subtitle: retry })
      },
    }
  )

  const tableauVizToken = isPublicTableau(state.workbookUrl) || !tableauToken ? {} : { token: tableauToken! }

  const tableauIframeContainerStyle = useMemo(() => {
    if (enabled && !filtersLoaded) {
      return { display: 'block' }
    }
    if (enabled && filtersLoaded) {
      return { display: 'none' }
    }
  }, [enabled, filtersLoaded])

  return {
    tableauVariantsFromIframe: {
      primaryFilterValues: data?.primaryFilterValues ?? [],
      secondaryFilterValues: data?.secondaryFilterValues ?? [],
      filterValuesCombinations: data?.filterValuesCombinations ?? [],

      isLoadingFilterValuesCombinations: !filtersLoaded || isLoading,

      filtersLoaded,

      tableauVizToken,
      tableauRef,
      setTableauViz,
      workbookUrl: state.workbookUrl,
      viewFilters,
      viewParameters,
    },
    tableauIframeContainerStyle,
    error,
  }
}
