import { FC, useMemo } from 'react'
import { OptionProps, SingleValueProps, components } from 'react-select'

import { DataSourceIcon, PlusIcon } from '@assets'

import { useAddDataSourcesFromDrive, useGetDataSources } from '@features/data-sources'
import { EDataSourceType, IDataSource } from '@features/data-sources/types/types'
import { SyncCoreDataSource } from '@features/syncs/types'

import { SelectInput, primaryStyle } from '@shared/components/select-input/select-input'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import styles from './select-data-source.module.css'

interface ISelectDataSource {
  disabled?: boolean
  dataSource: SyncCoreDataSource | null
  handleDSChange: (dataSource: SyncCoreDataSource | null) => void
}

interface IDataSourceOption {
  id: string
  name: string
  type: EDataSourceType
}

const ADD_NEW_DATA_SOURCE_OPTION = {
  id: 'add_new',
  name: 'Connect a new Spreadsheet',
  type: EDataSourceType.GoogleSheets,
}

export const SelectDataSource: FC<ISelectDataSource> = ({ disabled = false, dataSource, handleDSChange }) => {
  const { data } = useGetDataSources(true)
  const activeDataSources: (SyncCoreDataSource | { id: string; name: string })[] = useMemo(
    () => [...(data ?? []), ...(isFeatureEnabled('sheets-data-source') ? [ADD_NEW_DATA_SOURCE_OPTION] : [])],
    [data]
  )

  const { addSheetsDataSources } = useAddDataSourcesFromDrive(() => handleDSChange(null))
  const addGoogleSheetsDataSource = addSheetsDataSources(EDataSourceType.GoogleSheets)

  return (
    <div className={styles.container}>
      <SelectInput<SyncCoreDataSource | { id: string; name: string }>
        label="Datasource"
        placeholder="No data source selected"
        isMulti={false}
        isDisabled={disabled}
        options={activeDataSources}
        value={dataSource}
        isSearchable={true}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        className={styles.select}
        styles={primaryStyle(disabled)}
        onChange={selectedOption => {
          if (selectedOption?.id === ADD_NEW_DATA_SOURCE_OPTION.id) {
            addGoogleSheetsDataSource()
          } else {
            handleDSChange(selectedOption as SyncCoreDataSource)
          }
        }}
        Option={Option}
        SingleValue={SingleValue}
        dataTestId="datasource"
      />
    </div>
  )
}

const OptionWithIcon = (dataSource: IDataSourceOption) => (
  <div className={styles.selectDataSourceOption}>
    {dataSource.id === ADD_NEW_DATA_SOURCE_OPTION.id ? (
      <>
        <PlusIcon size={60} />
        <span className={styles.txtColor}>{dataSource.name}</span>
      </>
    ) : (
      <>
        <DataSourceIcon type={dataSource.type} size={60} />
        <span>{dataSource.name}</span>
      </>
    )}
  </div>
)

const SingleValue = (props: SingleValueProps<IDataSource>) => {
  const dataSource = props.getValue()[0]

  return (
    <components.SingleValue {...props}>
      <OptionWithIcon {...dataSource} />
    </components.SingleValue>
  )
}

const Option = (props: OptionProps<IDataSourceOption>) => {
  const dataSource = props.data

  return (
    <components.Option {...props}>
      <OptionWithIcon {...dataSource} />
    </components.Option>
  )
}
