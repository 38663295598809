import { FC } from 'react'

export const UsersIcon: FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M18.3327 17.5762V15.9095C18.3327 14.3563 17.2704 13.0512 15.8327 12.6812M12.916 2.81847C14.1376 3.31296 14.9993 4.5106 14.9993 5.90951C14.9993 7.30841 14.1376 8.50605 12.916 9.00054M14.166 17.5762C14.166 16.023 14.166 15.2465 13.9123 14.6339C13.574 13.8171 12.9251 13.1682 12.1083 12.8299C11.4957 12.5762 10.7192 12.5762 9.16602 12.5762H6.66602C5.11288 12.5762 4.33631 12.5762 3.72374 12.8299C2.90698 13.1682 2.25806 13.8171 1.91975 14.6339C1.66602 15.2465 1.66602 16.023 1.66602 17.5762M11.2493 5.90951C11.2493 7.75045 9.75697 9.24284 7.91602 9.24284C6.07507 9.24284 4.58268 7.75045 4.58268 5.90951C4.58268 4.06856 6.07507 2.57617 7.91602 2.57617C9.75697 2.57617 11.2493 4.06856 11.2493 5.90951Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
