/* eslint-disable no-magic-numbers */
import { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  DestinationsDataGridColumns,
  DestinationsDataGridData,
  DestinationsDataGridElements,
} from '@pages/destinations/destinations-table'
import { buildDestinationPath } from '@pages/routes/paths'

import { PlusIcon } from '@assets'

import { AddVariantsModal, TemplateExplainerModal, useCollectionRights } from '@features/collections'
import { useVariantOwnerFilter } from '@features/collections/hooks/use-variant-owner-filter'
import { CollectionVariantType, ICollection } from '@features/collections/types'
import { moveTemplateToFirstPosition } from '@features/destinations'
import { TemplateSection } from '@features/destinations/components/destination-element/template-section/template-section'
import { Destination } from '@features/destinations/types'

import { Button, EmptyPageTemplate, SortAndFilterHeader } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { useBoolean, useLayout, useSort } from '@shared/hooks'

export interface CollectionVariantsListProps {
  collection: ICollection
  shared: boolean
  templateDestination: Destination
}

const getGridViewMaxRows = (height: number) => (height < 1000 ? 2 : height < 1200 ? 3 : height < 1500 ? 4 : 5)
const getListViewMaxRows = (height: number) => Math.trunc((height - 400) / 69)

export const CollectionVariantsList: FC<CollectionVariantsListProps> = ({ collection, templateDestination }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [elementsPerPage, setElementsPerPage] = useState(10)

  const [templateExplainerModal, setTemplateExplainerModal] = useState(false)
  const [addVariantFilterModal, setAddVariantFilterModal] = useBoolean(false)
  const { isGridLayout, setIsGridLayout } = useLayout()
  const { owner, admin } = useCollectionRights(collection)

  const navigate = useNavigate()

  const { sortingOption, isAscendingOrder, handleSortTypeChange, handleSortOrderChange } = useSort(
    'variantsSortingType',
    'isVariantsAscendingOrder'
  )

  const { handleVariantOwnerChange, filterOwner, variantOwnerOption, variantOwnerOptions } =
    useVariantOwnerFilter(collection)

  const enabledVariants = (collection.collectionVariants ?? []).filter(v => !v.disabled)
  let sortedAndFilteredDestinations = useMemo(() => {
    let variants = enabledVariants
      .filter(variant => variant.destination?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()))
      .filter(variant => filterOwner(variant.destination))
      .map(variant => variant.destination)
      .sort(sortingOption.sorter)

    if (isAscendingOrder) {
      variants = variants.reverse()
    }

    return moveTemplateToFirstPosition(variants)
  }, [enabledVariants, sortingOption.sorter, isAscendingOrder, searchQuery, filterOwner])

  const variants = sortedAndFilteredDestinations.filter(
    destination => destination.variantInCollection?.type === CollectionVariantType.variant
  )

  const variantBadge = (variant: Destination) =>
    collection.collectionVariants
      .find(cv => cv.code === variant.variantInCollection?.code)
      ?.collectionVariantFilters.map(cvf => cvf.value)
      ?.join(' x ')

  const numberOfVariantsRef = (isGridLayout: boolean) => {
    const measureGridView = (element: HTMLDivElement) => {
      if (!element) return
      const gridComputedStyle = window.getComputedStyle(element)
      const gridColumnCount = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length
      setElementsPerPage(gridColumnCount * getGridViewMaxRows(window.innerHeight))
    }

    const measureListView = (element: HTMLTableSectionElement) => {
      if (!element) return
      setElementsPerPage(getListViewMaxRows(window.innerHeight))
    }

    if (isGridLayout) return measureGridView
    else return measureListView
  }

  const addVariantsBtn = (
    <Button className="min-w-fit" text="Add variants" iconLeading={<PlusIcon />} onClick={setAddVariantFilterModal} />
  )
  const tableViewRowOnClickHandler = (destinationId: string) => navigate(buildDestinationPath(destinationId))

  const EmptyStateDescription = () => (
    <div className="flex flex-col items-center gap-5">
      View of all your variants you created or that have been shared with you.
      {addVariantsBtn}
    </div>
  )

  const EmptyState = <EmptyPageTemplate label="No variants" description={<EmptyStateDescription />} />

  return (
    <div className="flex flex-row gap-20 w-full h-full">
      {(owner || admin) && (
        <div className="flex flex-col min-w-[320px] w-[320px]">
          <div className="composes text-xs-medium mb-20 px-2 border-b border-gray-200 mt-[18px]">Template</div>
          <TemplateSection
            collection={collection}
            template={templateDestination}
            badge="All"
            itemsInPageCount={variants.length}
            setTemplateExplainerModal={setTemplateExplainerModal}
          />
        </div>
      )}

      <div className="flex w-full flex-col gap-5">
        <div className="flex justify-between items-end gap-2">
          <div className="w-full composes text-xs-medium px-2 border-b border-gray-200 h-min">Variants</div>
          {addVariantsBtn}
        </div>
        <div className="flex flex-col gap-5 w-full">
          <SortAndFilterHeader
            sortingOption={sortingOption}
            isAscendingOrder={isAscendingOrder}
            setSearchQuery={setSearchQuery}
            handleSortTypeChange={handleSortTypeChange}
            handleSortOrderChange={handleSortOrderChange}
            isGridLayout={isGridLayout}
            setIsGridLayout={setIsGridLayout}
            filteringVariantOwnerOption={variantOwnerOption}
            filteringVariantOwnerOptions={variantOwnerOptions}
            handleFilterVariantOwnerChange={handleVariantOwnerChange}
          />
          <div className="flex flex-1 flex-col w-full justify-between">
            <DataGrid
              data={DestinationsDataGridData(variants, tableViewRowOnClickHandler, variantBadge)}
              columns={
                isGridLayout ? DestinationsDataGridElements(variants.length, true) : DestinationsDataGridColumns()
              }
              clickableRows
              EmptyState={EmptyState}
              isGrid={isGridLayout}
              numberOfElementsRef={numberOfVariantsRef}
              elementsPerPage={elementsPerPage}
              enablePagination
            />
          </div>
        </div>
      </div>

      <TemplateExplainerModal
        visible={templateExplainerModal}
        setModalOpen={setTemplateExplainerModal}
        type={collection.type}
      />

      {addVariantFilterModal && (
        <AddVariantsModal
          key={Boolean(addVariantFilterModal).toString()}
          visible={addVariantFilterModal}
          collection={collection}
          toggle={setAddVariantFilterModal}
        />
      )}
    </div>
  )
}
