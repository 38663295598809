import { FILTER_PADDING, FOOTER_HEIGHT, PADDING, TAB_PADDING } from '@features/embedding'

import { RANDOM_FOUR } from './metabase-embed'

import styles from './dashboard-element-overlay-wrapper.module.css'

export type MetabaseElementOverlay = {
  id: string
  row: number
  column: number
  width: number
  height: number
}

type DashboardElementOverlayWrapperProps = {
  elements: MetabaseElementOverlay[]
  hasFilters?: boolean
  hasTabs?: boolean
  iframeHeight: number
  iframeWidth: number
  numCols: number
  numRows: number
  render: (e: any) => React.ReactNode
}

export const PADDING_COUNT = 2
const METABASE_GRID_COLS = 18

export const DashboardElementOverlayWrapper = ({
  elements,
  hasFilters,
  hasTabs,
  iframeHeight,
  iframeWidth,
  numCols,
  numRows,
  render,
}: DashboardElementOverlayWrapperProps) => {
  const filterPadding = hasFilters ? FILTER_PADDING * PADDING_COUNT : RANDOM_FOUR
  const tabPadding = hasTabs ? TAB_PADDING * PADDING_COUNT : 0
  return (
    <div
      className={styles.root}
      style={{
        top: tabPadding + filterPadding + RANDOM_FOUR,
        left: 0,
        height: iframeHeight - (tabPadding + filterPadding) - FOOTER_HEIGHT - PADDING - RANDOM_FOUR,
        width: iframeWidth,
      }}
    >
      <div
        className={styles.wrapper}
        style={{
          gridTemplateRows: `repeat(${numRows}, 1fr)`,
          gridTemplateColumns: `repeat(${Math.max(numCols, METABASE_GRID_COLS)}, 1fr)`,
        }}
      >
        {elements
          .sort((a, b) => (a.row === b.row ? a.column - b.column : a.row - b.row))

          .map(({ row, column, height, width, ...rest }, index) => {
            const rowStart = row + 1
            const rowEnd = row + height + 1
            const columnStart = column + 1
            const columnEnd = column + width

            return render({
              ...rest,
              rowStart,
              rowEnd,
              columnStart,
              columnEnd,
              index: index + 1,
            })
          })}
      </div>
    </div>
  )
}
