import { FC } from 'react'

export const ErrorGraphIcon: FC = () => {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none">
      <path
        d="M150.455 149.363C177.815 122.003 177.489 77.3069 149.727 49.5449L100.182 99.0895L150.455 149.363Z"
        fill="white"
        stroke="#261E47"
        strokeWidth="1.90184"
        strokeMiterlimit="10"
      />
      <path
        d="M149.727 49.5446C122.367 22.185 77.7524 22.4296 50.091 50.091C22.4296 77.7524 22.1849 122.367 49.5445 149.727L149.727 49.5446Z"
        fill="#0098D9"
        stroke="#261E47"
        strokeWidth="1.90184"
        strokeMiterlimit="10"
      />
      <path
        d="M100.182 99.0889L49.5445 149.726C77.3065 177.488 122.492 177.325 150.455 149.362L100.182 99.0889Z"
        fill="#FFD400"
        stroke="#261E47"
        strokeWidth="1.90184"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
