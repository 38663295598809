import React from 'react'

import { CollectionShareModal } from '@features/collaboration/components/share-modal/collection-share-modal'
import {
  useGetCollectionById,
  useRevokeSharePermission,
  useShareCollection,
  useUpdateSharePermission,
} from '@features/collections/api/api'

import { useModalWithData } from '@shared/hooks'

export interface WithCollectionShareProps {
  shareCollection: (collectionId: string) => void
}

export const withCollectionShare = <T,>(WrappedComponent: React.ComponentType<T & WithCollectionShareProps>) => {
  return (props: T) => {
    const [modal, collectionId, toggleModal, openModalWithData] = useModalWithData<string>()

    const { mutateAsync: shareCollection, isLoading } = useShareCollection(collectionId!)
    const { data: collection } = useGetCollectionById({ collectionId })
    const { mutateAsync: updatePermissions } = useUpdateSharePermission(collectionId!)
    const { mutateAsync: revokePermissions } = useRevokeSharePermission(collectionId!)

    return (
      <>
        <WrappedComponent {...props} shareCollection={openModalWithData} />
        {collectionId && (
          <CollectionShareModal
            open={modal}
            toggle={toggleModal}
            collection={collection}
            shareItem={shareCollection}
            isInviteLoading={isLoading}
            updatePermissions={updatePermissions}
            revokePermissions={revokePermissions}
          />
        )}
      </>
    )
  }
}
