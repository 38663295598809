import { FC } from 'react'

export const FileIcon: FC = () => {
  return (
    <svg width="140" height="100" viewBox="0 0 140 100" fill="none">
      <path
        d="M42.728 34.4126C41.7845 36.6438 40.3089 38.6099 38.43 40.139C36.5512 41.6681 34.3264 42.7137 31.9501 43.1843C29.5738 43.655 27.1184 43.5363 24.7986 42.8388C22.4787 42.1413 20.3651 40.8861 18.6424 39.183C16.9197 37.4798 15.6405 35.3807 14.9165 33.0689C14.1925 30.7572 14.0459 28.3033 14.4894 25.9218C14.9328 23.5403 15.9529 21.3037 17.4605 19.4076C18.968 17.5114 20.9171 16.0134 23.1374 15.0445M42.7707 22.9685C43.3644 24.4017 43.7285 25.917 43.8521 27.4586C43.8827 27.8394 43.8979 28.0298 43.8223 28.2013C43.7591 28.3446 43.634 28.4802 43.4962 28.5546C43.3313 28.6437 43.1252 28.6437 42.7131 28.6437H30.2559C29.8406 28.6437 29.6329 28.6437 29.4743 28.5629C29.3348 28.4918 29.2214 28.3784 29.1503 28.2388C29.0694 28.0802 29.0694 27.8726 29.0694 27.4573V15C29.0694 14.5879 29.0694 14.3819 29.1585 14.2169C29.2329 14.0792 29.3685 13.954 29.5118 13.8908C29.6833 13.8152 29.8738 13.8305 30.2546 13.861C31.7962 13.9846 33.3114 14.3488 34.7447 14.9425C36.544 15.6878 38.1788 16.7801 39.5559 18.1572C40.933 19.5343 42.0254 21.1692 42.7707 22.9685Z"
        stroke="#E4E4E7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7211 71.3551H42.4153M15.7211 62.457H42.4153M15.7211 80.2532H42.4153"
        stroke="#E4E4E7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.677 76.0994V53.261M89.5757 76.0994V43.4731M63.4746 76.0994L63.4746 63.0489M94.1651 27.2498L111.028 33.5733M85.6565 28.4681L67.3905 42.1675M119.137 31.856C121.048 33.7672 121.048 36.8659 119.137 38.7771C117.226 40.6883 114.127 40.6883 112.216 38.7771C110.305 36.8659 110.305 33.7672 112.216 31.856C114.127 29.9448 117.226 29.9448 119.137 31.856ZM66.9352 41.6439C68.8464 43.5551 68.8464 46.6538 66.9352 48.565C65.024 50.4762 61.9253 50.4762 60.0141 48.565C58.1029 46.6538 58.1029 43.5551 60.0141 41.6439C61.9253 39.7327 65.024 39.7327 66.9352 41.6439ZM93.0362 22.0682C94.9474 23.9794 94.9474 27.078 93.0362 28.9892C91.125 30.9004 88.0263 30.9004 86.1151 28.9892C84.2039 27.078 84.2039 23.9794 86.1151 22.0682C88.0263 20.157 91.125 20.157 93.0362 22.0682Z"
        stroke="#E4E4E7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="139" height="99" rx="4.68182" stroke="#E4E4E7" />
    </svg>
  )
}
