import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { useDuplicateOutside } from '@features/collections'
import { useGetDrivePermissionCheckForCopy } from '@features/destinations/api/api'
import { DuplicateDestinationStandalone } from '@features/destinations/components'
import { Destination } from '@features/destinations/types'

import { duplicateDestinationAfterAuthorizeFileToast } from '@shared/components'

export interface WithDestinationDuplicateOutsideProps {
  duplicateOutside: (destination: Destination) => void
}

export const withDestinationDuplicateOutside = <T,>(
  WrappedComponent: React.ComponentType<T & WithDestinationDuplicateOutsideProps>
) => {
  return (props: T) => {
    const [destination, setDestination] = useState<Destination | null>(null)
    const navigate = useNavigate()
    const { mutateAsync: duplicateOutside, isLoading } = useDuplicateOutside({
      filePickerOptions: {
        itemId: destination?.docId,
        itemName: destination?.name,
        type: destination?.type,
      },
      onSuccess: () => {
        navigate(Paths.BASE)
      },
    })

    const { isLoading: isCheckingDrivePermission } = useGetDrivePermissionCheckForCopy({
      destination,
      onSuccess: () => {
        duplicateDestinationAfterAuthorizeFileToast()
      },
    })

    const visible = Boolean(destination)

    const closeDuplicateOutside = () => setDestination(null)

    const openDuplicateOutside = async (destination: Destination) => {
      setDestination(destination)
    }

    const handleDuplicateOutside = async (newName: string) => {
      const variantId = destination?.variantInCollection?.id
      const collectionId = destination?.variantInCollection?.collectionId
      if (!collectionId || !variantId) {
        return
      }

      await duplicateOutside({ id: variantId, name: newName, collectionId })
      navigate(Paths.BASE)
    }

    return (
      <>
        <WrappedComponent {...props} duplicateOutside={openDuplicateOutside} />

        {destination && (
          <>
            <DuplicateDestinationStandalone
              type={destination.type}
              visible={visible}
              originalName={destination.name}
              toggleVisibility={closeDuplicateOutside}
              isCheckingDrivePermission={isCheckingDrivePermission}
              onDuplicate={handleDuplicateOutside}
              processing={isLoading}
            />
          </>
        )}
      </>
    )
  }
}
