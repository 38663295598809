import { FC } from 'react'

export const CollectionsPlaceholder: FC = () => {
  return (
    <svg width="116" height="100" viewBox="0 0 116 100" fill="none">
      <g opacity="0.6">
        <rect x="75.1626" width="40.5021" height="29.5185" rx="2" fill="#BFDBF4" />
        <path
          d="M106.332 8C107.436 8 108.335 8.89543 108.335 10V38C108.335 39.1046 107.438 40 106.333 40C100.817 40 98.6036 40 88.6744 40C78.3166 40 79.6349 40 70.3357 40C69.2311 40 68.3354 39.1046 68.3354 38V10C68.3354 8.89543 69.2346 8 70.3392 8C77.0232 8 78.5662 8 88.6744 8C98.7754 8 99.757 8 106.332 8Z"
          fill="#7DB0DE"
        />
        <rect x="75.1626" y="60" width="40.5021" height="29.5185" rx="2" fill="#BFDBF4" />
        <path
          d="M106.332 68C107.436 68 108.335 68.8954 108.335 70V98C108.335 99.1046 107.438 100 106.333 100C100.817 100 98.6036 100 88.6744 100C78.3166 100 79.6349 100 70.3357 100C69.2311 100 68.3354 99.1046 68.3354 98V70C68.3354 68.8954 69.2346 68 70.3392 68C77.0232 68 78.5662 68 88.6744 68C98.7754 68 99.757 68 106.332 68Z"
          fill="#7DB0DE"
        />
        <rect x="7.20117" width="40.5021" height="29.5185" rx="2" fill="#BFDBF4" />
        <path
          d="M38.3316 8C39.4362 8 40.3354 8.89543 40.3354 10V38C40.3354 39.1046 39.4379 40 38.3333 40C32.8172 40 30.6036 40 20.6744 40C10.3166 40 11.6349 40 2.33569 40C1.23112 40 0.335449 39.1046 0.335449 38V10C0.335449 8.89543 1.23458 8 2.33915 8C9.02325 8 10.5662 8 20.6744 8C30.7754 8 31.757 8 38.3316 8Z"
          fill="#7DB0DE"
        />
        <rect x="7.20117" y="60" width="40.5021" height="29.5185" rx="2" fill="#BFDBF4" />
        <path
          d="M38.3316 68C39.4362 68 40.3354 68.8954 40.3354 70V98C40.3354 99.1046 39.4379 100 38.3333 100C32.8172 100 30.6036 100 20.6744 100C10.3166 100 11.6349 100 2.33569 100C1.23112 100 0.335449 99.1046 0.335449 98V70C0.335449 68.8954 1.23458 68 2.33915 68C9.02325 68 10.5662 68 20.6744 68C30.7754 68 31.757 68 38.3316 68Z"
          fill="#7DB0DE"
        />
      </g>
    </svg>
  )
}
