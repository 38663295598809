import { Crisp } from 'crisp-sdk-web'

import { ApiErrorToastPayload } from '@shared/components/toasts/toasts'

import styles from './api-error-toast.module.css'

export const ApiErrorToast = (data: ApiErrorToastPayload) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.body}>
        {data.message ? `${data.message} ` : ''}
        <br />
        {!data.hideSubtext && (
          <span>
            {'Please retry. If the problem persists '}
            <span className={styles.link} onClick={() => Crisp.chat.open()}>
              {'contact us here'}
            </span>
          </span>
        )}
      </div>
    </div>
  )
}
