import { FC } from 'react'

interface ColorOrbProps extends React.HTMLAttributes<HTMLDivElement> {
  color: string
}

export const ColorOrb: FC<ColorOrbProps> = ({ color, ...props }) => {
  return (
    <div className="py-1">
      <div
        className="rounded-full w-7 aspect-square border border-gray-200 cursor-pointer hover:scale-110 transition-all"
        style={{ backgroundColor: color }}
        {...props}
      />
    </div>
  )
}
