import React, { useState } from 'react'

import { useUpdateDestination } from '@features/destinations/api/api'
import { RenameDestination } from '@features/destinations/components'
import { Destination } from '@features/destinations/types'
import { isOAuthMissingError } from '@features/drive/error-handler'

import { ModalUpdating, renameDestinationToast } from '@shared/components'

import { buildModalProps } from './utils'

export interface WithDestinationRenameProps {
  renameDestination: (destination: Destination, showModal?: boolean) => void
}

enum ProcessSteps {
  Inactive,
  AssignName,
  RenameOperation,
  Completed,
}

export const withDestinationRename = <T,>(WrappedComponent: React.ComponentType<T & WithDestinationRenameProps>) => {
  return (props: T) => {
    const [step, setStep] = useState(ProcessSteps.Inactive)
    const [destination, setDestination] = useState<Destination | null>(null)
    const [processing, setProcessing] = useState(false)

    const updateDestinationQuery = useUpdateDestination(destination)

    const closeProcess = () => setStep(ProcessSteps.Inactive)

    const renameDestination = async (destination: Destination, showModal?: boolean) => {
      setDestination(destination)
      if (showModal) {
        setStep(ProcessSteps.AssignName)
      }
    }

    const onRename = async (name: string) => {
      if (!destination) {
        return
      }

      setProcessing(true)
      try {
        await updateDestinationQuery.mutateAsync({ id: destination.id, name, updatedAt: destination.updatedAt })

        setProcessing(false)
        setStep(ProcessSteps.Completed)
        renameDestinationToast(destination.type)
      } catch (error) {
        setProcessing(false)
        // TODO: handle this error in the query after react-query updated to v5
        if (isOAuthMissingError(error)) {
          return
        }
        closeProcess()
      }
    }

    let { modalUpdatingTitle } = buildModalProps({
      action: 'Rename',
      destinationType: destination?.type,
      docId: destination?.docId,
    })

    return (
      <>
        <WrappedComponent {...props} renameDestination={renameDestination} />

        {destination && (
          <>
            <RenameDestination
              type={destination.type}
              visible={step === ProcessSteps.AssignName}
              originalName={destination.name}
              toggleVisibility={closeProcess}
              onRename={onRename}
              processing={processing}
            />
            <ModalUpdating visible={step === ProcessSteps.RenameOperation} title={modalUpdatingTitle} />
          </>
        )}
      </>
    )
  }
}
