import { createContext } from 'react'

import { FilterOption, ParsedVariant } from '@features/collections/types'

export type TableauVariantsContextData = {
  filters: FilterOption[] | null
  primaryFilterValues: string[]
  secondaryFilterValues: string[]
  filterValuesCombinations: ParsedVariant[]
  isLoadingFilterValuesCombinations: boolean
}

export const TableauVariantsContext = createContext<TableauVariantsContextData>({
  filters: [],
  primaryFilterValues: [],
  secondaryFilterValues: [],
  filterValuesCombinations: [],
  isLoadingFilterValuesCombinations: false,
})
