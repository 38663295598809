import React, { useState } from 'react'

import { RefreshSingleArrowIcon, UpdateIcon } from '@assets'

import { useRecreateCollectionVariant } from '@features/collections'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { Destination } from '@features/destinations/types'

import { ActionModal, Button } from '@shared/components'

export interface WithDestinationRecreateProps {
  recreateVariant: (destination: Destination) => void
}

export const withDestinationRecreate = <T,>(
  WrappedComponent: React.ComponentType<T & WithDestinationRecreateProps>
) => {
  return (props: T) => {
    const [destination, setDestination] = useState<Destination | null>(null)
    const { mutate: recreateVariant, isLoading } = useRecreateCollectionVariant()
    const visible = Boolean(destination)

    const closeRecreateVariant = () => setDestination(null)

    const openRecreateVariant = async (destination: Destination) => {
      setDestination(destination)
    }

    const handleRecreateVariant = async (withUpdate: boolean) => {
      const collectionId = destination?.variantInCollection?.collectionId
      const variantId = destination?.variantInCollection?.id
      if (!collectionId || !variantId) {
        return
      }
      recreateVariant({ collectionId, variantId, withUpdate })
      closeRecreateVariant()
    }

    return (
      <>
        <WrappedComponent {...props} recreateVariant={openRecreateVariant} />

        {destination && (
          <>
            <ActionModal
              open={visible}
              Icon={RefreshSingleArrowIcon}
              title="Recreate variant"
              subtitle={`Are you sure you want to recreate this variant? New ${destinationHandlers(
                destination
              ).getTitlePlural()} will be created based on this collection's template, and you will lose all the changes made in the variants. The old files will be archived in ${destinationHandlers(
                destination
              ).getCloudTitle()}.`}
              processing={isLoading}
              onBackgroundClick={closeRecreateVariant}
              btns={
                <div className="w-full flex gap-4 items-center">
                  <Button fullWidth secondaryGray text="Cancel" onClick={closeRecreateVariant} disabled={isLoading} />
                  <div className="flex flex-col w-full gap-2">
                    <Button
                      fullWidth
                      iconLeading={<UpdateIcon />}
                      text="Recreate and Update"
                      onClick={() => handleRecreateVariant(true)}
                      loading={isLoading}
                      disabled={isLoading}
                    />
                    <Button
                      fullWidth
                      secondaryColor
                      text="Recreate"
                      onClick={() => handleRecreateVariant(false)}
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              }
            />
          </>
        )}
      </>
    )
  }
}
