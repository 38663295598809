import { MouseEventHandler, PropsWithChildren } from 'react'

import { DropdownMenuItem } from '@shared/components/ui/dropdown-menu'

import styles from './menu-item.module.css'

export interface MenuItemProps extends PropsWithChildren {
  visible?: boolean
  disabled?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLDivElement>
}

export const MenuItem = ({ children, disabled, onClick, visible = true }: MenuItemProps) => {
  if (!visible) return null

  return (
    <DropdownMenuItem disabled={disabled} onClick={onClick} className={styles.menuItem}>
      {children}
    </DropdownMenuItem>
  )
}
