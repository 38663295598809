import { FC } from 'react'

import { useOrganizationsStore } from '@features/organizations'

import { Badge, EBadgeColor } from '@shared/components'
import { StripePlan } from '@shared/types'

const PLAN_CONFIG = {
  [StripePlan.Free]: 'Free trial',
  [StripePlan.Starter]: 'Starter',
  [StripePlan.Essentials]: 'Essentials',
  [StripePlan.Standard]: 'Standard',
  [StripePlan.Pro]: 'Pro',
  [StripePlan.Premium]: 'Premium',
  [StripePlan.Enterprise]: 'Enterprise',
}

export const OrganizationBadge: FC = () => {
  const { activeOrganization } = useOrganizationsStore()

  if (!activeOrganization) return null
  const stripePlan = activeOrganization.organization.stripePlan
  const planConfig = stripePlan ? PLAN_CONFIG[stripePlan] : PLAN_CONFIG[StripePlan.Free]

  return <Badge text={planConfig} color={EBadgeColor.LIGHTGRAY} />
}
