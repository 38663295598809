import { ChangeEvent, FC, useState } from 'react'
import { SingleValue } from 'react-select'

import { EmailIcon, SearchIconMd, SendRightIcon } from '@assets'

import { useSendTestEmail } from '@features/collections/api/api'
import { CollectionVariantType, ICollection, ICollectionVariant } from '@features/collections/types'
import { getVariantName } from '@features/collections/utils'
import { isEmailDestination } from '@features/destinations'
import { SelectEmailTemplate } from '@features/emails/components/select-email-template'
import { EEmailTemplateType, ESelectEmailTemplateEditorMode } from '@features/emails/types'

import { ActionModal, Button, Input, SelectInput, successToast } from '@shared/components'
import { isValidEmail } from '@shared/utils'

import styles from './collection-test-email-modal.module.css'

interface ICollectionTestEmailModalParams {
  collection: ICollection
  variants: ICollectionVariant[]
  isOpen: boolean
  onClose: () => void
  initialTemplateId?: string | null
}

export const CollectionTestEmailModal: FC<ICollectionTestEmailModalParams> = ({
  collection,
  variants,
  isOpen,
  onClose,
  initialTemplateId,
}) => {
  const { mutateAsync: sendTestEmail, isLoading } = useSendTestEmail(collection.id, {
    onSuccess: () => {
      successToast('Test email sent')
      onClose()
    },
  })

  const [recipient, setRecipient] = useState('')
  const [recipientError, setRecipientError] = useState('')
  const [selectedVariant, setSelectedVariant] = useState<ICollectionVariant | null>()
  const [selectedVariantError, setSelectedVariantError] = useState('')
  const [name, setName] = useState<string>('')
  const [emailTemplateId, setEmailTemplateId] = useState<string | null | undefined>(initialTemplateId)

  const template = collection.collectionVariantTemplate

  const moveTemplateToBottom = (variants: ICollectionVariant[]): ICollectionVariant[] => {
    const template = variants.find(v => v.type === CollectionVariantType.template)
    const variantsWithoutTemplate = variants.filter(v => v.type === CollectionVariantType.variant)
    if (template) variantsWithoutTemplate.push(template)
    return variantsWithoutTemplate
  }

  const onVariantSelected = (option: SingleValue<ICollectionVariant>) => {
    setSelectedVariantError('')
    const variant = variants.find(v => v.id === option?.id)
    if (variant && selectedVariant?.id !== variant.id) {
      setSelectedVariant(variant)
    }
  }

  const handleRecipientChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientError('')
    setRecipient(e.target.value)
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const onSend = async () => {
    if (!selectedVariant) {
      setSelectedVariantError('Variant is required')
      return
    }
    if (!recipient) {
      setRecipientError('Email is required')
      return
    }
    if (!isValidEmail(recipient)) {
      setRecipientError('Invalid email')
      return
    }

    await sendTestEmail({
      recipient,
      variantId: selectedVariant.id,
      emailTemplateId,
      name,
    })
  }

  return (
    <ActionModal
      open={isOpen}
      title="Test email"
      subtitle="Fill these information to receive a test email"
      Icon={EmailIcon}
      btns={[
        <Button key="cancel" text="Cancel" fullWidth secondaryGray onClick={onClose} />,
        <Button
          key="send"
          text="Send email"
          iconLeading={<SendRightIcon />}
          fullWidth
          onClick={onSend}
          loading={isLoading}
        />,
      ]}
      onBackgroundClick={onClose}
    >
      <section className={styles.container}>
        <div className={styles.row}>
          <SelectInput<ICollectionVariant>
            leadingIcon={<SearchIconMd />}
            value={selectedVariant}
            options={moveTemplateToBottom(variants)}
            onChange={onVariantSelected}
            label="Variant"
            placeholder="Select variant"
            containerClassName="w-full"
            getOptionLabel={variant => getVariantName(collection.name, variant.destination.name)}
            getOptionValue={variant => variant.id}
            isSearchable={true}
          />
          {selectedVariantError && <label className={styles.errorLabel}>{selectedVariantError}</label>}
        </div>
        <div className={styles.row}>
          <label htmlFor="test-recipient" className={styles.label}>
            Recipient email
          </label>
          <Input id="test-recipient" className={styles.input} value={recipient} onChange={handleRecipientChange} />
          {recipientError && <label className={styles.errorLabel}>{recipientError}</label>}
        </div>
        <div className={styles.row}>
          <label htmlFor="test-recipient-name" className={styles.label}>
            Recipient name
          </label>
          <Input id="test-recipient-name" className={styles.input} value={name} onChange={handleNameChange} />
        </div>
        {!isEmailDestination(template.destination) && (
          <div className={styles.row}>
            <label htmlFor="test-template" className={styles.label}>
              Template
            </label>
            <SelectEmailTemplate
              targetId={collection.id}
              selectClassName="w-full"
              containerClassName="w-full"
              destinationType={collection.type}
              templateType={EEmailTemplateType.collectionUpdate}
              onSelectTemplate={templateId => setEmailTemplateId(templateId)}
              initialTemplateId={emailTemplateId}
              editorMode={ESelectEmailTemplateEditorMode.SELECT_ONLY}
            />
          </div>
        )}
      </section>
    </ActionModal>
  )
}
