import { CoreDataSource, EDataSourceType } from '@features/data-sources/types/types'
import { isSpreadsheetsDataSource } from '@features/data-sources/utils'
import { isGoogleDestination, isMicrosoftDestination } from '@features/destinations'
import { Destination, EDestinationType } from '@features/destinations/types'
import { GOOGLE_DRIVE_SCOPES, userHasGDriveScope } from '@features/drive'
import { MICROSOFT_DRIVE_SCOPES, userHasOneDriveScope } from '@features/drive/one-drive/helpers'
import { UserOrganizationRole } from '@features/organizations/types'
import { EOnboardingStep, OAuthType, User } from '@features/users'

export const getOAuthDetails = (type?: EDestinationType | null) => {
  if (!type) return { oAuthType: null, scopes: [] }
  if (isGoogleDestination({ type })) {
    return { oAuthType: OAuthType.GOOGLE, scopes: GOOGLE_DRIVE_SCOPES }
  } else if (isMicrosoftDestination({ type })) {
    return { oAuthType: OAuthType.MICROSOFT, scopes: MICROSOFT_DRIVE_SCOPES }
  }
  return { oAuthType: null, scopes: [] }
}

export const getOAuthDetailsDataSource = (type?: EDataSourceType) => {
  if (!type) return { oAuthType: null, scopes: [] }
  if (isSpreadsheetsDataSource({ type })) {
    return { oAuthType: OAuthType.GOOGLE, scopes: GOOGLE_DRIVE_SCOPES }
  } else if (isMicrosoftDestination({ type })) {
    return { oAuthType: OAuthType.MICROSOFT, scopes: MICROSOFT_DRIVE_SCOPES }
  }
  return { oAuthType: null, scopes: [] }
}

export const checkUserScopes = (oAuthType: OAuthType | null, user?: User) => {
  switch (oAuthType) {
    case OAuthType.GOOGLE:
      return userHasGDriveScope(user)
    case OAuthType.MICROSOFT:
      return userHasOneDriveScope(user)
    default:
      return true
  }
}

const mapRoleToLabel = (role: UserOrganizationRole) => {
  switch (role) {
    case UserOrganizationRole.admin:
      return 'Admin'
    case UserOrganizationRole.owner:
      return 'Owner'
    case UserOrganizationRole.member:
    default:
      return 'Team member'
  }
}

export const getUserRoleOption = (role: UserOrganizationRole) => ({
  value: role,
  label: mapRoleToLabel(role),
})
const adminOption = getUserRoleOption(UserOrganizationRole.admin)
const memberOption = getUserRoleOption(UserOrganizationRole.member)

export const getAvailableRoleOptions = (isAdminOrOwner = false) =>
  isAdminOrOwner ? [memberOption, adminOption] : [memberOption]

export const isUserFinishedOnboard = (user?: User, dataSources?: CoreDataSource[], destinations?: Destination[]) => {
  if (
    (user?.onboardingStepsCompleted.includes(EOnboardingStep.datasourceAdded) || !!dataSources?.length) &&
    (user?.onboardingStepsCompleted.includes(EOnboardingStep.presentationAdded) || !!destinations?.length) &&
    (user?.onboardingStepsCompleted.includes(EOnboardingStep.syncAdded) || destinations?.some(d => d.syncs?.length))
  ) {
    return true
  }
  return false
}
