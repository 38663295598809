import noop from 'lodash/noop'
import { FC, PropsWithChildren, createContext, useContext, useState } from 'react'

import { Destination } from './types'

interface ActiveDestination {
  destinationId?: string
  collectionId?: string
  setActiveDestination: (destination: Destination | null) => void
}

const ActiveDestinationContext = createContext<ActiveDestination>({
  setActiveDestination: noop,
})

export const ActiveDestinationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeDestination, setActiveDestination] = useState<Destination | null>(null)

  const destinationId = activeDestination?.id
  const collectionId = activeDestination?.variantInCollection?.collectionId

  return (
    <ActiveDestinationContext.Provider value={{ destinationId, collectionId, setActiveDestination }}>
      {children}
    </ActiveDestinationContext.Provider>
  )
}

export const useActiveDestinationContext = () => useContext(ActiveDestinationContext)
