import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC } from 'react'

import { ICollection } from '@features/collections/types'
import {
  WithDestinationOperationsProps,
  withDestinationOperations,
} from '@features/destinations/hocs/with-destination-operations'
import { Destination } from '@features/destinations/types'

import { DestinationMenu } from './destination-menu/destination-menu'
import { useDestination } from './use-destination'

dayjs.extend(localizedFormat)

type IDestinationMenuButton = {
  destination: Destination
  collection?: ICollection
} & WithDestinationOperationsProps

const RawDestinationMenuButton: FC<IDestinationMenuButton> = ({
  destination,
  collection,
  duplicateDestination,
  duplicateOutside,
  recreateVariant,
  renameDestination,
  shareDestination,
  updateDestination,
  createVersion,
  deleteDestination,
}) => {
  const { isViewer, isEditor, isOwner, isShared } = useDestination({
    destination,
    collection,
    updateDestination,
    createVersion,
  })

  const duplicateClick = () => duplicateDestination(destination)
  const renameClick = () => renameDestination(destination, true)
  const deleteClick = () => deleteDestination(destination)
  const duplicateOutsideClick = () => duplicateOutside(destination)
  const shareDestinationClick = () => shareDestination(destination.id)
  const recreateVariantClick = () => recreateVariant(destination)

  return (
    <DestinationMenu
      viewer={isViewer}
      editor={isEditor}
      owner={isOwner}
      destination={destination}
      shared={isShared}
      renameClick={renameClick}
      deleteClick={deleteClick}
      duplicateClick={duplicateClick}
      duplicateOutsideClick={duplicateOutsideClick}
      shareDestinationClick={shareDestinationClick}
      recreateVariantClick={recreateVariantClick}
    />
  )
}

export const DestinationMenuButton = withDestinationOperations(RawDestinationMenuButton)
