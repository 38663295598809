import debounce from 'lodash/debounce'
import React, { useState } from 'react'

import { useDeleteSlides } from '@features/destinations'
import { isOAuthMissingError } from '@features/drive/error-handler'

import { Button } from '@shared/components/button/button'
import { ActionModal } from '@shared/components/modal/action/action-modal'
import { useModalWithData } from '@shared/hooks/use-modal-with-data'

interface DeleteSlidesMutation {
  slideIds: string[]
  destinationId: string
  callback?: () => void
}

export interface WithSlideDeleteProps {
  isDeletingSlides: boolean
  deleteSlides: (props: DeleteSlidesMutation) => void
}

const debounceTimeout = 250

export const withSlideDelete = <T,>(WrappedComponent: React.ComponentType<T & WithSlideDeleteProps>) => {
  return (props: T) => {
    const [destinationId, setDestinationId] = useState<string | undefined>()
    const [slideCount, setSlideCount] = useState(1)
    const [modal, slidesToDelete, toggleModal, openModalWithData] = useModalWithData<string[]>()
    const deleteSlidesQuery = useDeleteSlides(destinationId, {
      onSettled: (_, error) => {
        if (!isOAuthMissingError(error)) {
          toggleModal(false)
        }
      },
    })

    const handleDeleteSlides = () => {
      if (!slidesToDelete || !slidesToDelete.length) {
        return
      }
      deleteSlidesQuery.mutate({ slideIds: slidesToDelete })
    }

    const debouncedDeleteSlides = debounce(handleDeleteSlides, debounceTimeout, { leading: true, trailing: false })

    const triggerDeleteSlides = ({ slideIds, destinationId, callback }: DeleteSlidesMutation) => {
      setDestinationId(destinationId)
      setSlideCount(slideIds.length)
      openModalWithData(slideIds)
      callback && callback()
    }

    const deleteModalTitle = slideCount === 1 ? `Delete slide` : `Delete ${slideCount} slides`
    const deleteModalSubtitle =
      slideCount === 1 ? `Are you sure you want to delete this slide?` : `Are you sure you want to delete these slides?`

    return (
      <>
        <WrappedComponent
          {...props}
          deleteSlides={triggerDeleteSlides}
          isDeletingSlides={deleteSlidesQuery.isLoading}
        />
        <ActionModal
          open={modal}
          title={deleteModalTitle}
          subtitle={deleteModalSubtitle}
          onBackgroundClick={toggleModal}
          btns={
            <>
              <Button secondaryGray text="Cancel" onClick={toggleModal} fullWidth />
              <Button
                fullWidth
                primaryError
                text="Delete"
                onClick={debouncedDeleteSlides}
                loading={deleteSlidesQuery.isLoading}
              />
            </>
          }
        />
      </>
    )
  }
}
