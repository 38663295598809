import { FC } from 'react'

import { ISlide, destinationHandlers } from '@features/destinations'
import { Destination } from '@features/destinations/types'
import { Section } from '@features/syncs/components/sync-settings/section/section'

import { SlidePreview } from './slide-preview'

interface SlidePreviewSectionProps {
  destination: Destination
  slides: ISlide[]
  slideIndex: number
  slideId: string
  index: number
  setSlideIndex: (slideIndex: number) => void
}

export const SlidePreviewSection: FC<SlidePreviewSectionProps> = ({
  destination,
  slides,
  slideIndex,
  slideId,
  setSlideIndex,
  index,
}) => {
  const destinationHandler = destinationHandlers(destination)

  const step1 = destinationHandler.getAddVizStep1()

  return (
    step1 && (
      <Section title={`${index}) ${step1.title}`} subtitle={step1.subtitle} className="w-[500px]">
        <SlidePreview
          destination={destination}
          slides={slides}
          slideIndex={slideIndex}
          slideId={slideId}
          setSlideIndex={setSlideIndex}
        />
      </Section>
    )
  )
}
