import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC, useMemo } from 'react'

import { useGetActivitiesLogs } from '@features/destinations'
import { Destination } from '@features/destinations/types'

import { Button, Spinner } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'

import { activityTableColumns, activityTableData } from './destination-activity-table'

import styles from './destination-activity.module.css'

dayjs.extend(localizedFormat)

interface PresentationVersionsProps {
  destination: Destination
  notFoundUserName: string
}

export const DestinationActivity: FC<PresentationVersionsProps> = ({ destination, notFoundUserName }) => {
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useGetActivitiesLogs({
    destinationId: destination.id,
  })
  const activityLogs = data?.pages.flatMap(page => page.data)

  const columns = activityTableColumns()
  const tableData = useMemo(() => activityTableData(activityLogs), [activityLogs])

  return (
    <div className={styles.container}>
      {isFetching && !isFetchingNextPage && <Spinner />}
      <DataGrid data={tableData} columns={columns} EmptyState="No activity yet" />
      <Button
        secondaryGray
        text="Show more"
        disabled={!hasNextPage || isFetchingNextPage}
        onClick={() => fetchNextPage()}
        className={styles.showMoreButton}
      />
    </div>
  )
}
