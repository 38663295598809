import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'

import { Tooltip } from '@shared/components/tooltip/tooltip'

import styles from './avatar.module.css'

interface AvatarProps extends PropsWithChildren {
  name?: string
  size?: number
  className?: string
}

const DEFAULT_SIZE = 40

export const Avatar: FC<AvatarProps> = ({ name, size = DEFAULT_SIZE, children, className }) => {
  if (!name) return null

  const [firstName, surname] = name.split(' ')

  return (
    <div className={styles.container}>
      <Tooltip title={name}>
        <div className={classNames(styles.backgroundCircle, className)} style={{ height: size, width: size }}>
          {firstName[0].toUpperCase()}
          {surname && surname[0].toUpperCase()}
        </div>
      </Tooltip>
      {children}
    </div>
  )
}
