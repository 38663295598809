import classNames from 'classnames'
import dayjs from 'dayjs'
import { FC, useContext, useMemo } from 'react'

import { EmailIcon, GoogleIcon, MicrosoftIcon } from '@assets'

import {
  OAuthContext,
  OAuthType,
  useAuthStore,
  useDeleteUser,
  useRefreshGoogleAccessToken,
  useRefreshMicrosoftAccessToken,
  useRevokeGoogleAccessToken,
  useRevokeMicrosoftAccessToken,
  useUnifiedAuth,
} from '@features/users'

import { ActionModal, Button, Input } from '@shared/components'
import { useBoolean } from '@shared/hooks'

import styles from './profile-settings.module.css'

export const ProfileSettings: FC = () => {
  const { unifiedSignOut } = useUnifiedAuth()
  const { user } = useAuthStore()
  const deleteUserQuery = useDeleteUser()

  const [modal, toggleModal] = useBoolean()

  const { showAuthModal } = useContext(OAuthContext)

  const { mutate: refreshGoogleAccess, isLoading: isGoogleRefreshLoading } = useRefreshGoogleAccessToken()
  const { mutate: revokeGoogleAccess, isLoading: isGoogleRevokeLoading } = useRevokeGoogleAccessToken()

  const { mutate: refreshMicrosoftAccess, isLoading: isMicrosoftRefreshLoading } = useRefreshMicrosoftAccessToken()
  const { mutate: revokeMicrosoftAccess, isLoading: isMicrosoftRevokeLoading } = useRevokeMicrosoftAccessToken()

  const deleteUser = async () => {
    if (!user) return

    unifiedSignOut()
    await deleteUserQuery.mutateAsync(user.id)
  }

  const googleTokenLastUpdate = useMemo(() => {
    if (!user?.googleOAuth) {
      return null
    }
    return dayjs(user.googleOAuth.updatedAt).fromNow()
  }, [user?.googleOAuth])

  const googleActionButton = useMemo(() => {
    if (user?.googleOAuth) {
      return (
        <Button text="Disconnect" secondaryGray loading={isGoogleRevokeLoading} onClick={() => revokeGoogleAccess()} />
      )
    }

    return (
      <Button
        text="Connect"
        onClick={() =>
          showAuthModal({
            type: OAuthType.GOOGLE,
            title: 'Connect Google Workspace',
            message: 'Please authorize your Google account to continue',
          })
        }
      />
    )
  }, [isGoogleRevokeLoading, revokeGoogleAccess, showAuthModal, user?.googleOAuth])

  const microsoftTokenLastUpdate = useMemo(() => {
    if (!user?.microsoftOAuth) {
      return null
    }
    return dayjs(user.microsoftOAuth.updatedAt).fromNow()
  }, [user?.microsoftOAuth])

  const microsoftActionButton = useMemo(() => {
    if (user?.microsoftOAuth) {
      return (
        <Button
          text="Disconnect"
          className={styles.destinationAppActionButton}
          secondaryGray
          loading={isMicrosoftRevokeLoading}
          onClick={() => revokeMicrosoftAccess()}
        />
      )
    }

    return (
      <Button
        text="Connect"
        className={styles.destinationAppActionButton}
        onClick={() =>
          showAuthModal({
            type: OAuthType.MICROSOFT,
            title: 'Connect Microsoft 365',
            message: 'Please authorize your Microsoft account to continue',
          })
        }
      />
    )
  }, [isMicrosoftRevokeLoading, revokeMicrosoftAccess, showAuthModal, user?.microsoftOAuth])

  if (!user) return null

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>Personal info</h2>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionContent}>
          <p className={styles.text}>Name</p>
          <Input disabled value={user.name} className={styles.input} />
        </div>
        <div className={styles.sectionContent}>
          <p className={styles.text}>Email</p>
          <Input icon={<EmailIcon />} disabled value={user.email} className={styles.input} />
        </div>
      </div>

      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>Destination apps</h2>
        <span className={styles.sectionSubtitle}>
          Destination apps are the platforms containing your presentations and documents.
        </span>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionContent}>
          <div className={classNames(styles.text, styles.destinationAppSectionContent)}>
            <GoogleIcon />
            <div className={styles.destinationAppDescription}>
              <p className={styles.destinationAppTitleText}>Google workspace</p>
              <p className={styles.destinationAppSubtitleText}>
                Connect your Google account to use Google slides or Google docs
              </p>
              {user.googleOAuth && (
                <div className={styles.destinationAppConnectedText}>
                  <span>Last updated: {googleTokenLastUpdate}</span>
                  <Button
                    text="Refresh"
                    linkGray
                    disabled={isGoogleRefreshLoading}
                    onClick={() => refreshGoogleAccess()}
                  />
                </div>
              )}
            </div>
          </div>
          {googleActionButton}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionContent}>
          <div className={classNames(styles.text, styles.destinationAppSectionContent)}>
            <MicrosoftIcon />
            <div className={styles.destinationAppDescription}>
              <p className={styles.destinationAppTitleText}>Microsoft 365</p>
              <p className={styles.destinationAppSubtitleText}>
                Connect your Microsoft account to use Powerpoint or Word documents
              </p>
              {user.microsoftOAuth && (
                <div className={styles.destinationAppConnectedText}>
                  <span>Last updated: {microsoftTokenLastUpdate}</span>
                  <Button
                    text="Refresh"
                    linkGray
                    className={styles.destinationAppRefreshButton}
                    disabled={isMicrosoftRefreshLoading}
                    onClick={() => refreshMicrosoftAccess()}
                  />
                </div>
              )}
            </div>
          </div>
          {microsoftActionButton}
        </div>
      </div>

      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>Delete account</h2>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionContent}>
          <p className={styles.text}>
            Once confirmed this operation cannot be undone. This will disconnect your google account and all your data
            sources.
          </p>
          <Button text="Delete account" secondaryError onClick={toggleModal} />
        </div>
      </div>
      <ActionModal
        open={modal}
        title="Delete your account"
        subtitle="Your google account and all your data sources will be disconnected."
        onBackgroundClick={toggleModal}
        btns={
          <>
            <Button secondaryGray text="Cancel" onClick={toggleModal} fullWidth />
            <Button primaryError text="Delete" onClick={deleteUser} fullWidth />
          </>
        }
      />
    </div>
  )
}
