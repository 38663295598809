import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useState } from 'react'

type ISelected = Record<string, boolean>

export const useSelectable = (ids: string[], initialValues: string[] = [], minOneSelected: boolean = false) => {
  const [selected, setSelected] = useState<ISelected>(initialValues.reduce((acc, id) => ({ ...acc, [id]: true }), {}))

  const selectedList = useMemo(() => Object.keys(selected).filter(id => selected[id]), [selected])
  const selectedCount = useMemo(() => Object.values(selected).filter(v => v).length, [selected])
  const anySelected = useMemo(() => Object.values(selected).some(v => v), [selected])
  const allSelected = useMemo(
    () => ids.length > 0 && Object.values(selected).every(v => v) && Object.keys(selected).length === ids.length,
    [selected, ids]
  )

  const deselect = useCallback(() => {
    if (minOneSelected && selectedCount <= 1) return
    setSelected({})
  }, [minOneSelected, selectedCount])

  const handleAllSelectClick = useCallback(() => {
    if (allSelected) {
      if (minOneSelected && selectedCount <= 1) return
      deselect()
    } else {
      const newSelected = ids.reduce((acc, id) => ({ ...acc, [id]: true }), {})
      setSelected(newSelected)
    }
  }, [allSelected, deselect, ids, minOneSelected, selectedCount])

  const handleSelectClick = useCallback(
    (ids: string | string[]) => {
      if (Array.isArray(ids)) {
        const newSelected = ids.reduce((acc, id) => ({ ...acc, [id]: !selected[id] }), selected)
        if (minOneSelected && Object.values(newSelected).filter(v => v).length === 0) return
        setSelected(newSelected)
        return
      }

      const newSelected = { ...selected }
      newSelected[ids] = !newSelected[ids]
      if (minOneSelected && Object.values(newSelected).filter(v => v).length === 0) return
      setSelected(newSelected)
    },
    [selected, minOneSelected]
  )

  useEffect(() => {
    const newSelected = Object.keys(selected).reduce((acc, id) => {
      if (ids.includes(id)) {
        acc[id] = selected[id]
      }
      return acc
    }, {} as ISelected)

    if (!isEqual(newSelected, selected)) {
      setSelected(newSelected)
    }
  }, [ids, selected])

  return {
    selected,
    anySelected,
    allSelected,
    selectedList,
    selectedCount,
    deselect,
    setSelected,
    handleSelectClick,
    handleAllSelectClick,
  }
}
