import { useState } from 'react'

import { useSyncDestination } from '@features/destinations/api/api'
import { MicrosoftLockWarning } from '@features/destinations/components/modals/microsoft-lock-warning'
import { Destination } from '@features/destinations/types'
import { isMicrosoftDestination } from '@features/destinations/utils'

import { useModalWithData } from '@shared/hooks/use-modal-with-data'

export interface WithDestinationUpdateProps {
  updateDestination: (presentation: Destination) => Promise<void>
  updateLoading: boolean
}

export const withDestinationUpdate = <T,>(WrappedComponent: React.ComponentType<T & WithDestinationUpdateProps>) => {
  return (props: T) => {
    const [loading, setLoading] = useState(false)

    const [modal, destination, toggleModal, openModalWithData] = useModalWithData<Destination>()
    const syncPresentationsQuery = useSyncDestination({
      destination,
    })

    const updateDestination = async () => {
      if (!destination) return

      if (isMicrosoftDestination(destination)) {
        toggleModal()
      }
      await syncPresentationsQuery.mutateAsync(destination)
    }

    const updateHandler = async (destination: Destination) => {
      if (isMicrosoftDestination(destination)) {
        setLoading(true)
        openModalWithData(destination)
      } else {
        await syncPresentationsQuery.mutateAsync(destination)
      }
    }

    const closeModal = () => {
      setLoading(false)
      toggleModal()
    }

    return (
      <>
        <WrappedComponent {...props} updateDestination={updateHandler} updateLoading={loading} />
        <MicrosoftLockWarning open={modal} onClose={closeModal} onOk={updateDestination} type={destination?.type} />
      </>
    )
  }
}
