import { GetFilterValuesParams } from '@features/collections/types'
import { toOption } from '@features/collections/utils'
import {
  getLookerFilterValues,
  getLookerFilterValuesQueryKey,
  getMetabaseFilterValues,
  getMetabaseFilterValuesQueryKey,
} from '@features/data-sources'
import { FilterType } from '@features/filters/types'

import { queryClient } from '@shared/http'

import { getTableauFilterValuesQueryKey, useGetTableauFilterValues } from './use-get-tableau-filter-values'

export const useGetFilterValues = (type: FilterType, params: GetFilterValuesParams) => {
  const { getTableauFilterValues } = useGetTableauFilterValues(params, {
    enabled: type === FilterType.TableauFilter || type === FilterType.TableauParams,
  })

  const handleLoadOptions = async (inputValue: string) => {
    const loadOptionsArgs = { ...params, query: inputValue }

    let queryKey, queryFn
    switch (type) {
      case FilterType.TableauFilter:
      case FilterType.TableauParams:
        queryKey = getTableauFilterValuesQueryKey(loadOptionsArgs)
        queryFn = () => getTableauFilterValues(loadOptionsArgs)
        break
      case FilterType.LookerFilter:
        queryKey = getLookerFilterValuesQueryKey(loadOptionsArgs)
        queryFn = () => getLookerFilterValues(loadOptionsArgs)
        break
      case FilterType.MetabaseFilter:
        queryKey = getMetabaseFilterValuesQueryKey(loadOptionsArgs)
        queryFn = () => getMetabaseFilterValues(loadOptionsArgs)
        break
      default:
        throw new Error('Invalid data source')
    }

    return (await queryClient.fetchQuery(queryKey, queryFn)).map(toOption)
  }

  return {
    handleLoadOptions,
  }
}
