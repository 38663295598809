import { FC } from 'react'

import { IIcon } from '@assets/types'

const DEFAULT_SIZE = 16
export const SlashCircle: FC<IIcon> = ({ size = DEFAULT_SIZE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_6169_281707)">
        <path
          d="M3.28634 3.28536L12.713 12.712M14.6663 7.9987C14.6663 11.6806 11.6816 14.6654 7.99967 14.6654C4.31778 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31778 1.33203 7.99967 1.33203C11.6816 1.33203 14.6663 4.3168 14.6663 7.9987Z"
          stroke="#E11A0C"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6169_281707">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
