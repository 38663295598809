import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useOrganizationsStore } from '@features/organizations'
import { useAuthStore } from '@features/users'

import { useBoolean } from '@shared/hooks'
import { StripePlan } from '@shared/types'

import { FreeTrialToast } from './components/toast/free-trial-toast'

dayjs.extend(utc)

interface IFreeTrialContext {
  show: () => void
  freeTrial: (cb?: () => void, options?: { allowedExtraFreeUpdate?: boolean }) => void
  freeTrialExpired: boolean
  freeUpdatesRemaining: number
}

export const FreeTrialContext = createContext<IFreeTrialContext>({
  show: () => {},
  freeTrial: () => {},
  freeTrialExpired: false,
  freeUpdatesRemaining: 0,
})

export const FreeTrialProvider: FC<PropsWithChildren> = ({ children }) => {
  const [freeTrialExpired, setFreeTrialExpired] = useBoolean()
  const [freeUpdatesRemaining, setFreeUpdatesRemaining] = useState(0)
  const { activeOrganization } = useOrganizationsStore()
  const { user } = useAuthStore()

  useEffect(() => {
    if (!activeOrganization || !user) {
      return
    }

    const isFreePlan = activeOrganization.organization.stripePlan
      ? activeOrganization.organization.stripePlan === StripePlan.Free
      : true

    const isAfterFreeTrialEnded = activeOrganization.organization.freeTrialEndDate
      ? dayjs.utc(activeOrganization.organization.freeTrialEndDate).isBefore(dayjs.utc())
      : false

    setFreeTrialExpired(isFreePlan && isAfterFreeTrialEnded)
    setFreeUpdatesRemaining(user.freeUpdatesRemaining)
  }, [activeOrganization, user, setFreeTrialExpired, setFreeUpdatesRemaining])

  const show = () => {
    toast(<FreeTrialToast />, { autoClose: false, type: 'warning' })
  }

  const freeTrial = async (cb?: () => Promise<void> | void, options?: { allowedExtraFreeUpdate?: boolean }) => {
    if (freeTrialExpired && (!options?.allowedExtraFreeUpdate || freeUpdatesRemaining <= 0)) {
      show()
    } else {
      if (cb) return cb()
    }
  }

  return (
    <FreeTrialContext.Provider value={{ show, freeTrial, freeTrialExpired, freeUpdatesRemaining }}>
      {children}
    </FreeTrialContext.Provider>
  )
}
