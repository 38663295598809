import classNames from 'classnames'
import { FC } from 'react'

import styles from './chevron-down-up.module.css'

interface IChevronDownUp {
  isUp?: boolean
}

export const ChevronDownUp: FC<IChevronDownUp> = ({ isUp }) => {
  const chevronClass = classNames(styles.chevron, isUp && styles.chevronUp)

  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" className={chevronClass}>
      <path
        d="M5.16309 8.18164L10.1631 13.6362L15.1631 8.18164"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
