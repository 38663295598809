import { dataSourcesModalsAssetsUrlsBase } from '@assets'

import { useShowTutorialModals } from '@features/users'

import { TutorialModal } from '@shared/components'

interface DataSourcesModalProps {
  visible: boolean
  setModalOpen: (param: boolean) => void
}

export const DataSourcesTutorial = ({ visible, setModalOpen }: DataSourcesModalProps) => {
  const { mutateAsync: updateShowTutorialModals, isLoading } = useShowTutorialModals()

  const handleDismiss = async () => {
    if (isLoading) {
      return
    }
    await updateShowTutorialModals({
      data: {
        showDataSourcesModal: false,
      },
    })
    setModalOpen(false)
  }

  return (
    <TutorialModal
      visible={visible}
      onDismiss={handleDismiss}
      contents={[
        {
          imageUrl: `${dataSourcesModalsAssetsUrlsBase}/data-sources-1.png`,
          title: 'Data sources',
          description:
            'A data source represents the BI tool where business data is stored. We support Tableau, Looker and Metabase, and we are actively working to add more.',
        },
      ]}
    />
  )
}
