import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultIconSize = 24

export const GoogleIcon: FC<IIcon> = ({ size = defaultIconSize }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7511 10.1951C18.7511 9.47569 18.6915 8.95069 18.5626 8.40625H10.1797V11.6534H15.1003C15.0011 12.4604 14.4654 13.6757 13.2749 14.4923L13.2582 14.601L15.9087 16.6133L16.0924 16.6312C17.7788 15.1048 18.7511 12.859 18.7511 10.1951"
        fill="#4285F4"
      />
      <path
        d="M10.1788 18.75C12.5895 18.75 14.6133 17.9722 16.0915 16.6305L13.274 14.4916C12.5201 15.0068 11.5081 15.3666 10.1788 15.3666C7.81773 15.3666 5.81379 13.8402 5.09944 11.7305L4.99473 11.7392L2.23868 13.8295L2.20264 13.9277C3.67087 16.786 6.68674 18.75 10.1788 18.75Z"
        fill="#34A853"
      />
      <path
        d="M5.10014 11.7302C4.91165 11.1858 4.80257 10.6024 4.80257 9.99967C4.80257 9.39686 4.91165 8.81355 5.09022 8.26911L5.08523 8.15316L2.29464 6.0293L2.20333 6.07186C1.5982 7.25798 1.25098 8.58995 1.25098 9.99967C1.25098 11.4094 1.5982 12.7413 2.20333 13.9274L5.10014 11.7302"
        fill="#FBBC05"
      />
      <path
        d="M10.1789 4.63331C11.8554 4.63331 12.9864 5.34303 13.6312 5.93612L16.1511 3.525C14.6035 2.11528 12.5895 1.25 10.1789 1.25C6.68676 1.25 3.67088 3.21387 2.20264 6.07218L5.08953 8.26943C5.81381 6.15972 7.81776 4.63331 10.1789 4.63331"
        fill="#EB4335"
      />
    </svg>
  )
}
