import { FC } from 'react'

export const DotsGrid: FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M10.0007 5.07585C10.4609 5.07585 10.834 4.70275 10.834 4.24251C10.834 3.78228 10.4609 3.40918 10.0007 3.40918C9.54041 3.40918 9.16732 3.78228 9.16732 4.24251C9.16732 4.70275 9.54041 5.07585 10.0007 5.07585Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0007 10.9092C10.4609 10.9092 10.834 10.5361 10.834 10.0758C10.834 9.61561 10.4609 9.24251 10.0007 9.24251C9.54041 9.24251 9.16732 9.61561 9.16732 10.0758C9.16732 10.5361 9.54041 10.9092 10.0007 10.9092Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0007 16.7425C10.4609 16.7425 10.834 16.3694 10.834 15.9092C10.834 15.4489 10.4609 15.0758 10.0007 15.0758C9.54041 15.0758 9.16732 15.4489 9.16732 15.9092C9.16732 16.3694 9.54041 16.7425 10.0007 16.7425Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.834 5.07585C16.2942 5.07585 16.6673 4.70275 16.6673 4.24251C16.6673 3.78228 16.2942 3.40918 15.834 3.40918C15.3737 3.40918 15.0007 3.78228 15.0007 4.24251C15.0007 4.70275 15.3737 5.07585 15.834 5.07585Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.834 10.9092C16.2942 10.9092 16.6673 10.5361 16.6673 10.0758C16.6673 9.61561 16.2942 9.24251 15.834 9.24251C15.3737 9.24251 15.0007 9.61561 15.0007 10.0758C15.0007 10.5361 15.3737 10.9092 15.834 10.9092Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.834 16.7425C16.2942 16.7425 16.6673 16.3694 16.6673 15.9092C16.6673 15.4489 16.2942 15.0758 15.834 15.0758C15.3737 15.0758 15.0007 15.4489 15.0007 15.9092C15.0007 16.3694 15.3737 16.7425 15.834 16.7425Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16732 5.07585C4.62755 5.07585 5.00065 4.70275 5.00065 4.24251C5.00065 3.78228 4.62755 3.40918 4.16732 3.40918C3.70708 3.40918 3.33398 3.78228 3.33398 4.24251C3.33398 4.70275 3.70708 5.07585 4.16732 5.07585Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16732 10.9092C4.62755 10.9092 5.00065 10.5361 5.00065 10.0758C5.00065 9.61561 4.62755 9.24251 4.16732 9.24251C3.70708 9.24251 3.33398 9.61561 3.33398 10.0758C3.33398 10.5361 3.70708 10.9092 4.16732 10.9092Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16732 16.7425C4.62755 16.7425 5.00065 16.3694 5.00065 15.9092C5.00065 15.4489 4.62755 15.0758 4.16732 15.0758C3.70708 15.0758 3.33398 15.4489 3.33398 15.9092C3.33398 16.3694 3.70708 16.7425 4.16732 16.7425Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
