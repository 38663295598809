import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { ButtonHTMLAttributes, LegacyRef } from 'react'

import { SmallSpinner } from '@assets/icons'

import styles from './button.module.css'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  fill?: string
  stroke?: string
  fullWidth?: boolean
  secondaryColor?: boolean
  secondaryGray?: boolean
  tertiaryColor?: boolean
  tertiaryGray?: boolean
  linkColor?: boolean
  linkGray?: boolean
  primaryError?: boolean
  secondaryError?: boolean
  tertiaryError?: boolean
  linkError?: boolean
  loading?: boolean
  iconLeading?: JSX.Element | null
  iconTrailing?: JSX.Element | null
  dataTestId?: string
  stopPropagation?: boolean
  ref?: LegacyRef<HTMLButtonElement>
}

const DEBOUNCE_TIME = 500

export const Button = ({
  text,
  fill,
  stroke,
  secondaryColor,
  secondaryGray,
  tertiaryColor,
  tertiaryGray,
  linkColor,
  linkGray,
  primaryError,
  secondaryError,
  tertiaryError,
  linkError,
  disabled,
  iconLeading,
  iconTrailing,
  loading,
  className,
  fullWidth,
  onClick,
  dataTestId,
  ref,
  stopPropagation = false,
  ...props
}: IButton) => {
  const defaultClass = classNames(
    styles.button,
    !disabled && 'cursor-pointer',
    secondaryColor && styles.secondaryColor,
    secondaryGray && styles.secondaryGray,
    tertiaryColor && styles.tertiaryColor,
    tertiaryGray && styles.tertiaryGray,
    linkColor && styles.linkColor,
    linkGray && styles.linkGray,
    disabled && styles.disabled,
    primaryError && styles.primaryError,
    secondaryError && styles.secondaryError,
    tertiaryError && styles.tertiaryError,
    linkError && styles.linkError,
    loading && styles.loading,
    fullWidth && styles.fullWidth,
    className
  )

  const buttonContentClass = classNames(styles.buttonContent, loading && styles.buttonLoadingContent)

  const onClickDebounced = debounce(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) e.stopPropagation()
      if (onClick) {
        onClick(e)
      }
    },
    DEBOUNCE_TIME,
    { leading: true, trailing: false }
  )

  return (
    <button
      ref={ref as LegacyRef<HTMLButtonElement>}
      className={defaultClass}
      onClick={onClickDebounced}
      style={{ fill, stroke }}
      disabled={disabled || loading}
      data-testid={dataTestId}
      {...props}
    >
      <div className={buttonContentClass}>
        {iconLeading}
        {text && <span>{text}</span>}
        {iconTrailing}
      </div>
      <div className={styles.spinnerClass}>{loading && <SmallSpinner />}</div>
    </button>
  )
}
