import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import {
  useGetOrganizationUsers,
  useRemoveInvite,
  useRemoveUserFromOrganization,
  useUpdateOrganization,
} from '@features/organizations'
import { useOrganizationsStore } from '@features/organizations'
import { OrganizationUser, UserOrganizationRole } from '@features/organizations/types'
import { useVerifyTenant } from '@features/users'

import {
  removeInviteFromOrgToast,
  removeMemberFromOrgToast,
  updateOrgNameToast,
  updateOrgSlugToast,
} from '@shared/components'
import { useBoolean } from '@shared/hooks'
import { queryClient } from '@shared/http'

export const useOrganizationSettings = () => {
  const navigate = useNavigate()
  const { activeOrganization, isAdmin, isOwner } = useOrganizationsStore()
  const org = activeOrganization?.organization
  const [inviteModal, toggleInviteModal] = useBoolean()
  const [removeMemberModal, toggleRemoveMemberModal] = useBoolean()
  const [newOrgName, setNewOrgName] = useState(org?.name || '')
  const [newOrgSlug, setNewOrgSlug] = useState(org?.slug || '')
  const [memberToRemove, setMemberToRemove] = useState<OrganizationUser | null>(null)
  const { mutateAsync: updateOrganization } = useUpdateOrganization(org?.id)
  const { data: users } = useGetOrganizationUsers(org?.id)
  const { mutateAsync: removeUser } = useRemoveUserFromOrganization()
  const { mutateAsync: removeInvite } = useRemoveInvite()
  const verifyTenantQuery = useVerifyTenant()

  const saveIsDisabled = useMemo(
    () => activeOrganization?.role !== UserOrganizationRole.owner || newOrgName === org?.name,
    [activeOrganization, newOrgName, org]
  )

  const [changeOrgSlugIsDisabled, setChangeOrgSlugIsDisabled] = useState(Boolean(org?.slug))

  useEffect(() => {
    const verifySlug = async () => {
      if (!org || !org.slug) return
      const { data: connections } = await verifyTenantQuery.mutateAsync(org.slug)
      setChangeOrgSlugIsDisabled(connections.length > 0)
    }

    if (org && org.slug) {
      verifySlug()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.slug])

  const saveOrgSlugIsDisabled = useMemo(
    () => activeOrganization?.role !== UserOrganizationRole.owner || newOrgSlug === org?.slug,
    [activeOrganization, newOrgSlug, org]
  )

  const changeNameIsDisabled = useMemo(
    () => activeOrganization?.role !== UserOrganizationRole.owner,
    [activeOrganization]
  )

  const saveOrgName = async () => {
    await updateOrganization({ name: newOrgName })
    updateOrgNameToast()
  }

  const saveOrgSlug = async () => {
    await updateOrganization({ slug: newOrgSlug })
    updateOrgSlugToast()
  }

  const addMember = () => toggleInviteModal()

  const startRemoveMemberProcess = (email: string) => {
    if (!users) return
    const user = users.find(user => user.email === email)

    if (!user) return

    setMemberToRemove(user)
    toggleRemoveMemberModal()
  }

  const removeMember = async () => {
    if (!org || !memberToRemove) return

    if (memberToRemove.userId) {
      await removeUser({ organizationId: org.id, userId: memberToRemove.userId })
      removeMemberFromOrgToast()
      await queryClient.invalidateQueries(['organizationUsers', org.id])
    } else if (memberToRemove.inviteId) {
      await removeInvite(memberToRemove.inviteId)
      removeInviteFromOrgToast()
    }

    toggleRemoveMemberModal()
  }

  const configureSSO = () => {
    navigate(Paths.SETTINGS_ORGANIZATION_SSO)
  }

  const configureDirectorySync = () => {
    navigate(Paths.SETTINGS_ORGANIZATION_DIRECTORY_SYNC)
  }

  useEffect(() => {
    setNewOrgName(activeOrganization?.organization.name || '')
  }, [activeOrganization])

  return {
    users,
    isOwner,
    isAdmin,
    newOrgName,
    newOrgSlug,
    inviteModal,
    saveIsDisabled,
    saveOrgSlugIsDisabled,
    changeOrgSlugIsDisabled,
    removeMemberModal,
    changeNameIsDisabled,
    organizationId: activeOrganization?.organizationId,
    plan: activeOrganization?.organization.stripePlan,
    addMember,
    configureSSO,
    configureDirectorySync,
    toggleInviteModal,
    saveOrgName,
    saveOrgSlug,
    removeMember,
    setNewOrgName,
    setNewOrgSlug,
    startRemoveMemberProcess,
    toggleRemoveMemberModal,
  }
}
