import { FC } from 'react'

import { CBInsights, MondayDotCom, MonzoBank, OctopusEnergy, OnePassword, Scopely, TheOrchard } from '@assets'
import { Kargo } from '@assets/customer-logos/kargo'

import { CompanyLogo } from '@shared/components'

export const TrustedBy: FC = () => {
  return (
    <div className="flex flex-col gap-8">
      <h2 className="flex text-sm-medium justify-center">Trusted by Industry Leaders:</h2>
      <div className="flex w-[520px] justify-center items-center gap-2 flex-wrap filter grayscale-[100%]">
        <CompanyLogo logo={<OctopusEnergy />} />
        <CompanyLogo logo={<MondayDotCom />} />
        <CompanyLogo logo={<OnePassword />} />
        <CompanyLogo logo={<Kargo />} />
        <CompanyLogo logo={<CBInsights />} />
        <CompanyLogo logo={<MonzoBank />} />
        <CompanyLogo logo={<Scopely />} />
        <CompanyLogo logo={<TheOrchard />} />
      </div>
    </div>
  )
}
