import clsx from 'clsx'
import { FC } from 'react'

interface Props {
  className?: string
}

export const Divider: FC<Props> = ({ className }) => {
  return <div className={clsx('w-full border-t border-gray-200', className)} />
}
