import { Crisp } from 'crisp-sdk-web'
import { FC, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Paths, buildVisualizationAddPath } from '@pages/routes/paths'

import { ArrowNarrow, MessageSquareIcon, OpenBookIcon } from '@assets'

import { useDataSourcesStore } from '@features/data-sources'
import { useDestinationsStore } from '@features/destinations'
import { OnboardingStep } from '@features/onboarding/components/onboarding-step/onboarding-step'
import { useOrganizationsStore } from '@features/organizations'
import { EOnboardingStep, isUserFinishedOnboard } from '@features/users'
import { useAuthStore } from '@features/users'

import { Button } from '@shared/components/button/button'
import { ROLLSTACK_DOCS_URL } from '@shared/constants'

import styles from './onboarding.module.css'

export const Onboarding: FC = () => {
  const { user } = useAuthStore()
  const { isAdmin } = useOrganizationsStore()
  const { destinations } = useDestinationsStore()
  const { dataSources } = useDataSourcesStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (isUserFinishedOnboard(user, dataSources, destinations)) {
      navigate(Paths.DESTINATIONS, { replace: true })
    }
  }, [user, dataSources, destinations, navigate])

  const dataSourceAdded = useMemo(() => {
    if (user?.onboardingStepsCompleted.includes(EOnboardingStep.datasourceAdded)) {
      return true
    }
    return !!dataSources?.length
  }, [user, dataSources])

  const presentationAdded = useMemo(() => {
    if (user?.onboardingStepsCompleted.includes(EOnboardingStep.presentationAdded)) {
      return true
    }
    return !!destinations?.length
  }, [user, destinations])

  const syncAdded = useMemo(() => {
    if (user?.onboardingStepsCompleted.includes(EOnboardingStep.syncAdded)) {
      return true
    }
    return !!destinations?.some(d => !!d.syncs?.length)
  }, [user, destinations])

  const onDocsClick = () => {
    window.open(ROLLSTACK_DOCS_URL, '_blank')
  }

  const onChatClick = () => {
    Crisp.chat.open()
  }

  const onConnectDataSourceClick = () => {
    isAdmin ? navigate(Paths.ADD_DATA_SOURCE) : navigate(Paths.DATA_SOURCES)
  }

  const onConnectPresentationClick = () => {
    navigate(Paths.ADD_DESTINATION)
  }

  const onAddVisualizationClick = () => {
    const selectedDestination = destinations?.[0]
    if (selectedDestination) {
      navigate(buildVisualizationAddPath(selectedDestination.id))
    } else {
      navigate(Paths.DESTINATIONS)
    }
  }

  return (
    <main className={styles.container}>
      <section>
        <h1 className={styles.header}>Welcome to Rollstack! Let’s get you started.</h1>
        <h3 className={styles.subHeader}>Connect a data source, a destination then add visualizations</h3>
      </section>
      <section className={styles.stepsContainer}>
        <OnboardingStep
          title="Connect a data source"
          text="The first step is to connect a data source. This can be a BI tool or any other tool where your data lives"
          buttonText="Connect"
          completed={dataSourceAdded}
          completedButtonText="Connect another data source"
          onBtnClick={onConnectDataSourceClick}
        />
        <span className={styles.arrowContainer}>
          <ArrowNarrow orientation="right" size={24} />
        </span>
        <OnboardingStep
          title="Add a destination"
          text="Connect your presentations or documents to start automating them"
          buttonText="Add a destination"
          completed={presentationAdded}
          disabled={!dataSourceAdded}
          completedButtonText="Add another destination"
          onBtnClick={onConnectPresentationClick}
        />
        <span className={styles.arrowContainer}>
          <ArrowNarrow orientation="right" size={24} />
        </span>
        <OnboardingStep
          title="Add visualizations"
          text="Import data and visualizations from your data sources into your presentations or documents"
          buttonText="Add visualizations"
          completed={syncAdded}
          completedButtonText="Modify"
          disabled={!presentationAdded}
          onBtnClick={onAddVisualizationClick}
        />
      </section>
      <section className={styles.helpContainer}>
        <p className={styles.helpTitle}>Need help?</p>
        <p className={styles.helpText}>
          Have a look at the documentation and learn about what you can do with Rollstack. If you have any issue or need
          help, please reach out to us.
        </p>
        <div className={styles.helpButtonContainer}>
          <Button
            className={styles.helpButton}
            secondaryColor
            iconLeading={<OpenBookIcon />}
            text="Documentation"
            onClick={onDocsClick}
          />
          <Button
            className={styles.helpButton}
            secondaryColor
            iconLeading={<MessageSquareIcon />}
            text="Chat with us"
            onClick={onChatClick}
          />
        </div>
      </section>
    </main>
  )
}
