import cloneDeep from 'lodash/cloneDeep'

import { ITableauFilterObject, ITableauParameterObject } from '@features/embedding/tableau/types'
import { IFilter } from '@features/filters/types'

import { getEmptyEmailConfig } from '@shared/components/email-config/utils'
import { queryClient } from '@shared/http'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'

import {
  CollectionFilter,
  ICollection,
  ICollectionEmailConfigCreateRequest,
  VariantOption,
  collectionFilterTypeToNumber,
} from './types'

export const invalidateCollectionVariants = async (collection: ICollection) => {
  await Promise.all(
    collection.collectionVariants.map(async variant => {
      await queryClient.invalidateQueries(['presentation', { destinationId: variant.destination.id }])
      await queryClient.invalidateQueries(['activities-logs', { destinationId: variant.destination.id }])
    })
  )
}

export const getVariantName = (collectionName: string, variantName: string): string =>
  variantName.replace(`${collectionName} - `, '')

export function filterTableauFiltersForCollection(
  dbFilters: IFilter[],
  embedFilters: ITableauFilterObject[],
  embedParameters: ITableauParameterObject[]
): IFilter[] {
  const showSuggestableFilters = isFeatureEnabled('show-suggestable-filters')
  const filteredEmbedFilters = cloneDeep(embedFilters).filter(
    filter => filter._filterType === 'categorical' && (filter?.domain?.values ?? []).length > 0
  )

  const filteredEmbedParameters = cloneDeep(embedParameters).filter(
    parameter => showSuggestableFilters || parameter.allowableValues?.allowableValues?.length > 0
  )

  const embedFilterIds = filteredEmbedFilters.map(filter => filter._fieldId)
  const embedParameterIds = filteredEmbedParameters.map(parameter => parameter.id)
  const combinedIds = [...embedFilterIds, ...embedParameterIds]

  return dbFilters.filter(dbFilter => combinedIds.includes(dbFilter.dashboardFilterId))
}

export const toOption = (option: string) => ({
  label: option,
  value: option,
})

export const sortOptionByValue = (option1: VariantOption, option2: VariantOption) =>
  option1.value.localeCompare(option2.value)

export const sortByCollectionFilter = (cfa: CollectionFilter, cfb: CollectionFilter) =>
  collectionFilterTypeToNumber[cfa.type] - collectionFilterTypeToNumber[cfb.type]

export const getEmptyCollectionEmailConfig = (collectionId?: string): ICollectionEmailConfigCreateRequest => {
  if (!collectionId) {
    throw new Error('collectionId is required')
  }
  return {
    collectionId: collectionId,
    ...getEmptyEmailConfig(),
  }
}
