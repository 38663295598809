import { TrashIcon } from '@assets'

import { MenuItem, MenuItemProps } from '@shared/components/menu-item/menu-item'

export const DeleteItem = (props: MenuItemProps) => {
  return (
    <MenuItem {...props}>
      <TrashIcon />
      {'Delete'}
    </MenuItem>
  )
}
