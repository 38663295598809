import { useRef } from 'react'

import { useEventListener } from './use-event-listener'

export const useClickOutside = (cb: (e: React.MouseEvent) => void, element: any = window) => {
  const ref = useRef<any>(null)

  useEventListener<React.MouseEvent>(
    'click',
    e => {
      if (!ref.current) return

      if (ref.current.contains(e.target as Node) || ref.current === e.target) return

      cb(e)
    },
    element
  )

  return ref
}
