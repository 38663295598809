import { collectionModalsAssetsUrlsBase } from '@assets'

import { destinationHandlers } from '@features/destinations/destination-handlers'
import { EDestinationType } from '@features/destinations/types'
import { useShowTutorialModals } from '@features/users'

import { TutorialModal } from '@shared/components'

interface TemplateExplainerModalProps {
  visible: boolean
  setModalOpen: (param: boolean) => void
  type: EDestinationType
}

export const TemplateExplainerModal = ({ visible, setModalOpen, type }: TemplateExplainerModalProps) => {
  const collectionHandler = destinationHandlers(type)
  const cloudApp = collectionHandler.getCloudAppTitle()
  const { mutateAsync: updateShowTutorialModals, isLoading } = useShowTutorialModals()

  const handleDismiss = async () => {
    if (isLoading) {
      return
    }
    await updateShowTutorialModals({
      data: {
        showTemplateExplainerModal: false,
      },
    })
    setModalOpen(false)
  }

  return (
    <TutorialModal
      visible={visible}
      onDismiss={handleDismiss}
      contents={[
        {
          imageUrl: `${collectionModalsAssetsUrlsBase}/template-explainer-1.png`,
          title: 'Collection templates',
          description: 'Changes made to the template in Rollstack will be propagated to the variants.',
        },
        {
          imageUrl: `${collectionModalsAssetsUrlsBase}/template-explainer-2.png`,
          title: 'Changes outside Rollstack',
          description: `However, changes made to the template directly in ${cloudApp} will not be reflected in the variants.`,
        },
        {
          imageUrl: `${collectionModalsAssetsUrlsBase}/template-explainer-3.png`,
          description: `If you wish to see the changes made in ${cloudApp} reflected in the variants, you can recreate them. Be aware that specific changes made to variants will be lost.`,
        },
      ]}
    />
  )
}
