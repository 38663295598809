import classNames from 'classnames'
import { FC } from 'react'

import { Loader } from '@assets/icons/loader'

import styles from './spinner.module.css'

interface ISpinner {
  center?: boolean
  localCenter?: boolean
  className?: string
}

export const Spinner: FC<ISpinner> = ({ className, center = true, localCenter = false }) => {
  const wrapperClass = classNames(center && styles.center, localCenter && styles.localCenter, className)

  return (
    <div className={wrapperClass}>
      <Loader />
    </div>
  )
}
