import { ILookerDashboard, ILookerFolder } from '@features/embedding/looker/types'

import { rootFolderId } from '@shared/components'

export const getFolderName = (collection: ILookerFolder) => collection.name
export const getFolderId = (collection: ILookerFolder) => collection.id.toString()
export const getParentFolderId = (collection: ILookerFolder) => collection.parent_id?.toString() || rootFolderId

export const getFileName = (dashboard: ILookerDashboard) => dashboard.title
export const getFileFolderId = (dashboard: ILookerDashboard) => dashboard.folder.id?.toString() || rootFolderId
