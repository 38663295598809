import { FC, PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react'

import { GDocsIcon, GSlidesIcon, GoogleIcon, MicrosoftIcon, MsPowerPointIcon, MsWordIcon } from '@assets'

import { EDestinationType } from '@features/destinations/types'
import { GOOGLE_PERMISSION_WAIT_TIME, getGooglePickerViewId, useDrivePicker } from '@features/drive'
import { useAuthStore } from '@features/users/auth-store'

import { ActionModal, Button, incorrectFileSelectedToast } from '@shared/components'
import { config } from '@shared/config'
import { sleep } from '@shared/http'
import { EDataSourceType } from '@features/data-sources/types/types'

export enum FilePickerFolderType {
  GoogleFolder = 'GoogleFolder',
  GoogleUnknown = 'GoogleUnknown',
  MicrosoftFolder = 'MicrosoftFolder',
  MicrosoftUnknown = 'MicrosoftUnknown',
}
export type FilePickerType = EDestinationType | FilePickerFolderType | EDataSourceType
type SuccessCallbackFn = (params: { id: string; name: string }[]) => void
export interface ShowFilePickerParams {
  type: FilePickerType
  itemName?: string
  itemId?: string
  modalTitle?: string
  modalMessage?: string
  skipSelectedItemIdValidation?: boolean
  successCallback?: SuccessCallbackFn
  handleClose?: () => void
}

export interface FilePickerContextProps {
  showFilePickerModal: (params: ShowFilePickerParams) => void
  hideFilePickerModal: () => void
}

export const FilePickerContext = createContext<FilePickerContextProps>({
  showFilePickerModal: () => {},
  hideFilePickerModal: () => {},
})

export const FilePickerProvider: FC<PropsWithChildren> = ({ children }) => {
  const { clientId, apiKey } = config.google
  const user = useAuthStore(state => state.user)
  const { openPicker: openGoogleDrivePicker } = useDrivePicker()
  const [filePickerModalVisible, setFilePickerModalVisible] = useState(false)

  const [isWaitingForGoogleDrivePermission, setIsWaitingForGoogleDrivePermission] = useState(false)

  const [type, setType] = useState<FilePickerType>()
  const [itemName, setItemName] = useState<string | undefined>()
  const [itemId, setItemId] = useState<string | undefined>()
  const [title, setTitle] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()
  const [skipSelectedItemIdValidation, setSkipSelectedItemIdValidation] = useState<boolean | undefined>()
  const [successCallback, setSuccessCallback] = useState<SuccessCallbackFn | undefined>()
  const [handleClose, setHandleClose] = useState<() => void | undefined>()

  const isGoogle = useMemo(
    () =>
      type === FilePickerFolderType.GoogleFolder ||
      type === EDestinationType.GoogleDocs ||
      type === EDestinationType.GoogleSlides ||
      type === FilePickerFolderType.GoogleUnknown,
    [type]
  )
  const isMicrosoft = useMemo(
    () =>
      type === FilePickerFolderType.MicrosoftFolder ||
      type === EDestinationType.MicrosoftWord ||
      type === EDestinationType.MicrosoftPowerPoint ||
      type === FilePickerFolderType.MicrosoftUnknown,
    [type]
  )

  const showFilePickerModal = ({
    type,
    itemName,
    itemId,
    modalTitle,
    modalMessage,
    skipSelectedItemIdValidation,
    handleClose,
    successCallback,
  }: ShowFilePickerParams) => {
    setType(type)
    setItemName(itemName)
    setItemId(itemId)
    setSkipSelectedItemIdValidation(skipSelectedItemIdValidation)

    setTitle(modalTitle)
    setMessage(modalMessage)

    if (handleClose) {
      setHandleClose(() => handleClose)
    }
    if (successCallback) {
      setSuccessCallback(() => successCallback)
    }
    setFilePickerModalVisible(true)
  }

  useEffect(() => {
    if (!title) {
      if (isMicrosoft) {
        setTitle('Microsoft OneDrive permission needed')
      } else {
        setTitle('Google Drive permission needed')
      }
    }

    if (!message) {
      if (isMicrosoft) {
        setMessage(
          'We need additional permission to access the item in your Microsoft OneDrive. Please authorize it to continue.'
        )
      } else {
        setMessage(
          'We need additional permission to access the item in your Google Drive. Please authorize it to continue.'
        )
      }
    }
  }, [isGoogle, isMicrosoft, message, title])

  const hideFilePickerModal = () => {
    handleClose && handleClose()
    setFilePickerModalVisible(false)
  }

  const filePickerIcon = useMemo(() => {
    switch (type) {
      case FilePickerFolderType.GoogleFolder:
      case FilePickerFolderType.GoogleUnknown:
        return <GoogleIcon />
      case EDestinationType.GoogleDocs:
        return <GDocsIcon />
      case EDestinationType.GoogleSlides:
        return <GSlidesIcon />
      case FilePickerFolderType.MicrosoftFolder:
      case FilePickerFolderType.MicrosoftUnknown:
        return <MicrosoftIcon />
      case EDestinationType.MicrosoftWord:
        return <MsWordIcon />
      case EDestinationType.MicrosoftPowerPoint:
        return <MsPowerPointIcon />
    }
  }, [type])

  const showFilePicker = () => {
    if (isGoogle) {
      openGoogleDrivePicker({
        appId: clientId,
        clientId,
        developerKey: apiKey,
        token: user?.googleOAuth?.accessToken,
        query: itemName,
        viewId: getGooglePickerViewId(type),
        setIncludeFolders: type === FilePickerFolderType.GoogleFolder,
        setSelectFolderEnabled: type === FilePickerFolderType.GoogleFolder,
        callbackFunction: async (data: google.picker.ResponseObject) => {
          if (data.action === google.picker.Action.CANCEL) {
            handleClose && handleClose()
            hideFilePickerModal()
          }
          if (data.action === google.picker.Action.PICKED) {
            const { docs } = data
            if (itemId && !skipSelectedItemIdValidation) {
              const selectedDoc = docs.find(d => d.id === itemId)
              if (!selectedDoc) {
                incorrectFileSelectedToast({ itemName })
                console.error('Selected item not found in the picked items', {
                  expectedFile: itemId,
                  pickedFiles: docs,
                })
                return
              }
            }
            setIsWaitingForGoogleDrivePermission(true)
            // Need to wait for the permission changes to take effect in the Drive
            // as it can take a couple of seconds after the user can access the slides data after authorizing the file
            await sleep(GOOGLE_PERMISSION_WAIT_TIME)
            successCallback && successCallback(docs.map(d => ({ id: d.id, name: d.name })))
            setIsWaitingForGoogleDrivePermission(false)
            setFilePickerModalVisible(false)
          }
        },
      })
    }
    if (isMicrosoft) {
      console.log('User should have access to every file in Microsoft Drive, no need to show picker modal.')
    }
  }

  return (
    <FilePickerContext.Provider value={{ showFilePickerModal, hideFilePickerModal }}>
      {children}
      {filePickerModalVisible && (
        <ActionModal
          open={filePickerModalVisible}
          title={title}
          subtitle={
            isWaitingForGoogleDrivePermission
              ? 'Please wait while the permission changes take effect in your Drive...'
              : message
          }
          onBackgroundClick={hideFilePickerModal}
          btns={
            <>
              <Button
                fullWidth
                text="Cancel"
                secondaryGray
                onClick={hideFilePickerModal}
                disabled={isWaitingForGoogleDrivePermission}
              />
              <Button
                fullWidth
                iconLeading={filePickerIcon}
                text="Authorize"
                secondaryColor
                onClick={showFilePicker}
                loading={isWaitingForGoogleDrivePermission}
              />
            </>
          }
        />
      )}
    </FilePickerContext.Provider>
  )
}
