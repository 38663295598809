import { FC, useState } from 'react'

import { UsersPlusIcon } from '@assets/icons/users'

import { useAddChildDataSource } from '@features/data-sources/api'
import { IDataSource } from '@features/data-sources/types/types'
import { useGetOrganizationUsers, useOrganizationsStore } from '@features/organizations'
import { OrganizationUser } from '@features/organizations/types'

import { ActionModal, Button, SelectInput, addChildDataSourcesToast } from '@shared/components'

import styles from './add-child-data-source-modal.module.css'

interface AddChildDataSourceModalProps {
  dataSource: IDataSource
  toggle: () => void
  cb?: (dataSource: IDataSource) => void
}

export const AddChildDataSourceModal: FC<AddChildDataSourceModalProps> = ({ dataSource, toggle, cb }) => {
  const [usersToAddDataSources, setUsersToAddDataSources] = useState<OrganizationUser[]>([])
  const { mutateAsync: addChildDataSource, isLoading: addingChildDataSources } = useAddChildDataSource(dataSource)

  const { activeOrganization } = useOrganizationsStore()
  const { data: orgUsers } = useGetOrganizationUsers(activeOrganization?.organization?.id)

  const usersWithoutDataSource: OrganizationUser[] | undefined = orgUsers
    ?.filter(user => user.userId)
    .filter(user => !dataSource?.childCredentials?.some(ds => ds.userId === user.userId))

  const addDataSourcesForSelectedUsers = async () => {
    if (usersToAddDataSources.length) {
      const newDataSources = await Promise.all(usersToAddDataSources.map(user => addChildDataSource(user.userId)))
      addChildDataSourcesToast()

      if (cb && newDataSources[0]?.data) {
        cb(newDataSources[0].data)
      }
    }

    toggle()
  }

  return (
    <ActionModal
      open
      title="Add user"
      Icon={UsersPlusIcon}
      onBackgroundClick={toggle}
      btns={
        <>
          <Button text="Cancel" onClick={toggle} secondaryGray fullWidth />
          <Button text="Add user" fullWidth onClick={addDataSourcesForSelectedUsers} loading={addingChildDataSources} />
        </>
      }
    >
      <SelectInput<OrganizationUser>
        isMulti
        maxMenuHeight={140}
        menuPlacement="bottom"
        options={usersWithoutDataSource || []}
        value={usersToAddDataSources}
        getOptionValue={user => user.email}
        getOptionLabel={user => user.name || user.email}
        onChange={setUsersToAddDataSources}
      />
      <div className={styles.spacer} />
    </ActionModal>
  )
}
