import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import {
  ITableauDataSource,
  TableauDataSourceRequest,
  TableauDataSourceUpdateRequest,
} from '@features/data-sources/types'

import { getMessage } from '@shared/constants'
import { onError, queryClient, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'

import { handleTableauApiErrors } from './error-handler'

export const useGetTableauDataSource = (
  sourceId?: string,
  includeChildCredentials = false,
  options: { enabled: boolean } = { enabled: true }
) => {

  return useQueryWithErrorHandler<ITableauDataSource, AxiosError>(
    ['tableau', sourceId, includeChildCredentials],
    async () => {
      if (!sourceId) return null

      const response = await api.get(`datasources/tableau/${sourceId}`, {
        params: { includeChildCredentials },
      })

      if (!response) return null

      return response.data
    },
    {
      enabled: options.enabled,
      skipToast: true,
      useErrorBoundary: false,
    }
  )
}

export const useAddParentTableauDataSource = () => {
  return useMutation<AxiosResponse<ITableauDataSource>, any, any>(
    (datasource: TableauDataSourceRequest) => api.post('/datasources/tableau', JSON.stringify(datasource)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('TABLEAU_DATA_SOURCE_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useAddChildTableauDataSource = (parentDataSource?: string) => {
  return useMutation<AxiosResponse<ITableauDataSource> | undefined, any, any>(
    async (userId: number) => {
      if (!parentDataSource) return

      return api.post(`/datasources/tableau/${parentDataSource}/children`, JSON.stringify({ userId }))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tableau'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('TABLEAU_DATA_SOURCE_ADD_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useUpdateTableauDataSource = () => {
  return useMutation(
    (datasource: TableauDataSourceUpdateRequest) =>
      api.patch(`/datasources/tableau/${datasource.id}`, JSON.stringify(datasource)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tableau'])
        queryClient.invalidateQueries(['datasources'])
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('TABLEAU_DATA_SOURCE_UPDATE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useDeleteTableauDataSource = () => {
  return useMutation((id: string) => api.delete(`/datasources/tableau/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tableau'])
      queryClient.invalidateQueries(['datasources'])
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('TABLEAU_DATA_SOURCE_DELETE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

// ================================================================
// ====================== TABLEAU AUTH ============================
// ================================================================

interface ITableauAuth {
  baseUrl: string
  contentUrl: string
  jwt: string
  value?: string
}

interface ITableauApiCredentials {
  credentials: {
    site: {
      id: string
      contentUrl: string
    }
    user: {
      id: string
    }
    token: string
  }
}

export interface ITableauApiError {
  code?: string | null
  message?: string | null
  detailedCode?: string | null
}

export const useGetTableauApiToken = () => {
  return useMutation(
    (tableauAuth: ITableauAuth): Promise<AxiosResponse<ITableauApiCredentials>> =>
      api.post('/datasources/tableau/auth', JSON.stringify(tableauAuth)),
    {
      useErrorBoundary: false,
    }
  )
}

// ================================================================
// ====================== TABLEAU API =============================
// ================================================================

export interface TableauProject {
  id: string
  owner: Owner
  name: string
  createdAt: Date
  updatedAt: Date
  description: string
  contentPermissions: string
  parentProjectId?: string
}

export interface Owner {
  id: string
}

export interface TableauWorkbook {
  isDisabled: boolean
  id: string
  name: string
  contentUrl: string
  project: Pick<TableauProject, 'name' | 'id'>
}

export interface ITableauWorkbook {
  workbook: TableauWorkbook[]
}

export interface ITableauWorkbookResponse {
  workbooks: ITableauWorkbook
}

export interface Projects {
  project: TableauProject[]
}

export interface ProjectsResponse {
  projects: Projects
}

export const useGetTableauProjects = (credentialsId: string) => {
  return useQuery<ProjectsResponse, AxiosError>(
    ['tableau/projects', { credentialsId }],
    async () => {
      const response = await api.get(`/datasources/tableau/${credentialsId}/projects`)
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleTableauApiErrors(error, getMessage('TABLEAU_PROJECTS_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetTableauWorkbooks = (credentialsId?: string) => {
  return useQuery<ITableauWorkbookResponse, AxiosError>(
    ['tableau/workbooks', { credentialsId }],
    async () => {
      if (!credentialsId) {
        return
      }

      const response = await api.get(`/datasources/tableau/${credentialsId}/workbooks`)
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleTableauApiErrors(error, getMessage('TABLEAU_WORKBOOKS_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}

export const useGetToken = (credentialsId?: string) => {
  return useQuery<string, AxiosError>(
    ['tableau/token', { credentialsId }],
    async () => {
      if (!credentialsId) {
        return
      }

      const response = await api.get(`/datasources/tableau/${credentialsId}/token`)
      return response?.data?.token
    },
    {
      onError: (error: AxiosError) => {
        handleTableauApiErrors(error, getMessage('TABLEAU_WORKBOOKS_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}

export interface IView {
  id: string
  name: string
  contentUrl: string
  viewUrlName: string
}

interface IViewResponse {
  views: IView[]
}

export const useGetTableauWorkbookViews = (credentialsId?: string, workbookId?: string) => {
  return useQuery<IViewResponse, AxiosError>(
    ['tableau/views', { credentialsId, workbookId }],
    async () => {
      if (!credentialsId || !workbookId) {
        return
      }

      const response = await api.get(`/datasources/tableau/${credentialsId}/views?workbookId=${workbookId}`)
      return response.data
    },
    {
      onError: (error: AxiosError) => {
        handleTableauApiErrors(error, getMessage('TABLEAU_VIEWS_GET_ERROR'))
      },
      useErrorBoundary: false,
    }
  )
}
