import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import { useOrganizationsStore } from '@features/organizations'
import { Invite, OrganizationSetupRequest } from '@features/organizations/types'
import { GoogleOAuth, MicrosoftOAuth, OAuthType, User, useAuthStore } from '@features/users'
import { GoogleCodeInfo, MicrosoftCodeInfo } from '@features/users/types'

import { getMessage } from '@shared/constants/text'
import { onError, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'

interface IAddUserRequest {
  email?: string
  sso?: {
    token: string
  }
  auth0?: {
    id: string
  }
}
/**
 * @description Add a new user to the database
 * @param {UserRequest} user
 * @returns {Promise<AxiosResponse<User>>}
 */
export const useAddUser = () => {
  const loginUser = useAuthStore(state => state.loginUser)

  return useMutation((user: IAddUserRequest): Promise<AxiosResponse<User>> => api.post('/auth/users', user), {
    onSuccess: (response: AxiosResponse<User>) => {
      const { data } = response
      loginUser(data)
    },
    onError: (error: AxiosError<{ metadata?: { code: string } }>) => {
      const errorCode = error.response?.data?.metadata?.code
      switch (errorCode) {
        case 'user-requires-sso':
          onError(error, {
            title: getMessage('USER_REQUIRES_SSO'),
            message: 'You have to login through SSO to access your organization',
            hideSubtext: true,
          })
          return
        case 'personal-email':
          onError(error, {
            title: getMessage('USER_REQUIRE_WORK_EMAIL'),
            message: 'Please use your work email to log in',
            hideSubtext: true,
          })
          return

        default:
          onError(error, {
            title: getMessage('USER_ADD_ERROR'),
            message: error.message,
          })
          break
      }
    },
    useErrorBoundary: false,
  })
}

export const useRefreshGoogleAccessToken = () => {
  const updateUser = useAuthStore(state => state.updateUser)

  return useMutation(
    (): Promise<AxiosResponse<GoogleOAuth>> => api.post(`/auth/oauth/refresh?type=${OAuthType.GOOGLE}`),
    {
      onSuccess: (response: AxiosResponse<GoogleOAuth>) => {
        const oauth = response.data
        updateUser({ googleOAuth: oauth })
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('TOKEN_REFRESH_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useRevokeGoogleAccessToken = () => {
  const updateUser = useAuthStore(state => state.updateUser)

  return useMutation((): Promise<AxiosResponse> => api.delete(`/auth/oauth?type=${OAuthType.GOOGLE}`), {
    onSuccess: () => {
      updateUser({ googleOAuth: undefined })
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('TOKEN_REVOKE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useRefreshMicrosoftAccessToken = () => {
  const updateUser = useAuthStore(state => state.updateUser)

  return useMutation(
    (): Promise<AxiosResponse<MicrosoftOAuth>> => api.post(`/auth/oauth/refresh?type=${OAuthType.MICROSOFT}`),
    {
      onSuccess: (response: AxiosResponse<MicrosoftOAuth>) => {
        const oauth = response.data
        updateUser({ microsoftOAuth: oauth })
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('TOKEN_REFRESH_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useRevokeMicrosoftAccessToken = () => {
  const updateUser = useAuthStore(state => state.updateUser)

  return useMutation((): Promise<AxiosResponse> => api.delete(`/auth/oauth?type=${OAuthType.MICROSOFT}`), {
    onSuccess: () => {
      updateUser({ microsoftOAuth: undefined })
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('TOKEN_REVOKE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useGetGoogleOAuthTokensByCode = () => {
  const updateUser = useAuthStore(state => state.updateUser)

  return useMutation(
    (payload: GoogleCodeInfo): Promise<AxiosResponse<GoogleOAuth>> => api.post(`/auth/oauth/google/code`, payload),
    {
      onSuccess: (response: AxiosResponse<GoogleOAuth>) => {
        const oauth = response.data
        updateUser({ googleOAuth: oauth })
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('GOOGLE_TOKEN_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

export const useSetMicrosoftOAuthTokens = () => {
  const updateUser = useAuthStore(state => state.updateUser)

  return useMutation(
    (payload: MicrosoftCodeInfo): Promise<AxiosResponse<MicrosoftOAuth>> =>
      api.post(`/auth/oauth/microsoft/code`, payload),
    {
      onSuccess: (response: AxiosResponse<MicrosoftOAuth>) => {
        const oauth = response.data
        updateUser({ microsoftOAuth: oauth })
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('MICROSOFT_TOKEN_SET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}
export const useDeleteUser = () => {
  const logoutUser = useAuthStore(state => state.logoutUser)
  const { setActiveOrganization, setOrganizations } = useOrganizationsStore()

  return useMutation((userId: number): Promise<AxiosResponse<User>> => api.delete(`/users/${userId}`), {
    onSuccess: () => {
      logoutUser()
      setActiveOrganization(undefined)
      setOrganizations([])
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('USER_DELETE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useGetUser = () => {
  const { user, updateUser } = useAuthStore()

  return useQueryWithErrorHandler<User, AxiosError>(
    ['activeUser'],
    async () => {
      if (!user) {
        return
      }
      const response = await api.get(`/users/${user.id}`)

      if (!response) {
        return
      }

      updateUser(response.data)
      return response.data
    },
    {
      canRequestWithoutActiveOrganization: true,
      errorTitle: getMessage('USERS_IN_ORGANIZATION_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

const LOGGING_OUT_TIMEOUT = 2000

export const useLogoutUser = () => {
  const { user, logoutUser, setIsLoggingOut } = useAuthStore()
  const { setActiveOrganization, setOrganizations } = useOrganizationsStore()

  return useMutation(
    (): Promise<AxiosResponse<User>> => api.post('/auth/logout', { refreshToken: user?.tokens.refresh.token }),
    {
      onSuccess: async () => {
        logoutUser()
        setActiveOrganization(undefined)
        setOrganizations([])
        setTimeout(() => {
          setIsLoggingOut(false)
        }, LOGGING_OUT_TIMEOUT)
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('LOGOUT_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

/**
 * @description Get the organizations that the user is invited to
 * @returns
 */
export const useGetInvitesByUser = () => {
  const { user } = useAuthStore()
  return useQuery<Invite[], Error>(
    ['userInvites', { userId: user?.id }],
    async () => {
      if (!user) return
      const response = await api.get('/users/invited-orgs')

      if (!response) {
        return []
      }

      return response.data
    },
    {
      onError: error => {
        console.log(error)
        return []
      },
    }
  )
}

export const useSetupUserOrganization = () => {
  return useMutation(
    ({ userId, data }: { userId: number; data: OrganizationSetupRequest }): Promise<AxiosResponse<User>> =>
      api.post(`/users/${userId}/organizations`, JSON.stringify(data)),
    {
      onSuccess: () => {},
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('ORGANIZATION_SETUP_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

type ShowTutorialModalData = Partial<
  Pick<
    User,
    | 'showCollectionExplainerModal'
    | 'showTemplateExplainerModal'
    | 'showTemplateModificationModal'
    | 'showVisualizationConceptModal'
    | 'showVisualizationExplainerModal'
    | 'showDataSourcesModal'
  >
>

export const useShowTutorialModals = () => {
  const { user, updateUser } = useAuthStore()
  return useMutation(
    async ({ data }: { data: ShowTutorialModalData }) => {
      if (!user) {
        return
      }
      await api.patch(`/users/${user.id}/show-tutorial-modals`, JSON.stringify(data))
    },
    {
      onSuccess: async (_, variables) => {
        updateUser(variables.data)
      },
      useErrorBoundary: false,
    }
  )
}
