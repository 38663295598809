import { FC } from 'react'

import { IIcon } from '@assets/types'

export const MsWordIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="5" y="1.25" width="15" height="17.5" rx="1.25" fill="url(#paint0_linear_11976_285010)" />
      <path
        d="M5 14.375H20V17.5C20 18.1904 19.4404 18.75 18.75 18.75H6.25C5.55964 18.75 5 18.1904 5 17.5V14.375Z"
        fill="url(#paint1_linear_11976_285010)"
      />
      <rect x="5" y="10" width="15" height="4.375" fill="url(#paint2_linear_11976_285010)" />
      <rect x="5" y="5.625" width="15" height="4.375" fill="url(#paint3_linear_11976_285010)" />
      <path
        d="M5 7.5C5 6.46447 5.83947 5.625 6.875 5.625H10.625C11.6605 5.625 12.5 6.46447 12.5 7.5V15C12.5 16.0355 11.6605 16.875 10.625 16.875H5V7.5Z"
        fill="black"
        fillOpacity="0.3"
      />
      <rect y="4.375" width="11.25" height="11.25" rx="1.25" fill="url(#paint4_linear_11976_285010)" />
      <path
        d="M9.375 6.88387H8.15768L7.2018 10.9619L6.15605 6.875H5.12663L4.07271 10.9619L3.125 6.88387H1.875L3.50082 13.125H4.57925L5.625 9.17996L6.67075 13.125H7.74918L9.375 6.88387Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11976_285010"
          x1="5"
          y1="4.16667"
          x2="20"
          y2="4.16667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2B78B1" />
          <stop offset="1" stopColor="#338ACD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11976_285010"
          x1="5"
          y1="17.1094"
          x2="20"
          y2="17.1094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B366F" />
          <stop offset="1" stopColor="#2657B0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11976_285010"
          x1="11.5625"
          y1="12.5"
          x2="20"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20478B" />
          <stop offset="1" stopColor="#2D6FD1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11976_285010"
          x1="11.5625"
          y1="8.125"
          x2="20"
          y2="8.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#215295" />
          <stop offset="1" stopColor="#2E84D3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11976_285010"
          x1="2.06961e-08"
          y1="10.625"
          x2="11.875"
          y2="10.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#223E74" />
          <stop offset="1" stopColor="#215091" />
        </linearGradient>
      </defs>
    </svg>
  )
}
