import { FC } from 'react'

const DEFAULT_SIZE = 24

interface IExpiredIconProps {
  height?: number | string
  width?: number | string
  color?: string
  className?: string
}

export const ExpiredIcon: FC<IExpiredIconProps> = ({ className, height = DEFAULT_SIZE, width = DEFAULT_SIZE }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 16 16">
      <path
        style={{
          lineHeight: 'normal',
          textIndent: 0,
          textAlign: 'start',
          textDecorationLine: 'none',
          textDecorationStyle: 'solid',
          textDecorationColor: '#000',
          textTransform: 'none',
          blockOverflow: 'tb',
          isolation: 'auto',
          mixBlendMode: 'normal',
        }}
        d="M 8 2 C 6.947 2 5.9817656 2.4300938 5.2597656 3.1210938 L 5.9628906 3.8242188 C 6.5018906 3.3152187 7.22 3 8 3 C 9.208 3 10.292672 3.7180781 10.763672 4.8300781 L 10.943359 5.2558594 L 11.380859 5.109375 C 11.606859 5.035375 11.809 5 12 5 C 13.103 5 14 5.897 14 7 C 14 7.233 13.953328 7.4739375 13.861328 7.7109375 L 13.716797 8.09375 L 14.0625 8.3125 C 14.6495 8.6845 15 9.314 15 10 C 15 10.774 14.554156 11.439484 13.910156 11.771484 L 14.644531 12.505859 C 15.459531 11.968859 16 11.047 16 10 C 16 9.101 15.600063 8.2633125 14.914062 7.6953125 C 14.971063 7.4643125 15 7.231 15 7 C 15 5.18 13.389187 3.6958281 11.492188 4.0488281 C 10.790188 2.7918281 9.463 2 8 2 z M 2.7285156 2.0214844 L 2.0214844 2.7285156 L 4.1816406 4.8886719 C 4.1669199 4.9347195 4.1468096 4.978075 4.1347656 5.0253906 C 3.0557656 5.1843906 2.1982031 6.0411562 2.0332031 7.1601562 C 0.83420313 7.5671563 0 8.706 0 10 C 0 11.654 1.346 13 3 13 L 12.292969 13 L 13.271484 13.978516 L 13.978516 13.271484 L 2.7285156 2.0214844 z M 5.0214844 5.7285156 L 11.292969 12 L 3 12 C 1.897 12 1 11.103 1 10 C 1 9.051 1.6745156 8.2260625 2.6035156 8.0390625 L 3.0117188 7.921875 L 3 7.5 C 3 6.673 3.6732188 5.9990938 4.4492188 5.9960938 L 4.5507812 6.0058594 L 4.9824219 6.0058594 L 5.0214844 5.7285156 z"
        fontWeight="400"
        fontFamily="sans-serif"
        white-space="normal"
        overflow="visible"
      />
    </svg>
  )
}
