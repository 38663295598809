import { config } from '@shared/config'

declare global {
  interface Window {
    pendo: {
      initialize: (config: {
        visitor: {
          id: string
          email: string
          full_name: string
        }
        account: {
          id?: string
          name?: string
        }
      }) => void
    }
  }
}

export const initializePendo = (user: any, activeOrganization: any) => {
  const script = document.createElement('script')
  script.src = `https://cdn.pendo.io/agent/static/${config.pendoApiKey}/pendo.js`
  script.async = true
  script.onload = () => {
    try {
      window.pendo.initialize({
        visitor: {
          id: user.id.toString(),
          email: user.email,
          full_name: user.name,
        },
        account: {
          id: activeOrganization?.organization?.id?.toString(),
          name: activeOrganization?.organization?.name,
        },
      })
      console.log('Pendo initialized successfully')
    } catch (error) {
      console.error('Pendo initialization failed', error)
    }
  }
  script.onerror = error => {
    console.error('Failed to load Pendo script', error)
  }
  document.head.appendChild(script)

  return () => {
    if (script.parentNode) {
      script.parentNode.removeChild(script)
    }
  }
}
