import { LookerEmbedCookielessSessionData, LookerEmbedSDK } from '@looker/embed-sdk'
import dayjs from 'dayjs'
import { useRef } from 'react'

import {
  acquireEmbedSessionForConnectionTest,
  generateEmbedTokensForConnectionTest,
  useDeleteLookerEmbedUser,
  useUpdateLookerEmbedConfig,
} from '@features/data-sources/api'
import {
  ConnectionTestKey,
  ConnectionTestMessages,
  ConnectionTestStatus,
} from '@features/data-sources/components/connection-test/utils'
import { ILookerApiCredentials, ILookerCredentials } from '@features/data-sources/types'
import { ILookerDashboard } from '@features/embedding/looker/types'
import { useAuthStore } from '@features/users'

import { config, isDev } from '@shared/config'
import { useStateReducer } from '@shared/hooks'
import { api } from '@shared/http/axios'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'
import { removeHttp } from '@shared/utils'

enum ConnectionError {
  DASHBOARD_TIMEOUT = 'Unable to connect to Looker dashboard - Connection timeout',
  EMBED_TIMEOUT = 'Unable to connect to Looker embed - Connection timeout',
  EMBED_FAIL = 'Dashboard embedding failed: Please verify the domain allowlist configuration.',
  COOKIELESS_EMBED_FAIL = 'Dashboard embedding failed: Please verify the domain allowlist configuration or check if cookieless embedding is enabled.',
}

interface ILookerTestConnection extends ILookerCredentials {
  isAdmin: boolean
}

const DASHBOARD_TIMEOUT_MS = 20000
const EMBED_TIMEOUT_MS = 30000
const DELETE_USER_TIMEOUT = 1000

export const useLookerConnectionTest = (testDashboardEmbed = false) => {
  const iframeDashboardRef = useRef<HTMLDivElement | null>(null)
  const [testsStatus, updateTestsStatus] = useStateReducer<ConnectionTestStatus | null>(null)
  const [connectionTestsMessages, updateConnectionTestsMessages] = useStateReducer<Partial<ConnectionTestMessages>>({})
  const isCookielessEmbed = isFeatureEnabled('cookieless-embed')
  const isPrivateEmbedding = isFeatureEnabled('looker-private-embedding')

  const { mutateAsync: deleteEmbedUser } = useDeleteLookerEmbedUser()
  const { mutateAsync: updateEmbedConfig } = useUpdateLookerEmbedConfig()

  const getTestDashboard = async ({ baseUrl, clientId, clientSecret }: ILookerApiCredentials) => {
    try {
      const response = await api.get<ILookerDashboard>(
        `datasources/looker-test/dashboard?baseUrl=${baseUrl}&clientId=${clientId}&clientSecret=${clientSecret}`
      )
      return response.data
    } catch (err) {
      const errorMessage = 'API test failed: Please check your URL, Client ID and Client Secret'
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'failed' })
      updateConnectionTestsMessages({ API_TEST: { failed: errorMessage } })
      throw new Error(errorMessage)
    }
  }

  const initLookerSdk = async ({
    baseUrl,
    clientId,
    clientSecret,
    embedSecret,
    dashboardId,
    userAttributes,
  }: ILookerCredentials & { dashboardId: string }) => {
    const { user, refreshToken } = useAuthStore.getState()
    if (!user) return config

    let accessToken = user.tokens.access

    if (dayjs(accessToken.expires).isBefore(dayjs())) {
      const tokens = await refreshToken()
      accessToken = tokens.access
    }

    const acquireEmbedSessionCallback = async (): Promise<LookerEmbedCookielessSessionData> => {
      const res = await acquireEmbedSessionForConnectionTest({ baseUrl, clientId, clientSecret, dashboardId })
      return res.data
    }

    const generateEmbedTokensCallback = async (): Promise<LookerEmbedCookielessSessionData> => {
      const res = await generateEmbedTokensForConnectionTest({ baseUrl, clientId, clientSecret })
      return res.data
    }

    if (isCookielessEmbed) {
      LookerEmbedSDK.initCookieless(removeHttp(baseUrl), acquireEmbedSessionCallback, generateEmbedTokensCallback)
    } else {
      LookerEmbedSDK.init(baseUrl, {
        url: `${config.apiHost}/datasources/looker-test/embed-url`,
        headers: [
          {
            name: 'Authorization',
            value: `Bearer ${accessToken.token}`,
          },
        ],
        params: [
          { name: 'baseUrl', value: baseUrl },
          { name: 'clientId', value: clientId },
          { name: 'clientSecret', value: clientSecret },
          { name: 'embedSecret', value: embedSecret },
          { name: 'dashboardId', value: dashboardId },
          { name: 'userAttributes', value: JSON.stringify(userAttributes) },
        ],
      })
    }
  }

  const makeDashboard = (dashboardId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!iframeDashboardRef.current) return

      const createTimeout = (testKey: ConnectionTestKey, errorMessage: string, timeout = DASHBOARD_TIMEOUT_MS) => {
        return setTimeout(() => {
          updateTestsStatus({ currentTest: testKey, stage: 'failed' })
          updateConnectionTestsMessages({ [testKey]: { failed: errorMessage } })
          reject(new Error(errorMessage))
        }, timeout)
      }

      const dashboardTimeout = createTimeout('DASHBOARD_TEST', ConnectionError.DASHBOARD_TIMEOUT)
      const embedTimeout = createTimeout('EMBED_TEST', ConnectionError.EMBED_TIMEOUT, EMBED_TIMEOUT_MS)

      LookerEmbedSDK.createDashboardWithId(dashboardId)
        .appendTo(iframeDashboardRef.current)
        .on('dashboard:run:start', () => {
          updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'success' })
          clearTimeout(embedTimeout)
          resolve()
        })
        .build()
        .connect()
        .then(() => {
          clearTimeout(dashboardTimeout)
          updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'running' })
        })
        .catch((error: Error) => {
          clearTimeout(dashboardTimeout)
          clearTimeout(embedTimeout)
          updateTestsStatus({ currentTest: 'EMBED_TEST', stage: 'failed' })
          updateConnectionTestsMessages({
            EMBED_TEST: {
              failed: isCookielessEmbed ? ConnectionError.COOKIELESS_EMBED_FAIL : ConnectionError.EMBED_FAIL,
            },
          })
          reject(error)
        })
    })
  }

  const updateLookerEmbedConfig = async (apiCredentials: ILookerApiCredentials) => {
    try {
      await updateEmbedConfig(apiCredentials)
    } catch (err) {
      const errorMessage = 'Admin Test failed: Please make sure that you are a Looker admin'
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'failed' })
      updateConnectionTestsMessages({ API_TEST: { failed: errorMessage } })
      throw new Error(errorMessage)
    }
  }

  const testConnection = async ({
    baseUrl,
    clientId,
    clientSecret,
    embedSecret,
    userAttributes,
    isAdmin,
  }: ILookerTestConnection) => {
    try {
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'running' })
      const dashboard = await getTestDashboard({ baseUrl, clientId, clientSecret })
      if (isAdmin) {
        await updateLookerEmbedConfig({ baseUrl, clientId, clientSecret })
      }
      updateTestsStatus({ currentTest: 'API_TEST', stage: 'success' })

      if (isPrivateEmbedding) {
        return true
      }
      const dashboardId = dashboard.id
      if (testDashboardEmbed) {
        updateTestsStatus({ currentTest: 'DASHBOARD_TEST', stage: 'running' })
        await initLookerSdk({ baseUrl, clientId, clientSecret, embedSecret, dashboardId, userAttributes })
        await makeDashboard(dashboardId)
        updateTestsStatus({ currentTest: 'DASHBOARD_TEST', stage: 'success' })
      }

      return true
    } catch (err) {
      return false
    } finally {
      if (isDev) {
        setTimeout(async () => {
          await deleteEmbedUser({ baseUrl, clientId, clientSecret })
        }, DELETE_USER_TIMEOUT)
      }
    }
  }

  return {
    ...testsStatus,
    iframeDashboardRef,
    connectionTestsMessages,
    testConnection,
  }
}
