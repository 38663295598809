import { useCallback, useEffect, useState } from 'react'

export const useImageSize = (imgUrl?: string) => {
  const [imgWidth, setImgWidth] = useState<number>()
  const [imgHeight, setImgHeight] = useState<number>()

  const loadImgDimensions = useCallback(() => {
    if (imgUrl) {
      const img = new Image()
      img.src = imgUrl
      img.onload = () => {
        setImgWidth(img.width)
        setImgHeight(img.height)
      }
    }
  }, [imgUrl])

  useEffect(() => {
    loadImgDimensions()
  }, [imgUrl, loadImgDimensions])

  return [imgWidth, imgHeight] as const
}
