import React, { ReactNode } from 'react'

import styles from './featured-icon.module.css'

interface FeaturedIconProps {
  icon: ReactNode
}

export const FeaturedIcon: React.FC<FeaturedIconProps> = ({ icon }) => {
  return <div className={styles.iconBackground}>{icon}</div>
}
