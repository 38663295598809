import { FC } from 'react'

import { IIcon } from '@assets/types'

export const OctopusEnergy: FC<IIcon> = () => {
  return (
    <svg width="110" height="17" viewBox="0 0 110 17" fill="none">
      <g clipPath="url(#clip0_16270_176229)">
        <path
          d="M10.2636 7.42813C10.2636 9.85744 8.47911 11.8268 5.7146 11.8268C3.20223 11.8268 1.25781 9.91701 1.25781 7.4877C1.25781 4.93258 3.24846 3.14844 5.76081 3.14844C8.27316 3.14844 10.2636 4.99882 10.2636 7.42813ZM5.76072 10.1894C7.25231 10.1894 8.31514 8.97977 8.31514 7.48761C8.31514 5.99544 7.36241 4.78583 5.76072 4.78583C4.19057 4.78583 3.20629 5.99546 3.20629 7.48763C3.20629 8.97979 4.25874 10.1894 5.76072 10.1894Z"
          fill="#4C3551"
        />
        <path
          d="M32.0937 7.42813C32.0937 9.85744 30.3092 11.8268 27.5447 11.8268C25.0323 11.8268 23.0879 9.91701 23.0879 7.4877C23.0879 4.93258 25.0785 3.14844 27.5909 3.14844C30.1033 3.14844 32.0937 4.99882 32.0937 7.42813ZM27.5908 10.1894C29.0824 10.1894 30.1452 8.97977 30.1452 7.48761C30.1452 5.99544 29.1925 4.78581 27.5908 4.78581C26.0207 4.78581 25.0364 5.99544 25.0364 7.48761C25.0364 8.97977 26.0888 10.1894 27.5908 10.1894Z"
          fill="#4C3551"
        />
        <path
          d="M42.0274 8.29462C42.0274 8.29462 42.0278 4.96996 42.0275 4.11016C42.1852 2.90713 43.8803 3.5525 43.8803 3.5525V8.03772V8.03609C43.8803 9.43556 44.8382 10.1194 46.0197 10.1194C47.2012 10.1194 48.159 9.45915 48.159 8.03609C48.159 8.03609 48.1643 4.97256 48.164 4.11278C48.3217 2.90975 50.0169 3.55512 50.0169 3.55512V6.43882C50.2581 9.99327 53.6574 10.2586 53.6574 10.2586C57.0421 10.7272 56.7443 9.3375 56.7443 9.3375C56.6568 8.63803 56.1351 8.47944 55.2345 8.36399C54.6754 8.2923 54.1899 8.24335 54.1899 8.24335C50.5619 8.01381 50.9188 5.62065 50.9188 5.62065C50.9647 3.59597 53.131 3.28787 53.131 3.28787C55.0448 2.94711 56.3965 3.28155 56.3965 3.28155C59.3751 3.90127 57.9084 5.31287 57.6623 5.39315C57.6623 5.39315 56.8691 4.66669 55.0135 4.56232C52.7279 4.4166 52.7683 5.49553 52.7683 5.49553C52.6939 6.28241 53.7017 6.42642 53.7017 6.42642L56.1512 6.76928C59.0448 7.1727 58.6922 9.22554 58.6922 9.22554C58.5022 11.7928 55.443 11.8116 55.443 11.8116C51.2593 12.0545 49.6843 9.88201 49.6843 9.88201C49.0979 11.0486 47.9273 11.8283 45.9818 11.8283C43.6627 11.8283 42.0273 10.5279 42.0273 8.19505"
          fill="#4C3551"
        />
        <path
          d="M14.8386 3.287C11.4924 3.52585 10.7289 6.63561 10.8104 7.53963C10.764 8.9055 11.7194 11.8275 15.4155 11.8291C15.9024 11.8202 16.3484 11.7763 16.7556 11.7011L16.7554 10.0436C16.4012 10.106 15.9736 10.1453 15.4575 10.1589C13.7327 10.2022 12.7523 8.78666 12.7589 7.53924C12.7589 6.09241 13.9424 4.90446 15.4575 4.91954L18.4471 4.91887L18.4545 9.01211C18.4545 9.01211 18.2387 12.4801 22.6224 11.752V10.1006C20.1917 10.6242 20.3229 9.09469 20.3229 9.09469V4.92182H22.6224V3.28445H20.3229V1.35254H18.4545V3.28447L14.8386 3.287Z"
          fill="#4C3551"
        />
        <path
          d="M34.4984 14.3148C34.3407 15.5178 32.6455 14.8725 32.6455 14.8725V7.48796C32.7527 5.13206 34.1621 3.21324 37.0641 3.1487C39.6263 3.09173 41.4664 4.99913 41.4825 7.42837C41.4996 10.0025 39.6947 11.9161 37.0276 11.8031C36.0768 11.7505 35.1481 11.3761 34.5063 10.5518C34.5063 10.5518 34.4981 13.455 34.4984 14.3148ZM37.016 10.1979C38.4925 10.1979 39.5447 8.98822 39.5447 7.49606C39.5447 6.00389 38.6015 4.79426 37.016 4.79426C35.4616 4.79426 34.4873 6.00389 34.4873 7.49606C34.4873 8.98822 35.5291 10.1979 37.016 10.1979Z"
          fill="#4C3551"
        />
        <path
          d="M68.0903 9.97671C67.3687 10.8158 66.3702 11.2593 65.2028 11.2593C64.1243 11.2593 63.2125 10.8766 62.566 10.1525C61.9585 9.47211 61.624 8.51701 61.624 7.46303V7.30851H68.801L68.7988 7.24351C68.7623 6.14969 68.3744 5.14757 67.7064 4.4218C67.0014 3.65587 66.0543 3.25098 64.9673 3.25098C62.7471 3.25098 61.1357 5.02241 61.1357 7.46303C61.1357 9.96435 62.8082 11.7113 65.2028 11.7113C66.5148 11.7113 67.6339 11.2278 68.4389 10.3129L68.4779 10.2686L68.1345 9.92526L68.0903 9.97671ZM61.6689 6.8566C61.9314 4.93826 63.2196 3.703 64.9673 3.703C65.8661 3.703 66.6603 4.03082 67.264 4.65104C67.8181 5.22027 68.167 6.00133 68.2504 6.8566H61.6689Z"
          fill="#4C3551"
        />
        <path
          d="M76.4078 4.30335C75.7734 3.63478 74.8523 3.25123 73.8807 3.25098H73.8798C72.9082 3.25123 71.9871 3.63478 71.3525 4.30365C70.7476 4.94886 70.4658 5.78584 70.4658 6.9376V11.5301H70.954V6.93762C70.954 5.9193 71.202 5.17872 71.7342 4.6071C72.2569 4.04956 73.0792 3.70311 73.8802 3.703C74.6812 3.70311 75.5035 4.04956 76.0261 4.60699C76.5585 5.17872 76.8064 5.9193 76.8064 6.93762V11.5301H77.2946V6.93762C77.2947 5.78584 77.0129 4.94888 76.4078 4.30335Z"
          fill="#4C3551"
        />
        <path
          d="M91.6951 3.25098C90.7231 3.25098 89.8018 3.63453 89.167 4.30365C88.562 4.94886 88.2803 5.78584 88.2803 6.9376V11.5301H88.7685V6.93762C88.7685 5.9193 89.0164 5.17872 89.5486 4.6071C90.0715 4.04944 90.8939 3.703 91.6951 3.703H91.758V3.25098H91.6951Z"
          fill="#4C3551"
        />
        <path
          d="M85.8882 9.97671C85.1666 10.8158 84.168 11.2593 83.0007 11.2593C81.9222 11.2593 81.0104 10.8766 80.3638 10.1525C79.7564 9.47211 79.4218 8.51701 79.4218 7.46303V7.30851H86.5988L86.5967 7.24351C86.5602 6.14969 86.1723 5.14757 85.5043 4.4218C84.7993 3.65587 83.8521 3.25098 82.7651 3.25098C80.545 3.25098 78.9336 5.02241 78.9336 7.46303C78.9336 9.96435 80.606 11.7113 83.0006 11.7113C84.3127 11.7113 85.4317 11.2278 86.2367 10.3129L86.2757 10.2686L85.9324 9.92526L85.8882 9.97671ZM79.4667 6.8566C79.7293 4.93826 81.0175 3.703 82.7651 3.703C83.664 3.703 84.4581 4.03082 85.0618 4.65104C85.6159 5.22027 85.9648 6.00133 86.0483 6.8566H79.4667Z"
          fill="#4C3551"
        />
        <path
          d="M108.158 3.43164L105.042 10.0218L101.55 3.43164H101.027L104.775 10.5804L102.264 15.9143H102.802L108.695 3.43164H108.158Z"
          fill="#4C3551"
        />
        <path
          d="M96.3843 3.25098C95.2298 3.25098 94.1693 3.69136 93.3983 4.49105C92.6408 5.27671 92.2236 6.33872 92.2236 7.4814C92.2236 8.62408 92.6408 9.68609 93.3983 10.4717C94.1693 11.2715 95.2297 11.7119 96.3843 11.7119C97.899 11.7119 99.1201 10.9663 99.7761 9.65461V11.395C99.7761 12.3728 99.7431 13.4743 99.3495 14.1721C98.8231 15.0979 97.8324 15.6077 96.5599 15.6077C94.8639 15.6077 93.6938 14.7061 93.2071 13.9341L93.1706 13.876L92.7773 14.171L92.8116 14.221C93.1083 14.6525 94.2552 16.0603 96.5599 16.0603C98.0123 16.0603 99.1212 15.4984 99.7668 14.4349C100.226 13.6628 100.265 12.4515 100.265 11.3769V7.53405L100.265 7.51168C100.266 7.50161 100.266 7.49151 100.266 7.4814C100.266 4.99059 98.6698 3.25098 96.3843 3.25098ZM99.7736 7.4814C99.7736 9.70581 98.3799 11.2593 96.3843 11.2593C94.3273 11.2593 92.7159 9.59987 92.7159 7.4814C92.7159 5.36297 94.3273 3.70352 96.3843 3.70352C98.3799 3.70352 99.7736 5.25705 99.7736 7.4814Z"
          fill="#4C3551"
        />
      </g>
      <defs>
        <clipPath id="clip0_16270_176229">
          <rect width="108.209" height="15.4256" fill="white" transform="translate(0.895508 0.939453)" />
        </clipPath>
      </defs>
    </svg>
  )
}
