import { useEffect } from 'react'

export const useOutsideModalClick = (
  ref: React.RefObject<any>,
  mouseDownOutsideModal: boolean,
  setMouseDownOutsideModal: any,
  processing: boolean,
  handleClose: () => void
) => {
  useEffect(() => {
    const onMouseDown = (e: any) => {
      if (ref.current && ref.current === e.target) setMouseDownOutsideModal(true)
    }

    const onMouseUp = (e: any) => {
      if (ref.current && ref.current === e.target && !processing && mouseDownOutsideModal) handleClose()
    }

    document.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mouseup', onMouseUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, mouseDownOutsideModal, processing])
}
