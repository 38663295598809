// Set the toast type. One of: 'info', 'success', 'warning', 'error', 'default'
import classNames from 'classnames'
import { FC, PropsWithChildren, useMemo } from 'react'

import { AlertIcon, InfoIcon, XCircle } from '@assets'

import styles from './callout.module.css'

interface CalloutProps extends PropsWithChildren {
  open?: boolean | null
  compact?: boolean
  type: 'info' | 'warning' | 'error'
  title: string
  subtitle?: string
}

export const Callout: FC<CalloutProps> = ({ open = true, compact = false, type, title, subtitle, children }) => {
  const Icon = useMemo(() => {
    switch (type) {
      case 'warning':
        return AlertIcon
      case 'error':
        return XCircle
      default:
        return InfoIcon
    }
  }, [type])

  const containerClass = classNames(
    styles.container,
    type === 'info' && styles.info,
    type === 'warning' && styles.warning,
    type === 'error' && styles.error,
    compact && styles.compact,
  )

  if (!open) {
    return null
  }
  return (
    <div className={containerClass}>
      <Icon color="currentColor" size={20} />
      <div>
        <div className={styles.title}>{title}</div>
        {subtitle && <div>{subtitle}</div>}
        {children && <div className={styles.childrenContainer}>{children}</div>}
      </div>
    </div>
  )
}
