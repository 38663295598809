import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'

import { SmallSpinner } from '@assets'
import { TickCircleFull } from '@assets/icons/ticks'

import styles from './selectable-options.module.css'

interface ISelectableOptions extends PropsWithChildren {
  selected?: boolean
  className?: string
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  dataTestId?: string
}

export const SelectableOption: FC<ISelectableOptions> = ({
  children,
  className,
  selected = false,
  disabled = false,
  loading = false,
  onClick,
  dataTestId,
}) => {
  const handleClick = () => !loading && !disabled && onClick && onClick()

  const checkIconClass = classNames(styles.checkIcon, selected && styles.checkIconSelected)
  const containerClass = classNames(styles.container, selected && styles.containerSelected, className)

  const childrenClass = classNames(styles.selectableContent, disabled && styles.disabled)

  return (
    <div className={containerClass} onClick={handleClick} data-testid={dataTestId}>
      <div className={checkIconClass}>
        <TickCircleFull />
      </div>
      <div className={childrenClass}>{children}</div>
      {loading && <SmallSpinner className={styles.spinner} />}
    </div>
  )
}
