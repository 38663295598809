import { FC } from 'react'

import { ArrowLeft, ArrowRight } from '@assets'

import { Button } from '@shared/components'

import { Stepper } from './stepper/stepper'

import styles from './collection-setup-header.module.css'

interface IAddCollectionHeader {
  step: number
  onPreviousClick: () => void
  onNextClick: () => void
  disabledPreviousClick: boolean
  disabledNextClick: boolean
  loadingNextClick: boolean
}

export const AddCollectionHeader: FC<IAddCollectionHeader> = ({
  step,
  onPreviousClick,
  onNextClick,
  disabledPreviousClick,
  disabledNextClick,
  loadingNextClick,
}) => {
  return (
    <div className={styles.headerContainer}>
      <Button
        className={styles.leftBtn}
        text="Previous"
        secondaryGray
        onClick={onPreviousClick}
        iconLeading={<ArrowLeft />}
        disabled={disabledPreviousClick}
      />
      <Stepper activeStep={step} steps={['Configure collection', 'Review']} />
      <Button
        className={styles.rightBtn}
        text={step === 1 ? 'Create collection' : 'Next'}
        onClick={onNextClick}
        disabled={disabledNextClick || loadingNextClick}
        loading={loadingNextClick}
        iconTrailing={step === 1 ? null : <ArrowRight />}
      />
    </div>
  )
}
