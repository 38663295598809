import { FC } from 'react'

import { IIcon } from '@assets/types'

const defaultSize = 14

export const TickIcon: FC<IIcon> = ({ size = defaultSize, color = '#04cf5c' }) => {
  return (
    <svg height={size} viewBox="0 0 10 8" fill="none">
      <path
        d="M0.75 3.99992L3.58 6.82992L9.25 1.16992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
