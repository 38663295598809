import { FC, PropsWithChildren } from 'react'

import { Tooltip } from '@shared/components/tooltip/tooltip'

interface IDeletedTooltip extends PropsWithChildren {
  show?: boolean
  position?: 'top' | 'bottom' | 'left' | 'right'
}

export const DeletedDestinationTooltip: FC<IDeletedTooltip> = ({ show = false, children, position = 'top' }) =>
  show ? (
    <Tooltip
      position={position}
      title="This destination has been deleted from Google Drive or de-authorized from Rollstack"
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )
