import classNames from 'classnames'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { FC, useEffect, useMemo } from 'react'

import { isGoogleDestination, useGetDriveDataByDestinationId } from '@features/destinations'
import {
  SuffixGroups,
  SuffixOptions,
  mapSuffixOptionsToExample,
  useDestinationConfiguration,
} from '@features/destinations'
import { useDestinationRights } from '@features/destinations'
import { DestinationCropOptionSelect } from '@features/destinations/components/destination-crop-option-select/destination-crop-option-select'
import { DestinationLinkOnImages } from '@features/destinations/components/destination-link-on-images-select/destination-link-on-images-select'
import { DestinationSchedule } from '@features/destinations/components/destination-update-schedule/destination-schedule'
import { destinationHandlers } from '@features/destinations/destination-handlers'
import { Destination, UpdateMethod } from '@features/destinations/types'
import { SelectFolder } from '@features/drive'
import { OAuthType } from '@features/users'

import { Button, Collapsable, SelectableOption, Spinner } from '@shared/components'

import styles from './destination-settings.module.css'

dayjs.extend(timezone)

interface IDestinationSettings {
  destination: Destination
}

export const DestinationSettings: FC<IDestinationSettings> = ({ destination }) => {
  const {
    formattedSchedules,
    expandedScheduleId,
    selectedParentFolder,
    suffix,
    suffixGroup,
    timeOptions,
    freqOptions,
    cropOption,
    updateMethod,
    linkOption,
    isSubmitting,
    removeSchedule,
    addSchedule,
    setExpandedScheduleId,
    handleArchiveUpdateSelection,
    handleEndDateChange,
    handleFrequencyChange,
    handlePeriodChange,
    handleSelectSuffix,
    handleSelectSuffixGroup,
    handleStartDateChange,
    handleTimeChange,
    setSelectedParentFolder,
    setUpdateMethod,
    setLinkOption,
    setCropOption,
    toggleEndDate,
    updateConfiguration,
  } = useDestinationConfiguration({ destination, itemUpdatedAt: destination.updatedAt })
  const { owner } = useDestinationRights(destination)

  const showFolderLocation = useMemo(
    () => owner && !destination.variantInCollection?.collectionId,
    [owner, destination]
  )

  const { data: driveData, isLoading } = useGetDriveDataByDestinationId({
    destination,
    enabled: showFolderLocation,
    onSuccess: data => setSelectedParentFolder(data),
  })

  useEffect(() => {
    if (driveData) {
      setSelectedParentFolder(driveData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const destinationHandler = destinationHandlers(destination)
  const destinationTitle = destinationHandler.getTitle()

  const dateDelimiter = destinationHandler.getDateDelimiter()
  const dateFormats = mapSuffixOptionsToExample(dateDelimiter)

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className={styles.grid}>
      <div>
        <h3 className={styles.label}>Update method</h3>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <SelectableOption
          className={styles.option}
          selected={updateMethod === UpdateMethod.Update}
          onClick={() => setUpdateMethod(UpdateMethod.Update)}
        >
          <div className={styles.optionTitle}>Update same {destinationTitle}</div>
          <div className={styles.optionSubtitle}>The same {destinationTitle} is updated with the latest data.</div>
        </SelectableOption>

        <SelectableOption
          className={styles.option}
          selected={updateMethod === UpdateMethod.Archive}
          onClick={handleArchiveUpdateSelection}
        >
          <div className={styles.optionTitle}>Archive & Update</div>
          <div className={styles.optionSubtitle}>The old content is archived and kept in your drive.</div>
        </SelectableOption>
      </section>

      <div>
        <h3 className={styles.label}>Scheduling</h3>
      </div>
      <section className={styles.rowContent}>
        <DestinationSchedule
          formattedSchedules={formattedSchedules}
          freqOptions={freqOptions}
          timeOptions={timeOptions}
          expandedScheduleId={expandedScheduleId}
          handleFrequencyChange={handleFrequencyChange}
          handleTimeChange={handleTimeChange}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          toggleEndDate={toggleEndDate}
          addSchedule={addSchedule}
          removeSchedule={removeSchedule}
          handlePeriodChange={handlePeriodChange}
          setExpandedScheduleId={setExpandedScheduleId}
        />
      </section>

      <Collapsable open={updateMethod === UpdateMethod.Archive} hideWhenClosed>
        <h3 className={styles.label}>Version name suffix format</h3>
        <span className={styles.subLabel}>The date will be used as a suffix for filenames.</span>
      </Collapsable>
      <Collapsable open={updateMethod === UpdateMethod.Archive} hideWhenClosed>
        <section className={styles.rowContent}>
          <div>
            <div className={styles.suffixGroupOptions}>
              <SelectableOption
                className={styles.suffixOption}
                selected={suffixGroup === SuffixGroups.DATE}
                onClick={() => handleSelectSuffixGroup(SuffixGroups.DATE)}
              >
                Date
              </SelectableOption>
              <SelectableOption
                className={styles.suffixOption}
                selected={suffixGroup === SuffixGroups.MONTH}
                onClick={() => handleSelectSuffixGroup(SuffixGroups.MONTH)}
              >
                Month
              </SelectableOption>
              <SelectableOption
                className={styles.suffixOption}
                selected={suffixGroup === SuffixGroups.QUARTER}
                onClick={() => handleSelectSuffixGroup(SuffixGroups.QUARTER)}
              >
                Quarter
              </SelectableOption>
              <SelectableOption
                className={styles.suffixOption}
                selected={suffixGroup === SuffixGroups.YEAR}
                onClick={() => handleSelectSuffixGroup(SuffixGroups.YEAR)}
              >
                Year
              </SelectableOption>
            </div>

            <div className={styles.suffixOptions}>
              {suffixGroup === SuffixGroups.DATE && (
                <>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffix === SuffixOptions.MM_DD_YYYY}
                    onClick={() => handleSelectSuffix(SuffixOptions.MM_DD_YYYY)}
                  >
                    {dateFormats[SuffixOptions.MM_DD_YYYY]}
                  </SelectableOption>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffix === SuffixOptions.DD_MM_YYYY}
                    onClick={() => handleSelectSuffix(SuffixOptions.DD_MM_YYYY)}
                  >
                    {dateFormats[SuffixOptions.DD_MM_YYYY]}
                  </SelectableOption>
                </>
              )}
              {suffixGroup === SuffixGroups.MONTH && (
                <>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffix === SuffixOptions.MMMM_YYYY}
                    onClick={() => handleSelectSuffix(SuffixOptions.MMMM_YYYY)}
                  >
                    {dateFormats[SuffixOptions.MMMM_YYYY]}
                  </SelectableOption>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffix === SuffixOptions.MM_YYYY}
                    onClick={() => handleSelectSuffix(SuffixOptions.MM_YYYY)}
                  >
                    {dateFormats[SuffixOptions.MM_YYYY]}
                  </SelectableOption>
                </>
              )}
              {suffixGroup === SuffixGroups.QUARTER && (
                <>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffix === SuffixOptions.Q}
                    onClick={() => handleSelectSuffix(SuffixOptions.Q)}
                  >
                    {dateFormats[SuffixOptions.Q]}
                  </SelectableOption>
                  <SelectableOption
                    className={styles.suffixOption}
                    selected={suffix === SuffixOptions.Q_YYYY}
                    onClick={() => handleSelectSuffix(SuffixOptions.Q_YYYY)}
                  >
                    {dateFormats[SuffixOptions.Q_YYYY]}
                  </SelectableOption>
                </>
              )}
              {suffixGroup === SuffixGroups.YEAR && (
                <SelectableOption
                  className={styles.suffixOption}
                  selected={suffix === SuffixOptions.YYYY}
                  onClick={() => handleSelectSuffix(SuffixOptions.YYYY)}
                >
                  {dateFormats[SuffixOptions.YYYY]}
                </SelectableOption>
              )}
            </div>
          </div>
        </section>
      </Collapsable>

      {showFolderLocation && (
        <>
          <div>
            <h3 className={styles.label}>Folder location</h3>
          </div>
          <section className={styles.rowContent}>
            <SelectFolder
              selectedParentFolder={selectedParentFolder}
              setSelectedParentFolder={setSelectedParentFolder}
              oauthType={isGoogleDestination(destination) ? OAuthType.GOOGLE : OAuthType.MICROSOFT}
            />
          </section>
        </>
      )}

      {isGoogleDestination(destination) && (
        <>
          <div>
            <h3 className={styles.label}>Link on images</h3>
          </div>
          <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
            <DestinationLinkOnImages linkOption={linkOption} setLinkOption={setLinkOption} />
          </section>
        </>
      )}

      <div>
        <h3 className={styles.label}>Default Crop Option</h3>
        <p className={styles.subLabel}>By default, all new syncs will use the selected crop option.</p>
      </div>
      <section className={classNames(styles.rowContent, styles.centeredRowContent)}>
        <DestinationCropOptionSelect cropOption={cropOption} setCropOption={setCropOption} />
      </section>

      <hr />

      <section className={classNames(styles.rowContent, styles.saveButtonRow)}>
        <Button text="Save" onClick={() => updateConfiguration({ showToast: true })} loading={isSubmitting} />
      </section>
    </div>
  )
}
