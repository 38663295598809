import styles from '@features/destinations/components/destination-settings/destination-settings.module.css'
import { LinkOption } from '@features/destinations/types'

import { SelectableOption } from '@shared/components'

interface Props {
  linkOption: LinkOption
  setLinkOption: (linkOption: LinkOption) => void
}

export const DestinationLinkOnImages = ({ linkOption, setLinkOption }: Props) => {
  return (
    <>
      <SelectableOption
        className={styles.option}
        selected={linkOption === LinkOption.rollstack}
        onClick={() => setLinkOption(LinkOption.rollstack)}
      >
        <div className={styles.optionWithoutDescription}>Link to Rollstack visualization settings</div>
      </SelectableOption>

      <SelectableOption
        className={styles.option}
        selected={linkOption === LinkOption.external}
        onClick={() => setLinkOption(LinkOption.external)}
      >
        <div className={styles.optionWithoutDescription}>Link to visualization's dashboard</div>
      </SelectableOption>

      <SelectableOption
        className={styles.option}
        selected={linkOption === LinkOption.nolink}
        onClick={() => setLinkOption(LinkOption.nolink)}
      >
        <div className={styles.optionTitle}>No link</div>
      </SelectableOption>
    </>
  )
}
