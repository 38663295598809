import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react'
import { Socket, io } from 'socket.io-client'

import { SlidesThumbnailsEventArgs } from '@features/destinations/slides-thumbnails-listener'
import {
  AddVariantsStatus,
  CollectionUpdateStatus,
  DestinationUpdateStatus,
  RegenerateVariantsStatus,
  VisUpdateStatus,
} from '@features/notch/destinations-status-provider'
import { Invite } from '@features/organizations/types'
import { useAuthStore } from '@features/users'

import { config } from '@shared/config'

export interface ServerToClientEvents {
  syncUpdateStatus: (data: VisUpdateStatus) => void
  collectionUpdateStatus: (data: CollectionUpdateStatus) => void
  destinationUpdateStatus: () => DestinationUpdateStatus
  addVariantsStatus: (data: AddVariantsStatus) => void
  regenerateVariantsStatus: (data: RegenerateVariantsStatus) => void
  organizationInvite: (data: Invite) => void
  destinationSlidesThumbnailsUpdate: (data: SlidesThumbnailsEventArgs) => void
  [s: string]: (args: any) => any
}

interface ClientToServerEvents {
  allDestinationsStatuses: () => void
  allCollectionsStatuses: () => void
  allAddVariantsStatuses: () => void
  allRegenerateVariantsStatuses: () => void
}

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(config.apiHost.replace('/v1', ''), {
  path: '/ws',
  autoConnect: false,
})

export const SocketContext = createContext({
  connected: false,
  socket,
})

export const SocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuthStore()

  const [connected, setConnected] = useState(socket.connected)

  const token = user?.tokens?.access?.token

  useEffect(() => {
    function onConnect() {
      setConnected(true)
    }

    function onDisconnect() {
      setConnected(false)
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [])

  useEffect(() => {
    socket.auth = cb => {
      cb({ token })
    }
    if (token) {
      socket.connect()
    }
  }, [token])

  return <SocketContext.Provider value={{ connected, socket }}>{children}</SocketContext.Provider>
}
