import { FC } from 'react'

import { IIcon } from '@assets/types'

export const DuplicateVariantIcon: FC<IIcon> = ({ className, color = 'currentColor' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
      <path
        d="M8.75002 1.66908C8.18748 1.6767 7.84977 1.70927 7.5767 1.8484C7.2631 2.00819 7.00813 2.26316 6.84834 2.57676C6.70921 2.84983 6.67664 3.18754 6.66902 3.75008M16.25 1.66908C16.8126 1.6767 17.1503 1.70927 17.4233 1.8484C17.7369 2.00819 17.9919 2.26316 18.1517 2.57676C18.2908 2.84983 18.3234 3.18754 18.331 3.75007M18.331 11.2501C18.3234 11.8126 18.2908 12.1503 18.1517 12.4234C17.9919 12.737 17.7369 12.992 17.4233 13.1518C17.1503 13.2909 16.8126 13.3235 16.25 13.3311M18.3334 6.66674V8.33341M11.6667 1.66675H13.3333M4.33335 18.3334H10.6667C11.6001 18.3334 12.0668 18.3334 12.4233 18.1518C12.7369 17.992 12.9919 17.737 13.1517 17.4234C13.3334 17.0669 13.3334 16.6002 13.3334 15.6667V9.33341C13.3334 8.39999 13.3334 7.93328 13.1517 7.57676C12.9919 7.26316 12.7369 7.00819 12.4233 6.8484C12.0668 6.66675 11.6001 6.66675 10.6667 6.66675H4.33335C3.39993 6.66675 2.93322 6.66675 2.5767 6.8484C2.2631 7.00819 2.00813 7.26316 1.84834 7.57676C1.66669 7.93328 1.66669 8.39999 1.66669 9.33341V15.6667C1.66669 16.6002 1.66669 17.0669 1.84834 17.4234C2.00813 17.737 2.2631 17.992 2.5767 18.1518C2.93322 18.3334 3.39993 18.3334 4.33335 18.3334Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
