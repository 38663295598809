import { DestinationStatusMessage, SyncStatusMessage } from "@features/syncs/types"

export function retrieveErrorString(error: unknown): string {
  if (!error) return 'NULL_ERROR'

  if (typeof error === 'string' && error in SyncStatusMessage) {
    return SyncStatusMessage[error as keyof typeof SyncStatusMessage]
  }

  if (typeof error === 'object' && 'message' in error) {
    return error.message as string
  }

  return JSON.stringify(error)
}

export const retrieveDestinationErrorString = (error: unknown): string => {
  if (!error) return 'NULL_ERROR'

  if (typeof error === 'string' && error in DestinationStatusMessage) {
    return DestinationStatusMessage[error as keyof typeof DestinationStatusMessage]
  }

  if (typeof error === 'object' && 'message' in error) {
    return error.message as string
  }

  return JSON.stringify(error)
}