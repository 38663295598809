import { FC } from 'react'

import { IIcon } from '@assets/types'

export const UsersPlusIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6667 17.5V12.5M14.1667 15H19.1667M10.8333 12.5H7.5C5.94686 12.5 5.17029 12.5 4.55772 12.7537C3.74096 13.092 3.09205 13.741 2.75373 14.5577C2.5 15.1703 2.5 15.9469 2.5 17.5M13.75 2.7423C14.9716 3.23679 15.8333 4.43443 15.8333 5.83333C15.8333 7.23224 14.9716 8.42988 13.75 8.92437M12.0833 5.83333C12.0833 7.67428 10.5909 9.16667 8.75 9.16667C6.90905 9.16667 5.41667 7.67428 5.41667 5.83333C5.41667 3.99238 6.90905 2.5 8.75 2.5C10.5909 2.5 12.0833 3.99238 12.0833 5.83333Z"
        stroke="currentColor"
        strokeWidth="1.6805"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
