import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { useAddParentLookerDataSource, useGetLookerDataSource, useUpdateLookerDataSource } from '@features/data-sources'
import { EDataSourceType, IDataSourceValidationErrors } from '@features/data-sources/types/types'
import { validateURLAndGetError } from '@features/embedding/shared/utils'

import { updateDataSourceToast } from '@shared/components'
import { useBoolean } from '@shared/hooks/use-boolean'
import { useCopyToClipboard } from '@shared/hooks/use-copy-to-clipboard'
import { useStateReducer } from '@shared/hooks/use-state-reducer'
import { isFeatureEnabled } from '@shared/product-tooling/posthog/posthog'
import { parseDuration } from '@shared/utils/time'

import { hasNoValidationErrors } from './utils'

const initialState = {
  id: '',
  name: '',
  baseUrl: '',
  clientId: '',
  clientSecret: '',
  embedSecret: '',
  type: EDataSourceType.Looker as const,
  connection: {
    loading: false,
    connected: true,
    failed: false,
  },
  autoProvision: true,
}

const FIELDS_TO_INVALIDATE_DATA_SOURCE = ['baseUrl', 'embedSecret']

export const useLookerDataSourcesSettings = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [state, updateState] = useStateReducer(initialState)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [testModal, toggleTestModal] = useBoolean(false)
  const [validationErrors, setValidationErrors] = useState<IDataSourceValidationErrors>({})
  const copy = useCopyToClipboard()

  const { data: dataSource } = useGetLookerDataSource(params.sourceId)
  const addLookerDataSourceMutation = useAddParentLookerDataSource()
  const updateLookerDataSourceMutation = useUpdateLookerDataSource()

  const toggleAutoProvision = () => updateState({ autoProvision: !state.autoProvision })

  const isCookielessEmbed = isFeatureEnabled('cookieless-embed')
  const isFormValid =
    state.name && state.baseUrl && (state.embedSecret || isCookielessEmbed) && hasNoValidationErrors(validationErrors)
  const isUpdate = Boolean(params.sourceId)

  const isLoading = addLookerDataSourceMutation.isLoading || updateLookerDataSourceMutation.isLoading

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    updateState({ [name]: value })
    if (name === 'baseUrl') {
      const validationError = validateURLAndGetError(value)
      setValidationErrors(err => ({
        ...err,
        baseUrlError: validationError,
      }))
      if (timer) {
        clearTimeout(timer)
      }
      setTimer(
        setTimeout(() => {
          const newUrl = value.replace(/\/+$/, '')
          updateState({ [name]: newUrl })
        }, parseDuration('1s'))
      )
    }

    if (name === 'clientId') {
      updateState({ [name]: value.trim() })
    }

    if (FIELDS_TO_INVALIDATE_DATA_SOURCE.includes(name)) {
      updateState({ connection: { connected: false, failed: false, loading: false } })
    }
  }

  const saveDataSource = async () => {
    if (isLoading) return
    if (isUpdate && state.id) {
      await updateLookerDataSourceMutation.mutateAsync(state)
      updateDataSourceToast(isUpdate)
    } else {
      await addLookerDataSourceMutation.mutateAsync(state)
      updateDataSourceToast(isUpdate)
    }
    navigate(Paths.DATA_SOURCES)
  }

  const testAndSave = async () => {
    if (state.connection.connected) {
      saveDataSource()
    } else {
      toggleTestModal()
    }
  }

  useEffect(() => {
    if (dataSource) {
      updateState(dataSource)
    }
  }, [dataSource, updateState])

  return {
    state,
    testModal,
    isFormValid,
    isLoading,
    isUpdate,
    validationErrors,
    copy,
    testAndSave,
    toggleTestModal,
    handleInputChange,
    toggleAutoProvision,
  }
}
