import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'

import { useAuthStore } from '@features/users'

import { getMessage } from '@shared/constants'
import { onError, queryClient, useQueryWithErrorHandler } from '@shared/http'
import { api } from '@shared/http/axios'

import {
  ActiveOrganizationUser,
  Organization,
  OrganizationUser,
  UserInOrganization,
  UserOrganizationRole,
} from './types'

export const useGetUsersInOrganizations = () => {
  const { user } = useAuthStore()
  return useQueryWithErrorHandler<UserInOrganization[], AxiosError>(
    ['userInOrganization', { userId: user?.id }],
    async () => {
      if (!user) return

      const response = await api.get('/users-in-organization')

      if (!response) {
        return []
      }

      return response.data
    },
    {
      canRequestWithoutActiveOrganization: true,
      errorTitle: getMessage('USERS_IN_ORGANIZATION_GET_ERROR'),
      useErrorBoundary: false,
    }
  )
}

export const useGetOrganizationUsers = (organizationId?: string) => {
  const { user } = useAuthStore()
  return useQuery<OrganizationUser[], Error>(
    ['organizationUsers', organizationId, { userId: user?.id }],
    async () => {
      if (!organizationId || !user) return []

      const response = await api.get(`/organizations/${organizationId}/users`)

      if (!response) {
        return []
      }

      return response.data
    },
    {
      onError: (error: Error) => {
        onError(error, {
          title: getMessage('ORGANIZATION_USERS_GET_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

type InviteUserRequest = {
  email: string
  organizationId?: string
  role?: UserOrganizationRole
}

export const useInviteOrganizationUser = () => {
  return useMutation((data: InviteUserRequest) => api.post('/users/invite', JSON.stringify(data)), {
    onSuccess: response => {
      console.log(response)
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('INVITE_USER_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useRemoveInvite = () => {
  return useMutation((inviteId: number) => api.delete(`/users/invite/${inviteId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('userInvites')
      queryClient.invalidateQueries('organizationUsers')
      queryClient.invalidateQueries('userInOrganization')
    },
    onError: (error: AxiosError) => {
      onError(error, {
        title: getMessage('INVITE_REMOVE_ERROR'),
        message: error.message,
      })
    },
    useErrorBoundary: false,
  })
}

export const useAcceptInvite = () => {
  return useMutation((inviteId: number) => api.post(`/users/invite/${inviteId}/accept`), {
    onSuccess: () => {
      queryClient.invalidateQueries('userInvites')
      queryClient.invalidateQueries('organizationUsers')
      queryClient.invalidateQueries('userInOrganization')
    },
    onError: (error: AxiosError) => {
      console.log(error)
    },
  })
}

export const useDeclineInvite = () => {
  return useMutation((inviteId: number) => api.post(`/users/invite/${inviteId}/decline`), {
    onSuccess: () => {
      queryClient.invalidateQueries('userInvites')
      queryClient.invalidateQueries('organizationUsers')
      queryClient.invalidateQueries('userInOrganization')
    },
    onError: (error: AxiosError) => {
      console.log(error)
    },
  })
}

type RemoveUserRequest = {
  userId: number
  organizationId: string
}

export const useRemoveUserFromOrganization = () => {
  return useMutation(
    (data: RemoveUserRequest) => api.delete(`/organizations/${data.organizationId}/users/${data.userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userInOrganization')
        queryClient.invalidateQueries('organizationUsers')
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('ORGANIZATION_USER_REMOVE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

type UpdateOrganizationRequest = Partial<Pick<Organization, 'domain' | 'name' | 'slug'>>

export const useUpdateOrganization = (organizationId?: string) => {
  return useMutation(
    (organizationSettings: UpdateOrganizationRequest) =>
      api.patch(`/organizations/${organizationId}`, JSON.stringify(organizationSettings)),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('userInOrganization')
        return data
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('ORGANIZATION_UPDATE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}

type UpdateUserInOrganizationRequest = Pick<ActiveOrganizationUser, 'role' | 'userId'>

export const useUpdateUserInOrganization = (organizationId: string) => {
  return useMutation(
    ({ role, userId }: UpdateUserInOrganizationRequest) => {
      return api.patch(`/organizations/${organizationId}/users/${userId}`, JSON.stringify({ role }))
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('userInOrganization')
        queryClient.invalidateQueries('organizationUsers')
      },
      onError: (error: AxiosError) => {
        onError(error, {
          title: getMessage('ORGANIZATION_USER_UPDATE_ERROR'),
          message: error.message,
        })
      },
      useErrorBoundary: false,
    }
  )
}
