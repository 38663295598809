import classNames from 'classnames'
import { FC } from 'react'

import { SmallSpinner } from '@assets'
import { PresentationPlaceholderIcon } from '@assets/icons/presentation-placeholder'

import { useGetDestinationThumbnails } from '@features/destinations'

import { ImageWithFallback } from '@shared/components'

import styles from './ms-powerpoint.module.css'

interface IMSPowerpoint {
  className?: string
  height?: number
  width?: number
  loading?: boolean
  destinationId: string
  title?: string
}

const defaultWidth = 640
const defaultHeight = 480
const slidesWidthDiff = 4
const slidesHeightDiff = 3

export const MSPowerpoint: FC<IMSPowerpoint> = ({
  className,
  height = defaultHeight,
  width = defaultWidth,
  title = '',
  destinationId,
  loading,
}) => {
  const { data, isLoading } = useGetDestinationThumbnails(destinationId)

  const classes = classNames(styles.slides, className)
  const containerHeight = height - slidesHeightDiff
  const containerWidth = width - slidesWidthDiff

  // eliminate caching
  const url = data?.url && `${data.url}&timestamp=${new Date().getTime()}`

  const showLoader = isLoading || loading
  return (
    <div className={classes} style={{ width: containerWidth, height: containerHeight }}>
      {showLoader && (
        <div className={styles.spinnerContainer} hidden={!isLoading && !loading} style={{ height: `${height}px` }}>
          <SmallSpinner />
        </div>
      )}
      {!showLoader && (
        <ImageWithFallback
          disablePosthogCapture
          src={url}
          alt={title}
          imageSize={{ width, height }}
          fallback={<PresentationPlaceholderIcon />}
        />
      )}
    </div>
  )
}
