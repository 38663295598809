import { FC, useCallback, useState } from 'react'

import { MoveToIcon } from '@assets'

import { ISlide } from '@features/destinations'
import { Destination } from '@features/destinations/types'
import { useUpdateSync } from '@features/syncs/api'
import { SlidePreview } from '@features/syncs/components/sync-settings/slide-preview/slide-preview'
import { ISync } from '@features/syncs/types'

import { ActionModal, Button } from '@shared/components'

import styles from './sync-location.module.css'

interface SyncPositionProps {
  destination: Destination
  slides: ISlide[]
  open: boolean
  sync: ISync
  toggle: () => void
}

export const SyncLocation: FC<SyncPositionProps> = ({ destination, slides, open, sync, toggle }) => {
  const [slideIndex, setSlideIndex] = useState<number>(sync.slideIndex)

  const { mutateAsync: updateSync, isLoading } = useUpdateSync()

  const handleSave = useCallback(async () => {
    const slide = slides[slideIndex - 1]
    await updateSync({
      id: sync.id,
      updatedAt: sync.updatedAt,
      slideIndex: slideIndex,
      slideId: slide.id,
    })
    toggle()
  }, [updateSync, sync.id, sync.updatedAt, slideIndex, toggle, slides])

  const handleSlideIndexChange = (index: number) => {
    setSlideIndex(index)
  }

  return (
    <ActionModal
      open={open}
      className={styles.container}
      onBackgroundClick={toggle}
      Icon={MoveToIcon}
      title="Move to"
      btns={
        <>
          <Button secondaryGray text="Cancel" onClick={toggle} fullWidth />
          <Button text="Save" loading={isLoading} onClick={handleSave} fullWidth />
        </>
      }
    >
      <SlidePreview
        destination={destination}
        slides={slides}
        slideIndex={slideIndex}
        slideId={sync.slideId}
        setSlideIndex={handleSlideIndexChange}
      />
    </ActionModal>
  )
}
