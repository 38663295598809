import httpStatus from 'http-status'

import { dataOutOfDateToast, transferSyncCredentialsErrorToast } from '@shared/components'
import { getMessage } from '@shared/constants'
import { ApiError, onError, queryClient } from '@shared/http'
import { ApiErrorHandlerFn } from '@shared/http/types'

export const handleSyncUpdateError: ApiErrorHandlerFn = (apiError: ApiError): boolean => {
  if (apiError.statusCode === httpStatus.CONFLICT) {
    dataOutOfDateToast({
      handleDataRefresh: () => {
        if (apiError.metadata.id) {
          queryClient.invalidateQueries(['sync', apiError.metadata.id])
        }
        if (apiError.metadata.presentationId) {
          queryClient.invalidateQueries(['presentation', { destinationId: apiError.metadata.presentationId }])
        }
        if (apiError.metadata.collectionId) {
          queryClient.invalidateQueries(['collection', { collectionId: apiError.metadata.collectionId }])
        }
      },
    })
    return true
  }
  if (apiError.statusCode === httpStatus.GONE) {
    onError(apiError, {
      title: getMessage('SYNC_UPDATE_IS_DELETED'),
      message: apiError.message,
      type: 'warning',
    })
    return true
  }
  return false
}

export const handleTransferSyncError: ApiErrorHandlerFn = (apiError: ApiError): boolean => {
  if (apiError.code === 'no-matching-credentials-for-transfer') {
    transferSyncCredentialsErrorToast()
    return true
  }
  return false
}