import { partition } from 'lodash'
import { FC, useState } from 'react'

import { FilterIcon, InfoIcon, UpdateIcon } from '@assets'
import { CollectionIcon } from '@assets/icons/collection'
import { DuplicateVariantIcon } from '@assets/icons/duplicate-variant'

import { useGetFilters } from '@features/collections/api/api'
import { DashboardOption, FilterOption, ParsedVariant } from '@features/collections/types'
import { destinationHandlers, useRefreshFilters } from '@features/destinations'
import { Destination } from '@features/destinations/types'
import { FilterType } from '@features/filters/types'
import { parseSyncProperties } from '@features/syncs'
import { ISync } from '@features/syncs/types'

import { ActionModal, Badge, Button, EBadgeColor, RollstackTabs } from '@shared/components'
import { DataGrid } from '@shared/components/data-grid/data-grid'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shared/components/ui/table'
import { useBoolean } from '@shared/hooks'
import { queryClient } from '@shared/http'

import { reviewCollectionTableColumns, reviewCollectionTableData } from './review-collection-table'

import styles from './review-collection.module.css'

interface ReviewCollectionProps {
  destination: Destination
  dashboards: DashboardOption[]
  selectedFilters: FilterOption[] | null
  parsedVariants: ParsedVariant[]
}

export const getIntegrationName = (type?: FilterType) => {
  switch (type) {
    case FilterType.LookerFilter:
      return 'Looker'
    case FilterType.MetabaseFilter:
      return 'Metabase'
    case FilterType.TableauFilter:
    case FilterType.TableauParams:
      return 'Tableau'
    default:
      return ''
  }
}

export const ReviewCollection: FC<ReviewCollectionProps> = ({
  destination,
  selectedFilters,
  dashboards,
  parsedVariants,
}) => {
  const [refreshModal, toggleRefreshModal] = useBoolean()
  const [tab, setTab] = useState(0)
  const { data: filters, refetch: refetchFilters } = useGetFilters({
    types: selectedFilters ? selectedFilters.map(f => f.type) : undefined,
    dashboardIds: dashboards.map(d => d.id),
  })
  const { mutateAsync: refreshFilters, isLoading: isRefreshingFilters } = useRefreshFilters()

  const handler = destinationHandlers(destination)
  const collectionFilters = filters?.filter(f => selectedFilters?.map(({ id }) => id).includes(f.id)) ?? []

  const hasManyFilters = collectionFilters.length > 1
  const integrationName = getIntegrationName(selectedFilters?.[0].type)

  const checkFiltersForSync = (sync: ISync) => {
    if (!filters) {
      return false
    }

    const { dashboardId, dataSourceId } = parseSyncProperties(sync)

    const filtersFromSyncsDashboard = filters.filter(
      f => f.dashboardId === dashboardId && f.credentialsId === dataSourceId
    )
    const filtersWithSameNameAndType = filtersFromSyncsDashboard.filter(f =>
      collectionFilters?.find(cf => cf.name === f.name && cf.type === f.type)
    )

    return filtersWithSameNameAndType.length === collectionFilters?.length
  }

  const syncsWithFilter =
    destination.syncs?.map(sync => ({
      ...sync,
      filtersAreApplicableToDashboard: Boolean(checkFiltersForSync(sync)),
    })) ?? []
  const showWarning = syncsWithFilter?.some(sync => !sync.filtersAreApplicableToDashboard)

  const [impactedSyncs, nonImpactedSyncs] = partition(syncsWithFilter, sync => sync.filtersAreApplicableToDashboard)

  const handleRefreshFilters = async () => {
    await refreshFilters(destination)
    await queryClient.invalidateQueries(['presentation', { destinationId: destination.id }])
    await refetchFilters()
    toggleRefreshModal(false)
  }

  const renderSyncs = (syncs: Array<ISync & { filtersAreApplicableToDashboard: boolean }>) => {
    return (
      <DataGrid
        data={reviewCollectionTableData(syncs)}
        columns={reviewCollectionTableColumns()}
        EmptyState="No visualization"
      />
    )
  }

  return (
    <div className={styles.reviewRootContainer}>
      <div className="text-sm-medium">Here is a summary of the collection you are about to create:</div>

      <div className={styles.destination}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldDetails}>
            <div className={styles.fieldName}>
              <DuplicateVariantIcon />
              Template
            </div>
            <div className={styles.fieldValue}>
              {handler.getSmallIcon()} {destination.name}
            </div>
          </div>
          <div className={styles.fieldDetails}>
            <div className={styles.fieldName}>
              <FilterIcon />
              Main filter
            </div>
            <div className={styles.fieldValue}>
              <div className={styles.flexCol}>
                {collectionFilters?.map(({ name }) => <div key={name}>• {name}</div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!parsedVariants.length && (
        <div className={styles.fieldDetails}>
          <div className={styles.fieldName}>
            <CollectionIcon />
            Variants
            <Badge text={parsedVariants.length.toString()} color={EBadgeColor.PRIMARY} />
          </div>
          <div className={styles.fieldValue}>
            <div className="max-h-64 overflow-y-auto pr-4">
              <div className="border rounded-lg">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[300px]">{collectionFilters?.[0]?.name}</TableHead>
                      {collectionFilters?.[1] && (
                        <TableHead className="w-[300px]">{collectionFilters[1]?.name}</TableHead>
                      )}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {parsedVariants.map((parsedVariant, i) => (
                      <TableRow key={i}>
                        <TableCell className="font-medium">{parsedVariant.primaryValue}</TableCell>
                        {parsedVariant.secondaryValue && (
                          <TableCell className="font-medium">{parsedVariant.secondaryValue}</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      )}

      <RollstackTabs
        tabs={[
          <div className={styles.tab}>
            Impacted visualizations
            <Badge
              text={impactedSyncs.length.toString()}
              color={tab === 0 ? EBadgeColor.PRIMARY : EBadgeColor.LIGHTGRAY}
            />
          </div>,
          <div className={styles.tab}>
            Non impacted visualizations
            <Badge
              text={nonImpactedSyncs.length.toString()}
              color={tab === 1 ? EBadgeColor.PRIMARY : EBadgeColor.LIGHTGRAY}
            />
          </div>,
        ]}
        onSelect={i => setTab(i)}
        selectedIndex={tab}
      >
        <div className={styles.slidesContainer}>{renderSyncs(impactedSyncs)}</div>
        <div>
          {filters && showWarning && (
            <div className={styles.warningContainer}>
              <InfoIcon color="currentColor" size={20} />
              <div>
                <div className={styles.warningTitle}>Non impacted visualizations</div>
                <div>
                  The <b>{collectionFilters?.map(({ name }) => name).join(' and ')}</b>{' '}
                  {hasManyFilters ? 'filters are' : 'filter is'} not applicable to these visualizations dashboards, so
                  these will be the same across all collection variants.
                  <br />
                  If you recently modified your {integrationName} dashboard&#39;s filters, please click the button below
                  to sync these new filters data. If you still don&#39;t see your filter, make sure it has the same name
                  across the dashboards.
                </div>
                <Button
                  iconLeading={<UpdateIcon color="currentColor" />}
                  text="Refresh filters"
                  className={styles.refreshBtn}
                  onClick={() => toggleRefreshModal(true)}
                />
              </div>
            </div>
          )}
          <div className={styles.slidesContainer}>{renderSyncs(nonImpactedSyncs)}</div>
        </div>
      </RollstackTabs>

      <ActionModal
        open={refreshModal}
        Icon={FilterIcon}
        title="Refresh filters"
        subtitle="This will fetch new values from the filters you’re using in this presentation. This may take a while."
        processing={isRefreshingFilters}
        onBackgroundClick={() => toggleRefreshModal(false)}
        btns={
          <>
            <Button
              fullWidth
              secondaryGray
              text="Cancel"
              onClick={() => toggleRefreshModal(false)}
              disabled={isRefreshingFilters}
            />
            <Button fullWidth text="Refresh all" onClick={handleRefreshFilters} loading={isRefreshingFilters} />
          </>
        }
      />
    </div>
  )
}
