import { Crisp } from 'crisp-sdk-web'

import { Button } from '@shared/components/button/button'

import styles from './free-trial-toast.module.css'

export const FreeTrialToast = () => {
  const onUpgradePlan = () => {
    Crisp.chat.open()
    Crisp.message.sendText(`Hey there 👋
      I would like to upgrade my current plan.
    `)
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>You have reached the limits of your free trial</div>
      <div className={styles.body}>
        <Button className={styles.btn} secondaryColor text="Upgrade plan" onClick={onUpgradePlan} />
      </div>
    </div>
  )
}
