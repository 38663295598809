type FunctionMessageKeys = {
  [K in keyof typeof errorMessages]: (typeof errorMessages)[K] extends Function ? K : never
}[keyof typeof errorMessages]

type NonFunctionMessageKeys = {
  [K in keyof typeof errorMessages]: (typeof errorMessages)[K] extends Function ? never : K
}[keyof typeof errorMessages]

export function getMessage<K extends FunctionMessageKeys>(
  messageKey: K,
  ...args: Parameters<(typeof errorMessages)[K]>
): string
export function getMessage<K extends NonFunctionMessageKeys>(messageKey: K, ...args: never[]): string
export function getMessage<K extends keyof typeof errorMessages>(messageKey: K, ...args: any[]): string {
  const messageOrFunction = errorMessages[messageKey]
  if (typeof messageOrFunction === 'function') {
    return (messageOrFunction as (...args: any[]) => string)(...args)
  }
  return messageOrFunction ?? messageKey
}

const errorMessages = {
  ACTIVITIES_LOGS_ERROR: 'We could not fetch the activity log',
  COLLECTION_ADD_ERROR: 'We could not create this collection',
  COLLECTION_ADD_NO_SYNCS: 'The destination must have at least one visualization to start a collection',
  COLLECTION_ADD_NO_UPDATE: 'The destination must have been updated at least once before starting a collection',
  COLLECTION_ADD_SHARED:
    "Shared destinations can't be used to start a collection. Please duplicate the destination and start a collection from the new one",
  COLLECTION_ADD_VIEWER: 'You need edit permissions to start collection from this destination',
  COLLECTION_ARCHIVE_SYNC_ERROR: 'We could not archive & update this collection',
  COLLECTION_CANCEL_UPDATE_ERROR: 'We could not cancel the update of this collection',
  COLLECTION_DELETE_ERROR: 'We could not delete this collection',
  COLLECTION_DUPLICATE_ERROR: 'We could not duplicate this collection',
  COLLECTION_DRIVE_DATA_GET_ERROR: 'We could not fetch the collection drive data',
  COLLECTION_EMAIL_CONFIG_GET_ERROR: 'We could not fetch the collection email config',
  COLLECTION_EMAIL_CONFIG_ADD_ERROR: 'We could not add the collection email config',
  COLLECTION_EMAIL_CONFIG_UPDATE_ERROR: 'We could not update the collection email config',
  COLLECTION_GET_ERROR: 'We could not fetch this collection',
  COLLECTION_INSUFFICIENT_PERMISSIONS_ERROR:
    "Please make sure you have edit permission on the collection's template destination and the collection's parent folder in your drive",
  COLLECTION_SEND_TEST_EMAIL_FAILED: 'We failed to send the test email',
  COLLECTION_SEND_EMAIL_FAILED: 'We failed to send the emails',
  COLLECTION_SYNC_ERROR: 'We could not update this collection',
  COLLECTION_UPDATE_ERROR: 'We could not update this collection',
  COLLECTION_VARIANT_UPDATE_ERROR: 'We could not update the collection variant',
  COLLECTION_VARIANTS_DELETE_ERROR: 'We could not delete the collection variant',
  COLLECTION_VARIANTS_UPDATE_ERROR: 'We could not update the collection variants',
  COLLECTIONS_GET_ERROR: 'We could not fetch your collections',
  COLLECTIONS_ADD_VARIANTS_CANCEL_FAILED_ERROR: 'We could not cancel these variants',
  DATA_SOURCE_GET_ERROR: (key: string) => `We could not fetch this ${key} data source`,
  DATA_SOURCES_GET_ERROR: 'We could not fetch the data sources',
  DATA_SOURCES_DEFAULT_ERROR: 'We could not set the default data source',
  DATA_SOURCES_REQUEST_ERROR: 'Error requesting data sources',
  DESTINATION_DRIVE_DATA_GET_ERROR: 'We could not fetch the destination data',
  DESTINATION_DRIVE_PERMISSION_CHECK_GET_ERROR: 'We could not check the drive permission for the destination',
  EMAIL_TEMPLATE_GET_ERROR: 'We could not fetch the destination email templates',
  EMAIL_TEMPLATE_ADD_ERROR: 'We could not add the destination email template',
  EMAIL_TEMPLATE_UPDATE_ERROR: 'We could not update the destination email template',
  DESTINATION_EMAIL_CONFIG_ADD_ERROR: 'We could not add the destination email config',
  DESTINATION_EMAIL_CONFIG_GET_ERROR: 'We could not fetch the destination email config',
  DESTINATION_EMAIL_CONFIG_UPDATE_ERROR: 'We could not update the destination email config',
  DESTINATION_TEST_EMAIL_SEND_ERROR: 'We could not send the test email',
  DESTINATION_EMAIL_SEND_ERROR: 'We could not send the email',
  DESTINATION_INSUFFICIENT_PERMISSIONS_ERROR:
    'Please make sure you have edit permission on the selected destination in your drive',
  DESTINATION_NOT_FOUND_OR_DELETED:
    'If you cannot access this destination, it may have been deleted. If not, You can re-authorize it',
  DESTINATION_THUMBNAIL_GET_ERROR: 'We could not fetch the destination thumbnail',
  DESTINATION_THEME_COLORS_GET_ERROR: 'We could not fetch the destination theme colors',
  DESTINATION_UPDATE_CANCEL_FAILED_ERROR: 'We could not cancel this update',
  DESTINATIONS_UPDATE_CANCEL_FAILED_ERROR: 'We could not cancel these updates',
  DESTINATIONS_BATCH_REMOVE_APPLIED_FILTERS_FAILED_ERROR: 'We could not remove the filters',
  DESTINATIONS_BATCH_APPLY_APPLIED_FILTERS_FAILED_ERROR: 'We could not apply the filters',
  GOOGLE_OAUTH_INVALID_TOKEN: 'Your Google OAuth token may be invalid. Please re-authorize',
  GOOGLE_TOKEN_GET_ERROR: 'We could not get your Google OAuth tokens',
  HISTORICAL_RUN_GET_ERROR: "We couldn't fetch this historical run",
  HISTORICAL_RUN_NOT_FOUND: "We don't have this historical run",
  INVALID_CREDENTIALS: 'Your credentials may be incomplete or invalid',
  INVITE_REMOVE_ERROR: 'We could not remove this invite',
  INVITE_USER_ERROR: 'We could not invite this user',
  LOGOUT_ERROR: 'We could not log you out',
  LOOKER_API_ERROR_GENERIC: 'We could not connect to Looker',
  LOOKER_CONNECT_TO_DASHBOARD_ERROR: 'We could not connect to this Looker dashboard',
  LOOKER_CONNECT_TO_DASHBOARD_NOT_FOUND_ERROR: 'We could not find this Looker dashboard',
  LOOKER_DASHBOARD_EMBED_DATA_GET_ERROR: 'We could not fetch the Looker embed data',
  LOOKER_DASHBOARD_GET_ERROR: 'We could not fetch this Looker dashboard',
  LOOKER_DASHBOARDS_SEARCH_ERROR: 'We could not search the Looker dashboards',
  LOOKER_DATA_SOURCE_ADD_ERROR: 'We could not create this Looker data source',
  LOOKER_DATA_SOURCE_DELETE_ERROR: 'We could not delete this Looker data source',
  LOOKER_DATA_SOURCE_GET_ERROR: 'We could not fetch this Looker data source',
  LOOKER_DATA_SOURCE_UPDATE_ERROR: 'We could not update this Looker data source',
  LOOKER_DATA_SOURCES_GET_ERROR: 'We could not fetch the Looker data sources',
  LOOKER_FILTERS_GET_ERROR: 'We could not fetch the Looker filters',
  LOOKER_FILTERS_VALUES_GET_ERROR: 'We could not fetch the Looker filters values',
  LOOKER_FOLDER_DASHBOARDS_GET_ERROR: 'We could not fetch the Looker folder dashboards',
  LOOKER_FOLDERS_GET_ERROR: 'We could not fetch the Looker folders',
  METABASE_API_ERROR_GENERIC: 'We could not connect to Metabase',
  METABASE_DASHBOARD_EMBED_URL_GET_ERROR: 'We could not fetch the Metabase dashboard embed URL',
  METABASE_DASHBOARD_FILTERS_GET_ERROR: 'We could not fetch the Metabase filters for this dashboard',
  METABASE_DASHBOARD_GET_ERROR: 'We could not fetch this Metabase dashboard',
  METABASE_DASHBOARDS_GET_ERROR: 'We could not fetch the Metabase dashboards',
  METABASE_DATA_SOURCE_ADD_ERROR: 'We could not add this Metabase data source',
  METABASE_DATA_SOURCE_DELETE_ERROR: 'We could not delete this Metabase data source',
  METABASE_DATA_SOURCE_UPDATE_ERROR: 'We could not update this Metabase data source',
  METABASE_DATA_SOURCES_GET_ERROR: 'We could not fetch the Metabase data sources',
  METABASE_FOLDERS_GET_ERROR: 'We could not fetch the Metabase folders',
  METABASE_FILTERS_VALUES_GET_ERROR: 'We could not fetch the Metabase filters values',
  MICROSOFT_TOKEN_SET_ERROR: 'We could not update your Microsoft OAuth tokens',
  ORGANIZATION_SETUP_ERROR: 'We could not set up your organization',
  ORGANIZATION_UPDATE_ERROR: 'We could not update this organization',
  ORGANIZATION_USER_REMOVE_ERROR: 'We could not remove this user from the organization',
  ORGANIZATION_USER_UPDATE_ERROR: 'We could not update this user in the organization',
  ORGANIZATION_USERS_GET_ERROR: 'We could not get the organization users',
  PRESENTATION_ARCHIVE_UPDATE_ERROR: 'We could not archive & update this presentation',
  PRESENTATION_DELETE_SLIDES_ERROR: 'We could not delete these slides',
  PRESENTATION_DUPLICATE_ERROR: 'We could not duplicate this presentation',
  PRESENTATION_DUPLICATE_SLIDES_ERROR: 'We could not duplicate these slides',
  PRESENTATION_GENERIC_ERROR: 'Something went wrong with this presentation',
  PRESENTATION_GET_ERROR: 'We could not fetch this presentation',
  PRESENTATION_GET_SYNCS_FILTER_APPLICABLE: 'We could not get the visualizations where the filters can be applied',
  PRESENTATION_UPDATE_ERROR: 'We could not update this presentation',
  PRESENTATIONS_ADD_ERROR: 'We could not add this presentation',
  PRESENTATIONS_GET_ERROR: 'We could not fetch the presentations',
  PRESENTATIONS_REFRESH_ERROR: 'We could not refresh these presentations',
  PRESENTATIONS_SYNC_ERROR: 'We could not sync these presentations',
  PRESENTATION_GET_SLIDES_THUMBNAILS_ERROR: 'We could not fetch the slides thumbnails',
  RENAME_MS_SHARED_ITEM: 'Renaming a shared Microsoft destination is not supported',
  SHARED_COLLECTIONS_GET_ERROR: 'We could not fetch shared collections',
  SHARED_DESTINATIONS_GET_ERROR: 'We could not fetch shared destinations',
  SLIDES_GET_ERROR: 'We could not fetch your slides',
  GET_FILTERS_ERROR: 'We could not fetch your filters',
  SPREADSHEETS_DATA_SOURCE_ADD_ERROR: 'We could not add this Spreadsheets data source',
  SPREADSHEETS_DATA_SOURCE_DELETE_ERROR: 'We could not delete this Spreadsheets data source',
  SPREADSHEET_DATA_GET_ERROR: 'We could not fetch the Spreadsheets data',
  SYNC_ADD_ERROR: 'We could not create this sync',
  SYNC_BATCH_APPLY_FILTERS_ERROR: 'We could not update the filters on syncs',
  SYNC_DELETE_FILTERS_ERROR: 'We could not delete filters for this sync',
  SYNC_TRANSFER_OWNERSHIP_ERROR: 'We could not transfer ownership of this sync',
  SYNC_GET_ERROR: 'We could not fetch this sync',
  SYNC_UPDATE_ERROR: 'We could not update this sync',
  SYNC_UPDATE_IS_DELETED: 'We could not update this sync because it has been deleted',
  SYNCS_DUPLICATE_ERROR: 'We could not duplicate this sync',
  TABLEAU_API_ERROR_GENERIC: 'We could not connect to Tableau',
  TABLEAU_DATA_SOURCE_ADD_ERROR: 'We could not add this Tableau data source',
  TABLEAU_DATA_SOURCE_DELETE_ERROR: 'We could not delete this Tableau data source',
  TABLEAU_DATA_SOURCE_GET_ERROR: 'We could not fetch this Tableau data source',
  TABLEAU_DATA_SOURCE_UPDATE_ERROR: 'We could not update this Tableau data source',
  TABLEAU_DATA_SOURCES_GET_ERROR: 'We could not fetch the Tableau data sources',
  TABLEAU_FILTERS_UPSERT_ERROR: 'We could not store the filter from this Tableau view',
  TABLEAU_PROJECTS_GET_ERROR: 'We could not fetch the Tableau projects',
  TABLEAU_VIEW_BY_ID_GET_ERROR: 'We could not fetch this Tableau view',
  TABLEAU_VIEWS_GET_ERROR: 'We could not fetch the Tableau views',
  TABLEAU_WORKBOOKS_GET_ERROR: 'We could not fetch the Tableau workbooks',
  TABLEAU_TOKEN_GET_ERROR: 'We could not get the Tableau token',
  TOKEN_REFRESH_ERROR: 'Error refreshing your token',
  TOKEN_REVOKE_ERROR: 'Error revoking your token',
  USER_ADD_ERROR: 'We could not add this user',
  USER_DELETE_ERROR: 'We could not delete this user',
  USER_REQUIRE_WORK_EMAIL: 'Rollstack can only be used with your work email',
  USER_REQUIRES_SSO: 'You must login with SSO',
  USERS_IN_ORGANIZATION_GET_ERROR: 'We could not get the users in the organization ',
} as const

export const SYNC_CAPABILITIES_MSG_REPOSITORY = {
  'invalid-credentials': 'Your credentials seems to be invalid. Please ask your admin to fix your credentials.',
  'missing-credentials': 'You don\'t have credentials for this visualization\'s data source',
  'view-only': 'You have view-only credentials for this visualization\'s data source',
  'unsupported-source-type': 'This visualization\'s data source is not supported by Rollstack',
  'missing-oauth': 'You need to authorize Rollstack to access your destination file',
  'invalid-oauth': 'Your authorization to access the destination file seems to be invalid. Please re-authorize',
  'already-owner': 'You are already the owner of this visualization',
  'images-only': 'Only supported for images',
} as const

export function getSynCapabilitiesTooltipMessage(defaultMsg: string, code?: keyof typeof SYNC_CAPABILITIES_MSG_REPOSITORY | null) {
  if (!code) return defaultMsg

  return SYNC_CAPABILITIES_MSG_REPOSITORY[code] ?? defaultMsg
}