import { EDataSourceType } from '@features/data-sources/types/types'

export enum PathSegments {
  BASE = '/',
  DESTINATIONS = 'destinations',
  COLLECTIONS = 'collections',
  DATA_SOURCES = 'data-sources',
  OLD_VISUALIZATIONS = 'edit-visualizations',
  ADD = 'add',
  SYNC = 'sync',
  SETUP = 'setup',
  LOOKER = 'looker',
  TABLEAU = 'tableau',
  METABASE = 'metabase',
  SETTINGS = 'settings',
  ORGANIZATION = 'organization',
  SOURCE_ID = ':sourceId',
  VISUALIZATION_ID = ':visualizationId',
  COLLECTION_ID = ':collectionId',
  DESTINATION_ID = ':destinationId',
  COLLECTION_SETUP = 'setup/:baseDestinationId',
  COLLECTION_DESTINATION_ID = 'destinations/:destinationId',
  SSO = 'sso',
  DIRECTORY_SYNC = 'directory-sync',
  USERS = 'users',
  HISTORICAL_RUNS = 'historical-runs',
  HISTORICAL_RUN_ID = ':historicalRunId',
  ACCOUNT = 'account',
  USAGE = 'usage',
  NOT_FOUND = 'not-found',
  AUTH_CALLBACK = 'auth-callback',
}

export const Paths = {
  BASE: '/',
  DESTINATIONS: `/${PathSegments.DESTINATIONS}`,
  COLLECTIONS: `/${PathSegments.COLLECTIONS}`,
  ADD_DESTINATION: `/${PathSegments.DESTINATIONS}/${PathSegments.ADD}`,
  DATA_SOURCES: `/${PathSegments.DATA_SOURCES}`,
  TABLEAU_DATA_SOURCE: `/${PathSegments.DATA_SOURCES}/${PathSegments.TABLEAU}`,
  LOOKER_DATA_SOURCE: `/${PathSegments.DATA_SOURCES}/${PathSegments.LOOKER}`,
  METABASE_DATA_SOURCE: `/${PathSegments.DATA_SOURCES}/${PathSegments.METABASE}`,
  TABLEAU_ADMIN_CHECK: `/${PathSegments.DATA_SOURCES}/${PathSegments.ADD}/${PathSegments.TABLEAU}`,
  LOOKER_ADMIN_CHECK: `/${PathSegments.DATA_SOURCES}/${PathSegments.ADD}/${PathSegments.LOOKER}`,
  METABASE_ADMIN_CHECK: `/${PathSegments.DATA_SOURCES}/${PathSegments.ADD}/${PathSegments.METABASE}`,
  ADD_DATA_SOURCE: `/${PathSegments.DATA_SOURCES}/${PathSegments.ADD}`,
  ORGANIZATION_NOT_FOUND: `/${PathSegments.ORGANIZATION}/${PathSegments.NOT_FOUND}`,
  SETTINGS: `/${PathSegments.SETTINGS}`,
  SETTINGS_ACCOUNT: `/${PathSegments.SETTINGS}/${PathSegments.ACCOUNT}`,
  SETTINGS_ORGANIZATION: `/${PathSegments.SETTINGS}/${PathSegments.ORGANIZATION}`,
  SETTINGS_ORGANIZATION_SSO: `/${PathSegments.SETTINGS}/${PathSegments.ORGANIZATION}/${PathSegments.SSO}`,
  SETTINGS_ORGANIZATION_DIRECTORY_SYNC: `/${PathSegments.SETTINGS}/${PathSegments.ORGANIZATION}/${PathSegments.DIRECTORY_SYNC}`,
  SETTINGS_USAGE: `/${PathSegments.SETTINGS}/usage`,
  COLLECTION_SETTINGS: `/${PathSegments.COLLECTION_DESTINATION_ID}/${PathSegments.SETTINGS}`,
  HISTORICAL_RUNS: `/${PathSegments.HISTORICAL_RUNS}`,
  HISTORICAL_RUNS_DETAILS: `/${PathSegments.HISTORICAL_RUNS}/${PathSegments.HISTORICAL_RUN_ID}`,
} as const

export const enum AuthCallbackProviders {
  MICROSOFT = 'microsoft',
}

export const enum DestinationTabs {
  VISUALIZATIONS = 'visualizations',
  FILTERS = 'filters',
  SENDING = 'sending',
  HISTORICAL_RUNS = 'historical-runs',
  ACTIVITY_LOG = 'activity',
  THEME = 'theme',
  UPDATE_SETTINGS = 'update-settings',
  SETTINGS = 'settings',
  TEMPLATE = 'template',
}

export const enum CollectionTabs {
  VARIANTS = 'variants',
  SENDING = 'sending',
  UPDATE_SETTINGS = 'update-settings',
  SETTINGS = 'settings',
}

const dataSourceTypeToRoute: Record<EDataSourceType, string> = {
  [EDataSourceType.Tableau]: Paths.TABLEAU_DATA_SOURCE,
  [EDataSourceType.Looker]: Paths.LOOKER_DATA_SOURCE,
  [EDataSourceType.Metabase]: Paths.METABASE_DATA_SOURCE,
  [EDataSourceType.GoogleSheets]: ''
}

export const buildDestinationPath = (destinationId?: string, tab?: DestinationTabs) =>
  `${Paths.DESTINATIONS}/${destinationId}/${tab || DestinationTabs.VISUALIZATIONS}`

export const buildVisualizationAddPath = (destinationId: string, slideId?: string, slideIndex?: number) =>
  `${Paths.DESTINATIONS}/${destinationId}/${DestinationTabs.VISUALIZATIONS}/${PathSegments.ADD}`

export const buildVisualizationEditPath = (destinationId: string, visualizationId: string) =>
  `${Paths.DESTINATIONS}/${destinationId}/${DestinationTabs.VISUALIZATIONS}/${visualizationId}`

export const buildSlideIdSearchParam = (slideId?: string | null) => {
  if (!slideId) {
    return ''
  }
  return `?slideId=${slideId}`
}

export const buildCollectionPath = (collectionId?: string, tab?: CollectionTabs) =>
  `${Paths.COLLECTIONS}/${collectionId}/${tab || CollectionTabs.VARIANTS}`

export const buildCollectionSettingsPath = (collectionId: string) => `${Paths.COLLECTIONS}/${collectionId}/settings`

export const buildCollectionSetupPath = (basePresentationId: string) =>
  `${Paths.COLLECTIONS}/${PathSegments.SETUP}/${basePresentationId}`

export const buildTableauDataSourceSettingsPath = (sourceId: string) => `${Paths.TABLEAU_DATA_SOURCE}/${sourceId}`

export const buildLookerDataSourceSettingsPath = (sourceId: string) => `${Paths.LOOKER_DATA_SOURCE}/${sourceId}`

export const buildMetabaseDataSourceSettingsPath = (sourceId: string) => `${Paths.METABASE_DATA_SOURCE}/${sourceId}`

export const buildAddSourcePath = (sourceType: EDataSourceType) => dataSourceTypeToRoute[sourceType]

export const buildUserManagementRoute = (sourceType: EDataSourceType, dataSourceId: string) =>
  `${dataSourceTypeToRoute[sourceType]}/${dataSourceId}/users`

export const buildDataSourceSettingsRoute = (sourceType: EDataSourceType, dataSourceId: string) =>
  `${dataSourceTypeToRoute[sourceType]}/${dataSourceId}`

export const buildHistoricalRunDetailsPath = (historicalRunId: string) => `${Paths.HISTORICAL_RUNS}/${historicalRunId}`

export const buildAddVisualizationPath = (destinationId: string) =>
  `${Paths.DESTINATIONS}/${destinationId}/visualizations/add`
