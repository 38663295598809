import { FC } from 'react'

import { IIcon } from '@assets/types'

export const OrganizationIcon: FC<IIcon> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.24935 9.16667H3.83268C3.36597 9.16667 3.13262 9.16667 2.95436 9.25749C2.79756 9.33739 2.67007 9.46487 2.59018 9.62167C2.49935 9.79993 2.49935 10.0333 2.49935 10.5V17.5M13.7493 9.16667H16.166C16.6327 9.16667 16.8661 9.16667 17.0443 9.25749C17.2011 9.33739 17.3286 9.46487 17.4085 9.62167C17.4993 9.79993 17.4993 10.0333 17.4993 10.5V17.5M13.7493 17.5V5.16667C13.7493 4.23325 13.7493 3.76654 13.5677 3.41002C13.4079 3.09641 13.1529 2.84144 12.8393 2.68166C12.4828 2.5 12.0161 2.5 11.0827 2.5H8.91602C7.98259 2.5 7.51588 2.5 7.15937 2.68166C6.84576 2.84144 6.59079 3.09641 6.43101 3.41002C6.24935 3.76654 6.24935 4.23325 6.24935 5.16667V17.5M18.3327 17.5H1.66602M9.16602 5.83333H10.8327M9.16602 9.16667H10.8327M9.16602 12.5H10.8327"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
