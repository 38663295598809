import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Paths } from '@pages/routes/paths'

import { DataSourcesPlaceholder, PlusIcon } from '@assets'

import { useRequestDataSource } from '@features/data-sources'
import { useOrganizationsStore } from '@features/organizations'

import { Button, EmptyPageTemplate, Tooltip } from '@shared/components'

dayjs.extend(relativeTime)

export const EmptyDataSources: FC = () => {
  const { isMember } = useOrganizationsStore()
  const { mutateAsync: requestDataSourcesAccess, isLoading: requestAccessLoading } = useRequestDataSource()
  const [requestExpiryDate, setRequestExpiryDate] = useState<string | null>(null)

  const canRequestAccess = useMemo(
    () => (requestExpiryDate ? dayjs(requestExpiryDate).isBefore(dayjs()) : true),
    [requestExpiryDate]
  )

  const requestAccess = async () => {
    const res = await requestDataSourcesAccess(undefined)
    setRequestExpiryDate(res.data.expiresAt)
  }

  useEffect(() => {
    const expiryDate = localStorage.getItem('dataSourcesAccessRequestExpiry')

    if (expiryDate) {
      setRequestExpiryDate(expiryDate)
    }
  }, [])

  const buttonView = isMember ? (
    <Tooltip show={!canRequestAccess} title="A request was sent to your admin">
      <Button
        loading={requestAccessLoading}
        disabled={!canRequestAccess}
        text={requestExpiryDate ? 'Pending admin action' : 'Request data source'}
        onClick={requestAccess}
      />
    </Tooltip>
  ) : (
    <Link to={Paths.ADD_DATA_SOURCE}>
      <Button iconLeading={<PlusIcon />} text="Add data source" />
    </Link>
  )

  const label = isMember
    ? 'You can request a data source connection from your admin'
    : 'No data sources in the workspace'
  const description = isMember ? 'Your workspace admin will receive a request' : 'Connect your BI instance'

  return (
    <EmptyPageTemplate icon={<DataSourcesPlaceholder />} label={label} description={description} button={buttonView} />
  )
}
