import { User } from '@features/users'

export interface IActivityLog {
  id: number
  userId: number
  user: User | null
  presentationId?: string | null
  collectionId?: string | null
  resource: EActivityResource
  action: EActivityAction
  data?: IActivityDataSync | ILogShareDestinationData
  createdAt: Date
  updatedAt: Date
}

export enum EActivityResource {
  sync = 'sync',
  presentation = 'presentation',
  collection = 'collection',
  tableauData = 'tableauData',
  lookerData = 'lookerData',
  metabaseData = 'metabaseData',
}

export enum EActivityAction {
  add = 'add',
  update = 'update',
  delete = 'delete',
  deleteMany = 'deleteMany',
  updateMany = 'updateMany',
  duplicate = 'duplicate',
  manualSync = 'manualSync',
  scheduledSync = 'scheduledSync',
  share = 'share',
}

export type ILogShareDestinationData = {
  name: string
  username: string
  shareWith: Array<{
    userId: number
    username: string
  }>
}

export interface IActivityDataSync {
  id: string
  name: string
  slideId: string | null
  slideIndex: number | null
  groupIndex: number | null
  imageUrl: string | null
  type: string
  tableauData?: {
    workbookName?: string
    sheetName?: string
    viewName?: string
    jsonFilters: JSON
    jsonParams: JSON
  } | null
  lookerData?: {
    dashboardName?: string
    elementName?: string | null
    jsonFilters: JSON
  } | null
  metabaseData?: {
    dashboardName?: string
    elementName?: string
    jsonFilters: JSON
  } | null
}
