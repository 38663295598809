import { FC } from 'react'

import { IIcon } from '@assets/types'

export const Rocket: FC<IIcon> = ({ color = 'currentColor' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99998 12.5L7.49998 9.99998M9.99998 12.5C11.164 12.0573 12.2807 11.4989 13.3333 10.8333M9.99998 12.5V16.6666C9.99998 16.6666 12.525 16.2083 13.3333 15C14.2333 13.65 13.3333 10.8333 13.3333 10.8333M7.49998 9.99998C7.94343 8.84951 8.50182 7.7467 9.16665 6.70832C10.1376 5.1558 11.4897 3.87752 13.0941 2.99506C14.6986 2.11259 16.5022 1.65529 18.3333 1.66665C18.3333 3.93332 17.6833 7.91665 13.3333 10.8333M7.49998 9.99998H3.33331C3.33331 9.99998 3.79165 7.47498 4.99998 6.66665C6.34998 5.76665 9.16665 6.66665 9.16665 6.66665M3.74998 13.75C2.49998 14.8 2.08331 17.9166 2.08331 17.9166C2.08331 17.9166 5.19998 17.5 6.24998 16.25C6.84165 15.55 6.83331 14.475 6.17498 13.825C5.85107 13.5158 5.42439 13.3372 4.97683 13.3233C4.52928 13.3095 4.09238 13.4614 3.74998 13.75Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
