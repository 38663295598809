import { FC } from 'react'

import { InfoIcon } from '@assets'

import { useShowTutorialModals } from '@features/users'

import { ActionModal, Button, Checkbox } from '@shared/components'
import { useBoolean } from '@shared/hooks/use-boolean'

import styles from './template-modification-modal.module.css'

interface TemplateModificationModalProps {
  visible: boolean
  setModalOpen: (param: boolean) => void
  onConfirm: () => void
}

export const TemplateModificationModal: FC<TemplateModificationModalProps> = ({ visible, setModalOpen, onConfirm }) => {
  const [dontShowAgain, toggleDontShowAgain] = useBoolean()
  const { mutateAsync: updateShowTutorialModals, isLoading } = useShowTutorialModals()

  const handleConfirm = async () => {
    onConfirm()
    setModalOpen(false)
    if (dontShowAgain && !isLoading) {
      await updateShowTutorialModals({
        data: {
          showTemplateModificationModal: false,
        },
      })
    }
  }

  return (
    <ActionModal
      Icon={InfoIcon}
      open={visible}
      title="Template modification"
      onBackgroundClick={() => setModalOpen(false)}
      btns={
        <div className={styles.actionContainer}>
          <div className={styles.checkboxField} onClick={toggleDontShowAgain}>
            <div>
              <Checkbox checked={dontShowAgain} onClick={toggleDontShowAgain} />
            </div>
            Don't show again
          </div>
          <div className={styles.btnContainer}>
            <Button secondaryGray text="Cancel" onClick={() => setModalOpen(false)} />
            <Button text="Confirm" onClick={handleConfirm} />
          </div>
        </div>
      }
    >
      Changes to the template's visualizations will be reflected in the variants, including:
      <ul className={styles.list}>
        <li>Adding visualizations</li>
        <li>Removing visualizations</li>
        <li>Editing visualizations</li>
      </ul>
    </ActionModal>
  )
}
