import { FC, ReactNode, createContext, useState } from 'react'

interface TooltipContextProps {
  activeTooltipId: string | null
  handleMouseOut: (e: React.MouseEvent) => void
  handleMouseOver: (e: React.MouseEvent, id: string) => void
}

export const TooltipContext = createContext<TooltipContextProps>({
  activeTooltipId: null,
  handleMouseOut: () => {},
  handleMouseOver: () => {},
})

export const TooltipProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeTooltipId, setActiveTooltipId] = useState<string | null>(null)

  const handleMouseOver = (e: React.MouseEvent, id: string) => {
    e.stopPropagation()
    setActiveTooltipId(id)
  }

  const handleMouseOut = (e: React.MouseEvent) => {
    e.stopPropagation()
    setActiveTooltipId(null)
  }

  return (
    <TooltipContext.Provider value={{ activeTooltipId, handleMouseOut, handleMouseOver }}>
      {children}
    </TooltipContext.Provider>
  )
}
