import classNames from 'classnames'
import { Crisp } from 'crisp-sdk-web'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { FC, useEffect, useMemo, useState } from 'react'

import { XClose } from '@assets'

import { Organization } from '@features/organizations/types'

import { StripePlan } from '@shared/types'

import styles from './free-trial-banner.module.css'

const MIN_FREE_TRIAL_DAYS_FOR_BANNER = 14
const BANNER_DISABLED_LS = 'bannerDisabled'

dayjs.extend(utc)

interface IFreeTrialBannerParams {
  organization?: Organization
  setIsBannerVisible?: (isVisible: boolean) => void
}

export const FreeTrialBanner: FC<IFreeTrialBannerParams> = ({ organization, setIsBannerVisible }) => {
  const [bannerDisabled, setBannerDisabled] = useState(true)

  useEffect(() => {
    const isBannerDisabled = sessionStorage.getItem(BANNER_DISABLED_LS)
    setBannerDisabled(isBannerDisabled === 'true')
  }, [])

  const freeTrialDaysLeft = useMemo(() => {
    if (organization?.stripePlan !== StripePlan.Free) {
      return undefined
    }

    if (!organization?.freeTrialEndDate) {
      return undefined
    }

    return dayjs.utc(organization.freeTrialEndDate).startOf('day').diff(dayjs.utc().startOf('day'), 'days')
  }, [organization])

  const trialPeriodText = useMemo(() => {
    if (freeTrialDaysLeft === 1) {
      return `Your free trial expires today`
    }
    if (freeTrialDaysLeft !== undefined && freeTrialDaysLeft <= 0) {
      return `Your free trial expired`
    }
    return `You still have ${freeTrialDaysLeft} days in your free trial`
  }, [freeTrialDaysLeft])

  const showBanner = useMemo(() => {
    if (freeTrialDaysLeft === undefined) {
      return false
    }
    if (freeTrialDaysLeft > MIN_FREE_TRIAL_DAYS_FOR_BANNER) {
      return false
    }
    if (freeTrialDaysLeft <= 1) {
      return true
    }
    if (bannerDisabled) {
      return false
    }
    return true
  }, [freeTrialDaysLeft, bannerDisabled])

  const showClose = useMemo(() => {
    if (freeTrialDaysLeft !== undefined && freeTrialDaysLeft <= 1) {
      return false
    }
    return true
  }, [freeTrialDaysLeft])

  const onContactClick = () => {
    Crisp.chat.open()
  }

  const onBannerCloseClick = () => {
    sessionStorage.setItem(BANNER_DISABLED_LS, 'true')
    setBannerDisabled(true)
  }

  useEffect(() => {
    if (setIsBannerVisible) {
      setIsBannerVisible(showBanner)
    }
  }, [showBanner, setIsBannerVisible])

  if (!showBanner) {
    return <></>
  }

  return (
    <div className={classNames(styles.freeTrialWarning, freeTrialDaysLeft! <= 1 ? styles.freeTrialDanger : '')}>
      {trialPeriodText}. Please{' '}
      <span onClick={onContactClick} className={styles.freeTrialContact}>
        contact us
      </span>{' '}
      to set-up your plan
      {showClose && (
        <span onClick={onBannerCloseClick} className={styles.close}>
          <XClose color="#ffffff" />
        </span>
      )}
    </div>
  )
}
