export type ConnectionTestStage = 'pending' | 'running' | 'success' | 'failed'
export type ConnectionTestKey = 'API_TEST' | 'DASHBOARD_TEST' | 'EMBED_TEST'

type TestStageMessage = {
  [key in ConnectionTestStage]?: JSX.Element | string
}

export type ConnectionTestMessages = {
  [key in ConnectionTestKey]: TestStageMessage
}

export interface ConnectionTestStatus {
  currentTest: ConnectionTestKey
  stage: ConnectionTestStage
}

export const defaultConnectionTests: ConnectionTestMessages = {
  API_TEST: {
    pending: 'API Test Pending...',
    running: 'Testing API connection...',
    success: 'API connection is valid',
    failed: 'API connection failed.',
  },
  DASHBOARD_TEST: {
    pending: 'Dashboard Test Pending...',
    running: 'Testing Dashboard access...',
    success: 'Dashboard access is valid',
    failed: 'Dashboard access failed. Please contact us for support',
  },
  EMBED_TEST: {
    pending: 'Embed Test Pending...',
    running: 'Testing Dashboard embedding...',
    success: 'Dashboard embedding is valid',
    failed: 'Dashboard embedding failed. Please contact us for support',
  },
}
