import { ISync } from '@features/syncs/types'

import { useSearching } from '@shared/hooks/use-searching'
import { useSorting } from '@shared/hooks/use-sorting'

export const useSortAndFilterSyncs = (rawSyncs: ISync[] = []) => {
  const { sort } = useSorting<ISync>(['slideIndex', 'name', 'createdAt'], 'asc')
  const { searchQuery, filter, setSearchQuery } = useSearching<ISync>([
    'name',
    'slideIndex',
    'tableauData.workbookName',
    'lookerData.dashboardName',
  ])

  const filteredVis = filter(rawSyncs, searchQuery)
  const syncs = sort(filteredVis)

  return { syncs, searchQuery, setSearchQuery }
}
