import { AxiosError } from 'axios'
import { useContext, useEffect, useState } from 'react'
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query'

import { router } from '@pages/routes/router'

import {
  googleDrivePermissionErrorHandlerFactory,
  insufficientPermissionsErrorHandlerFactory,
  microsoftDrivePermissionErrorHandlerFactory,
  oauthErrorHandlerFactory,
} from '@features/drive/error-handler'
import { useOrganizationsStore } from '@features/organizations'
import { OAuthContext, useAuthStore } from '@features/users'
import { FilePickerContext, FilePickerType } from '@features/users/providers/file-picker-provider'

import { createUserWithoutOrganizationErrorHandler, handleAxiosError } from './error-handler'
import { ApiErrorHandlerFn } from './types'

interface ErrorHandlerOptions {
  errorHandlers?: ApiErrorHandlerFn[]
  errorTitle?: string
  oauthOptions?: {
    ignore?: boolean
    skipRetry?: boolean
    onCancel?: () => void
    onSuccess?: () => void
  }
  insufficientPermissionOptions?: {
    ignore?: boolean
    message?: string
  }
  filePickerOptions?: {
    ignore?: boolean
    itemId?: string
    itemName?: string
    skipRetry?: boolean
    skipSelectedItemIdValidation?: boolean
    type?: FilePickerType
    onCancel?: () => void
    onSuccess?: () => void
  }
}

interface UseQueryCustomOptions {
  canRequestWithoutActiveOrganization?: boolean
}

export interface UseMutationWithErrorHandlerOptions<TData, TError, TVariables, TContext>
  extends Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
    ErrorHandlerOptions {}

export const useMutationWithErrorHandler = <TData, TError extends AxiosError, TVariables = any, TContext = any>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationWithErrorHandlerOptions<TData, TError, TVariables, TContext>
) => {
  const { setActiveOrganization, setOrganizations } = useOrganizationsStore()
  const { showAuthModal, hideAuthModal } = useContext(OAuthContext)
  const { showFilePickerModal, hideFilePickerModal } = useContext(FilePickerContext)
  const { user } = useAuthStore()

  const [variables, setVariables] = useState<TVariables | undefined>(undefined)
  const {
    errorTitle,
    errorHandlers,
    filePickerOptions,
    insufficientPermissionOptions,
    oauthOptions,
    onMutate,
    onSuccess,
    ...useMutationOptions
  } = options || {}

  const query = useMutation(mutationFn, {
    onMutate: variables => {
      setVariables(variables)
      return onMutate && onMutate(variables)
    },
    onSuccess: (data, variables, context) => {
      setVariables(undefined)
      return onSuccess && onSuccess(data, variables, context as TContext | undefined)
    },
    ...useMutationOptions,
  })

  useEffect(() => {
    if (query.isError) {
      handleAxiosError(
        query.error,
        [
          createUserWithoutOrganizationErrorHandler(router.navigate, setActiveOrganization, setOrganizations),
          oauthErrorHandlerFactory({
            user,
            ignoreError: oauthOptions?.ignore,
            handleClose: () => {
              oauthOptions?.onCancel && oauthOptions.onCancel()
              hideAuthModal()
            },
            showAuthModal,
            successCallback: () => {
              if (oauthOptions?.onSuccess) {
                oauthOptions.onSuccess()
                return
              }
              if (oauthOptions?.skipRetry) {
                return
              }
              if (variables) {
                query.mutate(variables)
                return
              }
              console.warn('Variables missing to retry mutation', {
                status: query.status,
                error: query.error,
                variables,
              })
            },
          }),
          insufficientPermissionsErrorHandlerFactory({
            user,
            ignoreError: insufficientPermissionOptions?.ignore,
            message: insufficientPermissionOptions?.message,
          }),
          microsoftDrivePermissionErrorHandlerFactory({
            user,
            ignoreError: filePickerOptions?.ignore,
          }),
          googleDrivePermissionErrorHandlerFactory({
            user,
            ignoreError: filePickerOptions?.ignore,
            handleClose: () => {
              filePickerOptions?.onCancel && filePickerOptions.onCancel()
              hideFilePickerModal()
            },
            itemId: filePickerOptions?.itemId,
            itemName: filePickerOptions?.itemName,
            showFilePickerModal,
            skipSelectedItemIdValidation: filePickerOptions?.skipSelectedItemIdValidation,
            successCallback: () => {
              if (filePickerOptions?.onSuccess) {
                filePickerOptions.onSuccess()
                return
              }
              if (filePickerOptions?.skipRetry) {
                return
              }
              if (variables) {
                query.mutate(variables)
                return
              }
              console.warn('Variables missing to retry mutation', {
                status: query.status,
                error: query.error,
                variables,
              })
            },
            type: filePickerOptions?.type,
          }),
          ...(errorHandlers || []),
        ],
        { errorTitle }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error, query.isError])

  return query
}

export interface UseQueryWithErrorHandlerOptions<
  TQueryFnData,
  TError extends AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> extends Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn' | 'queryKey'>,
    ErrorHandlerOptions,
    UseQueryCustomOptions {
  skipToast?: boolean
}

export const useQueryWithErrorHandler = <
  TQueryFnData,
  TError extends AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryWithErrorHandlerOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const { activeOrganization, setActiveOrganization, setOrganizations } = useOrganizationsStore()
  const { showAuthModal, hideAuthModal } = useContext(OAuthContext)
  const { showFilePickerModal, hideFilePickerModal } = useContext(FilePickerContext)
  const { user } = useAuthStore()

  const {
    errorTitle,
    errorHandlers,
    filePickerOptions,
    insufficientPermissionOptions,
    oauthOptions,
    enabled,
    skipToast,
    ...useQueryOptions
  } = options || {}

  const query = useQuery(queryKey, queryFn, {
    enabled: enabled ?? (options?.canRequestWithoutActiveOrganization ? true : !!activeOrganization),
    ...useQueryOptions,
  })

  useEffect(() => {
    if (query.isError) {
      handleAxiosError(
        query.error,
        [
          createUserWithoutOrganizationErrorHandler(router.navigate, setActiveOrganization, setOrganizations),
          oauthErrorHandlerFactory({
            user,
            ignoreError: oauthOptions?.ignore,
            handleClose: () => {
              oauthOptions?.onCancel && oauthOptions.onCancel()
              hideAuthModal()
            },
            showAuthModal,
            successCallback: () => {
              if (oauthOptions?.onSuccess) {
                oauthOptions.onSuccess()
                return
              }
              if (oauthOptions?.skipRetry) {
                return
              }
              query.refetch()
            },
          }),
          insufficientPermissionsErrorHandlerFactory({
            user,
            ignoreError: insufficientPermissionOptions?.ignore,
            message: insufficientPermissionOptions?.message,
          }),
          microsoftDrivePermissionErrorHandlerFactory({
            user,
            ignoreError: filePickerOptions?.ignore,
          }),
          googleDrivePermissionErrorHandlerFactory({
            user,
            ignoreError: filePickerOptions?.ignore,
            handleClose: () => {
              filePickerOptions?.onCancel && filePickerOptions.onCancel()
              hideFilePickerModal()
            },
            itemId: filePickerOptions?.itemId,
            itemName: filePickerOptions?.itemName,
            showFilePickerModal,
            skipSelectedItemIdValidation: filePickerOptions?.skipSelectedItemIdValidation,
            successCallback: () => {
              if (filePickerOptions?.onSuccess) {
                filePickerOptions.onSuccess()
                return
              }
              if (filePickerOptions?.skipRetry) {
                return
              }
              query.refetch()
            },
            type: filePickerOptions?.type,
          }),
          ...(errorHandlers || []),
        ],
        { errorTitle, skipToast }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error, query.isError])

  return query
}
