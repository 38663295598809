import { FC } from 'react'

export const FilterIcon: FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M5 10.0762H15M2.5 5.07617H17.5M7.5 15.0762H12.5"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
