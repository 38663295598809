import { FC } from 'react'

import { UserStatus } from '@features/organizations/types'

import { Badge, EBadgeColor } from '@shared/components/badge/badge'

export const OrganizationInviteBadge: FC<{ status: UserStatus }> = ({ status }) => {
  switch (status) {
    case UserStatus.joined:
      return <Badge color={EBadgeColor.GREEN} text={UserStatus.joined} />
    case UserStatus.pending:
      return <Badge color={EBadgeColor.YELLOW} text={UserStatus.pending} />
    case UserStatus.declined:
      return <Badge color={EBadgeColor.RED} text={UserStatus.declined} />
    default:
      return null
  }
}
