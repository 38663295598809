import { FC } from 'react'

import { ErrorGraphIcon } from '@assets'

import styles from './error-fallback.module.css'

interface ErrorFallbackProps {
  error: Error
}

export const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => (
  <div className={styles.container}>
    <ErrorGraphIcon />
    <div className={styles.title}>Unable to load</div>
    <div className={styles.message}>{error.message}</div>
  </div>
)
