import classNames from 'classnames'

import styles from './progress-bar.module.css'

interface ProgressBarProps {
  progress: number
  red?: boolean
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, red = false }) => {
  const progressClass = classNames(styles.progress, red && styles.red)

  return (
    <div className={styles.progressBar}>
      <div className={progressClass} style={{ width: `${progress}%` }} />
    </div>
  )
}
