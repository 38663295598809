import { Crisp } from 'crisp-sdk-web'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Paths, buildCollectionPath } from '@pages/routes/paths'

import { AlertIcon, DashboardIcon, FilterIcon, HardDriveIcon, TIcon } from '@assets'
import { CollectionIcon } from '@assets/icons/collection'
import { Loader } from '@assets/icons/loader'

import {
  AddCollectionHeader,
  AddCollectionNotAllowed,
  CollectionFieldStep,
  ReviewCollection,
  VariantNamingOptions,
  useAddCollection,
  useGetDashboardsFromDestination,
  useGetFilters,
} from '@features/collections'
import { FilterValuesSelect } from '@features/collections/components/collection-setup/filter-values-select/filter-values-select'
import { VariantSelectTable } from '@features/collections/components/collection-setup/variant-select-table/variant-select-table'
import { MAX_VARIANTS, TWO_FILTERS } from '@features/collections/consts'
import { useOneVariantValueField } from '@features/collections/hooks/use-one-variant-value-field'
import { useTableauVariants } from '@features/collections/hooks/use-tableau-variants'
import { useTwoVariantValuesField } from '@features/collections/hooks/use-two-variant-value-field'
import {
  DashboardOption,
  EVariantNaming,
  FieldStep,
  FilterOption,
  FolderOption,
  mapSourceTypeToVariantType,
} from '@features/collections/types'
import { filterTableauFiltersForCollection } from '@features/collections/utils'
import { EDataSourceType } from '@features/data-sources/types/types'
import {
  isEmailDestination,
  isGoogleDestination,
  useDestinationRights,
  useGetDestinationById,
} from '@features/destinations'
import { SelectFolder } from '@features/drive'
import { TableauIframe } from '@features/embedding/tableau/tableau-embed/tableau-iframe'
import { useFreeTrial } from '@features/free-trial/hooks/use-free-trial'
import { OAuthType } from '@features/users'

import { Button, SelectInput, Spinner, Switch } from '@shared/components'
import { getMessage } from '@shared/constants'

import { TableauVariantsContext } from './tableau-context'

import styles from './collection-setup.module.css'

export const CollectionSetup: FC = () => {
  const [step, setStep] = useState(0)
  const [openFieldStep, setOpenFieldStep] = useState<FieldStep>(FieldStep.DASHBOARD)
  const [lastFieldStepOpen, setLastFieldStepOpen] = useState<FieldStep>(FieldStep.DASHBOARD)
  const [selectedDashboard, setSelectedDashboard] = useState<DashboardOption | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<FilterOption[] | null>(null)

  const [variantNaming, setVariantNaming] = useState(EVariantNaming.sameName)
  const [selectedParentFolder, setSelectedParentFolder] = useState<FolderOption | null>(null)

  const navigate = useNavigate()
  const { baseDestinationId } = useParams()

  useFreeTrial(() => navigate(Paths.BASE))

  const { data: destination, isLoading: isLoadingDestination } = useGetDestinationById({
    destinationId: baseDestinationId,
  })

  const { viewer } = useDestinationRights(destination)

  const dashboards = useGetDashboardsFromDestination(destination)

  const { data: filters, isLoading: isFiltersLoading } = useGetFilters(
    {
      types: selectedDashboard?.type ? mapSourceTypeToVariantType[selectedDashboard.type] : [],
      dashboardIds: selectedDashboard?.id ? [selectedDashboard.id] : [],
      credentialsIds: selectedDashboard?.credentialsId ? [selectedDashboard.credentialsId] : [],
    },
    {
      enabled: Boolean(selectedDashboard),
    }
  )

  const isTableau = selectedDashboard?.type === EDataSourceType.Tableau

  const { tableauVariantsFromIframe, tableauIframeContainerStyle } = useTableauVariants({
    credentialsId: selectedDashboard?.credentialsId,
    filters: selectedFilters,
    tableauRequiredEmbedData: selectedDashboard?.vis.tableauData,
    enabled: isTableau,
  })

  const tableauContextData = useMemo(
    () => ({
      ...tableauVariantsFromIframe,
      filters: selectedFilters,
    }),
    [selectedFilters, tableauVariantsFromIframe]
  )

  const oneVariantValuesField = useOneVariantValueField({
    credentialsId: selectedDashboard?.credentialsId,
    filter: selectedFilters?.[0],
    tableauContextData,
  })

  const twoVariantValuesField = useTwoVariantValuesField({
    credentialsId: selectedDashboard?.credentialsId,
    filters: selectedFilters,
    tableauContextData,
  })

  const parsedVariants =
    selectedFilters?.length === 1 ? oneVariantValuesField.parsedVariants : twoVariantValuesField.parsedVariants
  const maxVariantsReached = parsedVariants.length > MAX_VARIANTS

  const { mutateAsync: addCollection, isLoading: isAddingCollection } = useAddCollection()

  const handlePrevious = () => {
    if (step === 0) {
      navigate(-1)
    } else {
      setStep(s => s - 1)
    }
  }

  const handleNext = async () => {
    if (step === 1) {
      createCollection()
    } else {
      setStep(s => s + 1)
    }
  }

  const handleFieldChange = useCallback(
    (field: FieldStep) => {
      setOpenFieldStep(field)
      if (field > lastFieldStepOpen) {
        setLastFieldStepOpen(field)
      }
    },
    [lastFieldStepOpen]
  )

  const getFieldStatus = (field: FieldStep) =>
    openFieldStep === field ? 'OPEN' : lastFieldStepOpen >= field ? 'SELECTED' : 'DISABLED'

  const getFieldStatusNamingAndLocation = (field: FieldStep) =>
    openFieldStep === field ? 'OPEN' : !parsedVariants?.length ? 'DISABLED' : 'SELECTED'

  const resetVariants = () => {
    oneVariantValuesField.reset()
    twoVariantValuesField.reset()
  }

  const handleDashboardChange = (dashboard: DashboardOption) => {
    resetVariants()
    setSelectedFilters(null)
    setSelectedDashboard(dashboard)
    handleFieldChange(FieldStep.FILTER)
  }

  const handleFilterChange = (filters: FilterOption[]) => {
    resetVariants()
    setSelectedFilters(filters)
  }

  const handleVariantNamingChange = (variantNaming: EVariantNaming) => {
    setVariantNaming(variantNaming)
    handleFieldChange(FieldStep.LOCATION)
  }

  const handleMoreCollectionVariants = () => {
    Crisp.chat.open()
    Crisp.message.sendText(`Hey there 👋
      I want to create more collection variants.
    `)
  }

  const createCollection = async () => {
    if (!destination || !selectedDashboard || !selectedFilters?.length) {
      return
    }

    const primaryFilterId = selectedFilters?.[0]?.id
    const secondaryFilterId = selectedFilters?.[1]?.id

    const response = await addCollection({
      destinationId: destination.id,

      primaryFilterId,
      ...(secondaryFilterId ? { secondaryFilterId } : {}),

      variants: parsedVariants,

      parentFolderId: selectedParentFolder?.folderId,
      driveId: selectedParentFolder?.driveId,
      variantNaming,
    })

    if (!response.data.id) {
      return
    }
    navigate(buildCollectionPath(response.data.id))
  }

  const maxSelectedFiltersReached = selectedFilters?.length === TWO_FILTERS

  const filtersToShow = useMemo(
    () =>
      maxSelectedFiltersReached
        ? []
        : isTableau && tableauVariantsFromIframe.viewFilters && filters
          ? filterTableauFiltersForCollection(
              filters,
              tableauVariantsFromIframe.viewFilters,
              tableauVariantsFromIframe.viewParameters
            )
          : filters,
    [
      filters,
      isTableau,
      maxSelectedFiltersReached,
      tableauVariantsFromIframe.viewFilters,
      tableauVariantsFromIframe.viewParameters,
    ]
  )

  const filtersLoading = isFiltersLoading || (isTableau && !tableauVariantsFromIframe.filtersLoaded)

  const disabledNext = !Boolean(selectedDashboard && selectedFilters)

  if (isLoadingDestination || !destination) {
    return <Spinner />
  }

  if (viewer) {
    return <AddCollectionNotAllowed message={getMessage('COLLECTION_ADD_VIEWER')} />
  }

  if (!destination.syncs?.length) {
    return <AddCollectionNotAllowed message={getMessage('COLLECTION_ADD_NO_SYNCS')} />
  }

  if (!destination.syncs?.some(sync => sync.originalImageUrl)) {
    return <AddCollectionNotAllowed message={getMessage('COLLECTION_ADD_NO_UPDATE')} />
  }

  return (
    <div>
      <AddCollectionHeader
        step={step}
        onNextClick={handleNext}
        onPreviousClick={handlePrevious}
        disabledPreviousClick={false}
        disabledNextClick={disabledNext || isAddingCollection}
        loadingNextClick={isAddingCollection}
      />

      {step === 0 && (
        <div className={styles.formContainer}>
          <CollectionFieldStep
            icon={<DashboardIcon />}
            title="Select a dashboard"
            status={getFieldStatus(FieldStep.DASHBOARD)}
            onHeaderClick={() => handleFieldChange(FieldStep.DASHBOARD)}
          >
            Select the dashboard containing all the variants (values) of the collection
            <SelectInput
              value={selectedDashboard}
              options={dashboards}
              onChange={handleDashboardChange}
              placeholder="Select a dashboard"
              getOptionValue={option => option.id}
              getOptionLabel={option => `${option.name}`}
              isSearchable={true}
              dataTestId="dashboard"
            />
          </CollectionFieldStep>

          <CollectionFieldStep
            icon={<FilterIcon />}
            title="Select the main filters"
            status={getFieldStatus(FieldStep.FILTER)}
            onHeaderClick={() => handleFieldChange(FieldStep.FILTER)}
          >
            Select which filter(s) the variants should be generated from (you can select up to 2 variables)
            <SelectInput
              isMulti
              value={selectedFilters}
              options={filtersToShow}
              onChange={handleFilterChange}
              placeholder={filtersLoading ? 'Loading filters ...' : 'Select a filter'}
              getOptionValue={option => option.id}
              getOptionLabel={option => `${option.name}`}
              isLoading={filtersLoading}
              isSearchable={true}
              noOptionsMessage={() => (maxSelectedFiltersReached ? 'You already selected two filters' : 'No options')}
              dataTestId="filters"
              closeMenuOnSelect={false}
            />
            <div className={styles.flexCenter}>
              <Button
                disabled={!selectedFilters?.length}
                text="Select variants"
                onClick={() => {
                  handleFieldChange(FieldStep.VARIANTS)
                }}
              />
            </div>
          </CollectionFieldStep>

          <CollectionFieldStep
            icon={<CollectionIcon />}
            title="Select variants"
            status={getFieldStatus(FieldStep.VARIANTS)}
            onHeaderClick={() => handleFieldChange(FieldStep.VARIANTS)}
          >
            <TableauVariantsContext.Provider value={tableauContextData}>
              <div>Select the variants you want to create</div>
              {selectedDashboard && (isTableau ? !tableauContextData.isLoadingFilterValuesCombinations : true) ? (
                <div className=" flex flex-col gap-[20px] font-normal">
                  {selectedFilters?.length === 1 && (
                    <div className="flex flex-col gap-4">
                      {selectedFilters && !oneVariantValuesField.selectedAllVariants && (
                        <FilterValuesSelect
                          credentialsId={selectedDashboard.credentialsId}
                          filter={selectedFilters[0]}
                          value={oneVariantValuesField.selectedVariants}
                          onChange={oneVariantValuesField.handleChangeOptions}
                        />
                      )}
                      <Switch
                        value={oneVariantValuesField.selectedAllVariants}
                        onChange={oneVariantValuesField.handleToggleSelectAll}
                        text="Select All"
                      />
                    </div>
                  )}
                  {selectedFilters?.length === TWO_FILTERS && (
                    <>
                      <Switch
                        value={twoVariantValuesField.selectedAllVariantsCombinations}
                        onChange={twoVariantValuesField.handleToggleAllCombinations}
                        text={
                          <div>
                            Select all{' '}
                            <span className="font-bold">
                              {selectedFilters[0].name} x {selectedFilters[1].name}
                            </span>{' '}
                            combinations
                          </div>
                        }
                      />
                      {!twoVariantValuesField.selectedAllVariantsCombinations && (
                        <VariantSelectTable
                          credentialId={selectedDashboard.credentialsId}
                          primaryFilter={selectedFilters[0]}
                          secondaryFilter={selectedFilters[1]}
                          rows={twoVariantValuesField.variantRows}
                          addRowRef={twoVariantValuesField.addRowRef}
                          onChangePrimaryValue={twoVariantValuesField.handleChangePrimaryValue}
                          onChangeSecondaryValue={twoVariantValuesField.handleChangeSecondaryValue}
                          onSelectAll={twoVariantValuesField.handleSelectAll}
                          onAdd={twoVariantValuesField.handleAdd}
                          onDelete={twoVariantValuesField.handleDelete}
                        />
                      )}
                    </>
                  )}
                  {maxVariantsReached && (
                    <div className="flex flex-col gap-2 border border-yellow-200 rounded-lg p-4 text-yellow-700 bg-yellow-50">
                      <div className="flex items-center gap-2 font-semibold ">
                        <AlertIcon color="currentColor" size={20} />
                        Maximum number of variants reached
                      </div>
                      <div className="ml-7">
                        You can create up to {MAX_VARIANTS} variants. If you need to create more, please contact us{' '}
                        <span className="font-bold underline cursor-pointer" onClick={handleMoreCollectionVariants}>
                          here
                        </span>
                        .
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex justify-center my-4">
                  <Loader />
                </div>
              )}
            </TableauVariantsContext.Provider>
          </CollectionFieldStep>

          <CollectionFieldStep
            icon={<TIcon />}
            title="Variants naming"
            status={getFieldStatusNamingAndLocation(FieldStep.NAMING)}
            onHeaderClick={() => handleFieldChange(FieldStep.NAMING)}
          >
            <VariantNamingOptions
              selected={variantNaming}
              onClick={handleVariantNamingChange}
              placeholderVariant={parsedVariants?.[0]?.primaryValue}
            />
          </CollectionFieldStep>

          {!isEmailDestination(destination) && (
            <CollectionFieldStep
              icon={<HardDriveIcon />}
              title="Files location"
              status={getFieldStatusNamingAndLocation(FieldStep.LOCATION)}
              onHeaderClick={() => handleFieldChange(FieldStep.LOCATION)}
            >
              If no folder is selected, the collection will be created in your Drive's home folder
              <SelectFolder
                oauthType={isGoogleDestination(destination) ? OAuthType.GOOGLE : OAuthType.MICROSOFT}
                selectedParentFolder={selectedParentFolder}
                setSelectedParentFolder={setSelectedParentFolder}
              />
            </CollectionFieldStep>
          )}
        </div>
      )}

      <div style={tableauIframeContainerStyle}>
        <TableauIframe
          token={tableauVariantsFromIframe.tableauVizToken.token}
          width={'0'}
          height={'0'}
          src={tableauVariantsFromIframe.workbookUrl}
          ref={el => {
            tableauVariantsFromIframe.tableauRef.current = el
            tableauVariantsFromIframe.setTableauViz(el)
          }}
        />
      </div>

      {step === 1 && (
        <div className={styles.reviewContainer}>
          <ReviewCollection
            destination={destination}
            selectedFilters={selectedFilters}
            dashboards={dashboards}
            parsedVariants={parsedVariants}
          />
        </div>
      )}
    </div>
  )
}
