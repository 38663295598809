import { Navigate } from 'react-router-dom'

import { MicrosoftAuthCallback } from '@pages/auth-callback/microsoft-auth-callback'
import { ErrorPage } from '@pages/error-page/error-page'

import { AuthCallbackProviders, PathSegments } from './paths'

export const authCallbackRoutes = {
  path: PathSegments.AUTH_CALLBACK,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Navigate to={PathSegments.BASE} replace />,
    },
    {
      path: AuthCallbackProviders.MICROSOFT,
      element: <MicrosoftAuthCallback />,
    },
  ],
}
