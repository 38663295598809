import classNames from 'classnames'
import dayjs from 'dayjs'
import { FC } from 'react'
import { SingleValue } from 'react-select'

import { ClockIcon, PlusIcon, TrashIcon, UpdateIcon } from '@assets'

import { IFormattedSchedule, IFreqOption, ITimeOption } from '@features/destinations/types'
import { getScheduleDescription } from '@features/destinations/utils'

import { Badge, Button, EBadgeColor, Input, Period, PeriodSwitch, SelectInput, Switch } from '@shared/components'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@shared/components/ui/accordion'

import styles from './destination-schedule.module.css'

interface IDestinationScheduleProps {
  formattedSchedules: IFormattedSchedule[]
  freqOptions: IFreqOption[]
  timeOptions: ITimeOption[]
  expandedScheduleId: string | null
  handleFrequencyChange: (scheduleId: string, option: SingleValue<IFreqOption>) => void
  handleTimeChange: (scheduleId: string, option: SingleValue<ITimeOption>) => void
  handleStartDateChange: (scheduleId: string, e: any) => void
  handleEndDateChange: (scheduleId: string, e: any) => void
  toggleEndDate: (scheduleId: string) => void
  addSchedule: () => void
  removeSchedule: (scheduleId: string) => void
  handlePeriodChange: (scheduleId: string, period: Period) => void
  setExpandedScheduleId: (scheduleId: string | null) => void
}
export const DestinationSchedule: FC<IDestinationScheduleProps> = ({
  formattedSchedules,
  freqOptions,
  timeOptions,
  expandedScheduleId,
  handleFrequencyChange,
  handleTimeChange,
  handleStartDateChange,
  handleEndDateChange,
  toggleEndDate,
  addSchedule,
  removeSchedule,
  handlePeriodChange,
  setExpandedScheduleId,
}) => {
  return (
    <div className="w-full">
      <Accordion
        type="single"
        collapsible
        value={expandedScheduleId ?? 'NoSechedule'}
        onValueChange={setExpandedScheduleId}
      >
        {formattedSchedules.map(
          ({ id, startDate, endDate, frequency, selectedTime, selectedPeriod, parentUpdateScheduleId }) => (
            <AccordionItem key={id} value={id} className={styles.scheduleItem}>
              <AccordionTrigger className={styles.scheduleHeader}>
                <div className={styles.scheduleTitle}>
                  <h3>
                    {getScheduleDescription({
                      frequency,
                      selectedTime,
                      selectedPeriod,
                      startDate,
                    })}
                  </h3>
                  {parentUpdateScheduleId && (
                      <Badge text={'Controlled by collection'} color={EBadgeColor.SECONDARY} />
                    )}
                </div>
              </AccordionTrigger>
              <AccordionContent
                className={classNames(styles.rowContent, styles.centeredRowContent, styles.schedulingContainer)}
                style={{ overflow: 'visible' }}
              >
                <div className={styles.scheduleItemContainer}>
                  <label className={styles.scheduleLabel}>Start Date</label>
                  <Input
                    type="date"
                    value={startDate || ''}
                    className={styles.input}
                    onChange={e => handleStartDateChange(id, e)}
                    min={dayjs().format('YYYY-MM-DD')}
                  />
                </div>
                <div className={styles.scheduleItemContainer}>
                  <div className={styles.endDateLabel}>
                    End Date
                    <Switch
                      className={styles.endDateSwitch}
                      value={Boolean(endDate)}
                      onChange={() => toggleEndDate(id)}
                    />
                  </div>
                  <Input
                    type="date"
                    disabled={!endDate}
                    value={endDate || ''}
                    className={styles.input}
                    onChange={e => handleEndDateChange(id, e)}
                    min={dayjs(startDate).add(1, 'day').format('YYYY-MM-DD')}
                  />
                </div>
                <div className={styles.breakLine}></div>
                <div className={styles.scheduleItemContainer}>
                  <div className={styles.scheduleLabel}>Schedule Frequency</div>
                  <SelectInput
                    className={styles.scheduleSelect}
                    options={freqOptions}
                    value={freqOptions.find(option => option.value === frequency)}
                    placeholder="Select frequency"
                    onChange={option => handleFrequencyChange(id, option)}
                    trailingIcon={<UpdateIcon />}
                  />
                </div>
                <div className={classNames(styles.inputTimeContainer, styles.scheduleItemContainer)}>
                  <div>
                    <div className={styles.scheduleLabel}>Schedule Time</div>
                    <SelectInput
                      className={styles.scheduleSelect}
                      options={timeOptions}
                      value={selectedTime}
                      placeholder="Select hour"
                      onChange={option => handleTimeChange(id, option)}
                      trailingIcon={<ClockIcon />}
                    />
                  </div>
                  <PeriodSwitch value={selectedPeriod} onChange={period => handlePeriodChange(id, period)} />
                </div>
                <Button
                  text="Delete Schedule"
                  iconLeading={<TrashIcon />}
                  secondaryColor
                  onClick={() => removeSchedule(id)}
                />
              </AccordionContent>
            </AccordionItem>
          )
        )}
      </Accordion>
      <Button text="Add a schedule" iconLeading={<PlusIcon />} onClick={addSchedule} />
    </div>
  )
}
